import { useFormContext } from "react-hook-form"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import { useAppThunkDispatch } from "src/redux/store"
import {
  onCancelClick,
  setValueForVariable
} from "src/configurationPortal/pages/FunctionHelper"
import { getVariableBasedOnComponent } from "../../Utils/Helpers/TotalsGridHelper"
import FormSubmitCancelButtons from "../../../FormSubmitCancelButtons"
import { replaceAllMultiple } from "src/utils/helper"

const FormSubmitCancel = () => {
  const history = useHistory()
  let location = useLocation() as any
  const dispatch = useAppThunkDispatch()

  const {
    id,
    tab,
    tabId,
    type
  }: { id: string; tab: string; tabId: string; type: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""
  const isSubtotal =
    type.toLocaleLowerCase() === ConfigTabNames.SubTotals.toLocaleLowerCase()

  let { variableSetRedux, variableRedux, redirectUrl, variablesRedux } =
    getVariableBasedOnComponent(tab, type, storeTabId, storeId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  const { reset } = useFormContext()

  let stateVariableRedux = location.state?.variableRedux
  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })
  //istanbul ignore next
  const handleSaveClick = (data, isSave = true) => {
    let totals = isSubtotal
      ? locVariableRedux.SubTotals
      : locVariableRedux.GrandTotals

    if (storeId <= -1) {
      totals = [...totals, { ...data }]
    } else {
      totals = totals?.map((i, j) => {
        return j === storeId - 1 ? data : i
      })
    }
    dispatch(variableSetRedux(locVariableRedux))
    dispatch(
      variableSetRedux({
        ...locVariableRedux,
        ...(isSubtotal && { SubTotals: totals }),
        ...(!isSubtotal && { GrandTotals: totals })
      })
    )
    isSave ? onCancelClick(history, redirectUrl) : reset()
  }
  //istanbul ignore next
  const handleNewSaveClickCall = data => {
    handleSaveClick(data, false)
  }
  const handleSaveClickCall = data => {
    handleSaveClick(data, true)
  }
  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClickCall}
      handleNewSaveClick={handleNewSaveClickCall}
      redirectUrl={redirectUrl}
    />
  )
}

export default FormSubmitCancel
