import {
  setCountFields,
  setDefaultCountFields
} from "src/redux/slices/configSlice"

export const callbackConfirmationHelper = ({
  res,
  setIsConfirmOpen,
  dispatch,
  deleteField,
  deleteFieldFromCountProgram,
  defaultCountFields,
  countFields
}: {
  res: boolean
  setIsConfirmOpen: Function
  dispatch: Function
  deleteField: Function
  deleteFieldFromCountProgram: Function
  defaultCountFields
  countFields
}) => {
  let fieldsCopy = { ...defaultCountFields }
  let countFieldsCopy = { ...countFields }
  if (res) {
    if (Object.keys(countFields).length) {
      let finalObj = deleteField(countFieldsCopy)
      dispatch(setCountFields({ ...finalObj }))
    }
    let finalObj = deleteField(fieldsCopy)
    dispatch(setDefaultCountFields({ data: { ...finalObj }, type: "DELETE" }))
    deleteFieldFromCountProgram()
  }
  setIsConfirmOpen(false)
}
