import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import {
  createEditOutputGTDifIniValues,
  createEditOutputSTDifIniValues,
  createEditReportGTDifIniValues,
  createEditReportSTDifIniValues,
  createEditTotalFieldNames,
  functionOptions
} from "../Constants/CreateEditTotalConstant"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { formField } from "src/utils/constants"
import { getTabLabel } from "src/configurationPortal/pages/FunctionHelper"

export const getDefaultValues = (
  locVariableRedux,
  storeId: number,
  tab: string,
  type: string
) => {
  let totals =
    type.toLocaleLowerCase() === ConfigTabNames.SubTotals.toLocaleLowerCase()
      ? locVariableRedux.SubTotals
      : locVariableRedux.GrandTotals

  if (totals?.length) {
    if (storeId > -1 && totals[storeId - 1]) {
      return {
        ...totals[storeId - 1]
      }
    }
  }
  let defaultValues: any = createEditReportSTDifIniValues

  if (tab.toLocaleLowerCase() === ConfigTabNames.Reports.toLocaleLowerCase()) {
    if (
      type.toLocaleLowerCase() ===
      ConfigTabNames.GrandTotals.toLocaleLowerCase()
    ) {
      defaultValues = createEditReportGTDifIniValues
    }
  } else if (
    [
      ConfigTabNames.Outputs.toLocaleLowerCase(),
      ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
    ].includes(tab.toLocaleLowerCase())
  ) {
    if (
      type.toLocaleLowerCase() === ConfigTabNames.SubTotals.toLocaleLowerCase()
    ) {
      defaultValues = createEditOutputSTDifIniValues
    } else if (
      type.toLocaleLowerCase() ===
      ConfigTabNames.GrandTotals.toLocaleLowerCase()
    ) {
      defaultValues = createEditOutputGTDifIniValues
    }
  }
  return defaultValues
}

export const getBreadcrumbConfig = (t, tab, type, storeId) => {
  let currentPath: string = "column"
  let createOrEdit =
    storeId > -1 ? Translates.Edit_Sub_Total : Translates.New_Sub_Total

  if (type === ConfigTabNames.GrandTotals.toLocaleLowerCase()) {
    createOrEdit =
      storeId > -1 ? Translates.Edit_Grand_Total : Translates.New_Grand_Total
  }
  const { locTab } = getTabLabel(tab)
  let variables: any = {
    column: `${getTranslations(t, locTab)} ${getTranslations(t, createOrEdit)}`
  }
  let bConfig: any = {
    currentPath,
    mapObj: variables,
    showHomeIcon: false
  }
  return bConfig
}

export const getTotalBasicFields = (locColumnsRedux, isDataGroupField) => {
  const dataGroupOptions =
    locColumnsRedux.columns?.map(i => ({
      label: i.ColumnName,
      value: i.ColumnName
    })) || []

  let fields = {
    ...(isDataGroupField && {
      dataGroup: {
        fieldType: formField.multi_auto_dropdown,
        props: {
          name: createEditTotalFieldNames.DataGroup.name,
          label: createEditTotalFieldNames.DataGroup.label,
          options: dataGroupOptions,
          required: true
        }
      }
    }),
    Label: {
      fieldType: formField.text,
      props: {
        name: createEditTotalFieldNames.Label.name,
        label: createEditTotalFieldNames.Label.label,
        required: true
      }
    }
  }
  return fields
}

export const getTotalValuesFields = (locColumnsRedux, locVariableRedux) => {
  const dataSourceOptions =
    locColumnsRedux.columns?.map(i => ({
      label: i.ColumnName,
      value: i.ColumnName
    })) || []

  const dataColumnNameOptions =
    locVariableRedux.Columns?.map(i => ({
      label: i.ColumnName,
      value: i.ColumnName
    })) || []

  let fields = {
    dataSource: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditTotalFieldNames.AConfigsDataSource.name,
        label: createEditTotalFieldNames.AConfigsDataSource.label,
        options: dataSourceOptions,
        required: true
      }
    },
    column: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditTotalFieldNames.AConfigsColumn.name,
        label: createEditTotalFieldNames.AConfigsColumn.label,
        options: dataColumnNameOptions,
        required: true
      }
    },
    function: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditTotalFieldNames.AConfigsFunction.name,
        label: createEditTotalFieldNames.AConfigsFunction.label,
        options: functionOptions,
        required: true
      }
    }
  }
  return fields
}
