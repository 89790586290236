import * as yup from "yup"
import { SQLTestColumnSliceType } from "src/redux/slices/ConfigPortal/ColumnSlice"
import {
  AggregateConfigsField,
  createEditTotalFieldNames,
  functionOptions
} from "./Constants/CreateEditTotalConstant"
import { oneOfErr, requiredErr } from "src/utils/constants"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"

export const validationSchema = (
  locColumnsRedux: SQLTestColumnSliceType["columnsRedux"],
  locVariableRedux,
  tab,
  type
) => {
  const apiColumns = [
    "",
    ...(locColumnsRedux.columns || []).map(item => item.ColumnName)
  ]

  const configColumns = [
    "",
    ...(locVariableRedux.Columns || []).map(item => item.ColumnName)
  ]

  const fns = ["", ...(functionOptions || []).map(item => item.value)]
  const isSubtotal =
    type.toLocaleLowerCase() === ConfigTabNames.SubTotals.toLocaleLowerCase()

  const isPlacement = [ConfigTabNames.Outputs.toLocaleLowerCase()].includes(
    tab.toLocaleLowerCase()
  )
  return yup.object().shape({
    ...(isSubtotal && {
      [createEditTotalFieldNames.DataGroup.name]: yup
        .array()
        .min(1, requiredErr)
        .required(requiredErr)
        .of(yup.string().oneOf(apiColumns, oneOfErr))
    }),
    [createEditTotalFieldNames.Label.name]: yup.string().required(requiredErr),
    [createEditTotalFieldNames.AggregateConfigs.name]: yup.array().of(
      yup.object().shape({
        [AggregateConfigsField.DataSource]: yup
          .string()
          .required(requiredErr)
          .oneOf(apiColumns, oneOfErr),
        [AggregateConfigsField.Column]: yup
          .string()
          .required(requiredErr)
          .oneOf(configColumns, oneOfErr),
        [AggregateConfigsField.Function]: yup
          .string()
          .required(requiredErr)
          .oneOf(fns, oneOfErr)
      })
    ),
    ...(isPlacement && {
      [createEditTotalFieldNames.Placement.name]: yup
        .string()
        .required(requiredErr)
    })
  })
}
