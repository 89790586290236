import {
  IConfigCountProgram,
  IFormCountProgram,
  Instruction
} from "src/utils/types/types"
import {
  LookupConfigToEditor,
  LookupEditorToConfig
} from "../LookupRoutines/helpers"
import { AllowedBarcodeTypes, defaultIniValues } from "./Constant"
import { fieldNames } from "src/configurationPortal/pages/CountPrograms/Constant"
import { getBreadcrumbConfig } from "../helper"
import { PATH } from "src/constants/paths"
import { getTranslations } from "src/utils/helper"
import {
  getLocationList,
  getProductList
} from "./Component/TabFunctionsAndConstant"
import { LocationsList, ProductsList } from "../CountFields/Constants"
import { AlertColor } from "@mui/material"
import { Translates } from "src/i18n/i18n"
//istanbul ignore next
export const formToReduxConversion = (
  form
): { reduxData: IConfigCountProgram; cpErrors: string[] } => {
  const productList = getProductList()
  const locationList = getLocationList()
  let cpErrors: string[] = []
  let reduxData: IConfigCountProgram = {} as IConfigCountProgram
  if (cpErrors.length <= 0) {
    let LocationModeData = {}

    if (
      Array.isArray(form.LocationMode?.items) &&
      form.LocationMode.items.length
    ) {
      const BreakException = {}
      try {
        form.LocationMode.items.forEach((i, j) => {
          if (
            locationList[i.key] &&
            [...locationList.map(i => i.OjKey)].includes(
              locationList[i.key].OjKey
            )
          ) {
            let InstructionCheck = true
            if (
              [LocationsList.Location.OjKey].includes(
                locationList[i.key].OjKey
              ) &&
              i.StandardWisTag === fieldNames.StandardWisTag.value
            ) {
              InstructionCheck = false
            }
            if (
              [LocationsList.Location.OjKey].includes(
                locationList[i.key].OjKey
              ) &&
              i.StandardWisTag === fieldNames.StandardWisTag.value &&
              !i.AllowedBarcodeTypes0 &&
              !i.AllowedBarcodeTypes1
            ) {
              cpErrors.push(
                `Location Standard WIS Tag is enabled so either "${AllowedBarcodeTypes[0]}" or "${AllowedBarcodeTypes[1]}" should be selected!`
              )
              throw BreakException
            } else if (
              InstructionCheck &&
              !i.SetupInstructions?.length &&
              [LocationsList.Location.OjKey].includes(locationList[i.key].OjKey)
            ) {
              cpErrors.push(
                `Location Capture "${
                  locationList[i.key].OjKey
                }" setup instruction required!`
              )
              throw BreakException
            } else if (
              i.ValidationInstructions?.length ||
              i.LookupInstructions?.length ||
              i.SetupInstructions?.length ||
              i?.InsertRules?.SetupInstructions?.length ||
              i?.InsertRules?.ValidationInstructions?.length ||
              i?.InsertRules?.LookupInstructions?.length ||
              !InstructionCheck
            ) {
              if (InstructionCheck && !i.SetupInstructions?.length) {
                cpErrors.push(
                  `Location Capture "${
                    locationList[i.key].OjKey
                  }" setup instruction required!`
                )
                throw BreakException
              } else if (
                InstructionCheck &&
                i.IsSeparateInsertRule &&
                !i?.InsertRules?.SetupInstructions?.length
              ) {
                cpErrors.push(
                  `Location Capture "${
                    locationList[i.key].OjKey
                  }" insert rule setup instruction required!`
                )
                throw BreakException
              } else {
                let allInstructions = getAllInstructionsForSingleField(i)

                let _IsAlpha = i.IsAlpha
                let _Allowed = [
                  i.AllowedBarcodeTypes0 && AllowedBarcodeTypes[0],
                  i.AllowedBarcodeTypes1 && AllowedBarcodeTypes[1]
                ]
                if (i.StandardWisTag === fieldNames.StandardWisTag.value) {
                  _IsAlpha = false
                } else if (
                  i.StandardWisTag === fieldNames.CustomLocation.value
                ) {
                  _Allowed = []
                } else {
                  _IsAlpha = i.IsAlpha
                }
                let locLocationData = {
                  [locationList[i.key].OjKey]: {
                    ...allInstructions,
                    ...([LocationsList.Location.OjKey].includes(
                      locationList[i.key].OjKey
                    ) && {
                      StandardWisTag:
                        i.StandardWisTag === fieldNames.StandardWisTag.value,
                      CustomLocation:
                        i.StandardWisTag === fieldNames.CustomLocation.value,
                      IsAlpha: _IsAlpha,
                      AllowedBarcodeTypes: _Allowed
                    })
                  }
                }
                LocationModeData = {
                  ...LocationModeData,
                  ...locLocationData
                }
              }
            }
          }
        })
      } catch (e) {
        if (e !== BreakException) throw e
      }
    }

    let ProductModeData = {}
    let AncillaryData = {}
    let QtyInfo = {
      index: -1,
      label: "",
      exist: false
    }
    if (form.ProductMode?.Enabled) {
      ProductModeData = {
        ...ProductModeData,
        ...{ CountType: form.ProductMode.CountType }
      }
      if (form.ProductMode.items && form.ProductMode.items.length) {
        const BreakException = {}
        try {
          form.ProductMode.items.forEach((i, j) => {
            if ([ProductsList.Qty.OjKey].includes(productList[i.key].OjKey)) {
              QtyInfo = {
                ...QtyInfo,
                ...{ index: j, label: productList[i.key].OjKey }
              }
            }
            if (j === 0 && !i.SetupInstructions?.length) {
              cpErrors.push(
                `Product count mode "${
                  productList[i.key].OjKey
                }" setup instruction required!`
              )
              throw BreakException
            } else if (
              i.ValidationInstructions?.length ||
              i.LookupInstructions?.length ||
              i.SetupInstructions?.length ||
              i?.InsertRules?.SetupInstructions?.length ||
              i?.InsertRules?.ValidationInstructions?.length ||
              i?.InsertRules?.LookupInstructions?.length
            ) {
              if (!i.SetupInstructions?.length) {
                cpErrors.push(
                  `Product count mode "${
                    productList[i.key].OjKey
                  }" setup instruction required!`
                )
                throw BreakException
              } else if (
                i.IsSeparateInsertRule &&
                !i?.InsertRules?.SetupInstructions?.length
              ) {
                cpErrors.push(
                  `Product count mode "${
                    productList[i.key].OjKey
                  }" insert rule setup instruction required!`
                )
                throw BreakException
              } else {
                let allInstructions = getAllInstructionsForSingleField(i)
                if (
                  [
                    ProductsList.ProductCaptured.OjKey,
                    ProductsList.Qty.OjKey
                  ].includes(productList[i.key].OjKey)
                ) {
                  if (
                    [ProductsList.Qty.OjKey].includes(productList[i.key].OjKey)
                  ) {
                    QtyInfo = {
                      ...QtyInfo,
                      ...{ exist: true }
                    }
                  }
                  ProductModeData = {
                    ...ProductModeData,
                    ...{
                      [productList[i.key].OjKey]: allInstructions
                    }
                  }
                } else {
                  AncillaryData = {
                    ...AncillaryData,
                    ...{
                      [productList[i.key].OjKey]: allInstructions
                    }
                  }
                }
              }
            }
          })
        } catch (e) {
          if (e !== BreakException) throw e
        }
      }
    }

    if (Object.keys(AncillaryData).length) {
      ProductModeData[productList[0].OjKey] = {
        ...ProductModeData[productList[0].OjKey],
        ...{ AncillaryData }
      }
    }

    if (cpErrors.length <= 0) {
      reduxData = {
        ...LocationModeData,
        ...{
          Name: [{ "en-us": form.Name }],
          ProductMode: {
            ...ProductModeData,
            ...{ Enabled: form.ProductMode?.Enabled }
          }
        }
      }
    }
  }

  return { reduxData, cpErrors }
}
//istanbul ignore next
const getAllInstructionsForSingleField = i => {
  let allInstructions = {
    ...(i.SetupInstructions &&
      i.SetupInstructions.length && {
        SetupInstructions: LookupEditorToConfig(i.SetupInstructions ?? [])
      }),
    ...(i.ValidationInstructions &&
      i.ValidationInstructions.length && {
        ValidationInstructions: LookupEditorToConfig(
          i.ValidationInstructions ?? []
        )
      }),
    ...(i.LookupInstructions &&
      i.LookupInstructions.length && {
        LookupInstructions: LookupEditorToConfig(i.LookupInstructions ?? [])
      }),
    IsSeparateInsertRule: i.IsSeparateInsertRule
  }

  if (i.IsSeparateInsertRule) {
    allInstructions = {
      ...allInstructions,
      ...{
        InsertRules: {
          SetupInstructions: LookupEditorToConfig(
            i?.InsertRules?.SetupInstructions ?? []
          ),
          ...(i?.InsertRules?.ValidationInstructions &&
            i.InsertRules.ValidationInstructions.length && {
              ValidationInstructions: LookupEditorToConfig(
                i?.InsertRules?.ValidationInstructions ?? []
              )
            }),
          ...(i?.InsertRules?.LookupInstructions &&
            i.InsertRules.LookupInstructions.length && {
              LookupInstructions: LookupEditorToConfig(
                i?.InsertRules?.LookupInstructions ?? []
              )
            })
        }
      }
    }
  }

  return allInstructions
}
//istanbul ignore next
const getStdWisTag = (data: any) => {
  let StdWisTagValue = ""
  if (data.StandardWisTag) {
    StdWisTagValue = fieldNames.StandardWisTag.value
  } else if (data.CustomLocation) {
    StdWisTagValue = fieldNames.CustomLocation.value
  } else {
    return StdWisTagValue
  }
  return StdWisTagValue
}
//istanbul ignore next
export const reduxToFormConversion = (
  data: IConfigCountProgram
): IFormCountProgram => {
  let productList = getProductList()
  let locationList = getLocationList()

  let items1: IFormCountProgram["LocationMode"]["items"] = []

  if (Object.keys(data).length) {
    locationList.forEach((i, j) => {
      if (data[i.OjKey]) {
        let locLocationData = fieldOj(j, data[i.OjKey])

        if ([LocationsList.Location.OjKey].includes(i.OjKey)) {
          const StdWisTagValue = getStdWisTag(data[i.OjKey])

          locLocationData = {
            ...locLocationData,
            ...{
              StandardWisTag: StdWisTagValue,
              IsAlpha: data[i.OjKey].IsAlpha,
              AllowedBarcodeTypes0: data[i.OjKey].AllowedBarcodeTypes.includes(
                AllowedBarcodeTypes[0]
              ),
              AllowedBarcodeTypes1: data[i.OjKey].AllowedBarcodeTypes.includes(
                AllowedBarcodeTypes[1]
              )
            }
          }
        }
        items1 = [...items1, locLocationData]
      }
    })
  }

  let items: IFormCountProgram["ProductMode"]["items"] = []
  let ProductModeData: IFormCountProgram["ProductMode"] = {
    Enabled: data.ProductMode?.Enabled,
    items: items
  }
  let defVales = defaultIniValues.ProductMode.items[0]
  if (data.ProductMode?.Enabled && Object.keys(data.ProductMode).length > 1) {
    productList.forEach((i, j) => {
      if (
        data.ProductMode[i.OjKey] &&
        data.ProductMode[i.OjKey] !== undefined &&
        typeof data.ProductMode[i.OjKey] === "object"
      ) {
        items = [...items, fieldOj(j, data.ProductMode[i.OjKey])]
      } else if (
        data.ProductMode[productList[0].OjKey]?.AncillaryData &&
        data.ProductMode[productList[0].OjKey].AncillaryData[i.OjKey] &&
        data.ProductMode[productList[0].OjKey].AncillaryData[i.OjKey] !==
          undefined &&
        typeof data.ProductMode[productList[0].OjKey]?.AncillaryData[
          i.OjKey
        ] === "object"
      ) {
        items = [
          ...items,
          fieldOj(
            j,
            data.ProductMode[productList[0].OjKey]?.AncillaryData[i.OjKey]
          )
        ]
      } else {
        items = [
          ...items,
          {
            ...defVales,
            key: j
          }
        ]
      }
    })

    ProductModeData = {
      ...ProductModeData,
      ...{
        CountType: data.ProductMode.CountType
      }
    }
  } else {
    items = [
      ...items,
      {
        ...defVales,
        key: 0
      }
    ]
  }

  ProductModeData = {
    ...ProductModeData,
    ...{
      items: items
    }
  }

  const formData: IFormCountProgram = {
    LocationMode: { items: items1 },
    Name: data.Name[0]["en-us"],
    ProductMode: ProductModeData,
    ParentTabIndex: 0,
    ChildTabIndex: 0
  }
  return formData
}
//istanbul ignore next
const fieldOj = (
  j: number,
  data: {
    IsSeparateInsertRule: boolean
    InsertRules: Instruction
  } & Instruction
) => {
  return {
    key: j,
    IsSeparateInsertRule: data.IsSeparateInsertRule,
    IsAlpha: false,
    SetupInstructions: LookupConfigToEditor(data.SetupInstructions),
    ValidationInstructions: LookupConfigToEditor(data.ValidationInstructions),
    LookupInstructions: LookupConfigToEditor(data.LookupInstructions),
    InsertRules: {
      SetupInstructions: LookupConfigToEditor(
        data?.InsertRules?.SetupInstructions
      ),
      ValidationInstructions: LookupConfigToEditor(
        data?.InsertRules?.ValidationInstructions
      ),
      LookupInstructions: LookupConfigToEditor(
        data?.InsertRules?.LookupInstructions
      )
    }
  }
}
//istanbul ignore next
export const getDefaultValues = newCountProgram => {
  if (Object.keys(newCountProgram)?.length) return newCountProgram
  return defaultIniValues
}
//istanbul ignore next
export const getBreadcrumbPath = (storeId: number, t, nextPage = false) => {
  let componentName =
    storeId !== -1
      ? getTranslations(t, Translates.Edit_count_program)
      : getTranslations(t, Translates.Create_count_program)
  const { bConfig } = getBreadcrumbConfig({
    landingName: getTranslations(t, Translates.Count_programs),
    landingPath: PATH.COUNT_PROGRAMS.slice(1),
    componentName: componentName,
    nextPage: nextPage,
    storeId: storeId,
    createPath: PATH.CREATE_COUNT_PROGRAMS,
    editPath: PATH.EDIT_COUNT_PROGRAMS
  })
  return { componentName, bConfig }
}
//istanbul ignore next
export const getToCopy = async (clickBoardName, instructions) => {
  let isCopied: boolean = false
  let locAlertProps = {
    title: "Success",
    severity: "success" as AlertColor,
    content: "Copied instructions successfully!"
  }
  if (instructions?.length) {
    try {
      await navigator.clipboard
        .writeText(
          JSON.stringify({
            sourceFrom: clickBoardName,
            copiedItem: LookupEditorToConfig(instructions)
          })
        )
        .then(
          () => {
            /* clipboard successfully set */
            isCopied = true
          },
          () => {
            /* clipboard write failed */
          }
        )
    } catch (err) {
      console.error("Failed to copy: ", err)
    }
  }

  return { isCopied, locAlertProps }
}
//istanbul ignore next
export const handleToPaste = async (
  clickBoardName,
  previousInstructions,
  instructionsName
) => {
  let isPasted: boolean = false
  let isNonEmptyArray: boolean = false
  let isAlertShow: boolean = false
  let isValueSet: boolean = false
  let instructions: any = []
  let locAlertProps = {
    title: "Error",
    severity: "error" as AlertColor,
    content: "Please copy the instruction before trying to paste!"
  }
  let locPasteInfo = {
    name: "",
    instructions: []
  }
  const text = await navigator.clipboard.readText()
  if (
    typeof text === "string" &&
    text.includes("sourceFrom") &&
    text.includes("copiedItem")
  ) {
    const copiedItem = JSON.parse(text)

    if (copiedItem?.sourceFrom === clickBoardName) {
      isPasted = true
      instructions = LookupConfigToEditor(copiedItem.copiedItem)
      if (!previousInstructions?.length) {
        locAlertProps = {
          title: "Success",
          severity: "success" as AlertColor,
          content: "Pasted instructions successfully!"
        }
        isAlertShow = true
        isValueSet = true
      } else {
        locPasteInfo = {
          name: instructionsName,
          instructions: instructions
        }
        isNonEmptyArray = true
      }
    } else {
      locAlertProps = {
        title: "Error",
        severity: "error" as AlertColor,
        content: "Invalid instructions!"
      }
      isAlertShow = true
    }
  } else {
    isAlertShow = true
  }

  return {
    isPasted,
    locAlertProps,
    instructions,
    isNonEmptyArray,
    locPasteInfo,
    isAlertShow,
    isValueSet
  }
}
