import { IconButton, Tooltip } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import editIcon from "src/assets/images/Edit.png"
import trashIcon from "src/assets/images/TrashIcon.svg"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"
import AlertConfirmationContent from "src/configurationPortal/pages/Components/AlertConfirmationContent"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import { SQLTestColumnSliceType } from "src/redux/slices/ConfigPortal/ColumnSlice"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { store } from "src/redux/store"
import { PATH, multiEventOutputRouteReplaceVar } from "src/constants/paths"
import { ConfigReportType } from "src/configurationPortal/pages/Reports/Utils/Types"
import { MultiEventOutputFormType } from "src/pages/MultiEventOutputs/Utils/Types"
import { setMultiEventOutputRedux } from "src/redux/slices/MultiEventOutputSlice"

export const displayValuesSpan = (props: any) => {
  return (
    <>
      {props?.value?.length > 0
        ? props?.value?.map(p => {
            return (
              <span key={p} className="d-block text-left">
                {`${p.Function} (${p.DataSource})`}
              </span>
            )
          })
        : "-"}
    </>
  )
}
export const displayDataSourceSpan = (
  props: any,
  columnsRedux: SQLTestColumnSliceType["columnsRedux"]
) => {
  return (
    <div className="d-flex">
      {props?.value?.map((i, j) => (
        <div
          key={i}
          className="d-block text-left"
          style={{
            ...(!columnsRedux.columns?.some(i1 => i1?.ColumnName === i) && {
              color: "red"
            })
          }}
        >
          {i}
          {props.value.length !== j + 1 ? ", " : ""}
        </div>
      ))}
    </div>
  )
}

export const getColumnsData = ({
  updateSubtotal,
  deleteSubtotal,
  columnsRedux,
  totalType,
  t
}) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    setWidth: 250,
    Cell: props => {
      return displaySpan(props)
    }
  }

  const resultArr = [
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Sort),
      accessor: "SortOrder",
      setWidth: 50
    },

    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Label),
      accessor: "Label"
    },

    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Values),
      accessor: "AggregateConfigs",
      Cell: props => {
        return displayValuesSpan(props)
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      setWidth: 150,
      Cell: props => {
        const icons = [
          {
            title: getTranslations(t, Translates.Edit),
            clickFn: updateSubtotal,
            icon: (
              <img
                src={editIcon}
                alt={"edit icon"}
                className="pointer"
                data-testid="report-total-edit"
              />
            )
          },
          {
            title: getTranslations(t, Translates.Delete),
            clickFn: deleteSubtotal,
            icon: (
              <img
                src={trashIcon}
                alt="delete"
                className="pointer"
                data-testid="report-total-delete"
              />
            )
          }
        ]
        return (
          <>
            {icons.map((f, i) => {
              return (
                <>
                  <Tooltip arrow title={f.title} key={f.title}>
                    <IconButton onClick={() => f.clickFn(props.row)}>
                      <span className="text-center d-block">{f.icon}</span>
                    </IconButton>
                  </Tooltip>
                </>
              )
            })}
          </>
        )
      }
    }
  ]

  if (totalType === ConfigTabNames.SubTotals) {
    resultArr.splice(1, 0, {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Data_Group),
      accessor: "DataGroup",
      Cell: props => {
        return displayDataSourceSpan(props, columnsRedux)
      }
    })
  }

  return resultArr
}

export const setModalContent = ({ preDeletedRow, totalType, t }) => {
  let content = `${getTranslations(
    t,
    Translates.Are_you_sure_you_want_to_delete
  )} "${preDeletedRow?.Label}" ${
    totalType === ConfigTabNames.SubTotals
      ? getTranslations(t, Translates.Sub)
      : getTranslations(t, Translates.Grand)
  } ${getTranslations(t, Translates.Total)}?`
  return <AlertConfirmationContent warningMsg={content} />
}

export const getVariableBasedOnComponent = (
  componentName: string,
  totalType: string,
  tabId: number,
  totalId: number
) => {
  let variableSetRedux = setReportRedux
  let tableComponent = TableComponentNames.reportSubTotals
  let totalLabel = "SUB_TOTALS"
  const { reportRedux, reportsRedux } = store.getState().report
  const { multiEventOutputRedux, multiEventOutputsRedux } =
    store.getState().multiEventOutput
  let variableRedux: ConfigReportType | MultiEventOutputFormType = reportRedux
  let variablesRedux: any = reportsRedux
  let isPlacementField = false

  let createPath = PATH.TAB_CREATE_TOTAL_CREATE
  let editPath = PATH.TAB_CREATE_TOTAL_EDIT
  let redirectUrl = PATH.REPORT_CREATE
  if (tabId !== -1) {
    createPath = PATH.TAB_EDIT_TOTAL_CREATE
    editPath = PATH.TAB_EDIT_TOTAL_EDIT
  }

  if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Reports.toLocaleLowerCase()
  ) {
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    }
    if (
      totalType.toLocaleLowerCase() ===
      ConfigTabNames.GrandTotals.toLocaleLowerCase()
    ) {
      tableComponent = TableComponentNames.reportGrandTotals
      totalLabel = "GRAND_TOTALS"
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Outputs.toLocaleLowerCase()
  ) {
    variableRedux = reportRedux
    variablesRedux = reportsRedux
    variableSetRedux = setReportRedux
    tableComponent = TableComponentNames.outputSubTotals
    isPlacementField = true
    if (
      totalType.toLocaleLowerCase() ===
      ConfigTabNames.GrandTotals.toLocaleLowerCase()
    ) {
      tableComponent = TableComponentNames.outputGrandTotals
      totalLabel = "GRAND_TOTALS"
    }
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    } else {
      redirectUrl = PATH.REPORT_CREATE
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
  ) {
    variableSetRedux = setMultiEventOutputRedux
    variableRedux = multiEventOutputRedux
    variablesRedux = multiEventOutputsRedux
    tableComponent = TableComponentNames.multiEventOutputSubTotals
    isPlacementField = true
    if (
      totalType.toLocaleLowerCase() ===
      ConfigTabNames.SubTotals.toLocaleLowerCase()
    ) {
      totalLabel = "Event_Sub_Totals"
    } else if (
      totalType.toLocaleLowerCase() ===
      ConfigTabNames.GrandTotals.toLocaleLowerCase()
    ) {
      tableComponent = TableComponentNames.multiEventOutputGrandTotals
      totalLabel = "Event_Grand_Totals"
    }
    createPath = createPath.replace(
      multiEventOutputRouteReplaceVar.Configuration,
      multiEventOutputRouteReplaceVar.CreateSchedule
    )
    editPath = editPath.replace(
      multiEventOutputRouteReplaceVar.Configuration,
      multiEventOutputRouteReplaceVar.CreateSchedule
    )
    if (tabId !== -1) {
      redirectUrl = PATH.MULTI_EVENT_OUTPUT_EDIT.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    } else {
      redirectUrl = PATH.MULTI_EVENT_OUTPUT_CREATE.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    }
  }

  return {
    tableComponent,
    variableSetRedux,
    totalLabel,
    variableRedux,
    variablesRedux,
    isPlacementField,
    createPath,
    editPath,
    redirectUrl
  }
}
