import { formField } from "src/utils/constants"
import { renderType } from "./FcsOptions"
import { dailyFieldNames } from "../CreateEditMultiEventOutput"

export const dailyRadioOptions = {
  everyDay: "everyDay",
  everyWeekDay: "everyWeekDay"
}
export const dailyFields = [
  [
    {
      type: renderType.field,
      fieldType: formField.radio,
      width: "22px",
      props: {
        name: dailyFieldNames.WeekDayRadio.name,
        formControl: {
          radioGroup: {
            row: true,
            formControlLabels: [
              {
                value: dailyRadioOptions.everyWeekDay,
                label: ""
              }
            ].map(i => ({ ...i, radio: { size: "medium" }, className: "mr-5" }))
          }
        }
      },
      resetFieldNames: [{ name: dailyFieldNames.DayRadio.name, value: "" }],
      triggerValidationFields: [{ name: dailyFieldNames.DayInterval.name }]
    },
    {
      type: renderType.heading,
      heading: dailyFieldNames.WeekDayRadio.label,
      className: "ml-1"
    }
  ]
]
