import { Grid } from "@mui/material"
import React from "react"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import shortid from "shortid"
import { margin_left_find_for_row } from "../helpers"
import { functionTypes, ROW } from "../LRConstants"
import ReadOnlyRow from "./ReadOnlyRow"
import { CONFIG_MODULES } from "src/utils/constants"
import { setNewLookupRoutine } from "src/redux/slices/configSlice"
import { useDispatch } from "react-redux"
import { getTranslations, replaceAll } from "./../../../../utils/helper"
import { updateWarnOnTabChangeToStore } from "../../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
const ReadOnlyLayout = _props => {
  const { t } = useTranslation<any>()
  let { form, index, type, component, countFieldComponent, bConfig, disable } =
    _props
  const history = useHistory()
  const dispatch = useDispatch()
  let search = window.location.search

  const handleRedirectToNewLookup = () => {
    if (!disable) {
      if (form && form.Name) {
        let pathName = PATH.CREATE_LOOKUP
        if (component === CONFIG_MODULES.LookupRoutines) {
          pathName = PATH.CREATE_LOOKUP
          dispatch(setNewLookupRoutine(form))
        } else if (
          [
            CONFIG_MODULES.CountPrograms.setup,
            CONFIG_MODULES.CountPrograms.validation,
            CONFIG_MODULES.CountPrograms.Lookup
          ].includes(component)
        ) {
          pathName = PATH.COUNT_PROGRAM_INSTRUCTIONS
        } else if (
          [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
        ) {
          pathName = PATH.AUDITS_INSTRUCTIONS
        } else if (
          [
            CONFIG_MODULES.CountFieldInstructions.validation,
            CONFIG_MODULES.CountFieldInstructions.Lookup
          ].includes(component)
        ) {
          pathName = PATH.COUNT_FIELDS_INSTRUCTIONS
        }
        updateWarnOnTabChangeToStore(false)
        setTimeout(() => {
          history.push(pathName + search, {
            lookup: form,
            index: index,
            type: type,
            component: component,
            bConfig: bConfig,
            countFieldComponent: countFieldComponent
          })
        })
      }
    }
  }
  const renderIfStatement = (row, index) => {
    const row1 = {
      ...row,
      ...{
        type: ROW,
        label: row.function.toUpperCase(),
        parameters: []
      }
    }
    return (
      <>
        <ReadOnlyRow rowNo={index} data={row1} />
      </>
    )
  }
  const renderLayout = () => {
    let margin_left = 0
    return (
      <>
        {form.Instructions && form.Instructions.length ? (
          form.Instructions.map((row: any, index: number) => {
            const { ml, start } = margin_left_find_for_row(row, margin_left)
            margin_left = ml

            row = {
              ...row,
              ...{ ml: start ? margin_left - 1 : margin_left }
            }
            let isRowDisplay = true
            if (
              row?.function === functionTypes.Else &&
              form.Instructions[index + 1]?.function === functionTypes.Endif
            ) {
              isRowDisplay = false
            }
            let locContent = row?.content

            if (
              [CONFIG_MODULES.AuditRule, CONFIG_MODULES.Audits].includes(
                component
              )
            ) {
              locContent = replaceAll(row?.content, "IF", "")
              if (
                [functionTypes.Else, functionTypes.Endif].includes(
                  row?.function
                )
              ) {
                isRowDisplay = false
              }
            }

            if (isRowDisplay) {
              row = {
                ...row,
                ...{ content: locContent }
              }
              return (
                <React.Fragment key={shortid.generate()}>
                  {row.function === functionTypes.If ? (
                    renderIfStatement(row, index)
                  ) : (
                    <ReadOnlyRow key={row?.id} data={row} />
                  )}
                </React.Fragment>
              )
            } else {
              return <React.Fragment key={shortid.generate()}></React.Fragment>
            }
          })
        ) : (
          <>{getTranslations(t, Translates.Click_here_to_add_instructions)}</>
        )}
      </>
    )
  }
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={`white-bg pa-3 ${disable ? "opacity-50" : ""} ${
          disable || !form.Name ? "cursor-not-allowed" : "pointer"
        }`}
        onClick={handleRedirectToNewLookup}
        data-testid="renderLayout-grid"
      >
        {renderLayout()}
      </Grid>
    </Grid>
  )
}
export default ReadOnlyLayout
