import { useHistory, useLocation, useParams } from "react-router-dom"
import { useAppThunkDispatch } from "src/redux/store"
import { replaceAllMultiple } from "src/utils/helper"
import { createEditPromptFieldNames } from "../../Utils/Constants/CreateEditPromptConstant"
import { PromptTypes } from "src/utils/constants"
import { PromptLabelType } from "../../Utils/Types"
import {
  onCancelClick,
  setValueForVariable
} from "src/configurationPortal/pages/FunctionHelper"
import { getVariableBasedOnComponent } from "../../Utils/Helpers/PromptsGridHelper"
import FormSubmitCancelButtons from "../../../FormSubmitCancelButtons"

const FormSubmitCancel = () => {
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  let location = useLocation() as any
  const { id, tab, tabId }: { id: string; tab: string; tabId: string } =
    useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""

  let { variableSetRedux, variableRedux, redirectUrl, variablesRedux } =
    getVariableBasedOnComponent(tab, storeTabId, storeId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  let stateVariableRedux = location.state?.variableRedux
  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const handleSaveClick = data => {
    let Prompts = locVariableRedux.Prompts

    if (data[createEditPromptFieldNames.Type.name] !== PromptTypes.Decimal) {
      data[createEditPromptFieldNames.NumberOfDecimals.name] = 0
    }

    if (storeId <= -1) {
      Prompts = [
        ...Prompts,
        { ...data, Label: [{ "en-us": data.Label }] as PromptLabelType[] }
      ]
    } else {
      Prompts = Prompts?.map((i, j) => {
        return j === storeId - 1
          ? {
              ...i,
              ...data,
              ...{ Label: [{ "en-us": data.Label }] as PromptLabelType[] }
            }
          : i
      })
    }
    dispatch(
      variableSetRedux({
        ...locVariableRedux,
        Prompts: Prompts
      })
    )
    onCancelClick(history, redirectUrl)
  }
  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClick}
      redirectUrl={redirectUrl}
    />
  )
}

export default FormSubmitCancel
