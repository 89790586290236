import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { useTranslation } from "react-i18next"
import CountFieldsToolBar from "../../../../src/components/ConfigurationPortal/CountFieldsToolBar/CountFieldsToolBar"
import CountFieldsLocations from "./CountFieldsLocations"
import CountFieldsProducts from "./CountFieldsProducts"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { LocationsList, ProductsList, initCountFields } from "./Constants"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNames, updateWarnOnTabChangeToStore } from "../helper"
import { setReduxCountScreenTotals } from "src/redux/slices/configSlice"
import { setReduxProductKey } from "src/redux/slices/configSlice"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { getTranslations } from "src/utils/helper"

const CountFields = ({ liftCountFieldsSaveClick }) => {
  const { t } = useTranslation<any>()
  const dispatch = useDispatch()
  const {
    config: {
      countFields,
      defaultCountFields,
      reduxCountScreenTotals,
      reduxProductKey,
      configDetails,
      isGetConfigDecrypt
    }
  } = ConfigDataHook()
  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)

  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.CountFields,
    isTabChangeCheck: isTabChangeCheck
  })

  let fieldsObj =
    Object.keys(countFields).length === 0 ? defaultCountFields : countFields

  const [locations, setLocations] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [countScreenTotals, setCountScreenTotals] = useState(
    reduxCountScreenTotals
  )
  const [productKey, setProductKey] = useState(
    reduxProductKey
  )

  useEffect(() => {
    if (configDetails?.configuration && isGetConfigDecrypt) {
      const configObject = JSON.parse(configDetails.configuration)
      if (configObject.Fields && !Object.keys(configObject.Fields).length) {
        if (
          JSON.stringify(defaultCountFields) !== JSON.stringify(initCountFields)
        ) {
          setIsTabChangeCheck(true)
        } else {
          setIsTabChangeCheck(false)
          updateWarnOnTabChangeToStore(false)
        }
      }
    }
  }, [configDetails, isGetConfigDecrypt])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setCountScreenTotals(reduxCountScreenTotals)
      setProductKey(reduxProductKey)
    }
    return () => {
      isMounted = false
    }
  }, [reduxCountScreenTotals,reduxProductKey])

  useEffect(() => {
        setProductKey(reduxProductKey)
  }, [reduxProductKey])

  useEffect(() => {
    let locations: any = []
    let products: any = []
    for (let key in defaultCountFields) {
      if (
        [
          LocationsList.Location.OjKey,
          LocationsList.SecondaryLocation1.OjKey,
          LocationsList.SecondaryLocation2.OjKey,
          LocationsList.SecondaryLocation3.OjKey
        ].includes(key)
      ) {
        const obj: any = {
          ...LocationsList[key],
          ...defaultCountFields[key],
          field: key
        }

        locations.push(obj)
      } else {
        const obj: any = {
          ...ProductsList[key],
          ...defaultCountFields[key],
          field: key
        }

        products.push(obj)
      }
    }

    setLocations(locations)
    setProducts(products)
    
  }, [defaultCountFields, fieldsObj])

  const fieldsSaveClick = () => {
    updateWarnOnTabChangeToStore(false)
    liftCountFieldsSaveClick(
      { defaultCountFields, countScreenTotals, productKey},
      "CountFieldsTab"
    )
  }


  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    setCountScreenTotals(e.target.value)
    dispatch(setReduxCountScreenTotals(e.target.value))
   
  }

  const onFormChangeProductKey = e => {
    updateWarnOnTabChangeToStore(true)
    setProductKey(e.target.value)
    dispatch(setReduxProductKey(e.target.value))
  }
  return (
    <>
      <CountFieldsToolBar fieldsSaveClick={fieldsSaveClick} />
      <Grid container className="main-container">
        <Grid container item xs={12}>
          <Grid container className="main-container">
            <Grid item sm={12} className="d-flex align-items-center">
              <h6>{getTranslations(t, Translates.GENERAL)}</h6>
            </Grid>

            <Grid item sm={3} className="mt-3">
              <div className="text-box">
                <FormControl
                  variant="filled"
                  className="custom-form-control select"
                >
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    variant="filled"
                  >
                    {getTranslations(t, Translates.Count_screen_totals)}
                  </InputLabel>
                  <Select
                    data-testid="CFCSTotals"
                    id="demo-multiple-checkbox"
                    name="field"
                    value={countScreenTotals}
                    onChange={e => onFormChange(e)}
                    inputProps={{ "data-testid": "selecct-count-field" }}
                  >
                    {locations.map((i: any) => (
                      <MenuItem
                        key={i.field}
                        value={i.Labels[0]["en-us"]}
                        className="dropdown-list"
                      >
                        <small>{i.Labels[0]["en-us"]}</small>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item sm={1} className="mt-1"></Grid>

            <Grid item sm={3} className="mt-3">
              <div className="text-box">
                <FormControl
                  variant="filled"
                  className="custom-form-control select"
                >
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    variant="filled"
                  >
                      {getTranslations(t, Translates.Product_key)}
                  </InputLabel>
                  <Select
                    id="demo-multiple-checkbox1"
                    name="field1"
                    value={productKey}
                    onChange={e => onFormChangeProductKey(e)}
                    defaultValue={productKey}                
                  >
                     <MenuItem key={getTranslations(t, Translates.SKU)}  value={getTranslations(t, Translates.SKU)} className="dropdown-list">
                        <small> {getTranslations(t, Translates.SKU)}</small>
                     </MenuItem>
                     <MenuItem key={getTranslations(t, Translates.SKU_Barcode)}  value={getTranslations(t, Translates.SKU_Barcode)} className="dropdown-list">
                        <small> {getTranslations(t, Translates.SKU_Barcode)}</small>
                     </MenuItem>
                
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <CountFieldsLocations
            locations={locations}
            setIsTabChangeCheck={setIsTabChangeCheck}
          />
        </Grid>
        <Grid container item xs={12}>
          <CountFieldsProducts
            products={products}
            setIsTabChangeCheck={setIsTabChangeCheck}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CountFields
