import { useEffect, useReducer, useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import {
  getColumnsData,
  setModalContent
} from "../../Utils/Helpers/ReportsGridHelper"
import {
  ConfigReportPreDeletedRowType,
  ConfigReportType
} from "../../Utils/Types"
import { useAppThunkDispatch } from "src/redux/store"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setReportsRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import {
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import { PATH } from "src/constants/paths"

let preDeletedRow: ConfigReportPreDeletedRowType =
  {} as ConfigReportPreDeletedRowType
let showWarningModal: boolean = false

const ReportsGrid = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [, setReload] = useReducer(reducer, false)
  const dispatch = useAppThunkDispatch()

  const {
    reportData: { reportsRedux },
    config: { configType }
  } = ConfigDataHook()

  let search = window.location.search || ""

  const [reportsData, setReportsData] =
    useState<ConfigReportType[]>(reportsRedux)

  useEffect(() => {
    setReportsData([...reportsRedux])
  }, [reportsRedux])

  const getIndex = (name: string) =>
    reportsRedux.findIndex(item => item.Name === name)

  const copyReport = (row1: { original: ConfigReportType }) => {
    const row = row1.original
    const newItem: ConfigReportType = {
      ...row,
      Name: `Copy of ${row.Name}`
    }
    dispatch(setReportsRedux([...reportsRedux, newItem]))
  }

  const updateReport = (row1: {
    original: ConfigReportType
    index: number
  }) => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(
        PATH.REPORT_EDIT.replace(":id", `${getIndex(row1.original.Name) + 1}`) +
          search
      )
    })
  }

  const deleteReport = (row1: {
    original: ConfigReportType
    index: number
  }) => {
    showWarningModal = true
    preDeletedRow = { ...row1.original, index: getIndex(row1.original.Name) }
    setReload(oldVal => !oldVal)
  }

  const CallbackConfirmation = (res: boolean) => {
    if (res) {
      let dt: ConfigReportType[] = reportsRedux.filter(
        (f: ConfigReportType, inx) => inx !== preDeletedRow.index
      )
      dispatch(setReportsRedux([...dt]))
    }
    showWarningModal = false
    preDeletedRow = {} as ConfigReportPreDeletedRowType
    setReload(oldVal => !oldVal)
  }

  const onRowDrop = (sortedReportsData: any) => {
    // updating the store with updated row order, so it can be used while saving 
    dispatch(setReportsRedux(sortedReportsData))
  }

  const columnsData = getColumnsData({
    copyReport,
    updateReport,
    deleteReport,
    t,
    configTypeFromStore: configType
  })
  return (
    <Grid
      container
      className="custom-table-responsive categories-table"
      data-testid="reportings-display-grid"
    >
      <Grid xs={12} item>
        <GlobalTableDragDrop
          component={TableComponentNames.reportings}
          onRowDrop={onRowDrop}
          columns={columnsData}
          data={reportsData || []}
          noDataText={getTranslations(t, Translates.No_Reports_Available)}
        />
      </Grid>
      {showWarningModal && (
        <ConfirmDialog
          open={showWarningModal}
          callBack={CallbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setModalContent({ preDeletedRow, t })}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.CANCEL)}
        />
      )}
    </Grid>
  )
}

export default ReportsGrid
