import { Translates } from "src/i18n/i18n"
import { getBreadcrumbConfig } from "src/configurationPortal/pages/helper"
import { PATH } from "src/constants/paths"
import {
  decrypt,
  encrypt,
  getJSONParse,
  getTranslations
} from "src/utils/helper"
import { fileCSOptions } from "../Constants/FileCreationSchedule/FcsOptions"
import {
  dailyFields,
  dailyRadioOptions
} from "../Constants/FileCreationSchedule/Daily"
import {
  weeklyFields,
  weeklyFields1
} from "../Constants/FileCreationSchedule/Weekly"
import {
  monthlyFields,
  monthlyRadioOptions
} from "../Constants/FileCreationSchedule/Monthly"
import {
  yearlyFields,
  yearlyRadioOptions
} from "../Constants/FileCreationSchedule/Yearly"
import { systemDefaultFields } from "../Constants/FileCreationSchedule/SystemDefault"
import { MultiEventOutputFormType } from "../Types"
import {
  YDMOValues,
  createEditMultiEventOutputDefaultValues,
  weekDays
} from "../Constants/CreateEditMultiEventOutput"
import moment from "moment"
import {
  fieldDelimiterOptions,
  recordDelimiterOptions
} from "src/configurationPortal/pages/Outputs/Utils/Constants/CreateEditOutputConstant"
import { FILE_TYPE, PromptTypes } from "src/utils/constants"
import { OutputColumn } from "src/configurationPortal/pages/Components/Columns/Utils/Types"

const dbDateFormat = "YYYY-MM-DD"
export const getBreadcrumbPath = (storeId: number, t, nextPage = false) => {
  let componentName =
    storeId !== -1
      ? window.location.search.includes("view")
        ? getTranslations(t, Translates.View_multi_event_output)
        : getTranslations(t, Translates.Edit_multi_event_output)
      : getTranslations(t, Translates.New_multi_event_output)
  const { bConfig } = getBreadcrumbConfig({
    landingName: getTranslations(t, Translates.Multi_Event_Outputs),
    landingPath: `${PATH.CONFIGURATION}?tab=1`.slice(1),
    componentName: componentName,
    nextPage: nextPage,
    storeId: storeId,
    createPath: PATH.MULTI_EVENT_OUTPUT_CREATE,
    editPath: PATH.MULTI_EVENT_OUTPUT_EDIT,
    showHomeIcon: true
  })
  return { componentName, bConfig }
}

export const getScheduleFieldDetails = optionSelect => {
  let scheduleDetailFields: any = []
  let scheduleDetailFields1: any = {}
  let scheduleDetailFields1Heading: string = ""
  if ([fileCSOptions.Default].includes(optionSelect)) {
    scheduleDetailFields = systemDefaultFields
  } else if ([fileCSOptions.Daily].includes(optionSelect)) {
    scheduleDetailFields = dailyFields
  } else if ([fileCSOptions.Weekly].includes(optionSelect)) {
    scheduleDetailFields = weeklyFields
    scheduleDetailFields1 = weeklyFields1
    scheduleDetailFields1Heading = "Day_Of_Week"
  } else if ([fileCSOptions.Monthly].includes(optionSelect)) {
    scheduleDetailFields = monthlyFields
  } else if ([fileCSOptions.Yearly].includes(optionSelect)) {
    scheduleDetailFields = yearlyFields
  }
  return {
    scheduleDetailFields,
    scheduleDetailFields1,
    scheduleDetailFields1Heading
  }
}

export const getDefaultValues = (
  multiEventOutputRedux: MultiEventOutputFormType
) => {
  if (multiEventOutputRedux && Object.keys(multiEventOutputRedux).length) {
    return multiEventOutputRedux
  }
  return createEditMultiEventOutputDefaultValues
}

//start payload preparation

const getSystemDefaultPayload = (data: MultiEventOutputFormType) => {
  let multiEventDefaultSchedule = {
    minStores: 0,
    maxStores: 0
  }

  if ([fileCSOptions.Default].includes(data.FileCreationSchedule)) {
    multiEventDefaultSchedule = {
      ...multiEventDefaultSchedule,
      minStores: Number(data.SystemDefaultMinStore),
      maxStores: Number(data.SystemDefaultMaxStore)
    }
  }
  return multiEventDefaultSchedule
}

const getDailyPayload = (data: MultiEventOutputFormType) => {
  let multiEventDailySchedule = {
    repeatInterval: 0,
    repeatWeekdayFlag: true
  }

  if ([fileCSOptions.Daily].includes(data.FileCreationSchedule)) {
    multiEventDailySchedule = {
      ...multiEventDailySchedule,
      repeatInterval:
        data.DailyDayRadio === dailyRadioOptions.everyDay
          ? Number(data.DailyDayInterval)
          : 0,
      repeatWeekdayFlag:
        data.DailyWeekDayRadio === dailyRadioOptions.everyWeekDay
    }
  }
  return multiEventDailySchedule
}

const getWeeklyPayload = (data: MultiEventOutputFormType) => {
  let multiEventWeeklySchedule = {
    repeatInterval: 0,
    repeatWeekdayFlag: true,
    isSundayChecked: true,
    isMondayChecked: true,
    isTuesdayChecked: true,
    isWednesdayChecked: true,
    isThursdayChecked: true,
    isFridayChecked: true,
    isSaturdayChecked: true
  }

  if ([fileCSOptions.Weekly].includes(data.FileCreationSchedule)) {
    multiEventWeeklySchedule = {
      ...multiEventWeeklySchedule,
      repeatInterval: Number(data.WeeklyInterval),
      repeatWeekdayFlag: data.WeeklyIsWeekDays,
      isSundayChecked: data.WeeklySunday,
      isMondayChecked: data.WeeklyMonday,
      isTuesdayChecked: data.WeeklyTuesday,
      isWednesdayChecked: data.WeeklyWednesday,
      isThursdayChecked: data.WeeklyThursday,
      isFridayChecked: data.WeeklyFriday,
      isSaturdayChecked: data.WeeklySaturday
    }
  }
  return multiEventWeeklySchedule
}

const getMonthlyPayload = (data: MultiEventOutputFormType) => {
  let multiEventMonthlySchedule = {
    optionType: true,
    repeatInterval: 0,
    dayNumber: 0,
    weekNumber: 0,
    weekdayValue: ""
  }
  if ([fileCSOptions.Monthly].includes(data.FileCreationSchedule)) {
    let repeatInterval = multiEventMonthlySchedule.repeatInterval
    let dayNumber = multiEventMonthlySchedule.dayNumber
    let weekNumber = multiEventMonthlySchedule.weekNumber
    let weekdayValue = multiEventMonthlySchedule.weekdayValue

    if (data.MonthlyDayRadio === monthlyRadioOptions.day) {
      repeatInterval = Number(data.MonthlyDayMonthInterval)
      dayNumber = Number(data.MonthlyDayNumber)
    } else if (data.MonthlyWeekRadio === monthlyRadioOptions.week) {
      repeatInterval = Number(data.MonthlyWeekMonthInterval)
      weekNumber = Number(data.MonthlyWeekNumber)
      weekdayValue = data.MonthlyWeekDay
    }

    multiEventMonthlySchedule = {
      ...multiEventMonthlySchedule,
      optionType: data.MonthlyDayRadio === monthlyRadioOptions.day,
      repeatInterval: repeatInterval,
      dayNumber: dayNumber,
      weekNumber: weekNumber,
      weekdayValue: weekdayValue
    }
  }
  return multiEventMonthlySchedule
}

const getYearlyPayload = (data: MultiEventOutputFormType) => {
  let multiEventYearlySchedule = {
    optionType: true,
    repeatInterval: 0,
    dayNumber: 0,
    yearlyMonthName: "",
    weekNumber: 0,
    weekdayValue: ""
  }

  if ([fileCSOptions.Yearly].includes(data.FileCreationSchedule)) {
    let dayNumber = multiEventYearlySchedule.dayNumber
    let monthName = multiEventYearlySchedule.yearlyMonthName
    let weekNumber = multiEventYearlySchedule.weekNumber
    let weekdayValue = multiEventYearlySchedule.weekdayValue

    if (data.YearlyDayMonthRadio === yearlyRadioOptions.day) {
      dayNumber = Number(data.YearlyDayMonthDayNumber)
      monthName = data.YearlyDayMonth
    } else if (data.YearlyWeekMonthRadio === yearlyRadioOptions.week) {
      weekNumber = Number(data.YearlyWeekMonthWeekNumber)
      weekdayValue = data.YearlyWeekMonthWeekDay
      monthName = data.YearlyWeekMonth
    }

    multiEventYearlySchedule = {
      ...multiEventYearlySchedule,
      optionType: data.YearlyDayMonthRadio === yearlyRadioOptions.day,
      repeatInterval: Number(data.YearlyYearInterval),
      dayNumber: dayNumber,
      yearlyMonthName: monthName,
      weekNumber: weekNumber,
      weekdayValue: weekdayValue
    }
  }
  return multiEventYearlySchedule
}

const getColumnsPayload = (data: MultiEventOutputFormType) => {
  return data.Columns.map((i, j) => {
    return {
      idMultiEventSqlColumnDetails: i.DataBaseId ?? 0,
      source: i.DataSource,
      columnName: i.ColumnName,
      dataType: i.DataType,
      dataTypeDetails:
        i.DataType === PromptTypes.Decimal
          ? {
              dataType: i.DataType,
              decimalPlaces: i.NumberOfDecimals ?? 0,
              currency:
                !i.CurrencySymbol || i.CurrencySymbol === ""
                  ? null
                  : i.CurrencySymbol,
              originalCurrencySymbol:
                !i.OriginalCurrencySymbol || i.OriginalCurrencySymbol === ""
                  ? null
                  : i.CurrencySymbol
            }
          : null,
      padding: i.Padding.Format,
      paddingDetails: [PromptTypes.LEFT, PromptTypes.RIGHT].includes(
        i.Padding.Format
      )
        ? {
            padding: i.Padding.Format,
            length: i.Padding.Length,
            character: i.Padding.Character
          }
        : null,
      surroundCharacter: i.SurroundCharacter,
      columnOrder: j + 1
    }
  })
}

export const getDateAndTimeMerge = (time: Date, date: Date) => {
  const timeMoment = moment(time)
  const dateMoment = moment(date)

  const hours = timeMoment.hours()
  const minutes = timeMoment.minutes()
  const seconds = timeMoment.seconds()
  const milliseconds = timeMoment.milliseconds()

  const formattedDate = dateMoment.format(dbDateFormat)

  const mergedDateTime = new Date(
    `${formattedDate} ${hours}:${minutes}:${seconds}:${milliseconds}`
  )
  return mergedDateTime.toISOString()
}

const getDayNumber = (dayName: string) => {
  const daysOfWeek = Object.keys(weekDays)
  const dayIndex = daysOfWeek.indexOf(dayName)
  return dayIndex
}

const getMonthNumber = (monthName: string) => {
  const monthsOfYear = Object.keys(YDMOValues)
  const monthIndex = monthsOfYear.indexOf(monthName)
  return monthIndex + 1
}

const todayConsiderConditionCheck = (Time: Date) => {
  const givenDatetime = moment(
    convertUTCToLocalDate(getDateAndTimeMerge(Time, new Date()))
  )
  const currentDate = moment(new Date())
  const minutesDifference = givenDatetime.diff(currentDate, "minutes")
  return minutesDifference > 15
}

const getWeekNumberInMonth = (date: Date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
  const daysOffset = firstDayOfMonth.getDay() // 0 (Sunday) to 6 (Saturday)
  const dayOfMonth = date.getDate()
  const NUMBER_OF_DAYS_IN_WEEK = 7
  return Math.ceil((daysOffset + dayOfMonth) / NUMBER_OF_DAYS_IN_WEEK)
}

const getUpcomingDateByDay = (date: Date, day: string) => {
  const currentDay = new Date(date).getDay()
  const targetDayIndex = getDayNumber(day)
  const NUMBER_OF_DAYS_IN_WEEK = 7

  const daysUntilNextDay =
    targetDayIndex >= currentDay
      ? targetDayIndex - currentDay
      : NUMBER_OF_DAYS_IN_WEEK - currentDay + targetDayIndex

  const nextDay = new Date(date)
  nextDay.setDate(date.getDate() + daysUntilNextDay)
  return nextDay.toISOString().split("T")[0]
}

const getDateFromDateNumber = (
  inputNumber: number,
  isTodayConsider: boolean
) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1 // Months are 0-based in JavaScript (0-Jan, 1-Feb, etc.)
  const MONTHS_IN_YEAR = 12
  const PADDING_START = 2

  let targetYear = currentYear
  let targetMonth = currentMonth

  // Check if the input number is in the past
  if (inputNumber <= today.getDate()) {
    // If it's in the past, set the target month to the next month
    let isGoForNextMonth = true
    //istanbul ignore next
    if (inputNumber === today.getDate() && isTodayConsider) {
      isGoForNextMonth = false
    }

    if (isGoForNextMonth) {
      targetMonth++
      //istanbul ignore next
      if (targetMonth > MONTHS_IN_YEAR) {
        targetMonth = 1
        targetYear++
      }
    }
  }
  // Construct the formatted date string
  return `${targetYear}-${targetMonth
    .toString()
    .padStart(PADDING_START, "0")}-${inputNumber
    .toString()
    .padStart(PADDING_START, "0")}`
}

const getDateFromDateNumberAndMonth = (
  monthNumber: number,
  dateNumber: number,
  isTodayConsider: boolean
) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1 // Months are 0-based in JavaScript (0-Jan, 1-Feb, etc.)
  const currentDate = today.getDate()
  let targetYear = currentYear
  const PADDING_START = 2

  //istanbul ignore next
  if (
    currentMonth > monthNumber ||
    (currentMonth === monthNumber &&
      (currentDate > dateNumber ||
        (currentDate === dateNumber && !isTodayConsider)))
  ) {
    targetYear++
  }

  // Construct the formatted date string

  return `${targetYear}-${monthNumber
    .toString()
    .padStart(PADDING_START, "0")}-${dateNumber
    .toString()
    .padStart(PADDING_START, "0")}`
}

const getDateFromYearMonthWeekDay = (
  year: number,
  month: number,
  week: number,
  dayOfWeek: number
) => {
  const firstDayOfMonth = new Date(year, month - 1, 1)
  const firstDayOfWeek = firstDayOfMonth.getDay()
  const NUMBER_OF_DAYS_IN_WEEK = 7
  const PADDING_START = 2
  const offset =
    (dayOfWeek - firstDayOfWeek + NUMBER_OF_DAYS_IN_WEEK) %
    NUMBER_OF_DAYS_IN_WEEK
  const targetDate = offset + 1 + (week - 1) * NUMBER_OF_DAYS_IN_WEEK

  //istanbul ignore next
  if (targetDate <= 0 || targetDate > new Date(year, month, 0).getDate()) {
    return "" // Invalid week or day of the week
  }

  // Construct the formatted date string

  return `${year}-${month.toString().padStart(PADDING_START, "0")}-${targetDate
    .toString()
    .padStart(PADDING_START, "0")}`
}

const getDateFromWeekDay = (
  week: number,
  dayOfWeek: number,
  isTodayConsider: boolean
) => {
  const currentDate = new Date()
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1 // Adding 1 because months are 0-indexed in JavaScript
  const currentWeek = getWeekNumberInMonth(new Date())
  const currentWeekDay = currentDate.getDay()
  const MONTHS_IN_YEAR = 12

  let isGoForNextMonth = false
  //istanbul ignore next
  if (
    currentWeek > week ||
    (currentWeek === week &&
      (currentWeekDay > dayOfWeek ||
        (currentWeekDay === dayOfWeek && !isTodayConsider)))
  ) {
    isGoForNextMonth = true
  }

  if (isGoForNextMonth) {
    month++
    //istanbul ignore next
    if (month > MONTHS_IN_YEAR) {
      month = 1
      year++
    }
  }

  return getDateFromYearMonthWeekDay(year, month, week, dayOfWeek)
}

const getDateFromMonthWeekDay = (
  month: number,
  week: number,
  dayOfWeek: number,
  isTodayConsider: boolean
) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1 // Adding 1 because months are 0-indexed in JavaScript
  const currentWeek = getWeekNumberInMonth(new Date())
  const currentWeekDay = currentDate.getDay()

  let targetYear = currentYear
  //istanbul ignore next
  if (
    currentMonth > month ||
    (currentMonth === month &&
      (currentWeek > week ||
        (currentWeek === week &&
          (currentWeekDay > dayOfWeek ||
            (currentWeekDay === dayOfWeek && !isTodayConsider)))))
  ) {
    targetYear++
  }

  return getDateFromYearMonthWeekDay(targetYear, month, week, dayOfWeek)
}

const getNextScheduleDateForDaily = ({ data, startDate, todayORTomorrow }) => {
  const SUNDAY_INDEX = 0
  const SATURDAY_INDEX = 6
  //istanbul ignore next
  let date = data.IsSpecificDateRange ? startDate : todayORTomorrow
  //istanbul ignore next
  if (
    data.DailyWeekDayRadio === dailyRadioOptions.everyWeekDay &&
    [SUNDAY_INDEX, SATURDAY_INDEX].includes(new Date(date).getDay())
  ) {
    date = getUpcomingDateByDay(new Date(date), weekDays.Monday)
  }

  return date
}

const getNextScheduleDateForWeekly = ({ data, startDate, todayORTomorrow }) => {
  const daysOfWeekSelected = [
    data.WeeklySunday,
    data.WeeklyMonday,
    data.WeeklyTuesday,
    data.WeeklyWednesday,
    data.WeeklyThursday,
    data.WeeklyFriday,
    data.WeeklySaturday
  ]
  //istanbul ignore next
  const startIndexDate = data.IsSpecificDateRange ? startDate : todayORTomorrow
  const startIndex = new Date(startIndexDate).getDay()

  let indexOfTrue = daysOfWeekSelected.indexOf(true, startIndex)

  if (indexOfTrue === -1) {
    indexOfTrue = daysOfWeekSelected.indexOf(true)
  }

  return getUpcomingDateByDay(
    new Date(startIndexDate),
    Object.keys(weekDays)[indexOfTrue]
  )
}

const getNextScheduleDateForMonthly = ({ data, isTodayConsider }) => {
  if (data.MonthlyDayRadio === monthlyRadioOptions.day) {
    return getDateFromDateNumber(Number(data.MonthlyDayNumber), isTodayConsider)
  } else if (data.MonthlyWeekRadio === monthlyRadioOptions.week) {
    return getDateFromWeekDay(
      Number(data.MonthlyWeekNumber),
      getDayNumber(data.MonthlyWeekDay),
      isTodayConsider
    )
  }

  return ""
}

const getNextScheduleDateForYearly = ({ data, isTodayConsider }) => {
  if (data.YearlyDayMonthRadio === yearlyRadioOptions.day) {
    return getDateFromDateNumberAndMonth(
      getMonthNumber(data.YearlyDayMonth),
      Number(data.YearlyDayMonthDayNumber),
      isTodayConsider
    )
  } else if (data.YearlyWeekMonthRadio === yearlyRadioOptions.week) {
    return getDateFromMonthWeekDay(
      getMonthNumber(data.YearlyWeekMonth),
      Number(data.YearlyWeekMonthWeekNumber),
      getDayNumber(data.YearlyWeekMonthWeekDay),
      isTodayConsider
    )
  }
  return ""
}
const getDateForNextSchedule = (
  data: MultiEventOutputFormType,
  startDate,
  todayORTomorrow,
  isTodayConsider,
  storeId
) => {
  let date: string | Date = ""
  let startDateCondition = startDate
  if (
    storeId !== -1 &&
    data.IsSpecificDateRange &&
    data.NextScheduleRunEventDateTime &&
    data.LastScheduleRunEventDateTime
  ) {
    if (
      data.StartDate < data.NextScheduleRunEventDateTime &&
      data.NextScheduleRunEventDateTime <= data.EndDate
    ) {
      startDateCondition = data.NextScheduleRunEventDateTime
    } else if (data.NextScheduleRunEventDateTime > data.EndDate) {
      startDateCondition = data.LastScheduleRunEventDateTime
    }
  }
  if ([fileCSOptions.Default].includes(data.FileCreationSchedule)) {
    date = data.IsSpecificDateRange ? startDateCondition : todayORTomorrow
  } else if ([fileCSOptions.Daily].includes(data.FileCreationSchedule)) {
    date = getNextScheduleDateForDaily({
      data,
      todayORTomorrow,
      startDate: startDateCondition
    })
  } else if ([fileCSOptions.Weekly].includes(data.FileCreationSchedule)) {
    date = getNextScheduleDateForWeekly({
      data,
      todayORTomorrow,
      startDate: startDateCondition
    })
  } else if ([fileCSOptions.Monthly].includes(data.FileCreationSchedule)) {
    date = getNextScheduleDateForMonthly({ data, isTodayConsider })
  } else if ([fileCSOptions.Yearly].includes(data.FileCreationSchedule)) {
    date = getNextScheduleDateForYearly({ data, isTodayConsider })
  } else if ([fileCSOptions.Reservation].includes(data.FileCreationSchedule)) {
    date = todayORTomorrow
  }
  return date
}

const getNextScheduleDate = (
  data: MultiEventOutputFormType,
  storeId: number
) => {
  let nextScheduledRunEventDateTime = ""
  const isTodayConsider = todayConsiderConditionCheck(data.Time)
  const todayORTomorrow = moment(new Date()).add(
    isTodayConsider ? 0 : 1,
    "days"
  )
  let startDate: string | Date = ""
  if (data.StartDate) {
    startDate = convertUTCToLocalDate(
      getDateAndTimeMerge(data.Time, data.StartDate)
    )
  }
  const date = getDateForNextSchedule(
    data,
    startDate,
    todayORTomorrow,
    isTodayConsider,
    storeId
  )
  if (date) {
    nextScheduledRunEventDateTime = getDateAndTimeMerge(
      new Date(data.Time),
      new Date(date)
    )
  }
  return nextScheduledRunEventDateTime
}

const getSubTotalsPayload = (data: MultiEventOutputFormType) => {
  return data.SubTotals.map(i => ({
    dataGroup: i.DataGroup,
    label: i.Label,
    aggregateConfigs: i.AggregateConfigs.map(i1 => ({
      dataSource: i1.DataSource,
      column: i1.Column,
      function: i1.Function
    })),
    placement: i.Placement
  }))
}

const getGrandTotalsPayload = (data: MultiEventOutputFormType) => {
  return data.GrandTotals.map(i => ({
    label: i.Label,
    aggregateConfigs: i.AggregateConfigs.map(i1 => ({
      dataSource: i1.DataSource,
      column: i1.Column,
      function: i1.Function
    })),
    placement: i.Placement
  }))
}

const getTime = (now = new Date()) => {
  const hours = String(now.getUTCHours()).padStart(2, "0")
  const minutes = String(now.getUTCMinutes()).padStart(2, "0")
  const seconds = String(now.getUTCSeconds()).padStart(2, "0")

  return `${hours}:${minutes}:${seconds}`
}

export const getPayload = async (
  data: MultiEventOutputFormType,
  storeId: number
) => {
  const multiEventDefaultSchedule = getSystemDefaultPayload(data)
  const multiEventDailySchedule = getDailyPayload(data)
  const multiEventWeeklySchedule = getWeeklyPayload(data)
  const multiEventMonthlySchedule = getMonthlyPayload(data)
  const multiEventYearlySchedule = getYearlyPayload(data)
  const columns = getColumnsPayload(data)
  const subTotals = getSubTotalsPayload(data)
  const grandTotals = getGrandTotalsPayload(data)
  const nextScheduledRunEventDateTime = getNextScheduleDate(data, storeId)
  let startDate: string | null = null
  let endDate: string | null = null
  if (
    [fileCSOptions.Default, fileCSOptions.Daily, fileCSOptions.Weekly].includes(
      data.FileCreationSchedule
    ) &&
    data.IsSpecificDateRange
  ) {
    startDate = getDateAndTimeMerge(
      new Date(data.Time),
      new Date(data.StartDate)
    )
    endDate = getDateAndTimeMerge(new Date(data.Time), new Date(data.EndDate))
  }
  const isDelimiter =
    data.FileType.toLocaleLowerCase() === FILE_TYPE.Text.toLocaleLowerCase()
  const payload = {
    idMultiEventFileDetails: storeId !== -1 ? storeId : 0,
    name: data.Name,
    description: data.Description,
    fileName: data.FileName,
    fileType: data.FileType,
    fieldDelimiter: isDelimiter ? data.FieldDelimiter : null,
    fieldDelimiterOthers:
      isDelimiter && data.FieldDelimiter === FILE_TYPE.Other
        ? data.OtherFieldDelimiter
        : null,
    recordDelimiter: isDelimiter ? data.RecordDelimiter : null,
    recordDelimiterOthers:
      isDelimiter && data.RecordDelimiter === FILE_TYPE.Other
        ? data.OtherRecordDelimiter
        : null,
    recordDelimiterASCIICharDetails:
      isDelimiter && data.RecordDelimiter === FILE_TYPE.ASCIICharValue
        ? {
            recordDelimiter: data.RecordDelimiter,
            recordDelimiterASCIIChar: data.AsciiCharValue
          }
        : null,
    hasHeaderRecord: data.HasHeaderRecord,
    columnsToSuppress: data.ColumnsToSuppress,
    sqlText: await encrypt(data.RawSQL),
    eventSubTotalValue: subTotals,
    eventGrandTotalValue: grandTotals,
    idConfig: data.ConfigurationIncludes,
    isFileDestinationCustomerPortal: data.Destination.CustomerPortalCheck,
    fileDestinationEmailId: data.Destination.Email.join(","),
    fileDestinationFtpDirectory: JSON.stringify(data.Destination.FtpDirectory),
    fileCreationScheduleType: data.FileCreationSchedule,
    scheduleStartDate: startDate,
    scheduleEndDate: endDate,
    fileCreationScheduleTime: getTime(data.Time),
    nextScheduledRunEventDateTime,
    multiEventDefaultSchedule,
    multiEventDailySchedule,
    multiEventWeeklySchedule,
    multiEventMonthlySchedule,
    multiEventYearlySchedule,
    columns
  }

  return { payload }
}

//end payload preparation
const generalFormConversion = apiResObj => {
  const {
    fieldDelimiter,
    recordDelimiter,
    name,
    description,
    fileName,
    fileType,
    hasHeaderRecord
  } = apiResObj

  const parsedRecordDelimiter: any = getJSONParse(recordDelimiter)

  const isRecordDelimiterString = typeof parsedRecordDelimiter === "string"

  // Helper function to check if the delimiter is in a predefined list
  const isDelimiterInList = (delimiter, delimiterOptions) =>
    delimiterOptions.some(i => i.label === delimiter) || !delimiter

  // Helper function to get the Record Delimiter Text Value
  const getRecordDelimiterTextValue = () => {
    if (!recordDelimiter) {
      return ""
    }
    return isRecordDelimiterString
      ? parsedRecordDelimiter
      : parsedRecordDelimiter.RecordDelimiter
  }

  // Extracted conditions for better readability
  const isFieldDelimiterNotOther = isDelimiterInList(
    fieldDelimiter,
    fieldDelimiterOptions
  )

  const recordDelimiterTextValue = getRecordDelimiterTextValue()
  const isRecordDelimiterNotOther = isDelimiterInList(
    recordDelimiterTextValue,
    recordDelimiterOptions
  )

  return {
    Name: name,
    Description: description,
    FileName: fileName,
    FileType: fileType,
    FieldDelimiter: isFieldDelimiterNotOther ? fieldDelimiter : FILE_TYPE.Other,
    OtherFieldDelimiter: isFieldDelimiterNotOther ? "" : fieldDelimiter,
    RecordDelimiter: isRecordDelimiterNotOther
      ? recordDelimiterTextValue
      : FILE_TYPE.Other,
    OtherRecordDelimiter: isRecordDelimiterNotOther
      ? ""
      : recordDelimiterTextValue,
    AsciiCharValue:
      !isRecordDelimiterString &&
      recordDelimiterTextValue === FILE_TYPE.ASCIICharValue
        ? parsedRecordDelimiter.RecordDelimiterASCIIChar
        : "",
    HasHeaderRecord: hasHeaderRecord
  }
}

export const convertUTCToLocalDate = dateString =>
  dateString ? moment.utc(dateString).toDate() : ""

const defaultFormConversion = apiResObj => {
  return {
    ...(apiResObj.fileCreationScheduleType === fileCSOptions.Default && {
      SystemDefaultMinStore: apiResObj.minStores,
      SystemDefaultMaxStore: apiResObj.maxStores,
      IsSpecificDateRange: !!apiResObj.defaultScheduleStartDate,
      StartDate: convertUTCToLocalDate(apiResObj.defaultScheduleStartDate),
      EndDate: convertUTCToLocalDate(apiResObj.defaultScheduleEndDate)
    })
  }
}

const dailyFormConversion = apiResObj => {
  return {
    ...(apiResObj.fileCreationScheduleType === fileCSOptions.Daily && {
      DailyDayRadio: apiResObj.dailyRepeatWeekdayFlag
        ? ""
        : dailyRadioOptions.everyDay,
      DailyDayInterval: apiResObj.dailyRepeatWeekdayFlag
        ? ""
        : apiResObj.dailyRepeatInterval,
      DailyWeekDayRadio: apiResObj.dailyRepeatWeekdayFlag
        ? dailyRadioOptions.everyWeekDay
        : "",
      IsSpecificDateRange: !!apiResObj.dailyScheduleStartDate,
      StartDate: convertUTCToLocalDate(apiResObj.dailyScheduleStartDate),
      EndDate: convertUTCToLocalDate(apiResObj.dailyScheduleEndDate)
    })
  }
}

const weeklyFormConversion = apiResObj => {
  return {
    ...(apiResObj.fileCreationScheduleType === fileCSOptions.Weekly && {
      WeeklyInterval: apiResObj.weeklyRepeatInterval,
      WeeklyIsWeekDays: !!apiResObj.weeklyRepeatWeekdayFlag,
      WeeklySunday: !!apiResObj.isSundayChecked,
      WeeklyMonday: !!apiResObj.isMondayChecked,
      WeeklyTuesday: !!apiResObj.isTuesdayChecked,
      WeeklyWednesday: !!apiResObj.isWednesdayChecked,
      WeeklyThursday: !!apiResObj.isThursdayChecked,
      WeeklyFriday: !!apiResObj.isFridayChecked,
      WeeklySaturday: !!apiResObj.isSaturdayChecked,
      IsSpecificDateRange: !!apiResObj.weeklyScheduleStartDate,
      StartDate: convertUTCToLocalDate(apiResObj.weeklyScheduleStartDate),
      EndDate: convertUTCToLocalDate(apiResObj.weeklyScheduleEndDate)
    })
  }
}

const monthlyFormConversion = apiResObj => {
  const {
    monthlyOptionType,
    monthlyDayNumber,
    monthlyRepeatInterval,
    monthlyWeekNumber,
    monthlyWeekdayValue,
    monthlyScheduleStartDate,
    monthlyScheduleEndDate,
    fileCreationScheduleType
  } = apiResObj

  // Extracted conditions for better readability
  const isMonthly = fileCreationScheduleType === fileCSOptions.Monthly
  const isDayOption = monthlyOptionType && isMonthly
  const isWeekOption = !monthlyOptionType && isMonthly

  return {
    ...(isMonthly && {
      MonthlyDayRadio: isDayOption ? monthlyRadioOptions.day : "",
      MonthlyDayNumber: isDayOption ? monthlyDayNumber : "",
      MonthlyDayMonthInterval: isDayOption ? monthlyRepeatInterval : "",
      MonthlyWeekRadio: isWeekOption ? monthlyRadioOptions.week : "",
      MonthlyWeekNumber: isWeekOption ? `${monthlyWeekNumber}` : "",
      MonthlyWeekDay: isWeekOption ? monthlyWeekdayValue : "",
      MonthlyWeekMonthInterval: isWeekOption ? "" : monthlyRepeatInterval,
      IsSpecificDateRange: !!monthlyScheduleStartDate,
      StartDate: convertUTCToLocalDate(monthlyScheduleStartDate),
      EndDate: convertUTCToLocalDate(monthlyScheduleEndDate)
    })
  }
}

const yearlyFormConversion = apiResObj => {
  // Destructure constants for better readability
  const {
    yearlyRepeatInterval,
    yearlyOptionType,
    yearlyMonthName,
    yearlyDayNumber,
    yearlyWeekNumber,
    yearlyWeekdayValue,
    yearlyScheduleStartDate,
    yearlyScheduleEndDate,
    fileCreationScheduleType
  } = apiResObj

  // Extracted conditions for better readability
  const isYearly = fileCreationScheduleType === fileCSOptions.Yearly
  const isDayOption = yearlyOptionType && isYearly
  const isWeekOption = !yearlyOptionType && isYearly

  return {
    ...(isYearly && {
      YearlyYearInterval: yearlyRepeatInterval,
      YearlyDayMonthRadio: isDayOption ? yearlyRadioOptions.day : "",
      YearlyDayMonth: isDayOption ? yearlyMonthName : "",
      YearlyDayMonthDayNumber: isDayOption ? yearlyDayNumber : "",
      YearlyWeekMonthRadio: isWeekOption ? yearlyRadioOptions.week : "",
      YearlyWeekMonthWeekNumber: isWeekOption ? `${yearlyWeekNumber}` : "",
      YearlyWeekMonthWeekDay: isWeekOption ? yearlyWeekdayValue : "",
      YearlyWeekMonth: isWeekOption ? yearlyMonthName : "",
      IsSpecificDateRange: !!yearlyScheduleStartDate,
      StartDate: convertUTCToLocalDate(yearlyScheduleStartDate),
      EndDate: convertUTCToLocalDate(yearlyScheduleEndDate)
    })
  }
}

const subtotalsFormConversion = apiResObj => {
  const subTotals = getJSONParse(apiResObj.eventSubTotal)
  return {
    SubTotals: subTotals.map(i => ({
      DataGroup: i.DataGroup,
      Label: i.Label,
      AggregateConfigs: i.AggregateConfigs,
      Placement: i.Placement
    }))
  }
}

const grandTotalsFormConversion = apiResObj => {
  const grandTotals = getJSONParse(apiResObj.eventGrandTotal)
  return {
    GrandTotals: grandTotals.map(i => ({
      Label: i.Label,
      AggregateConfigs: i.AggregateConfigs,
      Placement: i.Placement
    }))
  }
}

export const formDataPreparation = async (
  apiResObj
): Promise<{ formData: MultiEventOutputFormType }> => {
  const sqlText = await decrypt(apiResObj.sqlText)
  const formData = {
    ...createEditMultiEventOutputDefaultValues,
    ...{
      RecordId: apiResObj.idMultiEventFileDetail,
      Customers: [],
      ConfigurationIncludes: apiResObj.configDetails?.map(i =>
        Number(i.idConfig)
      ),
      //General settings start
      ...generalFormConversion(apiResObj),
      //General settings end
      RawSQL: sqlText,
      SQL: sqlText,
      Columns: apiResObj.columsDetails?.map(i => {
        const dataType: any = i.dataType ? getJSONParse(i.dataType) : ""
        const padding: any = i.padding ? getJSONParse(i.padding) : ""
        const isPaddingString = typeof (padding || "") === "string"
        const isDataTypeString = typeof (dataType || "") === "string"

        return {
          DataBaseId: Number(i.idMultiEventSqlColumnDetails),
          DataSource: i.source,
          ColumnName: i.columnName,
          DataType: isDataTypeString
            ? dataType.toLocaleUpperCase()
            : dataType.DataType.toLocaleUpperCase(),
          NumberOfDecimals: isDataTypeString ? 0 : dataType.DecimalPlaces,
          CurrencySymbol: isDataTypeString ? "" : dataType.Currency || "",
          OriginalCurrencySymbol: isDataTypeString
            ? ""
            : dataType.OriginalCurrencySymbol || "",
          Padding: {
            Format: isPaddingString ? padding : padding.Padding,
            Length: isPaddingString ? 0 : padding.Length,
            Character: isPaddingString ? "" : padding.Character
          },
          SurroundCharacter: i.surroundCharacter
        } as OutputColumn
      }),
      ColumnsToSuppress: apiResObj.columnsToSuppress,
      //Subtotals settings start
      ...(apiResObj.eventSubTotal && {
        ...subtotalsFormConversion(apiResObj)
      }),
      //Subtotals settings start
      //GrandTotals settings start
      ...(apiResObj.eventGrandTotal && {
        ...grandTotalsFormConversion(apiResObj)
      }),
      //GrandTotals settings start
      //File creation schedule fields start
      FileCreationSchedule: apiResObj.fileCreationScheduleType,

      //System Default radio option fields start
      ...defaultFormConversion(apiResObj),
      //System Default fields end

      //Daily radio option fields start
      ...dailyFormConversion(apiResObj),
      //Daily radio option fields end

      //Weekly radio option fields start
      ...weeklyFormConversion(apiResObj),
      //Weekly radio option fields end

      //Monthly radio option fields start
      ...monthlyFormConversion(apiResObj),
      //Monthly radio option fields end

      //Yearly radio option fields start
      ...yearlyFormConversion(apiResObj),
      //Yearly radio option fields end

      Time: convertUTCToLocalDate(
        getDateAndTimeMerge(
          convertUTCToLocalDate(apiResObj.nextScheduleRunEventDateTime) ||
            new Date(),
          new Date()
        )
      ),

      //File creation schedule radio option fields end
      Destination: {
        CustomerPortalCheck: apiResObj.isFileDestinationCustomerPortal,
        FtpDirectory: getJSONParse(apiResObj.fileDestinationFtpDirectory),
        Email: apiResObj.fileDestinationEmailID
          ? apiResObj.fileDestinationEmailID?.split(",")
          : []
      },
      NextScheduleRunEventDateTime: convertUTCToLocalDate(
        apiResObj.nextScheduleRunEventDateTime
      ),
      LastScheduleRunEventDateTime: convertUTCToLocalDate(
        apiResObj.lastScheduleRunEventDateTime
      )
    }
  } as MultiEventOutputFormType
  return { formData }
}

export const selectedCustomerSortByName = sCustomer => {
  return (sCustomer || [])
    .map(i => ({
      name: i.name
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}
