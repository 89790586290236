import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { getTabLabel } from "src/configurationPortal/pages/FunctionHelper"
import { createEditSignatureDefaultIniValues } from "../Constants/CreateEditSignatureConstant"
import { SignatureType } from "../Types"

export const getDefaultValues = (
  Signatures: SignatureType[],
  storeId: number
) => {
  if (Signatures.length && storeId > -1 && Signatures[storeId - 1]) {
    return {
      ...Signatures[storeId - 1]
    }
  }
  return createEditSignatureDefaultIniValues
}

export const getBreadcrumbConfig = (t, tab, storeId) => {
  const currentPath = "column"
  const createOrEdit =
    storeId > -1 ? Translates.Edit_Signature : Translates.New_Signature

  const { locTab } = getTabLabel(tab)
  const variables: any = {
    column: `${getTranslations(t, locTab)} ${getTranslations(t, createOrEdit)}`
  }
  const bConfig: any = {
    currentPath,
    mapObj: variables,
    showHomeIcon: false
  }
  return bConfig
}
