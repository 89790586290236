import { useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "@mui/material"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"
import FormSubmitCancel from "./Components/CreateEditSignature/FormSubmitCancel"
import FormFieldConditionRender from "../FormFieldConditionRender"
import CBreadCrumb from "../CBreadCrumb"
import FormHeading from "src/components/FormHeading"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setValueForVariable } from "../../FunctionHelper"
import { conditionCheckOnLoading } from "../Columns/Utils/Helpers/CreateEditColumnHelper"
import { getVariableBasedOnComponent } from "./Utils/Helpers/SignaturesGridHelper"
import {
  getBreadcrumbConfig,
  getDefaultValues
} from "./Utils/Helpers/CreateEditsignatureHelper"
import { validationSchema } from "./Utils/Validation"
import { SignatureFieldDetails } from "./Utils/Constants/CreateEditSignatureConstant"

const CreateEditSignature = () => {
  const { t } = useTranslation<any>()
  const location = useLocation() as any
  const history = useHistory()
  const {
    config: { configDataHookCalled }
  } = ConfigDataHook()

  const { id, tab, tabId }: { id: string; tab: string; tabId: string } =
    useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  const search = window.location.search || ""

  let { variableSetRedux, variableRedux, variablesRedux, redirectUrl } =
    getVariableBasedOnComponent(tab, storeTabId, storeId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  const stateVariableRedux = location.state?.variableRedux

  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })
  const signatures = locVariableRedux.Signatures || []
  const locGetDefaultValues = () => getDefaultValues(signatures, storeId)

  const formHookMethods = useForm<any>({
    defaultValues: locGetDefaultValues(),
    resolver: yupResolver(validationSchema(signatures, storeId, t, Translates)),
    criteriaMode: "all",
    mode: "onChange"
  })

  const { reset } = formHookMethods

  useEffect(() => {
    let isMounted = true
    if (isMounted && configDataHookCalled) {
      let isRedirect = false
      const isStoreTabIdInvalid =
        storeTabId !== -1 &&
        (storeTabId > variablesRedux.length || storeTabId <= 0)

      const isStoreIdInvalid =
        storeId !== -1 && (storeId > signatures.length || storeId <= 0)

      if (isStoreTabIdInvalid || isStoreIdInvalid) {
        isRedirect = true
      }
      if (isRedirect) {
        history.push(redirectUrl)
      } else {
        conditionCheckOnLoading(
          {
            locVariableRedux,
            variableSetRedux,
            variableRedux,
            storeTabId,
            storeId,
            reset,
            locGetDefaultValues,
            columnsRedux: {},
            locColumnsRedux: {}
          },
          false
        )
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [configDataHookCalled])

  const bConfig = getBreadcrumbConfig(t, tab, storeId)

  return (
    <>
      <CBreadCrumb bConfig={bConfig} customPath="Report Prompt Details" />
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container">
          <FormHeading
            heading={`${getTranslations(t, Translates.Signature)}*`}
          />
          <FormFieldConditionRender FieldDetails={SignatureFieldDetails} />
          <FormSubmitCancel />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditSignature
