import { Alert, AlertTitle, Button, Divider, Grid } from "@mui/material"

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { PATH } from "src/constants/paths"
import {
  setCurrentOutputFile,
  setOutputGrandTotals,
  setOutputSubTotals
} from "src/redux/slices/configSlice"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { Loader } from "src/utils/reusables/reusable"
import Warning from "@mui/icons-material/Warning"
import AddIcon from "@mui/icons-material/Add"
import { OUTPUT_FILE_COMPONENT_TYPE } from "src/constants/outputFiles"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import {
  getFieldDelimiterValue,
  getRecordDelimiterValue
} from "../OutputFiles/OutputHelper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { columnDataPrepare } from "./ReportOutputConstant"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
let preDeleteRow = {} as any
const OutputFileTotals = ({ form, isParentEdit, component, setForm }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const outputGrandTotals = useSelector(
    (state: RootState) => state.config?.outputGrandTotals
  )
  const outputSubTotals = useSelector(
    (state: RootState) => state.config?.outputSubTotals
  )

  const newOutputFile = useSelector(
    (state: RootState) => state.config?.currentOutputFile
  )
  const sqlQueryColumns = useSelector(
    (state: RootState) => state.config?.newSQLQueryColumns
  )
  let search = window.location.search || ""
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const location: any = useLocation()
  let locationState: any = { ...location.state }
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [outputGrandTotalValues, setOutputGrandTotalValues] = useState(
    [] as any
  )
  const [outputSubTotalValues, setOutputSubTotalValues] = useState([] as any)
  let rows: any

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL) {
        setOutputGrandTotalValues(outputGrandTotals && [...outputGrandTotals])
      } else {
        setOutputSubTotalValues(outputSubTotals && [...outputSubTotals])
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [outputGrandTotals, outputSubTotals])

  const deleteTotal = (row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row }
  }
  const callbackConfirmation = (res: boolean) => {
    const destination = {
      CustomerPortalCheck: form.customerPortalCheck,
      FtpDirectory: form.selectedOption,
      Email: form.chips
    }
    if (res) {
      if (component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL) {
        let dt = rows
          ?.filter(f => f.Label !== preDeleteRow.Label)
          .map((m: any, i) => {
            return { ...m, SortOrder: i + 1 }
          })
        let grandTotalConfig = {
          ...newOutputFile,
          form,
          Destination: destination,
          GrandTotals: dt
        }
        let grandTotalConfigVal = {
          ...form,
          GrandTotals: dt
        }
        setForm(grandTotalConfigVal)
        dispatch(setCurrentOutputFile(grandTotalConfig))
        dispatch(setOutputGrandTotals(dt))
        setOutputGrandTotalValues(dt)
      } else {
        let dt = rows
          ?.filter(f => f.DataGroup !== preDeleteRow.DataGroup)
          .map((m: any, i) => {
            return { ...m, SortOrder: i + 1 }
          })
        let subTotalConfig = {
          ...newOutputFile,
          form,
          Destination: destination,
          SubTotals: dt
        }
        let subTotalConfigVal = {
          ...form,
          SubTotals: dt
        }
        setForm(subTotalConfigVal)
        dispatch(setCurrentOutputFile(subTotalConfig))
        dispatch(setOutputSubTotals(dt))
        setOutputSubTotalValues(dt)
      }
    }
    setIsConfirmOpen(false)
  }
  const deleteText = () => {
    return `${getTranslations(t, Translates.Are_you_sure_you_want_to_delete)} ${
      preDeleteRow.Label
    } ${
      component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL
        ? getTranslations(t, Translates.Grand)
        : getTranslations(t, Translates.Sub)
    } Total?`
  }
  const setContent = () => {
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{deleteText()}</strong>
        </p>
      </Alert>
    )
  }
  const handleNewTotalClick = () => {
    updateWarnOnTabChangeToStore(false)
    form = {
      ...form,
      FieldDelimiter: getFieldDelimiterValue(form),
      RecordDelimiter: getRecordDelimiterValue(form)
    }
    const destination = {
      CustomerPortalCheck: form.customerPortalCheck,
      FtpDirectory: form.selectedOption,
      Email: form.chips
    }

    dispatch(
      setCurrentOutputFile({ ...newOutputFile, form, Destination: destination })
    )
    setTimeout(() => {
      if (component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL) {
        dispatch(setOutputGrandTotals(outputGrandTotals))

        history.push(PATH.CREATE_EDIT_OUTPUT_GRAND_TOTALS + search, {
          isParentEdit: isParentEdit,
          isGrandTotalEdit: false,
          form: form,
          data: locationState.data
        })
      } else {
        dispatch(setOutputSubTotals(outputSubTotals))
        history.push(PATH.CREATE_EDIT_OUTPUT_SUBTOTALS + search, {
          isParentEdit: isParentEdit,
          isSubTotalEdit: false,
          form: form,
          data: locationState.data
        })
      }
    })
  }

  const handleEditTotalClick = (row: any) => {
    const destination = {
      CustomerPortalCheck: form.customerPortalCheck,
      FtpDirectory: form.selectedOption,
      Email: form.chips
    }
    dispatch(setCurrentOutputFile(form))
    form = {
      ...form,
      FieldDelimiter: getFieldDelimiterValue(form),
      RecordDelimiter: getRecordDelimiterValue(form)
    }
    updateWarnOnTabChangeToStore(false)
    if (component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL) {
      dispatch(setOutputGrandTotals(outputGrandTotals))
      setTimeout(() => {
        history.push(PATH.CREATE_EDIT_OUTPUT_GRAND_TOTALS + search, {
          isParentEdit: isParentEdit,
          isGrandTotalEdit: true,
          grandTotalData: { ...row },
          form: form,
          data: locationState.data
        })
      })
      dispatch(
        setCurrentOutputFile({
          ...newOutputFile,
          Destination: destination,
          SubTotals: outputSubTotals,
          GrandTotals: outputGrandTotals
        })
      )
    } else {
      dispatch(setOutputSubTotals(outputSubTotals))
      setTimeout(() => {
        history.push(PATH.CREATE_EDIT_OUTPUT_SUBTOTALS + search, {
          isParentEdit: isParentEdit,
          isSubTotalEdit: true,
          subTotalData: { ...row },
          form: form,
          data: locationState.data
        })
      })
      dispatch(
        setCurrentOutputFile({
          ...newOutputFile,
          Destination: destination,
          SubTotals: outputSubTotals,
          GrandTotals: outputGrandTotals
        })
      )
    }
  }
  const commonValues = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: true,
    setWidth: 250
  }

  const columnHeadersList: any = [
    {
      ...{
        Header: getTranslations(t, Translates.Sort),
        accessor: "SortOrder"
      },
      ...commonValues,
      ...{ setWidth: 2 }
    },
    {
      ...{
        Header: getTranslations(t, Translates.Data_Group),
        accessor: "DataGroup"
      },
      ...commonValues
    },
    {
      ...{ Header: getTranslations(t, Translates.Label), accessor: "Label" },
      ...commonValues
    },
    {
      ...{ Header: getTranslations(t, Translates.Values), accessor: "Values" },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.Placement),
        accessor: "Placement"
      },
      ...commonValues
    }
  ]
  const columnHeadersGrandTotalList: any = [
    {
      ...{
        Header: getTranslations(t, Translates.Sort),
        accessor: "SortOrder"
      },
      ...commonValues,
      ...{ setWidth: 2 }
    },
    {
      ...{ Header: getTranslations(t, Translates.Label), accessor: "Label" },
      ...commonValues
    },
    {
      ...{ Header: getTranslations(t, Translates.Values), accessor: "Values" },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.Placement),
        accessor: "Placement"
      },
      ...commonValues
    }
  ]
  let columnsData = columnDataPrepare(
    columnHeadersList,
    sqlQueryColumns,
    handleEditTotalClick,
    deleteTotal,
    t,
    configTypeFromStore
  )
  let columnsGrandTotalData = columnDataPrepare(
    columnHeadersGrandTotalList,
    sqlQueryColumns,
    handleEditTotalClick,
    deleteTotal,
    t,
    configTypeFromStore
  )

  const getRowItems = sub => {
    let row = {
      ...sub,
      Values: sub.AggregateConfigs?.map(v => {
        if (v.Function && v.DataSource) {
          return `${v.Function} (${v.DataSource})`
        } else {
          return "-"
        }
      })
    }
    return row
  }
  if (component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL) {
    rows = outputGrandTotalValues?.map(sub => {
      return getRowItems(sub)
    })
  } else {
    rows = outputSubTotalValues?.map(sub => {
      return getRowItems(sub)
    })
  }

  const displayGrid = () => {
    if (component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL) {
      return (
        <Grid xs={12} item data-testid="OFTGrandGlobTable">
          <GlobalTableDragDrop
            component={TableComponentNames.outputGrandTotals}
            columns={columnsGrandTotalData}
            data={rows || []}
            noDataText={
              outputGrandTotalValues?.length
                ? Loader()
                : `${getTranslations(
                    t,
                    Translates.No_Grand_totals_data_available
                  )}`
            }
          ></GlobalTableDragDrop>
        </Grid>
      )
    } else {
      return (
        <Grid xs={12} item data-testid="OFTSubGlobTable">
          <GlobalTableDragDrop
            component={TableComponentNames.outputSubTotals}
            columns={columnsData}
            data={rows || []}
            noDataText={
              outputSubTotalValues?.length
                ? Loader()
                : `${getTranslations(
                    t,
                    Translates.No_Sub_totals_data_available
                  )}`
            }
          ></GlobalTableDragDrop>
        </Grid>
      )
    }
  }
  return (
    <>
      <Grid
        container
        className="custom-table-responsive categories-table"
        data-testid="reportings-prompt-display-grid"
      >
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />
          <Grid container>
            <Grid item xs={6}>
              <h4>
                {component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL
                  ? getTranslations(t, Translates.GRAND_TOTALS)
                  : getTranslations(t, Translates.SUB_TOTALS)}
              </h4>
            </Grid>
            <Grid item xs={6} className="text-right">
              <Button
                variant="contained"
                data-testid="reportpromptCreate"
                className="primary-btn pa-2"
                onClick={handleNewTotalClick}
                disabled={configTypeFromStore === "view"}
              >
                <AddIcon className="mr-1" />{" "}
                {component === OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL
                  ? getTranslations(t, Translates.New_Grand_Total)
                  : getTranslations(t, Translates.New_Sub_Total)}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} className="mt-2">
              {displayGrid()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isConfirmOpen && (
        <ConfirmDialog
          open={isConfirmOpen}
          callBack={callbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setContent}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.Cancel)}
        ></ConfirmDialog>
      )}
    </>
  )
}

export default OutputFileTotals
