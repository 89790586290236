import { Button } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
const BulkUploadButtons = ({
  previousBLabel,
  handlePreviousClick,
  cancelBLabel,
  handleCancelClick,
  nextBLabel,
  handleNextClick,
  saveDisable
}: {
  previousBLabel?: string
  handlePreviousClick?: React.MouseEventHandler<HTMLButtonElement>
  cancelBLabel: string
  handleCancelClick: React.MouseEventHandler<HTMLButtonElement>
  nextBLabel: string
  handleNextClick: React.MouseEventHandler<HTMLButtonElement>
  saveDisable?: Function
}) => {
  return (
    <>
      <div className="inner-view__footer">
        <Button
          variant="text"
          className="primary-btn mr-5 prvious-btn"
          data-testid="previous-Btn"
          onClick={handlePreviousClick}
        >
          {previousBLabel &&
          <ArrowBackIcon className="mr-1" />}
          {previousBLabel}
        </Button>
        <Button
          variant="text"
          className="primary-btn mr-5 cancel-btn"
          data-testid="cancelBtn"
          onClick={handleCancelClick}
        >
          {cancelBLabel}
        </Button>
        <Button
          variant="contained"
          disabled={saveDisable ? saveDisable() : false}
          className="primary-btn"
          data-testid="nextBtn"
          onClick={handleNextClick}
        >
          {nextBLabel}
        </Button>
      </div>
    </>
  )
}

export default BulkUploadButtons
