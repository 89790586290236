import { sqlEditorDefaultIniValues } from "./Constant"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import shortid from "shortid"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { SQLPrompt } from "./Typs"
import { ConfigPromptType } from "../../Prompts/Utils/Types"
import { replaceAllMultiple } from "src/utils/helper"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { store } from "src/redux/store"
import { PATH, multiEventOutputRouteReplaceVar } from "src/constants/paths"
import { MultiEventOutputFormType } from "./../../../../../pages/MultiEventOutputs/Utils/Types"
import { ConfigReportType } from "src/configurationPortal/pages/Reports/Utils/Types"
import { setMultiEventOutputRedux } from "src/redux/slices/MultiEventOutputSlice"
export const getDefaultValues = variableRedux => {
  if (Object.keys(variableRedux).length) {
    return {
      ...sqlEditorDefaultIniValues,
      RawSQL: variableRedux.RawSQL,
      Prompts: SQLPromptsPrepare(variableRedux.Prompts, variableRedux.RawSQL)
    }
  }

  return sqlEditorDefaultIniValues
}

export const headerTitle = (componentName: string) => {
  let innerHeading = "Report"
  if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Outputs.toLocaleLowerCase()
  ) {
    innerHeading = "Output"
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
  ) {
    innerHeading = "Multi Event Output"
  }
  return innerHeading
}

export const displayPromptVariables = (Prompts: SQLPrompt[]) => {
  return Prompts.map(item => {
    return (
      <span className="d-block mt-2 f-600" key={`${shortid.generate()}`}>
        {item.VariableName}{" "}
        <CheckCircleIcon className={item.IsUsed ? "green-text" : "grey-text"} />
      </span>
    )
  })
}

export const SQLPromptsPrepare = (
  Prompts: ConfigPromptType[],
  Query: string
) => {
  return (Prompts || []).map(item => {
    return {
      VariableName: item.VariableName,
      IsUsed: Query?.split(" ")?.includes(`@${item.VariableName}`)
    }
  })
}

export const SQLQueryPrepare = (Query: string) => {
  let locSqlQuery = replaceAllMultiple(
    Query,
    ["\n", "\r", "\\n", "\\r", ">", "<", "=", "  ", "> =", "< =", "   "],
    [" ", " ", " ", " ", " > ", " < ", " = ", " ", ">=", "<=", " "]
  )
  locSqlQuery = locSqlQuery.replace(/(?:\r\n|\r|\n)/g, " ")
  locSqlQuery = locSqlQuery.replace(/[\r\n\t]/g, " ")
  locSqlQuery = locSqlQuery.replace(/@(\w+)/g, " @$1 ")
  locSqlQuery = locSqlQuery.trim()

  return locSqlQuery
}

export const getVariableBasedOnComponent = (
  componentName: string,
  tabId: number
) => {
  let variableSetRedux = setReportRedux
  const { reportRedux, reportsRedux } = store.getState().report
  const { multiEventOutputRedux, multiEventOutputsRedux } =
    store.getState().multiEventOutput
  let variableRedux: ConfigReportType | MultiEventOutputFormType = reportRedux
  let variablesRedux: any = reportsRedux
  let createEditPath = PATH.TAB_CREATE_SQL_EDITOR
  let redirectUrl = PATH.REPORT_CREATE
  if (tabId !== -1) {
    createEditPath = PATH.TAB_EDIT_SQL_EDITOR
  }
  if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Reports.toLocaleLowerCase()
  ) {
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Outputs.toLocaleLowerCase()
  ) {
    variableSetRedux = setReportRedux
    variableRedux = reportRedux
    variablesRedux = reportsRedux
    //istanbul ignore next
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    } else {
      redirectUrl = PATH.REPORT_CREATE
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
  ) {
    variableSetRedux = setMultiEventOutputRedux
    variableRedux = multiEventOutputRedux
    variablesRedux = multiEventOutputsRedux
    createEditPath = createEditPath.replace(
      multiEventOutputRouteReplaceVar.Configuration,
      multiEventOutputRouteReplaceVar.CreateSchedule
    )
    if (tabId !== -1) {
      redirectUrl = PATH.MULTI_EVENT_OUTPUT_EDIT.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    } else {
      redirectUrl = PATH.MULTI_EVENT_OUTPUT_CREATE.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    }
  }
  return {
    variableSetRedux,
    variableRedux,
    createEditPath,
    redirectUrl,
    variablesRedux
  }
}
