import { useReducer } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { Button, Divider, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { Translates } from "src/i18n/i18n"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { useAppThunkDispatch } from "src/redux/store"
import {
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import {
  findHighestSuppressIndex,
  getColumnsData,
  getVariableBasedOnComponent,
  setModalContent
} from "./Utils/Helpers/ColumnsGridHelper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { ReportColumn, OutputColumn } from "./Utils/Types"
import FormHeading from "src/components/FormHeading"
import { createEditReportFieldNames } from "../../Reports/Utils/Constants/CreateEditReportConstant"
let preDeletedRow: any = {}
let showWarningModal: boolean = false
const Columns = ({
  componentName,
  fieldName
}: {
  componentName: string
  fieldName: string
}) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const [, setReload] = useReducer(reducer, false)
  const dispatch = useAppThunkDispatch()

  const {
    columnReduxData: { columnsRedux }
  } = ConfigDataHook()

  const { setValue, getValues } = useFormContext()

  const { Columns } = getValues()

  let search = window.location.search || ""
  type ConfigColumnType = ReportColumn | OutputColumn

  let { variableSetRedux, tableComponent, createPath, editPath } =
    getVariableBasedOnComponent(componentName, storeId, -1)

  const handleCreateColumnClick = () => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))
    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          createPath,
          [":tab", ":tabId"],
          [componentName.toLocaleLowerCase(), `${storeId}`]
        ) + search,
        {
          variableRedux: getValues(),
          columnRedux: columnsRedux
        }
      )
    })
  }

  const updateColumn = (row1: {
    original: ConfigColumnType
    index: number
  }) => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))
    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          editPath,
          [":tab", ":tabId", ":id"],
          [componentName.toLocaleLowerCase(), `${storeId}`, `${row1.index + 1}`]
        ) + search,
        {
          variableRedux: getValues(),
          columnRedux: columnsRedux
        }
      )
    })
  }
  const deleteColumn = (row1: {
    original: ConfigColumnType
    index: number
  }) => {
    showWarningModal = true
    preDeletedRow = {
      ...row1.original,
      index: row1.index
    }
    setReload(oldVal => !oldVal)
  }

  const CallbackConfirmation = (res: boolean) => {
    if (res) {
      let dt: ConfigColumnType[] = Columns.filter(
        (f: ConfigColumnType, inx) => inx !== preDeletedRow.index
      )
      setValue(fieldName, dt)
    }
    showWarningModal = false
    preDeletedRow = {}
    setReload(oldVal => !oldVal)
  }

  const handleSuppressChange = (e, row) => {
    let dt: ConfigColumnType[] = Columns.map(
      (i: ConfigColumnType, j: number) => {
        return j <= row?.index
          ? { ...i, Suppress: e.target.checked ?? false }
          : i
      }
    )
    setValue(fieldName, dt)
    setValue(
      createEditReportFieldNames.ColumnsToSuppress.name,
      findHighestSuppressIndex(dt)
    )
    setReload(oldVal => !oldVal)
  }

  const columnsData = getColumnsData({
    componentName: componentName,
    updateColumn: updateColumn,
    deleteColumn: deleteColumn,
    handleSuppressChange: handleSuppressChange,
    columnsRedux: columnsRedux,
    t
  })

  return (
    <Grid
      container
      className="custom-table-responsive categories-table"
      data-testid="reportings-display-grid"
    >
      <Grid item xs={12} sm={12}>
        <Divider className="dashed my-4" />

        <Grid container>
          <Grid item xs={6}>
            <FormHeading
              heading={`${getTranslations(t, Translates.Columns)}*`}
            />
          </Grid>
          <Grid item xs={6} className="text-right">
            <Button
              variant="contained"
              data-testid="reportColumnCreate"
              className="primary-btn pa-2"
              onClick={handleCreateColumnClick}
              disabled={!columnsRedux?.columns?.length}
            >
              <AddIcon className="mr-1" />{" "}
              {getTranslations(t, Translates.New_Column)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item className="mt-2">
        <GlobalTableDragDrop
          component={tableComponent}
          columns={columnsData}
          data={Columns || []}
          noDataText={getTranslations(t, Translates.No_Columns_Data_available)}
        />
      </Grid>
      {showWarningModal && (
        <ConfirmDialog
          open={showWarningModal}
          callBack={CallbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setModalContent({
            preDeletedRow,
            t
          })}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.CANCEL)}
        />
      )}
    </Grid>
  )
}

export default Columns
