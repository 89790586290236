import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface FtpConnectionSliceType {
  ftpConnections: any[]
}

const initialState: FtpConnectionSliceType = {
  ftpConnections: []
}
export const FtpConnectionSlice = createSlice({
  name: "ftpConnections",
  initialState,
  reducers: {
    setFtpConnections: (state, _action: PayloadAction<any>) => {
      state.ftpConnections = [..._action.payload]
    }
  },
  extraReducers: builder => {}
})
export const { setFtpConnections } = FtpConnectionSlice.actions
export default FtpConnectionSlice.reducer
