import { Controller, useFormContext } from "react-hook-form"
import { FormControl, FormHelperText, FormLabel } from "@mui/material"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-mysql"
import "ace-builds/src-noconflict/theme-textmate"
import "ace-builds/src-noconflict/ext-language_tools"
import { getFieldError } from "./Helper"

export interface Props {
  inputAttributes: {
    label?: string
    name: string
    value?: string | number
    defaultValue?: string | number
    onChange?: Function
    onFocus?: Function
    height?: string
    width?: string
    mode?: string
    theme?: string
    setOptions?: Object
    style?: Object
    className?: { FormControl?: string; FormLabel?: string; AceEditor?: string }
    readOnly?: boolean
  }
}

const AceEditorField = ({ inputAttributes }: Props) => {
  const {
    name,
    value,
    label,
    defaultValue,
    onChange,
    onFocus,
    mode,
    theme,
    height,
    width,
    setOptions,
    style,
    className,
    readOnly
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods
  const { isError, ErrorText } = getFieldError(name, errors)
  return (
    <div className="gray-bg pa-4">
      <Controller
        name={`${name}`}
        control={control}
        defaultValue={value ?? defaultValue ?? ""}
        render={({ field: { ref, ...field } }) => (
          <FormControl
            className={`${className?.FormControl}  `}
            style={{ width: "100%" }}
          >
            <FormLabel
              id={`${name}-ace-editor-label`}
              className={`${className?.FormLabel}`}
            >
              {label}
            </FormLabel>
            <AceEditor
              aria-label="query editor input"
              {...field}
              mode={mode ?? "mysql"}
              theme={theme ?? "textmate"}
              fontSize={16}
              maxLines={11}
              minLines={11}
              width={width ?? "100% !important"}
              height={height ?? "auto"}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={false}
              readOnly={readOnly ? true : false}
              highlightActiveLine={false}
              setOptions={{
                ...{
                  showLineNumbers: false,
                  showGutter: false,
                  highlightGutterLine: false,
                  highlightActiveLine: false,
                  enableBasicAutocompletion: true
                },
                ...setOptions
              }}
              style={style}
              className={`${className?.AceEditor ?? ""} sqlEditor`}
              onChange={e => {
                field.onChange(e)
                if (onChange) onChange(e)
              }}
              onFocus={e => {
                if (onFocus) onFocus(e)
              }}
              onLoad={editor => {
                editor.renderer.setScrollMargin(10, 0, 5, 0)
              }}
            />
            <FormHelperText className={isError ? "text-danger" : ""}>
              {ErrorText}
            </FormHelperText>
          </FormControl>
        )}
      />
    </div>
  )
}

export default AceEditorField
