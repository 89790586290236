import { getTranslations } from "src/utils/helper"
import { createEditReportDefaultIniValues } from "../Constants/CreateEditReportConstant"
import { ConfigReportType } from "../Types"
import { Translates } from "src/i18n/i18n"
import { PATH } from "src/constants/paths"
import { getBreadcrumbConfig } from "src/configurationPortal/pages/helper"

export const getBreadcrumbPath = (storeId: number, t, nextPage = false) => {
  let componentName =
    storeId !== -1
      ? getTranslations(t, Translates.Edit_Report)
      : getTranslations(t, Translates.New_Report)
  const { bConfig } = getBreadcrumbConfig({
    landingName: getTranslations(t, Translates.Reports),
    landingPath: PATH.REPORTS.slice(1),
    componentName: componentName,
    nextPage: nextPage,
    storeId: storeId,
    createPath: PATH.REPORT_CREATE,
    editPath: PATH.REPORT_EDIT
  })
  return { componentName, bConfig }
}

export const getDefaultValues = (
  reportRedux: ConfigReportType,
  reportsRedux: ConfigReportType[],
  storeId: number
) => {
  if (reportRedux && Object.keys(reportRedux).length) {
    return { ...createEditReportDefaultIniValues, ...reportRedux }
  } else if (
    storeId > -1 &&
    reportsRedux?.length &&
    reportsRedux[storeId - 1]
  ) {
    return { ...createEditReportDefaultIniValues, ...reportsRedux[storeId - 1] }
  }
  return createEditReportDefaultIniValues
}
