import React from "react"
import Link, { LinkProps } from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link as RouterLink, Route, Switch } from "react-router-dom"
import { breadcrumbNameMap } from "src/constants/breadcrumb"
import HomeIcon from "@mui/icons-material/Home"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
)

const Page: React.FC<any> = ({ customPath, page, breadCrumbInfo }) => {
  if (breadCrumbInfo) {
    breadCrumbInfo["showHomeIcon"] =
      breadCrumbInfo.showHomeIcon !== undefined
        ? breadCrumbInfo.showHomeIcon
        : true
  } else {
    breadCrumbInfo = { showHomeIcon: true }
  }
  const location = window.location
  const pathnames = location.pathname.split("/").filter(x => x)
  const IS_HOME_PAGE: boolean = pathnames.length === 0
  const { t } = useTranslation<any>()
  const getBreadCrumbList = () => {
    if (breadCrumbInfo.currentPath && breadCrumbInfo.mapObj) {
      let breadCrumbList: any[] = []
      let skipItems: any[] = []
      breadCrumbInfo.currentPath.split("/").map((bc: string, idx: number) => {
        let linkVal: string | null = !bc.includes("@")
          ? bc
          : breadCrumbInfo.custLinks[bc]
        breadCrumbList.push({
          link: linkVal,
          showText: breadCrumbInfo.mapObj[bc]
        })
        if (bc.startsWith("##") && bc.endsWith("##")) {
          skipItems.push(idx)
        }
      })
      if (skipItems.length) {
        skipItems.forEach(itemIndex => {
          if (breadCrumbList[itemIndex - 1] && breadCrumbList[itemIndex]) {
            breadCrumbList[itemIndex - 1].link += `/${breadCrumbList[
              itemIndex
            ].link.replaceAll("##", "")}`
          }
        })
        skipItems.forEach(itemIndex => {
          breadCrumbList.splice(itemIndex, 1)
        })
      }
      return breadCrumbList
    }
    return []
  }

  const renderWithTagNumber = (_page, to, _customPath, value) => {
    return (
      <Typography color="text.primary" key={to}>
        {value.showText}
      </Typography>
    )
  }
  const renderBreadCrumbs = () => {
    if (IS_HOME_PAGE) {
      return (
        <Typography color="text.primary" className="bc-home" key={`welcome`}>
          {getTranslations(t, Translates.Welcome)}
        </Typography>
      )
    } else if (!customPath) {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          {breadCrumbInfo.showHomeIcon ? (
            <LinkRouter
              className="custom-breadcrumb__link"
              underline="hover"
              color="inherit"
              to={"/"}
              key={"toHome"}
            >
              <HomeIcon />
            </LinkRouter>
          ) : null}
          {pathnames.map((_value, index) => {
            const last = index === pathnames.length - 1
            const to = `/${pathnames.slice(0, index + 1).join("/")}`
            if (last) {
              return (
                <Typography color="text.primary" key={to}>
                  {getTranslations(t, Translates.Settings)}
                </Typography>
              )
            } else {
              return (
                <LinkRouter
                  className="custom-breadcrumb__link"
                  underline="hover"
                  color="inherit"
                  to={to}
                  key={to}
                >
                  {breadcrumbNameMap[to]}
                </LinkRouter>
              )
            }
          })}
        </Breadcrumbs>
      )
    } else {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          {breadCrumbInfo.showHomeIcon ? (
            <LinkRouter
              className="custom-breadcrumb__link"
              underline="hover"
              color="inherit"
              to={"/"}
              key={"toHome"}
            >
              <HomeIcon />
            </LinkRouter>
          ) : null}
          {getBreadCrumbList().map((value, index) => {
            let search = location.search
            const last = index === getBreadCrumbList().length - 1
            const to = `/${value.link}`
            let toLink = `/${value.link}`
            toLink =
              search?.length && !breadCrumbInfo.isNotSearchParams
                ? toLink + search
                : toLink
            if (last) {
              return renderWithTagNumber(page, to, customPath, value)
            } else {
              return (
                <LinkRouter
                  className="custom-breadcrumb__link"
                  underline="hover"
                  color="inherit"
                  to={toLink}
                  key={to}
                >
                  {value.showText || ""}
                </LinkRouter>
              )
            }
          })}
        </Breadcrumbs>
      )
    }
  }
  return <>{renderBreadCrumbs()}</>
}

type breadcrumbType = {
  customPath: string
  renderedPage: string
  breadCrumbInfo: any
}

const RouterBreadcrumbs: React.FC<breadcrumbType> = ({
  customPath,
  renderedPage,
  breadCrumbInfo
}) => {
  return (
    <Switch>
      <Route
        path="*"
        component={() => (
          <Page
            page={renderedPage}
            customPath={customPath}
            breadCrumbInfo={breadCrumbInfo}
          />
        )}
      />
    </Switch>
  )
}

export default RouterBreadcrumbs
