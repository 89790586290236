export const getFieldError = (
  name,
  errors
): { isError: boolean; ErrorText: string } => {
  let isError: boolean = false
  let ErrorText: string = ""
  let locErrors: any = errors

  const processArrayErrors = element => {
    for (const error of locErrors[element]) {
      if (error?.message) {
        ErrorText = error.message
        break
      }
    }
  }

  const processErrorsWithNames = element => {
    isError = true

    if (Array.isArray(locErrors[element])) {
      processArrayErrors(element)
    } else {
      ErrorText = locErrors[element].message
    }
  }

  const processErrors = (element, inx, j) => {
    if (locErrors?.[element]) {
      if (name.split(".").length - 1 === j) {
        processErrorsWithNames(element)
      } else {
        locErrors = Array.isArray(locErrors[element])
          ? locErrors[element][inx]
          : locErrors[element]
      }
    }
  }

  if (name.split(".").length > 1) {
    name.split(".").forEach((i, j) => {
      let element = i
      let inx = 0
      if (element.split("[").length > 1) {
        inx = Number(element.match(/\[(.*?)\]/)[1])
        element = element.split("[")[0]
      }
      processErrors(element, inx, j)
    })
  } else if (errors[name]) {
    isError = true
    ErrorText = errors[name].message
  }

  return { isError, ErrorText }
}

export const getDefaultValue = (
  value,
  defaultValue,
  options,
  multiple = false
) => {
  let defVal: any = multiple ? [] : ""
  if (value !== undefined) {
    defVal = value
  } else if (defaultValue !== undefined) {
    defVal = defaultValue
  } else {
    options.length &&
      options.forEach(item => {
        if (item?.default) {
          if (multiple) {
            defVal = [...defVal, item.value]
          } else {
            defVal = item.value
          }
        }
      })
  }

  return defVal
}

export const getOptionLabelForAutoComplete = (option, options) => {
  if (typeof option !== "object") {
    return options.find(option1 => option1.value === option)?.label || ""
  } else {
    return option.label
  }
}
