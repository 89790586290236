import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ConfigReportType } from "src/configurationPortal/pages/Reports/Utils/Types"

export interface ReportSliceType {
  reportsRedux: ConfigReportType[]
  reportRedux: ConfigReportType
  issetReportsRedux: boolean
}

const initialState: ReportSliceType = {
  reportsRedux: [],
  reportRedux: {} as ConfigReportType,
  issetReportsRedux: false
}
export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportsRedux: (state, _action: PayloadAction<any>) => {
      state.reportsRedux = [..._action.payload]
      state.issetReportsRedux = true
    },
    setReportRedux: (state, _action: PayloadAction<any>) => {
      state.reportRedux = { ..._action.payload }
    }
  },
  extraReducers: builder => {}
})
export const { setReportsRedux, setReportRedux } = reportSlice.actions
export default reportSlice.reducer
