import * as yup from "yup"
import {
  PromptCustomListOptions,
  PromptTypeOptions,
  createEditPromptFieldNames
} from "./Constants/CreateEditPromptConstant"
import {
  PromptTypes,
  numberTypeErr,
  oneOfErr,
  requiredErr
} from "src/utils/constants"
import { getTranslations, validateInputString } from "src/utils/helper"

export const validationSchema = (t, Translates) => {
  return yup.object().shape({
    [createEditPromptFieldNames.Label.name]: yup
      .string()
      .required(requiredErr)
      .test(
        createEditPromptFieldNames.Label.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),
    [createEditPromptFieldNames.VariableName.name]: yup
      .string()
      .required(requiredErr)
      .test(
        createEditPromptFieldNames.VariableName.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),
    [createEditPromptFieldNames.Type.name]: yup
      .string()
      .required(requiredErr)
      .oneOf(["", ...PromptTypeOptions.map(item => item.value)], oneOfErr),
    [createEditPromptFieldNames.NumberOfDecimals.name]: yup
      .number()
      .typeError(numberTypeErr)
      .when([createEditPromptFieldNames.Type.name], {
        is: Type => Type && Type === PromptTypes.Decimal,
        then: yup.number().typeError(numberTypeErr).required(requiredErr)
      }),
    [createEditPromptFieldNames.CustomList.name]: yup
      .array()
      .when([createEditPromptFieldNames.Type.name], {
        is: Type => Type && Type === PromptTypes.CustomList,
        then: yup
          .array()
          .required(requiredErr)
          .min(1, requiredErr)
          .of(
            yup
              .string()
              .oneOf(
                ["", ...PromptCustomListOptions.map(item => item.value)],
                oneOfErr
              )
          )
      })
  })
}
