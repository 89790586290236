import { Checkbox, IconButton, Tooltip } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import CheckIcon from "@mui/icons-material/Check"
import editIcon from "src/assets/images/Edit.png"
import trashIcon from "src/assets/images/TrashIcon.svg"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"
import { GetOutputBundlesColumnsDataType } from "../Types"
import AlertConfirmationContent from "src/configurationPortal/pages/Components/AlertConfirmationContent"
import { FilesDetailsFields } from "../Constants/CreateEditOutputBundleConstant"

const displayDisableCheckbox = (props: any, isChecked: boolean) => {
  return (
    <Checkbox
      checked={isChecked}
      className={`custom-check light`}
      checkedIcon={<CheckIcon />}
      disabled={true}
    />
  )
}
export const getColumnsData = ({
  copyOutputBundle,
  updateOutputBundle,
  deleteOutputBundle,
  t,
  configTypeFromStore
}: GetOutputBundlesColumnsDataType) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    setWidth: 150,
    Cell: props => {
      return displaySpan(props)
    }
  }

  return [
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Output_Name),
      accessor: "Name"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Description),
      accessor: "Description"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Zipped),
      accessor: "FileType",
      Cell: props => {
        return displayDisableCheckbox(
          props,
          props.value ===
            FilesDetailsFields.fileType.props.formControl.radioGroup
              .formControlLabels[0].value
        )
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.CustomerPortal),
      accessor: "Destination.IsCustomerPortal",
      Cell: props => {
        return displayDisableCheckbox(props, props.value)
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Final_Reports_In_Store),
      accessor: "Destination.IsFinalReport",
      Cell: props => {
        return displayDisableCheckbox(props, props.value)
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      setWidth: 200,
      Cell: props => {
        const icons = [
          {
            title: getTranslations(t, Translates.Copy),
            clickFn: copyOutputBundle,
            icon: (
              <ContentCopyOutlinedIcon
                className="copy-icon pointer"
                data-testid="output-bundle-cloneBtn"
              />
            ),
            disabled: configTypeFromStore === "view"

          },
          {
            title: getTranslations(t, Translates.Edit),
            clickFn: updateOutputBundle,
            icon: (
              <img
                src={editIcon}
                alt={"edit icon"}
                className="pointer"
                data-testid="output-bundle-editBtn"
              />
            )
          },
          {
            title: getTranslations(t, Translates.Delete),
            clickFn: deleteOutputBundle,
            icon: (
              <img
                src={trashIcon}
                alt="delete"
                className="pointer"
                data-testid="output-bundle-deleteBtn"
              />
            ),
            disabled: configTypeFromStore === "view"

          }
        ]
        return (
          <>
            {icons.map((f, i) => {
              return (
                <>
                  <Tooltip arrow title={f.title} key={f.title}>
                    <IconButton onClick={() => f.clickFn(props.row)}  disabled={f.disabled}>
                      <span className="text-center d-block">{f.icon}</span>
                    </IconButton>
                  </Tooltip>
                </>
              )
            })}
          </>
        )
      }
    }
  ]
}

export const setModalContent = ({ preDeletedRow, t }) => {
  let content = `${getTranslations(
    t,
    Translates.Are_you_sure_you_want_to_delete
  )} "${preDeletedRow.Name}" ${getTranslations(t, Translates.Output_Bundle)}?`
  return <AlertConfirmationContent warningMsg={content} />
}
