import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'


const defaultProps = {
  limit: 5,
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "colored"
}
const Toaster = props => {
  let finalProps = { ...defaultProps, ...props }
  return <ToastContainer icon={({ type }) => (type === "error" ? <WarningIcon/> : <CheckCircleIcon/>)} {...finalProps}  /> 
}

export default Toaster
