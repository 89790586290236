import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface SQLTestColumnSliceType {
  columnsRedux: {
    SQL: string
    columns: {
      CharacterLength: null | string
      ColumnName: string
      Datatype: string
      NumericLength: number
    }[]
  }
}

const initialState: SQLTestColumnSliceType = {
  columnsRedux: {} as SQLTestColumnSliceType["columnsRedux"]
}
export const columnSlice = createSlice({
  name: "column",
  initialState,
  reducers: {
    setColumnsRedux: (state, _action: PayloadAction<any>) => {
      state.columnsRedux = { ..._action.payload }
    }
  },
  extraReducers: builder => {}
})
export const { setColumnsRedux } = columnSlice.actions
export default columnSlice.reducer
