/* istanbul ignore file */
//BEGIN-NOSCAN
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import English from "src/i18n/translations/en.json"
import French from "src/i18n/translations/de.json"
import Spanish from "./translations/me.json"
import { MultilingualService } from "src/service/multilingual.service"
import { languageCode, languageCodeMap } from "src/utils/constants"
import { LoginService } from "src/service/login.service"
import { setKeys, setTemplates } from "src/redux/slices/authSlice"
import { store } from "src/redux/store"
import { decryptUrlParams } from "src/utils/helper"
import { InItService } from "src/service/init.service"
import moment from "moment"
//clearing to avoid retaining old event cache
if (window.location.pathname === "/login") {
  localStorage.removeItem("i18nextLng")
}
export const resources = {
  English,
  French,
  Spanish
}
export const updateMoment = () => {
  Object.keys(languageCodeMap).forEach(lang => {
    moment.defineLocale(languageCodeMap[lang], {
      months: resources[lang].months,
      monthsShort: resources[lang].monthsShort
    })
  })
}
const initService = new InItService()
// istanbul ignore next
const setToken = (key, value) => {
  localStorage.setItem(key, value)
}
// istanbul ignore next
const getHereToken = () => {
  setTimeout(() => {
    initService.init()
  })
}
// istanbul ignore next
const getTemplateURLs = () => {
  initService
    .getTemplates()
    .then(resp => {
      store.dispatch(setTemplates(resp.data))
    })
    .catch(err => {
      throw err
    })
}
const multilingualService = new MultilingualService()
const loginService = new LoginService()

// istanbul ignore next
export const loginAccessTokenAndKeysSet = async () => {
  await loginService
    .getTokenForADLogin()
    .then(async (flexResp: any) => {
      setToken("accessToken", flexResp.data.accessToken)
      setToken("refreshToken", flexResp.data.refreshToken)
      updateMoment()
      await loginService
        .getLogin()
        .then(res => {
          if (res?.data?.length) {
            const _temp = res?.data?.map(a => ({
              secretKey: a.secretKey,
              secretValue: decryptUrlParams(a.secretValue)
            }))
            store.dispatch(setKeys(_temp))
            getHereToken()
            getTemplateURLs()
          }
        })
        .catch(function (err) {
          throw err
        })
    })
    .catch(err => {
      console.error(err)
    })
}
const route = window.location.pathname
// istanbul ignore next
if (!route.includes("login") && localStorage.getItem("accessToken")) {
  loginAccessTokenAndKeysSet()
  multilingualService.getLanguageText().then((res: any) => {
    const Languages = res?.data || []
    Object.keys(Languages)?.forEach(i => {
      if (i?.toLowerCase() === languageCode.us_en) {
        Languages[i]?.forEach(i1 => {
          English.common = {
            ...English.common,
            ...{ [i1?.field_Code]: i1?.text_Translation }
          }
        })
      } else if (i?.toLowerCase() === languageCode.ca_fr) {
        Languages[i]?.forEach(i1 => {
          French.common = {
            ...French.common,
            ...{ [i1?.field_Code]: i1?.text_Translation }
          }
        })
      } else if (i?.toLowerCase() === languageCode.es_mx) {
        Languages[i]?.forEach(i1 => {
          Spanish.common = {
            ...Spanish.common,
            ...{ [i1?.field_Code]: i1?.text_Translation }
          }
        })
      }
    })
  })
}

export const defaultNS = "common"

export const availableLanguages = Object.keys(resources)

const selectedLanguage = localStorage.getItem("i18nextLng")
const fallbackLanguage = "English" // Fallback language if stored language is not available
const initialLanguage = selectedLanguage ?? fallbackLanguage // Use stored language or fallback language
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS,
  lng: initialLanguage,
  fallbackLng: fallbackLanguage
})

export const Translates = Object.keys(English.common).reduce(
  (current: { [key: string]: string }, key: string) => ({
    ...current,
    [key]: key
  }),
  {}
)
//END-NOSCAN
