import { Button, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import PortalLogoLight from "../../../src/assets/images/PortalLogoLight.svg"
import LoginBg from "../../../src/assets/images/LoginBg.svg"
import styles from "./Login.module.scss"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../src/constants/paths"
import { config } from "../../config/config"
import { msalConfig, request } from "../../config/authConfig"
import { PublicClientApplication } from "@azure/msal-browser"
import { toast } from "react-toastify"
import { prepareMsalConfig, validateEmail } from "src/utils/helper"
import { loginAccessTokenAndKeysSet } from "src/i18n/i18n"

const Login = () => {
  const history = useHistory()
  const [disableADBtn, setDisableADBtn] = useState(true)
  const [username, setUsername] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [fetching, setFetching] = useState(false)

  const setToken = (key, value) => {
    localStorage.setItem(key, value)
  }
  const toastMsg = msg => {
    toast.error(`${msg}`, {
      toastId: msg.split("").join("-")
    })
  }

  const getErrMsgFromAzureErr = (err: any) => {
    let key = err.name
    let res: string = ""
    if (key) {
      let wholeMsg = err.errorMessage
      if (wholeMsg) {
        let splitMsg = wholeMsg.split(key)
        if (splitMsg[1]) res = splitMsg[1].split(".")[0]
      }
    }
    if (res.startsWith(":")) res = res.slice(1)
    return res
  }
  const handleADLogin = (params: any) => {
    const redirectPath = localStorage.getItem("redirectPath")
    let mConfig: any = prepareMsalConfig(params, msalConfig, false)
    const instance = new PublicClientApplication(mConfig)
    setDisableADBtn(true)
    let lrequest: any = {
      ...request,
      loginHint: params.login_hint,
      domainHint: params.domain_hint
    }

    instance
      .loginPopup(lrequest)
      .then(async (response: any) => {
        setToken("msalAccessToken", response.accessToken)
        setToken("username", response.account.username)
        setToken("name", response.account.name)
        try {
          setFetching(true)
          await loginAccessTokenAndKeysSet()
          setDisableADBtn(false)
          setFetching(false)
          if (redirectPath) {
            window.location.href = redirectPath
            localStorage.removeItem("redirectPath")
          } else {
            history.push(PATH.HOME)
          }
        } catch (err: any) {
          setDisableADBtn(false)
          setFetching(false)
          console.error(err.Message || err)
          toastMsg(err.Message || err)
        }
      })
      .catch(err => {
        setDisableADBtn(false)
        setFetching(false)
        console.log(JSON.stringify(err))
        let errorFromAD = getErrMsgFromAzureErr(err)
        if (errorFromAD) toastMsg(errorFromAD)
      })
  }

  const openLinkInNewTab = () => {
    window.open(config.supervisorURL, "_blank")
  }
  useEffect(() => {
    if (localStorage.getItem("accessToken") && localStorage.getItem("username"))
      history.push(PATH.HOME)
  }, [])
  const handleEnter = event => {
    if (event.keyCode && event.keyCode === 13) {
      return handleContinue()
    }
    setErrorMsg("")
    let v = event.target.value.trim()
    setDisableADBtn(v.length ? false : true)
  }

  const handleContinue = () => {
    if (!username || !username.trim().length) {
      setErrorMsg("Email is required.")
      return
    }
    let uName = username.trim()
    if (!validateEmail(uName)) {
      setErrorMsg("Invalid Email.")
      return
    }
    let splitStr = uName.split("@")
    let domain = splitStr[1]
    handleADLogin({ domain_hint: domain, login_hint: uName })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <header className={`header`}>
          <div className="main-container">
            <img src={PortalLogoLight} alt={"Portal Logo"} />
          </div>
        </header>
      </Grid>
      <Grid item xs={12}>
        <div className={`main-container ${styles.loginPage}`}>
          <Grid container>
            <Grid item sm={7} className={`${styles.loginBg}`}>
              <img width={"762px"} height={"744px"} src={LoginBg} alt={"Login "} />
            </Grid>
            <Grid item sm={5} className={`pl-3 ${styles.loginFormSec}`}>
              <div className={`${styles.loginForm}`}>
                <Grid container>
                  <Grid item xs={12}>
                    <h2>Log in</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type={"text"}
                      data-testid="email-input"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                      placeholder="Email address"
                      onKeyUp={handleEnter}
                      variant="filled"
                      label="Email address"
                      className="custom-form-control light"
                      fullWidth
                    />
                    {errorMsg.length ? (
                      <span className="error-text red-text">{errorMsg}</span>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      data-testid="continue-btn"
                      className="primary-btn mt-1 login-btn"
                      onClick={handleContinue}
                      disabled={disableADBtn || fetching}
                    >
                      {fetching ? "Fetching..." : "Continue"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} className="mt-3">
                    <Button
                      variant="text"
                      data-testid="event-btn"
                      className="primary-btn"
                      onClick={openLinkInNewTab}
                    >
                      <OpenInNewIcon className="mr-1" /> Event Login
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default Login
