import React from "react"
import { useLocation, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Button, Divider, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import MUIAutoCompleteText from "src/components/MUIFormFields/MUIAutoCompleteText"
import { getTotalValuesFields } from "../../Utils/Helpers/CreateEditTotalHelper"
import {
  AggregateConfigsIniValues,
  createEditTotalFieldNames
} from "../../Utils/Constants/CreateEditTotalConstant"
import { getVariableBasedOnComponent } from "../../Utils/Helpers/TotalsGridHelper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setValueForVariable } from "src/configurationPortal/pages/FunctionHelper"

const Values = () => {
  const { t } = useTranslation<any>()
  let location = useLocation() as any
  const {
    columnReduxData: { columnsRedux }
  } = ConfigDataHook()
  const {
    id,
    tab,
    tabId,
    type
  }: { id: string; tab: string; tabId: string; type: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1

  let { variableRedux, variablesRedux } = getVariableBasedOnComponent(
    tab,
    type,
    storeTabId,
    storeId
  )
  let stateVariableRedux = location.state?.variableRedux
  let stateColumnRedux = location.state?.columnRedux

  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const locColumnsRedux = setValueForVariable({
    values: [stateColumnRedux, columnsRedux],
    defaultValue: {}
  })

  const valueFields = getTotalValuesFields(locColumnsRedux, locVariableRedux)

  const { watch, setValue, trigger } = useFormContext()

  const handleAddValueCLick = () => {
    setValue(createEditTotalFieldNames.AggregateConfigs.name, [
      ...watch(createEditTotalFieldNames.AggregateConfigs.name),
      ...AggregateConfigsIniValues
    ])
  }
  const handleRemoveValueCLick = inx => {
    const newArr = watch(
      createEditTotalFieldNames.AggregateConfigs.name
    ).filter((i, j) => j !== inx)
    setValue(createEditTotalFieldNames.AggregateConfigs.name, [...newArr])
    if (!newArr.length) {
      trigger(createEditTotalFieldNames.AggregateConfigs.name)
    }
  }

  const getRemoveValueBtn = inx => {
    return (
      <Button
        variant="contained"
        style={{ width: "56px", height: "56px" }}
        className="secondary-btn icon-btn pa-0"
        onClick={() => handleRemoveValueCLick(inx)}
        data-testid="total-value-remove-btn"
      >
        <CloseIcon />
      </Button>
    )
  }

  const getAddValueBtn = () => {
    return (
      <Button
        variant="contained"
        style={{ width: "56px", height: "56px" }}
        className="secondary-btn icon-btn pa-0 mr-1"
        onClick={handleAddValueCLick}
        data-testid="total-value-add-btn"
      >
        <AddIcon />
      </Button>
    )
  }
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Divider className="dashed my-4" />
        <label className="form-control__label mb-3">
          {getTranslations(t, Translates.Values)}{" "}
          {!watch(createEditTotalFieldNames.AggregateConfigs.name).length
            ? getAddValueBtn()
            : "*"}
        </label>
      </Grid>

      <Grid container spacing={2}>
        {watch(createEditTotalFieldNames.AggregateConfigs.name).map((i, j) => {
          return (
            <React.Fragment key={i.DataSource}>
              {["dataSource", "column", "function"].map(i1 => (
                <Grid item xs={12} sm={3} key={i1}>
                  <MUIAutoCompleteText
                    inputAttributes={{
                      ...valueFields[i1].props,
                      name: valueFields[i1].props.name.replace("{inx}", j)
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={2}>
                {watch(createEditTotalFieldNames.AggregateConfigs.name).length -
                  1 ===
                j
                  ? getAddValueBtn()
                  : null}
                {getRemoveValueBtn(j)}
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}

export default Values
