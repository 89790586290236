import i18next from "i18next"
import moment from "moment"
import { languageCodeMap } from "src/utils/constants"
const DateFormat = props => {
  const lang = i18next.language
  let breakDandT = true
  let localTimeString = "-"
  let date
  let time
  let isLocal: boolean = props.isLocal || false
  let isEvent: boolean = props.isEvent || false
  let onlyDate: boolean = props.onlyDate || false
  moment.locale(languageCodeMap[lang])
  if (!isEvent) {
    const estDate = moment.utc(props.value).subtract(5, "hours")
    date = estDate.format("DD-MMM-YYYY").toLocaleUpperCase()
    time = `${estDate.format("hh:mm A").toLocaleUpperCase()} (EST)`
  } else if (isLocal) {
    date = moment
      .utc(props.value)
      .local()
      .format("DD-MMM-YYYY")
      .toLocaleUpperCase()
    time = moment.utc(props.value).local().format("hh:mm A").toLocaleUpperCase()
  } else if (onlyDate) {
    date = moment
      .utc(props.value)
      .utcOffset(0)
      .format("DD-MMM-YYYY")
      .toLocaleUpperCase()
    time = ""
  } else {
    date = moment
      .utc(props.value)
      .utcOffset(0)
      .format("DD-MMM-YYYY")
      .toLocaleUpperCase()
    time = moment
      .utc(props.value)
      .utcOffset(0)
      .format("hh:mm A")
      .toLocaleUpperCase()
  }
  if (props.breakDandT === false) breakDandT = false
  if (props.value) {
    return (
      <>
        {date}
        {breakDandT && <br />}
        {"  "} {time}
      </>
    )
  } else {
    return <span className="d-block text-left">{localTimeString}</span>
  }
}

export default DateFormat
