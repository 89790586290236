import {
  FormControl,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  InputLabel,
  Select,
  InputAdornment,
  IconButton,
  Button,
  Input,
  Typography,
  Alert,
  AlertTitle
} from "@mui/material"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { PATH } from "src/constants/paths"
import { ConfigService } from "src/service/config.service"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import {
  decrypt,
  decryptUrlParams,
  encrypt,
  fileExtensionCheck,
  getCurrentUserRole,
  getTranslations,
  setWeekFieldError,
  validateInteger
} from "src/utils/helper"
import csvIcon from "../../assets/images/CsvIcon.svg"
import { useDropzone } from "react-dropzone"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  API_RESPONSE,
  FTP_CONNECTIONS,
  ROLE_CONSTANTS
} from "src/utils/constants"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { Loader } from "../../../src/utils/reusables/reusable"
import { alertMsgShow } from "src/configurationPortal/pages/FunctionHelper"
const alertMsgInitialize = {
  severity: "error" as any,
  title: "",
  content: "" as any,
  class: "grey"
}
const CreateEditFtpConnection = () => {
  const { t } = useTranslation<any>()
  const userRole: string = getCurrentUserRole()
  const location = useLocation() as any
  const history = useHistory()
  const [name, setName] = useState("")
  const [weekIVFields, setWeekIVFields] = useState({
    Name: ""
  })
  const [protocolType, setProtocolType] = useState(FTP_CONNECTIONS.SFTP)
  const [host, setHost] = useState("")
  const [port, setPort] = useState("" as any)
  const [files, setFiles] = useState([] as any)
  const logonTypeOptions = ["Normal", "Key File"]
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const [defaultRemoteDirectory, setDefaultRemoteDirectory] = useState("")
  const [logonType, setLogonType] = useState("")
  const [securityType, setSecurityType] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [idConfigFtp, setIdConfigFtp] = useState("" as any)
  const [showPassword, setShowPassword] = useState(false)
  const [toastVisible, setToastVisible] = useState(false)
  const configService = new ConfigService()
  const [isConnectionTested, setIsConnectionTested] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [alertError, setAlertError] = useState(alertMsgInitialize)
  const customerList = useSelector(
    (state: RootState) => state.settings?.customers
  )
  const keys = useSelector((state: RootState) => state.auth.keys)
  const selectedCustomerList = customerList?.filter((c: any) => c.selected)
  let encryptPassword = password && encrypt(password)
  const prefixToRemove = "ftp://"
  const sanitizedHost = host?.startsWith(prefixToRemove)
    ? host.replace(prefixToRemove, "")
    : host

  const getPayload = () => {
    let payload = {
      idCustomer: customerId,
      name: name,
      protocolType: protocolType,
      host: sanitizedHost,
      port: parseInt(port),
      defaultRemoteDirectory: defaultRemoteDirectory,
      logonType: logonType,
      securityType: securityType,
      userName: userName,
      password:
        protocolType === FTP_CONNECTIONS.SFTP &&
        logonType === logonTypeOptions[1]
          ? null
          : encryptPassword,
      isConnectionTested: isConnectionTested
    }
    if (isEdit) payload["idConfigFtpConnection"] = idConfigFtp
    return payload
  }

  useEffect(() => {
    if (isConnectionTested) {
      setIsConnectionTested(false)
    }
  }, [
    name,
    protocolType,
    host,
    port,
    defaultRemoteDirectory,
    logonType,
    userName,
    password
  ])

  useEffect(() => {
    if (keys.length) {
      let pathname = location.pathname
      pathname = pathname.split("/")
      const len = pathname.length
      const mainRoute = pathname[len - 3] || ""
      const subRoute = pathname[len - 2] || ""
      const id: any = pathname[len - 1]
      const decryptedId: any = decryptUrlParams(id)

      let isEditVal: boolean = false
      if (mainRoute === "ftpConnection" && subRoute === "edit") {
        setIsEdit(true)
        setIdConfigFtp(decryptedId)
        isEditVal = true
      }

      if (isEditVal) {
        getFtpDetailsById(decryptedId)
      }
    }
  }, [keys])

  const getFtpDetailsById = idConfigFtpConnection => {
    configService
      .getFtpDetailsById(idConfigFtpConnection)
      .then((resp: any) => {
        if (resp.data?.password) {
          const decryptedPassword = decrypt(resp.data.password)
          setPassword(decryptedPassword)
        }
        setName(resp.data?.name)
        setDefaultRemoteDirectory(resp.data?.defaultRemoteDirectory)
        setProtocolType(resp.data?.protocolType)
        setHost(resp.data?.host)
        setUserName(resp.data?.userName)

        setPort(resp.data?.port)
        setSecurityType(resp.data?.securityType)
        setLogonType(resp.data?.idLogOnType)

        if (resp.status === Number(API_RESPONSE.SUCCESS)) {
          setLoading(false)
        }
      })
      .catch(err => err)
  }
  const submitSuccessAction = (res, operation) => {
    setSaving(false)
    if (res.status === Number(API_RESPONSE.SUCCESS) && operation === "SAVE") {
      setToastVisible(true)
      resetForm()
      setTimeout(() => {
        handleCancel()
      }, 2000)
    }
  }
  const updateConnection = (operation = "SAVE") => {
    setSaving(true)
    let payload = getPayload()
    const updatedPayload = {
      ...payload
    }
    configService
      .editFtpDetails(updatedPayload)
      .then(res => {
        submitSuccessAction(res, operation)
      })
      .catch(err => err)
  }

  const saveConnection = (operation = "SAVE") => {
    setSaving(true)
    let payload = getPayload()
    const updatedPayload = {
      ...payload
    }
    configService
      .testAndSaveConnection(updatedPayload)
      .then(res => {
        submitSuccessAction(res, operation)
      })
      .catch(err => err)
  }

  const testConnection = () => {
    let payload = getPayload()
    const updatedPayload = {
      ...payload,
      isConnectionTested: false
    }
    return configService.testAndSaveConnection(updatedPayload)
  }
  const handleSave = () => {
    if (!isEdit) {
      saveConnection()
    } else {
      updateConnection()
    }
  }
  const handleCancel = () => {
    history.push(`${PATH.CONFIGURATION}?tab=2`)
  }
  const handleChangeName = e => {
    setName(e.target.value)
    setWeekFieldError({
      setWeekIVFields,
      t,
      val: e.target.value,
      field: "Name"
    })
  }

  const handleChangeProtoType = e => {
    setProtocolType(e.target.value)
  }

  const handleChangeHost = e => {
    setHost(e.target.value)
  }

  const handleChangePort = e => {
    const value = e.target.value
    if (!validateInteger(value)) {
      setPort(value.substring(0, value.length - 1))
    } else {
      setPort(value)
    }
  }
  const handleChangeDefaultRemoteDirectory = e => {
    setDefaultRemoteDirectory(e.target.value)
  }
  const handleChangeUserName = e => {
    setUserName(e.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(show => !show)
  }

  const handleChangePassword = e => {
    setPassword(e.target.value)
  }
  const resetForm = () => {
    setName("")
    setProtocolType("")
    setPort("")
    setHost("")
    setDefaultRemoteDirectory("")
    setLogonType("")
    setSecurityType("")
    setUserName("")
    setPassword("")
    setShowPassword(false)
    setSaving(false)
    setIsConnectionTested(false)
  }

  const handleTestConnection = () => {
    setSaving(true)
    testConnection()
      .then(res => {
        setSaving(false)
        if (res.status === Number(API_RESPONSE.SUCCESS)) {
          setIsConnectionTested(true)
        }
      })
      .catch(_err => {
        setSaving(false)
      })
  }
  const handleChangeLogonType = e => {
    setLogonType(e.target.value)
  }

  const handleCsvFile = () => {
    return (
      <div>
        <Typography variant="body2">
          <strong>
            {
              //istanbul ignore next
              files[0]?.path
            }
          </strong>{" "}
        </Typography>
      </div>
    )
  }
  //istanbul ignore next
  const { getRootProps } = useDropzone({
    multiple: false,
    onError: error => {},
    onDrop: (acceptedFiles, rejectedFiles) => {
      setIsConnectionTested(false)
      if (rejectedFiles.length) {
        setAlertError(oldVal => ({
          ...oldVal,
          severity: "error",
          title: getTranslations(t, Translates.Error),
          content: JSON.stringify(rejectedFiles[0].errors[0].message) || ""
        }))
      } else if (acceptedFiles.length) {
        const fileName: string = acceptedFiles[0].name

        let errorFind = 0
        if (!fileExtensionCheck({ fileName, setAlertError, t })) {
          errorFind++
        }
        if (errorFind <= 0) {
          setFiles(acceptedFiles)
          const reader = new FileReader()
          setAlertError({ ...alertError, title: "", content: "" })
          reader.onload = async ({ target }) => {
            if (target?.result) {
              let result: any = target.result
              setPassword(result)
            }
          }
          reader.readAsText(acceptedFiles[0])
        }
      }
    }
  })

  useEffect(() => {
    if (alertError.title !== "") {
      setTimeout(() => {
        setAlertError(alertMsgInitialize)
      }, 3000)
    }
  }, [alertError.title])

  return (
    <Grid container>
      {toastVisible ? (
        <Alert
          severity="success"
          icon={<CheckCircleIcon />}
          className="custom-alert top"
        >
          <AlertTitle className="custom-alert__title f-600">
            {getTranslations(t, Translates.Success)}
          </AlertTitle>
          {isEdit ? (
            <p>
              {getTranslations(t, Translates.Connection_updated_successfully)}
            </p>
          ) : (
            <p>
              {getTranslations(t, Translates.Connection_added_successfully)}
            </p>
          )}
        </Alert>
      ) : null}
      <MiniHeader
        headerTitle={
          isEdit
            ? getTranslations(t, Translates.Configuration_Edit_Ftp_Connection)
            : getTranslations(t, Translates.Configuration_New_Ftp_Connection)
        }
      />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="inner-view__title">
                {isEdit
                  ? getTranslations(t, Translates.Edit_Ftp_Connection)
                  : getTranslations(t, Translates.New_Ftp_Connection)}
              </h4>
            </Grid>
            {loading && isEdit ? (
              <Grid item xs={12} className="text-center">
                {Loader()}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <label className="form-control__label mb-3">
                      {getTranslations(t, Translates.Settings)}
                    </label>
                    <TextField
                      fullWidth
                      label={getTranslations(t, Translates.Name)}
                      variant="filled"
                      value={name}
                      autoComplete="off"
                      disabled={isEdit}
                      onChange={handleChangeName}
                      className="custom-form-control"
                      error={!!weekIVFields.Name}
                      helperText={weekIVFields.Name}
                    />

                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={protocolType}
                        onChange={handleChangeProtoType}
                      >
                        <FormControlLabel
                          data-testid="protocolTypeOption2"
                          value={FTP_CONNECTIONS.SFTP}
                          control={<Radio />}
                          label={getTranslations(t, Translates.SFTP)}
                        />
                        <FormControlLabel
                          data-testid="protocolTypeOption1"
                          value={FTP_CONNECTIONS.FTP}
                          control={<Radio />}
                          label={getTranslations(t, Translates.FTP)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label={getTranslations(t, Translates.Host)}
                          variant="filled"
                          value={host}
                          autoComplete="off"
                          className="custom-form-control"
                          onChange={handleChangeHost}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label={getTranslations(t, Translates.Port)}
                          variant="filled"
                          value={port}
                          autoComplete="off"
                          onChange={handleChangePort}
                          className="custom-form-control"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label={getTranslations(
                            t,
                            Translates.Default_Remote_Directory
                          )}
                          variant="filled"
                          autoComplete="off"
                          value={defaultRemoteDirectory}
                          onChange={handleChangeDefaultRemoteDirectory}
                          className="custom-form-control"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    {protocolType === FTP_CONNECTIONS.SFTP ? (
                      <FormControl
                        variant="filled"
                        className="custom-form-control select"
                      >
                        <InputLabel id="groupSelect">Logon Type</InputLabel>
                        <Select
                          id="demo-multiple-checkbox"
                          fullWidth
                          value={logonType}
                          name="logonType"
                          onChange={handleChangeLogonType}
                          data-testid="logonTypeSelectField"
                        >
                          {logonTypeOptions.map((c: any) => {
                            return (
                              <MenuItem
                                key={c}
                                className="dropdown-list"
                                value={c}
                              >
                                <small>{c}</small>
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    ) : null}
                    <TextField
                      fullWidth
                      label={getTranslations(t, Translates.UserName)}
                      variant="filled"
                      value={userName}
                      autoComplete="new-password"
                      onChange={handleChangeUserName}
                      className="custom-form-control"
                    />
                    {protocolType === FTP_CONNECTIONS.FTP ||
                    (protocolType === FTP_CONNECTIONS.SFTP &&
                      logonType === logonTypeOptions[0]) ? (
                      <>
                        <FormControl
                          className="custom-pwd-field"
                          variant="filled"
                        >
                          <InputLabel htmlFor="standard-adornment-password">
                            {getTranslations(t, Translates.Password)}
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            className="custom-form-control"
                            autoComplete="new-password"
                            value={password}
                            type={showPassword ? "text" : "password"}
                            onChange={handleChangePassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  data-testid="passwordIcon"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </>
                    ) : null}
                  </Grid>

                  {protocolType === FTP_CONNECTIONS.SFTP &&
                  logonType === logonTypeOptions[1] ? (
                    <Grid item xs={12}>
                      <div>
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          className="upload-file  mb-2"
                        >
                          <img
                            src={csvIcon}
                            className="upload-file__icon mt-5"
                            style={{ cursor: "pointer" }}
                            alt="imag"
                          />
                          {!isEdit ? (
                            <p>
                              <label htmlFor="photo">
                                {getTranslations(
                                  t,
                                  Translates.Drop_your_file_here_or
                                )}
                                <Button
                                  component="span"
                                  variant="text"
                                  className="primary-btn f-600 ml-1"
                                >
                                  {getTranslations(t, Translates.browse)}
                                </Button>
                              </label>
                            </p>
                          ) : (
                            <p>
                              <label htmlFor="photo">
                                {getTranslations(
                                  t,
                                  Translates.Drop_your_txt_file_here_or_To_override
                                )}
                                <Button
                                  component="span"
                                  variant="text"
                                  className="primary-btn f-600 ml-1"
                                >
                                  {getTranslations(t, Translates.browse)}
                                </Button>
                              </label>
                            </p>
                          )}
                          {handleCsvFile()}
                        </div>
                      </div>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} className="d-flex align-items-center">
                    <Button
                      variant="contained"
                      className="secondary-btn mt-2"
                      onClick={handleTestConnection}
                      disabled={saving}
                      data-testid="TestConnectionBtn"
                    >
                      {getTranslations(t, Translates.Test_Connection)}
                    </Button>
                    {isConnectionTested ? (
                      <span className="success-info">
                        <CheckCircleIcon />
                        {getTranslations(t, Translates.Success)}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="inner-view__footer">
                    <Button
                      variant="text"
                      className="primary-btn cancel-btn mr-5"
                      onClick={handleCancel}
                      data-testid="cancelBtn"
                    >
                      {getTranslations(t, Translates.Cancel)}
                    </Button>
                    <Button
                      variant="contained"
                      className="primary-btn mr-3"
                      onClick={handleSave}
                      data-testid="saveBtn"
                      disabled={
                        !isConnectionTested ||
                        saving ||
                        userRole.toLowerCase() !==
                          ROLE_CONSTANTS.WisUser.toLowerCase() ||
                        selectedCustomerList.length > 1 ||
                        Object.values(weekIVFields).some(s => s || s !== "")
                      }
                    >
                      {getTranslations(t, Translates.Save)}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container className="justify-content-start mt-4">
          <Grid item xs={12}>
            {alertError.title !== "" ? <>{alertMsgShow(alertError)}</> : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateEditFtpConnection
