import React, { useEffect, useState } from "react"
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText
} from "@mui/material"
import { useHistory, useLocation } from "react-router-dom"
import CheckIcon from "@mui/icons-material/Check"
import {
  ProductsList,
  currencySymbolDisplay,
  decimalPositionDisplay,
  getCurrencySymbol,
  initCountFields,
  instructionDetails
} from "./Constants"
import { useDispatch } from "react-redux"
import BreadCrumb from "../../../../src/components/BreadCrumb/BreadCrumb"
import { setDefaultCountFields } from "../../../../src/redux/slices/configSlice"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { updateWarnOnTabChangeToStore } from "../helper"
import Instructions from "./Instructions"
import { CONFIG_MODULES } from "src/utils/constants"
import {
  LookupConfigToEditor,
  LookupEditorToConfig
} from "../LookupRoutines/helpers"
import { PATH } from "src/constants/paths"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations, setWeekFieldError } from "src/utils/helper"
interface ICreateFieldsProduct {
  field: any
  Enabled: boolean
  Labels: any
  ShowOnTagDetails: boolean
  ShowOnQtyEntry: boolean
  CurrencySymbol: boolean
  DecimalPlaces: any
  IsAllowEdits: boolean
  ValidationInstructions: any[]
  LookupInstructions: any[]
}

const CountFieldsCreateEditProduct = () => {
  const { t } = useTranslation<any>()
  const location = useLocation() as any
  let search = window.location.search || ""
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    config: {
      defaultCountFields,
      generalSettings,
      configType: configTypeFromStore
    }
  } = ConfigDataHook()

  const [form, setForm] = React.useState<ICreateFieldsProduct>({
    field: "",
    Enabled: true,
    Labels: [],
    ShowOnTagDetails: false,
    ShowOnQtyEntry: false,
    CurrencySymbol: false,
    DecimalPlaces: null,
    IsAllowEdits: false,
    ValidationInstructions: [],
    LookupInstructions: []
  } as ICreateFieldsProduct)

  const [isEdit, setIsEdit] = useState(false)

  const [weekIVFields, setWeekIVFields] = useState({
    displayName: ""
  })

  let productsMap = new Map()
  let productsArray: any = []

  for (let key in ProductsList) {
    if (ProductsList.hasOwnProperty(key)) {
      productsMap.set(key, ProductsList[key])
      let obj: any = Object.assign({}, { field: key, ...ProductsList[key] })
      productsArray.push(obj)
    }
  }

  let filtered = Object.keys(ProductsList).filter(key => {
    if (key === ProductsList.Ext.OjKey) {
      return (
        Object.keys(defaultCountFields).includes(ProductsList.Price.OjKey) &&
        !Object.keys(defaultCountFields).includes(key)
      )
    }
    return !Object.keys(defaultCountFields).includes(key)
  })
  const [fieldName, setFieldName] = useState("")
  const [errorMsg, setErrorMsg] = useState({
    field: false,
    displayName: false,
    DecimalPlaces: false
  })
  const [fieldErrorMsg, setFieldErrorMsg] = useState(false)
  const locationState = { ...location.state }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (locationState.isEdit === true) {
        setIsEdit(true)
        setFieldName(locationState.data.field)

        let ValidationInstructions: any[] =
          locationState?.data?.ValidationInstructions || []
        if (locationState?.data?.field === ProductsList.Qty.OjKey) {
          if (!ValidationInstructions?.length) {
            ValidationInstructions =
              initCountFields?.Qty?.ValidationInstructions
          }
        }
        setForm({
          ...form,
          ...locationState.data,
          ...{
            IsAllowEdits: locationState?.data?.IsAllowEdits ? true : false,
            ValidationInstructions: LookupConfigToEditor(ValidationInstructions)
          },
          ...{
            LookupInstructions: locationState?.data?.LookupInstructions
              ? LookupConfigToEditor(locationState?.data?.LookupInstructions)
              : []
          },
          ...([ProductsList.Qty.OjKey].includes(locationState?.data?.field) &&
            locationState?.data?.IsAllowEdits === undefined && {
              IsAllowEdits: true
            })
        })
      } else if (locationState.data) {
        const data = JSON.parse(locationState.data)
        setFieldName(data?.field)
        if (data?.isEdit) {
          setIsEdit(true)
        }

        setForm({
          ...form,
          ...data?.CountFieldData,
          ...(data?.component ===
            CONFIG_MODULES.CountFieldInstructions.validation && {
            ValidationInstructions: data?.Instructions
          }),
          ...(data?.component ===
            CONFIG_MODULES.CountFieldInstructions.Lookup && {
            LookupInstructions: data?.Instructions
          })
        })
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  const getBreadcrumbConfig = () => {
    let countFields = "countFields"
    let custLinks: any = {}
    custLinks["@countFields"] = "configuration/countFields"
    let product = "Product"
    let currentPath: string = `##configuration##/@${countFields}/${product}`
    let variables: any = {}
    variables[`@${countFields}`] = getTranslations(t, Translates.Count_Fields)
    variables[`${product}`] = getTranslations(t, Translates.Product)
    let bConfig: any = {
      custLinks,
      showHomeIcon: false,
      currentPath,
      mapObj: variables
    }
    return bConfig
  }

  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value || ""
    if (e.target?.name === "field" && !decimalPositionDisplay.includes(val)) {
      setErrorMsg({ ...errorMsg, DecimalPlaces: false })
    }
    if (e.target?.name === "field" && !isEdit) {
      let obj = productsMap.get(val)
      val.length < 1 ? setFieldErrorMsg(true) : setFieldErrorMsg(false)
      setFieldName(val)
      setForm({
        ...form,
        field: val,
        ...obj,
        Labels: [
          {
            "en-us": obj.Labels[0]["en-us"]
          }
        ]
      })
    } else if (e.target?.name === "displayName") {
      if (val.length < 1) setErrorMsg({ ...errorMsg, displayName: true })
      else setErrorMsg({ ...errorMsg, displayName: false })
      setForm({ ...form, Labels: [{ "en-us": val }] })
      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    } else if (e.target?.name === "DecimalPlaces") {
      let val = e.target.value
      if (val.length !== 1 || !/\d/.test(val))
        setErrorMsg({ ...errorMsg, DecimalPlaces: true })
      else setErrorMsg({ ...errorMsg, DecimalPlaces: false })
      setForm({ ...form, DecimalPlaces: e.target.value })
    } else if (
      [
        "IsAllowEdits",
        "ShowOnQtyEntry",
        "CurrencySymbol",
        "ShowOnTagDetails"
      ].includes(e.target?.name)
    ) {
      setForm({ ...form, [e.target.name]: e.target.checked })
    } else if (e.target?.name) {
      setForm({ ...form, [e.target.name]: val })
    }
  }

  const addEditFieldsProduct = async () => {
    updateWarnOnTabChangeToStore(false)
    let DataField = ""
    let Enabled = true
    const BreakException = {}
    try {
      productsArray.forEach(i => {
        if (i?.field === form?.field) {
          DataField = i?.DataField
          Enabled = i?.Enabled
          throw BreakException
        }
      })
    } catch (e) {
      if (e !== BreakException) throw e
    }

    let _payload: any = {
      field: form?.field,
      Enabled: Enabled,
      ...(DataField && { DataField: DataField }),
      Labels: form?.Labels,
      IsAllowEdits: form?.IsAllowEdits,
      ShowOnTagDetails: form?.ShowOnTagDetails,
      ShowOnQtyEntry: form?.ShowOnQtyEntry
    }
    if (currencySymbolDisplay.includes(form?.field)) {
      const { cDisplayMap } = getCurrencySymbol()
      _payload = {
        ..._payload,
        CurrencySymbol: form?.CurrencySymbol
          ? cDisplayMap.get(generalSettings.Currencies) ?? ""
          : ""
      }
    }
    if (
      decimalPositionDisplay.includes(form?.field) &&
      form?.DecimalPlaces !== ""
    ) {
      _payload = { ..._payload, DecimalPlaces: form?.DecimalPlaces }
    }

    if (instructionFieldsCheck()) {
      if (form?.IsAllowEdits) {
        _payload = {
          ..._payload,
          ...{
            IsAllowEdits: form?.IsAllowEdits,
            ValidationInstructions: LookupEditorToConfig(
              form?.ValidationInstructions
            ),
            ...(form?.field === ProductsList.Department.OjKey &&
              form?.LookupInstructions?.length && {
                LookupInstructions: LookupEditorToConfig(
                  form?.LookupInstructions
                )
              })
          }
        }
      }
    }

    if (_payload.Labels.length === "") {
      setErrorMsg({ ...errorMsg, field: true })
      return null
    } else {
      setErrorMsg({ ...errorMsg, field: false })
    }
    if (form?.field?.length < 1) {
      setFieldErrorMsg(true)
      return null
    } else {
      setFieldErrorMsg(false)
    }
    let fieldKey: any = _payload?.field

    delete _payload?.field

    let finalObj: any = {
      data: { [fieldKey]: { ...defaultCountFields[fieldKey], ..._payload } },
      type: "UPDATE"
    }

    dispatch(setDefaultCountFields(finalObj))

    setTimeout(() => {
      cancelClick()
    }, 100)
  }

  const cancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    history.push(PATH.CONFIGURATION_COUNT_FIELDS + search)
  }

  const errorCheck = () => {
    if (
      !form?.Labels[0]?.["en-us"] ||
      !form?.field ||
      errorMsg.DecimalPlaces ||
      errorMsg.displayName ||
      errorMsg.field ||
      (form?.IsAllowEdits && !form?.ValidationInstructions?.length) ||
      configTypeFromStore === "view" ||
      Object.values(weekIVFields).some(s => s || s !== "")
    ) {
      return true
    }
    return false
  }

  const instructionFieldsCheck = () => {
    return [
      ProductsList.Price.OjKey,
      ProductsList.Department.OjKey,
      ProductsList.Qty.OjKey
    ].includes(form?.field)
  }

  const labelErrorDisplay = () => {
    if (errorMsg.displayName) {
      return getTranslations(t, Translates.Please_enter_valid_Display_Name)
    } else if (weekIVFields.displayName) {
      return weekIVFields.displayName
    }
    return ""
  }

  return (
    <div>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item xs={12} className="d-flex align-items-center">
              <div className="custom-breadcrumb CP">
                <BreadCrumb
                  customPath={"Location"}
                  breadCrumbInfo={getBreadcrumbConfig()}
                  renderedPage={""}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Grid container className="main-container">
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <label className="form-control__label mb-3">
                {getTranslations(t, Translates.DETAILS)}
              </label>
              <div className="text-box">
                <FormControl
                  variant="filled"
                  className="custom-form-control select"
                >
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    variant="filled"
                  >
                    {getTranslations(t, Translates.Selected_Count_Field)}
                  </InputLabel>
                  <Select
                    required
                    id="demo-multiple-checkbox"
                    name="field"
                    value={form?.field || ""}
                    onChange={e => onFormChange(e)}
                    disabled={isEdit}
                    error={fieldErrorMsg}
                    inputProps={{ "data-testid": "selecct-count-field" }}
                  >
                    {isEdit
                      ? productsArray?.map((product: any) => (
                          <MenuItem
                            key={product.field}
                            value={product.field}
                            className="dropdown-list"
                          >
                            <small>{product.field}</small>
                          </MenuItem>
                        ))
                      : filtered?.map((product: any) => (
                          <MenuItem
                            key={product}
                            value={product}
                            className="dropdown-list"
                          >
                            <small>{product}</small>
                          </MenuItem>
                        ))}
                  </Select>
                  {fieldErrorMsg ? (
                    <FormHelperText error>
                      {getTranslations(
                        t,
                        Translates.Please_select_valid_Count_Field
                      )}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={getTranslations(t, Translates.Display_Name)}
                variant="filled"
                autoSave="false"
                autoComplete="off"
                name="displayName"
                value={form?.Labels[0]?.["en-us"] || ""}
                className="custom-form-control"
                error={
                  errorMsg.displayName || !!weekIVFields.displayName
                    ? true
                    : false
                }
                helperText={labelErrorDisplay()}
                onChange={e => onFormChange(e)}
              />
            </Grid>
          </Grid>
          <Grid container>
            {decimalPositionDisplay.includes(fieldName) && (
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label={getTranslations(
                    t,
                    Translates.Number_of_decimal_places
                  )}
                  variant="filled"
                  value={form?.DecimalPlaces}
                  name="DecimalPlaces"
                  autoSave="false"
                  autoComplete="off"
                  className="custom-form-control"
                  error={errorMsg.DecimalPlaces ? true : false}
                  helperText={
                    errorMsg.DecimalPlaces
                      ? `${getTranslations(
                          t,
                          Translates.Please_enter_valid_Decimal_Place
                        )}`
                      : ""
                  }
                  onChange={e => onFormChange(e)}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            {currencySymbolDisplay.includes(fieldName) && (
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form?.CurrencySymbol}
                      className="custom-check light"
                      name="CurrencySymbol"
                      onChange={e => onFormChange(e)}
                      checkedIcon={<CheckIcon />}
                      data-testid="CurrencySymbol"
                    />
                  }
                  label={getTranslations(t, Translates.Show_Currency_Symbol)}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="custom-check light"
                    checked={form?.ShowOnTagDetails}
                    name="ShowOnTagDetails"
                    onChange={e => onFormChange(e)}
                    checkedIcon={<CheckIcon />}
                    data-testid="Tag Detail Screen"
                  />
                }
                label={getTranslations(t, Translates.Tag_Detail_Screen)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="custom-check light"
                    checked={form?.ShowOnQtyEntry}
                    name="ShowOnQtyEntry"
                    onChange={e => onFormChange(e)}
                    disabled={
                      form?.field === ProductsList.Qty.OjKey ||
                      form?.field === ProductsList.Ext.OjKey
                    }
                    checkedIcon={<CheckIcon />}
                    data-testid="Qty Entry Screen"
                  />
                }
                label={getTranslations(t, Translates.Qty_Entry_Screen)}
              />
            </Grid>
          </Grid>
        </Grid>

        {instructionFieldsCheck() ? (
          <Instructions
            instructionDetails={{
              ValidationInstructions: instructionDetails.ValidationInstructions,
              ...(form?.field === ProductsList.Department.OjKey && {
                LookupInstructions: instructionDetails.LookupInstructions
              })
            }}
            setForm={setForm}
            form={form}
            locationState={locationState}
            componentName={CONFIG_MODULES.CountFieldInstructions.Product}
            onFormChange={onFormChange}
          />
        ) : null}

        <Grid item xs={12}>
          <div className="inner-view__footer">
            <Button
              variant="text"
              className="primary-btn cancel-btn mr-5"
              onClick={cancelClick}
            >
              {getTranslations(t, Translates.Cancel)}
            </Button>
            <Button
              variant="contained"
              className="primary-btn"
              disabled={errorCheck()}
              onClick={() => addEditFieldsProduct()}
            >
              {getTranslations(t, Translates.Save)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CountFieldsCreateEditProduct
