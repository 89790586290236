import { PromptTypes, formField } from "src/utils/constants"
import { ConfigPromptType, PromptFieldType } from "../Types"
import { MUIAutoCompleteField } from "src/utils/types/types"

export const createEditPromptFieldNames = {
  Label: { name: "Label", label: "Label" },
  VariableName: { name: "VariableName", label: "Variable_Name" },
  Type: { name: "Type", label: "Type" },
  NumberOfDecimals: { name: "NumberOfDecimals", label: "Enter_Decimal_Places" },
  CustomList: { name: "CustomList", label: "Custom_List" },
  DefaultValue: { name: "DefaultValue", label: "Default_Value" }
}

export const PromptTypeOptions: MUIAutoCompleteField["props"]["options"] = [
  { label: PromptTypes.Decimal, value: PromptTypes.Decimal },
  { label: PromptTypes.Text, value: PromptTypes.Text },
  { label: PromptTypes.Alpha, value: PromptTypes.Alpha },
  { label: PromptTypes.Numeric, value: PromptTypes.Numeric },
  { label: PromptTypes.LocationCategory, value: PromptTypes.LocationCategory },
  { label: PromptTypes.LocationRange, value: PromptTypes.LocationRange },
  { label: PromptTypes.Department, value: PromptTypes.Department },
  { label: PromptTypes.ParentDepartment, value: PromptTypes.ParentDepartment },
  { label: PromptTypes.CustomList, value: PromptTypes.CustomList }
]
export const PromptCustomListOptions: MUIAutoCompleteField["props"]["options"] =
  [
    { label: "SCANNED", value: "SCANNED" },
    { label: "KEYED", value: "KEYED" }
  ]

export const createEditPromptDefaultIniValues: ConfigPromptType = {
  Label: "",
  VariableName: "",
  Type: "",
  NumberOfDecimals: 0,
  CustomList: [],
  DefaultValue: ""
}

export const PromptFieldDetails: PromptFieldType = {
  label: {
    fieldType: formField.text,
    props: {
      name: createEditPromptFieldNames.Label.name,
      label: createEditPromptFieldNames.Label.label,
      required: true
    }
  },
  variableName: {
    fieldType: formField.text,
    props: {
      name: createEditPromptFieldNames.VariableName.name,
      label: createEditPromptFieldNames.VariableName.label,
      required: true
    }
  },
  type: {
    fieldType: formField.auto_dropdown,
    props: {
      name: createEditPromptFieldNames.Type.name,
      label: createEditPromptFieldNames.Type.label,
      required: true,
      isOptionTranslate: true,
      options: PromptTypeOptions
    },
    resetFieldNames: [
      {
        name: createEditPromptFieldNames.NumberOfDecimals.name,
        value: 0,
        isErrOnlyReset: true
      },
      {
        name: createEditPromptFieldNames.CustomList.name,
        value: [],
        isErrOnlyReset: true
      }
    ]
  },
  numberOfDecimals: {
    fieldType: formField.text,
    condition: {
      name: createEditPromptFieldNames.Type.name,
      value: PromptTypes.Decimal
    },
    props: {
      name: createEditPromptFieldNames.NumberOfDecimals.name,
      label: createEditPromptFieldNames.NumberOfDecimals.label,
      required: true
    }
  },
  customList: {
    fieldType: formField.multi_auto_dropdown,
    condition: {
      name: createEditPromptFieldNames.Type.name,
      value: PromptTypes.CustomList
    },
    props: {
      name: createEditPromptFieldNames.CustomList.name,
      label: createEditPromptFieldNames.CustomList.label,
      required: true,
      options: PromptCustomListOptions
    }
  },
  defaultValue: {
    fieldType: formField.text,
    props: {
      name: createEditPromptFieldNames.DefaultValue.name,
      label: createEditPromptFieldNames.DefaultValue.label
    }
  }
}
