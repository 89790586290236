import { useEffect, useReducer } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { Tab, Tabs, Box, Grid } from "@mui/material"
import { TabPanel } from "../CountPrograms/Component/TabFunctionsAndConstant"
import VariancesToolBar from "src/components/ConfigurationPortal/VariancesToolBar/VariancesToolBar"
import {
  a11Props,
  getConfigToFormConversion,
  getDefaultValues,
  getFormToConfigConversion
} from "./Utils/FunctionsHelper"
import { validationSchema } from "./Utils/Validations"
import { VarianceTypes, fieldNames } from "./Utils/Constants"
import VarianceForm from "./Components/VarianceLanding/VarianceForm"
import { VarianceType } from "./Utils/Type"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  GetConfigPayload,
  alertMsgShow,
  comparingConfigAndReduxArrays,
  emptyAlert,
  updateConfigurationData
} from "../FunctionHelper"
import { setVarianceRedux } from "src/redux/slices/ConfigPortal/VarianceSlice"
import { useAppThunkDispatch } from "src/redux/store"
import { getTranslations } from "src/utils/helper"
import { reducer } from "../helper"
import { Translates } from "src/i18n/i18n"

let alertVisible: boolean = false
let alertMsg = emptyAlert
const Variances = () => {
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const [, setReload] = useReducer(reducer, false)

  const {
    varianceData: { varianceRedux },
    config: { configDetails, isGetConfigDecrypt }
  } = ConfigDataHook()

  const formHookMethods = useForm<any>({
    defaultValues: getDefaultValues(varianceRedux),
    resolver: yupResolver(validationSchema(Translates, t)),
    criteriaMode: "all",
    mode: "onChange"
  })

  const { watch, setValue, handleSubmit, reset, getValues } = formHookMethods

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (varianceRedux && Object.keys(varianceRedux).length) {
        // istanbul ignore next
        if (JSON.stringify(getValues()) !== JSON.stringify(varianceRedux)) {
          reset(varianceRedux)
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [varianceRedux])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (configDetails?.configuration && isGetConfigDecrypt) {
        const dt = JSON.parse(configDetails.configuration)
        const { reduxData } = getConfigToFormConversion({
          GroupVariance: dt.GroupedVariances,
          FullVariance: dt.FullStoreVariances
        })
        // istanbul ignore next
        if (
          !comparingConfigAndReduxArrays(
            reduxData.GroupVariance,
            getValues().GroupVariance
          )
        ) {
          comparingConfigAndReduxArrays(
            reduxData.FullVariance,
            getValues().FullVariance
          )
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [configDetails, isGetConfigDecrypt])

  const hideAlert = () => {
    setTimeout(() => {
      alertVisible = false
      alertMsg = emptyAlert
      // istanbul ignore next
      setReload(oldVal => !oldVal)
    }, 3000)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(fieldNames.CurrentTab, newValue)
    setValue(
      fieldNames.CurrentTabVarianceType,
      VarianceTypes[Object.keys(VarianceTypes)[newValue]].name
    )
  }

  const variancesSaveClick = async (data: VarianceType) => {
    const { configData } = getFormToConfigConversion(data)
    let { payload } = GetConfigPayload()
    payload = {
      ...payload,
      configuration: JSON.stringify({
        ...payload.configuration,
        GroupedVariances: configData.GroupVariance,
        FullStoreVariances: configData.FullVariance,
        FilesRequired: {
          DepartmentFileNotRequired: false,
          ProductFileNotRequired: false
        }
      })
    }
    const { alertMsgUC } = await updateConfigurationData({ payload, t })
    if (Object.keys(alertMsgUC).length) {
      alertVisible = true
      alertMsg = { ...emptyAlert, ...alertMsgUC }
      // istanbul ignore next
      setReload(oldVal => !oldVal)
      hideAlert()
    }
    const locData = JSON.parse(JSON.stringify(data))
    dispatch(setVarianceRedux(locData))
  }

  const variancesErrorSubmit = errs => {
    alertVisible = true
    alertMsg = {
      severity: "error",
      title: "Error",
      content:
        'Please fill all mandatory fields Correctly in "Group & Full" Variance!'
    }
    setReload(oldVal => !oldVal)
    hideAlert()
  }
  return (
    <>
      <VariancesToolBar
        variancesSaveClick={variancesSaveClick}
        variancesErrorSubmit={variancesErrorSubmit}
        handleSubmit={handleSubmit}
      />

      <FormProvider {...formHookMethods}>
        <div className="main-container">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="custom-tabs"
            >
              <Tabs
                value={watch(fieldNames.CurrentTab)}
                onChange={handleChange}
                aria-label="basic tabs example"
                data-testid="CPVarianceTab"
              >
                {Object.keys(VarianceTypes).map((i, j) => (
                  <Tab
                    key={i}
                    label={getTranslations(t, VarianceTypes[i].label)}
                    {...a11Props(j)}
                  />
                ))}
              </Tabs>
            </Box>
            {Object.keys(VarianceTypes).map((i, j) => {
              return (
                <TabPanel
                  key={i}
                  value={watch(fieldNames.CurrentTab)}
                  index={j}
                >
                  <VarianceForm />
                </TabPanel>
              )
            })}
          </Box>
        </div>
      </FormProvider>
      <Grid container>
        {alertVisible ? <>{alertMsgShow(alertMsg)}</> : null}
      </Grid>
    </>
  )
}

export default Variances
