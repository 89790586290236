import { ifElseToReadable, LookupConfigToEditor } from "../helpers"
import { getLayout, setLayout } from "../layout.service"
import { functionTypes } from "."
export const renderConversionFromConfig = (configLookup, index) => {
  if (configLookup.length) {
    let layout = getLayout()
    if (layout.length <= 0 && (index || index === 0) && index !== -1) {
      layout = LookupConfigToEditor(configLookup[index].Instructions)
      setLayout(layout)
    }
    if (layout.length) {
      setLayoutIfElseReadable(layout)
    }
  }
  return true
}

export const setLayoutIfElseReadable = (layout, isIfReadableCheck = false) => {
  if (layout && layout.length && isIfReadableCheck) {
    layout.forEach((i, inx) => {
      if ([functionTypes.If].includes(i.function)) {
        layout[inx] = {
          ...layout[inx],
          ...{ content: ifElseToReadable(i.array) }
        }
      }
    })
  }
  layout = Array.isArray(layout) ? layout : []
  setLayout(layout)
  return layout
}
