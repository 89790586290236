import {
  AlertColor,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ReadOnlyLayout from "../LookupRoutines/ReadOnlyLayout/ReadOnlyLayout"
import shortid from "shortid"
import { COUNT_PROGRAMS_TYPE } from "../CountPrograms/Constant"
import { CONFIG_MODULES, KEY_CODES } from "src/utils/constants"
import { makeLayoutAllVariableEmpty } from "../LookupRoutines/helpers"
import { setLayoutIfElseReadable } from "../LookupRoutines/LRConstants/lookupRenderFromConfig"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo"
import { getToCopy, handleToPaste } from "../CountPrograms/helper"
import AlertC from "src/components/Alert/Alert"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
import { useCallback, useReducer } from "react"
import { ProductsList } from "./Constants"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { reducer } from "../helper"
interface Props {
  instructionDetails: { ValidationInstructions: any; LookupInstructions?: any }
  setForm: Function
  form: any
  locationState: any
  componentName: string
  onFormChange: Function
}

let isConfirmOpen: boolean = false
let showAlert: boolean = false
let alertProps = {
  title: "",
  severity: "success" as AlertColor,
  content: ""
}

let pasteInfo = {
  name: "",
  instructions: []
}

const Instructions = ({
  instructionDetails,
  setForm,
  form,
  locationState,
  componentName,
  onFormChange
}: Props) => {
  const { t } = useTranslation<any>()
  const [, setReload] = useReducer(reducer, false)

  const handleMoveValuesKeyUp = useCallback((e: any, layout: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      moveValuesToRedux(layout)
    }
  }, [])
  const moveValuesToRedux = layout => {
    makeLayoutAllVariableEmpty()
    setLayoutIfElseReadable(layout)
  }

  let storeId = -1
  if (locationState?.isEdit || locationState?.index >= 0) {
    storeId = locationState?.index
  }

  const getTextPrepareToCopy = async (clickBoardName, instructions) => {
    const { isCopied, locAlertProps } = await getToCopy(
      clickBoardName,
      instructions
    )
    if (isCopied) {
      alertProps = locAlertProps
      showAlert = true
      setReload(oldVal => !oldVal)
      setTimeout(() => {
        showAlert = false
        setReload(oldVal => !oldVal)
      }, 1000)
    }
  }

  const setDeleteContent = useCallback(
    () => {
      return (
        <AlertConfirmationContent
          warningMsg={`Are you sure you want to override the instructions?`}
        />
      )
    },
    //eslint-disable-next-line
    []
  )

  const callbackConfirmation = useCallback(
    (res: boolean) => {
      if (res) {
        setForm({ ...form, ...{ [pasteInfo.name]: pasteInfo.instructions } })
        pasteInfo = { name: "", instructions: [] }
      }
      isConfirmOpen = false
      setReload(oldVal => !oldVal)
    },
    //eslint-disable-next-line
    [pasteInfo]
  )

  const handleTextPrepareToPaste = async (
    clickBoardName,
    previousInstructions,
    instructionsName
  ) => {
    const {
      locAlertProps,
      instructions,
      isNonEmptyArray,
      locPasteInfo,
      isAlertShow,
      isValueSet
    } = await handleToPaste(
      clickBoardName,
      previousInstructions,
      instructionsName
    )

    if (isAlertShow) {
      alertProps = locAlertProps
      showAlert = true
      setReload(oldVal => !oldVal)
      setTimeout(() => {
        showAlert = false
        setReload(oldVal => !oldVal)
        if (isValueSet)
          setForm({ ...form, ...{ [instructionsName]: instructions } })
      }, 1000)
      return false
    } else if (isNonEmptyArray) {
      pasteInfo = locPasteInfo
      isConfirmOpen = true
      setReload(oldVal => !oldVal)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                className="custom-check light"
                checked={form?.IsAllowEdits}
                checkedIcon={<CheckIcon />}
                name="IsAllowEdits"
                onChange={e => onFormChange(e)}
                disabled={form?.field === ProductsList.Qty.OjKey}
              />
            }
            label={getTranslations(t, Translates.Allow_Edits)}
          />
        </Grid>
      </Grid>
      {form?.IsAllowEdits ? (
        <Grid container spacing={2} className="mt-2 ml-3">
          {Object.keys(instructionDetails).map((i, j) => {
            let instructions = []
            let clickBoardName = CONFIG_MODULES.CopyPastInstructions.validation
            let instructionsName = "ValidationInstructions"
            if (
              instructionDetails[i].component ===
              CONFIG_MODULES.CountFieldInstructions.validation
            ) {
              instructions = form?.ValidationInstructions
            } else if (
              instructionDetails[i].component ===
              CONFIG_MODULES.CountFieldInstructions.Lookup
            ) {
              instructions = form?.LookupInstructions
              clickBoardName = CONFIG_MODULES.CopyPastInstructions.Lookup
              instructionsName = "LookupInstructions"
            }
            return (
              <Grid item xs={12} sm={4} key={shortid.generate()}>
                <h4>{getTranslations(t, instructionDetails[i].title)}*</h4>
                <Grid container className="mt-3">
                  <Grid item xs={11} className="gray-bg-1 pa-4 row-copy">
                    <span className={`row-paste__icon mt-1 `}>
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Paste)}
                      >
                        <ContentPasteGoIcon
                          onClick={e =>
                            handleTextPrepareToPaste(
                              clickBoardName,
                              instructions,
                              instructionsName
                            )
                          }
                        />
                      </Tooltip>
                    </span>

                    <span
                      className={`row-copy__icon mt-1 ${
                        instructions?.length ? "" : "cursor-not-allowed"
                      }`}
                    >
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Copy)}
                      >
                        <ContentCopyIcon
                          onClick={() =>
                            getTextPrepareToCopy(clickBoardName, instructions)
                          }
                        />
                      </Tooltip>
                    </span>
                    <div
                      className="mt-2"
                      onClick={() => moveValuesToRedux(instructions)}
                      onKeyUp={e => handleMoveValuesKeyUp(e, instructions)}
                      data-testid="readonly-layout-div"
                    >
                      <ReadOnlyLayout
                        form={{
                          Name: i,
                          Instructions: instructions,
                          CountFieldData: form,
                          component: instructionDetails[i].component,
                          isEdit: locationState?.isEdit
                        }}
                        index={storeId}
                        type={
                          storeId === -1
                            ? undefined
                            : getTranslations(t, COUNT_PROGRAMS_TYPE.EDIT)
                        }
                        component={instructionDetails[i].component}
                        countFieldComponent={componentName}
                      />
                    </div>
                  </Grid>
                </Grid>
                {isConfirmOpen ? (
                  <ConfirmDialog
                    open={isConfirmOpen}
                    callBack={callbackConfirmation}
                    title={`CONFIRMATION`}
                    content={setDeleteContent}
                    custref={{}}
                    confirmButtonText={"CONFIRM"}
                    cancelButtonText={"CANCEL"}
                  />
                ) : null}
              </Grid>
            )
          })}
        </Grid>
      ) : null}
      <Grid container>{showAlert ? <AlertC {...alertProps} /> : null}</Grid>
    </>
  )
}

export default Instructions
