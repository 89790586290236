import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { toolBarHearder } from "src/utils/reusables/reusable"
import { useTranslation } from "react-i18next"

const AuditsToolBar = ({ AuditsSave }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config.configType
  )

  const handleAuditSaveClick = () => {
    AuditsSave()
  }

  return (
    <div className="main-toolbar">
      <div className="main-container">
        {toolBarHearder(handleAuditSaveClick, configTypeFromStore, t)}
      </div>
    </div>
  )
}

export default AuditsToolBar
