import { useReducer } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { Button, Divider, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { Translates } from "src/i18n/i18n"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { useAppThunkDispatch } from "src/redux/store"
import {
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import {
  getColumnsData,
  getVariableBasedOnComponent,
  setModalContent
} from "./Utils/Helpers/PromptsGridHelper"
import { ConfigPromptPreDeletedRowType, ConfigPromptType } from "./Utils/Types"
import FormHeading from "src/components/FormHeading"
let preDeletedRow: ConfigPromptPreDeletedRowType =
  {} as ConfigPromptPreDeletedRowType
let showWarningModal: boolean = false
const Prompts = ({
  componentName,
  fieldName
}: {
  componentName: string
  fieldName: string
}) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const [, setReload] = useReducer(reducer, false)
  const dispatch = useAppThunkDispatch()

  const { setValue, getValues } = useFormContext()

  const { Prompts } = getValues()

  let search = window.location.search || ""

  let { variableSetRedux, tableComponent, createPath, editPath } =
    getVariableBasedOnComponent(componentName, storeId, -1)

  const handleCreatePromptClick = () => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))

    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          createPath,
          [":tab", ":tabId"],
          [componentName.toLocaleLowerCase(), `${storeId}`]
        ) + search,
        {
          variableRedux: getValues()
        }
      )
    })
  }

  const updatePrompt = (row1: {
    original: ConfigPromptType
    index: number
  }) => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))
    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          editPath,
          [":tab", ":tabId", ":id"],
          [componentName.toLocaleLowerCase(), `${storeId}`, `${row1.index + 1}`]
        ) + search,
        {
          variableRedux: getValues()
        }
      )
    })
  }
  const deletePrompt = (row1: {
    original: ConfigPromptType
    index: number
  }) => {
    showWarningModal = true
    preDeletedRow = {
      ...row1.original,
      index: row1.index,
      Label: row1.original.Label[0]["en-us"]
    }
    setReload(oldVal => !oldVal)
  }

  const CallbackConfirmation = (res: boolean) => {
    if (res) {
      let dt: ConfigPromptType[] = Prompts.filter(
        (f: ConfigPromptType, inx) => inx !== preDeletedRow.index
      )
      setValue(fieldName, dt)
    }
    showWarningModal = false
    preDeletedRow = {} as ConfigPromptPreDeletedRowType
    setReload(oldVal => !oldVal)
  }

  const columnsData = getColumnsData({
    updatePrompt: updatePrompt,
    deletePrompt: deletePrompt,
    t
  })

  return (
    <>
      <Grid
        container
        className="custom-table-responsive categories-table"
        data-testid="reportings-display-grid"
      >
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />

          <Grid container>
            <Grid item xs={6}>
              <FormHeading
                heading={`${getTranslations(t, Translates.PROMPTS)}`}
              />
            </Grid>

            <Grid item xs={6} className="text-right">
              <Button
                variant="contained"
                data-testid="reportPromptCreate"
                className="primary-btn pa-2"
                onClick={handleCreatePromptClick}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Prompt)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item className="mt-2">
          <GlobalTableDragDrop
            component={tableComponent}
            columns={columnsData}
            data={Prompts || []}
            noDataText={getTranslations(
              t,
              Translates.No_Prompts_data_available
            )}
          />
        </Grid>
        {showWarningModal && (
          <ConfirmDialog
            open={showWarningModal}
            callBack={CallbackConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setModalContent({
              preDeletedRow,
              t
            })}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.CANCEL)}
          />
        )}
      </Grid>
    </>
  )
}

export default Prompts
