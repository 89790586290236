//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"
const api = new ApiService()
export class ConfigurationService {
  getColumnNamesAndDataTypes() {
    const endPoint = `api/ConfigurationPortal/GetColumnNamesAndDataTypes`
    return api.findAll(endPoint)
  }
}
//END-NOSCAN
