import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import {
  Customer_Input_Fields,
  DataTypeList,
  ErrorLevelList,
  FileTypeRadioValueList,
  INPUT_FILE_ACTION_TYPE,
  nameErrorText
} from "src/constants/inputFiles"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  setDelimitedInputFields,
  setExcelInputFields,
  setFixedLengthInputFields
} from "src/redux/slices/configSlice"
import { Loader } from "src/utils/reusables/reusable"
import { getTranslations, validateDecimal } from "src/utils/helper"
import { RootState } from "src/redux/store"
import { onCancelClick } from "../FunctionHelper"
interface ICreateField {
  Name: string
  DataType: string
  AllowedLengths: string
  Regex: string
  ErrorLevel: string
  Length: string
}
const CreateInputFields = () => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const location: any = useLocation()
  const {
    config: {
      configDetails,
      isGetConfigDecrypt,
      delimitedInputFields,
      excelInputFields,
      defaultCountFields,
      fixedLengthInputFields
    }
  } = ConfigDataHook()

  let locationState: any = { ...location.state }
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [fileType, setFileType] = useState("")
  const [list, setList] = useState([])
  const [duplicateNameError, setDuplicateNameError] = useState(false)
  const [helperText, setHelperText] = React.useState("")
  useEffect(() => {
    setLoading(true)
    if (configDetails?.configuration && isGetConfigDecrypt) {
      const dt = JSON.parse(configDetails.configuration)
      let fieldsOptionsList
      if (!dt.Fields || Object.keys(dt.Fields).length === 0) {
        fieldsOptionsList = [
          defaultCountFields.ProductCaptured.Labels[0]["en-us"],
          defaultCountFields.Qty.Labels[0]["en-us"]
        ]
      } else {
        fieldsOptionsList = Object.keys(dt.Fields)
          .filter(
            i =>
              dt.Fields[i].CurrencySymbol !== undefined &&
              dt.Fields[i].DecimalPlaces !== undefined
          )
          .map(i => {
            return dt.Fields[i].Labels[0]["en-us"]
          })
      }
      setList(fieldsOptionsList)
    }
    if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
      setForm({
        ...locationState.data
      })
    }

    setFileType(locationState.radioValue)
    setLoading(false)
  }, [configDetails, isGetConfigDecrypt])

  const [form, setForm] = React.useState<ICreateField>({
    Name: "",
    DataType: "",
    AllowedLengths: "",
    Regex: "",
    ErrorLevel: "",
    Length: ""
  } as ICreateField)

  const getBreadcrumbConfig = () => {
    let currentPath: string = `fields`
    let variables: any = {
      fields:
        locationState.type === INPUT_FILE_ACTION_TYPE.EDIT
          ? getTranslations(t, Translates.Edit_Field)
          : getTranslations(t, Translates.Add_Field)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const onFormChange = e => {
    const val = e.target.value
    if (e.target.name) {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target.name === Customer_Input_Fields.Name) {
      setHelperText("")
      setDuplicateNameError(false)
    }

    if (e.target.name === Customer_Input_Fields.AllowedLengths) {
      let locError = false
      val.split(",")?.forEach(element => {
        if (!validateDecimal(element) && element !== "") {
          locError = true
        }
      })

      if (locError) {
        setForm({
          ...form,
          [e.target.name]: val.substring(0, val.length - 1)
        })
      }
    }

    if (e.target.name === Customer_Input_Fields.Length) {
      if (!validateDecimal(val)) {
        setForm({
          ...form,
          [e.target.name]: val.substring(0, val.length - 1)
        })
      }
    }
  }

  const onSaveClick = () => {
    const fixedLengthSortOrder =
      fixedLengthInputFields.length > 0
        ? fixedLengthInputFields?.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0
    const delimitedSortOrder =
      delimitedInputFields?.length > 0
        ? delimitedInputFields?.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0
    const excelSortOrder =
      excelInputFields?.length > 0
        ? excelInputFields?.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0
    const getSortOrder = () => {
      if (locationState.radioValue === FileTypeRadioValueList[0].value) {
        return fixedLengthSortOrder + 1
      } else if (locationState.radioValue === FileTypeRadioValueList[1].value) {
        return delimitedSortOrder + 1
      } else {
        return excelSortOrder + 1
      }
    }
    const _item = {
      Name: form.Name,
      DataType: form.DataType,
      AllowedLengths: form.AllowedLengths,
      Regex: form.Regex,
      SortOrder: getSortOrder()
    }
    const getItem = () => {
      if (fileType === FileTypeRadioValueList[0].value) {
        let fixedPayload = {
          ..._item,
          Length: form.Length
        }
        return fixedPayload
      } else {
        let fixedPayload = {
          ..._item,
          ErrorLevel: form.ErrorLevel
        }
        return fixedPayload
      }
    }
    let error: any = false
    let foundIndex: number = Number(locationState.index)
    let duplicateField: any = fixedLengthInputFields?.find(
      (duplicateField: any, idx) => {
        return duplicateField?.Name === form.Name && foundIndex !== idx
      }
    )
    let delimitedField: any = delimitedInputFields?.find(
      (delimitedField: any, idx) => {
        return delimitedField?.Name === form.Name && foundIndex !== idx
      }
    )
    let excelField: any = excelInputFields?.find((excelField: any, idx) => {
      return excelField?.Name === form.Name && foundIndex !== idx
    })
    if (
      (fileType === FileTypeRadioValueList[0].value && duplicateField) ||
      (fileType === FileTypeRadioValueList[1].value && delimitedField) ||
      (fileType === FileTypeRadioValueList[2].value && excelField)
    ) {
      setDuplicateNameError(true)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      error = true
      setHelperText(nameErrorText)
    }
    if (!error) {
      if (locationState.radioValue === FileTypeRadioValueList[0].value) {
        if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
          let fixedLengthIndex: any = fixedLengthInputFields?.findIndex(
            x => x?.Name === locationState.data?.Name
          )
          let newArr = [...fixedLengthInputFields]
          newArr[fixedLengthIndex] = getItem()
          dispatch(setFixedLengthInputFields([...newArr]))
        } else {
          dispatch(
            setFixedLengthInputFields([...fixedLengthInputFields, getItem()])
          )
        }
      } else if (locationState.radioValue === FileTypeRadioValueList[1].value) {
        if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
          let delimitedIndex: any = delimitedInputFields?.findIndex(
            x => x?.Name === locationState.data?.Name
          )
          let newArr = [...delimitedInputFields]
          newArr[delimitedIndex] = getItem()
          dispatch(setDelimitedInputFields([...newArr]))
        } else {
          dispatch(
            setDelimitedInputFields([...delimitedInputFields, getItem()])
          )
        }
      } else {
        if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
          let excelIndex: any = excelInputFields?.findIndex(
            x => x?.Name === locationState.data?.Name
          )
          let newArr = [...excelInputFields]
          newArr[excelIndex] = getItem()
          dispatch(setExcelInputFields([...newArr]))
        } else {
          dispatch(setExcelInputFields([...excelInputFields, getItem()]))
        }
      }
      history.goBack()
    }
  }

  const saveButtonDisableCheck = () => {
    let locError = false

    if (!form.Name || configTypeFromStore === "view") {
      locError = true
    }
    form.AllowedLengths?.split(",")?.forEach(element => {
      if (!validateDecimal(element)) {
        locError = true
      }
    })
    return locError
  }
  return (
    <>
      {loading ? (
        Loader()
      ) : (
        <div>
          <div className="main-toolbar">
            <div className="main-container">
              <Grid container>
                <Grid item sm={6} className="d-flex align-items-center">
                  <div className="custom-breadcrumb CP">
                    <BreadCrumb
                      customPath={"Add New Field"}
                      breadCrumbInfo={getBreadcrumbConfig()}
                      renderedPage={""}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className="main-container">
            <Grid item xs={12} sm={5} className="mb-8">
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label className="form-control__label mb-3">
                    {getTranslations(t, Translates.DETAILS)}
                  </label>
                  <div>
                    <FormControl
                      variant="filled"
                      className="custom-form-control select"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                        htmlFor="detailsName"
                      >
                        {getTranslations(t, Translates.Name)}
                      </InputLabel>
                      <Select
                        data-testid="detailsName"
                        id="demo-multiple-checkbox"
                        value={form.Name}
                        name="Name"
                        error={duplicateNameError}
                        onChange={e => onFormChange(e)}
                        aria-labelledby="demo-multiple-checkbox-label"
                      >
                        {list.map((name: any) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="dropdown-list"
                          >
                            <small>{name}</small>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText
                      className="red-text"
                      style={{ marginTop: "-10px" }}
                    >
                      {helperText}
                    </FormHelperText>
                  </div>
                  {fileType === FileTypeRadioValueList[0].value ? (
                    <div className="text-box">
                      <TextField
                        data-testid="lengths"
                        fullWidth
                        label={getTranslations(t, Translates.Length)}
                        variant="filled"
                        autoSave="false"
                        autoComplete="off"
                        className="custom-form-control"
                        onChange={e => onFormChange(e)}
                        name="Length"
                        value={form.Length}
                      />
                    </div>
                  ) : null}
                  <FormControl
                    variant="filled"
                    className="custom-form-control select"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                      htmlFor="detailsDataType"
                    >
                      {getTranslations(t, Translates.Data_Type)}
                    </InputLabel>
                    <Select
                      data-testid="dataType"
                      id="demo-multiple-checkbox"
                      value={form.DataType}
                      name="DataType"
                      onChange={e => onFormChange(e)}
                      aria-labelledby="demo-multiple-checkbox-label"
                    >
                      {DataTypeList.map((name: any) => (
                        <MenuItem
                          key={name}
                          value={name}
                          className="dropdown-list"
                        >
                          <small>{getTranslations(t, name)}</small>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="text-box">
                    <TextField
                      fullWidth
                      data-testid="allowedLengths"
                      label={getTranslations(t, Translates.Allowed_Lengths)}
                      variant="filled"
                      autoSave="false"
                      autoComplete="off"
                      className="custom-form-control"
                      onChange={e => onFormChange(e)}
                      name="AllowedLengths"
                      value={form.AllowedLengths}
                    />
                  </div>
                  <div className="text-box">
                    <TextField
                      fullWidth
                      data-testid="regex"
                      label={getTranslations(t, Translates.Reg_Ex)}
                      variant="filled"
                      autoSave="false"
                      autoComplete="off"
                      className="custom-form-control"
                      onChange={e => onFormChange(e)}
                      name="Regex"
                      value={form.Regex}
                    />
                  </div>
                  {fileType !== FileTypeRadioValueList[0].value ? (
                    <FormControl
                      variant="filled"
                      className="custom-form-control select"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                        htmlFor="detailsErrorLevel"
                      >
                        {getTranslations(t, Translates.Error_Level)}
                      </InputLabel>
                      <Select
                        data-testid="errorLevel"
                        id="demo-multiple-checkbox"
                        value={form.ErrorLevel}
                        name="ErrorLevel"
                        onChange={e => onFormChange(e)}
                        aria-labelledby="demo-multiple-checkbox-label"
                      >
                        {ErrorLevelList.map((name: any) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="dropdown-list"
                          >
                            <small>{getTranslations(t, name)}</small>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="inner-view__footer">
                <Button
                  data-testid="cancelBtn"
                  variant="text"
                  className="primary-btn cancel-btn mr-5"
                  onClick={() => onCancelClick(history)}
                >
                  {getTranslations(t, Translates.Cancel)}
                </Button>
                <Button
                  data-testid="saveBtn"
                  variant="contained"
                  className="primary-btn"
                  disabled={saveButtonDisableCheck()}
                  onClick={onSaveClick}
                >
                  {getTranslations(t, Translates.Save)}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default CreateInputFields
