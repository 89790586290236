import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface LiveEventsState {
  status: string
  liveEventsFilters: any
  liveEventsFilterForm: any
  liveEventsSelectedSpans: any
  liveEventsData: any
}

const initialState: LiveEventsState = {
  status: "",
  liveEventsFilters: {
    region1: [],
    region2: [],
    region3: [],
    region4: [],
    country: [],
    state: [],
    currentDate: "",
    eventStatus: []
  },
  liveEventsFilterForm: {
    region1: [],
    region2: [],
    region3: [],
    region4: [],
    country: [],
    state: [],
    currentDate: "",
    eventStatus: []
  },
  liveEventsSelectedSpans: [],
  liveEventsData: []
}
export const liveEventsSlice = createSlice({
  name: "liveEventsList",
  initialState,
  reducers: {
    setLiveEventsFilters: (state, _action: PayloadAction<any>) => {
      state.liveEventsFilters = {
        ...state.liveEventsFilters,
        ..._action.payload
      }
    },
    setLiveEventsFilterForm: (state, _action: PayloadAction<any>) => {
      state.liveEventsFilterForm = { ..._action.payload }
    },
    setLiveEventsSelectedSpans: (state, _action: PayloadAction<any>) => {
      state.liveEventsSelectedSpans = [..._action.payload]
    },
    setLiveEventsData: (state, _action: PayloadAction<any>) => {
      state.liveEventsData = [..._action.payload]
    }
  }
})
export const {
  setLiveEventsFilters,
  setLiveEventsFilterForm,
  setLiveEventsSelectedSpans,
  setLiveEventsData
} = liveEventsSlice.actions

export default liveEventsSlice.reducer
