import { HereService } from "./here.service"
import ApiService from "src/utils/useAxios"
import { setKeys } from "src/redux/slices/authSlice"
import { store } from "src/redux/store"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { decryptUrlParams } from "src/utils/helper"
export class InItService {
  hereService = new HereService()
  api = new ApiService()
  //istanbul ignore next
  async init() {
    let token = localStorage.getItem("hereToken")
    let expireIn: any = localStorage.getItem("hereTokenExpireIn")
    expireIn = parseInt(expireIn)
    if (
      !token ||
      token === "**" ||
      (token.length > 5 && Math.floor(expireIn / 3600) < 1)
    ) {
      this.hereService.getToken()
    }

    if (!store.getState()?.auth.keys?.length) {
      this.api
        .findAll("/api/Login")
        .then(res => {
          if (res?.data && res?.data?.length) {
            const _temp = res?.data?.map(a => ({
              secretKey: a.secretKey,
              secretValue: decryptUrlParams(a.secretValue)
            }))
            store.dispatch(setKeys(_temp))
          }
        })
        .catch(function (err) {
          throw err
        })
    }
    updateWarnOnTabChangeToStore(false, true)
  }
  getTemplates(){
    const endPoint = "/api/Customer/GetBulkUploadTemplates"
    return this.api.findAll(endPoint)
  }
}
