import { useCallback, useReducer } from "react"
import { useFormContext } from "react-hook-form"
import { useDispatch } from "react-redux"
import { AlertColor, Grid, Tooltip } from "@mui/material"
import { useParams } from "react-router-dom"
import shortid from "shortid"
import ReadOnlyLayout from "../../LookupRoutines/ReadOnlyLayout/ReadOnlyLayout"
import {
  COUNT_PROGRAMS_TYPE,
  fieldNames
} from "src/configurationPortal/pages/CountPrograms/Constant"
import { instructionDetails } from "./TabFunctionsAndConstant"
import { setNewCountProgram } from "src/redux/slices/configSlice"
import { makeLayoutAllVariableEmpty } from "../../LookupRoutines/helpers"
import { setLayoutIfElseReadable } from "../../LookupRoutines/LRConstants/lookupRenderFromConfig"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo"
import { CONFIG_MODULES, KEY_CODES } from "src/utils/constants"
import AlertC from "src/components/Alert/Alert"
import { ProductsList } from "../../CountFields/Constants"
import { getToCopy, handleToPaste } from "../helper"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import AlertConfirmationContent from "../../Components/AlertConfirmationContent"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { reducer } from "../../helper"
interface Props {
  setupInstructionsName: string
  validationInstructionsName: string
  lookupInstructionsName: string
  bConfig: {
    custLinks: any
    showHomeIcon: boolean
    currentPath: string
    mapObj: any
  }
  disable?: boolean
  product?: string
}

let isConfirmOpen: boolean = false
let showAlert: boolean = false
let alertProps = {
  title: "",
  severity: "success" as AlertColor,
  content: ""
}

let pasteInfo = {
  name: "",
  instructions: []
}
const Instructions = ({
  setupInstructionsName,
  validationInstructionsName,
  lookupInstructionsName,
  bConfig,
  disable,
  product
}: Props) => {
  const { t } = useTranslation<any>()
  const [, setReload] = useReducer(reducer, false)

  const { watch, getValues, setValue } = useFormContext()
  const dispatch = useDispatch()

  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const handleMoveValuesKeyUp = useCallback((e: any, layout: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      moveValuesToRedux(layout)
    }
  }, [])
  const triggerSetReload = () => {
    setReload(oldVal => !oldVal)
  }
  const moveValuesToRedux = layout => {
    makeLayoutAllVariableEmpty()
    setLayoutIfElseReadable(layout)
    dispatch(setNewCountProgram(getValues()))
  }
  const isConditionObject = product === ProductsList.Qty.OjKey

  const getTextPrepareToCopy = async (clickBoardName, instructions) => {
    const { isCopied, locAlertProps } = await getToCopy(
      clickBoardName,
      instructions
    )
    if (isCopied) {
      alertProps = locAlertProps
      showAlert = true
      triggerSetReload()
      setTimeout(() => {
        showAlert = false
        triggerSetReload()
      }, 1000)
    }
  }

  const handleTextPrepareToPaste = async (clickBoardName, instructionsName) => {
    const {
      locAlertProps,
      instructions,
      isNonEmptyArray,
      locPasteInfo,
      isAlertShow,
      isValueSet
    } = await handleToPaste(
      clickBoardName,
      watch(instructionsName),
      instructionsName
    )

    if (isAlertShow) {
      alertProps = locAlertProps
      showAlert = true
      triggerSetReload()
      setTimeout(() => {
        showAlert = false
        triggerSetReload()
        //istanbul ignore next
        if (isValueSet) setValue(instructionsName, instructions)
      }, 1000)
      return false
    } else if (isNonEmptyArray) {
      pasteInfo = locPasteInfo
      isConfirmOpen = true
      triggerSetReload()
    }
  }

  const setDeleteContent = useCallback(
    () => {
      return (
        <AlertConfirmationContent
          warningMsg={`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_override_the_instructions
          )}?`}
        />
      )
    },
    //eslint-disable-next-line
    []
  )

  const callbackConfirmation = useCallback(
    (res: boolean) => {
      if (res) {
        setValue(pasteInfo.name, pasteInfo.instructions)
        pasteInfo = { name: "", instructions: [] }
      }
      isConfirmOpen = false
      triggerSetReload()
    },
    //eslint-disable-next-line
    [pasteInfo]
  )

  return (
    <>
      <Grid item xs={12} className="mt-3">
        <Grid container spacing={2}>
          {instructionDetails.map((i, j) => {
            let instructionsName = setupInstructionsName
            let clickBoardName = CONFIG_MODULES.CopyPastInstructions.setup
            if (j === 1) {
              instructionsName = validationInstructionsName
              clickBoardName = CONFIG_MODULES.CopyPastInstructions.validation
            } else if (j === 2) {
              instructionsName = lookupInstructionsName
              clickBoardName = CONFIG_MODULES.CopyPastInstructions.Lookup
            }
            return (
              <Grid item xs={12} sm={4} key={shortid.generate()}>
                <h4 className={`${disable ? "opacity-50" : ""}`}>
                  {getTranslations(t, i.title)}
                </h4>
                <Grid container className="mt-3">
                  <Grid item xs={11} className="gray-bg-1 pa-4 row-copy">
                    <span
                      className={`row-paste__icon mt-1 ${
                        disable ? "cursor-not-allowed" : ""
                      }`}
                    >
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Paste)}
                      >
                        <ContentPasteGoIcon
                          data-testid="pasteIcon"
                          onClick={e =>
                            handleTextPrepareToPaste(
                              clickBoardName,
                              instructionsName
                            )
                          }
                        />
                      </Tooltip>
                    </span>

                    <span
                      className={`row-copy__icon mt-1 ${
                        watch(instructionsName)?.length && !disable
                          ? ""
                          : "cursor-not-allowed"
                      }`}
                    >
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Copy)}
                      >
                        <ContentCopyIcon
                          data-testid="copyIcon"
                          onClick={() =>
                            getTextPrepareToCopy(
                              clickBoardName,
                              watch(instructionsName)
                            )
                          }
                        />
                      </Tooltip>
                    </span>
                    <div
                      className="mt-2"
                      onClick={() => moveValuesToRedux(watch(instructionsName))}
                      onKeyUp={e =>
                        handleMoveValuesKeyUp(e, watch(instructionsName))
                      }
                      data-testid="readonly-layout-div"
                    >
                      <ReadOnlyLayout
                        form={{
                          Name: watch(fieldNames.name.name),
                          Instructions: watch(instructionsName),
                          InstructionName: instructionsName,
                          isConditionObject: isConditionObject,
                          productMode: product
                        }}
                        index={storeId}
                        type={
                          storeId === -1 ? undefined : COUNT_PROGRAMS_TYPE.EDIT
                        }
                        component={i.component}
                        bConfig={bConfig}
                        disable={disable}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        {isConfirmOpen ? (
          <ConfirmDialog
            open={isConfirmOpen}
            callBack={callbackConfirmation}
            title={getTranslations(t, Translates.CONFIRMATION)}
            content={setDeleteContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.Cancel)}
          />
        ) : null}
      </Grid>
      <Grid container>{showAlert ? <AlertC {...alertProps} /> : null}</Grid>
    </>
  )
}

export default Instructions
