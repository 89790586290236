import { FILE_TYPE, PromptTypes, formField } from "src/utils/constants"
import { GeneralFieldType } from "../Types"
import { GeneralFieldDetails as ReportGeneralFieldDetails } from "src/configurationPortal/pages/Reports/Utils/Constants/CreateEditReportConstant"

export const createEditOutputFieldNames = {
  FileType: { name: "FileType", label: "File_Type" },
  FieldDelimiter: { name: "FieldDelimiter", label: "Field_Delimiter" },
  OtherFieldDelimiter: {
    name: "OtherFieldDelimiter",
    label: "OTHER"
  },
  RecordDelimiter: { name: "RecordDelimiter", label: "Record_Delimiter" },
  OtherRecordDelimiter: {
    name: "OtherRecordDelimiter",
    label: "OTHER"
  },
  AsciiCharValue: { name: "AsciiCharValue", label: FILE_TYPE.ASCIICharValue },
  HasHeaderRecord: { name: "HasHeaderRecord", label: "Has_Header_Record" },
  Prompts: { name: "Prompts", label: "Prompts" },
  RawSQL: { name: "RawSQL", label: "RawSQL" },
  SQL: { name: "SQL", label: "SQL" },
  Columns: { name: "Columns", label: "Columns" },
  SubTotals: { name: "SubTotals", label: "Sub Totals" },
  GrandTotals: { name: "GrandTotals", label: "Grand Totals" }
}

export const fileTypeOptions = [
  { value: PromptTypes.Text, label: PromptTypes.Text },
  { value: "EXCEL", label: "EXCEL" },
  { value: "EBCDIC", label: "EBCDIC" }
]

export const fieldDelimiterOptions = [
  { value: "NONE", label: "NONE" },
  { value: "\t", label: "TAB" },
  { value: ",", label: "COMMA" },
  { value: "|", label: "PIPE" },
  { value: ":", label: "COLON" },
  { value: ";", label: "SEMI COLON" },
  { value: PromptTypes.Other, label: PromptTypes.Other }
]

export const recordDelimiterOptions = [
  {
    label: "NONE",
    value: "NONE"
  },
  {
    label: "{CR}{LF}",
    value: "\r\n"
  },
  {
    label: "{LF}",
    value: "\n"
  },
  {
    label: "{CR}",
    value: "\r"
  },
  {
    label: FILE_TYPE.ASCIICharValue,
    value: FILE_TYPE.ASCIICharValue
  },
  {
    label: FILE_TYPE.Other,
    value: FILE_TYPE.Other
  }
]

export const GeneralFieldDetails: GeneralFieldType = {
  name: ReportGeneralFieldDetails.name,
  description: ReportGeneralFieldDetails.description,
  fileName: ReportGeneralFieldDetails.fileName,
  fileType: {
    fieldType: formField.auto_dropdown,
    props: {
      name: createEditOutputFieldNames.FileType.name,
      label: createEditOutputFieldNames.FileType.label,
      options: fileTypeOptions,
      required: true
    },
    resetFieldNames: [
      {
        name: createEditOutputFieldNames.FieldDelimiter.name,
        value: "",
        isErrOnlyReset: true
      },
      {
        name: createEditOutputFieldNames.RecordDelimiter.name,
        value: "",
        isErrOnlyReset: true
      }
    ]
  },
  fieldDelimiter: {
    fieldType: formField.auto_dropdown,
    condition: {
      name: createEditOutputFieldNames.FileType.name,
      value: PromptTypes.Text
    },
    props: {
      name: createEditOutputFieldNames.FieldDelimiter.name,
      label: createEditOutputFieldNames.FieldDelimiter.label,
      options: fieldDelimiterOptions,
      required: true
    },
    parentCols: { sm: 3 },
    childCols: { sm: 12 },
    resetFieldNames: [
      {
        name: createEditOutputFieldNames.OtherFieldDelimiter.name,
        value: "",
        isErrOnlyReset: true
      }
    ]
  },
  otherFieldDelimiter: {
    fieldType: formField.text,
    condition: {
      name: createEditOutputFieldNames.FieldDelimiter.name,
      value: "OTHER"
    },
    props: {
      name: createEditOutputFieldNames.OtherFieldDelimiter.name,
      label: createEditOutputFieldNames.OtherFieldDelimiter.label,
      required: true,
      className: "ml-2"
    },
    parentCols: { sm: 3 },
    childCols: { sm: 12 }
  },
  recordDelimiter: {
    fieldType: formField.auto_dropdown,
    condition: {
      name: createEditOutputFieldNames.FileType.name,
      value: PromptTypes.Text
    },
    props: {
      name: createEditOutputFieldNames.RecordDelimiter.name,
      label: createEditOutputFieldNames.RecordDelimiter.label,
      options: recordDelimiterOptions,
      required: true
    },
    isNewRow: true,
    parentCols: { sm: 3 },
    childCols: { sm: 12 },
    resetFieldNames: [
      {
        name: createEditOutputFieldNames.OtherRecordDelimiter.name,
        value: "",
        isErrOnlyReset: true
      }
    ]
  },
  otherRecordDelimiter: {
    fieldType: formField.text,
    condition: {
      name: createEditOutputFieldNames.RecordDelimiter.name,
      value: "OTHER"
    },
    props: {
      name: createEditOutputFieldNames.OtherRecordDelimiter.name,
      label: createEditOutputFieldNames.OtherRecordDelimiter.label,
      required: true,
      className: "ml-2"
    },
    parentCols: { sm: 3 },
    childCols: { sm: 12 }
  },
  asciiCharValue: {
    fieldType: formField.text,
    condition: {
      name: createEditOutputFieldNames.RecordDelimiter.name,
      value: FILE_TYPE.ASCIICharValue
    },
    props: {
      name: createEditOutputFieldNames.AsciiCharValue.name,
      label: createEditOutputFieldNames.AsciiCharValue.label,
      required: true,
      className: "ml-2"
    },
    parentCols: { sm: 3 },
    childCols: { sm: 12 }
  },
  hasHeaderRecord: {
    fieldType: formField.checkbox,
    props: {
      name: createEditOutputFieldNames.HasHeaderRecord.name,
      label: createEditOutputFieldNames.HasHeaderRecord.label,
      className: "light"
    }
  }
}
