import React from "react"
export const fieldConstants = {
  SortOrder: {
    ASC: "ASC",
    DESC: "DESC"
  },
  SortColumns: {
    startDate: "startDate",
    lastUpdatedDate: "lastUpdatedDate",
    scheduledDateTime: "scheduledDateTime",
    createdDate: "createdDate",
    reservation: "reservation"
  },
  SortColumnType: {
    Date: "Date",
    Interger: "Interger"
  }
}
export const timeOut = 1000
