import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ConfigOutputBundleType } from "src/configurationPortal/pages/OutputBundles/Utils/Types"

export interface OutputBundleSliceType {
  outputBundlesRedux: ConfigOutputBundleType[]
  isSetOutputBundlesRedux: boolean
}

const initialState: OutputBundleSliceType = {
  outputBundlesRedux: [],
  isSetOutputBundlesRedux: false
}
export const outputBundleSlice = createSlice({
  name: "outputBundle",
  initialState,
  reducers: {
    setOutputBundlesRedux: (state, _action: PayloadAction<any>) => {
      state.outputBundlesRedux = [...(_action.payload || [])]
      state.isSetOutputBundlesRedux = true
    }
  },
  extraReducers: builder => {}
})
export const { setOutputBundlesRedux } = outputBundleSlice.actions
export default outputBundleSlice.reducer
