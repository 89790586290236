import { Divider, Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import {
  ConfigurationDetailsFields,
  createEditFieldNames
} from "../../Utils/Constants/CreateEditMultiEventOutput"
import FormFieldConditionRender from "src/configurationPortal/pages/Components/FormFieldConditionRender"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useEffect, useState } from "react"
import { MUIAutoCompleteField } from "src/utils/types/types"
import Loading from "src/components/Loading/Loading"

const ConfigurationIncludes = () => {
  const {
    multiEventOutputData: { configFilesByCustomersRedux }
  } = ConfigDataHook()

  const { t } = useTranslation<any>()

  const { watch, setValue } = useFormContext()
  const loadings = watch(createEditFieldNames.Loadings.name)

  const [configOptions, setConfigOptions] = useState<
    MUIAutoCompleteField["props"]["options"]
  >([])
  useEffect(() => {
    let isMounted = true

    if (isMounted && Array.isArray(configFilesByCustomersRedux)) {
      if (configFilesByCustomersRedux.length) {
        setConfigOptions(
          configFilesByCustomersRedux.map(i => ({
            value: Number(i.configId),
            label: `${i.configName}(${i.configVersion})`
          }))
        )

        if (watch(createEditFieldNames.ConfigurationIncludes.name).length) {
          const firstArrayConfigIds = configFilesByCustomersRedux.map(i =>
            Number(i.configId)
          )
          const result = watch(
            createEditFieldNames.ConfigurationIncludes.name
          ).filter(item => firstArrayConfigIds.includes(item))
          setValue(createEditFieldNames.ConfigurationIncludes.name, result)
        }
        setValue(createEditFieldNames.Loadings.name, {
          ...loadings,
          isConfigAPICall: false
        })
      } else {
        setConfigOptions([])
        setTimeout(() => {
          setValue(createEditFieldNames.Loadings.name, {
            ...loadings,
            isConfigAPICall: false
          })
        }, 10000)
      }
    }
  }, [configFilesByCustomersRedux])

  const ConfigurationDetailsFields1 = {
    ...ConfigurationDetailsFields,
    configurationIncludes: {
      ...ConfigurationDetailsFields.configurationIncludes,
      props: {
        ...ConfigurationDetailsFields.configurationIncludes.props,
        options: configOptions
      }
    }
  }
  const loading = Object.keys(loadings)
    .filter(i => ["isConfigAPICall"].includes(i))
    .some(i => loadings[i])
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Divider className="dashed my-4" />
        <label className="form-control__label mb-3">
          {`${getTranslations(t, Translates.Configurations_Include)}`}{" "}
          <span className="selected">{`(${
            watch(createEditFieldNames.ConfigurationIncludes.name).length || 0
          } ${getTranslations(t, Translates.Selected)})`}</span>
        </label>
      </Grid>
      {loading ? (
        <Loading isMarginTop={false} isJustifyContent={false} />
      ) : (
        <FormFieldConditionRender FieldDetails={ConfigurationDetailsFields1} />
      )}
    </>
  )
}

export default ConfigurationIncludes
