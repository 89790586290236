import * as yup from "yup"
import { PromptTypes, oneOfErr, requiredErr } from "src/utils/constants"
import { createEditReportFieldNames } from "src/configurationPortal/pages/Reports/Utils/Constants/CreateEditReportConstant"
import {
  createEditOutputFieldNames,
  fieldDelimiterOptions,
  fileTypeOptions,
  recordDelimiterOptions
} from "src/configurationPortal/pages/Outputs/Utils/Constants/CreateEditOutputConstant"
import { destinationValidation } from "../../Reports/Utils/Validations/CreateEditReportValidation"
import { emptyFieldValues } from "./../../helper"
import { getTranslations, validateInputString } from "src/utils/helper"
const fieldDelimiterOptionsValues = fieldDelimiterOptions.map(
  item => item.value
)
const fieldDelimiterOptions1 = [
  ...fieldDelimiterOptionsValues,
  ...fieldDelimiterOptions
    .filter(i => !fieldDelimiterOptionsValues.includes(i.label))
    .map(item => item.label)
]
const recordDelimiterOptionsValues = recordDelimiterOptions.map(
  item => item.value
)
const recordDelimiterOptions1 = [
  ...recordDelimiterOptionsValues,
  ...recordDelimiterOptions
    .filter(i => !recordDelimiterOptionsValues.includes(i.label))
    .map(item => item.label)
]
export const generalSettingValidation = (t, Translates) => {
  return yup.object().shape({
    [createEditReportFieldNames.Name.name]: yup
      .string()
      .required(requiredErr)
      .test(
        createEditReportFieldNames.Name.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),
    [createEditReportFieldNames.Description.name]: yup
      .string()
      .required(requiredErr),
    [createEditReportFieldNames.FileName.name]: yup
      .string()
      // .max(30, "File name can not be more than 30 wew")
      .required(requiredErr)
      .test(
        createEditReportFieldNames.FileName.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),
    [createEditOutputFieldNames.FileType.name]: yup
      .string()
      .required(requiredErr)
      .oneOf(
        [...emptyFieldValues, ...fileTypeOptions.map(item => item.value)],
        oneOfErr
      ),
    [createEditOutputFieldNames.FieldDelimiter.name]: yup
      .string()
      .nullable()
      .oneOf([...emptyFieldValues, ...fieldDelimiterOptions1], oneOfErr)
      .when([createEditOutputFieldNames.FileType.name], {
        is: Type => Type && Type === PromptTypes.Text,
        then: yup.string().required(requiredErr)
      }),
    [createEditOutputFieldNames.OtherFieldDelimiter.name]: yup
      .string()
      .when([createEditOutputFieldNames.FieldDelimiter.name], {
        is: Type => Type && Type === PromptTypes.Other,
        then: yup.string().required(requiredErr)
      }),
    [createEditOutputFieldNames.RecordDelimiter.name]: yup
      .string()
      .nullable()
      .oneOf([...emptyFieldValues, ...recordDelimiterOptions1], oneOfErr)
      .when([createEditOutputFieldNames.FileType.name], {
        is: Type => Type && Type === PromptTypes.Text,
        then: yup.string().required(requiredErr)
      }),
    [createEditOutputFieldNames.OtherRecordDelimiter.name]: yup
      .string()
      .when([createEditOutputFieldNames.RecordDelimiter.name], {
        is: Type => Type && Type === PromptTypes.Other,
        then: yup.string().required(requiredErr)
      })
  })
}
export const validationSchema = (t, Translates) => {
  return yup.object().shape({
    ...destinationValidation().fields,
    ...generalSettingValidation(t, Translates).fields
  })
}
