import { Alert, AlertTitle } from "@mui/material"
import { Warning } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

const AlertConfirmationContent = ({ warningMsg }: { warningMsg: string }) => {
  const { t } = useTranslation<any>()
  return (
    <Alert severity="error" icon={<Warning />} className="custom-alert">
      <AlertTitle className="alert__title">
        <h2>{getTranslations(t, Translates.Warning)}</h2>
      </AlertTitle>
      <p>
        <strong className="d-block">{warningMsg}</strong>
      </p>
    </Alert>
  )
}

export default AlertConfirmationContent
