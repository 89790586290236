/* istanbul ignore file */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useLocation } from "react-router-dom"
import { PATH } from "src/constants/paths"

// istanbul ignore next
const InactivityLogout: React.FC = () => {
  const [isInactive, setIsInactive] = useState(false)
  const location = useLocation()
  // istanbul ignore next
  const broadcastChannel = new BroadcastChannel("inactivityChannel") // Channel name can be any string
  // istanbul ignore next
  const resetTimer = () => {
    // updated inactive time to 4 hours
    const allowedInactive =   4 * 60 * 60 * 1000
    clearTimeout(inactivityTimer)
    inactivityTimer = setTimeout(logout, allowedInactive) // 60 minutes
  }
  // istanbul ignore next
  const handleActivity = () => {
    if (isInactive) {
      setIsInactive(false)
      broadcastChannel.postMessage({ type: "activity" }) // Notify other tabs of activity
      resetTimer()
    }
  }
  // istanbul ignore next
  const handleInactivity = () => {
    setIsInactive(true)
  }
  // istanbul ignore next
  const logout = () => {
    toast.error(`Inactive session. Please sign in to continue.`, {
      toastId: "inactive-session"
    })
    localStorage.clear()
    window.location.href = PATH.LOGIN
  }

  let inactivityTimer: NodeJS.Timeout
  // istanbul ignore next
  useEffect(() => {
    const isLoginPage = location.pathname === PATH.LOGIN

    if (isLoginPage) {
      return // Skip inactivity tracking on the login page
    }

    resetTimer()

    window.addEventListener("mousemove", handleActivity)
    window.addEventListener("keydown", handleActivity)
    window.addEventListener("mousedown", handleActivity)
    window.addEventListener("touchstart", handleActivity)

    window.addEventListener("mousemove", handleInactivity)
    window.addEventListener("keydown", handleInactivity)
    window.addEventListener("mousedown", handleInactivity)
    window.addEventListener("touchstart", handleInactivity)

    const handleChannelMessage = (event: MessageEvent) => {
      if (event.data.type === "activity") {
        resetTimer() // Reset the timer when activity is detected from other tabs
      }
    }

    broadcastChannel.addEventListener("message", handleChannelMessage)

    return () => {
      clearTimeout(inactivityTimer)
      window.removeEventListener("mousemove", handleActivity)
      window.removeEventListener("keydown", handleActivity)
      window.removeEventListener("mousedown", handleActivity)
      window.removeEventListener("touchstart", handleActivity)

      window.removeEventListener("mousemove", handleInactivity)
      window.removeEventListener("keydown", handleInactivity)
      window.removeEventListener("mousedown", handleInactivity)
      window.removeEventListener("touchstart", handleInactivity)

      broadcastChannel.removeEventListener("message", handleChannelMessage)
      broadcastChannel.close() // Close the channel when the component unmounts
    }
  }, [location.pathname, isInactive])

  // No need for a placeholder div
  return null
}

export default InactivityLogout
