import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import React, { useEffect } from "react"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import InfoIcon from "@mui/icons-material/Info"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"

import { useFilters, useSortBy, useTable } from "react-table"
import { useAppThunkDispatch } from "../../../src/redux/store"
import {
  setLocationCategories,
  setDefaultCountFields,
  setReports,
  setOutputFiles,
  setOutputColumns,
  setOutputSubTotals,
  setOutputGrandTotals,
  setReportSubTotals,
  setReportGrandTotals,
  setCountFields,
  setNewFollowUpAuditRule,
  setNewRandomAuditRule,
  setNewTargetLocationAuditRule,
  setNewTargetRecordAuditRule
} from "../../../src/redux/slices/configSlice"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import shortid from "shortid"
import { setVarianceRedux } from "src/redux/slices/ConfigPortal/VarianceSlice"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { Audit_TypesObj } from "src/constants/audits"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"

const GlobalTableDragDrop: React.FC<any> = ({
  component,
  columns,
  data,
  onRowDrop,
  noDataText = "No Data Found",
  initialStateProp = {
    sortBy: []
  }
}) => {
  const [records, setRecords] = React.useState(data)
  const {
    varianceData: { varianceRedux },
    reportData: { reportRedux }
  } = ConfigDataHook()
  const getRowId = React.useCallback(row => {
    return row.id
  }, [])
  const dispatch = useAppThunkDispatch()
  useEffect(() => {
    setRecords([...data])
  }, [data])

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: records,
      disableSortRemove: true,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        ...initialStateProp
      }
    },
    useFilters, // useFilters!
    useSortBy,
    getRowId
  )

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    const updatedRecords = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord]
      ]
    })
    let dt = [
      ...updatedRecords.map((m, i) => {
        return { ...m, SortOrder: i + 1 }
      })
    ]
    setRecords([...dt])
    onRowDrop?.([...dt])
    if (component === TableComponentNames.categories) {
      dispatch(setLocationCategories([...dt]))
    } else if (component === TableComponentNames.groupVariances) {
      const locArr = JSON.parse(JSON.stringify(varianceRedux))
      locArr.GroupVariance.ReportFields = [...dt]
      dispatch(setVarianceRedux(locArr))
    } else if (component === TableComponentNames.reportColumns) {
      const locArr = JSON.parse(JSON.stringify(reportRedux))
      locArr.Columns = [...dt]
      dispatch(setReportRedux(locArr))
    } else if (component === TableComponentNames.reportSignatures) {
      const locArr = JSON.parse(JSON.stringify(reportRedux))
      locArr.Signatures = [...dt]
      dispatch(setReportRedux(locArr))
    } else if (component === TableComponentNames.reportPrompts) {
      const locArr = JSON.parse(JSON.stringify(reportRedux))
      locArr.Prompts = [...dt]
      dispatch(setReportRedux(locArr))
    } else if (component === TableComponentNames.reportSubTotals) {
      const locArr = JSON.parse(JSON.stringify(reportRedux))
      locArr.SubTotals = [...dt]
      dispatch(setReportRedux(locArr))
      dispatch(setReportSubTotals([...dt]))
    } else if (component === TableComponentNames.reportGrandTotals) {
      const locArr = JSON.parse(JSON.stringify(reportRedux))
      locArr.GrandTotals = [...dt]
      dispatch(setReportRedux(locArr))
      dispatch(setReportGrandTotals([...dt]))
    } else if (component === TableComponentNames.fullVariances) {
      const locArr = { ...varianceRedux }
      locArr.FullVariance.ReportFields = [...dt]
      dispatch(setVarianceRedux(locArr))
    } else if (component === TableComponentNames.outputFiles) {
      dispatch(setOutputFiles([...dt]))
    } else if (component === TableComponentNames.reportings) {
      dispatch(setReports([...dt]))
    } else if (component === TableComponentNames.outputColumns) {
      dispatch(setOutputColumns([...dt]))
    } else if (component === TableComponentNames.outputSubTotals) {
      dispatch(setOutputSubTotals([...dt]))
    } else if (component === TableComponentNames.outputGrandTotals) {
      dispatch(setOutputGrandTotals([...dt]))
    } else if (component === TableComponentNames.countFields) {
      let tempData: any = [...dt]
      let countFieldObj: any = []
      let finalObj = {}
      for (let prod of tempData) {
        const fieldKey: any = prod.field
        countFieldObj.push({ [fieldKey]: prod })
      }
      for (let countFieldVal of countFieldObj) {
        Object.assign(finalObj, countFieldVal)
      }
      dispatch(setCountFields({ ...finalObj }))
      dispatch(
        setDefaultCountFields({ data: { ...finalObj }, type: "REORDER" })
      )
    } else if (component === TableComponentNames.newauditrule) {
      dt = dt.map((i, j) => ({ ...i, SortOrder: j + 1 }))
      const followup = dt.filter(i => i.AuditType === Audit_TypesObj.FollowUp)

      const random = dt.filter(i => i.AuditType === Audit_TypesObj.Random)

      const targetedLocation = dt.filter(
        i => i.AuditType === Audit_TypesObj.TargetedLocation
      )

      const targetedRecord = dt.filter(
        i => i.AuditType === Audit_TypesObj.TargetedRecord
      )

      dispatch(setNewFollowUpAuditRule([...followup]))
      dispatch(setNewRandomAuditRule([...random]))
      dispatch(setNewTargetLocationAuditRule([...targetedLocation]))
      dispatch(setNewTargetRecordAuditRule([...targetedRecord]))
    }
  }

  const checkSorting = (column: any) => {
    //istanbul ignore next
    if (column.isSorted) {
      return column.isSortedDesc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
    } else {
      return ""
    }
  }

  const DND_ITEM_TYPE = "row"

  const Row = ({ row, index, moveRow }) => {
    const dropRef = React.useRef(null as any)
    const dragRef = React.useRef(null as any)

    const [{ isOver }, drop] = useDrop({
      accept: DND_ITEM_TYPE,
      drop(item: any, _monitor) {
        // istanbul ignore next
        if (!dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        if (dragIndex === 0 && component === "countFields") return
        if (hoverIndex === 0 && component === "countFields") return
        if (dragIndex === data.length - 1 && component === "countFields") return
        if (hoverIndex === data.length - 1 && component === "countFields")
          return
        if (row.original.field === "Ext") {
          if (dragIndex === data.length - 2 && component === "countFields")
            return
          if (hoverIndex === data.length - 2 && component === "countFields")
            return
        } else if (row.original.field === "Qty") {
          if (dragIndex === data.length - 3 && component === "countFields")
            return
          if (hoverIndex === data.length - 3 && component === "countFields")
            return
        }
        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    })

    const [{ isDragging }, drag, preview]: any = useDrag({
      type: DND_ITEM_TYPE,
      item: () => {
        return { index }
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging()
      })
    } as any)

    //istanbul ignore next
    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    let defaultCountFields = ["ProductCaptured", "Qty", "Ext"]

    let rowHoverClass = ""
    if (isOver) {
      rowHoverClass = `row-hover`
    }

    const onTouchMove = e => {
      // number of pixels near the top or bottom edge when auto scroll will begin
      const DISTANCE_FROM_TOP = 300
      const DISTANCE_FROM_BOTTOM = 100

      // number of pixels that are scrolled on each touchmove event
      // this could be replaced with an easing function
      const SCROLL_RATE = 5
      // global isDragging state that must be set to true on beginDrag and false on endDrag
      //istanbul ignore next
      if (isDragging) {
        // distance of touch from top of screen
        const y = e.clientY
        // scroll down
        if (y < DISTANCE_FROM_TOP) {
          window.scrollTo(0, document.documentElement.scrollTop - SCROLL_RATE)
        }
        // scroll up
        else if (y > window.innerHeight - DISTANCE_FROM_BOTTOM) {
          window.scrollTo(0, document.documentElement.scrollTop + SCROLL_RATE)
        }
      }
    }

    useEffect(() => {
      // Add event listener when the component mounts
      /** Handles auto scroll on drag near the edge of the screen on mobile. */
      document.addEventListener("dragover", onTouchMove)
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("dragover", onTouchMove)
      }
      //eslint-disable-next-line
    }, [isDragging]) // Empty dependency array ensures the effect runs only once

    return (
      <TableRow
        ref={dropRef}
        style={{ opacity }}
        className={`${rowHoverClass}`}
      >
        {/* <td ref={dragRef}>move</td> */}
        {row.cells.map((cell: any, j) => {
          return (
            <TableCell {...cell.getCellProps()}>
              {j === 0 ? (
                <span
                  ref={dragRef}
                  data-testid="GTDDDragIconSpan"
                  className={`custom-table__drag ${
                    defaultCountFields.includes(cell.row.original.field) &&
                    component === "countFields"
                      ? "disabled"
                      : ""
                  }`}
                >
                  <DragIndicatorIcon />
                </span>
              ) : (
                <>{cell.render("Cell")}</>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }
  // Render the UI for your table
  return (
    <DndProvider backend={HTML5Backend}>
      <Table className="custom-table" {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, hIndex) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              key={`glob-h-group-${shortid.generate()}`}
            >
              {headerGroup.headers.map((column: any, _colIndex) => (
                <TableCell
                  style={{ minWidth: `${column.render("setWidth")}px` }}
                  key={column.Header}
                >
                  <div
                    className="custom-table__sort"
                    {...column.getSortByToggleProps(
                      column.getSortByToggleProps()
                    )}
                    title={column.Header}
                  >
                    {column.render("Header")}
                    <span
                      className={`custom-table__sort-icon ${
                        column.canFilter ? "with-filter" : ""
                      }`}
                    >
                      {checkSorting(column)}
                    </span>
                  </div>
                  <span className="custom-table__filter">
                    {column.canFilter ? column.render("Filter") : null}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {(!rows || rows.length === 0) && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <h6 className="text-center">
                  {typeof noDataText === "string" ? (
                    <InfoIcon className="primary-text" />
                  ) : null}
                  <span style={{ paddingLeft: "12px" }}></span>
                  {noDataText}
                </h6>
              </TableCell>
            </TableRow>
          )}

          {rows.map((row, _i) => {
            prepareRow(row)
            return (
              <Row
                index={_i}
                row={row}
                moveRow={moveRow}
                {...row.getRowProps()}
              />
            )
          })}
        </TableBody>
      </Table>
    </DndProvider>
  )
}

export default GlobalTableDragDrop
