import {
  AlertColor,
  Button,
  Divider,
  Grid,
  TextField,
  Tooltip
} from "@mui/material"
import React, { useCallback, useEffect, useReducer, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { PATH } from "../../../constants/paths"
import { useDispatch } from "react-redux"
import {
  setLookupRoutines,
  setNewLookupRoutine
} from "../../../redux/slices/configSlice"
import { Loader } from "../../../utils/reusables/reusable"
import { LOOKUP_ROUTINES_TYPE } from "../../../constants/lookupRoutines"
import ReadOnlyLayout from "./ReadOnlyLayout/ReadOnlyLayout"
import AlertC from "src/components/Alert/Alert"
import { getBreadcrumbPath, LookupEditorToConfig } from "./helpers"
import { getLayout } from "./layout.service"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { CONFIG_MODULES } from "src/utils/constants"
import { renderConversionFromConfig } from "./LRConstants/lookupRenderFromConfig"
import LookupBreadCrumb from "../Components/CBreadCrumb"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo"
import { getToCopy, handleToPaste } from "../CountPrograms/helper"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
import { reducer, updateWarnOnTabChangeToStore } from "../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations, setWeekFieldError } from "src/utils/helper"
interface ICreateNewLookupRoutine {
  Name: string
  Instructions: any
}

let isConfirmOpen: boolean = false
let pasteInfo = {
  name: "",
  instructions: []
}
const NewLookupRoutine = () => {
  const { t } = useTranslation<any>()
  const {
    config: {
      lookupRoutines,
      newLookupRoutine,
      configType: configTypeFromStore
    }
  } = ConfigDataHook()
  const [lookupNameError, setLookupNameError] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [, setReload] = useReducer(reducer, false)
  const [alertProps, setAlertProps] = useState({
    title: "",
    severity: "success" as AlertColor,
    content: ""
  })
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [weekIVFields, setWeekIVFields] = useState({
    Name: ""
  })
  const location: any = useLocation()

  const dispatch = useDispatch()
  let search = window.location.search
  let locationState: any = { ...(location.state || {}) }
  const [form, setForm] = React.useState<ICreateNewLookupRoutine>({
    Name: "",
    Instructions: []
  } as ICreateNewLookupRoutine)

  const data = locationState.data
    ? JSON.parse(locationState.data)
    : locationState.data
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (
        locationState.type === LOOKUP_ROUTINES_TYPE.EDIT &&
        !Object.keys(newLookupRoutine).length
      ) {
        let foundIndex: number = Number(locationState.index)
        if (!lookupRoutines[foundIndex]) {
          history.push(PATH.CONFIG_LOOKUP_ROUTINES + search)
        } else {
          let layout: any = []
          renderConversionFromConfig(lookupRoutines, foundIndex)
          layout = [...getLayout()]
          const locData = {
            Name: lookupRoutines[foundIndex]?.Name,
            Instructions: layout
          }
          dispatch(setNewLookupRoutine(locData))
          setForm(locData)
          lookupNameValidationCheck(data?.Name)
        }
      } else if (Object.keys(newLookupRoutine).length) {
        updateWarnOnTabChangeToStore(true)
        setForm(newLookupRoutine)
      }

      setLoading(false)
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  const lookupNameValidationCheck = name => {
    let foundIndex: number = Number(locationState.index)
    let lookupObject: any = lookupRoutines?.find((lookup: any, idx) => {
      if (name) {
        if (locationState.type === LOOKUP_ROUTINES_TYPE.EDIT) {
          return (
            lookup?.Name?.toLowerCase() === name?.toLowerCase() &&
            foundIndex !== idx
          )
        } else {
          return lookup?.Name?.toLowerCase() === name?.toLowerCase()
        }
      }
    })
    if (lookupObject) {
      setLookupNameError(true)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      return true
    } else {
      setLookupNameError(false)
    }

    return false
  }

  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIG_LOOKUP_ROUTINES + search)
    })
  }
  const onHandleNameChange = e => {
    updateWarnOnTabChangeToStore(true)
    setLookupNameError(false)
    setForm({ ...form, Name: e.target.value })
    lookupNameValidationCheck(e.target.value)
    const val = e.target.value
    setWeekFieldError({ setWeekIVFields, val, t, field: "Name" })
  }

  const getTextToCopy = () => {
    if (form.Instructions?.length) {
      let configPrepared = LookupEditorToConfig(form.Instructions)
      return JSON.stringify(configPrepared)
    }
    return null
  }
  let textToCopy = getTextToCopy()

  const onSaveClick = () => {
    let foundIndex: number = Number(locationState.index)
    const _item = {
      Name: form.Name,
      Instructions: textToCopy ? JSON.parse(textToCopy) : []
    }
    let error: boolean = false

    error = lookupNameValidationCheck(form.Name)
    if (!error) {
      if (locationState.type === LOOKUP_ROUTINES_TYPE.EDIT) {
        let newArr = [...lookupRoutines]
        if (foundIndex !== -1) {
          newArr[foundIndex] = _item
          dispatch(setLookupRoutines([...newArr]))
        } else {
          dispatch(setLookupRoutines([...lookupRoutines, _item]))
        }
      } else {
        dispatch(setLookupRoutines([...lookupRoutines, _item]))
      }
      updateWarnOnTabChangeToStore(false)
      setTimeout(() => {
        history.push(PATH.CONFIG_LOOKUP_ROUTINES + search)
      })
    }
  }

  const disableCheckFunction = () => {
    let disable: boolean = false

    if (
      form.Name === "" ||
      lookupNameError ||
      configTypeFromStore === "view" ||
      Object.values(weekIVFields).some(s => s || s !== "")
    ) {
      disable = true
    }

    return disable
  }

  const { componentName, bConfig } = getBreadcrumbPath(
    locationState.index ?? -1,
    t
  )

  const getTextPrepareToCopy = async (clickBoardName, instructions) => {
    const { isCopied, locAlertProps } = await getToCopy(
      clickBoardName,
      instructions
    )
    if (isCopied) {
      setAlertProps(locAlertProps)
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 1000)
    }
  }

  const handleTextPrepareToPaste = async (
    clickBoardName,
    previousInstructions
  ) => {
    const {
      locAlertProps,
      instructions,
      isNonEmptyArray,
      locPasteInfo,
      isAlertShow,
      isValueSet
    } = await handleToPaste(
      clickBoardName,
      previousInstructions,
      "Instructions"
    )

    if (isAlertShow) {
      setAlertProps(locAlertProps)
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
        if (isValueSet) setForm({ ...form, ...{ Instructions: instructions } })
      }, 1000)
      return false
    } else if (isNonEmptyArray) {
      pasteInfo = locPasteInfo
      isConfirmOpen = true
      setReload(oldVal => !oldVal)
    }
  }

  const setDeleteContent = useCallback(
    () => {
      return (
        <AlertConfirmationContent
          warningMsg={`Are you sure you want to override the instructions?`}
        />
      )
    },
    //eslint-disable-next-line
    []
  )

  const callbackConfirmation = useCallback(
    (res: boolean) => {
      if (res) {
        setForm({ ...form, ...{ Instructions: pasteInfo.instructions } })
        pasteInfo = { name: "", instructions: [] }
      }
      isConfirmOpen = false
      setReload(oldVal => !oldVal)
    },
    //eslint-disable-next-line
    [pasteInfo]
  )

  const getNameError = () => {
    if (lookupNameError) {
      return getTranslations(t, Translates.Lookup_name_already_exists)
    } else if (weekIVFields.Name) {
      return weekIVFields.Name
    }
    return ""
  }

  return (
    <>
      {loading ? (
        Loader()
      ) : (
        <div>
          <div className="main-toolbar">
            <div className="main-container">
              <Grid container>
                <Grid item sm={6} className="d-flex align-items-center">
                  <div className="custom-breadcrumb CP">
                    <LookupBreadCrumb
                      customPath={componentName}
                      bConfig={bConfig}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className="main-container">
            <Grid item xs={12} sm={5} className="mb-8">
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label className="form-control__label mb-3">
                    {getTranslations(t, Translates.DETAILS)}
                  </label>
                  <div className="text-box">
                    <TextField
                      fullWidth
                      label={`${getTranslations(t, Translates.Name)}*`}
                      variant="filled"
                      autoSave="false"
                      autoComplete="off"
                      className="custom-form-control"
                      onChange={e => onHandleNameChange(e)}
                      name="Label"
                      value={form.Name}
                      error={lookupNameError || !!weekIVFields.Name}
                      helperText={getNameError()}
                    />
                  </div>
                </Grid>
              </Grid>
              <Divider className="dashed my-4" />
              <Grid container>
                <Grid item xs={12}>
                  <label className="form-control__label mb-3">
                    {getTranslations(
                      t,
                      Translates.Lookup_Routines_Instructions
                    )}
                  </label>
                  <Grid container>
                    <Grid item xs={11} className="gray-bg pa-4 row-copy">
                      <span className={`row-paste__icon mt-1  `}>
                        <Tooltip
                          arrow
                          title={getTranslations(t, Translates.Paste)}
                        >
                          <ContentPasteGoIcon
                            onClick={e =>
                              handleTextPrepareToPaste(
                                CONFIG_MODULES.CopyPastInstructions
                                  .LookupRoutines,
                                form.Instructions
                              )
                            }
                          />
                        </Tooltip>
                      </span>

                      <span className={`row-copy__icon mt-1  `}>
                        <Tooltip
                          arrow
                          title={getTranslations(t, Translates.Copy)}
                        >
                          <ContentCopyIcon
                            onClick={() =>
                              getTextPrepareToCopy(
                                CONFIG_MODULES.CopyPastInstructions
                                  .LookupRoutines,
                                form.Instructions
                              )
                            }
                          />
                        </Tooltip>
                      </span>
                      <div className="mt-2" data-testid="readonly-layout-div">
                        <ReadOnlyLayout
                          form={form}
                          index={locationState.index ?? -1}
                          type={locationState.type ?? undefined}
                          component={CONFIG_MODULES.LookupRoutines}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className="inner-view__footer">
                <Button
                  data-testid="cancelBtn"
                  variant="text"
                  className="primary-btn cancel-btn mr-5"
                  onClick={onCancelClick}
                >
                  {getTranslations(t, Translates.Cancel)}
                </Button>
                <Button
                  data-testid="saveBtn"
                  variant="contained"
                  className="primary-btn"
                  disabled={disableCheckFunction()}
                  onClick={onSaveClick}
                >
                  {locationState.type === LOOKUP_ROUTINES_TYPE.EDIT
                    ? getTranslations(t, Translates.Update)
                    : getTranslations(t, Translates.Save)}
                </Button>
              </div>
            </Grid>
            {isConfirmOpen ? (
              <ConfirmDialog
                open={isConfirmOpen}
                callBack={callbackConfirmation}
                title={getTranslations(t, Translates.CONFIRMATION)}
                content={setDeleteContent}
                custref={{}}
                confirmButtonText={getTranslations(t, Translates.CONFIRM)}
                cancelButtonText={getTranslations(t, Translates.Cancel)}
              />
            ) : null}
          </Grid>
          <Grid container>{showAlert ? <AlertC {...alertProps} /> : null}</Grid>
        </div>
      )}
    </>
  )
}

export default NewLookupRoutine
