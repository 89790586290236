import { Button, Grid, Tooltip } from "@mui/material"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import BreadCrumbC from "../ToolBar/BreadCrumbC"
const CountProgramsToolBar = ({ programsSaveClick, setIsTabChangeCheck }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config.configType
  )
  const history = useHistory()
  let search = window.location.search || ""
  const getBreadcrumbConfig = () => {
    let currentPath: string = `countPrograms`
    let variables: any = {
      countPrograms: getTranslations(t, Translates.Count_Programs)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const countProgramsSaveClick = () => {
    programsSaveClick()
  }
  const handleNewCountProgramClick = () => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_COUNT_PROGRAMS + search)
    })
  }
  return (
    <div className="main-toolbar">
      <div className="main-container">
        <Grid container>
          <BreadCrumbC
            customPath="Count Programs"
            getBreadcrumbConfig={getBreadcrumbConfig}
          />
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <span>
                  <Button
                    disabled={configTypeFromStore === "view" ? true : false}
                    variant="text"
                    data-testid="saveBtn"
                    className="icon-btn mx-2"
                    onClick={countProgramsSaveClick}
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
              <Button
                variant="contained"
                className="primary-btn"
                onClick={handleNewCountProgramClick}
                data-testid="newCountProgramBtn"
                disabled={configTypeFromStore === "view" ? true : false}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Count_Program)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default CountProgramsToolBar
