//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()
export class LookupRoutinesService {
  getTableNamesWithColumns() {
    const endpoint = `/api/ConfigurationPortal/GetTableNamesAndColumnNames`
    return api.findAll(endpoint)
  }
}
//END-NOSCAN