import { PromptTypes } from "src/utils/constants"
import { OutputColumn, ReportColumn } from "../Types"
import { MUIAutoCompleteField } from "src/utils/types/types"

export const createEditColumnFieldNames = {
  DataSource: { name: "DataSource", label: "Data_Source" },
  ColumnName: { name: "ColumnName", label: "Name" },
  DataType: { name: "DataType", label: "Data_Type" },
  NumberOfDecimals: { name: "NumberOfDecimals", label: "Enter_Decimal_Places" },
  CurrencySymbol: { name: "CurrencySymbol", label: "Currency" },
  OriginalCurrencySymbol: { name: "OriginalCurrencySymbol", label: "Currency" },
  Weight: { name: "Weight", label: "Weight" },
  SortType: { name: "SortType", label: "Sort_Type" },
  FilterType: { name: "FilterType", label: "Filter_Type" },
  Suppress: { name: "Suppress", label: "Suppress" },

  Format: { name: "Padding.Format", label: "Padding" },
  Character: { name: "Padding.Character", label: "Character" },
  Length: { name: "Padding.Length", label: "Length" },
  SurroundCharacter: { name: "SurroundCharacter", label: "Surround_Character" }
}

export const ColumnCustomListOptions: MUIAutoCompleteField["props"]["options"] =
  [
    { label: "SCANNED", value: "SCANNED" },
    { label: "KEYED", value: "KEYED" }
  ]

export const PaddingOptions: MUIAutoCompleteField["props"]["options"] = [
  { label: PromptTypes.NONE, value: PromptTypes.NONE },
  { label: PromptTypes.LEFT, value: PromptTypes.LEFT },
  { label: PromptTypes.RIGHT, value: PromptTypes.RIGHT }
]

export const SurroundOptions: MUIAutoCompleteField["props"]["options"] = [
  { label: '"', value: '"' },
  { label: "'", value: "'" }
]

const commonDefaultValues = {
  DataSource: "",
  ColumnName: "",
  DataType: "",
  NumberOfDecimals: 0,
  CurrencySymbol: "",
  OriginalCurrencySymbol: ""
}

export const createEditReportColumnDefaultIniValues: ReportColumn = {
  ...commonDefaultValues,
  ...{
    Weight: 100,
    SortType: "",
    FilterType: "",
    Suppress: false
  }
}

export const createEditOutputColumnDefaultIniValues: OutputColumn = {
  ...commonDefaultValues,
  ...{
    Padding: { Format: "", Character: "", Length: 0 },
    SurroundCharacter: ""
  }
}

export const ColumnDataTypeOptions: MUIAutoCompleteField["props"]["options"] = [
  { label: "", value: "" },
  { label: PromptTypes.Decimal, value: PromptTypes.Decimal },
  { label: PromptTypes.Alpha, value: PromptTypes.Alpha },
  { label: PromptTypes.Numeric, value: PromptTypes.Numeric }
]

export const ColumnFilterTypeOptions: MUIAutoCompleteField["props"]["options"] =
  [
    { label: "", value: "" },
    { label: "CHOOSE1", value: "CHOOSE1" },
    { label: "CONTAINS", value: "CONTAINS" },
    { label: "GREATER_OR_LESS_THAN", value: "GREATER_OR_LESS_THAN" },
    { label: "EXACT_MATCH", value: "EXACT_MATCH" }
  ]

export const ColumnCurrencyOptions: MUIAutoCompleteField["props"]["options"] = [
  { label: "No Currency Symbol", value: "" },
  { label: "$ - USD - US Dollar", value: "$" },
  { label: "$ - MXN - MEXICAN PESO", value: "Mex$" },
  { label: "C$ - CAD - Canadian Dollar", value: "C$" },
  { label: "¥ - CNY - Chinese Yuan", value: "¥" }
]
