import BreadCrumb from "../../BreadCrumb/BreadCrumb"
import { Button, Grid, Tooltip } from "@mui/material"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
const CountFieldsToolBar = ({ fieldsSaveClick }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config.configType
  )
  const getBreadcrumbConfig = () => {
    let currentPath: string = `countFields`
    let variables: any = {
      countFields: getTranslations(t, Translates.Count_Fields)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }

  const countFieldsSaveClick = () => {
    fieldsSaveClick()
  }

  return (
    <div className="main-toolbar">
      <div className="main-container">
        <Grid container>
          <Grid item xs={12} sm={6} className="d-flex align-items-center">
            <div className="custom-breadcrumb CP">
              <BreadCrumb
                customPath={"Count Fields"}
                breadCrumbInfo={getBreadcrumbConfig()}
                renderedPage={""}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <span>
                  <Button
                    disabled={configTypeFromStore === "view"}
                    variant="text"
                    className="icon-btn mx-2"
                    onClick={countFieldsSaveClick}
                    data-testid="CountFieldsToolBarSavebtn"
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default CountFieldsToolBar
