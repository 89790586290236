import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import {
  ColumnCurrencyOptions,
  ColumnDataTypeOptions,
  ColumnFilterTypeOptions,
  createEditReportColumnDefaultIniValues,
  createEditOutputColumnDefaultIniValues,
  createEditColumnFieldNames,
  PaddingOptions,
  SurroundOptions
} from "../Constants/CreateEditColumnConstant"
import { OutputColumnFieldType, ReportColumnFieldType } from "../Types"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { PromptTypes, formField } from "src/utils/constants"
import {
  getColumnsFromAPI,
  getTabLabel
} from "src/configurationPortal/pages/FunctionHelper"
import { setColumnsRedux } from "src/redux/slices/ConfigPortal/ColumnSlice"
import store from "src/redux/store"

export const getDefaultValues = (
  columns: any[],
  storeId: number,
  tab: string
) => {
  if (columns.length) {
    if (storeId > -1 && columns[storeId - 1]) {
      return {
        ...columns[storeId - 1]
      }
    }
  }
  return tab.toLocaleLowerCase() === ConfigTabNames.Reports.toLocaleLowerCase()
    ? createEditReportColumnDefaultIniValues
    : createEditOutputColumnDefaultIniValues
}

export const getBreadcrumbConfig = (t, tab, storeId) => {
  let currentPath: string = "column"
  const createOrEdit =
    storeId > -1 ? Translates.Edit_Column : Translates.New_Column

  const { locTab } = getTabLabel(tab)
  let variables: any = {
    column: `${getTranslations(t, locTab)} ${getTranslations(t, createOrEdit)}`
  }
  let bConfig: any = {
    currentPath,
    mapObj: variables,
    showHomeIcon: false
  }
  return bConfig
}

export const getReportColumnFieldDetails = (
  locColumnsRedux
): ReportColumnFieldType => {
  const dataSourceOptions =
    locColumnsRedux.columns?.map(i => ({
      label: i.ColumnName,
      value: i.ColumnName
    })) || []
  return {
    dataSource: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditColumnFieldNames.DataSource.name,
        label: createEditColumnFieldNames.DataSource.label,
        options: dataSourceOptions,
        required: true
      }
    },
    columnName: {
      fieldType: formField.text,
      props: {
        name: createEditColumnFieldNames.ColumnName.name,
        label: createEditColumnFieldNames.ColumnName.label,
        required: true
      }
    },
    dataType: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditColumnFieldNames.DataType.name,
        label: createEditColumnFieldNames.DataType.label,
        options: ColumnDataTypeOptions
      },
      resetFieldNames: [
        {
          name: createEditColumnFieldNames.NumberOfDecimals.name,
          value: 0,
          isErrOnlyReset: true
        },
        {
          name: createEditColumnFieldNames.CurrencySymbol.name,
          value: "",
          isErrOnlyReset: true
        }
      ]
    },
    numberOfDecimals: {
      fieldType: formField.text,
      condition: {
        name: createEditColumnFieldNames.DataType.name,
        value: PromptTypes.Decimal
      },
      props: {
        name: createEditColumnFieldNames.NumberOfDecimals.name,
        label: createEditColumnFieldNames.NumberOfDecimals.label,
        required: true
      }
    },
    currencySymbol: {
      fieldType: formField.auto_dropdown,
      condition: {
        name: createEditColumnFieldNames.DataType.name,
        value: PromptTypes.Decimal
      },
      props: {
        name: createEditColumnFieldNames.OriginalCurrencySymbol.name,
        label: createEditColumnFieldNames.OriginalCurrencySymbol.label,
        options: ColumnCurrencyOptions,
        required: true
      }
    },
    weight: {
      fieldType: formField.text,
      props: {
        name: createEditColumnFieldNames.Weight.name,
        label: createEditColumnFieldNames.Weight.label,
        required: true
      }
    },
    sortType: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditColumnFieldNames.SortType.name,
        label: createEditColumnFieldNames.SortType.label,
        options: ColumnDataTypeOptions
      }
    },
    filterType: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditColumnFieldNames.FilterType.name,
        label: createEditColumnFieldNames.FilterType.label,
        options: ColumnFilterTypeOptions
      }
    },
    suppress: {
      fieldType: formField.checkbox,
      props: {
        name: createEditColumnFieldNames.Suppress.name,
        label: createEditColumnFieldNames.Suppress.label,
        className: "light",
        value: false
      }
    }
  }
}

export const getOutputColumnFieldDetails = (
  locColumnsRedux
): OutputColumnFieldType => {
  const exportFromReportColumn = getReportColumnFieldDetails(locColumnsRedux)
  return {
    dataSource: exportFromReportColumn.dataSource,
    columnName: exportFromReportColumn.columnName,
    dataType: exportFromReportColumn.dataType,
    numberOfDecimals: exportFromReportColumn.numberOfDecimals,
    currencySymbol: exportFromReportColumn.currencySymbol,
    padding: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditColumnFieldNames.Format.name,
        label: createEditColumnFieldNames.Format.label,
        options: PaddingOptions
      },
      resetFieldNames: [
        {
          name: createEditColumnFieldNames.Length.name,
          value: 0,
          isErrOnlyReset: true
        },
        {
          name: createEditColumnFieldNames.Character.name,
          value: "",
          isErrOnlyReset: true
        }
      ]
    },
    length: {
      fieldType: formField.text,
      condition: {
        name: createEditColumnFieldNames.Format.name,
        value: [PromptTypes.LEFT, PromptTypes.RIGHT]
      },
      props: {
        name: createEditColumnFieldNames.Length.name,
        label: createEditColumnFieldNames.Length.label,
        required: true
      }
    },
    character: {
      fieldType: formField.text,
      condition: {
        name: createEditColumnFieldNames.Format.name,
        value: [PromptTypes.LEFT, PromptTypes.RIGHT]
      },
      props: {
        name: createEditColumnFieldNames.Character.name,
        label: createEditColumnFieldNames.Character.label,
        required: true
      }
    },
    surroundCharacter: {
      fieldType: formField.auto_dropdown,
      props: {
        name: createEditColumnFieldNames.SurroundCharacter.name,
        label: createEditColumnFieldNames.SurroundCharacter.label,
        options: SurroundOptions,
        freeSolo: true
      }
    }
  }
}

export const conditionCheckOnLoading = (
  {
    locVariableRedux,
    variableSetRedux,
    variableRedux,
    columnsRedux,
    storeTabId,
    locColumnsRedux,
    storeId,
    reset,
    locGetDefaultValues
  },
  isSetColumnsRedux = true
) => {
  if (
    locVariableRedux &&
    JSON.stringify(locVariableRedux) !== JSON.stringify(variableRedux)
  ) {
    store.dispatch(variableSetRedux(locVariableRedux))
  }
  if (isSetColumnsRedux) {
    if (!columnsRedux.SQL) {
      if (locColumnsRedux && Object.keys(locColumnsRedux).length) {
        store.dispatch(setColumnsRedux(locColumnsRedux))
      } else {
        getColumnsFromAPI(locVariableRedux)
      }
    }
  }

  if (storeId > 0) {
    reset(locGetDefaultValues())
  }
}
