import React, { useCallback, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useParams } from "react-router"
import {
  Grid,
  Box,
  Tab,
  Tabs,
  FormGroup,
  Button,
  Alert,
  AlertTitle
} from "@mui/material"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import shortid from "shortid"
import {
  CopyStdWisTagSetupInstructions,
  CopyStdWisTagValidationInstructions,
  checkBoxClass,
  fieldNames
} from "src/configurationPortal/pages/CountPrograms/Constant"
import MUIRadio from "src/components/MUIFormFields/MUIRadio"
import MUICheckbox from "src/components/MUIFormFields/MUICheckbox"
import {
  a11yProps,
  fetchInstructionLayout,
  getLocationList,
  TabPanel
} from "./TabFunctionsAndConstant"
import { getBreadcrumbPath } from "../helper"
import { useTranslation } from "react-i18next"
import { LocationsList } from "../../CountFields/Constants"
import { getTranslations } from "src/utils/helper"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { Translates } from "src/i18n/i18n"
import InfoIcon from "@mui/icons-material/Info"
import { LookupConfigToEditor } from "../../LookupRoutines/helpers"

const LocationCapture = () => {
  const { t } = useTranslation<any>()
  const { watch, setValue } = useFormContext()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const locationList = getLocationList()
  const tabValue: number = watch(fieldNames.ChildTabIndex.name)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [confirmMsg, setConfirmMsg] = useState("")

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(fieldNames.ChildTabIndex.name, newValue)
    const keyName = fieldNames.locationKeyName.name.replace(
      "{inx}",
      newValue.toString()
    )
    setValue(keyName, newValue)
  }
  const { bConfig } = getBreadcrumbPath(storeId, t, true)
  const copyWisTagClick = useCallback(() => {
    setIsConfirmOpen(true)
    setConfirmMsg(
      `${getTranslations(
        t,
        Translates.Would_you_like_to_continue_with_standard_logic
      )} ?`
    )
  }, [])

  const getTabValueLabel = () =>
    //istanbul ignore next
    tabValue?.toString()

  const callbackConfirmation = useCallback((res: boolean) => {
    const setUpInstructions =
      fieldNames.locationSetupInstructionArrayName.name.replace(
        "{inx}",
        getTabValueLabel()
      )
    const validationInstructions =
      fieldNames.locationValidationInstructionArrayName.name.replace(
        "{inx}",
        getTabValueLabel()
      )
    if (res) {
      const setUpValues = LookupConfigToEditor(CopyStdWisTagSetupInstructions)
      const validationValues = LookupConfigToEditor(
        CopyStdWisTagValidationInstructions
      )
      setValue(setUpInstructions, setUpValues)
      setValue(validationInstructions, validationValues)
      setIsConfirmOpen(false)
    } else {
      setValue(setUpInstructions, [])
      setValue(validationInstructions, [])
      setIsConfirmOpen(res)
    }
  }, [])
  const setConfirmContent = useCallback(() => {
    return (
      <Alert severity="info" icon={<InfoIcon />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Info)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{confirmMsg}</strong>
        </p>
      </Alert>
    )
  }, [confirmMsg])
  const resetInstructions = () => {
    const setUpInstructions =
      fieldNames.locationSetupInstructionArrayName.name.replace(
        "{inx}",
        getTabValueLabel()
      )
    const validationInstructions =
      fieldNames.locationValidationInstructionArrayName.name.replace(
        "{inx}",
        getTabValueLabel()
      )
    setValue(setUpInstructions, [])
    setValue(validationInstructions, [])
  }

  const radioChange = useCallback((e: any) => {
    if (e.target.value === fieldNames.StandardWisTag.value) {
      resetInstructions()
    }
  }, [])

  const checkForRadioOptions = (StandardWisTag: any, j: any) => {
    if (watch(StandardWisTag) === fieldNames.StandardWisTag.value) {
      return (
        <div className="ml-4">
          <FormGroup>
            {[0, 1].map(i => {
              const AllowedBarcodeTypes = fieldNames[
                `AllowedBarcodeTypes${i}`
              ].name.replace("{inx}", j.toString())
              return (
                <span key={shortid.generate()}>
                  <MUICheckbox
                    inputAttributes={{
                      name: AllowedBarcodeTypes,
                      label: fieldNames[`AllowedBarcodeTypes${i}`].label,
                      className: checkBoxClass
                    }}
                  />
                </span>
              )
            })}
          </FormGroup>
        </div>
      )
    } else if (watch(StandardWisTag) === fieldNames.CustomLocation.value) {
      return (
        <div
          className="ml-13 pl-14"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span key={shortid.generate()}>
            <MUICheckbox
              inputAttributes={{
                name: fieldNames.IsAlpha.name.replace(
                  "{inx}",
                  tabValue.toString()
                ),
                label: "Is Alpha",
                className: checkBoxClass
              }}
            />
          </span>
          <div className="ml-18">
            <span>
              <Button
                variant="contained"
                data-testid="CopyWisTag"
                className="primary-btn"
                onClick={copyWisTagClick}
              >
                {getTranslations(t, Translates.Copy_Standard_WIS_Logic)}
              </Button>
            </span>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="inner-tabs"
        >
          <Tabs
            data-testid="CPLCTabs"
            value={tabValue}
            onChange={handleChange}
            aria-label="location tabs"
            variant="scrollable"
            scrollButtons
          >
            {locationList.map((i, j) => {
              return (
                <Tab
                  data-testid="locationTab"
                  key={shortid.generate()}
                  icon={<LocationOnOutlinedIcon />}
                  label={`${
                    i.label?.toLowerCase() === i.originalValue?.toLowerCase()
                      ? i.label
                      : i.label + " (" + i.originalValue + ")"
                  }`}
                  {...a11yProps(j, "child")}
                  className="cp-child-tabs"
                />
              )
            })}
          </Tabs>
        </Box>
        {locationList.map((i, j) => {
          const isSeparateInsertRuleName =
            fieldNames.locationIsSeparateInsertRuleName.name.replace(
              "{inx}",
              j.toString()
            )

          const StandardWisTag = fieldNames.StandardWisTag.name.replace(
            "{inx}",
            j.toString()
          )
          const instructionDisabled =
            locationList[watch(fieldNames.ChildTabIndex.name)]?.OjKey ===
              LocationsList.Location.OjKey &&
            watch(StandardWisTag) === fieldNames.StandardWisTag.value

          return (
            <TabPanel
              value={tabValue}
              index={j}
              type="child"
              key={shortid.generate()}
            >
              <div className="gray-bg pa-5">
                <Grid container>
                  {locationList[watch(fieldNames.ChildTabIndex.name)]?.OjKey ===
                  LocationsList.Location.OjKey ? (
                    <Grid item xs={12} data-testid="tabPanelRadio">
                      <MUIRadio
                        name={StandardWisTag}
                        className={"mb-2"}
                        onChange={radioChange}
                        formControl={{
                          radioGroup: {
                            row: true,
                            formControlLabels: [
                              {
                                value: fieldNames.StandardWisTag.value,
                                label: getTranslations(
                                  t,
                                  fieldNames.StandardWisTag.label
                                ),
                                radio: { size: "medium" }
                              },
                              {
                                value: fieldNames.CustomLocation.value,
                                label: getTranslations(
                                  t,
                                  fieldNames.CustomLocation.label
                                ),
                                radio: { size: "medium" }
                              }
                            ]
                          }
                        }}
                      />

                      {checkForRadioOptions(StandardWisTag, j)}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container>
                  {fetchInstructionLayout(
                    fieldNames.locationSetupInstructionArrayName.name,
                    fieldNames.locationValidationInstructionArrayName.name,
                    fieldNames.locationLookupInstructionArrayName.name,
                    j,
                    i.OjKey,
                    bConfig,
                    instructionDisabled
                  )}
                </Grid>

                <div className="p-2 my-2">
                  <MUICheckbox
                    inputAttributes={{
                      name: isSeparateInsertRuleName,
                      label: getTranslations(
                        t,
                        fieldNames.locationIsSeparateInsertRuleName.label
                      ),
                      className: checkBoxClass,
                      disabled: instructionDisabled
                    }}
                  />
                </div>
                {watch(isSeparateInsertRuleName) ? (
                  <>
                    <h4 className="mt-5">Insert Rules</h4>
                    <Grid container>
                      {fetchInstructionLayout(
                        fieldNames.locationInsertRuleSetupInstructionArrayName
                          .name,
                        fieldNames
                          .locationInsertRuleValidationInstructionArrayName
                          .name,
                        fieldNames.locationInsertRuleLookupInstructionArrayName
                          .name,
                        j,
                        i.OjKey,
                        bConfig
                      )}
                    </Grid>
                  </>
                ) : null}
              </div>
            </TabPanel>
          )
        })}
      </Box>
      <Grid container>
        {isConfirmOpen && (
          <ConfirmDialog
            open={isConfirmOpen}
            callBack={callbackConfirmation}
            title={getTranslations(t, Translates.Copy_Standard_WIS_Logic)}
            content={setConfirmContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.YES)}
            cancelButtonText={getTranslations(t, Translates.NO)}
          ></ConfirmDialog>
        )}
      </Grid>
    </>
  )
}

export default LocationCapture
