import {    
    FIELD_DELIMITER,
    RECORD_DELIMITER,
  } from "src/utils/constants"

export const getFieldDelimiterValue = (form) => {
    if (form.FieldDelimiter == FIELD_DELIMITER[1]) {
      return "\t"
    } else if (form.FieldDelimiter == FIELD_DELIMITER[2]) {
      return ","
    } else if (form.FieldDelimiter == FIELD_DELIMITER[3]) {
      return "|"
    } else if (form.FieldDelimiter == FIELD_DELIMITER[4]) {
      return ":"
    } else if (form.FieldDelimiter == FIELD_DELIMITER[5]) {
      return ";"
    } else if(form.FieldDelimiter) {
      return form.OtherFieldDelimiter
    }
  }

  export const getRecordDelimiterValue = (form) => {
    if (form.RecordDelimiter == RECORD_DELIMITER[0]) {
      return "\r\n"
    } else if (form.RecordDelimiter == RECORD_DELIMITER[1]) {
      return "\n"
    } else if (form.RecordDelimiter == RECORD_DELIMITER[2]) {
      return "\r"
    } else if (form.RecordDelimiter === "OTHER") {
      return form.OtherRecordDelimiter
    } else {
      return form.RecordDelimiter
    }
  }