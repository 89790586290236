let layout: any = []
let ifElseLayout: any = []
let isIfElseScreen: boolean = false
let singleIfBlockInstructions: any = []

export const getLayout = () => {
  return layout
}

export const getIfElseLayout = () => {
  return ifElseLayout
}
export const setLayout = newLayout => {
  layout = newLayout
}

export const setIfElseLayout = newLayout => {
  ifElseLayout = newLayout
}
export const getIsIfElseScreen = () => {
  return isIfElseScreen
}
export const setIsIfElseScreen = (value: boolean) => {
  isIfElseScreen = value
}
