import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const BulkDataSelectField = ({
  bulkData,
  label,
  defaultValue,
  value,
  handleOnChange,
  FormControlClass,
  fieldIndex
}: {
  bulkData: any[]
  label: string
  defaultValue: any
  value: any
  handleOnChange: Function
  FormControlClass?: string
  fieldIndex: number
}) => {
  return (
    <FormControl
      variant="filled"
      className={`custom-form-control select ${FormControlClass}`}
    >
      <InputLabel id="groupSelect">{label}</InputLabel>
      <Select
        data-testid="BDSelectF"
        fullWidth
        value={value}
        defaultValue={defaultValue}
        onChange={e => handleOnChange(e, fieldIndex)}
      >
        {bulkData.map((name: any) => (
          <MenuItem className="dropdown-list" key={name} value={name}>
            <small>{name}</small>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default BulkDataSelectField
