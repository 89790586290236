import { Button, Grid, Tooltip } from "@mui/material"
import BreadCrumb from "../../BreadCrumb/BreadCrumb"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
const GeneralSettingsToolBar = ({ settingsSaveClick, disabled }) => {
  const { t } = useTranslation<any>()
  const {
    config: { customerConfigurations }
  } = ConfigDataHook()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const getBreadcrumbConfig = () => {
    let currentPath: string = `generalSettings`
    let variables: any = {
      generalSettings: getTranslations(t, Translates.General_Settings)
    }
    let bConfig: any = {
      currentPath,
      showHomeIcon: false,
      mapObj: variables
    }
    return bConfig
  }
  const generalSettingsSaveClick = () => {
    settingsSaveClick()
  }
  return (
    <div className="main-toolbar" data-testid="gs-tool-bar">
      <div className="main-container">
        <Grid container>
          <Grid item xs={12} sm={6} className="d-flex align-items-center">
            <div className="custom-breadcrumb CP" data-testid="">
              <BreadCrumb
                renderedPage={""}
                customPath={"General Settings"}
                breadCrumbInfo={getBreadcrumbConfig()}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <span>
                  <Button
                    onClick={generalSettingsSaveClick}
                    variant="text"
                    className="icon-btn mx-2"
                    disabled={
                      disabled ||
                      !customerConfigurations?.length ||
                      configTypeFromStore === "view"
                    }
                    data-testid="saveBtn"
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default GeneralSettingsToolBar
