import DeviceBg from "src/assets/images/DeviceReservationBg.svg"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Grid, Button, Dialog } from "@mui/material"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import editIcon from "../../../../src/assets/images/EditIcon.svg"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { KEY_CODES } from "src/utils/constants"
import { useCallback } from "react"
const InputFileTypeSelect = () => {
  const history = useHistory()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config.configType
  )
  const { t } = useTranslation<any>()
  let search = window.location.search || ""
  const handleClose = () => {
    history.push(PATH.INPUT_FILES + search)
  }
  const handleAFCKeyUp = useCallback((e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleAuxiliaryFileClick()
    }
  }, [])
  
  const handleAuxiliaryFileClick = () => {
    history.push(PATH.CREATE_EDIT_AUXILIARY_INPUT_FILE + search)
  }
  const handleCFCKeyUp = useCallback((e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleCustomerFileClick()
    }
  }, [])
  const handleCustomerFileClick = () => {
    history.push(PATH.CREATE_EDIT_CUSTOMER_INPUT_FILE + search)
  }
  return (
    <>
      <Grid container className="custom-banner">
        <Grid item sm={7}>
          <Dialog fullScreen open={true}>
            <MiniHeader headerTitle={getTranslations(t, Translates.ADD_NEW_INPUT_FILE)} />
            <Grid container className="inner-view">
              <Grid item xs={12} className="main-container">
                <Grid container>
                  <Grid item xs={12}>
                    <h4 className="inner-view__title">
                      {getTranslations(t, Translates.ADD_NEW_INPUT_FILE)}
                    </h4>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="text-uppercase font-14 f-700 mb-3">
                      {`${getTranslations(t, Translates.Select_Option)}:`}
                    </p>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={6}>
                        <div
                          className="blue-bg pa-5 text-center shadow-1 pointer"
                          onClick={handleAuxiliaryFileClick}
                          onKeyUp={handleAFCKeyUp}
                        >
                          <img
                            data-testid="auxiliaryBtn"
                            src={editIcon}
                            className={`font-108 mt-2 mb-3 blue-text ${configTypeFromStore === "view"  ? "disable-img" :"pointer"}`}

                          />
                          <h4>{`${getTranslations(t, Translates.Auxiliary_File_Maint)}`}</h4>
                        </div>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <div
                          className="orange-bg pa-5 text-center shadow-1 pointer"
                          onClick={handleCustomerFileClick}
                          onKeyUp={handleCFCKeyUp}
                        >
                          <CloudUploadIcon
                            className="font-108 mt-2 mb-3 orange-text"
                            data-testid="customerBtn"
                          />
                          <h4>{getTranslations(t, Translates.Customer_File_Tape)}</h4>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="inner-view__footer">
                      <Button
                        variant="contained"
                        className="primary-btn"
                        onClick={handleClose}
                      >
                        {getTranslations(t, Translates.Cancel)}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
        <Grid item sm={5} className="text-right">
          <img width={"248px"} height={"153px"} src={DeviceBg} alt="device reservation" />
        </Grid>
      </Grid>
    </>
  )
}

export default InputFileTypeSelect
