import { LinearProgress } from "@mui/material"

const ProgressBar = ({ value, type }) => {
  let roundedPercentage = value < 99 ? Math.round(value) : Math.floor(value)
  const getClassName = (val: any) => {
    if (val === 0) {
      return "not-started"
    } else if (val > 0 && val < 100) {
      return "in-progress"
    } else {
      return "completed"
    }
  }
  const getType = (val: any) => {
    if (val === "count") {
      return "count"
    } else if (val === "audit") {
      return "audit"
    } else {
      return "variance"
    }
  }
  return (
    <div className={`custom-progressbar ${getClassName(roundedPercentage)}`}>
      <LinearProgress
        color="success"
        value={roundedPercentage < 25 ? 25 : roundedPercentage}
        className={`custom-progressbar__bar ${getType(type)}`}
        variant="determinate"
      />
      <span
        style={{ width: roundedPercentage + "%" }}
        className={`custom-progressbar__val ${
          value > 60 && value <= 100 ? "val-full" : ""
        }`}
      >
        {roundedPercentage}%
      </span>
    </div>
  )
}

export default ProgressBar
