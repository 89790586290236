import { Checkbox, IconButton, Tooltip } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import editIcon from "src/assets/images/Edit.png"
import trashIcon from "src/assets/images/TrashIcon.svg"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"
import AlertConfirmationContent from "src/configurationPortal/pages/Components/AlertConfirmationContent"
import { SQLTestColumnSliceType } from "src/redux/slices/ConfigPortal/ColumnSlice"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import {
  getOutputColumnFieldDetails,
  getReportColumnFieldDetails
} from "./CreateEditColumnHelper"
import { store } from "src/redux/store"
import { PATH, multiEventOutputRouteReplaceVar } from "src/constants/paths"
import { ConfigReportType } from "src/configurationPortal/pages/Reports/Utils/Types"
import { MultiEventOutputFormType } from "src/pages/MultiEventOutputs/Utils/Types"
import { setMultiEventOutputRedux } from "src/redux/slices/MultiEventOutputSlice"
const displayCheckbox = (props: any, handleSuppressChange) => {
  return (
    <Checkbox
      className="custom-check light"
      name="ShowOnTagDetails"
      checked={props.value}
      checkedIcon={<CheckIcon />}
      onChange={e => handleSuppressChange(e, props.row)}
      data-testid="column-grid-suppress-checkbox"
    />
  )
}

export const displayDataSourceSpan = (
  props: any,
  columnsRedux: SQLTestColumnSliceType["columnsRedux"],
  defaultVal = ""
) => {
  return (
    <span
      className="d-block text-left"
      style={{
        ...(!columnsRedux.columns?.some(
          i => i?.ColumnName === props?.row?.original?.DataSource
        ) && { color: "red" })
      }}
    >
      {props.value ? props.value.toString() : defaultVal}
    </span>
  )
}

export const getColumnsData = ({
  componentName,
  updateColumn,
  deleteColumn,
  handleSuppressChange,
  columnsRedux,
  t
}) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    setWidth: 150,
    Cell: props => {
      return displaySpan(props)
    }
  }
  const sort = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Sort),
    accessor: "SortOrder",
    setWidth: 50
  }

  const source = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Source),
    accessor: "DataSource",
    setWidth: 80,
    Cell: props => {
      return displayDataSourceSpan(props, columnsRedux)
    }
  }
  const name = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Name),
    accessor: "ColumnName",
    setWidth: 120
  }
  const datatype = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Data_Type),
    accessor: "DataType"
  }

  const weight = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.WEIGHT),
    accessor: "Weight"
  }

  const sortType = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.SortType),
    accessor: "SortType"
  }

  const filterType = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.FilterType),
    accessor: "FilterType"
  }

  const suppress = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Suppress),
    accessor: "Suppress",
    Cell: props => {
      return displayCheckbox(props, handleSuppressChange)
    }
  }

  const padding = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Padding),
    accessor: "Padding.Format"
  }

  const surroundCharacter = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Surround_Character),
    accessor: "SurroundCharacter"
  }

  const action = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.ACTIONS),
    accessor: "action",
    setWidth: 150,
    Cell: props => {
      const icons = [
        {
          title: getTranslations(t, Translates.Edit),
          clickFn: updateColumn,
          icon: (
            <img
              src={editIcon}
              alt={"edit icon"}
              className="pointer"
              data-testid="report-column-edit"
            />
          )
        },
        {
          title: getTranslations(t, Translates.Delete),
          clickFn: deleteColumn,
          icon: (
            <img
              src={trashIcon}
              alt="delete"
              className="pointer"
              data-testid="report-column-delete"
            />
          )
        }
      ]
      return (
        <>
          {icons.map((f, i) => {
            return (
              <>
                <Tooltip arrow title={f.title} key={f.title}>
                  <IconButton onClick={() => f.clickFn(props.row)}>
                    <span className="text-center d-block">{f.icon}</span>
                  </IconButton>
                </Tooltip>
              </>
            )
          })}
        </>
      )
    }
  }

  let columns: any = []
  if (componentName === ConfigTabNames.Reports) {
    columns = [
      sort,
      source,
      name,
      datatype,
      weight,
      sortType,
      filterType,
      suppress,
      action
    ]
  } else if (
    [ConfigTabNames.Outputs, ConfigTabNames.MultiEventOutputs].includes(
      componentName
    )
  ) {
    columns = [
      sort,
      source,
      name,
      datatype,
      padding,
      surroundCharacter,
      suppress,
      action
    ]
  }

  return columns
}

export const setModalContent = ({ preDeletedRow, t }) => {
  let content = `${getTranslations(
    t,
    Translates.Are_you_sure_you_want_to_delete
  )} "${preDeletedRow?.ColumnName}" ${getTranslations(t, Translates.Column)}?`
  return <AlertConfirmationContent warningMsg={content} />
}

export const getVariableBasedOnComponent = (
  componentName: string,
  tabId: number,
  columnId: number
) => {
  let variableSetRedux = setReportRedux
  let tableComponent = TableComponentNames.reportColumns
  let getFieldFn: Function = getReportColumnFieldDetails
  const { reportRedux, reportsRedux } = store.getState().report
  const { multiEventOutputRedux, multiEventOutputsRedux } =
    store.getState().multiEventOutput
  let variableRedux: ConfigReportType | MultiEventOutputFormType = reportRedux
  let variablesRedux: any = reportsRedux
  let createPath = PATH.TAB_CREATE_COLUMN_CREATE
  let editPath = PATH.TAB_CREATE_COLUMN_EDIT
  let redirectUrl = PATH.REPORT_CREATE
  if (tabId !== -1) {
    createPath = PATH.TAB_EDIT_COLUMN_CREATE
    editPath = PATH.TAB_EDIT_COLUMN_EDIT
  }
  if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Reports.toLocaleLowerCase()
  ) {
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Outputs.toLocaleLowerCase()
  ) {
    variableRedux = reportRedux
    variablesRedux = reportsRedux
    variableSetRedux = setReportRedux
    tableComponent = TableComponentNames.outputColumns
    getFieldFn = getOutputColumnFieldDetails
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    } else {
      redirectUrl = PATH.REPORT_CREATE
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
  ) {
    variableRedux = multiEventOutputRedux
    variablesRedux = multiEventOutputsRedux
    variableSetRedux = setMultiEventOutputRedux
    tableComponent = TableComponentNames.multiEventOutputColumns
    getFieldFn = getOutputColumnFieldDetails
    createPath = createPath.replace(
      multiEventOutputRouteReplaceVar.Configuration,
      multiEventOutputRouteReplaceVar.CreateSchedule
    )
    editPath = editPath.replace(
      multiEventOutputRouteReplaceVar.Configuration,
      multiEventOutputRouteReplaceVar.CreateSchedule
    )
    if (tabId !== -1) {
      redirectUrl = PATH.MULTI_EVENT_OUTPUT_EDIT.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    } else {
      redirectUrl = PATH.MULTI_EVENT_OUTPUT_CREATE.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    }
  }
  return {
    variableSetRedux,
    tableComponent,
    getFieldFn,
    variableRedux,
    variablesRedux,
    createPath,
    editPath,
    redirectUrl
  }
}

export const findHighestSuppressIndex = array => {
  if (array?.length) {
    for (let i = array?.length - 1; i >= 0; i--) {
      if (array[i].Suppress) {
        return i + 1
      }
    }
  }

  return 0 // Return 0 if no object with Suppress=true is found
}
