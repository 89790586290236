import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip
} from "@mui/material"
import { useEffect, useState } from "react"
import { ConfigService } from "../../../../src/service/config.service"
import TextFieldChips from "../../../../src/utils/reusables/TextFieldChips"
import CheckIcon from "@mui/icons-material/Check"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useTranslation } from "react-i18next"
import {
  getCustomerPortalText,
  getDestinationText,
  getEmailText,
  getEmailTooltipText,
  getFtpDirectoryText
} from "./ReportFileDestinationHelper"
import InfoIcon from "@mui/icons-material/Info"
import shortid from "shortid"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
const ReportFileDestination = ({
  form,
  setSelectedOption,
  setChips,
  setCustomerPortalCheck,
  isValid,
  setIsValid
}) => {
  const customerList = useSelector(
    (state: RootState) => state.settings?.customers
  )
  const selectedCustomerList = customerList?.filter((c: any) => c.selected)
  const configService = new ConfigService()
  const { t } = useTranslation<any>()
  const [connections, setConnections] = useState([] as any)
  const emailTooltipText = getEmailTooltipText(t)

  const handleCheckboxChange = event => {
    setCustomerPortalCheck(event.target.checked)
  }
  const handleChange = (_event, value) => {
    setSelectedOption(value)
  }
  useEffect(() => {
    setSelectedOption(form.selectedOption)
  }, [])

  useEffect(() => {
    let isMounted = true
    if (isMounted && customerList) {
      GetConnectionData()
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [customerList])
  const GetConnectionData = async () => {
    try {
      const payload = selectedCustomerList.map(i => i.idCustomer)
      await configService.getConnectionData(payload).then((temp: any) => {
        let ftpConnections = temp.data || []
        ftpConnections = ftpConnections.map(ftp => ftp.name)
        ftpConnections.unshift("None")
        setConnections(ftpConnections)
      })
    } catch (_error) {
      return _error
    }
    return true
  }
  return (
    <Grid
      container
      data-testid="reportings-prompt-display-grid"
      className="custom-table-responsive categories-table"
    >
      <Grid item xs={12} sm={12}>
        <Divider className="dashed my-4" />
        <h4 className="my-3 text-uppercase">{getDestinationText(t)}</h4>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.customerPortalCheck}
                  name="english"
                  className="custom-check light"
                  onChange={handleCheckboxChange}
                  disabled
                  checkedIcon={<CheckIcon />}
                />
              }
              label={getCustomerPortalText(t)}
            />
          </Grid>
          <Grid item xs={12} className="mt-3">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <label className="mb-1 f-600">{getEmailText(t)}</label>
                <Tooltip
                  id="emailTooltip"
                  arrow
                  title={
                    <div className="pa-2">
                      <h6 className="mb-2 f-600 text-uppercase">
                        <InfoIcon className="primary-text mr-1" />
                        {getEmailText(t)}
                      </h6>

                      {emailTooltipText.split("\n").map((line, index) => (
                        <div className="mb-1" key={shortid.generate()}>
                          {line}
                        </div>
                      ))}
                    </div>
                  }
                >
                  <InfoOutlinedIcon className="primary-text ml-1" />
                </Tooltip>
                <TextFieldChips
                  getChips={setChips}
                  form={form}
                  isValid={isValid}
                  setIsValid={setIsValid}
                />
              </Grid>
              <Grid item xs={12} sm={3} data-testid="FTPConnectionAutoGrid">
                <label className="mb-1 f-600">{getFtpDirectoryText(t)}</label>
                <FormControl
                  className="custom-form-control select no-border"
                  variant="filled"
                >
                  <Autocomplete
                    id="demo-multiple-checkbox"
                    value={form.selectedOption}
                    onChange={handleChange}
                    options={connections}
                    getOptionLabel={item => item}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={getFtpDirectoryText(t)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReportFileDestination
