import classNames from "classnames"
import { useDrop } from "react-dnd"
import { COMPONENT, SIDEBAR_ITEM, ROW, COLUMN } from "./LRConstants"

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN]

const DropZone = ({ data, onDrop, isLast, className }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop(data, item)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const isActive = isOver && canDrop
  return (
    <div
      className={classNames(
        "dropZone",
        { active: isActive, isLast },
        className
      )}
      ref={drop}
      data-testid="dropZone-drop"
    />
  )
}
export default DropZone
