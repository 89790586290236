import { useHistory, useParams } from "react-router-dom"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { PATH } from "src/constants/paths"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useAppThunkDispatch } from "src/redux/store"
import { setVarianceRedux } from "src/redux/slices/ConfigPortal/VarianceSlice"
import FormSubmitCancelButtons from "src/configurationPortal/pages/Components/FormSubmitCancelButtons"

const FormSubmitCancel = () => {
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const {
    varianceData: { varianceRedux }
  } = ConfigDataHook()

  const dispatch = useAppThunkDispatch()

  let search = window.location.search

  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.VARIANCES + search)
    })
  }

  const handleSaveClick = data => {
    //istanbul ignore next
    let locVarianceRedux = JSON.parse(JSON.stringify(varianceRedux))

    let ReportFields =
      varianceRedux[varianceRedux.CurrentTabVarianceType].ReportFields
    if (storeId <= -1) {
      ReportFields = [...ReportFields, data]
    } else {
      ReportFields = ReportFields.map((i, j) =>
        j === storeId - 1 ? { ...i, ...data } : i
      )
    }
    locVarianceRedux[varianceRedux.CurrentTabVarianceType].ReportFields =
      ReportFields
    dispatch(setVarianceRedux(locVarianceRedux))
    onCancelClick()
  }
  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClick}
      onCancelClick={onCancelClick}
    />
  )
}

export default FormSubmitCancel
