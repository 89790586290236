import { formField } from "src/utils/constants"
import { WeekDayOptions, WeekNumberOptions, renderType } from "./FcsOptions"
import { monthlyFieldNames } from "../CreateEditMultiEventOutput"

export const monthlyRadioOptions = {
  day: "day",
  week: "week"
}

export const monthlyFields = [
  [
    {
      type: renderType.field,
      fieldType: formField.radio,
      width: "22px",
      props: {
        name: monthlyFieldNames.WeekRadio.name,
        formControl: {
          radioGroup: {
            row: true,
            formControlLabels: [
              {
                value: monthlyRadioOptions.week,
                label: ""
              }
            ].map(i => ({ ...i, radio: { size: "medium" }, className: "mr-5" }))
          }
        }
      },
      resetFieldNames: [{ name: monthlyFieldNames.DayRadio.name, value: "" }]
    },
    { type: renderType.heading, heading: "The", className: "ml-1" },
    {
      type: renderType.field,
      fieldType: formField.auto_dropdown,
      width: "150px",
      className: "ml-2",
      disableCondition: {
        name: monthlyFieldNames.WeekRadio.name,
        value: monthlyRadioOptions.week
      },
      props: {
        name: monthlyFieldNames.WeekNumber.name,
        label: monthlyFieldNames.WeekNumber.label,
        required: true,
        isNotSort: true,
        options: WeekNumberOptions
      }
    },
    {
      type: renderType.field,
      fieldType: formField.auto_dropdown,
      width: "200px",
      className: "ml-2",
      disableCondition: {
        name: monthlyFieldNames.WeekRadio.name,
        value: monthlyRadioOptions.week
      },
      props: {
        name: monthlyFieldNames.WeekDay.name,
        label: monthlyFieldNames.WeekDay.label,
        required: true,
        isNotSort: true,
        options: WeekDayOptions
      }
    }
  ]
]
