
export const LOCATION_FIELD_MAP = {
  NAME: "name",
  SITE_ID: "siteId",
  BANNER: "banner",
  PHONE: "phone",
  EMAIL: "email",
  REGION_1: "region1",
  REGION_2: "region2",
  REGION_3: "region3",
  REGION_4: "region4",
  LOCATION_ATTRIBUTE_1: "locationAttribute1",
  LOCATION_ATTRIBUTE_2: "locationAttribute2",
  LOCATION_ATTRIBUTE_3: "locationAttribute3",
  LOCATION_ATTRIBUTE_4: "locationAttribute4",
  LOCATION_ATTRIBUTE_5: "locationAttribute5",
  LOCATION_ATTRIBUTE_6: "locationAttribute6",
  LOCATION_ATTRIBUTE_7: "locationAttribute7",
  LOCATION_ATTRIBUTE_8: "locationAttribute8",
  LOCATION_ATTRIBUTE_9: "locationAttribute9",
  LOCATION_ATTRIBUTE_10: "locationAttribute10",
  ADDRESS_1: "address1",
  ADDRESS_2: "address2",
  ADDRESS_3: "address3",
  CITY: "city",
  STATE: "state",
  POSTAL_CODE: "postalCode",
  COUNTRY: "country"
};
