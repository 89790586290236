import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import LinearProgress from "@mui/material/LinearProgress"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { PATH } from "src/constants/paths"
import { useEffect, useState } from "react"
import { ScheduleService } from "src/service/schedule.service"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import shortid from "shortid"
import { Translates } from "src/i18n/i18n"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import { getBulkAPIValidation, getTranslations } from "src/utils/helper"
import { BULK_ACTIONS } from "src/utils/constants"
const ScheduleBulkValidation = ({ setPage }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const scheduleService = new ScheduleService()
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [scheduleresponseData, setScheduleResponseData] = useState({} as any)
  const [ignoreState, setIgnoreState] = useState("")
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const mappingPayloadData = useSelector(
    (state: RootState) => state.schedule.mappingPayloadData
  )

  useEffect(() => {
    ScheduleValidationPost(false, null)
  }, [])

  let config = {
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      setProgress(percentCompleted)
    }
  }

  const ScheduleValidationPost = async (
    isConfirmed: boolean,
    confirmationAction: any
  ) => {
    const _payload = {
      idCustomer: mappingPayloadData.idCustomer,
      isConfirmed: isConfirmed,
      confirmationAction: confirmationAction,
      columns: mappingPayloadData.columns,
      fileData: mappingPayloadData.fileData
    }
    if (!isConfirmed) {
      setLoading(true)
    }

    try {
      await scheduleService
        .bulkUpdateSchedule(_payload, isConfirmed ? {} : config)
        .then(res => {
          setScheduleResponseData(res.data)
          setLoading(false)
          if (isConfirmed) {
            history.push(PATH.SCHEDULE)
          }
        })
    } catch (_error) {
      console.error(_error)
    }
  }

  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.SCHEDULE)
      }, 3000)
    }
  }
  const handlePreviousClick = () => {
    setPage("page2")
  }
  const handleCancelClick = () => {
    history.push(PATH.SCHEDULE)
  }
  const handleStatusChange = e => {
    setIgnoreState(e.target.value)
  }
  const saveDisable = () => {
    const { errorRecordsCount, recordsInsertedCount } = scheduleresponseData
if(recordsInsertedCount>0) return false
    const allInsertedRecordsHaveErrors =
      recordsInsertedCount == 0 || errorRecordsCount === recordsInsertedCount

    return loading || allInsertedRecordsHaveErrors
    // return loading || scheduleresponseData?.errorRecordsCount > 0
    // if (scheduleresponseData?.errorRecordsCount > 0) {
    //   return true
    // } else {
    //   return progress < 100
    // }
  }

  const handleImportClick = () => {
    let continueStatus: string | null = null
    const UPDATE_STRING: string = BULK_ACTIONS?.UPDATE
    if (scheduleresponseData.duplicateRecords?.length > 0) {
      if (ignoreState === UPDATE_STRING) {
        continueStatus = UPDATE_STRING
      } else {
        continueStatus = BULK_ACTIONS?.IGNORE
      }
    }
    ScheduleValidationPost(true, continueStatus)
  }
  return (
    <>
      <Grid container>
        <MiniHeader
          headerTitle={getTranslations(t, Translates.UPLOAD_SCHEDULES)}
          multiCustomer={handleNoCustomerId}
        />
        <Grid container className="inner-view">
          <Grid item xs={12} className="main-container">
            <Grid container>
              <Grid item xs={12}>
                <h4 className="inner-view__title">
                  {getTranslations(t, Translates.Validation)}
                </h4>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <p className="mb-0 font-16 f-700 ">
                {getTranslations(t, "Validation")}{" "}
                {progress == 100
                  ? getTranslations(t, Translates.Completed)
                  : getTranslations(t, Translates.In_Progress)}
              </p>
              <Grid container spacing={2} className="mb-5 justify-content-end ">
                <span className="justify-content-end font-15">
                  {progress}% {getTranslations(t, Translates.Completed)}
                </span>
                <Grid item sm={12}>
                  <div className={`custom-progressbar`}>
                    <LinearProgress
                      color="success"
                      className={`custom-progressbar__bar}`}
                      variant="determinate"
                      value={progress}
                    />
                  </div>
                </Grid>
              </Grid>
              {!loading ? (
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <Divider className="mb-3 dashed " />
                    <p className="f-700 font-18 ">
                      {getTranslations(t, Translates.Validation_Outcome)}
                    </p>
                    <Divider className="mb-3" />
                    <p className="font-15 f-700">
                      {getTranslations(t, Translates.File_Statistics)}
                    </p>
                    {scheduleresponseData?.recordsInsertedCount > 0 ? (
                      <Accordion className="pointer-events-none custom-accordion dark validates success ">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h5>
                            {scheduleresponseData?.recordsInsertedCount}
                            {scheduleresponseData?.recordsInsertedCount > 1
                              ? ` ${getTranslations(t, Translates.Records)}`
                              : ` ${getTranslations(t, Translates.Record)}`}
                            {` ${getTranslations(
                              t,
                              Translates.will_be_inserted
                            )}`}
                          </h5>
                        </AccordionSummary>
                      </Accordion>
                    ) : (
                      <Accordion className="custom-accordion dark validates success pointer-events-none">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h5>
                            {" "}
                            {getTranslations(
                              t,
                              Translates.No_Records_will_be_inserted
                            )}{" "}
                          </h5>
                        </AccordionSummary>
                      </Accordion>
                    )}
                    <Grid item xs={12} sm={12} className="mt-4">
                      {scheduleresponseData?.duplicateRecords?.length ? (
                        <Accordion className="info custom-accordion dark validates ">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                            aria-controls="panel1a-content"
                          >
                            <h5>
                              {scheduleresponseData?.duplicateRecords?.length}
                              {scheduleresponseData?.duplicateRecords?.length >
                              1
                                ? ` ${getTranslations(t, Translates.Sites)}`
                                : ` ${getTranslations(t, Translates.Site)}`}
                              {` ${getTranslations(
                                t,
                                Translates.already_exist_in_FlexCount
                              )}`}
                            </h5>

                            <Grid item xs={12} sm={6} className="mt-1">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  value={ignoreState}
                                  name="controlled-radio-buttons-group"
                                  onChange={handleStatusChange}
                                >
                                  <FormControlLabel
                                    control={
                                      <Radio data-testid="radio-update1" />
                                    }
                                    value={BULK_ACTIONS?.UPDATE}
                                    label={getTranslations(
                                      t,
                                      Translates.Update
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio data-testid="radio-ignore1" />
                                    }
                                    value={BULK_ACTIONS?.IGNORE}
                                    label={getTranslations(
                                      t,
                                      Translates.Ignore
                                    )}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container xs={12}>
                              <Grid item xs={12} sm={12}>
                                <ul>
                                  {scheduleresponseData?.duplicateRecords?.map(
                                    (m: any) => {
                                      const scheduleDuplicates =
                                        getBulkAPIValidation(m)
                                      return (
                                        <li key={shortid.generate()}>
                                          {`${getTranslations(
                                            t,
                                            scheduleDuplicates?.rowPart
                                          )}  ${
                                            scheduleDuplicates?.rowNumber
                                          }:  ${getTranslations(
                                            t,
                                            scheduleDuplicates?.msg
                                          )}`}
                                        </li>
                                      )
                                    }
                                  )}
                                </ul>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      {scheduleresponseData?.errorRecordsCount ? (
                        <Accordion className=" error custom-accordion dark validates  ">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${scheduleresponseData?.errorRecordsCount} ${
                              scheduleresponseData?.errorRecordsCount > 1
                                ? getTranslations(
                                    t,
                                    Translates.Records_have_errors
                                  )
                                : getTranslations(
                                    t,
                                    Translates.Record_has_error
                                  )
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {scheduleresponseData?.errorColumns?.map(
                                (m: any) => {
                                  const scheduleErrors = getBulkAPIValidation(m)
                                  return (
                                    <li key={shortid.generate()}>
                                      {`${getTranslations(
                                        t,
                                        scheduleErrors?.rowPart
                                      )}  ${
                                        scheduleErrors?.rowNumber
                                      }:  ${getTranslations(
                                        t,
                                        scheduleErrors?.msg
                                      )}`}
                                    </li>
                                  )
                                }
                              )}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      {scheduleresponseData?.warningRecordsCount ? (
                        <Accordion className="warning custom-accordion dark validates  ">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${
                              scheduleresponseData?.warningRecordsCount
                            } ${
                              scheduleresponseData?.warningRecordsCount > 1
                                ? getTranslations(
                                    t,
                                    Translates.Records_have_warnings
                                  )
                                : getTranslations(
                                    t,
                                    Translates.Record_has_warning
                                  )
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {scheduleresponseData?.warningColumns?.map(
                                (m: any) => {
                                  const scheduleWarns = getBulkAPIValidation(m)
                                  return (
                                    <li key={shortid.generate()}>
                                      {`${getTranslations(
                                        t,
                                        scheduleWarns?.rowPart
                                      )}  ${
                                        scheduleWarns?.rowNumber
                                      }:  ${getTranslations(
                                        t,
                                        scheduleWarns?.msg
                                      )}`}
                                    </li>
                                  )
                                }
                              )}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <BulkUploadButtons
                cancelBLabel={getTranslations(t, Translates.Cancel)}
                handleCancelClick={handleCancelClick}
                handlePreviousClick={handlePreviousClick}
                handleNextClick={handleImportClick}
                nextBLabel={getTranslations(t, Translates.Next_Begin_Import)}
                saveDisable={saveDisable}
                previousBLabel={getTranslations(t, Translates.Previous)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default ScheduleBulkValidation
