import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UploadFileService } from "src/service/uploadfile.service"
import { TagCategory } from "src/utils/types/types"

export interface UploadFileData {
  validationControlId: number
  name: string
  uploadedBy: number
  uploadedDateTime: string
}

export interface ValidationDepartment {
  validationDepartmentId: number
  validationControlId: number
  customerId: number
  departmentParentSort: number
  department: string
  departmentName: string
  departmentSort: number
  departmentParent: string
  departmentParentName: string
  createdBy: string
  updatedBy: string
}

export interface UploadFileState {
  status: string
  uploadStatus: any
  uploadStatusFilters: any
  uploadStatusFilterDatas: any[]
  uploadFilesStatusRecordsRender: any[]
  uploadFilesStatusRecordsInitialResp: any[]
  uploadFilesData: {
    status: string
    data: UploadFileData[] | null
  }
  validationDepartmentData: {
    status: string
    data: ValidationDepartment[] | null
  }
  departmentData: any
  varianceData: any
  areaRangeData: any
  uploadFilesFullData: any
  uploadFileMappingData: any
  uploadFileMappingPayloadData: any
  csvUploadFileColumnListDepartment: string[]
  csvUploadFileColumnListVariance: string[]
  csvUploadFileColumnListAreaRange: string[]
  categories: TagCategory[]
  uploadFileFilePath: string
  newFileData: any
  continuesStatus: any
}
const initialState: UploadFileState = {
  status: "",
  categories: [],
  uploadStatus: [],
  uploadStatusFilters: { sDate: "" },
  uploadStatusFilterDatas: [],
  uploadFilesStatusRecordsRender: [],
  uploadFilesStatusRecordsInitialResp: [],
  uploadFilesData: {
    status: "",
    data: null
  },
  validationDepartmentData: {
    status: "",
    data: null
  },
  departmentData: [],
  varianceData: [],
  areaRangeData: [],
  newFileData: "",
  uploadFilesFullData: [""],
  uploadFileMappingData: {},
  uploadFileMappingPayloadData: {},
  csvUploadFileColumnListDepartment: [
    "Department Group",
    "Department Name",
    "Department Sort",
    "Department Parent Name",
    "Department Parent Sort",
    "Department Parent"
  ],
  csvUploadFileColumnListVariance: [
    "Variance Group",
    "Group Name",
    "Sort Order",
    "Qty Tolerance",
    "Full Store Qty Tolerance",
    "Ext Tolerance",
    "Full Store Ext Tolerance"
  ],
  csvUploadFileColumnListAreaRange: [
    "Category Name",
    "Range Name",
    "Area Range Sort"
  ],
  uploadFileFilePath: "",
  continuesStatus: ""
}
const uploadFileService = new UploadFileService()
export const getUploadStatus = createAsyncThunk(
  "uploadFilesList/getUploadStatus",
  async (params: any) => {
    let customerId = params.customerId
    return uploadFileService.getUploadFilesViewStatus(customerId)
  }
)

export const getCategories = createAsyncThunk(
  "countList/getCategories",
  async (configurationId: string) => {
    return uploadFileService.getCategories(configurationId)
  }
)

export const getUploadFiles = createAsyncThunk(
  "uploadFilesList/getUploadFiles",
  async (customerId: string) => {
    return uploadFileService.getUploadFiles(customerId)
  }
)

export const getValidationDepartmentDetails = createAsyncThunk(
  "uploadFilesList/getValidationDepartmentDetails",
  async ({
    customerId,
    validationControlId
  }: {
    customerId: string
    validationControlId: string
  }) => {
    return uploadFileService.getValidationDepartmentDetails(
      customerId,
      validationControlId
    )
  }
)

export const deleteValidationDepartment = createAsyncThunk(
  "uploadFilesList/deleteValidationDepartment",
  async ({
    customerId,
    validationControlId,
    validationDepartmentId,
    updatedBy
  }: {
    customerId: string
    validationControlId: string
    validationDepartmentId: string
    updatedBy: string
  }) => {
    return uploadFileService.deleteValidationDepartment(
      customerId,
      validationControlId,
      validationDepartmentId,
      updatedBy
    )
  }
)
//istanbul ignore next
const mapUploadConfigData = data => {
  if (!data || !Array.isArray(data)) return []
  data = data.map((config: any) => {
    let filesData: any = (config && config.uploadFileData) || []
    filesData = filesData.map(file => {
      return {
        ...file,
        idConfig: config.idConfig,
        lastUpdatedDate: file.uploadedDateTime,
        description: file.description,
        configName: config.configName,
        updatedUserName: config.updatedUserName
      }
    })
    return { ...config, uploadFileData: filesData }
  })
  return data
}

export const uploadFilesSlice = createSlice({
  name: "uploadFilesList",
  initialState,
  reducers: {
    setUploadStatusFilters: (state, _action: PayloadAction<any>) => {
      state.uploadStatusFilters = {
        ...state.uploadStatusFilters,
        ..._action.payload
      }
    },
    setUploadFileData: (state, _action: PayloadAction<any>) => {
      state.uploadFilesFullData = _action.payload
    },
    setCSVUploadFileColumnListDeparment: (
      state: any,
      _action: PayloadAction<any>
    ) => {
      state.csvUploadFileColumnListDepartment = [..._action.payload]
    },
    setCSVUploadFileColumnListVariance: (
      state: any,
      _action: PayloadAction<any>
    ) => {
      state.csvUploadFileColumnListVariance = [..._action.payload]
    },
    setCSVUploadFileColumnListAreaRange: (
      state: any,
      _action: PayloadAction<any>
    ) => {
      state.csvUploadFileColumnListAreaRange = [..._action.payload]
    },
    setUploadFileMappingData: (state, _action: PayloadAction<any>) => {
      state.uploadFileMappingData = { ..._action.payload }
    },
    setUploadFileMappingPayloadData: (state, _action: PayloadAction<any>) => {
      state.uploadFileMappingPayloadData = _action.payload
    },
    setNewFileData: (state, _action: PayloadAction<any>) => {
      state.newFileData = _action.payload
    },
    setUploadStatusFilterDatas: (state, _action: PayloadAction<any>) => {
      state.uploadStatusFilterDatas = [..._action.payload]
    },
    setUploadFilesStatusRecordsRender: (state, _action: PayloadAction<any>) => {
      return { ...state, uploadFilesStatusRecordsRender: [..._action.payload] }
    },
    setUploadFilesStatusRecordsInitialResp: (
      state,
      _action: PayloadAction<any>
    ) => {
      state.uploadFilesStatusRecordsInitialResp = [..._action.payload]
    },
    setDepartmentData: (state, _action: PayloadAction<any>) => {
      state.departmentData = [..._action.payload]
    },
    setVarianceData: (state, _action: PayloadAction<any>) => {
      state.varianceData = [..._action.payload]
    },
    setAreaRangeData: (state, _action: PayloadAction<any>) => {
      state.areaRangeData = [..._action.payload]
    },
    setContinueStatus: (state, _action: PayloadAction<any>) => {
      state.continuesStatus = _action.payload
    },

    setUploadFileReset: () => initialState,

    setUploadFilePath: (state, _action: PayloadAction<any>) => {
      state.uploadFileFilePath = _action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getUploadStatus.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(getUploadStatus.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.uploadStatus = payload.data
    })
    builder.addCase(getUploadStatus.rejected, state => {
      state.status = "failed"
    })

    builder.addCase(getCategories.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.status = "success"




      state.categories = payload.data
    })
    builder.addCase(getCategories.rejected, state => {
      state.status = "failed"
    })

    builder.addCase(getUploadFiles.pending, state => {
      state.uploadFilesData.status = "loading"
      state.uploadFilesData.data = null
    })
    //istanbul ignore next
    builder.addCase(getUploadFiles.fulfilled, (state, { payload }) => {
      state.uploadFilesData.status = "success"
      state.uploadFilesData.data = mapUploadConfigData(payload.data)
    })
    builder.addCase(getUploadFiles.rejected, state => {
      state.uploadFilesData.status = "failed"
      state.uploadFilesData.data = null
    })

    builder.addCase(getValidationDepartmentDetails.pending, state => {
      state.validationDepartmentData.status = "loading"
      state.validationDepartmentData.data = null
    })
    //istanbul ignore next
    builder.addCase(
      getValidationDepartmentDetails.fulfilled,
      (state, { payload }) => {
        state.validationDepartmentData.status = "success"
        state.validationDepartmentData.data = payload.data
      }
    )
    builder.addCase(getValidationDepartmentDetails.rejected, state => {
      state.validationDepartmentData.status = "failed"
      state.validationDepartmentData.data = null
    })
    builder.addCase(deleteValidationDepartment.pending, state => {})
    //istanbul ignore next
    builder.addCase(
      deleteValidationDepartment.fulfilled,
      (state, { payload }) => {}
    )
  }
})
export const {
  setUploadStatusFilters,
  setUploadFilesStatusRecordsRender,
  setUploadFilesStatusRecordsInitialResp,
  setDepartmentData,
  setVarianceData,
  setAreaRangeData,
  setUploadFileData,
  setCSVUploadFileColumnListDeparment,
  setCSVUploadFileColumnListVariance,
  setCSVUploadFileColumnListAreaRange,
  setUploadFileMappingData,
  setUploadFileMappingPayloadData,
  setUploadStatusFilterDatas,
  setUploadFileReset,
  setNewFileData,
  setUploadFilePath,
  setContinueStatus
} = uploadFilesSlice.actions
export default uploadFilesSlice.reducer
