import React from "react"
import { useFormContext } from "react-hook-form"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  conditionBasedReturnFieldComponent,
  handleFieldChangeRevalidation
} from "src/configurationPortal/pages/FunctionHelper"
import { renderType } from "src/pages/MultiEventOutputs/Utils/Constants/FileCreationSchedule/FcsOptions"
import { getTranslations } from "src/utils/helper"

const FcsOptionsFields = ({
  FieldDetails,
  checkFieldRenderCondition
}: {
  FieldDetails: any
  checkFieldRenderCondition?: Function
}) => {
  const { t } = useTranslation<any>()

  const formHookMethods = useFormContext()

  const handleFieldChange = (FieldDetail: any) => {
    handleFieldChangeRevalidation({
      FieldDetail,
      formHookMethods
    })
  }
  return (
    <Grid item xs={12} sm={12}>
      <Grid container className="align-items-center">
        {FieldDetails.map((i, j) => {
          return (
            <React.Fragment key={`field-${i.props?.name}-${i.heading}`}>
              <Grid
                item
                className={`${i.className}`}
                style={{ ...(i.width && { width: i.width }) }}
              >
                {i.type === renderType.heading
                  ? getTranslations(t, i.heading)
                  : null}
                {i.type === renderType.field
                  ? conditionBasedReturnFieldComponent(
                      {
                        ...i
                      },
                      formHookMethods,
                      handleFieldChange
                    )
                  : null}
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default FcsOptionsFields
