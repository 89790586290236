import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ReservationsState {
  status: string
  reservationFilters: any
  reservationPrevFilters: any
  validReservationColumnList: string[]
  csvReservationColumnList: string[]
  reservationFileData: any
  reservationMappingData: any
  mappingPayloadData: any
  bulkReservationFilePath: string
  reservationSort: any
  reservationColumn: any
}

const initialState = {
  status: "",
  csvReservationColumnList: [],
  mappingPayloadData: {},
  reservationFileData: [""],
  reservationFilters: {
    status: ["Pending", "Confirmed"],
    country: [],
    sDate: "",
    eDate: ""
  },
  bulkReservationFilePath: "",

  reservationPrevFilters: {
    status: ["Pending", "Confirmed"],
    country: [],
    sDate: "",
    eDate: ""
  },
  reservationMappingData: {},
  validReservationColumnList: [
    "Reservation",
    "Country",
    "StartDate",
    "EndDate",
    "ReservedDevices",
    "AverageWisTagsRequired",
    "TrainingDays",
    "EventDays",
    "Notes",
    "IsConfirmed",
    "Confirmation"
  ],
  reservationSort: "",
  reservationColumn: ""
}

export const reservationsSlice = createSlice({
  name: "reservationsList",
  initialState,
  reducers: {
    setReservationFilters: (state, _action: PayloadAction<any>) => {
      state.reservationFilters = {
        ...state.reservationFilters,
        ..._action.payload
      }
    },
    setReservationPrevFilters: (state, _action: PayloadAction<any>) => {
      state.reservationPrevFilters = {
        ...state.reservationPrevFilters,
        ..._action.payload
      }
    },
    setReservationFileData: (state, _action: PayloadAction<any>) => {
      state.reservationFileData = _action.payload
    },
    setCSVReservationColumnList: (state: any, _action: PayloadAction<any>) => {
      state.csvReservationColumnList = [..._action.payload]
    },
    setReservationMappingData: (state, _action: PayloadAction<any>) => {
      state.reservationMappingData = { ..._action.payload }
    },
    setMappingPayloadData: (state, _action: PayloadAction<any>) => {
      state.mappingPayloadData = _action.payload
    },
    setReservationReset: () => initialState,

    setReservationLocationFile: (state, _action: PayloadAction<any>) => {
      state.bulkReservationFilePath = _action.payload
    },
    setReservationSort: (state, _action: PayloadAction<any>) => {
      state.reservationSort = _action.payload
    },
    setReservationColumn: (state, _action: PayloadAction<any>) => {
      state.reservationColumn = _action.payload
    }
  }
})
export const {
  setReservationFilters,
  setCSVReservationColumnList,
  setReservationFileData,
  setReservationMappingData,
  setMappingPayloadData,
  setReservationReset,
  setReservationPrevFilters,
  setReservationLocationFile,
  setReservationSort,
  setReservationColumn
} = reservationsSlice.actions
export default reservationsSlice.reducer
