//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"
import { API_REQUESTS } from "../utils/constants"
 import { encrypt } from "src/utils/helper"

const api = new ApiService()

export class SqlTestEditorService {
  postSqlTestEditorValueForVerify(query) {
    const endPoint = API_REQUESTS.SQLEDITORTESTAPI
    const payLoad = {
       sqlQuery: encrypt(query)
    }

    return api
      .sqlPost(endPoint, payLoad)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
}
//END-NOSCAN