import { useReducer, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Button, Divider, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { Translates } from "src/i18n/i18n"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { useAppThunkDispatch } from "src/redux/store"
import {
  ConfigTabNames,
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  getColumnsData,
  getVariableBasedOnComponent,
  setModalContent
} from "./Utils/Helpers/TotalsGridHelper"
import FormHeading from "src/components/FormHeading"
let preDeletedRow: any = {}
const Totals = ({
  componentName,
  totalType,
  fieldName
}: {
  componentName: string
  totalType: string
  fieldName: string
}) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [, setReload] = useReducer(reducer, false)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const dispatch = useAppThunkDispatch()
  const {
    columnReduxData: { columnsRedux }
  } = ConfigDataHook()
  const isSubtotal = totalType === ConfigTabNames.SubTotals
  const { setValue, getValues } = useFormContext()
  const { SubTotals, GrandTotals } = getValues()
  const rows = isSubtotal ? SubTotals : GrandTotals

  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""

  let { tableComponent, variableSetRedux, totalLabel, createPath, editPath } =
    getVariableBasedOnComponent(componentName, totalType, storeId, -1)

  const handleCreateTotalClick = () => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))

    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          createPath,
          [":tab", ":tabId", ":type"],
          [
            componentName.toLocaleLowerCase(),
            `${storeId}`,
            totalType.toLocaleLowerCase()
          ]
        ) + search,
        {
          variableRedux: getValues(),
          columnRedux: columnsRedux
        }
      )
    })
  }

  const updateSubtotal = (row1: { original: any; index: number }) => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))
    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          editPath,
          [":tab", ":tabId", ":type", ":id"],
          [
            componentName.toLocaleLowerCase(),
            `${storeId}`,
            totalType.toLocaleLowerCase(),
            `${row1.index + 1}`
          ]
        ) + search,
        {
          variableRedux: getValues(),
          columnRedux: columnsRedux
        }
      )
    })
  }
  const deleteSubtotal = (row1: { original: any; index: number }) => {
    setShowWarningModal(true)
    preDeletedRow = {
      ...row1.original,
      index: row1.index
    }
    setReload(oldVal => !oldVal)
  }

  const CallbackConfirmation = (res: boolean) => {
    if (res) {
      let dt: any[] = rows.filter((f: any, inx) => inx !== preDeletedRow.index)
      setValue(fieldName, dt)
    }
    setShowWarningModal(false)
    preDeletedRow = {}
    setReload(oldVal => !oldVal)
  }

  const columnsData = getColumnsData({
    updateSubtotal: updateSubtotal,
    deleteSubtotal: deleteSubtotal,
    columnsRedux: columnsRedux,
    totalType: totalType,
    t
  })

  return (
    <>
      <Grid
        container
        className="custom-table-responsive categories-table"
        data-testid="reportings-display-grid"
      >
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />

          <Grid container>
            <Grid item xs={6}>
              <FormHeading
                heading={`${getTranslations(t, Translates[totalLabel])}`}
              />
            </Grid>
            <Grid item xs={6} className="text-right">
              <Button
                variant="contained"
                data-testid="reportTotalCreate"
                className="primary-btn pa-2"
                onClick={handleCreateTotalClick}
                disabled={!columnsRedux?.columns?.length}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(
                  t,
                  isSubtotal
                    ? Translates.New_Sub_Total
                    : Translates.New_Grand_Total
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item className="mt-2">
          <GlobalTableDragDrop
            component={tableComponent}
            columns={columnsData}
            data={rows || []}
            noDataText={getTranslations(
              t,
              isSubtotal
                ? Translates.No_Sub_totals_data_available
                : Translates.No_Grand_totals_data_available
            )}
          />
        </Grid>
        {showWarningModal && (
          <ConfirmDialog
            open={showWarningModal}
            callBack={CallbackConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setModalContent({
              preDeletedRow,
              totalType,
              t
            })}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.CANCEL)}
          />
        )}
      </Grid>
    </>
  )
}

export default Totals
