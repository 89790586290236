import React, { useCallback, useEffect, useState } from "react"
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Button
} from "@mui/material"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Loader } from "../../../../src/utils/reusables/reusable"
import CheckIcon from "@mui/icons-material/Check"
import BreadCrumb from "../../../../src/components/BreadCrumb/BreadCrumb"
import { setDefaultCountFields } from "../../../../src/redux/slices/configSlice"
import { updateWarnOnTabChangeToStore } from "../helper"
import Instructions from "./Instructions"
import { LocationsList, instructionDetails } from "./Constants"
import { CONFIG_MODULES } from "src/utils/constants"
import {
  LookupConfigToEditor,
  LookupEditorToConfig
} from "../LookupRoutines/helpers"
import { PATH } from "src/constants/paths"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { getTranslations, setWeekFieldError } from "src/utils/helper"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { auditRuleTypes } from "../Audits/AuditHelper"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
interface ICreateFieldsLocation {
  field?: string
  Enabled: boolean
  Labels: any
  ShowOnTagDetails: boolean
  ShowOnQtyEntry: boolean
  IsAllowEdits: boolean
  ValidationInstructions: any[]
}

const CountFieldsCreateEditLocation = () => {
  const { t } = useTranslation<any>()
  const location = useLocation() as any
  let search = window.location.search || ""
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    config: {
      defaultCountFields,
      configType: configTypeFromStore,
      configDetails
    }
  } = ConfigDataHook()

  const [form, setForm] = React.useState<ICreateFieldsLocation>({
    field: "",
    Enabled: true,
    Labels: [],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false,
    IsAllowEdits: false,
    ValidationInstructions: []
  } as ICreateFieldsLocation)
  const [errorMsg, setErrorMsg] = useState(false)
  const [isLabelErrorMessage, setIsLabelErrorMessage] = useState(false)

  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const [weekIVFields, setWeekIVFields] = useState({
    displayName: ""
  })

  let locationsMap = new Map()
  let locationsArray: any = []

  for (let key in LocationsList) {
    if (LocationsList.hasOwnProperty(key)) {
      locationsMap.set(key, LocationsList[key])
      const obj: any = { ...LocationsList[key], field: key }
      locationsArray.push(obj)
    }
  }

  let filtered = Object.keys(LocationsList).filter(key => {
    return !Object.keys(defaultCountFields).includes(key)
  })

  const locationState = { ...location.state }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (locationState.isEdit === true) {
        setIsEdit(true)
        setForm({
          ...form,
          ...locationState.data,
          ...(locationState.data?.ValidationInstructions &&
            locationState.data.ValidationInstructions.length && {
              ValidationInstructions: LookupConfigToEditor(
                locationState.data.ValidationInstructions
              )
            }),
          ...(locationState.data?.IsAllowEdits === undefined &&
            [LocationsList.Location.OjKey].includes(
              locationState.data?.field
            ) && {
              IsAllowEdits: true
            })
        })
      } else if (locationState.data) {
        const data = JSON.parse(locationState.data)
        if (data.isEdit) {
          setIsEdit(true)
        }
        setForm({
          ...form,
          ...data.CountFieldData,
          ...{ ValidationInstructions: data.Instructions }
        })
      } else {
        setForm({
          ...form,
          ...{
            field: filtered.length ? filtered[0] : "",
            Labels: [
              {
                "en-us": LocationsList[filtered[0]].Labels[0]["en-us"] || ""
              }
            ]
          }
        })
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  const getBreadcrumbConfig = () => {
    let countFields = "countFields"
    let custLinks: any = {}
    custLinks["@countFields"] = "configuration/countFields"
    let loc = "Location"
    let currentPath: string = `##configuration##/@${countFields}/${loc}`
    let variables: any = {}
    variables[`@${countFields}`] = getTranslations(t, Translates.Count_Fields)
    variables[`${loc}`] =
      locationState.isEdit === true
        ? `${getTranslations(t, Translates.Edit)} ${form.field}`
        : `${getTranslations(t, Translates.Add_New_Count_Field)}`
    let bConfig: any = {
      custLinks,
      showHomeIcon: false,
      currentPath,
      mapObj: variables
    }
    return bConfig
  }

  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value || ""
    if (e.target.name === "field" && !isEdit) {
      let obj = locationsMap.get(val)
      setForm({
        ...form,
        field: val,
        ...obj,
        Labels: [
          {
            "en-us": obj.Labels[0]["en-us"]
          }
        ]
      })
    } else if (e.target.name === "displayName") {
      if (val.length < 1) setErrorMsg(true)
      else {
        setErrorMsg(false)
        val.length > 10
          ? setIsLabelErrorMessage(true)
          : setIsLabelErrorMessage(false)
      }
      setForm({ ...form, Labels: [{ "en-us": val }] })

      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    } else if (["ShowOnTagDetails", "IsAllowEdits"].includes(e.target.name)) {
      setForm({ ...form, [e.target.name]: e.target.checked })
    }
  }

  const addEditFieldsLocation = async () => {
    if (
      [
        LocationsList.SecondaryLocation1.OjKey,
        LocationsList.SecondaryLocation2.OjKey,
        LocationsList.SecondaryLocation3.OjKey
      ].includes(form.field) &&
      configDetails.configuration
    ) {
      const dt = JSON.parse(configDetails.configuration)
      if (dt.Audits?.AuditTypeRule?.Type === auditRuleTypes.RESCAN) {
        setIsConfirmOpen(true)
        return false
      }
    }
    if (form.Labels[0]["en-us"]?.length > 10) {
      setIsLabelErrorMessage(true)
      return false
    }
    updateWarnOnTabChangeToStore(false)

    let DataField = ""
    let Enabled = true
    const BreakException = {}
    try {
      locationsArray.forEach(i => {
        if (i.field === form.field) {
          DataField = i.DataField
          Enabled = i.Enabled
          throw BreakException
        }
      })
    } catch (e) {
      if (e !== BreakException) throw e
    }

    let _payload: any = {
      field: form.field,
      ...(DataField && { DataField: DataField }),
      Enabled: Enabled,
      Labels: form.Labels,
      IsAllowEdits: form.IsAllowEdits,
      ShowOnTagDetails: form.ShowOnTagDetails,
      ShowOnQtyEntry: form.ShowOnQtyEntry
    }

    if (form.IsAllowEdits) {
      _payload = {
        ..._payload,
        ...{
          IsAllowEdits: form.IsAllowEdits,
          ValidationInstructions: LookupEditorToConfig(
            form.ValidationInstructions
          )
        }
      }
    }

    if (_payload.Labels.length === "") {
      setErrorMsg(true)
      return null
    } else {
      setErrorMsg(false)
    }

    let fieldKey: any = _payload?.field

    delete _payload?.field
    let finalObj: any = { data: { [fieldKey]: _payload }, type: "UPDATE" }

    setLoading(true)
    dispatch(setDefaultCountFields(finalObj))
    setTimeout(() => {
      setLoading(false)
      cancelClick()
    }, 100)
  }

  const cancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    history.push(PATH.CONFIGURATION_COUNT_FIELDS + search)
  }

  const errorCheck = () => {
    if (
      errorMsg ||
      isLabelErrorMessage ||
      (form.IsAllowEdits && !form.ValidationInstructions?.length) ||
      configTypeFromStore === "view" ||
      Object.values(weekIVFields).some(s => s || s !== "")
    ) {
      return true
    }
    return false
  }

  const labelErrorDisplay = () => {
    let errorMessage = ""
    if (errorMsg) {
      errorMessage = "Please enter valid Display Name"
    } else if (isLabelErrorMessage) {
      errorMessage = "Length can not be more than 10"
    } else if (weekIVFields.displayName) {
      errorMessage = weekIVFields.displayName
    }
    return errorMessage
  }

  const setModalContent = () => {
    const content = `${getTranslations(t, Translates.Rescan_select_warning)}.`
    return <AlertConfirmationContent warningMsg={content} />
  }

  const callbackConfirmation = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  return (
    <>
      {loading === true ? (
        Loader()
      ) : (
        <div>
          <div className="main-toolbar">
            <div className="main-container">
              <Grid container>
                <Grid item xs={12} className="d-flex align-items-center">
                  <div className="custom-breadcrumb CP">
                    <BreadCrumb
                      customPath={"Product"}
                      breadCrumbInfo={getBreadcrumbConfig()}
                      renderedPage={""}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className="main-container">
            <Grid item xs={12} sm={3}>
              <label className="form-control__label mb-3">
                {getTranslations(t, Translates.DETAILS)}
              </label>
              <div className="text-box">
                <FormControl
                  variant="filled"
                  className="custom-form-control select"
                >
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    variant="filled"
                  >
                    {getTranslations(t, Translates.Selected_Count_Field)}
                  </InputLabel>
                  <Select
                    data-testid="CFPCELocationSelectField"
                    id="demo-multiple-checkbox"
                    name="field"
                    value={form.field}
                    disabled
                    onChange={e => onFormChange(e)}
                    inputProps={{ "data-testid": "selecct-count-field" }}
                  >
                    {isEdit
                      ? locationsArray?.map((name: any) => (
                          <MenuItem
                            key={name.field}
                            value={name.field}
                            className="dropdown-list"
                          >
                            <small>{name.field}</small>
                          </MenuItem>
                        ))
                      : filtered?.map((name: any) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="dropdown-list"
                          >
                            <small>{name}</small>
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </div>
              <TextField
                fullWidth
                label={getTranslations(t, Translates.Display_Name)}
                variant="filled"
                autoSave="false"
                autoComplete="off"
                name="displayName"
                value={form.Labels[0]?.["en-us"] || ""}
                onChange={e => onFormChange(e)}
                error={
                  errorMsg || isLabelErrorMessage || !!weekIVFields.displayName
                }
                helperText={labelErrorDisplay()}
                className="custom-form-control"
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="CFCShowOnTagDetails"
                      className="custom-check light"
                      checked={form.ShowOnTagDetails}
                      name="ShowOnTagDetails"
                      onChange={e => onFormChange(e)}
                      disabled={form.field === LocationsList.Location.OjKey}
                      checkedIcon={<CheckIcon />}
                    />
                  }
                  label={getTranslations(t, Translates.Tag_Detail_Screen)}
                />
              </Grid>
            </Grid>

            {[LocationsList.Location.OjKey].includes(form.field) ? (
              <>
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="custom-check light"
                          checked={form.ShowOnQtyEntry}
                          disabled
                          checkedIcon={<CheckIcon />}
                        />
                      }
                      label={getTranslations(t, Translates.Qty_Entry_Screen)}
                    />
                  </Grid>
                </Grid>
                <Instructions
                  instructionDetails={{
                    ValidationInstructions:
                      instructionDetails.ValidationInstructions
                  }}
                  setForm={setForm}
                  form={form}
                  locationState={locationState}
                  componentName={CONFIG_MODULES.CountFieldInstructions.Location}
                  onFormChange={onFormChange}
                />
              </>
            ) : null}

            <Grid item xs={12}>
              <div className="inner-view__footer">
                <Button
                  variant="text"
                  className="primary-btn cancel-btn mr-5"
                  onClick={cancelClick}
                >
                  {getTranslations(t, Translates.Cancel)}
                </Button>
                <Button
                  variant="contained"
                  className="primary-btn"
                  disabled={errorCheck()}
                  onClick={() => addEditFieldsLocation()}
                >
                  {getTranslations(t, Translates.Save)}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {isConfirmOpen && (
        <ConfirmDialog
          open={isConfirmOpen}
          callBack={callbackConfirmation}
          title={getTranslations(t, Translates.Condition)}
          content={setModalContent()}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.Ok)}
          cancelButtonText={getTranslations(t, Translates.Cancel)}
        />
      )}
    </>
  )
}

export default CountFieldsCreateEditLocation
