import { Grid, Button, Alert, AlertTitle, LinearProgress } from "@mui/material"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import WarningIcon from "@mui/icons-material/Warning"
import { useTranslation } from "react-i18next"
import csvIcon from "../../assets/images/CsvIcon.svg"
import { useDropzone } from "react-dropzone"
import React, { useEffect } from "react"
import { UploadService } from "../../service/upload.service"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import {
  decryptUrlParams,
  fileExtensionCheck,
  getQueryParam,
  getTranslations
} from "src/utils/helper"
import { v4 as uuidv4 } from "uuid"
import { Translates } from "src/i18n/i18n"

let failedChunkAPICalls: any = []
let successChunkAPICalls: any = []
let overallChunkAPICalls: any = []
const UploadFilesData = () => {
  const [saving, setSaving] = React.useState(false)
  let fileSelected: any = null
  const [progress, setProgress] = React.useState(0)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )

  const uploadService = new UploadService()

  const { t } = useTranslation<any>()
  const [alertError, setAlertError] = React.useState({
    severity: "error" as any,
    title: "",
    content: "" as any,
    class: "grey"
  })
  const history = useHistory()

  const handleCancelClick = () => {
    history.push(PATH.UPLOADFILES)
  }

  let config = {
    onUploadProgress: progressEvent => {}
  }

  const idConfigEnc: any = getQueryParam("idConfig")
  const idConfig = decryptUrlParams(idConfigEnc)
  const idConfigNameEnc: any = getQueryParam("name")
  const idConfigName = decryptUrlParams(idConfigNameEnc)

  useEffect(() => {
    if (
      [undefined, null].includes(idConfigEnc) ||
      [undefined, null].includes(idConfigNameEnc)
    ) {
      history.push(PATH.UPLOADFILES)
    }
  }, [])

  const uploadOnHandsFile = (file: File) => {
    setSaving(true)
    setProgress(0)
    failedChunkAPICalls = []
    successChunkAPICalls = []
    overallChunkAPICalls = []

    const chunkSize = 1048576 * 10 //its 10MB, increase the number measure in mb

    let beginningOfTheChunk = 0
    let endOfTheChunk = chunkSize

    const _totalCount =
      file.size % chunkSize === 0
        ? file.size / chunkSize
        : Math.floor(file.size / chunkSize) + 1 // Total count of chunks will have been upload to finish the file

    const chunkAPICalls: any = []
    const locUUID = uuidv4()

    for (let i = 1; i <= _totalCount; i++) {
      if (i <= _totalCount) {
        let chunk = file.slice(beginningOfTheChunk, endOfTheChunk, file?.type)
        chunk = new File([chunk], file?.name, {
          type: file?.type
        })

        const payload = new FormData()
        payload.append("UploadFile", chunk)
        payload.append("FileName", file.name)
        payload.append("ChunkNumber", `${i}`)
        payload.append("ChunkSize", `${chunk.size}`)
        payload.append("FileIdentifier", locUUID)

        chunkAPICalls.push(chunkAPICall(payload))
        overallChunkAPICalls.push(payload)

        beginningOfTheChunk = endOfTheChunk
        endOfTheChunk = endOfTheChunk + chunkSize
      }
    }

    overallChunkAPICall(chunkAPICalls, file, _totalCount, locUUID)
  }

  const chunkAPICall = payload => {
    return new Promise((resolve, reject) => {
      uploadService
        .uploadFile(payload, config)
        .then(res => {
          if (
            !successChunkAPICalls.some(
              i => i.get("ChunkNumber") === payload.get("ChunkNumber")
            )
          ) {
            successChunkAPICalls.push(payload)
          }
          const percentage = Math.floor(
            (successChunkAPICalls.length / overallChunkAPICalls.length) * 100
          )
          setProgress(percentage)
          resolve(res.data)
        })
        .catch((err: any) => {
          if (
            !failedChunkAPICalls.some(
              i => i.get("ChunkNumber") === payload.get("ChunkNumber")
            )
          ) {
            failedChunkAPICalls.push(payload)
          }
          reject(err)
        })
    })
  }

  const overallChunkAPICall = async (
    chunkAPICalls,
    file,
    _totalCount,
    locUUID
  ) => {
    try {
      await Promise.all(chunkAPICalls).then(res => {
        if (overallChunkAPICalls.length !== successChunkAPICalls.length) {
          checkSuccessAPIWithOverallAPI(file, _totalCount, locUUID)
        } else {
          const payload = {
            OriginalFileName: file.name,
            InputFileName: idConfigName,
            CustomerId: customerId,
            IdConfig: idConfig,
            UploadedBy: customerId,
            UpdatedBy: customerId,
            NumberOfChunks: _totalCount,
            FileIdentifier: locUUID
          }

          finalMergeAPICall(payload)
        }
      })
    } catch {
      if (overallChunkAPICalls.length !== successChunkAPICalls.length) {
        checkSuccessAPIWithOverallAPI(file, _totalCount, locUUID)
      } else {
        setAlertError({
          ...alertError,
          title: "Something went wrong!",
          content: "Please try again"
        })
        throw Error("Promise failed")
      }
    }
  }

  const checkSuccessAPIWithOverallAPI = (file, _totalCount, locUUID) => {
    const isSuccessAPI = (a, b) => a.get("ChunkNumber") === b.get("ChunkNumber")
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        leftValue =>
          !right.some(rightValue => compareFunction(leftValue, rightValue))
      )
    failedChunkAPICalls = onlyInLeft(
      failedChunkAPICalls,
      successChunkAPICalls,
      isSuccessAPI
    )
    let locChunkAPICalls: any = []
    failedChunkAPICalls.forEach(i => {
      locChunkAPICalls.push(chunkAPICall(i))
    })
    overallChunkAPICall(locChunkAPICalls, file, _totalCount, locUUID)
  }

  const finalMergeAPICall = async payload => {
    await uploadService
      .finalFileMergePost(payload)
      .then(res => {
        setSaving(false)
        if (res.status === 200) {
          setAlertError({
            ...alertError,
            severity: "success",
            title: getTranslations(t, Translates.Uploaded),
            content: getTranslations(t, Translates.File_uploaded_successfully),
            class: "top"
          })
          setTimeout(() => {
            history.push(PATH.UPLOADFILES)
          }, 2000)
        }
      })
      .catch((err: any) => {
        setSaving(false)
        setAlertError({
          ...alertError,
          title: getTranslations(t, Translates.Please_try_again),
          content: getTranslations(t, Translates.Upload_file_failed)
        })
      })
  }

  const { getRootProps } = useDropzone({
    multiple: false,
    onError: error => {},
    onDrop: (acceptedFiles, rejectedFiles) => {
      setProgress(0)
      if (rejectedFiles.length) {
        setAlertError(oldVal => ({
          ...oldVal,
          severity: "error",
          title: getTranslations(t, Translates.Error),
          content: JSON.stringify(rejectedFiles[0].errors?.[0].message) || ""
        }))
      } else if (acceptedFiles.length) {
        const fileName: string = acceptedFiles[0].name
        let errorFind = 0

        if (!fileExtensionCheck({ fileName, setAlertError, t })) {
          errorFind++
        }
        if (errorFind <= 0) {
          fileSelected = acceptedFiles[0]
          setAlertError({ ...alertError, title: "", content: "" })
          uploadOnHandsFile(fileSelected)
        }
      }
    }
  })

  const showError = () => {
    return (
      <Alert
        severity={alertError.severity || "error"}
        icon={<WarningIcon />}
        className={`custom-alert ${alertError.class || "grey"}`}
      >
        <AlertTitle className="custom-alert__title">
          {alertError?.title}
        </AlertTitle>
        <>{alertError?.content}</>
      </Alert>
    )
  }

  return (
    <Grid container>
      <MiniHeader headerTitle={getTranslations(t, Translates.UPLOAD_Files)} />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="inner-view__title">
                {getTranslations(t, Translates.Choose_File)}
              </h4>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <p className="mb-6">
                  {getTranslations(
                    t,
                    Translates.Upload_your_excel_zip_or_text_file_in_the_box_provided_below
                  )}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h6>{getTranslations(t, Translates.Upload_File)}</h6>
            <div>
              <div
                {...getRootProps({ className: "dropzone" })}
                className="upload-file mt-1"
              >
                <img
                  src={csvIcon}
                  className="upload-file__icon mt-5"
                  style={{ cursor: "pointer" }}
                  alt="imag"
                />
                <p>
                  <label htmlFor="photo">
                    {getTranslations(
                      t,
                      Translates.Drop_your_Excel_or_Delimited_file_here_or
                    )}
                    <Button
                      component="span"
                      variant="text"
                      className="primary-btn f-600 ml-1"
                      disabled={saving}
                    >
                      {getTranslations(t, Translates.browse)}
                    </Button>
                  </label>
                </p>
              </div>
            </div>
          </Grid>

          {saving && progress < 100 ? (
            <Grid container spacing={2} sm={6}>
              <Grid item xs={8}>
                <div className={`custom-progressbar mt-1`}>
                  <LinearProgress
                    color="success"
                    value={progress}
                    className={`custom-progressbar__bar}`}
                    variant="determinate"
                  />
                  <label className="mt-1">
                    {getTranslations(t, Translates.Processing)}
                  </label>
                </div>
              </Grid>
              <Grid item xs={4}>
                <span className="font-15">{progress}%</span>
              </Grid>
            </Grid>
          ) : null}

          {saving && progress >= 100 ? (
            <Grid item xs={4} className="mt-4">
              <span className="font-15">
                {getTranslations(
                  t,
                  Translates.File_uploaded_Please_wait_while_we_prepare_it_for_processing
                )}
              </span>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <Grid container className="justify-content-start mt-4">
              <Grid item xs={12}>
                {alertError?.title !== "" ? <>{showError()}</> : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="inner-view__footer mt-4 mb-0">
              <Button
                variant="text"
                className="primary-btn mr-5 cancel-btn"
                onClick={handleCancelClick}
                data-testid="cancel"
                disabled={progress >= 100 && saving}
              >
                {getTranslations(t, Translates.Cancel)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default UploadFilesData
