import { useFormContext } from "react-hook-form"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { createEditFieldNames } from "../../Utils/Constants/CreateEditOutputBundleConstant"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import CheckIcon from "@mui/icons-material/Check"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import FormHeading from "src/components/FormHeading"

const FilesToIncludes = () => {
  const { t } = useTranslation<any>()
  const { watch, setValue } = useFormContext()
  const {
    config: { Outputs },
    reportData: { reportsRedux }
  } = ConfigDataHook()

  const checkboxOnChange = (e, i, i1) => {
    let fieldName = createEditFieldNames.Reports.name
    if (i === ConfigTabNames.Outputs) {
      fieldName = createEditFieldNames.Outputs.name
    }
    if (e.target.checked) {
      setValue(fieldName, [...watch(fieldName), ...[i1.Name]])
    } else {
      setValue(fieldName, [...watch(fieldName).filter(i => i !== i1.Name)])
    }
  }
  return (
    <>
      <FormHeading
        heading={getTranslations(t, Translates.Files_To_Include)}
        isDivider={true}
      />
      <Grid item xs={12} sm={12}>
        <Grid container spacing={2}>
          {[ConfigTabNames.Reports, ConfigTabNames.Outputs].map(i => {
            const records =
              (i === ConfigTabNames.Reports ? reportsRedux : Outputs) || []
            const recordsNamesSelects =
              i === ConfigTabNames.Reports
                ? watch(createEditFieldNames.Reports.name)
                : watch(createEditFieldNames.Outputs.name)
            return (
              <Grid item xs={12} sm={3} key={i}>
                <div className="files-include-block">
                  <FormHeading heading={`${getTranslations(t, i)}`} />
                  <div className="files-include-block__cont">
                    {records.map(i1 => (
                      <div key={i1.Name} className="files-include-block__row">
                        <FormControlLabel
                          title={`${i1.Name}`}
                          control={
                            <Checkbox
                              data-testid="files-to-include-checkbox"
                              className={`custom-check light`}
                              disableRipple
                              checkedIcon={<CheckIcon />}
                              onChange={e => checkboxOnChange(e, i, i1)}
                              checked={recordsNamesSelects.includes(i1.Name)}
                            />
                          }
                          label={`${i1.Name}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default FilesToIncludes
