import { useFormContext } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Button, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { onCancelClick as onCancelClickHelper } from "src/configurationPortal/pages/FunctionHelper"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
const FormSubmitCancelButtons = ({
  handleSaveClick,
  onCancelClick,
  handleTestClick,
  handleNewSaveClick,
  saveEnableCheck,
  redirectUrl,
  isSaveBtnDisabled
}: {
  handleSaveClick: any
  onCancelClick?: Function
  handleTestClick?: any
  handleNewSaveClick?: any
  saveEnableCheck?: Function
  redirectUrl?: string
  isSaveBtnDisabled?: boolean
}) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const configTypeFromStore = useSelector(
    (state: RootState) =>state.config?.configType
  )

  const { id }: { id: string; tab: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const {
    handleSubmit,
    formState: { isValid }
  } = useFormContext()
  return (
    <Grid item xs={12}>
      <div className="inner-view__footer">
        <Button
          data-testid="cancelBtn"
          variant="text"
          className="primary-btn cancel-btn mr-5"
          onClick={() => {
            if (onCancelClick) {
              onCancelClick()
            } else {
              onCancelClickHelper(history, redirectUrl ?? "")
            }
          }}
        >
          {getTranslations(t, Translates.Cancel)}
        </Button>
        {handleTestClick ? (
          <Button
            data-testid="testBtn"
            variant="contained"
            className="primary-btn mr-2"
            disabled={!isValid || configTypeFromStore === "view"}
            onClick={handleTestClick}
          >
            {getTranslations(t, Translates.Test)}
          </Button>
        ) : null}
        {handleNewSaveClick && storeId === -1 ? (
          <Button
            className="mr-5 primary-btn  cancel-btn"
            variant="text"
            data-testid="saveNewBtn"
            disabled={!isValid || configTypeFromStore === "view"}
            onClick={handleSubmit(handleNewSaveClick)}
          >
            {getTranslations(t, Translates.Save_New)}
          </Button>
        ) : null}
        {saveEnableCheck ? (
          <Button
            data-testid="saveBtn"
            variant="contained"
            className="secondary-btn"
            disabled={saveEnableCheck() || configTypeFromStore === "view"}
            onClick={handleSubmit(handleSaveClick)}
          >
            {getTranslations(t, Translates.Save_SQL)}
          </Button>
        ) : (
          <Button
            data-testid="SaveBtn"
            variant="contained"
            className="primary-btn"
            disabled={
              !isValid || isSaveBtnDisabled || configTypeFromStore === "view"
            }
            onClick={handleSubmit(handleSaveClick)}
          >
            {storeId === -1
              ? getTranslations(t, Translates.Save)
              : getTranslations(t, Translates.Update)}
          </Button>
        )}
      </div>
    </Grid>
  )
}

export default FormSubmitCancelButtons
