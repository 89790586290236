import { getUploadStatus } from "src/redux/slices/uploadFileSlice"

const interval: number = 60000 // 1minute
export const intervalVarNames = {
  UPLOADSTATUS: "uploadStatus"
}
const pageWiseRunStatus = {
  uploadStatus: false
}
export class IntervalsList {
  uploadStatus: any = null
}
let intervalIdVars = new IntervalsList()
const isUserLoggedIn = () => {
  return localStorage.getItem("accessToken")
}
export const setRunPollStatus = (status: boolean, page: string) => {
  if (page == "uploadStatus") pageWiseRunStatus.uploadStatus = status
}
let currentCustomerId: number | string
let dispatchFunc: any
export const uploadFilePoll = (dispatch, customerId) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    //istanbul ignore next
    currentCustomerId = customerId || currentCustomerId
    if (dispatch) setRunPollStatus(true, "uploadStatus")
    if (!dispatch) {
      dispatchFunc(getUploadStatus({ customerId: currentCustomerId }))
    }
    if (pageWiseRunStatus.uploadStatus && isUserLoggedIn()) {
      console.log("---------------setting timeout again-------------")
      intervalIdVars.uploadStatus = setTimeout(() => {
        uploadFilePoll(null, customerId)
      }, interval)
    }
  } catch (error) {
    //istanbul ignore next
    console.error("error in polling api")
  }
}

export const clearPoll = async (pollList: string[], page: string) => {
  setRunPollStatus(false, page)

  pollList.forEach((intervalIdVar: string) => {
    if (
      intervalIdVars &&
      intervalIdVars.hasOwnProperty(intervalIdVar) &&
      intervalIdVars[intervalIdVar]
    ) {
      clearTimeout(intervalIdVars[intervalIdVar])
    }
  })
}
