import { lazy, Suspense } from "react"
import { Switch, Route } from "react-router-dom"
import AuthenticatedGuard from "src/guards/AuthenticatedGuard"
import { PATH } from "src/constants/paths"
import Loading from "src/components/Loading/Loading"
import LocationBulkContainer from "src/pages/Location/LocationBulkContainer"
import ScheduleBulkContainer from "src/pages/Schedule/ScheduleBulkContainer"
import ConfigurationPortal from "src/configurationPortal/pages/ConfigurationPortal"
import UploadFiles from "src/pages/UploadFiles/UploadFiles"
import UploadFilesData from "src/pages/UploadFiles/UploadFilesData"
import ScheduleEventDetails from "src/pages/Schedule/ScheduleEventDetails"
import EditUploadFile from "src/pages/UploadFiles/EditUploadFile"
import CreateEditUploadFileData from "src/pages/UploadFiles/CreateEditUploadFileData"
import LiveEvents from "src/pages/LiveEvents/LiveEvents"
import LocationAttributes from "src/pages/Settings/LocationAttributes"
import CreateEditFtpConnection from "src/pages/Configuration/CreateEditFtpConnection"
import { overAllRoutes } from "./Constant"
import Login from "src/pages/Login/Login"
import UpdateConfiguration from "src/pages/Schedule/UpdateConfiguration"
import { FEATURES, PERMISSIONS } from "src/utils/constants"
import UploadFileBulkContainer from "src/pages/UploadFiles/UploadFileBulkContainer"
import MainLayout from "src/layouts/MainLayout"
const Home = lazy(() => import("src/pages/Home/Home"))
const DeviceReservation = lazy(
  () => import("src/pages/DeviceReservation/DeviceReservation")
)
const Settings = lazy(() => import("src/pages/Settings/Settings"))
const FinalReportsAndFiles = lazy(
  () => import("src/pages/FinalReportsAndFiles/FinalReportsAndFiles")
)
const ScheduleBanner = lazy(() => import("src/pages/Schedule/ScheduleBanner"))
const CreateEditSchedule = lazy(
  () => import("src/pages/Schedule/CreateEditSchedule")
)
const Locations = lazy(() => import("src/pages/Location/Locations"))
const LocationBanner = lazy(() => import("src/pages/Location/LocationBanner"))
const CreateLocation = lazy(() => import("src/pages/Location/CreateLocation"))
const UserBulkContainer = lazy(
  () => import("src/pages/Users/CreateUserBulk/UserBulkContainer")
)
const CreateEditUser = lazy(
  () => import("src/pages/Users/CreateEditUser/CreateEditUser")
)
const LocationTypeSelect = lazy(
  () => import("src/pages/Location/LocationTypeSelect")
)
const SettingsRegionalGroups = lazy(
  () => import("src/pages/Location/SettingsRegionalGroup")
)
const CreateReservation = lazy(
  () => import("src/pages/DeviceReservation/CreateEditReservation")
)
const SelectCustomer = lazy(
  () => import("src/pages/SelectCustomer/SelectCustomer")
)
const RegionalGroups = lazy(() => import("src/pages/Settings/RegionalGroups"))
const RegionalGroupsBanner = lazy(
  () => import("src/pages/Settings/RegionalGroupsBanner")
)
const Schedule = lazy(() => import("src/pages/Schedule/Schedule"))
const ScheduleDetails = lazy(() => import("src/pages/Schedule/ScheduleDetails"))
const CompanyDetails = lazy(() => import("src/pages/Settings/CompanyDetails"))
const UserList = lazy(() => import("src/pages/Users/UserList/UserList"))
const ScheduleTypeSelect = lazy(
  () => import("src/pages/Schedule/ScheduleTypeSelect")
)
const UserTypeSelect = lazy(
  () => import("src/pages/Users/CreateEditUser/UserTypeSelect")
)
const Configuration = lazy(
  () => import("src/pages/Configuration/Configuration")
)
const ConfigurationDetails = lazy(
  () => import("src/pages/Configuration/ConfigurationDetails")
)
const CreateEditSql = lazy(
  () => import("src/configurationPortal/pages/CreateAndEditSql")
)
const DeviceReservationTypeSelect = lazy(
  () => import("src/pages/DeviceReservation/DeviceReservationTypeSelect")
)
const DeviceReservationBulkContainer = lazy(
  () => import("src/pages/DeviceReservation/DeviceReservationBulkContainer")
)

const PageNotFound = lazy(() => import("../pages/PageNotFound/PageNotFound"))

// Move the component definition outside of the render method
const renderConfigPortalComponent = (
  renderComponent,
  isInsideCP = true,
  isInsideML = false
) => {
  let locComp: any = renderComponent

  if (isInsideCP) {
    locComp = <ConfigurationPortal>{renderComponent}</ConfigurationPortal>
  } else if (isInsideML) {
    locComp = <MainLayout>{renderComponent}</MainLayout>
  }
  return <Suspense fallback={<Loading />}>{locComp}</Suspense>
}

export default function RouteList(_props) {
  return (
    <Switch>
      <Route
        path={PATH.LOGIN}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        )}
      />

      {overAllRoutes.map((i: any) => {
        return (
          <AuthenticatedGuard
            exact
            key={i.path}
            path={i.path}
            {...(i.permissions && {
              permissions: i.permissions
            })}
            {...(i.feature && {
              feature: i.feature
            })}
            component={() =>
              renderConfigPortalComponent(
                i.renderComponent,
                i?.isInsideCP ?? false,
                i?.isInsideML ?? false
              )
            }
          />
        )
      })}

      <AuthenticatedGuard
        exact
        path={PATH.HOME}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.DEVICE_RESERVATION}
        feature={FEATURES.RESERVATIONS}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <DeviceReservation />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CREATE_RESERVATION}
        feature={FEATURES.RESERVATIONS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateReservation />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.LIVE_EVENTS}
        component={() => (
          <Suspense fallback={<Loading />}>
            <LiveEvents />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SETTINGS}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Settings />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SCHEDULE_BANNER}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ScheduleBanner />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CREATE_SCHEDULE}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditSchedule />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.EDIT_SCHEDULE}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.UPDATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditSchedule />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SCHEDULE_EVENT_DETAILS}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ScheduleEventDetails />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.LOCATION_BANNER}
        feature={FEATURES.LOCATIONS}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <LocationBanner />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.LOCATIONS}
        feature={FEATURES.LOCATIONS}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Locations />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CREATE_LOCATION}
        feature={FEATURES.LOCATIONS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateLocation />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.EDIT_LOCATION}
        feature={FEATURES.LOCATIONS}
        permissions={PERMISSIONS.UPDATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateLocation />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.LOCATION_TYPE_SELECT}
        feature={FEATURES.LOCATIONS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <LocationTypeSelect />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.DEVICE_RESERVATION_TYPE_SELECT}
        permissions="create"
        component={() => (
          <Suspense fallback={<Loading />}>
            <DeviceReservationTypeSelect />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.DEVICE_RESERVATION_BULK_UPLOAD}
        // permissions="create"
        component={() => (
          <Suspense fallback={<Loading />}>
            <DeviceReservationBulkContainer />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.UPLOADFILE_BULK_UPLOAD}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UploadFileBulkContainer />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.REGIONAL_GROUPS}
        component={() => (
          <Suspense fallback={<Loading />}>
            <RegionalGroups />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SETTINGS_REGIONAL_GROUPs}
        component={() => (
          <Suspense fallback={<Loading />}>
            <SettingsRegionalGroups />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SELECT_CUSTOMER}
        component={() => (
          <Suspense fallback={<Loading />}>
            <SelectCustomer />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.REGIONAL_GROUPS_BANNER}
        component={() => (
          <Suspense fallback={<Loading />}>
            <RegionalGroupsBanner />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.COMPANY_DETAILS}
        feature={FEATURES.COMPANY_SETTINGS}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CompanyDetails />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CREATE_USER}
        feature={FEATURES.USERS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditUser />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.EDIT_USER}
        feature={FEATURES.USERS}
        permissions={PERMISSIONS.UPDATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditUser />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.USERS}
        feature={FEATURES.USERS}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UserList />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SCHEDULE}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Schedule />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SCHEDULE_DETAILS}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ScheduleDetails />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.SCHEDULE_CREATE_TYPE}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ScheduleTypeSelect />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.CREATE_BULK_LOCATION}
        feature={FEATURES.LOCATIONS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <LocationBulkContainer />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.USER_TYPE_SELECT}
        feature={FEATURES.USERS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UserTypeSelect />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CREATE_USER_BULK}
        feature={FEATURES.USERS}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UserBulkContainer />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.CREATE_BULK_SCHEDULE}
        feature={FEATURES.SCHEDULE}
        permissions={PERMISSIONS.CREATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ScheduleBulkContainer />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        path={PATH.CONFIGURATION_PORTAL}
        feature={FEATURES.CONFIGURATION}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ConfigurationPortal />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.CONFIGURATION}
        feature={FEATURES.CONFIGURATION}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Configuration />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CONFIGURATION_DETAILS}
        feature={FEATURES.CONFIGURATION}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <ConfigurationDetails />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        path={PATH.CREATE_EDIT_SQL}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditSql />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.UPLOADFILES_DATA}
        feature={FEATURES.UPLOADFILES}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UploadFilesData />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        exact
        path={PATH.UPLOADFILES}
        feature={FEATURES.UPLOADFILES}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UploadFiles />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.EDIT_UPLOAD_FILES}
        feature={FEATURES.UPLOADFILES}
        permissions={PERMISSIONS.UPDATE}
        component={() => (
          <Suspense fallback={<Loading />}>
            <EditUploadFile />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.CREATE_EDIT_UPLOAD_FILES_DATA}
        feature={FEATURES.UPLOADFILES}
        permissions={PERMISSIONS.VIEW}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditUploadFileData />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        path={PATH.FINAL_REPORTS_AND_FILES}
        component={() => (
          <Suspense fallback={<Loading />}>
            <FinalReportsAndFiles />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        path={PATH.LOCATION_ATTRIBUTES}
        component={() => (
          <Suspense fallback={<Loading />}>
            <LocationAttributes />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        path={PATH.CREATE_EDIT_FTP_CONNECTION}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditFtpConnection />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        path={PATH.EDIT_FTP_CONNECTION}
        component={() => (
          <Suspense fallback={<Loading />}>
            <CreateEditFtpConnection />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        path={PATH.FTP_CONNECTION}
        component={() => (
          <Suspense fallback={<Loading />}>
            <Configuration />
          </Suspense>
        )}
      />

      <AuthenticatedGuard
        path={PATH.UPDATE_CONFIGURATION}
        component={() => (
          <Suspense fallback={<Loading />}>
            <UpdateConfiguration />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        exact
        path={PATH.PAGE_NOT_FOUND}
        component={() => (
          <Suspense fallback={<Loading />}>
            <PageNotFound />
          </Suspense>
        )}
      />
      <AuthenticatedGuard
        isRedirect={true}
        redirectTo={PATH.PAGE_NOT_FOUND}
        component={() => <></>}
      />
    </Switch>
  )
}
