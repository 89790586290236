import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ConfigurationState {
  status: string
  configurationByCustomer: any
  configurationFilters: any
  configurationPrevFilters: any
  configurationSort: any
  configurationColumn: any
}
const initialState: ConfigurationState = {
  status: "",
  configurationByCustomer: [],
  configurationFilters: { status: ["Active", "Not Installed"] },
  configurationPrevFilters: {
    status: ["Active", "In Active", "Not Installed"]
  },
  configurationSort: "",
  configurationColumn: ""
}

export const configurationSlice = createSlice({
  name: "configurationList",
  initialState,
  reducers: {
    setConfigurationFilters: (state, _action: PayloadAction<any>) => {
      state.configurationFilters = {
        ...state.configurationFilters,
        ..._action.payload
      }
    },
    setConfigurationPrevFilters: (state, _action: PayloadAction<any>) => {
      state.configurationPrevFilters = {
        ...state.configurationPrevFilters,
        ..._action.payload
      }
    },
    setConfigurationSort: (state, _action: PayloadAction<any>) => {
      state.configurationSort = _action.payload
    },
    setConfigurationColumn: (state, _action: PayloadAction<any>) => {
      state.configurationColumn = _action.payload
    }
  }
})
export const {
  setConfigurationFilters,
  setConfigurationPrevFilters,
  setConfigurationSort,
  setConfigurationColumn
} = configurationSlice.actions
export default configurationSlice.reducer
