import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid
} from "@mui/material"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import LinearProgress from "@mui/material/LinearProgress"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { PATH } from "src/constants/paths"
import React, { useEffect, useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import shortid from "shortid"
import { Translates } from "src/i18n/i18n"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import { getTranslations } from "src/utils/helper"
import {
  setContinueStatus,
  setNewFileData
} from "src/redux/slices/uploadFileSlice"
import { UploadFileService } from "src/service/uploadfile.service"
import { PAGES } from "../Location/UploadFileHelper"

const UploadFileBulkValidation = ({ setPage }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const uploadFileService = new UploadFileService()
  const [loading, setLoading] = React.useState(false)
  const [progress, setProgress] = useState(0)
  const [uploadFileBulkResponseData, setUploadFileBulkResponseData] = useState(
    {} as any
  )
  const [disable, setDisable] = useState(true)
  const dispatch = useAppThunkDispatch()

  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )

  const mappingPayloadData: any = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFileMappingPayloadData
  )

  useEffect(() => {
    dispatch(setNewFileData(mappingPayloadData?.fileData))
  }, [mappingPayloadData])

  useEffect(() => {
    uploadFilePost(false, null)
  }, [])

  let config = {
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      setProgress(percentCompleted)
    }
  }
  const uploadFilePost = async (
    isConfirmed: boolean,
    confirmationAction: any
  ) => {
    const _payload = {
      idCustomer: mappingPayloadData?.idCustomer,
      isConfirmed: isConfirmed,
      IdConfig: mappingPayloadData?.idConfig,
      IdFileType: mappingPayloadData?.idFileType,
      confirmationAction: confirmationAction,
      columns: mappingPayloadData?.columns,
      fileData: mappingPayloadData?.fileData
    }
    if (!isConfirmed) {
      setLoading(true)
    }
    try {
      const dt = await uploadFileService?.AddBulkUploadFile(
        _payload,
        isConfirmed ? {} : config
      )
      setUploadFileBulkResponseData(dt.data)
      setDisable(false)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.UPLOADFILES)
      }, 3000)
    }
  }
  const handlePreviousClick = () => {
    setPage(PAGES.page2)
  }
  const handleCancelClick = () => {
    history.push(PATH.UPLOADFILES)
  }
  const saveDisable = () => {
    if (disable || !uploadFileBulkResponseData) {
      return true
    } else if (uploadFileBulkResponseData?.duplicateColumns?.length > 0) {
      return true
    } else if (
      uploadFileBulkResponseData &&
      uploadFileBulkResponseData?.errorRecordsCount > 0
    ) {
      return true
    } else {
      return progress < 100
    }
  }
  const handlePreview = () => {
    let continueStatus: string | null = null
    dispatch(setContinueStatus(continueStatus))
    setPage(PAGES.page4)
  }
  return (
    <>
      <Grid container>
        <MiniHeader
          headerTitle={getTranslations(t, Translates.Upload_Files)}
          multiCustomer={handleNoCustomerId}
        />
        <Grid container className="inner-view">
          <Grid item xs={12} className="main-container">
            <Grid container>
              <Grid item xs={12}>
                <h4 className="inner-view__title">
                  {getTranslations(t, Translates.Reservation_Validation)}
                </h4>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <p className="font-16 f-700 mb-0">
                {getTranslations(t, Translates.Reservation_Validation)}{" "}
                {progress == 100
                  ? getTranslations(
                      t,
                      Translates.Reservation_bulk_valiadtion_completed
                    )
                  : getTranslations(
                      t,
                      Translates.Reservation_bulk_valiadtion_In_Progress
                    )}
              </p>
              <Grid container spacing={2} className="justify-content-end mb-5">
                <span className="justify-content-end font-15">
                  {progress}%{" "}
                  {getTranslations(
                    t,
                    Translates.Reservation_bulk_valiadtion_completed
                  )}
                </span>
                <Grid item sm={12}>
                  <div className={`custom-progressbar`}>
                    <LinearProgress
                      color="success"
                      value={progress}
                      className={`custom-progressbar__bar}`}
                      variant="determinate"
                    />
                  </div>
                </Grid>
              </Grid>
              {!loading ? (
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <Divider className="dashed mb-3" />
                    <p className="font-18 f-700">
                      {getTranslations(
                        t,
                        Translates.Reservation_Validation_Outcome
                      )}
                    </p>
                    <Divider className="mb-3" />
                    <p className="font-15 f-700">
                      {getTranslations(
                        t,
                        Translates.Reservation_File_Statistics
                      )}
                    </p>
                    {uploadFileBulkResponseData?.validRecordsCount > 0 ? (
                      <Accordion className="custom-accordion dark validates success pointer-events-none">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h5>{`${
                            uploadFileBulkResponseData?.validRecordsCount
                          } ${
                            uploadFileBulkResponseData?.validRecordsCount > 1
                              ? getTranslations(
                                  t,
                                  Translates.Reservation_Records_will_be_inserted
                                )
                              : getTranslations(
                                  t,
                                  Translates.Reservation_Record_will_be_inserted
                                )
                          }
                          `}</h5>
                        </AccordionSummary>
                      </Accordion>
                    ) : (
                      <Accordion className="custom-accordion dark validates success pointer-events-none">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          {" "}
                          <h5>
                            {" "}
                            {getTranslations(
                              t,
                              Translates.No_Records_will_be_inserted
                            )}{" "}
                          </h5>
                        </AccordionSummary>
                      </Accordion>
                    )}
                    <Grid item xs={12} sm={12} className="mt-4">
                      {uploadFileBulkResponseData?.duplicateColumns?.length ? (
                        <Accordion className="custom-accordion dark validates info">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${
                              uploadFileBulkResponseData?.duplicateColumns
                                ?.length
                            } ${
                              uploadFileBulkResponseData?.duplicateColumns
                                ?.length > 1
                                ? `${getTranslations(
                                    t,
                                    Translates.Records_Dublicate_In_File
                                  )}`
                                : `${getTranslations(
                                    t,
                                    Translates.Record_Dublicate_In_File
                                  )}`
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container xs={12}>
                              <Grid item xs={12} sm={12}>
                                <ul>
                                  {uploadFileBulkResponseData?.duplicateColumns?.map(
                                    w => {
                                      return (
                                        <li key={shortid.generate()}>{w}</li>
                                      )
                                    }
                                  )}
                                </ul>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      {uploadFileBulkResponseData?.errorColumns?.length ? (
                        <Accordion className="custom-accordion dark validates error">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${
                              uploadFileBulkResponseData?.errorRecordsCount
                            } ${
                              uploadFileBulkResponseData?.errorRecordsCount > 1
                                ? getTranslations(
                                    t,
                                    Translates.Records_have_errors
                                  )
                                : getTranslations(
                                    t,
                                    Translates.Record_has_error
                                  )
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {uploadFileBulkResponseData?.errorColumns?.map(
                                w => {
                                  return <li key={shortid.generate()}>{w}</li>
                                }
                              )}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      {uploadFileBulkResponseData?.warningColumns?.length ||
                      uploadFileBulkResponseData?.regionalGroupLevelWarnings
                        ?.length ? (
                        <Accordion className="custom-accordion dark validates warning">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${
                              uploadFileBulkResponseData?.warningRecordsCount
                            } ${
                              uploadFileBulkResponseData?.warningRecordsCount >
                              1
                                ? getTranslations(
                                    t,
                                    Translates.Records_have_warnings
                                  )
                                : getTranslations(
                                    t,
                                    Translates.Record_has_warning
                                  )
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {uploadFileBulkResponseData?.warningColumns?.map(
                                w => {
                                  return <li key={shortid.generate()}>{w}</li>
                                }
                              )}
                              {uploadFileBulkResponseData?.regionalGroupLevelWarnings?.map(
                                w => {
                                  return <li key={shortid.generate()}>{w}</li>
                                }
                              )}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <BulkUploadButtons
                handleCancelClick={handleCancelClick}
                nextBLabel={getTranslations(t, Translates.Preview)}
                handleNextClick={handlePreview}
                saveDisable={saveDisable}
                previousBLabel={getTranslations(t, Translates.Previous)}
                handlePreviousClick={handlePreviousClick}
                cancelBLabel={getTranslations(t, Translates.Cancel)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default UploadFileBulkValidation
