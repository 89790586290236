export const SubTotalFunctionTypeList = [
  "SUM",
  "MIN",
  "MAX",
  "MEAN",
  "MEDIAN",
  "DISTINCTCOUNT",
  "COUNT"
]
export const PlacementTypeList = [
 "AFTER",
 "BEFORE"
]
export const Subtotal_Fields = {
  DataGroup: "DataGroup",
  Label: "Label",
  Values: "Values",
  Placement: "Placement"
}
export const OUTPUT_FILE_ACTION_TYPE = {
  EDIT: "EDIT"
}

export const OUTPUT_FILE_COMPONENT_TYPE = {
  GRAND_TOTAL: "GRAND_TOTAL",
  SUB_TOTAL: "SUB_TOTAL"
}
export const REPORT_FILE_COMPONENT_TYPE = {
  GRAND_TOTAL: "GRAND_TOTAL",
  SUB_TOTAL: "SUB_TOTAL"
}
export const OUTPUT_FILE_TYPE = {
  SUB_TOTAL: "createEditOutputSubTotals"
}
export const REPORT_FILE_TYPE = {
  SUB_TOTAL: "createEditReportSubTotals"
}
export const OUTPUT_FILE_TITLES = {
  EditSubTotal: "Edit Sub Total",
  NewSubTotal: "New Sub Total",
  EditGrandTotal: "Edit Grand Total",
  NewGrandTotal: "New Grand Total",
  EditOutputFile: "Edit Output",
  NewOutputFile: "New Output"
}
export const REPORT_FILE_TITLES = {
  EditSubTotal: "Edit Sub Total",
  NewSubTotal: "New Sub Total",
  EditGrandTotal: "Edit Grand Total",
  NewGrandTotal: "New Grand Total"
}

export const REPORT_HEADER={
  GRAND_TOTALS:"GRAND TOTALS",
  SUB_TOTALS:"SUB TOTALS"
}

export const REPORT_ADD_BUTTON={
  New_Grand_Total:"New Grand Total",
  New_Sub_Total:"New Sub Total"
}