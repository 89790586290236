import { Alert, AlertTitle, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import { getTranslations } from "src/utils/helper"
import { Loader } from "src/utils/reusables/reusable"
import { UploadFileService } from "src/service/uploadfile.service"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import {
  setContinueStatus,
  setDepartmentData,
  setVarianceData
} from "src/redux/slices/uploadFileSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { PATH } from "src/constants/paths"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import { useHistory } from "react-router-dom"
import TagIcon from "../../assets/images/TagIcon.svg"
import {
  UPLOADFILE_FIELD_MAP,
  UPLOADFILE_MAP
} from "../Location/UploadFileHelper"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WarningIcon from "@mui/icons-material/Warning"

const UpdateDepartFile = ({ setPage, Row }) => {
  // @ts-ignore
  const { t } = useTranslation<any>()
  const [file, setFile] = useState("")
  const [fileData, setFileData] = React.useState([] as any)
  const [loading, setLoading] = useState(false)
  const uploadService = new UploadFileService()
  const [deptFileType, setDeptFileType] = useState(false)
  const [lastUpdatedDate, setLastUpdatedDate] = useState<null | string>("")
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertType, setAlertType] = useState(false)
  const [alertContent, setAlertContent] = useState("")

  const history = useHistory()

  const mappingPayloadData: any = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFileMappingPayloadData
  )
  const continuesStatus: any = useSelector(
    (state: RootState) => state.uploadFiles?.continuesStatus
  )

  const Data = Row && Object.keys(Row).length ? JSON.parse(Row) : {}

  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )

  const newFileData: any = useSelector(
    (state: RootState) => state.uploadFiles?.newFileData
  )
  const uploadFileService = new UploadFileService()

  const newData =
    newFileData && Object.keys(newFileData).length
      ? JSON.parse(newFileData)
      : []

  const dispatch = useDispatch()
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setLoading(true)
      init()
    }
    return () => {
      isMounted = false
    }
  }, [])
  const init = () => {
    let fileName = Data.name
    let data = Data
    let selectedFileType = Data.selectedFileType

    if (selectedFileType === "Department") {
      setDeptFileType(true)
    }
    setFile(fileName)
    getFileDetails(data?.idConfig, data?.idFileType)
  }
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setLoading(true)
      init()
    }
    return () => {
      isMounted = false
    }
  }, [customerId])
  const getFileDetails = async (fileIdVal, fileType) => {
    try {
      const res = await uploadService?.getUploadSingleFileDetails(
        fileIdVal,
        fileType
      )
      if (res && res.status === 200) {
        setLoading(false)
        setLastUpdatedDate(res.data.lastUpdatedDate)
        if (
          res.data.uploadFileData.VarianceControlFile &&
          res.data.uploadFileData.VarianceControlFile.length > 0
        ) {
          setDeptFileType(false)
          setFileData(res.data.uploadFileData.VarianceControlFile)
          dispatch(setVarianceData(res.data.uploadFileData.VarianceControlFile))
          setLoading(false)
        } else if (
          res.data.uploadFileData.DepartmentFile &&
          res.data.uploadFileData.DepartmentFile.length > 0
        ) {
          setDeptFileType(true)
          setFileData(res.data.uploadFileData.DepartmentFile)
          dispatch(setDepartmentData(res.data.uploadFileData.DepartmentFile))
          setLoading(false)
        }
      } else if (res && res.status === 204) {
        setFileData([])
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }
  const display = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }

  const Values = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: false,
    setWidth: 10,
    Cell: props => {
      return display(props)
    }
  }
  const oldColumnHeaders = [
    {
      Header: getTranslations(t, Translates.DEPARTMENT),
      accessor: UPLOADFILE_MAP.department,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Name),
      accessor: UPLOADFILE_MAP.departmentName,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Sort),
      accessor: UPLOADFILE_MAP.departmentSort,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Parent_Name),
      accessor: UPLOADFILE_MAP.departmentParentName,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Parent_Sort),
      accessor: UPLOADFILE_MAP.departmentParentSort,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Parent),
      accessor: UPLOADFILE_MAP.departmentParent,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.VARIANCE_GROUP),
      accessor: UPLOADFILE_MAP.varianceGroup,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.VARIANCE_GROUP_NAME),
      accessor: UPLOADFILE_MAP.varianceGroupName,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.SORT_ORDER),
      accessor: UPLOADFILE_MAP.sortOrder,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.QTY_TOLERANCE),
      accessor: UPLOADFILE_MAP.qtyTolerance,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.FULL_STORE_QTY_TOLERANCE),
      accessor: UPLOADFILE_MAP.fullStoreQtyTolerance,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.EXT_TOLERANCE),
      accessor: UPLOADFILE_MAP.extTolerance,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.FULL_STORE_EXT_TOLERANCE),
      accessor: UPLOADFILE_MAP.fullStoreExtTolerance,
      ...Values
    }
  ]
  const newColumnHeaders = [
    {
      Header: getTranslations(t, Translates.DEPARTMENT),
      accessor: UPLOADFILE_FIELD_MAP.departmentGroup,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Name),
      accessor: UPLOADFILE_FIELD_MAP.departmentName,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Sort),
      accessor: UPLOADFILE_FIELD_MAP.departmentSort,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Parent_Name),
      accessor: UPLOADFILE_FIELD_MAP.departmentParentName,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Parent_Sort),
      accessor: UPLOADFILE_FIELD_MAP.departmentParentSort,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.Department_Parent),
      accessor: UPLOADFILE_FIELD_MAP.departmentParent,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.VARIANCE_GROUP),
      accessor: UPLOADFILE_FIELD_MAP.varianceGroup,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.VARIANCE_GROUP_NAME),
      accessor: UPLOADFILE_FIELD_MAP.varianceGroupName,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.SORT_ORDER),
      accessor: UPLOADFILE_FIELD_MAP.sortOrder,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.QTY_TOLERANCE),
      accessor: UPLOADFILE_FIELD_MAP.qtyTolerance,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.FULL_STORE_QTY_TOLERANCE),
      accessor: UPLOADFILE_FIELD_MAP.fullStoreQtyTolerance,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.EXT_TOLERANCE),
      accessor: UPLOADFILE_FIELD_MAP.extTolerance,
      ...Values
    },
    {
      Header: getTranslations(t, Translates.FULL_STORE_EXT_TOLERANCE),
      accessor: UPLOADFILE_FIELD_MAP.fullStoreExtTolerance,
      ...Values
    }
  ]
  const getOldColumnHeaders = () => {
    if (!deptFileType) {
      return oldColumnHeaders.splice(6, 7)
    } else {
      return oldColumnHeaders.splice(0, 6)
    }
  }
  const getNewColumnHeaders = () => {
    if (!deptFileType) {
      return newColumnHeaders.splice(6, 7)
    } else {
      return newColumnHeaders.splice(0, 6)
    }
  }
  const newColumnsData: Array<{
    Header: any
    accessor: string
    headerClassName: string
    disableFilters: boolean
    disableSortBy: boolean
    setWidth: number
    Cell: (props: any) => JSX.Element
  }> = [
    ...(getNewColumnHeaders()?.map(id => {
      return {
        Header: id.Header,
        accessor: id.accessor,
        headerClassName: id.headerClassName,
        disableFilters: id.disableFilters,
        disableSortBy: id.disableSortBy,
        setWidth: id.setWidth,
        Cell: id.Cell
      }
    }) ?? [])
  ].flat(Infinity)
  const columnsData: Array<{
    Header: any
    accessor: string
    headerClassName: string
    disableFilters: boolean
    disableSortBy: boolean
    setWidth: number
    Cell: (props: any) => JSX.Element
  }> = [
    ...(getOldColumnHeaders()?.map(id => {
      return {
        Header: id.Header,
        accessor: id.accessor,
        headerClassName: id.headerClassName,
        disableFilters: id.disableFilters,
        disableSortBy: id.disableSortBy,
        setWidth: id.setWidth,
        Cell: id.Cell
      }
    }) ?? [])
  ].flat(Infinity)

  const displayGrid = () => {
    return (
      <Grid container className="table-responsive">
        <Grid xs={12} item>
          <GlobalTable
            columns={columnsData || []}
            data={fileData || []}
            noDataText={
              fileData && fileData?.length > 0
                ? Loader()
                : `No ${file} data available.`
            }
            initialStateProp={{
              sortBy: [
                {
                  id: columnsData?.length ? columnsData[0].Header : "",
                  desc: false
                }
              ]
            }}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }
  const displayNewGrid = () => {
    return (
      <Grid container className="table-responsive">
        <Grid xs={12} item>
          <GlobalTable
            columns={newColumnsData || []}
            data={newData || []}
            noDataText={
              newData && newData?.length > 0
                ? Loader()
                : `No ${file} data available.`
            }
            initialStateProp={{
              sortBy: [
                {
                  id: newColumnsData?.length ? newColumnsData[0].Header : "",
                  desc: false
                }
              ]
            }}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }

  const handleCancelClick = () => {
    history.push(PATH.UPLOADFILES)
  }
  let config = {}
  const uploadFilePost = async (isConfirmed: boolean) => {
    setLoading(true)
    try {
      const _payload = {
        idCustomer: mappingPayloadData.idCustomer,
        isConfirmed: isConfirmed,
        IdConfig: mappingPayloadData.idConfig,
        IdFileType: mappingPayloadData.idFileType,
        confirmationAction: continuesStatus,
        columns: mappingPayloadData.columns,
        fileData: mappingPayloadData.fileData
      }

      if (!isConfirmed) {
        setLoading(true)
      }

      const response = await uploadFileService.AddBulkUploadFile(
        _payload,
        isConfirmed ? {} : config
      )

      if (response.status === 200) {
        setAlertVisible(true)
        setAlertType(true)
        setAlertContent(
          getTranslations(t, Translates.File_uploaded_successfully)
        )
      }

      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleNextBeginImport = () => {
    uploadFilePost(true)
    setTimeout(() => {
      history.push(PATH.UPLOADFILES)
    }, 3000)
    dispatch(setContinueStatus(""))
  }
  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.UPLOADFILES)
      }, 3000)
    }
  }
  const DateDifference = () => {
    if (fileData && lastUpdatedDate) {
      const lastUpdateDate: any = new Date(lastUpdatedDate)
      const currentDate: any = new Date()
      const timeDifference = Math.abs(currentDate - lastUpdateDate)
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
      return (
        <div>
          <p>
            {daysDifference} {getTranslations(t, Translates.Days_Ago)}
          </p>
        </div>
      )
    }
    return "-"
  }
  return (
    <>
      {alertVisible ? (
        <>
          <Alert
            severity={alertType ? "success" : "error"}
            icon={alertType ? <CheckCircleIcon /> : <WarningIcon />}
            className="custom-alert top"
          >
            <AlertTitle className="custom-alert__title f-600">
              {alertType
                ? getTranslations(t, Translates.Success)
                : getTranslations(t, Translates.Error)}
            </AlertTitle>
            {alertContent}
          </Alert>
          <Grid container className="inner-view">
            <Grid item xs={12} className="main-container">
              {Loader()}
            </Grid>
          </Grid>
        </>
      ) : null}
      {!alertVisible && (
        <Grid container className="update-file-view">
          <MiniHeader
            headerTitle={getTranslations(t, Translates.UPLOAD_FILES)}
            multiCustomer={handleNoCustomerId}
          />
          <Grid container className="inner-view">
            <Grid item xs={12} className="main-container">
              <Grid container>
                <Grid item xs={12}>
                  <h4 className="inner-view__title">
                    {getTranslations(t, Translates.Update_File)}
                  </h4>
                </Grid>
              </Grid>
              {!loading ? (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={2} className="gray-bg-row">
                      <Grid item md={6}>
                        <Grid container className="gray-bg pa-3">
                          <Grid item sm={12} md={12}>
                            <Grid container>
                              <Grid item md={10}>
                                <img
                                  aria-label="tag_icon"
                                  src={TagIcon}
                                  alt="tag icon"
                                  width={18}
                                  className="mr-1"
                                />
                                <label className="font-18 text-uppercase f-700 new-count-title">
                                  {getTranslations(t, Translates.Original_File)}
                                </label>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sm={12} md={12}>
                            <Grid
                              container
                              className="tag-list blue-bg mt-2 type-2"
                            >
                              <Grid item md={12} className="tag-list__cont">
                                <div className="tag-list__col">
                                  <span className="tag-list__col-title">
                                    {getTranslations(
                                      t,
                                      Translates.Total_Records
                                    )}
                                  </span>
                                  <strong className="tag-list__col-value">
                                    {fileData?.length}
                                  </strong>
                                </div>
                                <div className="tag-list__col">
                                  <span className="tag-list__col-title">
                                    {getTranslations(
                                      t,
                                      Translates.Received_Time
                                    )}
                                    :
                                  </span>
                                  <strong className="tag-list__col-value">
                                    {DateDifference()}
                                  </strong>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            className="white-bg mt-2 sticky-table"
                          >
                            <div className="custom-table__col update-icon-table">
                              {displayGrid()}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6}>
                        <Grid container className="gray-bg pa-3">
                          <Grid item sm={12} md={12}>
                            <Grid container>
                              <Grid item md={10}>
                                <img
                                  aria-label="tag_icon"
                                  src={TagIcon}
                                  alt="tag icon"
                                  width={18}
                                  className="mr-1"
                                />
                                <label className="font-18 text-uppercase f-700 new-count-title">
                                  {getTranslations(t, Translates.New_File)}
                                </label>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sm={12} md={12}>
                            <Grid
                              container
                              className="tag-list blue-bg mt-2 type-2"
                            >
                              <Grid item md={12} className="tag-list__cont">
                                <div className="tag-list__col">
                                  <span className="tag-list__col-title">
                                    {getTranslations(
                                      t,
                                      Translates.Total_Records
                                    )}
                                  </span>
                                  <strong className="tag-list__col-value">
                                    {newData?.length}
                                  </strong>
                                </div>
                                <div className="tag-list__col">
                                  <span className="tag-list__col-title">
                                    {getTranslations(
                                      t,
                                      Translates.Received_Time
                                    )}
                                    :
                                  </span>
                                  <strong className="tag-list__col-value">
                                    {getTranslations(t, Translates.Now)}
                                  </strong>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            className="white-bg mt-2 sticky-table"
                          >
                            <div className="custom-table__col update-icon-table">
                              {displayNewGrid()}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <BulkUploadButtons
                      handleCancelClick={handleCancelClick}
                      nextBLabel={getTranslations(
                        t,
                        Translates.Next_Begin_Import
                      )}
                      handleNextClick={handleNextBeginImport}
                      cancelBLabel={getTranslations(t, Translates.Cancel)}
                    />
                  </Grid>
                </>
              ) : (
                Loader()
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default UpdateDepartFile
