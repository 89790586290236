import React, { useCallback, useEffect } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TextField, Grid, Button } from "@mui/material"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import { setUploadStatusFilters } from "src/redux/slices/uploadFileSlice"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

interface IUploadStatus {
  droppedOffAt: string
}
const UploadStatusFilter = ({ applyClick, cancelClick, showFilterPage }) => {
  const { t } = useTranslation<any>()
  const [date, setDate] = React.useState<IUploadStatus>({} as IUploadStatus)
  const uploadStatusFilter = useSelector(
    (state: RootState) => state.uploadFiles?.uploadStatusFilters
  )
  const dispatch = useAppThunkDispatch()
  const minDate = "2000-01-01"
  const maxDate = "3000-12-31"

  useEffect(() => {
    setDate({
      droppedOffAt: uploadStatusFilter.sDate || ""
    })
  }, [uploadStatusFilter])

  const onDateChange = e => {
    moment.locale("us_en")
    const val = e.target.value || ""
    let dateObj = {
      ...date,
      [e.target.name]: val
    }
    if (val && e.target?.name === "droppedOffAt") {
      dateObj = {
        droppedOffAt: e.target.value
      }
    }
    setDate(dateObj)
  }
  const applyFilterClick = () => {
    let filtersData = {
      sDate: date.droppedOffAt
    }
    applyClick(date.droppedOffAt)
    dispatch(setUploadStatusFilters(filtersData))
  }

  const applyCancelClick = () => {
    cancelClick()
  }

  const handleResetAllClick = () => {
    setDate({
      droppedOffAt: ""
    })
  }

  const handleKeyDown = useCallback(
    event => {
      event.preventDefault() // This does the trick
      if (event.keyCode === 8 || event.keyCode === 46) {
        const info = { ...date, droppedOffAt: "" }
        setDate(info)
      }
    },
    [setDate, date]
  )

  return (
    <>
      {showFilterPage ? (
        <div className="filter-panel" data-testid="filter-panel">
          <Grid container className="main-container">
            <Grid item xs={12}>
              <div className="gray-bg pa-4" data-testid="gray-bg">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h4 className="mb-2" data-testid="filter-title">
                      {getTranslations(t, Translates.Filters)}
                    </h4>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <label
                      className="f-600 text-uppercase mb-2"
                      data-testid="date-label"
                    >
                      {getTranslations(t, Translates.Select_Date)}
                    </label>
                    <div className="text-box" data-testid="text-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="text-box">
                          <DesktopDatePicker
                            label={"Uploaded At"}
                            inputFormat="dd-MMM-yyyy"
                            value={
                              date?.droppedOffAt
                                ? moment(date?.droppedOffAt || "").format(
                                    "DD-MMM-YYYY"
                                  )
                                : null
                            }
                            onChange={value =>
                              onDateChange({
                                target: {
                                  name: "droppedOffAt",
                                  value: moment(value).format("MM-DD-YYYY")
                                }
                              })
                            }
                            minDate={minDate}
                            maxDate={maxDate}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                placeholder="dd-mmm-yyyy"
                                label={getTranslations(
                                  t,
                                  Translates.Uploaded_At
                                )}
                                variant="filled"
                                onKeyDown={handleKeyDown}
                                error={false}
                                className="custom-form-control light date"
                                data-testid="date-input"
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className="mt-6 mb-4 inner-view__footer ">
                      <Grid item sm={4} className="text-left">
                        <Button
                          onClick={handleResetAllClick}
                          variant="text"
                          className="primary-btn mr-5 cancel-btn"
                          data-testid="reset-button"
                        >
                          {getTranslations(t, Translates.Reset_All)}
                        </Button>
                      </Grid>
                      <Grid item sm={8}>
                        <Button
                          variant="text"
                          className="cancel-btn primary-btn mr-5 "
                          onClick={applyCancelClick}
                          data-testid="cancel-button"
                        >
                          {getTranslations(t, Translates.Cancel)}
                        </Button>
                        <Button
                          className="primary-btn"
                          variant="contained"
                          onClick={applyFilterClick}
                          data-testid="apply-filter-button"
                        >
                          {getTranslations(t, Translates.Apply_Filter)}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  )
}

export default UploadStatusFilter
