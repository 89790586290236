import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Chip from "@mui/material/Chip"
import CloseIcon from "@mui/icons-material/Close"
import { Autocomplete } from "@mui/material"
import { validateEmail } from "../helper"
import { getInvalidEmailText } from "src/configurationPortal/pages/Components/ReportFileDestinationHelper"
import { useTranslation } from "react-i18next"
import { KEY_CODE } from "../constants"
const TextFieldChips = ({ getChips, form, isValid, setIsValid }) => {
  const [inputValue, setInputValue] = useState("")
  const [chips, setChips] = useState([]) as any
  const { t } = useTranslation<any>()

  const validateChips = chipsArray => {
    let hasInvalidChip = false
    for (let chip of chipsArray) {
      if (!validateEmail(chip)) {
        hasInvalidChip = true
        break
      }
    }
    setIsValid(!hasInvalidChip)
  }
  useEffect(() => {
    setChips(form?.chips || [])
    validateChips(form?.chips || [])
  }, [form])

  const handleKeyDown = event => {
    if (
      (event.key === KEY_CODE?.ENTER ||
        event.key === KEY_CODE?.TAB ||
        event.key === KEY_CODE?.COMMA) &&
      inputValue !== ""
    ) {
      event.preventDefault()
      addChip()
    } else if (event.key === KEY_CODE?.BACKSPACE && inputValue === "") {
      event.preventDefault()
      removeLastChip()
    }
  }

  const removeLastChip = () => {
    const updatedChips = [...chips]
    const removedChip = updatedChips.pop()
    setChips(updatedChips)
    getChips(updatedChips)
    if (!validateEmail(removedChip)) {
      setIsValid(true)
    }
    validateChips(updatedChips)
  }

  const addChip = () => {
    const _temp = inputValue?.trim()?.split(KEY_CODE?.COMMA)
    const trimmedValue = inputValue.trim()
    if (trimmedValue !== "") {
      const updatedChips: any =
        _temp?.length > 1 ? [...chips, ..._temp] : [...chips, trimmedValue]
      setChips(updatedChips)
      getChips(updatedChips)
      setInputValue("")
      validateChips(updatedChips)
    }
  }

  const handleChipDelete = index => {
    const updatedChips = [...chips]
    const removedChip = updatedChips.splice(index, 1)[0]
    setChips(updatedChips)
    getChips(updatedChips)
    if (!validateEmail(removedChip)) {
      setIsValid(true)
    }
    validateChips(updatedChips)
  }

  return (
    <div data-testid="parent-container">
      <Autocomplete
        className="custom-form-control select-chips"
        multiple
        freeSolo
        options={[]}
        value={chips}
        data-testid="autocomplete"
        onChange={(event, newValue: any) => {
          setChips(newValue || [])
          validateChips(newValue || [])
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setIsValid(true)
          setInputValue(newInputValue)
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              onDelete={() => handleChipDelete(index)}
              deleteIcon={<CloseIcon />}
            />
          ))
        }
        renderInput={params => (
          <div>
            <TextField
              {...params}
              name= "Email"
              label="Email"
              variant="filled"
              onKeyDown={handleKeyDown}
              onBlur={handleKeyDown}
              data-testid="chips-input" 
            />
          </div>
        )}
      />
      {!isValid && <div style={{ color: "red" }}>{getInvalidEmailText(t)}</div>}
    </div>
  )
}

export default TextFieldChips
