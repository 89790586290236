import { Grid } from "@mui/material"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"

const BreadCrumbC = ({
  customPath,
  getBreadcrumbConfig
}: {
  customPath: string
  getBreadcrumbConfig: Function
}) => {
  return (
    <>
      <Grid item xs={12} sm={6} className="d-flex align-items-center">
        <div className="custom-breadcrumb CP">
          <BreadCrumb
            customPath={customPath}
            breadCrumbInfo={getBreadcrumbConfig()}
            renderedPage={""}
          />
        </div>
      </Grid>
    </>
  )
}

export default BreadCrumbC
