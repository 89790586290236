import { IconButton, Tooltip } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import editIcon from "src/assets/images/Edit.png"
import trashIcon from "src/assets/images/TrashIcon.svg"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"
import AlertConfirmationContent from "src/configurationPortal/pages/Components/AlertConfirmationContent"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import store from "src/redux/store"
import { ConfigReportType } from "src/configurationPortal/pages/Reports/Utils/Types"
import { PATH } from "src/constants/paths"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"

export const getColumnsData = ({ updateSignature, deleteSignature, t }) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    setWidth: 200,
    Cell: props => displaySpan(props)
  }

  const sort = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Sort),
    accessor: "SortOrder",
    setWidth: 50
  }

  const Designation = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.Designation),
    accessor: "Designation"
  }

  const action = {
    ...commonColumnsData,
    Header: getTranslations(t, Translates.ACTIONS),
    accessor: "action",
    setWidth: 150,
    Cell: props => {
      const handleEditClick = () => {
        updateSignature(props.row)
      }

      const handleDeleteClick = () => {
        deleteSignature(props.row)
      }
      const icons = [
        {
          title: getTranslations(t, Translates.Edit),
          clickFn: handleEditClick,
          icon: (
            <img
              src={editIcon}
              alt={"edit icon"}
              className="pointer"
              data-testid="report-signature-edit"
            />
          )
        },
        {
          title: getTranslations(t, Translates.Delete),
          clickFn: handleDeleteClick,
          icon: (
            <img
              src={trashIcon}
              alt="delete"
              className="pointer"
              data-testid="report-signature-delete"
            />
          )
        }
      ]
      return (
        <>
          {icons.map(f => {
            return (
              <Tooltip arrow title={f.title} key={f.title}>
                <IconButton onClick={f.clickFn}>
                  <span className="text-center d-block">{f.icon}</span>
                </IconButton>
              </Tooltip>
            )
          })}
        </>
      )
    }
  }

  return [sort, Designation, action]
}

export const setModalContent = ({ preDeletedRow, t }) => {
  const content = `${getTranslations(
    t,
    Translates.Are_you_sure_you_want_to_delete
  )} "${preDeletedRow.Designation}" ${getTranslations(
    t,
    Translates.Signature
  )}?`
  return <AlertConfirmationContent warningMsg={content} />
}

export const getVariableBasedOnComponent = (
  componentName: string,
  tabId: number,
  _storeId: number
) => {
  const variableSetRedux = setReportRedux
  const tableComponent = TableComponentNames.reportSignatures
  const { reportRedux, reportsRedux } = store.getState().report
  const variableRedux: ConfigReportType = reportRedux
  const variablesRedux: ConfigReportType[] = reportsRedux
  let createPath = PATH.TAB_CREATE_SIGNATURE_CREATE
  let editPath = PATH.TAB_CREATE_SIGNATURE_EDIT
  let redirectUrl = PATH.REPORT_CREATE
  if (tabId !== -1) {
    createPath = PATH.TAB_EDIT_SIGNATURE_CREATE
    editPath = PATH.TAB_EDIT_SIGNATURE_EDIT
  }

  if (
    componentName.toLocaleLowerCase() ===
      ConfigTabNames.Reports.toLocaleLowerCase() &&
    tabId !== -1
  ) {
    redirectUrl = PATH.REPORT_EDIT
  }

  return {
    variableSetRedux,
    tableComponent,
    variableRedux,
    variablesRedux,
    createPath,
    editPath,
    redirectUrl
  }
}
