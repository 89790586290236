import { Grid } from "@mui/material"
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb"

interface Props {
  customPath: string
  bConfig: {
    currentPath: string
    mapObj: { reportings: string }
    showHomeIcon: boolean
  }
}

const CBreadCrumb = ({ customPath, bConfig }: Props) => {
  return (
    <div className="main-toolbar">
      <div className="main-container">
        <Grid container>
          <Grid item sm={12} className="d-flex align-items-center">
            <div className="custom-breadcrumb CP">
              <BreadCrumb
                customPath={customPath}
                breadCrumbInfo={bConfig}
                renderedPage={""}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default CBreadCrumb
