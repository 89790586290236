import { getTranslations } from "src/utils/helper"
import { createEditPromptDefaultIniValues } from "../Constants/CreateEditPromptConstant"
import { ConfigPromptType } from "../Types"
import { Translates } from "src/i18n/i18n"
import { getTabLabel } from "src/configurationPortal/pages/FunctionHelper"

export const getDefaultValues = (
  prompts: ConfigPromptType[],
  storeId: number
) => {
  if (prompts.length) {
    if (storeId > -1 && prompts[storeId - 1]) {
      return {
        ...prompts[storeId - 1],
        Label: prompts[storeId - 1].Label[0]["en-us"]
      }
    }
  }
  return createEditPromptDefaultIniValues
}

export const getBreadcrumbConfig = (t, tab, storeId) => {
  let currentPath: string = "prompt"

  const createOrEdit =
    storeId > -1 ? Translates.Edit_Prompt : Translates.New_Prompt

  const { locTab } = getTabLabel(tab)
  let variables: any = {
    prompt: `${getTranslations(t, locTab)} ${getTranslations(t, createOrEdit)}`
  }

  let bConfig: any = {
    currentPath,
    mapObj: variables,
    showHomeIcon: false
  }
  return bConfig
}
