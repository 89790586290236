import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { setLocationCategories } from "../../../redux/slices/configSlice"
import { RootState, useAppThunkDispatch } from "../../../redux/store"
import { useHistory, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import React, { useState, useEffect, useCallback } from "react"
import { PATH } from "../../../constants/paths"
import { updateWarnOnTabChangeToStore } from "../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations, setWeekFieldError } from "src/utils/helper"
import CBreadCrumb from "../Components/CBreadCrumb"
import { ConfigDataHook } from "src/hooks/configurationFileData"
interface INewCategory {
  Category: string
  Label: string
  IsAQOnly: boolean
}

const formFieldNames = {
  Category: "Category",
  Label: "Label",
  IsAQOnly: "IsAQOnly"
}
const CreateEditCategory = () => {
  const { id }: { id: string } = useParams()
  const { t } = useTranslation<any>()
  const {
    config: { configType: configTypeFromStore }
  } = ConfigDataHook()
  const [categoriesList, setCategoriesList] = useState([
    "Salesfloor",
    "Stockroom",
    "Pharmacy",
    "Overstock",
    "Understock",
    "Offsite",
    "Trailer",
    "Precount",
    "Other"
  ])
  const [form, setForm] = React.useState<INewCategory>({
    Category: "",
    Label: "",
    IsAQOnly: false
  } as INewCategory)
  const [weekIVFields, setWeekIVFields] = useState({
    Label: ""
  })
  const [errorMsg, setErrorMsg] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const configData = useSelector((state: RootState) => state.config)
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const search = window.location.search || ""

  useEffect(() => {
    if (configData) {
      const categories = configData.locationCategories || []
      let formData = {} as INewCategory
      if (storeId !== -1 && categories[storeId - 1]) {
        formData = {
          ...formData,
          ...{
            Category: categories[storeId - 1].Name,
            Label: categories[storeId - 1].Label,
            IsAQOnly: categories[storeId - 1].IsAQOnly || false
          }
        }
        setForm(formData)
      }
      const _temp = categories.filter(f => f.Name !== "Other").map(d => d.Name)
      setCategoriesList([
        ...categoriesList.filter(
          f => !_temp.includes(f) || (storeId !== -1 && f === formData.Category)
        )
      ])
    }
  }, [configData])

  const getBreadcrumbConfig = () => {
    const currentPath = "categories"
    const variables: any = {
      categories: getTranslations(
        t,
        storeId === -1 ? Translates.New_Category : Translates.Edit_Category
      )
    }
    const bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }

  const onFormChange = useCallback(
    e => {
      updateWarnOnTabChangeToStore(true)
      const val = e.target.value
      if (e.target.name) {
        setForm({
          ...form,
          [e.target.name]: [formFieldNames.IsAQOnly].includes(e.target.name)
            ? e.target.checked
            : val
        })
      }
      if (e.target.name === formFieldNames.Category) {
        setForm({ ...form, [e.target.name]: val, Label: val })
      }
      if (e.target.name === formFieldNames.Label) {
        setErrorMsg(false)
        setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
      }
    },
    [setForm, form]
  )

  const checkValidation = () =>
    Object.values(form).some(s => s === "" || s === null) ||
    Object.values(weekIVFields).some(s => s || s !== "") ||
    configTypeFromStore === "view"

  const getMaxSortOrder = categories => {
    return categories?.length > 0
      ? categories.reduce(
          (maxSortOrder, category) =>
            Math.max(category.SortOrder, maxSortOrder),
          0
        )
      : 0
  }

  const updateCategories = (categories, newItem) => {
    const categoriesLoc = categories || []
    if (storeId <= -1) {
      return [...categoriesLoc, { ...newItem }]
    } else {
      return categoriesLoc.map((category, index) =>
        index === storeId - 1
          ? { ...newItem, SortOrder: category.SortOrder }
          : category
      )
    }
  }

  const handleSave = () => {
    const categories = configData?.locationCategories
    const otherLabels = categories
      ?.filter(
        (category, index) => category.Name === "Other" && storeId - 1 !== index
      )
      .map(category => category.Label)

    if (form.Category === "Other" && otherLabels?.includes(form.Label)) {
      setErrorMsg(true)
    } else {
      setErrorMsg(false)
      const sortOrder = getMaxSortOrder(categories)
      const newItem = {
        Name: form.Category,
        Label: form.Label,
        SortOrder: sortOrder + 1,
        IsAQOnly: form.IsAQOnly
      }

      const updatedCategories = updateCategories(categories, newItem)

      dispatch(setLocationCategories(updatedCategories))
      history.goBack()
    }
  }

  const onSaveClickUseCallBack = useCallback(() => {
    const handleUpdate = () => {
      updateWarnOnTabChangeToStore(false)
      setSaveClicked(true)
      handleSave()
    }

    handleUpdate()
  }, [configData, setSaveClicked, form, storeId, history, dispatch])

  const onCancelClickUseCallBack = useCallback(() => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CATEGORIES + search)
    })
  }, [])

  const getLabelError = () => {
    if (saveClicked && errorMsg) {
      return `Can not have two "Other" categories with same Label`
    } else if (weekIVFields.Label) {
      return weekIVFields.Label
    }
    return ""
  }

  return (
    <div>
      <CBreadCrumb customPath="New Category" bConfig={getBreadcrumbConfig()} />
      <Grid container className="main-container">
        <Grid item xs={12} sm={3}>
          <label className="mb-3 form-control__label">
            {getTranslations(t, Translates.DETAILS)}
          </label>
          <div className="text-box">
            <FormControl
              className="custom-form-control select"
              variant="filled"
            >
              <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                {getTranslations(t, Translates.Select_Category)}*
              </InputLabel>
              <Select
                id="demo-multiple-checkbox"
                data-testid="category-select"
                value={form.Category}
                name={formFieldNames.Category}
                onChange={onFormChange}
              >
                {categoriesList.map((name: any, i) => (
                  <MenuItem key={name} value={name} className="dropdown-list">
                    <small data-testid={`coption-${i}`}>{name}</small>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={`${getTranslations(t, Translates.Enter_Category_Name)}*`}
              variant="filled"
              autoSave="false"
              autoComplete="off"
              className="custom-form-control"
              onChange={onFormChange}
              name={formFieldNames.Label}
              data-testid="category-name"
              value={form.Label}
              error={(saveClicked && errorMsg) || !!weekIVFields.Label}
              helperText={getLabelError()}
            />
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  className="custom-check light"
                  checked={form.IsAQOnly}
                  name={formFieldNames.IsAQOnly}
                  onChange={onFormChange}
                  checkedIcon={<CheckIcon />}
                  data-testid="IsAQOnlyCheckbox"
                />
              }
              label={"AQ Only"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-8">
          <div className="inner-view__footer">
            <Button
              data-testid="cancel-btn"
              variant="text"
              className="primary-btn cancel-btn mr-5"
              onClick={onCancelClickUseCallBack}
            >
              {getTranslations(t, Translates.Cancel)}
            </Button>
            <Button
              variant="contained"
              data-testid="save-btn"
              className="primary-btn"
              disabled={checkValidation() === true}
              onClick={onSaveClickUseCallBack}
            >
              {getTranslations(t, Translates.Save)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateEditCategory
