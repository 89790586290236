import { Checkbox, IconButton, Tooltip } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import editIcon from "src/assets/images/Edit.png"
import trashIcon from "src/assets/images/TrashIcon.svg"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"
import { GetReportsColumnsDataType } from "../Types"
import AlertConfirmationContent from "src/configurationPortal/pages/Components/AlertConfirmationContent"

const displayDisableCheckbox = (props: any) => {
  return (
    <Checkbox
      checked={props.value}
      className={`custom-check light`}
      checkedIcon={<CheckIcon />}
      disabled={true}
    />
  )
}
export const getColumnsData = ({
  copyReport,
  updateReport,
  deleteReport,
  t,
  configTypeFromStore
}: GetReportsColumnsDataType) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    setWidth: 50,
    Cell: props => {
      return displaySpan(props)
    }
  }

  return [
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Sort),
      accessor: "SortOrder"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Report_Name),
      accessor: "Name",
      setWidth: 500
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Interim),
      accessor: "IsInterim",
      Cell: props => {
        return displayDisableCheckbox(props)
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Final),
      accessor: "IsFinal",
      Cell: props => {
        return displayDisableCheckbox(props)
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Signature),
      accessor: "IsSignature",
      Cell: props => {
        return displayDisableCheckbox(props)
      }
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      setWidth: 200,
      Cell: props => {
        const handleCopyClick = () => {
          copyReport(props.row)
        }

        const handleEditClick = () => {
          updateReport(props.row)
        }

        const handleDeleteClick = () => {
          deleteReport(props.row)
        }
        const icons = [
          {
            title: getTranslations(t, Translates.Copy),
            clickFn: handleCopyClick,
            icon: (
              <ContentCopyOutlinedIcon
                className="copy-icon pointer"
                data-testid="report-copy"
              />
            )
          },
          {
            title: getTranslations(t, Translates.Edit),
            clickFn: handleEditClick,
            isNotDisabled: true,
            icon: (
              <img
                src={editIcon}
                alt={"edit icon"}
                className="pointer"
                data-testid="report-edit"
              />
            )
          },
          {
            title: getTranslations(t, Translates.Delete),
            clickFn: handleDeleteClick,
            icon: (
              <img
                src={trashIcon}
                alt="delete"
                className="pointer"
                data-testid="report-delete"
              />
            )
          }
        ]
        return (
          <>
            {icons.map((f, i) => {
              return (
                <Tooltip arrow title={f.title} key={f.title}>
                  <IconButton
                    onClick={f.clickFn}
                    disabled={
                      !f.isNotDisabled && configTypeFromStore === "view"
                    }
                  >
                    <span className="text-center d-block">{f.icon}</span>
                  </IconButton>
                </Tooltip>
              )
            })}
          </>
        )
      }
    }
  ]
}

export const setModalContent = ({ preDeletedRow, t }) => {
  let content = `${getTranslations(
    t,
    Translates.Are_you_sure_you_want_to_delete
  )} "${preDeletedRow.Name}" ${getTranslations(
    t,
    Translates.record_in_report_and_output_bundles
  )}?`
  return <AlertConfirmationContent warningMsg={content} />
}
