import { Button, Grid } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useLocation } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import CreateLookupEditor from "./CreateLookupEditor"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import { ruleEditorTitle } from "../helpers"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
const CreateLookup = () => {
  const { t } = useTranslation<any>()
  const location = useLocation() as any
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const locationState = { ...location.state }
  let component = locationState?.component

  const { title } = ruleEditorTitle(component, t)

  const getBreadcrumbConfig = () => {
    let currentPath: string = `newLookup`
    let variables: any = {
      newLookup: title
    }
    let bConfig: any = {
      currentPath,
      showHomeIcon: false,
      mapObj: variables
    }
    return bConfig
  }

  const componentRef = useRef<any>(null)
  const [isIfElse, setIsIfElse] = useState(false) // temp state to re render createLookup, update it later accordingly
  const [bConfig, setBConfig] = useState<any>(getBreadcrumbConfig())

  const updateIfElse = (val: boolean) => {
    setIsIfElse(val)
    return val
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (location.state?.bConfig) {
        let bConfigloc = location.state?.bConfig
        bConfigloc.currentPath = `${bConfigloc.currentPath}/${title}`
        bConfigloc.mapObj = {
          ...bConfigloc.mapObj,
          ...{ [title]: title }
        }
        setBConfig(bConfigloc)
      }
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item xs={12} sm={8} className="d-flex align-items-center">
              <div className="custom-breadcrumb CP">
                <BreadCrumb
                  customPath={"Lookup Routines"}
                  breadCrumbInfo={bConfig}
                  renderedPage={""}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className="text-right">
              <Button
                variant="text"
                className="primary-btn cancel-btn black-text mr-3"
                onClick={() => {
                  componentRef.current.cancelButtonClick()
                }}
              >
                <CloseIcon className="font-30 ml-3" />{" "}
                {getTranslations(t, Translates.Close)}
              </Button>
              {isIfElse ? (
                <Button
                  variant="contained"
                  className="primary-btn"
                  onClick={() => {
                    componentRef.current.handleIfElseSubmit()
                  }}
                  data-testid="screen2-submit-btn"
                  disabled={configTypeFromStore === "view" ? true : false}
                >
                  {componentRef.current.isIfElseStateEditCheckFunction()
                    ? getTranslations(t, Translates.Update)
                    : getTranslations(t, Translates.Save)}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="primary-btn"
                  onClick={() => {
                    componentRef.current.handleSubmit()
                  }}
                  data-testid="screen1-submit-btn"
                  disabled={configTypeFromStore === "view" ? true : false}
                >
                  {[undefined, -1].includes(location.state?.index)
                    ? getTranslations(t, Translates.Save)
                    : getTranslations(t, Translates.Update)}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="main-container">
        <DndProvider backend={HTML5Backend}>
          <CreateLookupEditor
            initialState={isIfElse}
            updateIfElse={updateIfElse}
            ref={componentRef}
            locationVariable={location}
          />
        </DndProvider>
      </div>
    </>
  )
}
export default CreateLookup
