import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Divider,
  Grid,
  TextField
} from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { Translates } from "src/i18n/i18n"
import {
  decryptUrlParams,
  encryptUrlParams,
  getTranslations
} from "src/utils/helper"
import { Loader } from "src/utils/reusables/reusable"
import { PATH } from "src/constants/paths"
import { useHistory, useLocation } from "react-router-dom"
import { ScheduleService } from "src/service/schedule.service"
import { ConfigService } from "src/service/config.service"
import { useSelector } from "react-redux"
import { RootState } from "../../../src/redux/store"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ScheduleDetails from "./ScheduleDetails"
import InfoIcon from "@mui/icons-material/Info"
import shortid from "shortid"
import { QUERY_PARAMETERS } from "src/utils/constants"
import ScheduleToolBar from "src/components/ScheduleToolBar/ScheduleToolBar"
const UpdateConfiguration = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation<any>()
  const [configOptions, setConfigOptions] = useState([] as any)
  const [eventIds, setEventIds] = useState([] as any)

  const [eventDetails, setEventDetails] = useState([] as any)
  const [configName, setConfigName] = useState("")
  const [alertVisible, setAlertVisible] = useState(false)
  const [noFutureEvents, setNoFutureEvents] = useState(false)
  const [futureEventData, setFutureEventData] = useState([])
  const [stateData, setStateData] = useState({} as any)
  const [schedule, setSchedule] = useState([] as any)
  const [filterNum, setFilterNum] = useState<any>(0)
  const [showConfigurationFilter, setShowConfigurationFilter] = useState(false)
  const history = useHistory()
  const scheduleService = new ScheduleService()
  const configService = new ConfigService()
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const customers = useSelector((state: RootState) => state.settings.customers)
  let location = useLocation() as any
  const hideConfigurationFilter = useCallback(() => {
    setShowConfigurationFilter(false)
  }, [])
  const sendFilterConfigurationDataToParent = useCallback(filteredData => {
    const ConfigStatusLength =
      filteredData?.eventStatus?.length === 4
        ? 0
        : filteredData?.eventStatus?.length;
    const num =
      Number(filteredData?.region1 ? filteredData?.region1.length : 0) +
      Number(filteredData?.region2 ? filteredData?.region2.length : 0) +
      Number(filteredData?.region3 ? filteredData?.region3.length : 0) +
      Number(filteredData?.region4 ? filteredData?.region4.length : 0) +
      Number(filteredData?.country ? filteredData?.country.length : 0) +
      Number(filteredData?.state ? filteredData?.state.length : 0) +
      Number(ConfigStatusLength) +
      (filteredData?.currentDate ? 1 : 0);

    setFilterNum(num);
    return num;
  }, []);
  const handleConfigurationFilter = useCallback(() => {
    setShowConfigurationFilter(prevState => !prevState)
  }, [])
  useEffect(() => {
    let decryptedId: any
    let pathname: string | string[] = location.pathname.split("/")
    let len = pathname?.length
    let id: any = pathname[len - 1] || ""
    decryptedId = decryptUrlParams(id)
    const _locationState = location.state
    setStateData(_locationState)
    if (_locationState?.data?.length) {
     const currentDateTime = new Date(); // Current date and time in user's local timezone
    const futureEvents = _locationState?.data?.filter(event => {
      const eventDateTime = new Date(event.scheduledDateTime); // Converts to local timezone
      return eventDateTime > currentDateTime;
    })
      setFutureEventData(futureEvents)
    }
    if (customerId) {
      getConfigDetails(_locationState)
    }
    if (decryptedId !== "-1") {
      getEventDetailsForConfig(decryptedId)
    }

    setEventIds(decryptedId)
  }, [eventIds, customerId])

  useEffect(() => {
    if (alertVisible === true) {
      setTimeout(() => {
        setAlertVisible(false)
      }, 3000)
    }
  }, [alertVisible])
  // getting event details for seleted events
  const getEventDetailsForConfig = id => {
    if (id) {
      setLoading(true)
      scheduleService
        .getEventDetailsForConfig(id.split(","))
        .then(async (resp: any) => {
          setEventDetails(resp?.data)
          console.log(resp?.data)
        })
      setLoading(false)
    }
  }
  // cancel Click
  const cancelClick = () => {
    setNoFutureEvents(true)
    if (stateData?.actionIdConfig !== 0) {
      setTimeout(() => {
        history.push(PATH.CONFIGURATION)
      }, 3000)
    } else {
      setTimeout(() => {
        history.push(
          `/schedule?${QUERY_PARAMETERS?.EVENTIDS}=${encryptUrlParams(
            eventIds
          )}`
        )
      }, 3000)
    }
  }
  // getting config values for dropdown
  const getConfigDetails = _pState => {
    configService
      .getConfigDetails([customerId])
      .then((res: any) => {
        res = res.data
        let options = res.map((c: any) => {
          return {
            label: `${c.name} (${c.version})`,
            value: c.idConfig
          }
        })
        if (_pState?.actionIdConfig && _pState?.isInstall) {
          const temp = options.find(f => f.value === _pState?.actionIdConfig)
          setConfigOptions([temp])
          setConfigName(temp.label)
        } else {
          setConfigOptions(options)
        }
      })
      .catch(err => {})
  }
  // on change of autocomplete
  const onHandleChange = e => {
    const val = e.target.value || ""
    setConfigName(val)
  }
  // Updating the configurtion for selected events- API call
  const handleUpdateConfigAPI = () => {
    setLoading(true)
    const _config: any = configOptions?.find(
      (c: any) => c?.label === configName
    )
    // making the payload
    const _payload = {
      eventIds: eventIds?.split(","),
      idConfig:
        stateData?.actionIdConfig && stateData?.isInstall
          ? stateData?.actionIdConfig
          : _config?.value,
      idCustomer: customerId,
      updatedBy: customers[0]?.idUser ? customers[0]?.idUser : 0,
      lastUpdatedDate: new Date()
    }
    scheduleService
      .UpdateEventsConfigDetails(_payload)
      .then(async (resp: any) => {
        setAlertVisible(true)
        if (stateData?.actionIdConfig !== 0) {
          const NoFutureEvents =
            stateData?.futureEventCount === eventIds.split(",").length
              ? true
              : false
          const IdConfig =
            stateData?.futureEventCount === eventIds.split(",").length
              ? stateData?.actionIdConfig
              : 0
          setTimeout(() => {
            history.push(
              `/configuration?${
                QUERY_PARAMETERS?.NOFUTUREEVENTS
              }=${NoFutureEvents}&${
                QUERY_PARAMETERS?.IDCONFIG
              }=${encryptUrlParams(IdConfig)}`
            )
          }, 3000)
        } else {
          setTimeout(() => {
            history.push(PATH.SCHEDULE)
          }, 3000)
        }
      })
    setLoading(false)
  }
  // Filtering the options from autocomplete depending on event details
  const getOptions = () => {
    const temp = eventDetails?.map(m => m.idConfig)
    return configOptions?.filter(m => !temp.includes(m.value))
  }
  const groupedData = eventDetails?.reduce((groups, item) => {
    const key = `${item.configName || ""}-${item.configVersion || ""}`
    if (!groups[key]) {
      groups[key] = {
        configName: item.configName,
        configVersion: item.configVersion,
        count: 1
      }
    } else {
      groups[key].count++
    }
    return groups
  }, {})

  const groupedItems = Object.values(groupedData)

  return (
    <>
      <Grid container>
        <MiniHeader
          headerTitle={getTranslations(t, Translates.UPDATE_CONFIGURATION)}
          // multiCustomer={handleNoCustomerId}
        />
        {loading === true ? (
          Loader()
        ) : (
          <Grid container className="inner-view">
            <Grid item xs={12} className="main-container">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    {eventIds !== "-1" ? (
                      <Grid item xs={12} sm={6}>
                        <>
                          <label className="form-control__label mb-3">
                            {getTranslations(t, Translates.Basic_Details)}
                          </label>
                        </>
                        <>
                          <div className="mb-3">
                            <strong>
                              {getTranslations(t, Translates.Events_Selected)}
                            </strong>
                          </div>
                        </>

                        <Grid item xs={12} sm={12}>
                          {groupedItems?.map((m: any) => {
                            return (
                              <ul
                                key={shortid.generate()}
                                style={{ listStyleType: "none" }}
                              >
                                <li key={shortid.generate()} className="mb-3">
                                  <strong>{m.count} </strong>
                                  {"     "}
                                  {`${m.configName ? m.configName : ""}    ${
                                    m.configVersion ? m.configVersion : ""
                                  }`}
                                </li>
                              </ul>
                            )
                          })}
                        </Grid>

                        <Divider className="dashed my-4" />
                        <label className="form-control__label mb-3">
                          {getTranslations(t, Translates.CHOOSE_CONFIGURATION)}
                        </label>
                        <Grid item className="mx-2" style={{ width: "300px" }}>
                          <div className="text-box custom-form-control select no-border mb-0">
                            <Autocomplete
                              data-testid="configName"
                              fullWidth
                              className="auto-complete-full"
                              id="tags-filled"
                              noOptionsText={getTranslations(
                                t,
                                Translates.Choose_Configuration_Version
                              )}
                              disablePortal={true}
                              disableClearable
                              value={configName}
                              options={getOptions().map((o: any) => o?.label)}
                              getOptionLabel={(option: any) =>
                                option?.toString()
                              }
                              onChange={(e, newValue) => {
                                const value = newValue
                                onHandleChange({
                                  target: { name: "configName", value }
                                })
                              }}
                              disabled={configOptions?.length === 1}
                              autoHighlight
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label={getTranslations(
                                    t,
                                    Translates.Choose_Configuration_Version
                                  )}
                                  name="configName"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off"
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <div>
                        <ScheduleToolBar
                          handleFilter={handleConfigurationFilter}
                          schedule={schedule}
                          futureEventsDataCheck={true}
                          filterCount={
                            schedule && schedule.length > 0 ? filterNum : 0
                          }
                        />
                        <div className="main-container mt-8">
                          <ScheduleDetails
                            hideFilter={hideConfigurationFilter}
                            showFilterPage={showConfigurationFilter}
                            setScheduleInParent={setSchedule}
                            sendFilterDataToParent={
                              sendFilterConfigurationDataToParent
                            }
                            futureEventsData={futureEventData}
                            selectedEventIds={[eventIds]}
                            actionIdConfig={stateData?.actionIdConfig}
                            isInstall={stateData?.isInstall}
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {eventIds !== "-1" && (
                    <div className="inner-view__footer">
                      <Button
                        variant="text"
                        className="primary-btn cancel-btn mr-5"
                        data-testid="cancel"
                        onClick={() => cancelClick()}
                      >
                        {getTranslations(t, Translates.Cancel)}
                      </Button>
                      <Button
                        variant="contained"
                        className="primary-btn"
                        data-testid="continue"
                        onClick={() => handleUpdateConfigAPI()}
                        disabled={!configName}
                      >
                        {getTranslations(t, Translates.CONTINUE)}
                      </Button>
                    </div>
                  )}
                </Grid>
                <div>
                  {alertVisible ? (
                    <Alert
                      severity={"success"}
                      icon={<CheckCircleIcon />}
                      className="custom-alert top"
                    >
                      <AlertTitle className="custom-alert__title f-600">
                        {getTranslations(t, Translates.Success)}
                      </AlertTitle>
                      {`${eventIds.split(",")?.length} ${getTranslations(
                        t,
                        Translates.of
                      )} ${stateData?.futureEventCount} ${getTranslations(
                        t,
                        Translates.future_events_will_be_updated_to_this_new_configuration
                      )}`}
                    </Alert>
                  ) : null}
                </div>
                <div>
                  {noFutureEvents ? (
                    <Alert
                      severity={"info"}
                      icon={<InfoIcon />}
                      className="custom-alert top"
                    >
                      <AlertTitle className="custom-alert__title f-600">
                        {getTranslations(t, Translates.Info)}
                      </AlertTitle>

                      {getTranslations(
                        t,
                        Translates.No_Future_events_have_been_updated
                      )}
                    </Alert>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}
export default UpdateConfiguration
