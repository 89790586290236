import { formField } from "src/utils/constants"
import { SignatureFieldType, SignatureType } from "../Types"

export const createEditSignatureFieldNames = {
  Designation: { name: "Designation", label: "Designation" }
}

export const createEditSignatureDefaultIniValues: SignatureType = {
  Designation: ""
}

export const SignatureFieldDetails: SignatureFieldType = {
  designation: {
    fieldType: formField.text,
    props: {
      name: createEditSignatureFieldNames.Designation.name,
      label: createEditSignatureFieldNames.Designation.label,
      required: true
    }
  }
}
