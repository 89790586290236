import { Controller, useFormContext } from "react-hook-form"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material"
import shortid from "shortid"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { useTranslation } from "react-i18next"
import { getDefaultValue, getFieldError } from "./Helper"
import { getTranslations } from "src/utils/helper"

interface Option {
  label: string
  value: any
  default?: boolean
  translateKey?: string
}
export interface Props {
  inputAttributes: {
    name: string
    label: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    InputProps?: any
    value?: any
    defaultValue?: any
    onChange?: Function
    options: Option[]
    disabled?: boolean
    isNotSort?: boolean
    isOptionTranslate?: boolean
  }
}

const MUISelect = ({ inputAttributes }: Props) => {
  let {
    name,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    required,
    value,
    defaultValue,
    onChange,
    options,
    disabled,
    isNotSort,
    isOptionTranslate
  } = inputAttributes
  const { t } = useTranslation<any>()

  let newOptions = !isNotSort
    ? [...options].sort((a, b) => a.label?.localeCompare(b.label))
    : options

  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods
  let defVal: any = getDefaultValue(value, defaultValue, newOptions)
  const { isError, ErrorText } = getFieldError(name, errors)
  if (isOptionTranslate) {
    newOptions = newOptions.map(i => ({
      ...i,
      label: getTranslations(t, i.translateKey ?? i.label )
    }))
  }
  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field } }) => (
        <FormControl variant="filled" className="custom-form-control select">
          <InputLabel>{getTranslations(t, label)}</InputLabel>
          <Select
            {...field}
            data-testid="selectCombobox"
            onChange={e => {
              field.onChange(e)
              updateWarnOnTabChangeToStore(true)
              if (onChange) onChange()
            }}
            id={id ?? name}
            required={required ?? false}
            size={size ?? "small"}
            placeholder={placeholder}
            inputRef={ref}
            variant={variant ?? "filled"}
            color={color ?? "primary"}
            fullWidth
            disabled={disabled ?? false}
            className={`${className}`}
            error={isError}
          >
            {newOptions.map((type, inx) => (
              <MenuItem
                className="dropdown-list"
                key={shortid.generate()}
                value={type.value}
              >
                <small>{type.label}</small>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={isError ? "text-danger" : ""}>
            {ErrorText}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default MUISelect
