import { object, string } from "yup"
import { requiredErr } from "src/utils/constants"
import { createEditSignatureFieldNames } from "./Constants/CreateEditSignatureConstant"
import { SignatureType } from "./Types"
import { getTranslations, validateInputString } from "src/utils/helper"

export const validationSchema = (
  Signatures: SignatureType[],
  storeId: number,
  t,
  Translates
) => {
  return object().shape({
    [createEditSignatureFieldNames.Designation.name]: string()
      .required(requiredErr)
      .test(
        createEditSignatureFieldNames.Designation.name,
        "Destination name already exists.",
        value => {
          return !Signatures.some(
            (item, inx) => item.Designation === value && storeId - 1 !== inx
          )
        }
      )
      .test(
        createEditSignatureFieldNames.Designation.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      )
  })
}
