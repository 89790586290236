//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class MultiEventOutputsService {
  GetMultiEventFileDetailsByEventId(id) {
    const endPoint = `/api/MultiStoreFileProcessing/GetMultiEventFileDetailsByEventId?multiEventFileDetailId=${id}`
    return api.findAll(endPoint)
  }
  getConfigsByCustomers(payload) {
    const endPoint = `/api/MultiStoreFileProcessing/GetConfigurationsById`
    return api.post(endPoint, payload)
  }
  addMultiEventDetails(payload) {
    const endPoint = `/api/MultiStoreFileProcessing/AddMultiEventDetails`
    return api.post(endPoint, payload)
  }

  updateMultiEventDetails(payload) {
    const endPoint = `/api/MultiStoreFileProcessing/UpdateMultiEventDetails`
    return api.put(endPoint, payload)
  }
}
//END-NOSCAN
