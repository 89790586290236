import * as yup from "yup"
import { requiredErr } from "src/utils/constants"
import { createEditReportFieldNames } from "../Constants/CreateEditReportConstant"
import { ConfigReportType } from "../Types"
import { getTranslations, validateInputString } from "src/utils/helper"

export const destinationValidation = (emailName = "Email") => {
  return yup.object().shape({
    Destination: yup.object().shape({
      [emailName]: yup.array().of(
        yup
          .string()
          .matches(
            /^(([^<>()[\]\.,:\s@\"]+(\.[^<>()[\]\.,:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,:\s@\"]+\.)+[^<>()[\]\.,:\s@\"]{2,})$/i,
            "Invalid email address"
          )
          .trim()
      )
    })
  })
}
export const validationSchema = (
  reportsRedux: ConfigReportType[],
  storeId: number,
  t,
  Translates
) => {
  return yup.object().shape({
    [createEditReportFieldNames.Name.name]: yup
      .string()
      .required(requiredErr)
      .test(
        createEditReportFieldNames.Name.name,
        "Report name already exist.",
        value => {
          return !(reportsRedux || []).some(
            (item, inx) => item.Name === value && storeId - 1 !== inx
          )
        }
      )
      .test(
        createEditReportFieldNames.Name.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),
    [createEditReportFieldNames.Description.name]: yup
      .string()
      .required(requiredErr),
    [createEditReportFieldNames.FileName.name]: yup
      .string()
      .max(30, "File name can not be more than 30")
      .required(requiredErr)
      .test(
        createEditReportFieldNames.FileName.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),
    [createEditReportFieldNames.SQL.name]: yup.string().required(requiredErr),
    [createEditReportFieldNames.Columns.name]: yup
      .array()
      .min(1, "At least one column is required")
      .required(requiredErr),
    ...destinationValidation().fields
  })
}
