import { Controller, useFormContext } from "react-hook-form"
import shortid from "shortid"
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { getFieldError } from "./Helper"
import { getTranslations } from "src/utils/helper"
import { useTranslation } from "react-i18next"

export type formControlLabel = {
  value: string | boolean
  className?: string
  label: string
  radio?: {
    className?: string
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
  }
  default?: boolean
  disabled?: boolean
}

export type Props = {
  className?: string
  name: string
  formLabel?: string
  formControl: {
    className?: string
    disabled?: boolean
    radioGroup: {
      row?: boolean
      formControlLabels: formControlLabel[]
      ariaLabelledby?: string
      name?: string
      onChange?: Function
    }
    defaultValue?: any
    value?: any
  }
  onChange?: Function
  disabled?: Function
}

const MUIRadio = ({
  formLabel,
  name,
  className,
  formControl,
  onChange
}: Props) => {
  const { t } = useTranslation<any>()

  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods
  let defVal: any = ""
  if (formControl.value !== undefined) {
    defVal = formControl.value
  } else if (formControl.defaultValue !== undefined) {
    defVal = formControl.defaultValue
  } else {
    formControl.radioGroup.formControlLabels.forEach(item => {
      if (item.default) {
        defVal = item.value
      }
    })
  }
  const { isError, ErrorText } = getFieldError(name, errors)
  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field } }) => (
        <FormControl className={`${className}`} disabled={formControl.disabled}>
          <FormLabel id={`${name}-radio-buttons-group-label`}>
            {formLabel}
          </FormLabel>
          <RadioGroup
            {...field}
            row={formControl.radioGroup.row ?? false}
            aria-labelledby={
              formControl.radioGroup.ariaLabelledby ??
              `${name}-row-radio-buttons-group-label`
            }
            className={`${formControl.className}`}
            onChange={e => {
              field.onChange(e)
              updateWarnOnTabChangeToStore(true)
              if (formControl.radioGroup.onChange)
                formControl.radioGroup.onChange()
              if (onChange) onChange(e)
            }}
          >
            {formControl.radioGroup.formControlLabels.map((item, inx) => (
              <FormControlLabel
                key={shortid.generate()}
                value={item?.value}
                className={`${item?.className}`}
                label={`${getTranslations(t, item?.label)}`}
                {...(!formControl.disabled && {
                  disabled: item?.disabled ?? false
                })}
                control={
                  <Radio
                    className={item?.radio?.className}
                    size={item?.radio?.size ?? "small"}
                    inputRef={ref}
                  />
                }
              />
            ))}
          </RadioGroup>
          <FormHelperText className={isError ? "text-danger" : ""}>
            {ErrorText}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default MUIRadio
