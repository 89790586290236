import { useReducer } from "react"
import { SubmitHandler, useFormContext } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AlertColor, Button, Grid } from "@mui/material"
import AlertC from "src/components/Alert/Alert"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setCountPrograms } from "src/redux/slices/configSlice"
import { PATH } from "../../../../constants/paths"
import { formToReduxConversion } from "../helper"
import { IFormCountProgram } from "./../../../../utils/types/types"
import { fieldNames } from "src/configurationPortal/pages/CountPrograms/Constant"
import { reducer, updateWarnOnTabChangeToStore } from "../../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
let alertProps = {
  title: "Validation Error!",
  severity: "error" as AlertColor,
  content: ""
}
let showAlert: boolean = false

const setAlertProps = newAlertProps =>
  (alertProps = { ...alertProps, ...newAlertProps })
const setShowAlert = newVal => (showAlert = newVal)

const SubmitAndCancelButton = () => {
  const { t } = useTranslation<any>()
  const {
    config: { countPrograms, configType: configTypeFromStore }
  } = ConfigDataHook()
  const {
    watch,
    handleSubmit,
    formState: { errors }
  } = useFormContext()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const [, setReload] = useReducer(reducer, false)
  let search = window.location.search

  const triggerSetReload = () => {
    setReload(oldVal => !oldVal)
  }

  const formSubmitHandler: SubmitHandler<any> = async (
    data: IFormCountProgram
  ) => {
    const { reduxData, cpErrors } = formToReduxConversion(data)
    if (cpErrors.length) {
      toastMsgShow({
        content: cpErrors[0]
      })
      return false
    }
    let newArr = [...countPrograms]
    if (storeId !== -1) {
      newArr[storeId - 1] = reduxData
    }
    //istanbul ignore next
    else {
      newArr = [...newArr, reduxData]
    }
    dispatch(setCountPrograms([...newArr]))
    //istanbul ignore next
    onCancelClick()
  }

  const toastMsgShow = alertMsg => {
    setAlertProps({
      ...alertMsg
    })
    setShowAlert(true)
    triggerSetReload()
    setTimeout(() => {
      setShowAlert(false)
      triggerSetReload()
    }, 3000)
  }

  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.COUNT_PROGRAMS + search)
    })
  }

  const formErrorCheckFunction = () => {
    let error: boolean = false
    if (
      !watch(fieldNames.name.name) ||
      (watch(fieldNames.ProductEnableMode.name) &&
        !watch(fieldNames.countType.name)) ||
      Object.keys(errors).length ||
      configTypeFromStore === "view"
    ) {
      error = true
    }
    return error
  }

  return (
    <>
      <Grid item xs={12}>
        <div className="inner-view__footer">
          <Button
            data-testid="cancelBtn"
            variant="text"
            className="primary-btn cancel-btn mr-5"
            onClick={onCancelClick}
          >
            {getTranslations(t, Translates.Cancel)}
          </Button>
          <Button
            data-testid="saveBtn"
            variant="contained"
            className="primary-btn"
            disabled={formErrorCheckFunction()}
            onClick={handleSubmit(formSubmitHandler)}
          >
            {storeId !== -1
              ? getTranslations(t, Translates.Update)
              : getTranslations(t, Translates.Save)}
          </Button>
        </div>
      </Grid>
      <Grid container>{showAlert ? <AlertC {...alertProps} /> : null}</Grid>
    </>
  )
}

export default SubmitAndCancelButton
