import * as yup from "yup"
import {
  ColumnCurrencyOptions,
  ColumnDataTypeOptions,
  ColumnFilterTypeOptions,
  PaddingOptions,
  createEditColumnFieldNames
} from "./Constants/CreateEditColumnConstant"
import {
  PromptTypes,
  numberTypeErr,
  oneOfErr,
  requiredErr
} from "src/utils/constants"
import { SQLTestColumnSliceType } from "src/redux/slices/ConfigPortal/ColumnSlice"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
//istanbul ignore next
export const validationSchema = (
  columnsRedux: SQLTestColumnSliceType["columnsRedux"],
  tab: string
) => {
  return yup.object().shape({
    [createEditColumnFieldNames.DataSource.name]: yup
      .string()
      .required(requiredErr)
      .oneOf(
        ["", ...(columnsRedux.columns || []).map(item => item.ColumnName)],
        oneOfErr
      ),
    [createEditColumnFieldNames.ColumnName.name]: yup
      .string()
      .max(30, "Column name can not be more than 30")
      .required(requiredErr),
    [createEditColumnFieldNames.DataType.name]: yup
      .string()
      .oneOf(["", ...ColumnDataTypeOptions.map(item => item.value)], oneOfErr),
    [createEditColumnFieldNames.NumberOfDecimals.name]: yup
      .number()
      .typeError(numberTypeErr)
      .when([createEditColumnFieldNames.DataType.name], {
        is: Type => Type && Type === PromptTypes.Decimal,
        then: yup.number().typeError(numberTypeErr).required(requiredErr)
      }),
    [createEditColumnFieldNames.CurrencySymbol.name]: yup
      .string()
      .oneOf(["", ...ColumnCurrencyOptions.map(item => item.value)], oneOfErr)
      .when([createEditColumnFieldNames.DataType.name], {
        is: Type => Type && Type === PromptTypes.Decimal,
        then: yup.string()
      }),
    ...([ConfigTabNames.Reports.toLocaleLowerCase()].includes(
      tab.toLocaleLowerCase()
    ) && {
      [createEditColumnFieldNames.Weight.name]: yup
        .number()
        .typeError(numberTypeErr)
        .required(requiredErr),
      [createEditColumnFieldNames.SortType.name]: yup
        .string()
        .oneOf(
          ["", ...ColumnDataTypeOptions.map(item => item.value)],
          oneOfErr
        ),
      [createEditColumnFieldNames.FilterType.name]: yup
        .string()
        .oneOf(
          ["", ...ColumnFilterTypeOptions.map(item => item.value)],
          oneOfErr
        )
    }),
    ...([
      ConfigTabNames.Outputs.toLocaleLowerCase(),
      ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
    ].includes(tab.toLocaleLowerCase()) && {
      Padding: yup.object().shape({
        Format: yup
          .string()
          .oneOf(["", ...PaddingOptions.map(item => item.value)], oneOfErr),
        Length: yup.mixed().when(["Format"], {
          is: Format =>
            Format && [PromptTypes.LEFT, PromptTypes.RIGHT].includes(Format),
          then: yup.number().typeError(numberTypeErr).required(requiredErr)
        }),
        Character: yup.string().when(["Format"], {
          is: Format =>
            Format && [PromptTypes.LEFT, PromptTypes.RIGHT].includes(Format),
          then: yup.string().required(requiredErr)
        })
      }),
      [createEditColumnFieldNames.SurroundCharacter.name]: yup.string()
    })
  })
}
