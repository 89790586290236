import { Grid, Alert, AlertTitle, Snackbar, Typography } from "@mui/material"
import csvIcon from "../../assets/images/CsvIcon.svg"
import WarningIcon from "@mui/icons-material/Warning"

import React, { useState, useEffect } from "react"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useDropzone } from "react-dropzone"
import { setBulkScheduleFile } from "src/redux/slices/scheduleSlice"
import { Translates } from "src/i18n/i18n"
import ScheduleBulkFIleUpload from "../Component/BulkFIleUpload"
import { BULK_UPLOADING } from "src/utils/constants"

import {
  createURLFromByteArray,
  fileExtensionCheck,
  getTranslations,
  processCSV
} from "src/utils/helper"
const CreateScheduleBulk = ({ setPage }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [progress, setProgressData] = useState(0)
  const dispatch = useAppThunkDispatch()
  const [showPopup, setShowPopup] = useState(false)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const scheduleCsvData = useSelector(
    (state: RootState) => state.schedule?.scheduleFileData
  )
  const template = useSelector(
    (state: RootState) => state.auth?.templates.SCHEDULE
  )
  useEffect(() => {
    if (Array.isArray(scheduleCsvData) && scheduleCsvData.length === 0) {
      setAlertError({
        title: getTranslations(t, Translates.No_data_found),
        content: getTranslations(
          t,
          Translates.Please_ensure_the_file_you_are_uploading_contains_data
        )
      })
    }
  }, [scheduleCsvData])

  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 3000
  })
  const bulkScheduleFilePath = useSelector(
    (state: RootState) => state.schedule?.bulkScheduleFilePath
  )
  const [alertError, setAlertError] = React.useState({ title: "", content: "" })
  const [files, setFiles] = useState([] as any)

  const onSnackClose = () => {
    setSnackBar({ ...snackBar, open: false })
  }
  const { getRootProps } = useDropzone({
    multiple: false,
    accept: {
      "text/csv": [".csv"]
    },
    onError: error => {},
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        setAlertError(oldVal => ({
          ...oldVal,
          severity: "error",
          title: getTranslations(t, Translates.Error),
          content: getTranslations(t, Translates.File_type_must_be_csv)
        }))
      }
      // istanbul ignore next
      else if (acceptedFiles.length) {
        const fileName: string = acceptedFiles[0].name
        let errorFind = 0
        if (!fileExtensionCheck({ fileName, setAlertError, t })) {
          errorFind++
        }
        if (errorFind <= 0) {
          setAlertError({ title: "", content: "" })
          setFiles(acceptedFiles)
          const reader = new FileReader()
          dispatch(setBulkScheduleFile(fileName))
          reader.addEventListener("progress", event => {
            if (event.loaded && event.total) {
              const percent = (event.loaded / event.total) * 100
              setProgressData(percent)
            }
          })
          reader.onload = async ({ target }) => {
            // istanbul ignore next

            if (target?.result) {
              processCSV(
                target?.result,
                dispatch,
                BULK_UPLOADING.SCHEDULE_FILE_UPLOADING
              )
            }
          }
          reader.readAsText(acceptedFiles[0])
        }
      }
    }
  })

  const handleDataMapping = () => {
    setPage("page2")
  }

  const handleCsvFile = () => {
    return (
      <div>
        <img
          src={csvIcon}
          className="mt-5 upload-file__icon "
          style={{ cursor: "pointer" }}
        />
        <Typography variant="body2">
          <strong>{files[0]?.path || bulkScheduleFilePath}</strong>{" "}
          {getTranslations(t, Translates.file_uploaded)}
        </Typography>
      </div>
    )
  }

  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.SCHEDULE)
      }, 3000)
    }
  }
  const onDeleteClick = e => {
    e.stopPropagation()
    setShowPopup(true)
  }
  const onClearClick = e => {
    e.stopPropagation()
    setShowPopup(false)
  }
  const onDeleteFileClick = e => {
    e.stopPropagation()
    setShowPopup(false)
    setFiles([])
    setAlertError({ title: "", content: "" })
    dispatch(setBulkScheduleFile(""))
  }
  const handleCancelClick = () => {
    history.push(PATH.SCHEDULE_CREATE_TYPE)
  }
  const saveDisable = () => {
    if (bulkScheduleFilePath && alertError?.title === "") {
      return false
    } else {
      return true
    }
  }
  const showError = () => {
    return (
      <Alert
        severity="error"
        icon={<WarningIcon />}
        data-testid="error-alert"
        className="grey custom-alert"
      >
        <AlertTitle className="custom-alert__title" data-testid="alert-title">
          {alertError?.title}
        </AlertTitle>
        <p data-testid="alert-content">{alertError?.content}</p>
      </Alert>
    )
  }

  return (
    <>
      <Grid container>
        <MiniHeader
          multiCustomer={handleNoCustomerId}
          headerTitle={getTranslations(t, Translates.UPLOAD_SCHEDULES)}
        />
        <Grid container className="inner-view">
          <Grid item className="main-container" xs={12}>
            <ScheduleBulkFIleUpload
              onClearClick={onClearClick}
              bulkLocationFilePath={bulkScheduleFilePath}
              progress={progress}
              files={files}
              showPopup={showPopup}
              onDeleteFileClick={onDeleteFileClick}
              getRootProps={getRootProps}
              handleDataMapping={handleDataMapping}
              handleCsvFile={handleCsvFile}
              url={createURLFromByteArray(template)}
              onDeleteClick={onDeleteClick}
              typeOfFile={BULK_UPLOADING.SCHEDULE_FILE_UPLOADING}
              handleCancelClick={handleCancelClick}
              saveDisable={saveDisable}
              alertError={alertError}
              showError={showError}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        {...snackBar}
        onClose={onSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  )
}
export default React.memo(CreateScheduleBulk)
