import DeviceBg from "../../../src/assets/images/DeviceReservationBg.svg"
import { Grid, Button } from "@mui/material"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../src/constants/paths"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

const ScheduleBanner = () => {
  const {t}=useTranslation<any>()
  const history = useHistory()
  const handleClickOpen = () => {
    history.push(PATH.SCHEDULE_CREATE_TYPE)
  }
  return (   
      <Grid container className="custom-banner">
        <Grid item sm={7}>
          <h2>{getTranslations(t, Translates.Add_your_Schedule)}</h2>
          <p>{getTranslations(t, Translates.Schedule_an_Event_for_your_Store_Locations)}.</p>
          <Button
            variant="contained"
            className="primary-btn mt-3 px-4"
            data-testid="newSchedule"
            onClick={handleClickOpen}
          >
           {getTranslations(t, Translates.Add_new_Schedule)}
          </Button>
        </Grid>
        <Grid item sm={5} className="text-right">
          <img width={"248px"} height={"153px"} src={DeviceBg} alt="device reservation" />
        </Grid>
      </Grid>    
  )
}

export default ScheduleBanner
