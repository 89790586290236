import { Grid } from "@mui/material"
import React from "react"
import { useFormContext } from "react-hook-form"
import {
  conditionBasedReturnFieldComponent,
  handleFieldChangeRevalidation
} from "../FunctionHelper"

const FormFieldConditionRender = ({
  FieldDetails,
  checkFieldRenderCondition
}: {
  FieldDetails: any
  checkFieldRenderCondition?: Function
}) => {
  const formHookMethods = useFormContext()

  const { watch } = formHookMethods

  const RenderCondition = FieldDetail => {
    if (checkFieldRenderCondition) {
      return checkFieldRenderCondition(FieldDetail)
    } else {
      let display: boolean = true
      if (FieldDetail.condition) {
        display = [FieldDetail.condition.value]
          .flat(Infinity)
          .includes(watch(FieldDetail.condition.name))
      }
      return display
    }
  }

  const OnChangeHandler = FieldDetail => {
    handleFieldChangeRevalidation({
      FieldDetail,
      formHookMethods
    })
  }

  return (
    <Grid container>
      {Object.keys(FieldDetails).map((key: string, inx: number) => {
        const sm = FieldDetails[key]?.parentCols?.sm
        const sm1 = FieldDetails[key]?.childCols?.sm
        return (
          <React.Fragment key={FieldDetails[key]?.props?.name}>
            {RenderCondition(FieldDetails[key]) ? (
              <>
                {FieldDetails[key]?.isNewRow ? (
                  <Grid item sm={12}></Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  sm={sm ?? 12}
                  className={`${FieldDetails[key]?.parentCols?.className}`}
                >
                  <Grid container>
                    <Grid item xs={12} sm={sm1 ?? 3}>
                      {conditionBasedReturnFieldComponent(
                        FieldDetails[key],
                        formHookMethods,
                        OnChangeHandler
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

export default FormFieldConditionRender
