import * as yup from "yup"
import {
  RadioButtonLists,
  ReportFieldName,
  SortList,
  VarianceTypes,
  singleFieldNames
} from "./Constants"
import { numberTypeErr, oneOfErr, requiredErr } from "src/utils/constants"
import { getTranslations, validateInputString } from "src/utils/helper"

const ThresholdOptions: string[] = Object.values(
  RadioButtonLists.Thresholds
).map(item => item.value)

const ThresholdTypeOptions: string[] = Object.values(
  RadioButtonLists.ThresholdSelects
).map(item => item.value)

const SortListOptions: string[] = SortList.map(i => i.value)
export const validationSchema = (Translates, t) => {
  const GroupVarianceValidation = getVarianceValidation(
    VarianceTypes.GroupVariance.name,
    Translates,
    t
  )
  const FullVarianceValidation = getVarianceValidation(
    VarianceTypes.FullVariance.name,
    Translates,
    t
  )
  return yup.object().shape({
    ...GroupVarianceValidation,
    ...FullVarianceValidation
  })
}

const getVarianceValidation = (varianceType, Translates, t) => {
  return {
    [varianceType]: yup.object().shape({
      [singleFieldNames.VarianceName]: getSingleFieldValidation().test(
        singleFieldNames.VarianceName,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        function (value) {
          const isEnable = this.parent[singleFieldNames.IsEnable]
          return !isEnable || validateInputString(value ?? "")
        }
      ),
      [singleFieldNames.Sort]: getSingleFieldValidation(true, SortListOptions),
      [singleFieldNames.BatchSize]: yup
        .number()
        .typeError(numberTypeErr)
        .when([singleFieldNames.IsBatchSize], {
          is: IsBatchSize => IsBatchSize,
          then: yup.number().typeError(numberTypeErr).required(requiredErr)
        }),
      [singleFieldNames.Threshold]: getSingleFieldValidation(
        true,
        ThresholdOptions
      ),
      [singleFieldNames.QtyThreshold]: getQtyExtThresholdValidation(
        RadioButtonLists.Thresholds.Ext.value
      ),
      [singleFieldNames.ExtThreshold]: getQtyExtThresholdValidation(
        RadioButtonLists.Thresholds.Qty.value
      ),
      [singleFieldNames.QtyConstant]: getQtyExtThresholdConstantValidation(
        singleFieldNames.QtyThreshold,
        RadioButtonLists.Thresholds.Ext.value
      ),
      [singleFieldNames.ExtConstant]: getQtyExtThresholdConstantValidation(
        singleFieldNames.ExtThreshold,
        RadioButtonLists.Thresholds.Qty.value
      )
    })
  }
}

const getSingleFieldValidation = (
  isOneOf: boolean = false,
  options: string[] = []
) => {
  let locYup = yup.string().when(singleFieldNames.IsEnable, {
    is: value => value,
    then: yup.string().required(requiredErr)
  })

  if (isOneOf) {
    locYup = locYup.oneOf(["", ...options], oneOfErr)
  }

  return locYup
}

const getQtyExtThresholdValidation = ThresholdNotEqualText => {
  return yup
    .string()
    .when([singleFieldNames.IsEnable, singleFieldNames.Threshold], {
      is: (IsEnable, Threshold) =>
        IsEnable && Threshold && Threshold !== ThresholdNotEqualText,
      then: yup.string().required(requiredErr)
    })
    .oneOf(["", ...ThresholdTypeOptions], oneOfErr)
}

const getQtyExtThresholdConstantValidation = (
  ThresholdTypeName,
  ThresholdNotEqualText
) => {
  return yup
    .number()
    .typeError(numberTypeErr)
    .when(
      [
        singleFieldNames.IsEnable,
        singleFieldNames.Threshold,
        ThresholdTypeName
      ],
      {
        is: (IsEnable, Threshold, ThresholdType) =>
          IsEnable &&
          Threshold &&
          Threshold !== ThresholdNotEqualText &&
          ThresholdType &&
          ThresholdType === RadioButtonLists.ThresholdSelects.Constant.value,
        then: yup.number().typeError(numberTypeErr).required(requiredErr)
      }
    )
}

export const reportFieldValidationSchema = (fieldOptions, t, Translates) => {
  return yup.object().shape({
    [ReportFieldName.Field.name]: yup
      .string()
      .required(requiredErr)
      .oneOf(["", ...fieldOptions.map(item => item.value)], oneOfErr),
    [ReportFieldName.Name.name]: yup
      .string()
      .required(requiredErr)
      .test(
        ReportFieldName.Name.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      )
  })
}
