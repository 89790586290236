import CreateEditColumn from "src/configurationPortal/pages/Components/Columns/CreateEditColumn"
import CreateEditPrompt from "src/configurationPortal/pages/Components/Prompts/CreateEditPrompt"
import EditSQLEditor from "src/configurationPortal/pages/Components/SQLEditor/EditSQLEditor"
import Reports from "src/configurationPortal/pages/Reports"
import CreateEditReport from "src/configurationPortal/pages/Reports/CreateEditReport"
import CreateEditOutputBundle from "src/configurationPortal/pages/OutputBundles/CreateEditOutputBundle"
import Variances from "src/configurationPortal/pages/Variances"
import CreateEditReportField from "src/configurationPortal/pages/Variances/CreateEditReportField"
import {
  PATH,
  configColumnPaths,
  configPromptPaths,
  configSQLEditorPaths,
  configSignaturePaths,
  configTotalPaths,
  multiEventOutputRouteReplaceVar
} from "src/constants/paths"
import CreateEditTotal from "src/configurationPortal/pages/Components/Totals/CreateEditTotal"
import CreateEditMultiEventOutput from "src/pages/MultiEventOutputs/CreateEditMultiEventOutput"
import ConfigurationPortal from "src/configurationPortal/pages/ConfigurationPortal"
import CreateEditSignature from "src/configurationPortal/pages/Components/Signatures/CreateEditSignature"
import ConfigurationHistory from "src/pages/Configuration/ConfigurationHistory"
import { FEATURES, PERMISSIONS } from "src/utils/constants"

const configPortal = {
  variancesRoutes: [
    {
      path: PATH.VARIANCES,
      renderComponent: <Variances />,
      isInsideCP: true
    },
    [PATH.VARIANCES_REPORT_CREATE, PATH.VARIANCES_REPORT_EDIT].map(i => ({
      path: i,
      renderComponent: <CreateEditReportField />,
      isInsideCP: true
    }))
  ].flat(Infinity),
  reportsRoutes: [
    {
      path: PATH.REPORTS,
      renderComponent: <Reports />,
      isInsideCP: true
    },
    [PATH.REPORT_CREATE, PATH.REPORT_EDIT].map(i => ({
      path: i,
      renderComponent: <CreateEditReport />,
      isInsideCP: true
    }))
  ].flat(Infinity),
  promptsRoutes: [...Object.values(configPromptPaths).map(i => i)].map(i => ({
    path: i,
    renderComponent: <CreateEditPrompt />,
    isInsideCP: true
  })),
  sqlEditorRoutes: [
    ...Object.values(configSQLEditorPaths).map(i => i),
    ...Object.values(configSQLEditorPaths).map(i =>
      i.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    )
  ].map(i => ({
    path: i,
    renderComponent: <EditSQLEditor />,
    isInsideCP: false
  })),
  columnsRoutes: [
    ...Object.values(configColumnPaths).map(i => i),
    ...Object.values(configColumnPaths).map(i =>
      i.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    )
  ].map((i, j) => ({
    path: i,
    renderComponent: <CreateEditColumn />,
    isInsideCP: i.includes(multiEventOutputRouteReplaceVar.Configuration),
    isInsideML: !i.includes(multiEventOutputRouteReplaceVar.Configuration)
  })),
  signaturesRoutes: [...Object.values(configSignaturePaths).map(i => i)].map(
    i => ({
      path: i,
      renderComponent: <CreateEditSignature />,
      isInsideCP: true
    })
  ),
  outputBundlesRoutes: [PATH.OUTPUT_BUNDLE_CREATE, PATH.OUTPUT_BUNDLE_EDIT].map(
    i => ({
      path: i,
      renderComponent: <CreateEditOutputBundle />,
      isInsideCP: true
    })
  ),
  totalsRoutes: [
    ...Object.values(configTotalPaths).map(i => i),
    ...Object.values(configTotalPaths).map(i =>
      i.replace(
        multiEventOutputRouteReplaceVar.Configuration,
        multiEventOutputRouteReplaceVar.CreateSchedule
      )
    )
  ].map((i, j) => ({
    path: i,
    renderComponent: <CreateEditTotal />,
    isInsideCP: i.includes(multiEventOutputRouteReplaceVar.Configuration),
    isInsideML: !i.includes(multiEventOutputRouteReplaceVar.Configuration)
  }))
}

const configRoutes = [...Object.values(configPortal).map(i => i)].flat(Infinity)

const customerPortal = {
  multiEventOutputsRoutes: [
    {
      path: PATH.MULTI_EVENT_OUTPUTS,
      renderComponent: <CreateEditMultiEventOutput />,
      isInsideCP: false,
      isInsideML: true
    },
    [PATH.MULTI_EVENT_OUTPUT_CREATE, PATH.MULTI_EVENT_OUTPUT_EDIT].map(i => ({
      path: i,
      renderComponent: <CreateEditMultiEventOutput />,
      isInsideCP: false,
      isInsideML: true
    }))
  ].flat(Infinity),
  configHistoryRoutes: [
    {
      path: PATH.CONFIG_VIEW_HISTORY,
      renderComponent: <ConfigurationHistory />,
      isInsideCP: false,
      isInsideML: true,
      permissions: PERMISSIONS.VIEW,
      feature: FEATURES.CONFIGURATION
    }
  ]
}

const customerRoutes = [...Object.values(customerPortal).map(i => i)].flat(
  Infinity
)

const configurationPortalFileConditionRoutes = [
  [
    PATH.CREATE_EDIT_COLUMN,
    PATH.CATEGORIES,
    PATH.CREATE_CATEGORY,
    PATH.EDIT_CATEGORY,
    PATH.CONFIGURATION_COUNT_FIELDS,
    PATH.CONFIGURATION_COUNT_FIELDS_LOCATION,
    PATH.CONFIGURATION_COUNT_FIELDS_PRODUCT,
    PATH.CREATE_REPORTING,
    PATH.EDIT_REPORTING,
    PATH.CREATE_REPORTING_PROMPT,
    PATH.CONFIG_LOOKUP_ROUTINES,
    PATH.CONFIG_NEW_LOOKUP_ROUTINE,
    PATH.CREATE_LOOKUP,
    PATH.COUNT_FIELDS_INSTRUCTIONS,
    PATH.OUTPUT_FILES,
    PATH.CREATE_EDIT_OUTPUTFILES,
    PATH.OUTPUTPROMPTS,
    PATH.AUDITS,
    PATH.CREATE_AUDIT,
    PATH.CREATE_EDIT_PROMPTS,
    PATH.CREATE_COUNT_PROGRAMS,
    PATH.EDIT_COUNT_PROGRAMS,
    PATH.COUNT_PROGRAMS,
    PATH.EDIT_COLUMNS,
    PATH.INPUT_FILES,
    PATH.SELECT_INPUT_FILE_TYPE,
    PATH.CREATE_EDIT_AUXILIARY_INPUT_FILE,
    PATH.CREATE_EDIT_CUSTOMER_INPUT_FILE,
    PATH.CREATE_EDIT_REPORT_SUBTOTALS,
    PATH.CREATE_EDIT_REPORT_GRAND_TOTALS,
    PATH.CREATE_INPUT_FIELDS,
    PATH.CREATE_EDIT_OUTPUT_SUBTOTALS,
    PATH.CREATE_EDIT_OUTPUT_GRAND_TOTALS,
    PATH.AUDITS_INSTRUCTIONS,
    PATH.COUNT_PROGRAM_INSTRUCTIONS,
    PATH.EDIT_REPORTING_PROMPT
  ].map(i => ({
    path: i,
    renderComponent: <ConfigurationPortal />,
    isInsideCP: false,
    isInsideML: false
  }))
].flat(Infinity)

export const overAllRoutes = [
  ...configRoutes,
  ...customerRoutes,
  ...configurationPortalFileConditionRoutes
]
