import { PERMISSIONS } from "src/utils/constants"
import { getPermissionsForUser } from "../../utils/helper"
import React, { useEffect, useState } from "react"

function PermissionsAccessGate({
  children,
  feature,
  permissionKey,
  restriction = null as any
}) {
  const [userPermissions, setUserPermissions] = useState([] as any)
  useEffect(() => {
    const _dt = getPermissionsForUser()
    let _data = (_dt || []).map((m: { Permissions: any[] }) => {
      return {
        ...m,
        Permissions: m?.Permissions?.map(e => e.toString().toLowerCase())
      }
    })

    setUserPermissions([..._data])
  }, [])

  const hasPermission = (feature, permissionKey) => {
    if (
      feature &&
      userPermissions.length > 0 &&
      !userPermissions
        .find(
          (f: { Feature: string }) =>
            f.Feature?.toLowerCase() === feature.toString().toLowerCase()
        )
        ?.Permissions.includes(permissionKey || "")
    ) {
      return false
    } else {
      return true
    }
  }

  const checkRestriction = () => {
    if (restriction) {
      const _children = {
        ...children,
        props: { ...children.props, ...restriction }
      }
      return _children
    } else {
      return <></>
    }
  }

  const permissionGranted = hasPermission(feature, permissionKey)

  if (
    !permissionGranted &&
    permissionKey &&
    [
      PERMISSIONS.UPDATE,
      PERMISSIONS.CREATE,
      PERMISSIONS.DOWNLOAD,
      PERMISSIONS.VIEW
    ].includes(permissionKey.toString().toLowerCase())
  ) {
    return checkRestriction()
  }

  return <>{children}</>
}

export default React.memo(PermissionsAccessGate)
