import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface CustomerSelectionSliceType {
  modalOpen: boolean
  isRedirectHome: boolean
}

const initialState: CustomerSelectionSliceType = {
  modalOpen: false,
  isRedirectHome: false
}
export const CustomerSelectionSlice = createSlice({
  name: "CustomerSelection",
  initialState,
  reducers: {
    setModalOpen: (state, _action: PayloadAction<any>) => {
      state.modalOpen = _action.payload
    },
    setIsRedirectHome: (state, _action: PayloadAction<any>) => {
      state.isRedirectHome = _action.payload
    }
  },
  extraReducers: builder => {}
})
export const { setModalOpen, setIsRedirectHome } =
  CustomerSelectionSlice.actions
export default CustomerSelectionSlice.reducer
