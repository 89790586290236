import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import EditIcon from "src/assets/images/Edit.png"
import TrashIcon from "src/assets/images/TrashIcon.svg"
import AddIcon from "@mui/icons-material/Add"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { Warning } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

const displaySpan = (props: any) => {
  return (
    <span className="d-block text-left">{props.value ? props.value : ""}</span>
  )
}

const commonValues = {
  headerClassName: "temp_class",
  disableFilters: true,
  disableSortBy: true,
  setWidth: 250,
  Cell: props => {
    return displaySpan(props)
  }
}

const columnHeadersList: any = [
  {
    ...{
      Header: "Sort",
      accessor: "SortOrder"
    },
    ...commonValues,
    ...{ setWidth: 50 }
  },
  {
    ...{ Header: "Prompt", accessor: "Label[0][en-us]" },
    ...commonValues
  },
  {
    ...{ Header: "Variable Name", accessor: "VariableName" },
    ...commonValues
  },
  {
    ...{ Header: "Type", accessor: "Type" },
    ...commonValues
  },
  {
    ...{ Header: "Default Value", accessor: "DefaultValue" },
    ...commonValues
  }
]
export const columnData = (
  handleEditPromptClick,
  deletePrompt,
  t,
  configTypeFromStore
) => {
  return [
    columnHeadersList?.map(id => {
      return {
        Header: getTranslations(t, id.Header),
        accessor: id.accessor,
        headerClassName: id.headerClassName,
        disableFilters: id.disableFilters,
        disableSortBy: id.disableSortBy,
        setWidth: id.setWidth,
        Cell: id.Cell
      }
    }),
    {
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      headerClassName: "temp_class",
      disableFilters: true,
      disableSortBy: true,
      setWidth: 150,
      Cell: props => {
        return (
          <span className="action-btns d-block">
            <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
              <IconButton
                onClick={() =>
                  handleEditPromptClick({
                    ...props.row.original,
                    ...{ id: props.row.id }
                  })
                }
              >
                <img src={EditIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
              <span>
                <IconButton
                  onClick={() => deletePrompt(props.row.original)}
                  disabled={configTypeFromStore === "view"}
                  className={`mr-1 ${
                    configTypeFromStore === "view" ? "disable-img" : "pointer"
                  }`}
                >
                  <img src={TrashIcon} />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        )
      }
    }
  ].flat(Infinity)
}

export const Loader = () => {
  return (
    <>
      <Box
        key={"loader"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "32px"
        }}
        data-testid="loader"
      >
        <CircularProgress />
      </Box>
    </>
  )
}

const setContent = (preDeleteRow, t) => {
  return (
    <Alert severity="error" icon={<Warning />} className="custom-alert">
      <AlertTitle className="alert__title">
        <h2>{getTranslations(t, Translates.Warning)}</h2>
      </AlertTitle>
      <p>
        {" "}
        <strong className="d-block">{`${getTranslations(
          t,
          Translates.Are_you_sure_you_want_to_delete
        )} ${preDeleteRow?.Label[0]["en-us"]} ${getTranslations(
          t,
          Translates.Prompt
        )}?`}</strong>
      </p>
    </Alert>
  )
}

export const PromptRender = (
  loading,
  colnsData,
  handlePromptClick,
  reportPromptData,
  newReportPrompts,
  alertParams,
  configTypeFromStore
) => {
  const { isConfirmOpen, callbackConfirmation, preDeleteRow } = alertParams
  const { t } = useTranslation<any>()
  return (
    <>
      <Grid
        container
        className="custom-table-responsive categories-table"
        data-testid="reportings-prompt-display-grid"
      >
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />
          <Grid container>
            <Grid item xs={6}>
              <h4>{getTranslations(t, Translates.PROMPTS)}</h4>
            </Grid>
            <Grid item xs={6} className="text-right">
              <Button
                variant="contained"
                data-testid="reportpromptCreate"
                className="primary-btn pa-2"
                onClick={handlePromptClick}
                disabled={configTypeFromStore === "view" ? true : false}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Prompt)}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} className="mt-2">
              {loading ? (
                <Loader />
              ) : (
                <Grid xs={12} item>
                  <GlobalTableDragDrop
                    component={TableComponentNames.reportPrompts}
                    columns={colnsData}
                    data={reportPromptData || []}
                    noDataText={
                      newReportPrompts?.length &&
                      newReportPrompts.length !== 0 ? (
                        <Loader />
                      ) : (
                        `${getTranslations(
                          t,
                          Translates.No_Prompts_data_available
                        )}`
                      )
                    }
                  ></GlobalTableDragDrop>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isConfirmOpen && (
        <ConfirmDialog
          open={isConfirmOpen}
          callBack={callbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setContent(preDeleteRow, t)}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.Cancel)}
        ></ConfirmDialog>
      )}
    </>
  )
}
