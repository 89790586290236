import { Grid } from "@mui/material"
import ForwardIcon from "@mui/icons-material/Forward"
import React, { useEffect, useState } from "react"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { useTranslation } from "react-i18next"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { Translates } from "src/i18n/i18n"
import { encryptBulkColumns, getTranslations } from "src/utils/helper"
import BulkDataSelectField from "../Component/BulkDataSelectField"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import LocationAndScheduleTopHeadings from "../Component/LocationAndScheduleTopHeadings"
import {
  FILE_TYPE,
  PAGES,
  UPLOADFILE_FIELD_MAP,
  UPLOAD_KEYS
} from "../Location/UploadFileHelper"
import {
  setUploadFileMappingData,
  setUploadFileMappingPayloadData,
  setUploadFileReset
} from "src/redux/slices/uploadFileSlice"
import { PromptTypes } from "src/utils/constants"

const UploadFileBulkDataMapping = ({ setPage, FileType, Row }) => {
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const [bulkData, setBulkData] = useState([] as any)

  const rowData = Row && Object.keys(Row).length ? JSON.parse(Row) : {}

  const uploadFileData: any = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFilesFullData
  )
  const uploadFileMappingData: any = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFileMappingData
  )
  const csvUploadFileColumnListDeparment: any = useSelector(
    (state: RootState) => state.uploadFiles?.csvUploadFileColumnListDepartment
  )
  const csvUploadFileColumnListVariance: any = useSelector(
    (state: RootState) => state.uploadFiles?.csvUploadFileColumnListVariance
  )
  const csvUploadFileColumnListAreaRange: any = useSelector(
    (state: RootState) => state.uploadFiles?.csvUploadFileColumnListAreaRange
  )

  const [fileDataString, setFileDataString] = useState("" as any)
  const getColumnHeader = (value: any) => {
    let csvColumnList: any
    if (FileType === FILE_TYPE.Department) {
      csvColumnList = csvUploadFileColumnListDeparment
    } else if (FileType === FILE_TYPE.AreaRangeFile) {
      csvColumnList = csvUploadFileColumnListAreaRange
    } else {
      csvColumnList = csvUploadFileColumnListVariance
    }

    if (csvColumnList?.includes(value)) {
      return true
    } else {
      return false
    }
  }
  const getIndividualRecordDetail = val => {
    if (FileType === FILE_TYPE.Department) {
      if (val === UPLOADFILE_FIELD_MAP.departmentGroup) {
        if (uploadFileMappingData?.departmentGroup) {
          return uploadFileMappingData.departmentGroup
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.departmentGroup)
            ? UPLOADFILE_FIELD_MAP.departmentGroup
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.departmentName) {
        if (uploadFileMappingData?.departmentName) {
          return uploadFileMappingData.departmentName
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.departmentName)
            ? UPLOADFILE_FIELD_MAP.departmentName
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.departmentParentName) {
        if (uploadFileMappingData?.departmentParentName) {
          return uploadFileMappingData.departmentParentName
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.departmentParentName)
            ? UPLOADFILE_FIELD_MAP.departmentParentName
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.departmentParentSort) {
        if (uploadFileMappingData?.departmentParentSort) {
          return uploadFileMappingData.departmentParentSort
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.departmentParentSort)
            ? UPLOADFILE_FIELD_MAP.departmentParentSort
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.departmentSort) {
        if (uploadFileMappingData?.departmentSort) {
          return uploadFileMappingData.departmentSort
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.departmentSort)
            ? UPLOADFILE_FIELD_MAP.departmentSort
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.departmentParent) {
        if (uploadFileMappingData?.departmentParent) {
          return uploadFileMappingData.departmentParent
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.departmentParent)
            ? UPLOADFILE_FIELD_MAP.departmentParent
            : ""
      }
    } else if (FileType === FILE_TYPE.AreaRangeFile) {
      console.log(val)
      if (val === UPLOADFILE_FIELD_MAP.categoryName) {
        if (uploadFileMappingData?.categoryName) {
          return uploadFileMappingData.categoryName
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.categoryName)
            ? UPLOADFILE_FIELD_MAP.categoryName
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.rangeName) {
        if (uploadFileMappingData?.rangeName) {
          return uploadFileMappingData.rangeName
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.rangeName)
            ? UPLOADFILE_FIELD_MAP.rangeName
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.areaRangeSort) {
        if (uploadFileMappingData?.areaRangeSort) {
          return uploadFileMappingData.areaRangeSort
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.areaRangeSort)
            ? UPLOADFILE_FIELD_MAP.areaRangeSort
            : ""
      }
    } else {
      if (val === UPLOADFILE_FIELD_MAP.varianceGroup) {
        if (uploadFileMappingData?.varianceGroup) {
          return uploadFileMappingData.varianceGroup
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.varianceGroup)
            ? UPLOADFILE_FIELD_MAP.varianceGroup
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.varianceGroupName) {
        if (uploadFileMappingData?.varianceGroupName) {
          return uploadFileMappingData.varianceGroupName
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.varianceGroupName)
            ? UPLOADFILE_FIELD_MAP.varianceGroupName
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.sortOrder) {
        if (uploadFileMappingData?.sortOrder) {
          return uploadFileMappingData.sortOrder
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.sortOrder)
            ? UPLOADFILE_FIELD_MAP.sortOrder
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.qtyTolerance) {
        if (uploadFileMappingData?.qtyTolerance) {
          return uploadFileMappingData.qtyTolerance
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.qtyTolerance)
            ? UPLOADFILE_FIELD_MAP.qtyTolerance
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.fullStoreQtyTolerance) {
        if (uploadFileMappingData?.fullStoreQtyTolerance) {
          return uploadFileMappingData.fullStoreQtyTolerance
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.fullStoreQtyTolerance)
            ? UPLOADFILE_FIELD_MAP.fullStoreQtyTolerance
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.extTolerance) {
        if (uploadFileMappingData?.extTolerance) {
          return uploadFileMappingData.extTolerance
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.extTolerance)
            ? UPLOADFILE_FIELD_MAP.extTolerance
            : ""
      } else if (val === UPLOADFILE_FIELD_MAP.fullStoreExtTolerance) {
        if (uploadFileMappingData?.fullStoreExtTolerance) {
          return uploadFileMappingData.fullStoreExtTolerance
        } else
          return getColumnHeader(UPLOADFILE_FIELD_MAP.fullStoreExtTolerance)
            ? UPLOADFILE_FIELD_MAP.fullStoreExtTolerance
            : ""
      }
    }
  }
  const getUploadFileDetails = () => {
    if (FileType === FILE_TYPE.Department) {
      return {
        [UPLOAD_KEYS.departmentGroup]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.departmentGroup
        ),
        [UPLOAD_KEYS.departmentName]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.departmentName
        ),
        [UPLOAD_KEYS.departmentSort]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.departmentSort
        ),
        [UPLOAD_KEYS.departmentParentName]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.departmentParentName
        ),
        [UPLOAD_KEYS.departmentParentSort]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.departmentParentSort
        ),
        [UPLOAD_KEYS.departmentParent]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.departmentParent
        )
      }
    } else if (FileType === FILE_TYPE.AreaRangeFile) {
      return {
        [UPLOAD_KEYS.categoryName]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.categoryName
        ),
        [UPLOAD_KEYS.rangeName]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.rangeName
        ),
        [UPLOAD_KEYS.areaRangeSort]: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.areaRangeSort
        )
      }
    } else {
      return {
        varianceGroup: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.varianceGroup
        ),
        varianceGroupName: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.varianceGroupName
        ),
        sortOrder: getIndividualRecordDetail(UPLOADFILE_FIELD_MAP.sortOrder),
        qtyTolerance: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.qtyTolerance
        ),
        fullStoreQtyTolerance: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.fullStoreQtyTolerance
        ),
        extTolerance: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.extTolerance
        ),
        fullStoreExtTolerance: getIndividualRecordDetail(
          UPLOADFILE_FIELD_MAP.fullStoreExtTolerance
        )
      }
    }
  }

  const [uploadFileDetails, setUploadFileDetails] = useState<any>(
    getUploadFileDetails()
  )
  useEffect(() => {
    dispatch(setUploadFileMappingData(uploadFileDetails))
  }, [uploadFileDetails])

  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  useEffect(() => {
    dispatch(setUploadFileMappingData(""))
    getFileData()
  }, [])

  const getFileData = () => {
    setFileDataString(uploadFileData)
    let keys: any
    if (FileType === FILE_TYPE.Department) {
      keys = csvUploadFileColumnListDeparment
    } else if (FileType === FILE_TYPE.AreaRangeFile) {
      keys = csvUploadFileColumnListAreaRange
    } else {
      keys = csvUploadFileColumnListVariance
    }
    setBulkData(keys)
    setUploadFileDetails(getUploadFileDetails())
  }

  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.UPLOADFILES)
      }, 3000)
    }
  }

  const handleChangeField = (e, field: any) => {
    setUploadFileDetails(oldVal => ({
      ...oldVal,
      [field]: e.target.value
    }))
  }
  const handleValidation = () => {
    dispatch(setUploadFileMappingData(uploadFileDetails))
    sendPayload()
  }
  const sendPayload = () => {
    let COLUMNS
    if (FileType === FILE_TYPE.Department) {
      COLUMNS = [
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.departmentGroup,
          csvAttribute: uploadFileDetails.departmentGroup
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.departmentName,
          csvAttribute: uploadFileDetails.departmentName
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.departmentSort,
          csvAttribute: uploadFileDetails.departmentSort
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.departmentParentName,
          csvAttribute: uploadFileDetails.departmentParentName
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.departmentParentSort,
          csvAttribute: uploadFileDetails.departmentParentSort
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.departmentParent,
          csvAttribute: uploadFileDetails.departmentParent
        }
      ].map(i =>
        i.csvAttribute === PromptTypes.NONE ? { ...i, csvAttribute: "" } : i
      )
    }
    else if (FileType === FILE_TYPE.AreaRangeFile) {
      COLUMNS = [
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.categoryName,
          csvAttribute: uploadFileDetails.categoryName
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.rangeName,
          csvAttribute: uploadFileDetails.rangeName
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.areaRangeSort,
          csvAttribute: uploadFileDetails.areaRangeSort
        }
      ].map(i =>
        i.csvAttribute === PromptTypes.NONE ? { ...i, csvAttribute: "" } : i
      )
    }
    if (FileType === FILE_TYPE.VarianceControl) {
      COLUMNS = [
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.varianceGroup,
          csvAttribute: uploadFileDetails.varianceGroup
        },

        {
          dataAttribute: UPLOADFILE_FIELD_MAP.varianceGroupName,
          csvAttribute: uploadFileDetails.varianceGroupName
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.sortOrder,
          csvAttribute: uploadFileDetails.sortOrder
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.qtyTolerance,
          csvAttribute: uploadFileDetails.qtyTolerance
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.fullStoreQtyTolerance,
          csvAttribute: uploadFileDetails.fullStoreQtyTolerance
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.extTolerance,
          csvAttribute: uploadFileDetails.extTolerance
        },
        {
          dataAttribute: UPLOADFILE_FIELD_MAP.fullStoreExtTolerance,
          csvAttribute: uploadFileDetails.fullStoreExtTolerance
        }
      ].map(i =>
        i.csvAttribute === PromptTypes.NONE ? { ...i, csvAttribute: "" } : i
      )
    }
    const payloadToAdd = {
      idCustomer: customerId,
      IsConfirmed: false,
      continueStatus: null,
      idFileType: rowData.idFileType,
      idConfig: rowData.idConfig,
      columns: encryptBulkColumns(COLUMNS),
      fileData: fileDataString
    }
    dispatch(setUploadFileMappingPayloadData(payloadToAdd))
    setPage(PAGES.page3)
  }

  const handlePreviousClick = () => {
    dispatch(setUploadFileMappingData(uploadFileDetails))
    setPage(PAGES.page1)
  }
  const handleCancelClick = () => {
    dispatch(setUploadFileReset())
    history.push(PATH.UPLOADFILES)
  }
  const saveDisable = () => {
    if (FileType === FILE_TYPE.Department) {
      if (uploadFileDetails.departmentGroup === "") {
        return true
      } else {
        return false
      }
    } else if (FileType === FILE_TYPE.AreaRangeFile) {
      if (uploadFileDetails.rangeName === "") {
        return true
      } else {
        return false
      }
    } else {
      if (uploadFileDetails.varianceGroup === "") {
        return true
      } else {
        return false
      }
    }
  }

  const bulkDataWithNone = [PromptTypes.NONE, ...bulkData]

  return (
    <Grid container>
      <MiniHeader
        headerTitle={getTranslations(t, Translates.UPLOAD_FILES)}
        multiCustomer={handleNoCustomerId}
      />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <LocationAndScheduleTopHeadings />
          <Grid container className="ml-2 mt-2">
            {FileType === FILE_TYPE.Department && (
              <>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.Department_Group)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.departmentGroup}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.departmentGroup)
                      }
                      fieldIndex={1}
                      bulkData={bulkDataWithNone}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.Department_Name)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.departmentName}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.departmentName)
                      }
                      fieldIndex={2}
                      bulkData={bulkDataWithNone}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.Department_Sort)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.departmentSort}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.departmentSort)
                      }
                      fieldIndex={3}
                      bulkData={bulkDataWithNone}
                      FormControlClass={"mb-0"}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.Department_Parent_Name)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.departmentParentName}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.departmentParentName)
                      }
                      fieldIndex={4}
                      bulkData={bulkDataWithNone}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.Department_Parent_Sort)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.departmentParentSort}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.departmentParentSort)
                      }
                      fieldIndex={5}
                      bulkData={bulkDataWithNone}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.Department_Parent)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.departmentParent}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.departmentParent)
                      }
                      fieldIndex={20}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {FileType === FILE_TYPE.VarianceControl && (
              <>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.VARIANCE_GROUP)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.varianceGroup}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.varianceGroup)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.VARIANCE_GROUP_NAME)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.varianceGroupName}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.varianceGroupName)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>

                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>{getTranslations(t, Translates.SORT_ORDER)}</label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.sortOrder}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.sortOrder)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>

                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.QTY_TOLERANCE)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.qtyTolerance}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.qtyTolerance)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.FULL_STORE_QTY_TOLERANCE)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.fullStoreQtyTolerance}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.fullStoreQtyTolerance)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.EXT_TOLERANCE)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.extTolerance}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.extTolerance)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.FULL_STORE_EXT_TOLERANCE)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.fullStoreExtTolerance}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.fullStoreExtTolerance)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {FileType === FILE_TYPE.AreaRangeFile && (
              <>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>{getTranslations(t, Translates.categoryName)}</label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.categoryName}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.categoryName)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>{`${getTranslations(
                      t,
                      Translates.rangeName
                    )}*`}</label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.rangeName}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.rangeName)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>

                <Grid container className="mb-3">
                  <Grid item sm={3} className="d-flex align-items-center">
                    <label>
                      {getTranslations(t, Translates.areaRangeSort)}
                    </label>
                  </Grid>
                  <Grid item sm={1} className="d-flex align-items-center">
                    <ForwardIcon className="mr-1 blue-text" />
                  </Grid>
                  <Grid item sm={4}>
                    <BulkDataSelectField
                      label={getTranslations(t, Translates.CSV_Column_Header)}
                      value={uploadFileDetails?.areaRangeSort}
                      defaultValue={""}
                      handleOnChange={e =>
                        handleChangeField(e, UPLOAD_KEYS.areaRangeSort)
                      }
                      fieldIndex={23}
                      bulkData={bulkDataWithNone}
                      FormControlClass="mb-0"
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} className="py-3"></Grid>
          </Grid>
          <Grid item xs={12}>
            <BulkUploadButtons
              previousBLabel={getTranslations(t, Translates.Previous)}
              handlePreviousClick={handlePreviousClick}
              cancelBLabel={getTranslations(t, Translates.Cancel)}
              handleCancelClick={handleCancelClick}
              nextBLabel={getTranslations(t, Translates.NextValidation)}
              handleNextClick={handleValidation}
              saveDisable={saveDisable}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(UploadFileBulkDataMapping)
