import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Grid, Button, Checkbox, Tooltip } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CheckIcon from "@mui/icons-material/Check"
import { Loader } from "../../../../src/utils/reusables/reusable"
import GlobalTableDragDrop from "../../../../src/components/GlobalTable/GlobalTableDragDrop"
import editIcon from "../../../../src/assets/images/Edit.png"
import trashIcon from "../../../../src/assets/images/TrashIcon.svg"
import { PATH } from "../../../constants/paths"
import {
  columnDataDetails,
  currencySymbolDisplay,
  ProductsList,
  reorderRecords
} from "./Constants"
import { setCountPrograms } from "../../../../src/redux/slices/configSlice"
import ConfirmDialog from "../../../../src/components/ConfirmDialog/ConfirmDialog"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  CONFIG_MODULES,
  KEY_CODES,
  setIsDisableButton
} from "src/utils/constants"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { updateWarnOnTabChangeToStore } from "../helper"
import { getTranslations } from "src/utils/helper"
import { callbackConfirmationHelper } from "./Helper"
let preDeleteRow = {} as any

const CountFieldsProducts = ({ products, setIsTabChangeCheck }) => {
  const { t } = useTranslation<any>()
  const {
    config: {
      generalSettings,
      countFields,
      defaultCountFields,
      countPrograms,
      configType: configTypeFromStore
    }
  } = ConfigDataHook()

  const [renderData, setRenderData] = useState(reorderRecords(products))
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  let search = window.location.search || ""
  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }
  const displayCurrency = (props: any) => {
    return (
      <span className="d-block text-left">
        {currencySymbolDisplay.includes(props.row.original.field) && props.value
          ? props.value
          : "-"}
      </span>
    )
  }
  const displayCheckbox = (props: any) => {
    return (
      <span>
        <Checkbox
          checked={props.value}
          className="custom-check light"
          checkedIcon={<CheckIcon />}
          disabled={true}
        />
      </span>
    )
  }
  const displayAction = (props: any) => {
    return (
      <span className="action-btns">
        <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
          <img
            src={editIcon}
            alt={"editImg"}
            className="mr-2 pointer"
            width="20"
            onClick={() => editFieldsProduct(props.row)}
            onKeyUp={e => handleEditFieldsKeyUp(e, props.row)}
          />
        </Tooltip>
        <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
          <span>
            <img
              src={trashIcon}
              alt="deleteImg"
              width="20"
              aria-label={
                props.row.original.field === "ProductCaptured" ||
                props.row.original.field === "Qty"
                  ? "disabled"
                  : ""
              }
              className={`${
                configTypeFromStore === "view" ? "disable-img" : "pointer"
              }`}
              onClick={() => deleteFieldsProduct(props.row.original)}
              onKeyUp={e => handleDeleteFieldsKeyUp(e, props.row.original)}
            />
          </span>
        </Tooltip>
      </span>
    )
  }
  useEffect(() => {
    setRenderData(reorderRecords(products))
  }, [products])
  const columnsData = React.useMemo(() => {
    return columnDataDetails.map((i, j) => {
      return {
        ...i,
        ...{
          Header: getTranslations(t, i.Header),
          headerClassName: "temp_class",
          disableFilters: true,
          disableSortBy: [0].includes(j),
          Cell: props => {
            if ([3].includes(j)) return displayCurrency(props)
            else if ([5, 6].includes(j)) return displayCheckbox(props)
            else if ([7].includes(j)) return displayAction(props)
            return displaySpan(props)
          }
        }
      }
    })
    //eslint-disable-next-line
  }, [Object.keys(generalSettings).length, t])
  const handleNewProductClick = () => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIGURATION_COUNT_FIELDS_PRODUCT + search)
    })

    //eslint-disable-next-line
  }

  const handleEditFieldsKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      editFieldsProduct(row)
    }
  }, [])

  const editFieldsProduct = (row: any) => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIGURATION_COUNT_FIELDS_PRODUCT + search, {
        isEdit: true,
        index: row.index,
        data: { ...row.original }
      })
    })
  }
  const handleDeleteFieldsKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      deleteFieldsProduct(row)
    }
  }, [])
  const deleteFieldsProduct = (row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row }
  }
  const deleteField = data => {
    for (let key in data) {
      if (key === preDeleteRow.field) delete data[key]
      if (key === "Ext" && preDeleteRow.field === "Price") {
        delete data[key]
      }
    }
    return data
  }

  const deleteFieldFromCountProgram = () => {
    let locCountPrograms = countPrograms.map(i => {
      let copyOfI = JSON.parse(JSON.stringify(i))
      if (i?.ProductMode) {
        delete copyOfI[preDeleteRow.OjKey]
        if (i.ProductMode.Product?.AncillaryData) {
          delete copyOfI.ProductMode.Product.AncillaryData[preDeleteRow.OjKey]
          if (!Object.keys(copyOfI.ProductMode.Product.AncillaryData).length) {
            delete copyOfI.ProductMode.Product.AncillaryData
          }
        }
      }
      return copyOfI
    })
    dispatch(setCountPrograms(locCountPrograms))
  }
  const callbackConfirmation = useCallback(
    (res: boolean) => {
      callbackConfirmationHelper({
        res,
        setIsConfirmOpen,
        dispatch,
        deleteField,
        deleteFieldFromCountProgram,
        defaultCountFields,
        countFields
      })
    },
    //eslint-disable-next-line
    [countFields]
  )
  const setContent = useCallback(
    () => {
      return (
        <AlertConfirmationContent
          warningMsg={`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete
          )} "${preDeleteRow.field}" ${getTranslations(
            t,
            Translates.Product
          )} ${getTranslations(
            t,
            Translates.in_count_fields_and_count_programs
          )}?`}
        />
      )
    },
    //eslint-disable-next-line
    [preDeleteRow.field]
  )
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive mt-2">
        <Grid
          item
          xs={12}
          className="products-table"
          data-testid="CountFProductsGlobTable"
        >
          <GlobalTableDragDrop
            component={CONFIG_MODULES.countFields}
            columns={columnsData}
            data={renderData}
            noDataText={
              renderData.length !== 0
                ? Loader()
                : `${getTranslations(t, Translates.No_Products_Data_Available)}`
            }
          ></GlobalTableDragDrop>
        </Grid>
        {isConfirmOpen && (
          <ConfirmDialog
            open={isConfirmOpen}
            callBack={callbackConfirmation}
            title={`${getTranslations(t, Translates.DELETE)}`}
            content={setContent}
            custref={{}}
            confirmButtonText={`${getTranslations(t, Translates.CONFIRM)}`}
            cancelButtonText={`${getTranslations(t, Translates.Cancel)}`}
          />
        )}
      </Grid>
    )
  }

  return (
    <Grid container className="main-container">
      <Grid item sm={6} className="d-flex align-items-center">
        <h6>{getTranslations(t, Translates.Products)}</h6>
      </Grid>
      <Grid item sm={6} className="text-right">
        <Button
          variant="contained"
          className="primary-btn"
          disabled={setIsDisableButton(
            configTypeFromStore,
            renderData,
            ProductsList
          )}
          onClick={handleNewProductClick}
        >
          <AddIcon className="mr-1" />{" "}
          {getTranslations(t, Translates.New_Product)}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {displayGrid()}
      </Grid>
    </Grid>
  )
}
export default CountFieldsProducts
