import { getTranslations } from "src/utils/helper"
import { ConfigOutputBundleType } from "../Types"
import { Translates } from "src/i18n/i18n"
import { PATH } from "src/constants/paths"
import { getBreadcrumbConfig } from "src/configurationPortal/pages/helper"
import { createEditOutputBundleDefaultIniValues } from "../Constants/CreateEditOutputBundleConstant"

export const getDefaultValues = (
  outputBundlesRedux: ConfigOutputBundleType[],
  storeId: number
) => {
  if (storeId !== -1 && outputBundlesRedux[storeId - 1]) {
    return outputBundlesRedux[storeId - 1]
  }
  return createEditOutputBundleDefaultIniValues
}

export const getBreadcrumbPath = (storeId: number, t) => {
  let componentName =
    storeId !== -1
      ? getTranslations(t, Translates.Edit_Output_Bundle)
      : getTranslations(t, Translates.New_Output_Bundle)

  const { bConfig } = getBreadcrumbConfig({
    landingName: getTranslations(t, Translates.Output_Bundle),
    landingPath: PATH.OUTPUT_FILES.slice(1),
    componentName: componentName
  })
  return { componentName, bConfig }
}
