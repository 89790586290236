import { Alert, AlertColor, AlertTitle } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { HighlightOff } from "@mui/icons-material"

interface AlertProps {
  severity: AlertColor
  title: string
  content: string
}
const AlertC = (props: AlertProps) => {
  let Icon = <CheckCircleIcon />
  if (props.severity === "error") {
    Icon = <HighlightOff />
  }
  return (
    <Alert
      severity={props.severity}
      icon={Icon}
      className="custom-alert top"
      data-testid="alert-box"
    >
      <AlertTitle className="custom-alert__title f-600">
        {`${props.title}`}
      </AlertTitle>
      <p> {`${props.content}`}</p>
    </Alert>
  )
}
export default AlertC
