import { Button, Grid, Tooltip } from "@mui/material"
import BreadCrumb from "../../BreadCrumb/BreadCrumb"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom"
import { setCustomerFormValues } from "src/redux/slices/configSlice"
import { useDispatch, useSelector } from "react-redux"
import { PATH } from "src/constants/paths"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
const InputFilesToolBar = ({ inputFilesSaveClick, setIsTabChangeCheck }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore: any = useSelector(
    (state: RootState) => state.config?.configType
  )
  const history = useHistory()
  const dispatch = useDispatch()
  let search = window.location.search || ""
  const getBreadcrumbConfig = () => {
    let currentPath: string = `inputFiles`
    let variables: any = {
      inputFiles: getTranslations(t, Translates.Input_Files)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const inputFilesToolbarSaveClick = () => {
    inputFilesSaveClick()
  }

  const handleNewInputFileClick = () => {
    setIsTabChangeCheck(false)
    dispatch(setCustomerFormValues({}))
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.SELECT_INPUT_FILE_TYPE + search)
    })
  }

  return (
    <div className="main-toolbar">
      <div className="main-container" data-testid="input-container">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            className="d-flex align-items-center"
            data-testid="input-BreadCrumb-grid"
          >
            <div className="custom-breadcrumb CP">
              <BreadCrumb
                customPath={"Input Files"}
                breadCrumbInfo={getBreadcrumbConfig()}
                renderedPage={""}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions" data-testid="input-div">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <span>
                  <Button
                    disabled={configTypeFromStore === "view"}
                    variant="text"
                    onClick={inputFilesToolbarSaveClick}
                    className="icon-btn mx-2"
                    data-testid="saveBtn"
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>

              <Button
                variant="contained"
                className="primary-btn"
                onClick={handleNewInputFileClick}
                disabled={configTypeFromStore === "view"}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Input_File)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default InputFilesToolBar
