export const TableComponentNames = {
  categories: "categories",
  reportings: "reportings",
  countFields: "countFields",
  reportPrompts: "reportPrompts",
  outputPrompts: "outputPrompts",
  reportColumns: "reportColumns",
  reportSignatures: "reportSignatures",
  outputSignatures: "outputSignatures",
  groupVariances: "groupVariances",
  fullVariances: "fullVariances",
  outputFiles: "outputFiles",
  outputColumns: "outputColumns",
  outputSubTotals: "outputSubTotals",
  outputGrandTotals: "outputGrandTotals",
  reportSubTotals: "reportSubTotals",
  reportGrandTotals: "reportGrandTotals",
  outputBundles: "outputBundles",
  newauditrule: "newauditrule",
  multiEventOutputColumns: "multiEventOutputColumns",
  multiEventOutputSubTotals: "multiEventOutputSubTotals",
  multiEventOutputGrandTotals: "multiEventOutputGrandTotals"
}
