import { SqlEditorFormType } from "./Typs"

export const sqlEditorFieldNames = {
  RawSQL: { name: "RawSQL", label: "RawSQL" },
  Prompts: { name: "Prompts", label: "Prompts" },
  IsValidSQL: { name: "IsValidSQL", label: "IsValidSQL" }
}

export const sqlEditorDefaultIniValues: SqlEditorFormType = {
  RawSQL: "",
  Prompts: [],
  IsValidSQL: false
}
