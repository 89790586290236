import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  TextField,
  Checkbox
} from "@mui/material"
import { useHistory } from "react-router-dom"
import InfoIcon from "@mui/icons-material/Info"
import CheckIcon from "@mui/icons-material/Check"
import React, { useEffect, useState } from "react"
import { SettingsService } from "src/service/settings.service"
import { PATH } from "../../constants/paths"
import { useTranslation } from "react-i18next"
import { RootState } from "../../../src/redux/store"
import { useSelector } from "react-redux"
import MiniHeader from "../../../src/components/MiniHeader/MiniHeader"
import { Translates } from "src/i18n/i18n"
import { getTranslations, validateInputString } from "src/utils/helper"

const LocationAttributes = () => {
  const MAX_ALLOWED = 10
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const settingsService = new SettingsService()
  const [locationAttributes, setLocationAttributes] = useState({} as any)
  const [locationAttributeErrors, setLocationAttributeErrors] = useState(
    {} as any
  )
  const [locationAttributeChecks, setLocationAttributeChecks] = useState(
    {} as any
  )
  const [locationAttributeErrorMsg, setLocationAttributeErrorMsg] =
    useState(`Value required`)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  useEffect(() => {
    if (customerId) {
      getLocationsByStore()
    }
  }, [customerId])
  const getLocationsByStore = async () => {
    setLoading(true)
    try {
      await settingsService
        .getLocationsByStore([customerId])
        .then((temp: any) => {
          const temp1 = temp.data?.[0]
          setLocationAttributes({
            LAValue1: temp1?.locationAttribute1Label,
            LAValue2: temp1?.locationAttribute2Label,
            LAValue3: temp1?.locationAttribute3Label,
            LAValue4: temp1?.locationAttribute4Label,
            LAValue5: temp1?.locationAttribute5Label,
            LAValue6: temp1?.locationAttribute6Label,
            LAValue7: temp1?.locationAttribute7Label,
            LAValue8: temp1?.locationAttribute8Label,
            LAValue9: temp1?.locationAttribute9Label,
            LAValue10: temp1?.locationAttribute10Label
          })
          setLocationAttributeChecks({
            LACheck1: temp1?.locationAttribute1Label ? true : false,
            LACheck2: temp1?.locationAttribute2Label ? true : false,
            LACheck3: temp1?.locationAttribute3Label ? true : false,
            LACheck4: temp1?.locationAttribute4Label ? true : false,
            LACheck5: temp1?.locationAttribute5Label ? true : false,
            LACheck6: temp1?.locationAttribute6Label ? true : false,
            LACheck7: temp1?.locationAttribute7Label ? true : false,
            LACheck8: temp1?.locationAttribute8Label ? true : false,
            LACheck9: temp1?.locationAttribute9Label ? true : false,
            LACheck10: temp1?.locationAttribute10Label ? true : false
          })
          setLoading(false)
        })
    } catch (_error) {
      console.error(_error)
      setLoading(false)
    }
  }

  const locationAttributesCheckChange = (event, id) => {
    if (id == 1) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck1: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError1) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError1: false
        })
      }
    } else if (id == 2) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck2: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError2) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError2: false
        })
      }
    } else if (id == 3) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck3: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError3) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError3: false
        })
      }
    } else if (id == 4) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck4: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError4) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError4: false
        })
      }
    } else if (id == 5) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck5: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError5) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError5: false
        })
      }
    } else if (id == 6) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck6: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError6) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError6: false
        })
      }
    } else if (id == 7) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck7: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError7) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError7: false
        })
      }
    } else if (id == 8) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck8: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError8) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError8: false
        })
      }
    } else if (id == 9) {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck9: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError9) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError9: false
        })
      }
    } else {
      setLocationAttributeChecks({
        ...locationAttributeChecks,
        LACheck10: event.target.checked
      })
      if (!event.target.checked && locationAttributeErrors.LAError10) {
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          LAError10: false
        })
      }
    }
  }
  const handleLAClick = () => {
    history.push(PATH.SETTINGS)
  }

  const handleLAChange = (event, id) => {
    if (id == 1) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError1: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue1: event.target.value ? event.target.value : ""
      })
    } else if (id == 2) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError2: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue2: event.target.value ? event.target.value : ""
      })
    } else if (id == 3) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError3: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue3: event.target.value ? event.target.value : ""
      })
    } else if (id == 4) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError4: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue4: event.target.value ? event.target.value : ""
      })
    } else if (id == 5) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError5: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue5: event.target.value ? event.target.value : ""
      })
    } else if (id == 6) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError6: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue6: event.target.value ? event.target.value : ""
      })
    } else if (id == 7) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError7: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue7: event.target.value ? event.target.value : ""
      })
    } else if (id == 8) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError8: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue8: event.target.value ? event.target.value : ""
      })
    } else if (id == 9) {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError9: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue9: event.target.value ? event.target.value : ""
      })
    } else {
      setLocationAttributeErrors({
        ...locationAttributeErrors,
        LAError10: event.target.value ? false : true
      })
      setLocationAttributes({
        ...locationAttributes,
        LAValue10: event.target.value ? event.target.value : ""
      })
    }
  }
  const saveDisable = () => {
    let l = 1
    while (l <= MAX_ALLOWED) {
      const check_key = `LACheck${l}`
      const val_key = `LAValue${l}`
      if (locationAttributeChecks[check_key] && !locationAttributes[val_key]) {
        return true
      }
      l += 1
    }
    return false
  }
  const saveClick = () => {
    setLocationAttributeErrors({
      LAError1: false,
      LAError2: false,
      LAError3: false,
      LAError4: false,
      LAError5: false,
      LAError6: false,
      LAError7: false,
      LAError8: false,
      LAError9: false,
      LAError10: false
    })
    let l = 1
    while (l <= MAX_ALLOWED) {
      const check_key = `LACheck${l}`
      const val_key = `LAValue${l}`
      const err_key = `LAError${l}`
      if (locationAttributeChecks[check_key]) {
        const isValid = validateInputString(locationAttributes[val_key])
        setLocationAttributeErrors({
          ...locationAttributeErrors,
          [err_key]: !isValid
        })
        setLocationAttributeErrorMsg(
          !locationAttributes[val_key]
            ? getTranslations(t, Translates.Value_required)
            : getTranslations(t, Translates.Invalid_Input)
        )
        if (!isValid) {
          return false
        }
      }
      l += 1
    }

    let l1 = 1
    let isAllowed = false
    while (l1 <= MAX_ALLOWED) {
      const val_key = `LAValue${l1}`
      if (locationAttributes[val_key]) {
        isAllowed = true
        break
      }
      l1 += 1
    }

    if (isAllowed) {
      addLocationAttributeDetails()
    }
  }
  const addLocationAttributeDetails = () => {
    const payloadToAdd = {
      idCustomer: customerId, //id customer should be dynamic
      locationAttribute1Label: locationAttributeChecks.LACheck1
        ? locationAttributes.LAValue1
        : "",
      locationAttribute2Label: locationAttributeChecks.LACheck2
        ? locationAttributes.LAValue2
        : "",
      locationAttribute3Label: locationAttributeChecks.LACheck3
        ? locationAttributes.LAValue3
        : "",
      locationAttribute4Label: locationAttributeChecks.LACheck4
        ? locationAttributes.LAValue4
        : "",
      locationAttribute5Label: locationAttributeChecks.LACheck5
        ? locationAttributes.LAValue5
        : "",
      locationAttribute6Label: locationAttributeChecks.LACheck6
        ? locationAttributes.LAValue6
        : "",
      locationAttribute7Label: locationAttributeChecks.LACheck7
        ? locationAttributes.LAValue7
        : "",
      locationAttribute8Label: locationAttributeChecks.LACheck8
        ? locationAttributes.LAValue8
        : "",
      locationAttribute9Label: locationAttributeChecks.LACheck9
        ? locationAttributes.LAValue9
        : "",
      locationAttribute10Label: locationAttributeChecks.LACheck10
        ? locationAttributes.LAValue10
        : ""
    }
    settingsService
      .addLocationAttributeDetails(payloadToAdd)
      .then(res => {
        if (res && res.status == 200) {
          history.push(PATH.SETTINGS)
        }
      })
      .catch(err => {
        console.log(err.Message || err)
      })
  }
  const renderInputs = () => {
    const temp = Array.from({ length: MAX_ALLOWED }, (_, i) => i + 1)
    return temp.map(idx => {
      const check_key = `LACheck${idx}`
      const val_key = `LAValue${idx}`
      const err_key = `LAError${idx}`
      return (
        <div className="d-flex" key={check_key}>
          <Checkbox
            data-testid={`location${idx}`}
            checked={locationAttributeChecks[check_key]}
            checkedIcon={<CheckIcon />}
            defaultChecked={locationAttributes[val_key] ? true : false}
            className="custom-check mr-2"
            inputProps={{ "aria-label": "controlled" }}
            onChange={e => locationAttributesCheckChange(e, idx)}
          />
          <div className="text-box">
            <TextField
              fullWidth
              data-testid={`Attribute${idx}`}
              label={getTranslations(t, Translates.Enter_Attribute_Name)}
              variant="filled"
              inputProps={{
                maxLength: 25
              }}
              defaultValue={locationAttributes[val_key]}
              autoSave="false"
              autoComplete="off"
              onChange={e => handleLAChange(e, idx)}
              className="custom-form-control"
              disabled={locationAttributeChecks[check_key] ? false : true}
              error={locationAttributeErrors[err_key]}
              helperText={
                locationAttributeErrors[err_key]
                  ? locationAttributeErrorMsg
                  : ""
              }
            />
          </div>
        </div>
      )
    })
  }
  return (
    <>
      {!loading ? (
        <Grid container>
          <MiniHeader
            headerTitle={getTranslations(t, Translates.Add_Location_Attribute)}
          />
          <Grid container className="inner-view">
            <Grid item xs={12} className="main-container">
              <Grid container>
                <Grid item xs={12}>
                  <h4 className="inner-view__title">
                    {getTranslations(t, Translates.Location_Attribute_Details)}
                  </h4>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item sm={6} md={6} lg={8}>
                    <Grid container>
                      <Grid item sm={8}>
                        <label className="form-control__label">
                          {getTranslations(t, Translates.Basic_Details)}
                        </label>
                      </Grid>
                      <Grid item sm={10} md={10} lg={5}>
                        {renderInputs()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} md={6} lg={4}>
                    <Alert
                      severity="info"
                      icon={<InfoIcon />}
                      className="custom-alert"
                    >
                      <AlertTitle className="custom-alert__title">
                        {getTranslations(t, Translates.Info)}
                      </AlertTitle>
                      <p>
                        {getTranslations(
                          t,
                          Translates.Organize_your_locations_better_by_adding_Location_Attributes_We_can_add_various_levels_of_Location_Attribute_here_which_would_be_required_while_adding_Users_and_Locations
                        )}
                      </p>
                    </Alert>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="inner-view__footer">
                    <Button
                      variant="text"
                      className="primary-btn mr-5 cancel-btn"
                      data-testid="Cancel"
                      onClick={handleLAClick}
                    >
                      {getTranslations(t, Translates.Cancel)}
                    </Button>
                    <Button
                      variant="contained"
                      className="primary-btn"
                      disabled={saveDisable()}
                      data-testid="Save"
                      onClick={saveClick}
                    >
                      {getTranslations(t, Translates.Save)}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}
export default LocationAttributes
