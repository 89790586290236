import {
  Download_template_right_to_create_a_CSV_file,
  populateCSVFileWithDoNotChangeTheColumnHeader,
  Upload_the_CSV_file_in_the_upload_box,
  getTranslations
} from "src/utils/helper"
import { t } from "i18next"
import { Translates } from "src/i18n/i18n"
import { BULK_UPLOADING } from "src/utils/constants"

const BulkUploadData = ({ fileUploadType }) => {
  return (
    <>
      {" "}
      {fileUploadType === BULK_UPLOADING.LOCATION_FILE_UPLOADING ? (
        <>
          <p className="mb-3">
            {getTranslations(
              t,
              Translates.To_upload_Locations_you_need_to_populate_a_CSV_file_with_user_data_Please_use_the_provided_CSV_template_The_columns_in_the_template_are_the_expected_fields
            )}
          </p>
          <label className="form-control__label mb-2">
            {getTranslations(
              t,
              Translates.TO_IMPORT_LOCATIONS_PERFORM_FOLLOWING_TASKS
            )}
          </label>
        </>
      ) : (
        ""
      )}
      {fileUploadType === BULK_UPLOADING.SCHEDULE_FILE_UPLOADING ? (
        <>
          <p className="mb-3">
            {getTranslations(
              t,
              Translates.To_upload_data_you_need_to_populate_a_CSV_file_with_user_data_Please_use_the_provided_CSV_template_The_columns_in_the_template_are_the_expected_fields
            )}
          </p>
          <label className="form-control__label mb-2">
            {getTranslations(
              t,
              Translates.TO_IMPORT_SCHEDULED_DATA_PERFORM_FOLLOWING_TASKS
            )}
          </label>
        </>
      ) : (
        ""
      )}
      {fileUploadType === BULK_UPLOADING.USER_DEATILS_FILE_UPLOADING ? (
        <>
          {" "}
          <p className="mb-3">
            {getTranslations(
              t,
              Translates.To_upload_data_you_need_to_populate_a_CSV_file_with_user_data_Please_use_the_provided_CSV_template_The_columns_in_the_template_are_the_expected_fields
            )}
          </p>
          <label className="form-control__label mb-2">
            {getTranslations(
              t,
              Translates.TO_IMPORT_USERS_DATA_PERFORM_FOLLOWING_TASKS
            )}
          </label>
        </>
      ) : (
        ""
      )}
      <p className="mb-1">
        {getTranslations(t, Download_template_right_to_create_a_CSV_file())}
      </p>
      <p className="mb-1">
        {getTranslations(t, populateCSVFileWithDoNotChangeTheColumnHeader())}
      </p>
      {[
        BULK_UPLOADING.USER_DEATILS_FILE_UPLOADING,
        BULK_UPLOADING.LOCATION_FILE_UPLOADING
      ].includes(fileUploadType) ? (
        <p className="mb-1">
          {getTranslations(t, Upload_the_CSV_file_in_the_upload_box())}
        </p>
      ) : (
        ""
      )}
      {fileUploadType === BULK_UPLOADING.SCHEDULE_FILE_UPLOADING ? (
        <p className="mb-1">
          {getTranslations(
            t,
            Translates.Upload_the_CSV_file_in_the_upload_box_provided_below
          )}
        </p>
      ) : (
        ""
      )}
    </>
  )
}

export default BulkUploadData
