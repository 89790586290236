import AceEditor from "react-ace"
import "ace-builds/src-min-noconflict/ext-language_tools"
import "ace-builds/src-min-noconflict/mode-mysql"
import "ace-builds/src-min-noconflict/theme-tomorrow"
import "ace-builds/src-min-noconflict/theme-xcode"
const AceEditorC = ({
  editormode,
  editorvalue,
  editorreadOnly,
  editorBackgroundColor,
  onFocusChangeHandler,
  onChangeHandler,
  focus
}) => {
  return (
    <AceEditor
      data-testid="ace-editor"
      aria-label="query editor input"
      mode={editormode !== "" ? editormode : "mysql"}
      theme="x-code"
      name={"sql-editor"}
      fontSize={16}
      maxLines={11}
      minLines={11}
      width="100%"
      height="auto"
      className="sqlEditor"
      showPrintMargin={false}
      highlightActiveLine={false}
      readOnly={editorreadOnly}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: true,
        showLineNumbers: false,
        showGutter: false
      }}
      onFocus={onFocusChangeHandler}
      onChange={onChangeHandler}
      onLoad={editor => {
        // istanbul ignore next
        if (focus) {
          setTimeout(() => {
            editor.focus()
            const session = editor.getSession()
            const count = session.getLength()
            editor.gotoLine(count, session.getLine(count - 1).length, true)
          }, 100)
        }
      }}
      value={editorvalue !== "" ? editorvalue : ""}
    />
  )
}

export default AceEditorC
