import OutputFilesToolBar from "../../../../src/components/ConfigurationPortal/OutputFilesToolBar/OutputFilesToolBar"
import {
  Alert,
  AlertTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material"
import { Loader } from "../../../../src/utils/reusables/reusable"
import React, { useEffect, useState } from "react"

import GlobalTableDragDrop from "../../../../src/components/GlobalTable/GlobalTableDragDrop"
import { useAppThunkDispatch } from "../../../../src/redux/store"
import {
  setNewReportPrompts,
  setOutputFiles,
  setSQLQuery,
  setOutputSubTotals,
  setCurrentOutputFile,
  setOutputGrandTotals,
  setOutputColumns,
  setSQLQueryColumns
} from "../../../../src/redux/slices/configSlice"
import ConfirmDialog from "../../../../src/components/ConfirmDialog/ConfirmDialog"
import Warning from "@mui/icons-material/Warning"
import editIcon from "src/assets/images/Edit.png"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import { useHistory, useParams } from "react-router-dom"
import { PATH } from "../../../../src/constants/paths"
import trashIcon from "src/assets/images/TrashIcon.svg"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { ConfigTabNames, updateWarnOnTabChangeToStore } from "../helper"
import { getTranslations } from "src/utils/helper"
import OutputBundles from "../OutputBundles"
let preDeleteRow = {} as any
const OutputFiles = ({ liftOutputFilesSaveClick }) => {
  const { t } = useTranslation<any>()
  const {
    config: { Outputs, configType: configTypeFromStore }
  } = ConfigDataHook()

  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)

  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.Outputs,
    isTabChangeCheck: isTabChangeCheck
  })

  let search = window.location.search || ""

  let { storeId }: { storeId: string } = useParams()
  const [renderData, setRenderData] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const history = useHistory()
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    dispatch(setCurrentOutputFile({}))
    dispatch(setNewReportPrompts([]))
    dispatch(setOutputColumns([]))
    dispatch(setSQLQuery(""))
    dispatch(setOutputSubTotals([]))
    dispatch(setOutputGrandTotals([]))
    dispatch(setSQLQueryColumns([]))
  }, [])
  useEffect(() => {
    setLoading(true)
    setRenderData([...Outputs])
    setLoading(false)
  }, [Outputs])

  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">{props.value ? props.value : 0}</span>
    )
  }

  const copyOutputFile = (row: any) => {
    const _item = {
      ...row,
      ...{
        Name: `Copy of ${row.Name}`,
        SortOrder: (renderData?.length ?? 0) + 1
      }
    }
    dispatch(setOutputFiles([...renderData, _item]))
  }
  const EditOutputFile = (data: any) => {
    setIsTabChangeCheck(false)
    dispatch(setCurrentOutputFile(data))
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
        isEdit: true,
        data: { ...data },
        storeId: storeId
      })
    })
  }
  const deleteOutputFile = (row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row }
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Sort),
        accessor: "SortOrder",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 50,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Output_Name),
        accessor: "Name",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 150,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Description),
        accessor: "Description",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 300,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.File_Name),
        accessor: "FileName",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 200,
        Cell: props => {
          return displaySpan(props)
        }
      },

      {
        Header: getTranslations(t, Translates.File_Type),
        accessor: "FileType",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 200,
        Cell: props => {
          return displaySpan(props)
        }
      },

      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 180,
        Cell: props => {
          return (
            <>
              <Tooltip arrow title={getTranslations(t, Translates.Copy)}>
                <IconButton
                  onClick={() => copyOutputFile(props.row.original)}
                  data-testid="copy-btn"
                  disabled={configTypeFromStore === "view" ? true : false}
                >
                  <span className="action-btns text-center d-block ">
                    <ContentCopyOutlinedIcon
                      className={`copy-icon ${
                        configTypeFromStore === "view"
                          ? "disable-img"
                          : "pointer"
                      }`}
                    />
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <IconButton
                  onClick={() => EditOutputFile(props.row.original)}
                  data-testid="edit-btn"
                >
                  <span className="action-btns text-center d-block ">
                    <img src={editIcon} alt={"edit icon"} className="pointer" />
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
                <IconButton
                  onClick={() => deleteOutputFile(props.row.original)}
                  data-testid="delete-btn"
                  disabled={configTypeFromStore === "view" ? true : false}
                >
                  <span className="action-btns text-center d-block ">
                    <img
                      src={trashIcon}
                      alt="delete"
                      className={`${
                        configTypeFromStore === "view"
                          ? "disable-img"
                          : "pointer"
                      }`}
                    />
                  </span>
                </IconButton>
              </Tooltip>
            </>
          )
        }
      }
    ],
    [renderData, t]
  )

  const setContent = () => {
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete_the
          )} "${preDeleteRow.Name}" ${getTranslations(
            t,
            Translates.record_in_output_and_output_bundles
          )}?`}</strong>
        </p>
      </Alert>
    )
  }
  const callbackConfirmation = (res: boolean) => {
    if (res) {
      let dt = renderData.filter(f => f.Name !== preDeleteRow?.Name)

      dt = dt.map((m: any, i) => {
        return { ...m, SortOrder: i + 1 }
      })
      dispatch(setOutputFiles(dt))
    }
    setIsConfirmOpen(false)
  }
  const outputFilesSaveClick = () => {
    liftOutputFilesSaveClick(
      [
        ...Outputs.map((m, i) => {
          return { ...m, SortOrder: i + 1 }
        })
      ],
      "OutputFilesTab"
    )
  }

  const displayGrid = () => {
    return (
      <Grid
        container
        className="custom-table-responsive categories-table"
        data-testid="table"
      >
        <Grid xs={12} item>
          <GlobalTableDragDrop
            component="outputFiles"
            columns={columnsData}
            data={renderData || []}
            noDataText={
              renderData?.length && renderData?.length !== 0
                ? Loader()
                : `${getTranslations(t, Translates.No_Output_Files_Available)}.`
            }
          ></GlobalTableDragDrop>
        </Grid>
        <Grid xs={12} item className="mt-2">
          <Divider className="dashed my-4" />
          <OutputBundles />
        </Grid>
        {isConfirmOpen && (
          <ConfirmDialog
            open={isConfirmOpen}
            callBack={callbackConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.Cancel)}
          ></ConfirmDialog>
        )}
      </Grid>
    )
  }
  return (
    <>
      <OutputFilesToolBar
        outputFilesSaveClick={outputFilesSaveClick}
        setIsTabChangeCheck={setIsTabChangeCheck}
      />

      <div className="main-container">{loading ? Loader() : displayGrid()}</div>
    </>
  )
}

export default OutputFiles
