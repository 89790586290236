import { useHistory, useLocation, useParams } from "react-router-dom"
import { useAppThunkDispatch } from "src/redux/store"
import { createEditColumnFieldNames } from "../../Utils/Constants/CreateEditColumnConstant"
import { PromptTypes } from "src/utils/constants"
import {
  findHighestSuppressIndex,
  getVariableBasedOnComponent
} from "../../Utils/Helpers/ColumnsGridHelper"
import FormSubmitCancelButtons from "../../../FormSubmitCancelButtons"
import { replaceAllMultiple } from "src/utils/helper"
import {
  onCancelClick,
  setValueForVariable
} from "src/configurationPortal/pages/FunctionHelper"
import { getCurrencySymbol } from "src/configurationPortal/pages/CountFields/Constants"

const FormSubmitCancel = () => {
  const history = useHistory()
  let location = useLocation() as any
  const { cOriginalDisplayMap } = getCurrencySymbol()

  const { id, tab, tabId }: { id: string; tab: string; tabId: string } =
    useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1

  let { variableSetRedux, variableRedux, redirectUrl, variablesRedux } =
    getVariableBasedOnComponent(tab, storeTabId, storeId)

  const dispatch = useAppThunkDispatch()
  let search = window.location.search || ""

  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  let stateVariableRedux = location.state?.variableRedux
  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const handleSaveClick = data => {
    if (
      data[createEditColumnFieldNames.DataType.name] !== PromptTypes.Decimal
    ) {
      delete data[createEditColumnFieldNames.NumberOfDecimals.name]
      delete data[createEditColumnFieldNames.CurrencySymbol.name]
      delete data[createEditColumnFieldNames.OriginalCurrencySymbol.name]
    } else {
      let currency = ""
      if (data[createEditColumnFieldNames.OriginalCurrencySymbol.name]) {
        currency = cOriginalDisplayMap.get(
          data[createEditColumnFieldNames.OriginalCurrencySymbol.name]
        )
      }

      data[createEditColumnFieldNames.CurrencySymbol.name] = currency
    }

    let Columns = locVariableRedux.Columns
    if (storeId <= -1) {
      Columns = [...Columns, { ...data }]
    } else {
      Columns = Columns?.map((i, j) => {
        return j === storeId - 1 ? data : i
      })
    }
    dispatch(
      variableSetRedux({
        ...locVariableRedux,
        Columns: Columns,
        ColumnsToSuppress: findHighestSuppressIndex(Columns)
      })
    )
    onCancelClick(history, redirectUrl)
  }
  return (
    <>
      <FormSubmitCancelButtons
        handleSaveClick={handleSaveClick}
        redirectUrl={redirectUrl}
      />
    </>
  )
}

export default FormSubmitCancel
