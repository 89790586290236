import {
  Box,
  CircularProgress,
  Grid,
  Button,
  Tooltip,
  FormControl,
  Autocomplete,
  TextField,
  Checkbox
} from "@mui/material"
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import ImageIcon from "@mui/icons-material/Image"
import CheckIcon from "@mui/icons-material/Check"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "../helper"
export const Loader = () => {
  return (
    <Box
      key={"loader"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "32px"
      }}
      data-testid="loader"
    >
      <CircularProgress />
    </Box>
  )
}

const getAuditBreadcrumbConfig = t => {
  let currentPath: string = `audits`
  let variables: any = {
    audits: getTranslations(t, Translates.General_Audits_Settings)
  }
  let bConfig: any = {
    currentPath,
    mapObj: variables,
    showHomeIcon: false
  }
  return bConfig
}

export const toolBarHearder = (
  handleAuditSaveClick,
  configTypeFromStore,
  t
) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} className="d-flex align-items-center">
        <div className="custom-breadcrumb CP">
          <BreadCrumb
            customPath={"audits"}
            breadCrumbInfo={getAuditBreadcrumbConfig(t)}
            renderedPage={""}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="text-right toolbar-actions">
          <Tooltip arrow title={getTranslations(t, Translates.Save)}>
            <span>
              <Button
                disabled={configTypeFromStore === "view"}
                variant="text"
                className="icon-btn mx-2"
                onClick={handleAuditSaveClick}
                data-testid="save-header-brecrb-btn"
              >
                <SaveOutlinedIcon />
              </Button>
            </span>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  )
}

export const getDataSourceAutoComplete = (
  result,
  dataSource,
  handleDataSourceChange,
  t
) => {
  return (
    <div className="text-box">
      <FormControl
        className="custom-form-control select no-border multi-select"
        variant="filled"
      >
        <Autocomplete
          id="combo-box-demo"
          multiple
          disableCloseOnSelect
          options={result}
          limitTags={2}
          value={dataSource || ""}
          onChange={handleDataSourceChange}
          getOptionLabel={(option: any) => option}
          renderInput={params => (
            <TextField
              {...params}
              value={dataSource}
              name="DataSource"
              label={getTranslations(t, Translates.Data_Group)}
            />
          )}
          // istanbul ignore next
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checkedIcon={<CheckIcon />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
        />
      </FormControl>
    </div>
  )
}
export const renderCompanyCell = (idCustomer, selectedCustomerList) => {
  let customer = selectedCustomerList.find(c => c.idCustomer === idCustomer)
  if (customer) {
    return (
      <div className="cust-name-in-grid">
        {customer.companyLogo ? (
          <img
            className="multi-cust-logo"
            src={customer.companyLogo}
            alt={"logo"}
          />
        ) : (
          <ImageIcon style={{ fontSize: "40px" }} />
        )}

        <Tooltip arrow title={customer.companyName}>
          <span className="multi-cust-cname">{customer.companyName} </span>
        </Tooltip>
      </div>
    )
  }
  return "NA"
}
