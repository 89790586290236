import React, { useEffect, useMemo, useState } from "react"
import { Grid } from "@mui/material"
import UploadFileBulkDataMapping from "./UploadFileBulkDataMapping"
import { useAppThunkDispatch } from "src/redux/store"
import { setLocationReset } from "src/redux/slices/settingsSlice"
import UploadFileBulkValidation from "./UploadFileBulkValidation"
import UploadFileLocationBulk from "./UploadFileLocationBulk"
import { getQueryParam } from "src/utils/helper"
import UpdateDepartFile from "./UpdateDepartFile"
import { PAGES } from "../Location/UploadFileHelper"
const UploadFileBulkContainer: React.FC<any> = () => {
  const [currentPage, setCurrentPage] = useState(PAGES.page1)
  const dispatch = useAppThunkDispatch()
  useEffect(() => {
    setCurrentPage(PAGES.page1)
    return () => {
      dispatch(setLocationReset())
    }
  }, [])

  const FileType = getQueryParam("Type")
  const Row = getQueryParam("row")

  const setPage = _page => {
    setCurrentPage(_page)
  }

  const renderPages = useMemo(() => {
    if (currentPage === PAGES.page1) {
      return <UploadFileLocationBulk setPage={setPage} FileType={FileType} />
    } else if (currentPage === PAGES.page2) {
      return (
        <UploadFileBulkDataMapping
          setPage={setPage}
          FileType={FileType}
          Row={Row}
        />
      )
    } else if (currentPage === PAGES.page3) {
      return <UploadFileBulkValidation setPage={setPage} />
    } else if (currentPage === PAGES.page4) {
      return <UpdateDepartFile setPage={setPage} Row={Row} />
    } else {
      return <></>
    }
  }, [currentPage])
  return <Grid container>{renderPages}</Grid>
}
export default React.memo(UploadFileBulkContainer)
