import React, { useCallback, useEffect, useState } from "react"
import { Grid, Tooltip, Alert, AlertTitle } from "@mui/material"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../constants/paths"
import LookupRoutinesToolBar from "../../../components/ConfigurationPortal/LookupRoutinesToolBar/LookupRoutinesToolBar"
import { useDispatch } from "react-redux"
import {
  setLookupRoutines,
  setNewLookupRoutine
} from "../../../redux/slices/configSlice"
import GlobalTable from "../../../components/GlobalTable/GlobalTable"
import trashIcon from "../../../../src/assets/images/TrashIcon.svg"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import editIcon from "../../../../src/assets/images/Edit.png"
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog"
import Warning from "@mui/icons-material/Warning"
import { Loader } from "../../../utils/reusables/reusable"
import { LOOKUP_ROUTINES_TYPE } from "../../../constants/lookupRoutines"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { makeLayoutAllVariableEmpty } from "./helpers"
import "../../../../src/assets/scss/lookup-routines.scss"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { ConfigTabNames, updateWarnOnTabChangeToStore } from "../helper"
import { getTranslations } from "src/utils/helper"
import { KEY_CODES } from "src/utils/constants"
const LookupRoutines = ({ liftLookupRoutinesSaveClick }) => {
  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)

  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.LookupRoutines,
    isTabChangeCheck: isTabChangeCheck
  })
  const { t } = useTranslation<any>()
  const history = useHistory()
  const dispatch = useDispatch()
  let search = window.location.search || ""
  const {
    config: { lookupRoutines, configType: configTypeFromStore }
  } = ConfigDataHook()
  const [renderData, setRenderData] = useState([] as any)
  const [deleteRow, setDeleteRow] = useState({} as any)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    setRenderData([...(lookupRoutines || [])])
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [lookupRoutines])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      dispatch(setNewLookupRoutine({}))
      makeLayoutAllVariableEmpty()
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  const handleDeleteLRKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      deleteLookupRoutine(row)
    }
  }, [])

  const deleteLookupRoutine = (row: any) => {
    setIsConfirmDeleteOpen(true)
    setDeleteRow(row)
  }
  const setDeleteContent = () => {
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete
          )} ${deleteRow.Name}?`}</strong>
        </p>
      </Alert>
    )
  }
  const callbackDeleteConfirmation = (res: boolean) => {
    if (res) {
      let dt: any
      dt = renderData.filter((f, i) => i !== Number(deleteRow.id))
      dispatch(setLookupRoutines(dt))
    }
    setIsConfirmDeleteOpen(false)
  }

  const cloneLookupRoutine = (row: any) => {
    const _item = {
      ...row,
      ...{
        Name: `Copy of ${row.Name}`
      }
    }

    dispatch(setLookupRoutines([...lookupRoutines, _item]))
  }

  const handleEditLRKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      editLookupRoutine(row)
    }
  }, [])

  const editLookupRoutine = (row: any) => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIG_NEW_LOOKUP_ROUTINE + search, {
        type: LOOKUP_ROUTINES_TYPE.EDIT,
        data: JSON.stringify({ ...row }),
        index: Number(row.id),
        configInstructions: true
      })
    })
  }
  const lookupRoutinesSaveClick = () => {
    liftLookupRoutinesSaveClick([...lookupRoutines], "LookupRoutinesTab")
  }
  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Name),
        accessor: "Name",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 250,
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 180,
        Cell: props => {
          return (
            <span className="action-btns">
              <Tooltip arrow title={getTranslations(t, Translates.Clone)}>
                <ContentCopyIcon
                  data-testid="cloneBtn"
                  className={`mr-2 ${
                    configTypeFromStore === "view"
                      ? "disable-img"
                      : "font-24 pointer"
                  }`}
                  onClick={() => cloneLookupRoutine(props.row.original)}
                />
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <img
                  data-testid="editBtn"
                  className="pointer mr-2"
                  src={editIcon}
                  width="24"
                  onClick={() => {
                    editLookupRoutine({
                      ...props.row.original,
                      ...{ id: props.row.id }
                    })
                  }}
                  onKeyUp={e =>
                    handleEditLRKeyUp(e, {
                      ...props.row.original,
                      ...{ id: props.row.id }
                    })
                  }
                />
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
                <img
                  data-testid="deleteBtn"
                  src={trashIcon}
                  width="24"
                  className={`mr-2 ${
                    configTypeFromStore === "view" ? "disable-img" : "pointer"
                  }`}
                  onClick={() =>
                    deleteLookupRoutine({
                      ...props.row.original,
                      ...{ id: props.row.id }
                    })
                  }
                  onKeyUp={e =>
                    handleDeleteLRKeyUp(e, {
                      ...props.row.original,
                      ...{ id: props.row.id }
                    })
                  }
                />
              </Tooltip>
            </span>
          )
        }
      }
    ],
    [lookupRoutines, t]
  )
  const displayGrid = () => {
    return (
      <Grid
        container
        className="custom-table-responsive categories-table"
        data-testid="lookup-display-grid"
      >
        <Grid xs={12} item>
          <GlobalTable
            component="lookupRoutines"
            columns={columnsData}
            data={renderData}
            noDataText={
              renderData.length !== 0
                ? Loader()
                : `${getTranslations(
                    t,
                    Translates.No_Lookup_Routines_data_available
                  )}.`
            }
          ></GlobalTable>
        </Grid>
        {isConfirmDeleteOpen && (
          <ConfirmDialog
            open={isConfirmDeleteOpen}
            callBack={callbackDeleteConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setDeleteContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.Cancel)}
          ></ConfirmDialog>
        )}
      </Grid>
    )
  }
  return (
    <>
      <LookupRoutinesToolBar
        lookupRoutinesSaveClick={lookupRoutinesSaveClick}
        setIsTabChangeCheck={setIsTabChangeCheck}
      />
      <div className="main-container">{loading ? Loader() : displayGrid()}</div>
    </>
  )
}

export default LookupRoutines
