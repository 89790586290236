import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

// check the column is available in the SQL Query Columns
export const displaySource = (props: any, sqlQueryColumns) => {
  // check the column is available in the SQL Query Columns
  let result = sqlQueryColumns.map(({ ColumnName }) => ColumnName)

  if (result.includes(props.value)) {
    return (
      <span className="d-block text-left">
        {props.value ? props.value.toString() : ""}
      </span>
    )
  } else {
    return (
      <span className="d-block text-left errro" style={{ color: "red" }}>
        {props.value ? props.value.toString() : ""}
      </span>
    )
  }
}

export const getCurrencySymbolField = (form, t, onFormChange, currencies) => {
  return (
    <>
      {" "}
      {form.DataType === "DECIMAL" ? (
        <FormControl className="custom-form-control select" variant="filled">
          <InputLabel variant="filled" id="demo-multiple-checkbox-label">
            {getTranslations(t, Translates.Currency)}
          </InputLabel>
          <Select
            id="demo-multiple-checkbox"
            value={form?.CurrencySymbol}
            name="CurrencySymbol"
            onChange={e => onFormChange(e)}
          >
            {currencies?.map((name: any, index) => (
              <MenuItem key={name} value={name} className="dropdown-list">
                <small>
                  <ListItemText
                    primary={
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {name.split("-")[0]}
                        </span>{" "}
                        {name.split("-")[1] ? (
                          <>
                            -{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {name.split("-")[1]}
                            </span>
                          </>
                        ) : null}{" "}
                        {name.split("-")[2] ? (
                          <span>- {name.split("-")[2]}</span>
                        ) : null}
                      </>
                    }
                  />
                </small>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        ""
      )}
    </>
  )
}
