import { Alert, AlertColor, AlertTitle } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { store } from "src/redux/store"
import { ConfigurationStoreType } from "src/utils/types/types"
import { decryptUrlParams, getTranslations } from "src/utils/helper"
import { ConfigService } from "src/service/config.service"
import {
  ConfigTabNames,
  makeClearQueryToExecute,
  updateWarnOnTabChangeToStore
} from "./helper"
import { setConfigDetails } from "src/redux/slices/configSlice"
import { getFormToConfigConversion } from "./Variances/Utils/FunctionsHelper"
import { API_RESPONSE, DEFAULT_RADIX, formField } from "src/utils/constants"
import MUIText from "src/components/MUIFormFields/MUIText"
import MUIAutoCompleteText from "src/components/MUIFormFields/MUIAutoCompleteText"
import MUIMultiAutoCompleteText from "src/components/MUIFormFields/MUIMultiAutoCompleteText"
import MUICheckbox from "src/components/MUIFormFields/MUICheckbox"
import MUIRadio from "src/components/MUIFormFields/MUIRadio"
import MUIDatePicker from "src/components/MUIFormFields/MUIDatePicker"
import MUITimePicker from "src/components/MUIFormFields/MUITimePicker"
import { Translates } from "src/i18n/i18n"
import { SqlTestEditorService } from "src/service/sqlTestEditor.service"
import { setColumnsRedux } from "src/redux/slices/ConfigPortal/ColumnSlice"
import { getFieldError } from "src/components/MUIFormFields/Helper"
import { renderType } from "src/pages/MultiEventOutputs/Utils/Constants/FileCreationSchedule/FcsOptions"
import { LocationsList } from "./CountFields/Constants"

let configService = new ConfigService()

export const alertMsgShow = alertMsg => {
  return (
    <Alert
      severity={alertMsg.severity}
      icon={
        alertMsg.severity === "error" ? (
          <HighlightOffIcon />
        ) : (
          <CheckCircleIcon />
        )
      }
      className="custom-alert top"
    >
      <AlertTitle className="custom-alert__title f-600">
        {`${alertMsg.title}`}
      </AlertTitle>
      <p> {`${alertMsg.content}`}</p>
    </Alert>
  )
}

export const emptyAlert = {
  severity: "" as AlertColor,
  title: "",
  content: ""
}

export const GetConfigPayload = () => {
  const config: any = store.getState().config
  const settings = store.getState().settings
  const varianceData = store.getState().variance
  const reportData = store.getState().report
  const outputBundleData = store.getState().outputBundle
  const { selectedCustomerForOperation } = settings

  let params = new URLSearchParams(
    decryptUrlParams(window.location.search?.slice(1))
  )
  let idString = params.get("id")
  let idNum = idString ? parseInt(idString, DEFAULT_RADIX) : undefined
  const {
    configDetails,
    generalSettings,
    locationCategories,
    lookupRoutines,
    countFields,
    Outputs,
    Audits,
    countPrograms,
    inputFiles,
    efficiencySettings,
    accuracySettings
  } = config

  let payload: any = {
    createdDate: configDetails?.createdDate || new Date(),
    lastUpdatedDate: new Date(),
    idConfig: idNum,
    idCustomer: parseInt(selectedCustomerForOperation, DEFAULT_RADIX),
    name: configDetails?.name,
    status: generalSettings.Status || 3,
    supportedLanguages: configDetails?.supportedLanguages?.toString() || "",
    version: configDetails?.version || "1.0",
    configuration: ""
  }
  let configVal: ConfigurationStoreType
  if (configDetails?.configuration) {
    configVal = JSON.parse(configDetails?.configuration)
  } else {
    const { varianceRedux } = varianceData
    const { outputBundlesRedux } = outputBundleData
    const { reportsRedux } = reportData

    const { configData } = getFormToConfigConversion(varianceRedux)
    configVal = {
      GeneralSettings: generalSettings,
      LocationCategories: locationCategories,
      LookupRoutines: lookupRoutines,
      Reports: reportsRedux,
      Fields: countFields,
      GroupedVariances: configData.GroupVariance,
      FullStoreVariances: configData.FullVariance,
      Outputs: Outputs,
      OutputBundles: outputBundlesRedux,
      Audits: Audits,
      CountPrograms: countPrograms,
      InputFiles: inputFiles,
      FilesRequired: {
        DepartmentFileNotRequired: false,
        ProductFileNotRequired: false
      },
      Efficiency: efficiencySettings,
      Accuracy: accuracySettings
    }
  }
  return {
    payload: { ...payload, ...{ configuration: configVal } }
  }
}

export const updateConfigurationData = async ({ payload, t }) => {
  let alertMsgUC = {}
  try {
    let _res: any = {}
    if (Object.keys(payload).length) {
      _res = await configService.updateConfiguration(payload)
    }
    if (_res && _res.status === 200) {
      console.log("Generated Configuration ", JSON.parse(payload.configuration)) // intentionally added this comment to show the configuration object in demo
      store.dispatch(setConfigDetails(payload))
      updateWarnOnTabChangeToStore(false)
      alertMsgUC = {
        severity: "success",
        title: getTranslations(t, Translates.Success),
        content: `${getTranslations(t, Translates.Saved_Successfully)!}`
      }
    }
  } catch (err: any) {
    alertMsgUC = {
      severity: "error",
      title: "Error",
      content: err?.Message || "Something went wrong, Please try again later!"
    }
  }

  return { alertMsgUC }
}

export const comparingConfigAndReduxArrays = (ConfigArr, ReduxArr) => {
  if (
    ![undefined].includes(ConfigArr) &&
    JSON.stringify(ConfigArr) !== JSON.stringify(ReduxArr)
  ) {
    updateWarnOnTabChangeToStore(true)
    return true
  } else {
    updateWarnOnTabChangeToStore(false)
  }
  return false
}

export const getCountFieldFormateForConfig = fieldsObj => {
  let fieldsObjCopy = {}
  let qtyCopy = {}
  let extCopy = {}
  for (let key in fieldsObj) {
    if (fieldsObj.hasOwnProperty(key)) {
      let locFieldCopy = JSON.parse(JSON.stringify(fieldsObj[key]))
      if (locFieldCopy?.OjKey) {
        delete locFieldCopy?.OjKey
      }
      if (
        [
          LocationsList.Location.OjKey,
          LocationsList.SecondaryLocation1.OjKey,
          LocationsList.SecondaryLocation2.OjKey,
          LocationsList.SecondaryLocation3.OjKey
        ].includes(key)
      ) {
        delete locFieldCopy?.SortOrder
      }
      if (key !== "Qty" && key !== "Ext") {
        fieldsObjCopy = { ...fieldsObjCopy, [key]: locFieldCopy }
      } else if (key === "Qty") {
        qtyCopy = { ...locFieldCopy }
      } else if (key === "Ext") {
        extCopy = { ...locFieldCopy }
      }
    }
  }
  fieldsObjCopy = { ...fieldsObjCopy, Qty: qtyCopy, Ext: extCopy }
  let SortOrder = 1
  let FieldsObjCopyFinal = JSON.parse(JSON.stringify(fieldsObjCopy))

  for (let key in fieldsObjCopy) {
    if (
      fieldsObjCopy.hasOwnProperty(key) &&
      ![
        LocationsList.Location.OjKey,
        LocationsList.SecondaryLocation1.OjKey,
        LocationsList.SecondaryLocation2.OjKey,
        LocationsList.SecondaryLocation3.OjKey
      ].includes(key)
    ) {
      let locFieldCopy = JSON.parse(JSON.stringify(fieldsObjCopy[key]))
      FieldsObjCopyFinal = {
        ...FieldsObjCopyFinal,
        [key]: {
          ...locFieldCopy,
          SortOrder: SortOrder
        }
      }
      SortOrder = SortOrder + 1
    }
  }

  return FieldsObjCopyFinal
}

export const displaySpan = (props: any, defaultVal = "") => {
  return (
    <span className="d-block text-left">
      {props.value ? props.value : defaultVal}
    </span>
  )
}

export const onCancelClick = (history, path = "") => {
  updateWarnOnTabChangeToStore(false)
  setTimeout(() => {
    if (path) {
      history.push(path)
    } else {
      history.goBack()
    }
  })
}

export const conditionBasedReturnFieldComponent = (
  i,
  formHookMethods,
  OnChangeHandler: Function | undefined = undefined
) => {
  let disabled = i.props?.disabled ? true : false

  if ([renderType.field, undefined].includes(i?.type)) {
    if (i?.disableCondition) {
      if (
        formHookMethods?.watch(i?.disableCondition?.name) !==
        i?.disableCondition?.value
      ) {
        disabled = true
      }
    }
  }

  i = {
    ...i,
    props: {
      ...i.props,
      ...(OnChangeHandler && {
        onChange: () => {
          OnChangeHandler(i)
        }
      }),
      ...(disabled && { disabled: disabled })
    }
  }
  if ([formField.text, formField.textarea].includes(i?.fieldType)) {
    return <MUIText inputAttributes={i?.props} />
  } else if ([formField.auto_dropdown].includes(i.fieldType)) {
    return <MUIAutoCompleteText inputAttributes={i?.props} />
  } else if ([formField.multi_auto_dropdown].includes(i?.fieldType)) {
    return <MUIMultiAutoCompleteText inputAttributes={i?.props} />
  } else if ([formField.checkbox].includes(i.fieldType)) {
    return <MUICheckbox inputAttributes={i?.props} />
  } else if ([formField.radio].includes(i.fieldType)) {
    return <MUIRadio {...i?.props} />
  } else if ([formField.date].includes(i.fieldType)) {
    return <MUIDatePicker inputAttributes={i?.props} />
  } else if ([formField.time].includes(i.fieldType)) {
    return <MUITimePicker inputAttributes={i?.props} />
  }

  return null
}

export const getTabLabel = tab => {
  let locTab = Translates.Report

  if (tab.toLocaleLowerCase() === ConfigTabNames.Outputs.toLocaleLowerCase()) {
    locTab = Translates.Output
  } else if (
    tab.toLocaleLowerCase() ===
    ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
  ) {
    locTab = Translates.Multi_Event_Outputs
  }
  return { locTab }
}

export const getColumnsFromAPI = async (variableRedux: any) => {
  let query = variableRedux?.SQL
  const { columnsRedux } = store.getState().column
  if (query && query !== columnsRedux.SQL) {
    try {
      const queryToBeTested = makeClearQueryToExecute(
        query,
        variableRedux?.Prompts
      )
      const testSQLService = new SqlTestEditorService()

      await new Promise(resolve => setTimeout(resolve, 2000))
      const res = await testSQLService.postSqlTestEditorValueForVerify(
        queryToBeTested
      )
      if (Number(res.status) === Number(API_RESPONSE.SUCCESS)) {
        store.dispatch(
          setColumnsRedux({
            SQL: query,
            columns: res?.data || []
          })
        )
      }
    } catch (err: any) {
      console.log(err.Message || err)
    }
  }
}

export const setValueForVariable = ({ values, defaultValue }) => {
  let newValue: any = defaultValue
  const BreakException = {}
  try {
    values.forEach(el => {
      if (el) {
        if (
          typeof el !== "object" ||
          (typeof el === "object" &&
            ((Array.isArray(el) && el.length) ||
              (!Array.isArray(el) && Object.keys(el).length)))
        ) {
          newValue = el
          throw BreakException
        }
      }
    })
  } catch (e) {
    if (e !== BreakException) throw e
  }

  return newValue
}

export const handleFieldChangeRevalidation = ({
  FieldDetail,
  formHookMethods
}) => {
  const { setValue, formState, trigger, watch } = formHookMethods
  if (FieldDetail?.resetFieldNames) {
    FieldDetail?.resetFieldNames.forEach(e => {
      const { isError } = getFieldError(e.name, formState?.errors)
      if ((e?.isErrOnlyReset && isError) || !e?.isErrOnlyReset) {
        setValue(e.name, e.value, { shouldValidate: true })
      }
    })
  }

  if (FieldDetail?.triggerValidationFields) {
    FieldDetail?.triggerValidationFields?.forEach(i => {
      const { isError } = getFieldError(i.name, formState?.errors)
      if (!["", null, undefined, false].includes(watch(i.name)) || isError) {
        trigger(i.name)
      }
    })
  }
}
