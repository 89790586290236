import { useFormContext } from "react-hook-form"
import { Grid, Divider, Box, Tab, Tabs } from "@mui/material"
import React from "react"
import LocationCapture from "./LocationCapture"
import ProductMode from "./ProductMode"
import { a11yProps, TabPanel } from "./TabFunctionsAndConstant"
import { fieldNames } from "src/configurationPortal/pages/CountPrograms/Constant"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
const CPTabs = () => {
  const { t } = useTranslation<any>()
  const { watch, setValue } = useFormContext()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(fieldNames.ParentTabIndex.name, newValue)
    setValue(fieldNames.ChildTabIndex.name, 0)
  }
  const tabValue: number = watch(fieldNames.ParentTabIndex.name)
  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Divider className="dashed mt-3 mb-4" />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="custom-tabs"
          >
            <Tabs
              data-testid="CpTabs"
              value={tabValue}
              onChange={handleChange}
              aria-label="parent-tabs"
            >
              <Tab
                label={getTranslations(t, Translates.Location_Capture)}
                {...a11yProps(0, "parent")}
              />
              <Tab
                label={getTranslations(t, Translates.Product_Count_Mode)}
                {...a11yProps(1, "parent")}
              />
              <Tab
                label={getTranslations(t, Translates.Financial_Count_Mode)}
                {...a11yProps(2, "parent")}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0} type={"parent"}>
            <LocationCapture />
          </TabPanel>
          <TabPanel value={tabValue} index={1} type={"parent"}>
            <ProductMode />
          </TabPanel>
          <TabPanel value={tabValue} index={2} type={"parent"}>
            {getTranslations(t, Translates.Financial_Count_Mode_Content)} ...
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CPTabs
