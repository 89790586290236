import { formField } from "src/utils/constants"
import { FilesDetailsType } from "../Types"
import { DestinationFields } from "src/configurationPortal/pages/Components/Destination/Utils/Constant"

export const createEditFieldNames = {
  Name: { name: "Name", label: "Name" },
  Description: { name: "Description", label: "Description" },
  FileType: { name: "FileType", label: "File Type" },
  FileName: { name: "FileName", label: "File Name" },
  Reports: { name: "Reports", label: "Reports" },
  Outputs: { name: "Outputs", label: "Outputs" },
  Destination: DestinationFields
}

export const createEditOutputBundleDefaultIniValues = {
  Name: "",
  Description: "",
  FileType: "",
  Reports: [],
  Outputs: [],
  Destination: {
    IsCustomerPortal: false,
    IsFinalReport: false,
    Emails: [],
    FtpDirectory: []
  }
}

export const fileTypes = {
  Zipped: "Zipped",
  Individual: "Individual"
}
export const FilesDetailsFields: FilesDetailsType = {
  name: {
    fieldType: formField.text,
    props: {
      name: createEditFieldNames.Name.name,
      label: createEditFieldNames.Name.label,
      required: true
    }
  },
  description: {
    fieldType: formField.textarea,
    props: {
      name: createEditFieldNames.Description.name,
      label: createEditFieldNames.Description.label,
      multiline: true,
      rows: 3,
      maxRows: 5
    },
    childCols: { sm: 5 }
  },
  fileType: {
    fieldType: formField.radio,
    props: {
      name: createEditFieldNames.FileType.name,
      formControl: {
        radioGroup: {
          row: true,
          formControlLabels: [
            {
              value: fileTypes.Zipped,
              label: "Zipped_File",
              radio: { size: "medium" },
              className: "mr-7"
            },
            {
              value: fileTypes.Individual,
              label: "Individual_File",
              radio: { size: "medium" },
              className: "mr-7"
            }
          ]
        }
      }
    },
    resetFieldNames: [
      {
        name: createEditFieldNames.FileName.name,
        value: "",
        isErrOnlyReset: true
      }
    ]
  },
  fileTypeZipped: {
    fieldType: formField.text,
    condition: { name: createEditFieldNames.FileType.name, value: "Zipped" },
    props: {
      name: createEditFieldNames.FileName.name,
      label: createEditFieldNames.FileName.label,
      required: true
    }
  }
}
