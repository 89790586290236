import { Alert, AlertTitle } from "@mui/material"
import React from "react"
import InfoIcon from "@mui/icons-material/Info"
import {
  Csv_Template,
  custom_title_Info,
  getTranslations
} from "src/utils/helper"
import { t } from "i18next"
import { Translates } from "src/i18n/i18n"
import { BULK_UPLOADING, templateFileMap } from "src/utils/constants"

const BulkUploadAlert = ({ templateUrl, downloadType }) => {
  const DEF_FILE_NAME = "upload_file_template.csv"
  const getFileName = () => {
    let fName = templateFileMap[downloadType]
    return fName || DEF_FILE_NAME
  }

  return (
    <>
      {" "}
      <Alert severity="info" icon={<InfoIcon />} className="custom-alert grey">
        <AlertTitle className="custom-alert__title">
          {getTranslations(t, custom_title_Info())}
        </AlertTitle>
        <p>{getTranslations(t, Csv_Template())}</p>
        <a
          className="primary-text f-600"
          style={{ textDecoration: "none" }}
          href={`${templateUrl}`}
          download={getFileName()}
        >
          {downloadType === BULK_UPLOADING.LOCATION_FILE_UPLOADING
            ? getTranslations(t, Translates.Locations_Data_Import_Template)
            : ""}

          {downloadType === BULK_UPLOADING.SCHEDULE_FILE_UPLOADING
            ? getTranslations(t, Translates.Schedule_Data_Import_Template)
            : ""}
          {downloadType === BULK_UPLOADING.USER_DEATILS_FILE_UPLOADING
            ? getTranslations(t, Translates.Users_Data_Import_Template)
            : ""}
          {downloadType === BULK_UPLOADING.DEVICE_RESERVATION
            ? getTranslations(t, Translates.Device_Data_Import_Template)
            : ""}
          {[
            BULK_UPLOADING.UPLOADFILE,
            BULK_UPLOADING.DEPARTMENT_FILE_UPLOADING,
            BULK_UPLOADING.VARIANCE_FILE_UPLOADING,
            BULK_UPLOADING.AREA_RANGE_FILE_UPLOADING
          ].includes(downloadType)
            ? getTranslations(t, Translates.Upload_File_Import_Templete)
            : ""}
        </a>
      </Alert>
    </>
  )
}

export default BulkUploadAlert
