import {
  LookupRoutineCopyOverRide,
  lookupTableOptions,
  TrueOrFalse
} from "./fieldOptions"
import functionNames, {
  stringWithNotRequired,
  stringWithRequired
} from "./functionNames"

const functionTypes = functionNames
const SIDEBAR_ITEM = "sidebarItem"

const lookupFunctions = [
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Lookup,
    content: "Lookup",
    parameters: [],
    inputType: [
      {
        type: "dropdown",
        label: "Table Name*",
        name: "parameter1",
        options: lookupTableOptions, //come from api so load option data while load fields
        validation: {
          ...stringWithRequired
        }
      },
      {
        type: "dropdown",
        label: "Field Name*",
        name: "parameter2",
        options: [], //come from api so load option data while load fields
        validation: {
          ...stringWithRequired
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.LookupRoutine,
    content: "Lookup Routine",
    parameters: [],
    inputType: [
      {
        type: "dropdown",
        label: "Name Of Lookup Routine*",
        name: "parameter1",
        options: [],
        validation: {
          ...stringWithRequired
        }
      },
      {
        type: "dropdown",
        label: "Error If Not Found*",
        name: "parameter2",
        options: TrueOrFalse,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: TrueOrFalse.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Error Message*",
        name: "parameter3",
        conditions: { name: "parameter2" },
        validation: { ...stringWithNotRequired }
      },
      {
        type: "dropdown",
        label: "Allow Override*",
        name: "parameter4",
        options: TrueOrFalse,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: TrueOrFalse.map(i => i.value) }
        }
      },
      {
        type: "dropdown",
        label: "Copy Override Value To",
        name: "parameter5",
        options: LookupRoutineCopyOverRide,
        validation: {
          ...stringWithNotRequired,
          ...{
            oneOf: [...[""], ...LookupRoutineCopyOverRide.map(i => i.value)]
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Error,
    content: "Error",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Message",
        name: "parameter1",
        validation: { ...stringWithRequired }
      },
      {
        type: "dropdown",
        label: "Allow Override*",
        name: "parameter2",
        options: TrueOrFalse,
        validation: {
          ...stringWithNotRequired,
          ...{ oneOf: [...[""], ...TrueOrFalse.map(i => i.value)] }
        }
      },
      {
        type: "dropdown",
        label: "Copy Override Value To",
        name: "parameter3",
        options: LookupRoutineCopyOverRide,
        validation: {
          ...stringWithNotRequired,
          ...{
            oneOf: [...[""], ...LookupRoutineCopyOverRide.map(i => i.value)]
          }
        }
      }
    ]
  }
].sort((a, b) => {
  if (a.function > b.function) {
    return 1
  } else if (b.function > a.function) {
    return -1
  } else {
    return 0
  }
})
export default lookupFunctions
