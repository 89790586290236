import BreadCrumb from "../../BreadCrumb/BreadCrumb"
import { Button, Grid, Tooltip } from "@mui/material"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../../src/constants/paths"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
const OutputFilesToolBar = ({ outputFilesSaveClick, setIsTabChangeCheck }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config.configType
  )
  let search = window.location.search || ""
  const history = useHistory()
  const getBreadcrumbConfig = () => {
    let currentPath: string = `outputFiles`
    let variables: any = {
      outputFiles: getTranslations(t, Translates.Outputs)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const handleNewOutputFileClick = () => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
        isEdit: false
      })
    })
  }
  const outputSaveClick = () => {
    outputFilesSaveClick()
  }
  return (
    <div className="main-toolbar" data-testid="output-tool-bar">
      <div className="main-container">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className="custom-breadcrumb CP">
              <BreadCrumb
                customPath={"OutputFiles"}
                breadCrumbInfo={getBreadcrumbConfig()}
                renderedPage={""}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <Button
                  disabled={configTypeFromStore === "view"}
                  data-testid="saveBtn"
                  variant="text"
                  className="icon-btn mx-2"
                  onClick={outputSaveClick}
                >
                  <SaveOutlinedIcon />
                </Button>
              </Tooltip>
              <Button
                data-testid="newOutputFile"
                variant="contained"
                className="primary-btn"
                onClick={handleNewOutputFileClick}
                disabled={configTypeFromStore === "view"}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Output)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default OutputFilesToolBar
