import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { PATH } from "src/constants/paths"
import { getUserAccounts } from "src/redux/slices/usersSlice"
import {
  setCustomerForOperation,
  setCustomers
} from "../../../src/redux/slices/settingsSlice"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import { getConfigurationDecryptAndSetRedux } from "src/utils/helper"

const MiniHeader: React.FC<any> = props => {
  let showCustName = props.showCustName === false ? false : true
  let titleStr = props.headerTitle
  const dispatch = useAppThunkDispatch()
  const [title, setTitle] = useState("")
  const customerList = useSelector(
    (state: RootState) => state.settings?.customers
  )
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const configSliceData = useSelector((state: RootState) => state.config)
  const authSliceData = useSelector((state: RootState) => state.auth)
  const configDataFromStore = configSliceData?.configDetails
  const configNameFromStore = configSliceData?.configName
  const userAccounts = useSelector(
    (state: RootState) => state.user?.userAccounts
  )
  const [configName, setConfigName] = useState("")
  const [version, setVersion] = useState("")
  const location = useLocation()
  const userEmail = localStorage.getItem("username") || ""

  useEffect(() => {
    if (configDataFromStore?.configuration && authSliceData?.keys?.length) {
      const configObject = getConfigurationDecryptAndSetRedux(configSliceData)
      setConfigName(configObject?.GeneralSettings?.ConfigurationName)
      setVersion(configObject?.GeneralSettings?.ConfigurationVersion)
    }
  }, [configDataFromStore, authSliceData?.keys])
  useEffect(() => {
    setConfigName(configNameFromStore)
  }, [configNameFromStore])

  const getCustomerList = pathStr => {
    if (!userAccounts?.length) {
      dispatch(getUserAccounts(userEmail))
        .then((temp: any) => {
          setUserDetails(temp?.payload?.data, pathStr)
        })
        .catch(err => {
          return null
        })
    } else {
      setUserDetails(userAccounts, pathStr)
    }
  }

  const setUserDetails = (data, pathStr) => {
    let res = data || []
    res = res.map((c: any) => {
      return {
        name: c?.companyName,
        img: c?.companyLogo,
        selected: c?.isCustomerSelected,
        idCustomer: c?.idCustomer,
        ...c
      }
    })
    let selected = res?.filter(c => c.isCustomerSelected)
    // setSelectedCustomers(selected)
    if (selected?.length == 1) {
      dispatch(setCustomerForOperation(selected[0]?.idCustomer))
    } else if (props.multiCustomer) {
      props.multiCustomer()
    }
    dispatch(setCustomers(res))
    if (selected.length === 1 && showCustName && !titleStr.includes("-")) {
      if (pathStr === PATH.CONFIGURATION.replace("/", "")) {
        titleStr += ` - ${
          selected[0]?.companyName?.toUpperCase() +
          " - " +
          (configName || configDataFromStore?.name) +
          " V " +
          (version || configDataFromStore?.version)
        }`
      } else {
        titleStr += ` - ${selected[0]?.companyName?.toUpperCase()}`
      }
      setTitle(titleStr)
    }
  }

  let configPath: any
  const prepareTitle = () => {
    let pathSplit = location?.pathname && location?.pathname?.split("/")
    if (pathSplit?.length && pathSplit?.length > 2) {
      configPath = pathSplit[1]
    }
    if (customerList?.length && customerId) {
      const selectedCust: any = customerList.find(
        (c: any) => c?.idCustomer === customerId
      )
      if (selectedCust && showCustName && !titleStr?.includes("-")) {
        if (configPath === PATH.CONFIGURATION.replace("/", "")) {
          titleStr += ` - ${
            selectedCust?.companyName?.toUpperCase() +
            " - " +
            (configName || configDataFromStore?.name) +
            " V " +
            (version || configDataFromStore?.version)
          }`
        } else {
          titleStr += ` - ${selectedCust?.companyName?.toUpperCase()}`
        }
        setTitle(titleStr)
      }
    } else {
      getCustomerList(configPath)
    }
  }
  useEffect(() => {
    prepareTitle()
  }, [customerId, props?.headerTitle])
  return (
    <header className={`header`}>
      <div className="main-container">
        <h6 className="white-text">{title}</h6>
      </div>
    </header>
  )
}

export default MiniHeader
