/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  Tooltip
} from "@mui/material"
import MainLayout from "../../../src/layouts/MainLayout"
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
import React, { useEffect, useState } from "react"

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import UploadFileDetails from "./UploadFileDetails"
import UploadStatusDetails from "./UploadStatusDetails"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { getTabValue } from "../Configuration/helper"
import { useHistory } from "react-router-dom"
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
const UploadFiles: React.FC<any> = () => {
  // @ts-ignore
  const { t } = useTranslation<any>()
  const [showFilter, setShowFilter] = useState(false)
  const [filterNum, setFilterNum] = useState<any>(0)
  const [tabValue, setTabValue] = React.useState(0)
  const history = useHistory()

  useEffect(() => {
    const tabParam = getTabValue()
    if (tabParam && Number(tabParam) !== tabValue) {
      setTabValue(Number(tabParam))
    }
  }, [])

  const getBreadcrumbConfig = () => {
    let currentPath: string = `uploadFiles`
    let variables: any = {
      uploadFiles:
        tabValue === 0
          ? `${getTranslations(t, Translates.Upload_Files)}`
          : `${getTranslations(t, Translates.Upload_Status)}`
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }

 

  const handleFilter = () => {
    setShowFilter(true)
  }
  const handleFilterClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    handleFilter()
  }
  const hideFilter = () => {
    setShowFilter(false)
  }
  const sendFilterDataToParent = droppedOffAt => {
    setFilterNum(droppedOffAt ? 1 : 0)
    return droppedOffAt ? 1 : 0
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
    history.push(`?tab=${newValue}`)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  return (
    <div>
      <MainLayout>
        <div className="main-toolbar">
          <div className="main-container">
            <Grid container>
              <Grid item xs={12} sm={9} className="d-flex align-items-center">
                <div className="custom-breadcrumb CP">
                  <BreadCrumb
                    customPath={"upload files"}
                    breadCrumbInfo={getBreadcrumbConfig()}
                    renderedPage={""}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3} className="text-right">
                {tabValue === 1 ? (
                  <Tooltip
                    arrow
                    title={`${getTranslations(t, Translates.Filter)}`}
                  >
                    <Button
                      variant="text"
                      className="icon-btn mx-2"
                      onClick={handleFilterClick}
                    >
                      <FilterListOutlinedIcon />
                      {filterNum !== 0 ? (
                        <span className="filter-icon__count">{filterNum}</span>
                      ) : null}
                    </Button>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="main-container">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="custom-tabs"
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                data-testid="tabData"
              >
                <Tab
                  label={getTranslations(t, Translates.Upload_Files)}
                  data-testid="Upload Files"
                  {...a11yProps(0)}
                />
                <Tab
                  label={getTranslations(t, Translates.Upload_Status)}
                  data-testid="Upload Status"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <UploadFileDetails />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <UploadStatusDetails
                hideFilter={hideFilter}
                showFilterPage={showFilter}
                sendFilterDataToParent={sendFilterDataToParent}
              />
            </TabPanel>
          </Box>
        </div>
      </MainLayout>
    </div>
  )
}
export default UploadFiles
