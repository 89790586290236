import React, { useState } from "react"
import {
  FormControl,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { RootState } from "../../redux/store"
import { useSelector, useDispatch } from "react-redux"
import { setCustomerForOperation } from "../../redux/slices/settingsSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

const SelectCustomerDropdown = ({ open, close, onContinue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation<any>()
  const customers = useSelector((state: RootState) => state.settings.customers)
  const selectedCustomers: any = customers.filter((c: any) => c.selected)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const handleChangeOption = e => {
    setSelectedCustomer(e.target.value)
  }
  const handleContinue = () => {
    dispatch(setCustomerForOperation(parseInt(selectedCustomer)))
    onContinue()
  }
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      className="custom-dialog"
    >
      <div>
        <Grid container className="custom-dialog__header">
          <Grid
            className="custom-dialog__header-close"
            data-testid="SCDCloseClick"
            onClick={close}
          >
            <Button
              variant="text"
              className="secondary-btn"
              startIcon={<CloseIcon />}
            >
              {getTranslations(t, Translates.Close)}
            </Button>
          </Grid>
          <h2>{getTranslations(t, Translates.Select_Customer)}</h2>
        </Grid>
        <DialogContent className="custom-dialog__cont">
          <p>
            {getTranslations(t, Translates.Select_customer_from_the_below_list)}
          </p>
          <Grid container>
            <Grid item xs={12} sm={5}>
              <FormControl
                variant="filled"
                className="custom-form-control select"
              >
                <InputLabel>
                  {getTranslations(t, Translates.Select_Customer)}
                </InputLabel>
                <Select
                  data-testid="SCDSelectField"
                  className="img-list"
                  onChange={handleChangeOption}
                  value={selectedCustomer}
                >
                  {selectedCustomers.map((c: any) => {
                    return (
                      <MenuItem
                        className="img-list"
                        value={c.idCustomer}
                        key={c.companyName}
                      >
                        <img src={c.companyLogo} alt="customer logo" />
                        {c.companyName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="custom-dialog__actions">
          <Button
            variant="contained"
            className="primary-btn"
            onClick={handleContinue}
            data-testid="continue"
            disabled={!selectedCustomer}
          >
            {getTranslations(t, Translates.Continue)}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default SelectCustomerDropdown
