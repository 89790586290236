import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import moment from "moment"
import { ReportService } from "src/service/reports.service"

export interface ReportsState {
  status: string
  finalReports: any
  reportFilters: any
  reportPrevFilters: any
  reportSort: any
  reportColumn: any
}

const getDefaultStartDate = () => {
  const today = new Date()
  const thirtyDaysAgo = moment(today).subtract(30, "days").toDate()
  return thirtyDaysAgo.toISOString().split("T")[0]
}
const getDefaultEndDate = () => {
  const today = new Date()
  const thirtyDaysLater = moment(today).toDate()
  return thirtyDaysLater.toISOString().split("T")[0]
}
const initialState: ReportsState = {
  status: "",
  finalReports: [],
  reportFilters: {
    filesData: [],
    dateRange: { sDate: getDefaultStartDate(), eDate: getDefaultEndDate() },
    storesData: [],
    region1: [],
    region2: [],
    region3: [],
    region4: []
  },
  reportPrevFilters: {
    filesData: [],
    sDate: "",
    storesData: [],
    region1: [],
    region2: [],
    region3: [],
    region4: []
  },
  reportSort: "",
  reportColumn: ""
}
const reportService = new ReportService()

export const getFinalReports = createAsyncThunk(
  "reportsList/getFinalReports",
  async (params: any) => {
    let customerId = [params.customerId]
    return reportService.getCustomerFinalReports(customerId)
  }
)
export const reportsSlice = createSlice({
  name: "reportsList",
  initialState,
  reducers: {
    setReportFilters: (state, _action: PayloadAction<any>) => {
      // console.log(_action.payload)
      state.reportFilters = { ...state.reportFilters, ..._action.payload }
    },
    setReportPrevFilters: (state, _action: PayloadAction<any>) => {
      state.reportPrevFilters = {
        ...state.reportPrevFilters,
        ..._action.payload
      }
    },
    setReportSort: (state, _action: PayloadAction<any>) => {
      state.reportSort = _action.payload
    },
    setReportColumn: (state, _action: PayloadAction<any>) => {
      state.reportColumn = _action.payload
    }
  },

  extraReducers: builder => {
    //istanbul ignore next
    builder.addCase(getFinalReports.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.finalReports = payload.data
    })
    builder.addCase(getFinalReports.rejected, state => {
      state.status = "failed"
    })
  }
})
export const {
  setReportFilters,
  setReportPrevFilters,
  setReportSort,
  setReportColumn
} = reportsSlice.actions
export default reportsSlice.reducer
