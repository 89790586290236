import { useEffect, useReducer } from "react"
import { useHistory } from "react-router-dom"
import { Button, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import { PATH } from "src/constants/paths"
import { useSelector } from "react-redux"

import GlobalTable from "src/components/GlobalTable/GlobalTable"
import {
  getColumnsData,
  setModalContent
} from "./Utils/Helpers/OutputBGridHelper"
import { setOutputBundlesRedux } from "src/redux/slices/ConfigPortal/OutputBundleSlice"
import {
  ConfigOutputBundlePreDeletedRowType,
  ConfigOutputBundleType
} from "./Utils/Types"
import AddIcon from "@mui/icons-material/Add"
let preDeletedRow: ConfigOutputBundlePreDeletedRowType =
  {} as ConfigOutputBundlePreDeletedRowType
let showWarningModal: boolean = false

const OutputBundles = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [, setReload] = useReducer(reducer, false)
  const dispatch = useAppThunkDispatch()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const {
    outputBundleData: { outputBundlesRedux }
  } = ConfigDataHook()

  let search = window.location.search || ""

  const createOutputBundle = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.OUTPUT_BUNDLE_CREATE + search)
    })
  }

  const copyOutputBundle = (row1: { original: ConfigOutputBundleType }) => {
    let row = row1.original
    const _item: ConfigOutputBundleType = {
      ...row,
      ...{
        Name: `Copy of ${row.Name}`
      }
    }

    dispatch(setOutputBundlesRedux([...outputBundlesRedux, _item]))
  }
  const updateOutputBundle = (row1: {
    original: ConfigOutputBundleType
    index: number
  }) => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(
        PATH.OUTPUT_BUNDLE_EDIT.replace(":id", `${row1.index + 1}`) + search
      )
    })
  }
  const deleteOutputBundle = (row1: {
    original: ConfigOutputBundleType
    index: number
  }) => {
    showWarningModal = true
    preDeletedRow = { ...row1.original, index: row1.index }
    setReload(oldVal => !oldVal)
  }

  const CallbackConfirmation = (res: boolean) => {
    if (res) {
      let dt: ConfigOutputBundleType[] = outputBundlesRedux.filter(
        (f: ConfigOutputBundleType, inx) => inx !== preDeletedRow.index
      )
      dispatch(setOutputBundlesRedux([...dt]))
    }
    showWarningModal = false
    preDeletedRow = {} as ConfigOutputBundlePreDeletedRowType
    setReload(oldVal => !oldVal)
  }

  const columnsData = getColumnsData({
    copyOutputBundle: copyOutputBundle,
    updateOutputBundle: updateOutputBundle,
    deleteOutputBundle: deleteOutputBundle,
    t,
    configTypeFromStore
  })
  return (
    <Grid
      container
      className="custom-table-responsive categories-table"
      data-testid="reportings-display-grid"
    >
      <Grid item sm={6} className="d-flex align-items-center">
        <h6>{getTranslations(t, Translates.Output_Bundle)}</h6>
      </Grid>
      <Grid item sm={6} className="text-right">
        <Button
          variant="contained"
          data-testid="OBNOBundles"
          className="primary-btn"
          onClick={createOutputBundle}
          disabled={configTypeFromStore === "view"}
        >
          <AddIcon className="mr-1" />{" "}
          {getTranslations(t, Translates.New_Output_Bundle)}
        </Button>
      </Grid>
      <Grid xs={12} item className="mt-2">
        <GlobalTable
          component={TableComponentNames.outputBundles}
          columns={columnsData}
          data={outputBundlesRedux || []}
          noDataText={getTranslations(
            t,
            Translates.No_Output_Bundles_Available
          )}
        />
      </Grid>
      {showWarningModal && (
        <ConfirmDialog
          open={showWarningModal}
          callBack={CallbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setModalContent({
            preDeletedRow,
            t
          })}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.CANCEL)}
        />
      )}
    </Grid>
  )
}

export default OutputBundles
