//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class ReportService {
  getCustomerFinalReports(customerIds) {
    const endPoint = `/api/Reports/GetCustomerFinalReports`
    return api.post(endPoint, customerIds)
  }
  downloadReports(payload: any) {
    const endPoint = "/api/Reports/DownloadReports"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
}
//END-NOSCAN