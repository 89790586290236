export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AD_AUTHORITY_URL
    }
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN
}

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [
      b2cPolicies.authorityDomain,
      process.env.REACT_APP_KNOWN_AUTH
    ],
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
      }
    }
  }
}
export const tokenRequest = {
  scopes: [`${process.env.REACT_APP_REQUEST_SCOPE}`]
}
export const request = {
  loginHint: "",
  domainHint: "",
  scopes: [`${process.env.REACT_APP_REQUEST_SCOPE}`]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}
export const loginRequest = {
  scopes: []
}
