import React, { useEffect, useState } from "react"
import { Grid, Tooltip } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import shortid from "shortid"
import { useTranslation } from "react-i18next"
import MUICheckbox from "src/components/MUIFormFields/MUICheckbox"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import MUIAutoCompleteText, {
  Props as MUIAutoCompleteTextProps
} from "src/components/MUIFormFields/MUIAutoCompleteText"
import { DestinationFields1, DestinationFieldsRender } from "./Utils/Constant"
import MUIMultiAutoCompleteTextChips from "src/components/MUIFormFields/MUIMultiAutoCompleteTextChips"
import { ConfigService } from "src/service/config.service"
import MUIMultiAutoCompleteText from "src/components/MUIFormFields/MUIMultiAutoCompleteText"
import { ConfigTabNames } from "../../helper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import FormHeading from "src/components/FormHeading"

const Destination = ({
  componentName,
  isFinalReport,
  isFTPMultiple
}: {
  componentName: string
  isFinalReport?: boolean
  isFTPMultiple?: boolean
}) => {
  const { t } = useTranslation<any>()
  const configService = new ConfigService()
  const [connections, setConnections] = useState<
    MUIAutoCompleteTextProps["inputAttributes"]["options"]
  >([])

  const {
    settings: { customers }
  } = ConfigDataHook()

  const selectedCustomerList = customers?.filter((c: any) => c.selected)

  const GetConnectionData = async () => {
    try {
      const payload = selectedCustomerList.map(i => i.idCustomer)
      const res = await configService.getConnectionData(payload)
      let locFtpConnections = res.data || []
      locFtpConnections = locFtpConnections.map(ftp => ({
        label: ftp.name,
        value: ftp.name
      }))
      if (!isFTPMultiple) {
        locFtpConnections.unshift({
          label: "None",
          value: "None"
        })
      }
      setConnections(locFtpConnections)
    } catch (_error) {
      return _error
    }
    return true
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted && customers) {
      GetConnectionData()
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [customers])

  const FTPInputAttr = {
    ...DestinationFieldsRender.ftpDirectory.props,
    options: connections
  }

  let emailName = DestinationFieldsRender.emails.props.name

  if (
    [
      ConfigTabNames.Reports.toLocaleLowerCase(),
      ConfigTabNames.Outputs.toLocaleLowerCase(),
      ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
    ].includes(componentName.toLocaleLowerCase())
  ) {
    emailName = DestinationFields1.Emails.name
  }
  return (
    <>
      <FormHeading
        heading={`${getTranslations(t, Translates.Destination)}`}
        isDivider={true}
      />

      <Grid item xs={12} sm={12}>
        <Grid container spacing={2}>
          {[
            DestinationFieldsRender.isCustomerPortal,
            DestinationFieldsRender.isFinalReport
          ].map((i, j) => {
            let display = true
            let disabled = false
            let name = i.props?.name
            if (j === 0) {
              if (
                [
                  ConfigTabNames.Reports.toLocaleLowerCase(),
                  ConfigTabNames.Outputs.toLocaleLowerCase(),
                  ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
                ].includes(componentName.toLocaleLowerCase())
              ) {
                disabled = true
                name = DestinationFields1.IsCustomerPortal.name
              }
            } else if (j === 1 && !isFinalReport) {
              display = false
            }
            return (
              <React.Fragment key={i.props?.name}>
                {display ? (
                  <Grid item xs={12} sm={3}>
                    <MUICheckbox
                      inputAttributes={{
                        ...i.props,
                        name: name,
                        disabled: disabled
                      }}
                    />
                  </Grid>
                ) : null}
              </React.Fragment>
            )
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-3">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <label className="mb-2 f-600">
              {getTranslations(t, Translates.Email)}
            </label>
            <Tooltip
              id="emailTooltip"
              arrow
              title={
                <div className="pa-2">
                  <h6 className="mb-2 f-600 text-uppercase">
                    <InfoIcon className="primary-text mr-1" />
                    {getTranslations(t, Translates.Email)}
                  </h6>

                  {getTranslations(t, Translates.Email_Text)
                    .split("\n")
                    .map((line, index) => (
                      <div className="mb-1" key={shortid.generate()}>
                        {line}
                      </div>
                    ))}
                </div>
              }
            >
              <InfoOutlinedIcon className="primary-text ml-1" />
            </Tooltip>

            <MUIMultiAutoCompleteTextChips
              inputAttributes={{
                ...DestinationFieldsRender.emails.props,
                name: emailName
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <label className="mb-2 f-600">
              {getTranslations(t, Translates.Ftp_Directory)}
            </label>
            {isFTPMultiple ? (
              <MUIMultiAutoCompleteText inputAttributes={FTPInputAttr} />
            ) : (
              <MUIAutoCompleteText inputAttributes={FTPInputAttr} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Destination
