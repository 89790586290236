//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"
let encrypt: any

async function getEncryptFunction() {
  if (!encrypt) {
    // Import encrypt only when needed
    const { encrypt: importedEncrypt } = await import("src/utils/helper")
    encrypt = importedEncrypt
  }
  return encrypt
}

const api = new ApiService()
export class ScheduleService {
  saveSchedule(payload) {
    const endPoint: string = `api/schedule`
    return api.post(endPoint, payload)
  }
  updateSchedule(payload) {
    const endPoint: string = `api/schedule`
    return api.put(endPoint, payload)
  }
  getCountryDevices() {
    const endPoint: string = `api/Schedule/GetCountryDevices`
    return api.findAll(endPoint)
  }
  getviewschedule(payload: any) {
    const endPoint = "api/Schedule/ViewScheduleDetails"
    return api.post(endPoint, payload)
  }

  deleteschedule(customerId) {
    const endPoint = `api/Schedule/CancelSchedule?eventId=${customerId}`
    return api.put(endPoint, {})
  }

  editschedule(customerId) {
    const endPoint: string = `api/Schedule/GetScheduleById?eventid=${customerId}`
    return api.findAll(endPoint)
  }
  getScheduleById(id) {
    const endPoint = `/api/Schedule/GetScheduleById?eventid=${id}`
    return api.findAll(endPoint)
  }
  getEventPasswords(id) {
    const endPoint = `/api/Schedule/GetEventPasswords?eventid=${id}`
    return api.findAll(endPoint)
  }
  async bulkUpdateSchedule(payload: any, config: any) {
    let params = { ...payload }
    const encryptFunction = await getEncryptFunction()
    const fileData = params.fileData ? encryptFunction(params.fileData) : ""
    params.fileData = fileData
    const endPoint = "/api/Schedule/AddSchedulesBulk"
    return api.saveWithParams(endPoint, params, config)
  }
  sendEmail(payload: any, config: any) {
    const endPoint: string = `/api/Schedule/SendEmailWithQRCode`
    return api.savefile(endPoint, payload, config)
  }
  getEventDetailsForConfig(payload: any[]) {
    const endPoint = `/api/Schedule/GetEventsDetails`
    return api.post(endPoint, payload)
  }
  UpdateEventsConfigDetails(payload: any) {
    const endPoint = `/api/Schedule/UpadateEventsConfigDetails`
    return api.put(endPoint, payload)
  }
  getFutureEventsDetails(CustomerId, configId) {
    const endPoint = `api/Schedule/GetFutureEventsDetails?CustomerId=${CustomerId}&configId=${configId}`
    return api.findAllFutureEvents(endPoint)
  }
  getFutureEventsForDeactivate(CustomerId, configId) {
    const endPoint = `api/Schedule/GetFutureEventsData?CustomerId=${CustomerId}&configId=${configId}`
    return api.findAllFutureEvents(endPoint)
  }
  getSiteIdList(customerId) {
    const endPoint = `api/Location/GetAllSiteIdByAccount/${customerId}`
    return api.findAll(endPoint)
  }
  unlockEvent(payload) {
    const endPoint: string = `api/schedule/UnlockEvent`
    return api.put(endPoint, payload)
  }
}
//END-NOSCAN
