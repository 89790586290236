import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import {
  setScheduleFilterForm,
  setScheduleFilters
} from "../../../src/redux/slices/scheduleSlice"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import FilterComponent from "src/utils/reusables/FilterComponent"
import {
  dateChangeHandler,
  filterAndSort,
  getEventStatusList,
  getRegionData,
  handleChangeCountryVal,
  handleChangeRegion,
  handleChangeSiteIdVal,
  handleChangeStateVal,
  handleChangeStatusVal,
  handleScheduleResetAll,
  sortData,
  handleChangeConfigVal
} from "src/utils/helper"
import { InvalidDate } from "src/utils/constants"
import ScheduleFilterComponent from "./ScheduleFilterComponent"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    },
    anchororigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformorigin: {
      vertical: "top",
      horizontal: "left"
    },
    getcontentanchorel: null
  }
}

interface SReservation {
  scheduledDateTime: string
}

const ScheduleFilter = ({
  storeData,
  userData,
  cancelClick,
  applyClick,
  countries,
  states,
  status,
  showFilterPage,
  multipleCustomersAssigned,
  siteIds,
  configNames
}) => {
  const [stateName, setStateName] = React.useState([] as any)
  const [configName, setConfigName] = React.useState(["All"] as any)
  const dispatch = useAppThunkDispatch()
  const { t } = useTranslation<any>()
  const [statusName, setStatusName] = React.useState([] as any)
  const [startDate, setStartDate] = React.useState<string | null>(null)
  const [endDate, setEndDate] = React.useState<string | null>(null)
  const [region1, setRegion1] = React.useState([] as any)
  const [region2, setRegion2] = React.useState([] as any)
  const [region3, setRegion3] = React.useState([] as any)
  const [region4, setRegion4] = React.useState([] as any)
  const scheduleFilter = useSelector(
    (state: RootState) => state.schedule.scheduleFilters
  )
  const [filteredRegion1, setFilteredRegion1] = useState([] as any)
  const [filteredRegion2, setFilteredRegion2] = useState([] as any)
  const [filteredRegion3, setFilteredRegion3] = useState([] as any)
  const [filteredRegion4, setFilteredRegion4] = useState([] as any)
  const [countryName, setCountryName] = React.useState([] as any)
  const [siteIdSelects, setSiteIdSelects] = React.useState([] as any)
  const [inValidStartDateFormat, setInValidStartDateFormat] = useState(false)
  const [inValidEndDateFormat, setInValidEndDateFormat] = useState(false)

  const handleChange = (event, id) => {
    handleChangeRegion(
      event,
      id,
      setRegion1,
      setRegion2,
      setRegion3,
      setRegion4
    )
  }
  const handleChangeCountry = event => {
    handleChangeCountryVal(event, setCountryName)
  }
  const handleChangeSiteId = event => {
    handleChangeSiteIdVal(event, setSiteIdSelects)
  }
  const handleChangeStatus = event => {
    handleChangeStatusVal(event, setStatusName, statusName, uniqStatus)
  }

  const handleChangeState = event => {
    handleChangeStateVal(event, setStateName)
  }
  const handleChangeConfig = event => {
    handleChangeConfigVal(event, setConfigName)
  }
  useEffect(() => {
    getRegionData(
      userData,
      setFilteredRegion1,
      setFilteredRegion2,
      setFilteredRegion3,
      setFilteredRegion4
    )
    setRegion1(scheduleFilter?.region1 || [])
    setRegion2(scheduleFilter?.region2 || [])
    setRegion3(scheduleFilter?.region3 || [])
    setRegion4(scheduleFilter?.region4 || [])
    setStateName(scheduleFilter?.state || [])
    setCountryName(scheduleFilter?.country || [])
    setStatusName(scheduleFilter?.eventStatus || [])
    setStartDate(scheduleFilter?.startDate || "")
    setEndDate(scheduleFilter?.endDate || "")
    setConfigName(
      scheduleFilter?.configNames.length > 0
        ? scheduleFilter?.configNames
        : ["All"]
    )
  }, [scheduleFilter])

  let filteredCountries = countries.filter(function (el) {
    return el != null || ""
  })
  let uniqCountries: any = Array.from(new Set(filteredCountries))
  uniqCountries = sortData(uniqCountries)
  let filteredStates = states.filter(function (el) {
    return el != null || ""
  })
  let uniqStates: any = Array.from(new Set(filteredStates))
  uniqStates = filterAndSort(uniqStates)

  let uniqSiteIds: any = Array.from(new Set(siteIds))
  let uniqConfigNames = filterAndSort(configNames)

  const uniqStatus = React.useMemo(() => getEventStatusList({ t }), [t])

  const sortedStatus = uniqStatus
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))
  const isAllSelected = useMemo(
    () => 
    uniqStatus.length > 0 && statusName.length === uniqStatus.length,
    [statusName, uniqStatus]
  )
  const isAllConfigSelected = useMemo(
    () =>
    (uniqConfigNames?.length > 0 && configName?.length === uniqConfigNames?.length) || configName.includes("All"),
    [configName, uniqConfigNames]
  )
  const applyFilter = () => {
    let filtersData = {
      region1: region1,
      region2: region2,
      region3: region3,
      region4: region4,
      country: countryName,
      siteIds: siteIdSelects,
      state: stateName,
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      eventStatus: statusName,
      configNames: configName
    }
    dispatch(setScheduleFilterForm({ ...filtersData }))
    applyClick({
      ...filtersData
    })
    dispatch(setScheduleFilters({ ...filtersData }))
  }
  const applyFilterClick = () => {
    let hasInvalidDate = false
    if (startDate == InvalidDate) {
      setInValidStartDateFormat(true)
      hasInvalidDate = true
    } else {
      setInValidStartDateFormat(false)
    }
    if (endDate == InvalidDate) {
      setInValidEndDateFormat(true)
      hasInvalidDate = true
    } else {
      setInValidEndDateFormat(false)
    }

    if (!hasInvalidDate) {
      applyFilter()
    }
  }

  const onStartDateChange = e => {
    moment.locale("us_en")
    setStartDate(e.target.value)
    setInValidStartDateFormat(false)
  }
  const onEndDateChange = e => {
    moment.locale("us_en")
    setEndDate(e.target.value)
    setInValidEndDateFormat(false)
  }
  const applyCancelClick = () => {
    cancelClick()
  }

  const handleResetAllClick = () => {
    handleScheduleResetAll({
      setRegion1,
      setRegion2,
      setRegion3,
      setRegion4,
      setCountryName,
      setStateName,
      setStartDate,
      setEndDate,
      setStatusName,
      setConfigName: () => setConfigName(["All"]),
      isSchedulePage: true
    })
    setSiteIdSelects([])
  }
  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === 8 || event.keyCode === 46) {
        setStartDate("")
        setEndDate("")
      }
    },
    [setStartDate, setEndDate]
  )
  return (
    <>
      {showFilterPage ? (
        <ScheduleFilterComponent
          t={t}
          Translates={Translates}
          storeData={storeData}
          multipleCustomersAssigned={multipleCustomersAssigned}
          handleChange={handleChange}
          filteredRegion1Values={filteredRegion1}
          filteredRegion2Values={filteredRegion2}
          filteredRegion3Values={filteredRegion3}
          filteredRegion4Values={filteredRegion4}
          region1={region1}
          region2={region2}
          region3={region3}
          region4={region4}
          MenuProps={MenuProps}
          countryName={countryName}
          handleChangeCountry={handleChangeCountry}
          uniqCountries={uniqCountries}
          stateName={stateName}
          handleChangeState={handleChangeState}
          uniqStates={uniqStates}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
          handleKeyDown={handleKeyDown}
          statusName={statusName}
          handleChangeStatus={handleChangeStatus}
          isAllSelected={isAllSelected}
          sortedStatus={sortedStatus}
          handleResetAllClick={handleResetAllClick}
          applyCancelClick={applyCancelClick}
          applyFilterClick={applyFilterClick}
          uniqSiteIds={uniqSiteIds}
          siteIdSelects={siteIdSelects}
          handleChangeSiteId={handleChangeSiteId}
          isSchedulePage={true}
          inValidStartDateFormat={inValidStartDateFormat}
          inValidEndDateFormat={inValidEndDateFormat}
          uniqConfigNames={uniqConfigNames}
          configName={configName}
          handleChangeConfig={handleChangeConfig}
          isAllConfigSelected={isAllConfigSelected}
        />
      ) : null}
    </>
  )
}

export default ScheduleFilter
