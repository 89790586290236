import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Grid, Tooltip } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import CountProgramsToolBar from "../../../components/ConfigurationPortal/CountProgramsToolBar/CountProgramsToolBar"
import GlobalTable from "../../../components/GlobalTable/GlobalTable"
import trashIcon from "../../../../src/assets/images/TrashIcon.svg"
import editIcon from "../../../../src/assets/images/Edit.png"
import { setCountPrograms } from "../../../redux/slices/configSlice"
import { PATH } from "../../../constants/paths"
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog"
import { landingColumnDataDetails } from "src/configurationPortal/pages/CountPrograms/Constant"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
import { Tab } from "src/constants/configurationPortal"
import { makeLayoutAllVariableEmpty } from "../LookupRoutines/helpers"
import { setNewCountProgram } from "src/redux/slices/configSlice"
import {
  prepareDataForProductList,
  prepareDataForLocationList
} from "./Component/TabFunctionsAndConstant"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { ConfigTabNames, updateWarnOnTabChangeToStore } from "../helper"
import { getTranslations } from "src/utils/helper"
import { KEY_CODES } from "src/utils/constants"
let deleteRowIndex: number = -1
let isConfirmDeleteOpen: boolean = false
const LandingPage = ({ liftCountProgramsSaveClick }) => {
  const { t } = useTranslation<any>()
  const {
    config: {
      countPrograms,
      defaultCountFields,
      configDetails,
      configType: configTypeFromStore
    }
  } = ConfigDataHook()

  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)

  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.CountPrograms,
    isTabChangeCheck: isTabChangeCheck
  })
  const history = useHistory()
  const dispatch = useDispatch()
  let search = window.location.search || ""
  const [reload, setReload] = useState<boolean>(false)
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      makeLayoutAllVariableEmpty()
      dispatch(setNewCountProgram({}))
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (configDetails?.configuration) {
        prepareDataForProductList(defaultCountFields, true)
        prepareDataForLocationList(defaultCountFields, true)
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [defaultCountFields])

  const setIsConfirmDeleteOpen = (val: boolean) => {
    isConfirmDeleteOpen = val
    setReload(oldVal => !oldVal)
    return reload
  }
  const countProgramsSaveClick = useCallback(
    () => {
      liftCountProgramsSaveClick([...countPrograms], Tab.CountProgramsTab)
    },
    //eslint-disable-next-line
    [countPrograms]
  )
  const handleDeleteRowKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      deleteRow(row)
    }
  }, [])
  const deleteRow = (row: any) => {
    deleteRowIndex = Number(row.id)
    setIsConfirmDeleteOpen(true)
  }
  const setDeleteContent = useCallback(
    () => {
      return (
        <AlertConfirmationContent
          warningMsg={`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete
          )} ${countPrograms[deleteRowIndex].Name[0]["en-us"]}?`}
        />
      )
    },
    //eslint-disable-next-line
    [deleteRowIndex]
  )
  const callbackDeleteConfirmation = useCallback(
    (res: boolean) => {
      if (res && deleteRowIndex >= 0) {
        let dt: any
        dt = countPrograms.filter((i, j) => j !== deleteRowIndex)
        dispatch(setCountPrograms(dt))
      }
      deleteRowIndex = -1
      setIsConfirmDeleteOpen(false)
    },
    //eslint-disable-next-line
    [deleteRowIndex]
  )

  const cloneRow = (row: any) => {
    const _item = {
      ...row,
      ...{
        Name: [{ "en-us": `Copy of ${row.Name[0]["en-us"]}` }]
      }
    }
    dispatch(setCountPrograms([...countPrograms, _item]))
  }
  const handleEditRowKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      editRow(row)
    }
  }, [])
  const editRow = (row: any) => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(
        PATH.EDIT_COUNT_PROGRAMS.replace(":id", `${Number(row.id) + 1}`) +
          search
      )
    })
  }
  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }
  const displayAction = (props: any) => {
    return (
      <span className="action-btns">
        <Tooltip arrow title={getTranslations(t, Translates.Clone)}>
          <ContentCopyIcon
            data-testid="cloneBtn"
            className={`mr-2 ${
              configTypeFromStore === "view" ? "disable-img" : "font-24 pointer"
            }`}
            onClick={() => cloneRow(props.row.original)}
          />
        </Tooltip>
        <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
          <img
            alt="editImg"
            className="pointer mr-2"
            src={editIcon}
            width="24"
            onClick={() => editRow(props.row)}
            onKeyUp={e => handleEditRowKeyUp(e, props.row)}
          />
        </Tooltip>
        <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
          <span>
            <img
              alt="deleteImg"
              src={trashIcon}
              width="24"
              className={`${
                configTypeFromStore === "view" ? "disable-img" : "pointer"
              }`}
              onClick={() => deleteRow(props.row)}
              onKeyUp={e => handleDeleteRowKeyUp(e, props.row)}
            />
          </span>
        </Tooltip>
      </span>
    )
  }
  const columnsData = React.useMemo(() => {
    return landingColumnDataDetails.map((i, j) => {
      return {
        ...i,
        ...{
          Header: getTranslations(t, i.Header),
          headerClassName: "temp_class",
          disableFilters: true,
          disableSortBy: [1].includes(j) ? false : true,
          Cell: props => {
            if ([1].includes(j)) return displayAction(props)
            return displaySpan(props)
          }
        }
      }
    })
    //eslint-disable-next-line
  }, [countPrograms, t])
  return (
    <>
      <CountProgramsToolBar
        programsSaveClick={countProgramsSaveClick}
        setIsTabChangeCheck={setIsTabChangeCheck}
      />
      <div className="main-container">
        <Grid
          container
          className="custom-table-responsive categories-table"
          data-testid="CpGlobTable"
        >
          <Grid xs={12} item>
            <GlobalTable
              component="lookupRoutines"
              columns={columnsData}
              data={countPrograms}
              noDataText={`${getTranslations(
                t,
                Translates.No_Count_Programs_data_available
              )}.`}
            />
          </Grid>
          {isConfirmDeleteOpen ? (
            <ConfirmDialog
              open={isConfirmDeleteOpen}
              callBack={callbackDeleteConfirmation}
              title={getTranslations(t, Translates.DELETE)}
              content={setDeleteContent}
              custref={{}}
              confirmButtonText={getTranslations(t, Translates.CONFIRM)}
              cancelButtonText={getTranslations(t, Translates.Cancel)}
            />
          ) : null}
        </Grid>
      </div>
    </>
  )
}

export default LandingPage
