import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { VarianceType } from "src/configurationPortal/pages/Variances/Utils/Type"

export interface VarianceSliceType {
  varianceRedux: VarianceType
}

const initialState: VarianceSliceType = {
  varianceRedux: {} as VarianceType
}
export const varianceSlice = createSlice({
  name: "variance",
  initialState,
  reducers: {
    setVarianceRedux: (state, _action: PayloadAction<any>) => {
      state.varianceRedux = { ..._action.payload }
    }
  },
  extraReducers: builder => {}
})
export const { setVarianceRedux } = varianceSlice.actions
export default varianceSlice.reducer
