//BEGIN-NOSCAN
import { encrypt } from "src/utils/helper"
import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class ConfigService {
  postConfiguration(payload) {
    const endPoint = "api/ConfigControl/AddConfigurationDetails"
    let configString = payload.configuration
    configString = encrypt(configString)
    payload.configuration = configString
    return api
      .post(endPoint, payload)
      .then(res => res)
      .catch(err => err)
  }

  updateConfiguration(params) {
    let payload = { ...params }
    const endPoint = "api/ConfigControl/UpdateConfigurationData"
    let configString = payload.configuration
    configString = encrypt(configString)
    payload.configuration = configString
    return api
      .put(endPoint, payload)
      .then(res => res)
      .catch(err => err)
  }

  getConfiguration(configId) {
    const endPoint = `api/ConfigControl/GetConfigurationDetailsById?configurationId=${configId}`
    return api.findAll(endPoint)
  }

  deleteConfiguration(configId, customerId) {
    const endPoint = `api/ConfigControl/DeleteConfigurationData?configurationId=${configId}&customerId=${customerId}`
    return api.post(endPoint, {})
  }

  configurationActions(params) {
    let payload = { ...params }
    const endPoint = `api/ConfigControl/ConfigurationActions`
    if (payload && payload.hasOwnProperty("configuration")) {
      let configString = payload.configuration
      configString = encrypt(configString)
      payload.configuration = configString
    }
    return api
      .post(endPoint, payload)
      .then(res => {
        return res
      })
      .catch(err => {
        return err
      })
  }

  getConfigDetails(payload) {
    payload = [...(payload || [])].map(i => Number(i))
    const endPoint = `api/ConfigControl/GetConfigurationDetailsByCustomerId`
    return api.post(endPoint, payload)
  }

  getConnectionData(payload) {
    const endPoint = `api/Ftp/GetConnectionData`
    return api.post(endPoint, payload)
  }

  testAndSaveConnection(payload) {
    const endPoint = `api/Ftp/TestAndSaveConnection`
    return api
      .post(endPoint, payload)
      .then(res => res)
      .catch(err => err)
  }

  uploadProcedure(configId, payload) {
    const endPoint = `/api/ConfigControl/UploadProcedurePdf?configurationId=${configId}`
    return api.post(endPoint, payload)
  }

  viewProcedures(configId) {
    const endPoint = `/api/ConfigControl/ViewProcedurePdf?configurationId=${configId}`
    return api.findAll(endPoint)
  }
  deleteFtpConnection(idConfigFtpConnection) {
    const endPoint = `/api/Ftp/DeleteFtpDetailsById?idConfigFTPConnection=${idConfigFtpConnection}`
    return api.delete(endPoint, {})
  }
  getFtpDetailsById(idConfigFtpConnection) {
    const endPoint = `/api/Ftp/GetFtpDetailsById?idConfigFTPConnection=${idConfigFtpConnection}`
    return api.findAll(endPoint)
  }
  editFtpDetails(payload: any) {
    const endPoint = `/api/Ftp/UpdateFtpDetailsById`
    return api.put(endPoint, payload)
  }
  copyFtpConnection(idConfigFtpConnection, name) {
    const endPoint = `/api/Ftp/CopyFtpDetailsByIdAndName?idConfigFTPConnection=${idConfigFtpConnection}&name=${name}`
    return api.post(endPoint, {})
  }
  getCustomerDetails() {
    const endPoint = `/api/Customer/GetCustomerDetails`
    return api.findAll(endPoint)
  }
  getMultiEventsOutputs(payload: any) {
    const endPoint = `api/MultiStoreFileProcessing/GetListOfMultiEventOutputFiles`
    return api.post(endPoint, payload)
  }
  deleteMultiEventFileData(multiEventFileDetailId) {
    const endPoint = `api/MultiStoreFileProcessing/DeleteMultiEventFileDetailsData?multiEventFileDetailId=${multiEventFileDetailId}`
    return api.delete(endPoint, {})
  }
  copyMultiEventFileData(payload) {
    const endPoint = `/api/MultiStoreFileProcessing/CopyMultiEventDetails`
    return api.post(endPoint, payload)
  }
  getConfigHistoryDetails(configId) {
    const endPoint = `/api/ConfigHistory/GetConfigHistoryDetailsById?configId=${configId}`
    return api.findAll(endPoint)
  }
}
//END-NOSCAN
