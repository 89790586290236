import { FormControlLabel, Radio, RadioGroup, Switch } from "@mui/material"
import { functionTypes } from "."
import { getIfElseLayout, setIfElseLayout } from "../layout.service"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
export const handleCondition = (curRow, value, setReload) => {
  let val = value
  let rowsData = [...getIfElseLayout()]
  curRow = {
    ...curRow,
    ...{ condition: val }
  }
  rowsData.splice(curRow.index, 1, curRow)
  setIfElseLayout([...rowsData])
  setReload(oldVal => !oldVal) // updating to rerender the comp for now, need to be updated
  return true
}

export const getRadioButtonsJSX = (setReload, curRow) => {
  let checkValue = curRow?.condition || "OR"
  return (
    <div className="d-flex align-items-center">
      <span>
        {curRow.function === functionTypes.If && curRow.content !== ")"
          ? functionTypes.If
          : null}{" "}
        (
      </span>

      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={e => handleCondition(curRow, e.target.value, setReload)}
        value={checkValue}
        className={"ml-1"}
        data-testid="if-screen-radio"
      >
        <FormControlLabel
          value="OR"
          control={<Radio />}
          label="Any"
          data-testid="if-screen-radio-or"
        />
        <FormControlLabel
          value="AND"
          control={<Radio />}
          label="All"
          data-testid="if-screen-radio-and"
        />
      </RadioGroup>
    </div>
  )
}

export const getIfOrIfElseRadioButtonJSX = (
  isShowEmptyElseBlock,
  setIsShowEmptyElseBlock,
  t
) => {
  const handleIfOrIfElseRadioCondition = e => {
    const ifElseLayout = getIfElseLayout()
    setIfElseLayout([...ifElseLayout])
    setIsShowEmptyElseBlock(e.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isShowEmptyElseBlock}
          onChange={handleIfOrIfElseRadioCondition}
        />
      }
      label={getTranslations(t, Translates.Show_Empty_Else_Block)}
    />
  )
}
