import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LookupRoutinesService } from "../../../src/service/lookupRoutines.service"

export interface LookupRoutinesState {
  tableNames: {
    status: string
    data: any
  }

  tableNameOptions: any[]
}
const initialState: LookupRoutinesState = {
  tableNames: { status: "loading1", data: [] },
  tableNameOptions: []
}
const lookupService = new LookupRoutinesService()
export const getTableNamesWithColumns = createAsyncThunk(
  "lookup/getTableNamesWithColumns",
  async () => {
    return lookupService.getTableNamesWithColumns()
  }
)

export const lookupRoutinesSlice = createSlice({
  name: "lookup",
  initialState,
  reducers: {
    setTableNameOptions: (state, _action: PayloadAction<any>) => {
      state.tableNameOptions = [..._action.payload]
    }
  },
  extraReducers: builder => {
    builder.addCase(getTableNamesWithColumns.pending, state => {
      state.tableNames.status = "loading"
      state.tableNames.data = []
    })
    //istanbul ignore next
    builder.addCase(
      getTableNamesWithColumns.fulfilled,
      (state, { payload }) => {
        state.tableNames.status = "success"
        state.tableNames.data = payload.data
      }
    )
    builder.addCase(getTableNamesWithColumns.rejected, state => {
      state.tableNames.status = "failed"
      state.tableNames.data = []
    })
  }
})
export const { setTableNameOptions } = lookupRoutinesSlice.actions
export default lookupRoutinesSlice.reducer
