import { Button, Grid, Tooltip } from "@mui/material"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { useHistory } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import { PATH } from "../../../../src/constants/paths"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import BreadCrumbC from "../ToolBar/BreadCrumbC"
const LookupRoutinesToolBar = ({
  lookupRoutinesSaveClick,
  setIsTabChangeCheck
}: {
  lookupRoutinesSaveClick: Function
  setIsTabChangeCheck: Function
}) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config.configType
  )
  const history = useHistory()
  let search = window.location.search || ""
  const getBreadcrumbConfig = () => {
    let currentPath: string = `lookupRoutines`
    let variables: any = {
      lookupRoutines: getTranslations(t, Translates.Lookup_Routines)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const lookupRoutinesToolbarSaveClick = () => {
    lookupRoutinesSaveClick()
  }
  const handleNewLookupRoutineClick = () => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIG_NEW_LOOKUP_ROUTINE + search)
    })
  }

  return (
    <div className="main-toolbar">
      <div className="main-container">
        <Grid container>
          <BreadCrumbC
            customPath="Lookup Routines"
            getBreadcrumbConfig={getBreadcrumbConfig}
          />
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <span>
                  <Button
                    disabled={configTypeFromStore === "view" ? true : false}
                    variant="text"
                    className="icon-btn mx-2"
                    data-testid="saveBtn"
                    onClick={lookupRoutinesToolbarSaveClick}
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
              <Button
                variant="contained"
                className="primary-btn"
                onClick={handleNewLookupRoutineClick}
                disabled={configTypeFromStore === "view" ? true : false}
                data-testid="newLookupBtn"
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Lookup_Routine)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default LookupRoutinesToolBar
