import { useHistory, useLocation, useParams } from "react-router-dom"
import { useAppThunkDispatch } from "src/redux/store"
import { replaceAllMultiple } from "src/utils/helper"
import {
  onCancelClick,
  setValueForVariable
} from "src/configurationPortal/pages/FunctionHelper"
import FormSubmitCancelButtons from "../../../FormSubmitCancelButtons"
import { getVariableBasedOnComponent } from "../../Utils/Helpers/SignaturesGridHelper"
import { useCallback } from "react"

const FormSubmitCancel = () => {
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const location = useLocation() as any
  const { id, tab, tabId }: { id: string; tab: string; tabId: string } =
    useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  const search = window.location.search || ""

  let { variableSetRedux, variableRedux, redirectUrl, variablesRedux } =
    getVariableBasedOnComponent(tab, storeTabId, storeId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  const stateVariableRedux = location.state?.variableRedux
  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const handleSaveClick = useCallback(
    data => {
      let Signatures = locVariableRedux.Signatures

      if (storeId <= -1) {
        Signatures = [...Signatures, data]
      } else {
        Signatures = Signatures.map((i, j) => {
          return j === storeId - 1
            ? {
                ...i,
                ...data
              }
            : i
        })
      }
      dispatch(
        variableSetRedux({
          ...locVariableRedux,
          Signatures
        })
      )
      onCancelClick(history, redirectUrl)
    },
    [
      locVariableRedux,
      variableSetRedux,
      redirectUrl,
      storeId,
      dispatch,
      history
    ]
  )
  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClick}
      redirectUrl={redirectUrl}
    />
  )
}

export default FormSubmitCancel
