/* istanbul ignore file */
/* eslint-disable no-console */
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios"
import axios from "axios"
import { config } from "../config/config"
import { toast } from "react-toastify"
import { CUSTOM_REQUEST_HEADERS } from "../utils/constants"
import { msalConfig, tokenRequest } from "src/config/authConfig"
import { parseToken, prepareMsalConfig, translate } from "./helper"
import { PublicClientApplication } from "@azure/msal-browser"

let tokenPromise: any = null
let isTokenFetching = false
// istanbul ignore next
function fetchAccessToken() {
  if (tokenPromise) {
    return tokenPromise
  }
  if (isTokenFetching) {
    // If another part of the code is already fetching a token, wait for it
    return new Promise(resolve => {
      const checkFetching = () => {
        if (!isTokenFetching) {
          resolve(tokenPromise)
        } else {
          setTimeout(checkFetching, 100)
        }
      }
      checkFetching()
    })
  }

  // Set the flag to indicate that token fetching is in progress
  isTokenFetching = true
  // istanbul ignore next
  return new Promise(async (resolve, _reject) => {
    let msalToken: any = localStorage.getItem("msalAccessToken")
    let token = parseToken(msalToken)
    let exp = token?.exp
    let current_time = Math.floor(Date.now() / 1000)
    if (!msalToken || current_time > exp) {
      let mConfig: any = prepareMsalConfig(
        { domain_hint: "", login_hint: "" },
        msalConfig,
        true
      )
      const userName: any = localStorage.getItem("username")
      const msalInstance = new PublicClientApplication(mConfig)
      const account = await msalInstance.getAccountByUsername(userName)
      msalInstance.setActiveAccount(account)
      tokenPromise = msalInstance
        .acquireTokenSilent(tokenRequest)
        .then(response => {
          localStorage.setItem("msalAccessToken", response.accessToken)
          return response.accessToken
        })
        .catch(err => {
          console.log("got error ", err)
          localStorage.clear()
          window.location.href = "/login"
          // reject(err)
          throw err
        })
        .finally(() => {
          // Clear the token fetching promise once it's done
          tokenPromise = null
        })
      return tokenPromise
    }
    isTokenFetching = false
    resolve(msalToken)
  })
}
// istanbul ignore next
const getErrorMsg = (error: any) => {
  if (error.response && error.response.data && error.response.data.Message) {
    return error.response?.data.Message
  } else {
    return ""
  }
}
// istanbul ignore next
class ApiService<T> {
  service: AxiosInstance
  constructor(_tedUrl?: boolean) {
    const url: string = config.baseURL
    this.service = axios.create({
      baseURL: url
    })
    // request interceptor
    this.service?.interceptors?.request.use(
      this.requestHandleSuccess,
      this.requestHandleError
    )
    // response interceptor
    this.service?.interceptors?.response.use(
      this.responseHandleSuccess,
      this.responseHandleError
    )
  }
  // istanbul ignore next
  post(path: string, payLoad: T): Promise<AxiosResponse> {
    return this.service.post<AxiosResponse<T>>(path, payLoad)
  }
  // istanbul ignore next
  get(path: string, options: any): Promise<AxiosResponse> {
    return this.service.get(path, options)
  }
  // istanbul ignore next
  put(path: string, payLoad: T): Promise<AxiosResponse> {
    return this.service.put<AxiosResponse<T>>(path, payLoad)
  }
  // istanbul ignore next
  saveWithParams(
    path: string,
    payLoad: object,
    params: object
  ): Promise<AxiosResponse> {
    return this.service.post(path, payLoad, params)
  }
  // istanbul ignore next
  savefile(path: string, formData: object, config): Promise<AxiosResponse> {
    this.service.defaults.headers.common["Content-Type"] = "multipart/form-data"
    return this.service.post(path, formData, config)
  }
  // istanbul ignore next
  sqlPost(endPoint: string, query: object): Promise<AxiosResponse> {
    this.service.defaults.headers.common["Content-Type"] = "application/json"
    return this.service.post(endPoint, query)
  }
  // istanbul ignore next
  herePOST(path: string, body): Promise<AxiosResponse> {
    return this.service.post(path, body)
  }
  // istanbul ignore next
  update(path: string, payLoad: object): Promise<AxiosResponse> {
    return this.service.put(path, payLoad)
  }
  // istanbul ignore next
  findByParams(path: string, params: object): Promise<AxiosResponse> {
    return this.service.get(path, params)
  }
  // istanbul ignore next
  findAll(path: string): Promise<AxiosResponse> {
    return this.service.get(path)
  }
  // istanbul ignore next
  findAllFutureEvents(path: string): Promise<AxiosResponse> {
    return this.service.get(path, {
      headers: {
        "content-type": "application/json",
        Accept: "application/json"
      }
    })
  }
  // istanbul ignore next
  delete(path: string, params: object): Promise<AxiosResponse> {
    return this.service.delete(path, params)
  }
  // istanbul ignore next
  setCustomHeaders(key: string, value: any) {
    this.service.defaults.headers.common[key] = value
  }
  // istanbul ignore next
  async requestHandleSuccess(
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    let access_token: any = await fetchAccessToken()
    const userName = localStorage.getItem("name") ?? ""
    if (access_token && !config.url?.includes("hereapi")) {
      config.headers = {
        Authorization: `Bearer ${access_token}`,
        Username: userName,
        ...CUSTOM_REQUEST_HEADERS
      }
    }
    const newConfig: AxiosRequestConfig = {
      ...config,
      ...{ headers: { ...config.headers } }
    }

    return Promise.resolve(newConfig)
  }
  // istanbul ignore next
  requestHandleError(error: AxiosError) {
    console.log("errror ------->> ", error)
    return Promise.reject(error)
  }
  // istanbul ignore next
  responseHandleSuccess(response: AxiosResponse) {
    return response
  }
  // istanbul ignore next
  responseHandleError(error: AxiosError) {
    console.log("===> ", error)
    let errorMsg: string = ""
    let avoidToaster: boolean = false
    if (error?.response?.data?.title) {
      errorMsg = error.response?.data?.title
    }

    switch (error?.response?.data.StatusCode) {
      case 400:
        errorMsg = getErrorMsg(error)
        if (
          errorMsg ===
          "Devices are already reserved for the selected time period"
        ) {
          avoidToaster = true
        }
        break
      case 500:
        errorMsg = getErrorMsg(error)
        break

      case 404:
        errorMsg = getErrorMsg(error)
        if (errorMsg === "Reservation Name Already Exists") {
          avoidToaster = true
        }
        break
      case 401:
        errorMsg = error.response?.data.Message
        // localStorage.clear()
        // window.location.href = "/login"
        break
      case 403:
        if (error.response?.data.Message)
          errorMsg = error.response?.data.Message
        console.log("requested resource not found")
        break
      case 409:
        errorMsg = error.response?.data.Message
        if (
          error.response?.data.Message === "Changed Location not found" ||
          error.response?.data.Message === "Location not found" ||
          error.response?.data.Message === "Device Reservation Not Found" ||
          error.response?.data.Message === "Schedule Not Found" ||
          error.response?.data.Message ===
            "Unable to find the user. Please contact your account Admin" ||
          error.response?.data.Message ===
            "Devices are already reserved for the selected time period" ||
          error.response?.data.Message.includes(
            "This action will make changes to the reservation"
          ) ||
          error.response?.data.Message.includes(
            "This action will cancel the reservation"
          )
        ) {
          // avoidToaster = true

          avoidToaster = true
          // setOverlapMsg(`${translate(s1)} ${s2}. ${translate(s3)}`)
        }
        console.log("requested resource not found")
        break

      default:
        console.log("default requested intercept")
        break
    }
    if (error?.response?.config?.url) {
      if (
        ["/api/UploadFiles/UploadChunks"].includes(error?.response?.config?.url)
      ) {
        avoidToaster = true
      }
    }

    if (
      !avoidToaster &&
      errorMsg &&
      errorMsg !== "undefined" &&
      errorMsg !== "null"
    ) {
      let _errorString = errorMsg.split(" ").join("_")
      let temp
      if (errorMsg.includes("does not belong to table")) {
        const pattern = /^(\w+)\s+(\{(\d+)\})\s+(.*)$/

        // Use the exec() method to extract parts using the pattern
        const match = pattern.exec(errorMsg)

        // Extract the desired substrings from the match result
        let s1 = ""
        let s2 = ""
        let s3 = ""
        if (match) {
          s1 = match[1]
          s2 = match[3]
          s3 = match[4]
        }
        temp = translate(s1)
          ? `${translate(s1)} ${s2} ${translate(s3.split(" ").join("_"))}`
          : errorMsg
        toast.error(`${temp}`, {
          toastId: errorMsg.split("").join("-")
        })
      } else {
        temp = translate(_errorString) ? translate(_errorString) : errorMsg
        toast.error(`${temp}`, {
          toastId: errorMsg.split("").join("-")
        })
      }
    }
    return Promise.reject(error?.response?.data)
  }
}
export default ApiService
