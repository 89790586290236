//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class LiveEventsService { 
  getLiveEvents = payload => {
    const endPoint = `api/Customer/GetLiveEventsDetails`
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
}
//END-NOSCAN