import { Divider, Grid } from "@mui/material"

const FormHeading = ({
  heading,
  isDivider
}: {
  heading: string
  isDivider?: boolean
}) => {
  return (
    <>
      <Grid item xs={12} sm={12}>
        {isDivider ? <Divider className="dashed my-4" /> : null}
        <label className="form-control__label mb-3">{`${heading}`}</label>
      </Grid>
    </>
  )
}

export default FormHeading
