import { formField } from "src/utils/constants"

export const DestinationFields = {
  IsCustomerPortal: {
    name: "Destination.IsCustomerPortal",
    label: "CustomerPortal"
  },
  Emails: { name: "Destination.Emails", label: "Email" },
  IsFinalReport: {
    name: "Destination.IsFinalReport",
    label: "Final_Reports_In_Store"
  },
  FtpDirectory: { name: "Destination.FtpDirectory", label: "Ftp_Directory" }
}

export const DestinationFields1 = {
  IsCustomerPortal: {
    name: "Destination.CustomerPortalCheck"
  },
  Emails: { name: "Destination.Email" }
}

export const DestinationFieldsRender = {
  isCustomerPortal: {
    fieldType: formField.checkbox,
    props: {
      name: DestinationFields.IsCustomerPortal.name,
      label: DestinationFields.IsCustomerPortal.label,
      className: "light"
    }
  },
  emails: {
    fieldType: formField.multi_auto_dropdown,
    props: {
      name: DestinationFields.Emails.name,
      label: DestinationFields.Emails.label,
      options: [],
      freeSolo: true
    }
  },
  isFinalReport: {
    fieldType: formField.checkbox,
    props: {
      name: DestinationFields.IsFinalReport.name,
      label: DestinationFields.IsFinalReport.label,
      className: "light"
    }
  },
  ftpDirectory: {
    fieldType: formField.auto_dropdown,
    props: {
      name: DestinationFields.FtpDirectory.name,
      label: DestinationFields.FtpDirectory.label,
      options: []
    }
  }
}
