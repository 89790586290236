import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
const LocationAndScheduleTopHeadings = () => {
  const { t } = useTranslation<any>()
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h4 className="inner-view__title">
            {getTranslations(t, Translates.Data_Mapping)}
          </h4>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <label className="form-control__label mb-2">
              {getTranslations(
                t,
                Translates.Associating_the_attributes_to_the_CSV_Data
              )}
            </label>
            <p className="mb-3">
              {getTranslations(
                t,
                Translates.Map_the_available_data_base_fields_to_your_file
              )}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <p className="text-uppercase font-14 f-700 mb-3">
            {getTranslations(t, Translates.Attributes)}
          </p>
        </Grid>
      </Grid>
      <Grid container className="mb-3 gray-bg pa-2">
        <Grid item sm={4}>
          <p className="text-uppercase font-14 f-700 mb-0">
            {getTranslations(t, Translates.Attributes)}
          </p>
        </Grid>
        <Grid item sm={4}>
          <p className="text-uppercase font-14 f-700 ml-1 mb-0">
            {getTranslations(t, Translates.CSV_Data)}
          </p>
        </Grid>
      </Grid>
    </>
  )
}

export default LocationAndScheduleTopHeadings
