import { Controller, useFormContext } from "react-hook-form"
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { useTranslation } from "react-i18next"
import { getFieldError } from "./Helper"
import { getTranslations } from "src/utils/helper"

export interface Props {
  inputAttributes: {
    name: string
    label: string
    id?: string
    required?: boolean
    value?: string | number | boolean
    defaultValue?: string | number | boolean
    className?: string
    onChange?: Function
    formGroupClassName?: string
    disabled?: boolean
    disableRipple?: boolean
  }
}

const MUICheckbox = ({ inputAttributes }: Props) => {
  const { t } = useTranslation<any>()
  const {
    name,
    label,
    className,
    id,
    required,
    value,
    defaultValue,
    onChange,
    formGroupClassName,
    disabled,
    disableRipple
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods
  const defVal = defaultValue ? true : false

  const { isError, ErrorText } = getFieldError(name, errors)
  return (
    <>
      <Controller
        name={`${name}`}
        control={control}
        defaultValue={defVal}
        render={({ field: { ref, ...field } }) => (
          <FormGroup
            className={`lang-checks ${formGroupClassName}`}
            data-testid="checkbox"
          >
            <div className="lang-checks__row">
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      updateWarnOnTabChangeToStore(true)
                      if (onChange) onChange()
                    }}
                    disableRipple={disableRipple ? true : false}
                    disabled={disabled}
                    value={value}
                    checked={field.value ? true : false}
                    id={id ?? name}
                    required={required ?? false}
                    className={`custom-check ${className}`}
                    checkedIcon={<CheckIcon />}
                  />
                }
                label={`${getTranslations(t, label)} ${required ? "*" : ""}`}
              />
            </div>
            <FormHelperText className={isError ? "text-danger" : ""}>
              {ErrorText}
            </FormHelperText>
          </FormGroup>
        )}
      />
    </>
  )
}

export default MUICheckbox
