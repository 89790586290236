import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TimeAgo = props => {
  console.log("time agoo")
  /* @ts-ignore */
  const { t } = useTranslation();

  if(!props.value) return <>{"-"}</>

  // Get the relative time using moment.js
  const relativeTime = moment.utc(props.value).local().fromNow();

  // Custom translation function
  const translateTimeAgo = (timePhrase) => {
    
    const units = ["seconds", "minutes", "hours", "days", "months", "years"]
    const singularUnit = ["a minute ago", "an hour ago", "a day ago", "a month ago", "a year ago"]

    if (timePhrase.includes('few')) {
      for(let idx in units){
        const unit = units[idx]
        if(timePhrase.includes(unit)){
          return t(`common:timeAgo.few_${unit}_ago`)
        }
      }
    }
    for(let idx in singularUnit){
      const sUnit = singularUnit[idx]
      if(timePhrase === sUnit) {
        return t(`common:timeAgo.${sUnit.split(' ').join('_')}`)
      }
    }
    
    // Extract the numeric part and the unit of time (days, hours, minutes, seconds)
    const [, numericPart, unit] = timePhrase.match(/(\d+) (day|hour|minute|second)s? ago/) || [];

    // Translate the entire time phrase including the numeric part and the unit
    const translated = t(`common:timeAgo.${unit}`, { count: numericPart });
    return translated.includes('undefined') ? relativeTime : translated;
  };

  // Translate the entire time phrase
  const translatedTimeAgo = translateTimeAgo(relativeTime);

  return (
    <span>
      {translatedTimeAgo}
    </span>
  );
};

export default TimeAgo;


