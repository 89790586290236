import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  FormHelperText
} from "@mui/material"
import { RootState, useAppThunkDispatch } from "../../../redux/store"
import { useHistory, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { PATH } from "../../../constants/paths"
import { setOutputColumns } from "src/redux/slices/configSlice"
import {
  getTranslations,
  setWeekFieldError,
  validateDecimal
} from "src/utils/helper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { getCurrencySymbol } from "../CountFields/Constants"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import OutputFilesBreadCrumb from "./OutputFilesBreadCrumb"
import { getCurrencySymbolField } from "../ColumnHelper"
import { DEFAULT_RADIX } from "src/utils/constants"
interface INewColumnFile {
  DataSource: string
  ColumnName: string
  DataType: string
  Padding: string
  SurroundCharacter: string
  NumberOfDecimals: string
  Length: string
  Character: string
  CurrencySymbol: string
  Suppress: boolean
}
const EditColumns = () => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const { cMap, cFullMap, cDisplayMap } = getCurrencySymbol()
  const [surroundCharacterValue, setSurroundCharacterValue] = React.useState("")
  const [errorMsg, setErrorMsg] = useState(false)
  const [columnErr, setColumnErr] = useState("")
  const [lengthErrorMsg, setLengthErrorMsg] = useState(false)
  const [characterErrorMsg, setCharacterErrorMsg] = useState(false)
  const location = useLocation() as any
  let search = window.location.search || ""
  const dataTypeValues = ["DECIMAL", "ALPHA", "NUMERIC"]
  const paddingValues = ["NONE", "LEFT", "RIGHT"]
  const surroundCharacterValues = ['"', "'"]
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const configData = useSelector((state: RootState) => state.config)
  const sqlQueryColumns = useSelector(
    (state: RootState) => state.config.newSQLQueryColumns
  )

  const outputColumn = useSelector(
    (state: RootState) => state.config.outputColumns
  )
  const currencies = [
    "No Currency Symbol",
    "$ - USD - US Dollar",
    "$ - MXN - MEXICAN PESO",
    "$ - CAD - Canadian Dollar",
    "¥ - CNY - Chinese Yuan"
  ]
  const [form, setForm] = React.useState<INewColumnFile>({
    DataSource: "",
    ColumnName: "",
    DataType: "",
    Padding: "",
    SurroundCharacter: "",
    NumberOfDecimals: "",
    Length: "",
    Character: "",
    CurrencySymbol: "",
    Suppress: false
  })

  const [weekIVFields, setWeekIVFields] = useState({
    ColumnName: ""
  })

  useEffect(() => {
    const locationState = { ...location?.state }

    if (locationState.isEdit === true) {
      // return the full form of the currency

      let cSymbol = currencies[0]

      if (locationState?.data?.CurrencySymbol) {
        if (
          locationState?.data?.OriginalCurrencySymbol &&
          cFullMap.has(locationState?.data?.OriginalCurrencySymbol)
        ) {
          cSymbol = cFullMap.get(locationState?.data?.OriginalCurrencySymbol)
        } else if (cFullMap.has(locationState?.data?.CurrencySymbol)) {
          cSymbol = cFullMap.get(locationState?.data?.CurrencySymbol)
        }
      }

      setForm({
        ...locationState.data,
        Padding: getThePadding(locationState?.data?.Padding, "Format"),
        Length: getThePadding(locationState?.data?.Padding, "Length"),
        Character: getThePadding(locationState?.data?.Padding, "Character"),
        CurrencySymbol: cSymbol
      })
    }
  }, [])

  const getThePadding = (paddingObj, Type) => {
    if (Array.isArray(paddingObj)) {
      return paddingObj[0][Type]
    } else {
      return paddingObj
    }
  }

  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value || ""
    setErrorMsg(false)
    setLengthErrorMsg(false)
    setCharacterErrorMsg(false)
    if (e.target?.name === "DataSource") {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target?.name === "CurrencySymbol") {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target?.name !== "DataSource") {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target?.name === "Padding") {
      setForm({ ...form, [e.target.name]: val, Length: "", Character: "" })
      setLengthErrorMsg(false)
      setCharacterErrorMsg(false)
    }
    if (e.target?.name === "Length") {
      setForm({ ...form, [e.target.name]: val })
      setLengthErrorMsg(false)
    }
    if (e.target?.name === "Character") {
      setForm({ ...form, [e.target.name]: val })
      setCharacterErrorMsg(false)
    }

    if (e.target?.name === "ColumnName") {
      setColumnErr("")
      if (val.length > 30) {
        setColumnErr("Column name length can not be more than 30")
      }
      setForm({ ...form, [e.target.name]: val })

      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    }

    if (e.target?.name === "NumberOfDecimals") {
      if (!validateDecimal(val)) {
        setForm({
          ...form,
          [e.target.name]: val.substring(0, val.length - 1)
        })
      }
    }
  }

  const constructPaddingObj = form => {
    if (form.Padding == "LEFT" || form.Padding == "RIGHT") {
      return [
        {
          Format: form.Padding,
          Length: form.Length !== "" ? parseInt(form.Length, DEFAULT_RADIX) : 0,
          Character: form.Character
        }
      ]
    } else if (form.Padding == "") {
      return null
    } else {
      return form.Padding
    }
  }

  const onFormSurroundCharacterChange = e => {
    setForm({ ...form, SurroundCharacter: e })
  }

  const onSaveClick = () => {
    const duplicateColumn = outputColumn.filter(function (itm) {
      return form.DataSource == itm.DataSource
    })

    const _sortOrder =
      configData?.outputColumns.length > 0
        ? configData?.outputColumns?.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0

    let _item: any = {
      DataSource: form.DataSource,
      ColumnName: form.ColumnName,
      Suppress: form.Suppress,
      SortOrder: location.state?.isEdit
        ? location.state?.data?.SortOrder
        : _sortOrder + 1
    }
    if (form.DataType !== "") {
      _item = { ..._item, DataType: form.DataType }
    }

    // build the currency value
    let currencySymbol = ""
    let OriginalCurrencySymbol = ""
    if (form.CurrencySymbol !== "" && form.CurrencySymbol !== currencies[0]) {
      currencySymbol = cDisplayMap.get(form.CurrencySymbol)
      OriginalCurrencySymbol = cMap.get(form.CurrencySymbol)
    }

    _item = {
      ..._item,
      Padding: constructPaddingObj(form),
      SurroundCharacter: form.SurroundCharacter,
      CurrencySymbol: currencySymbol,
      OriginalCurrencySymbol: OriginalCurrencySymbol,
      NumberOfDecimals: form.NumberOfDecimals
        ? parseInt(form.NumberOfDecimals, DEFAULT_RADIX)
        : 0
    }

    if (form.DataType !== dataTypeValues[0]) {
      delete _item?.CurrencySymbol
      delete _item?.OriginalCurrencySymbol
      delete _item?.NumberOfDecimals
    }

    let _dt = [] as any
    if (location.state?.isEdit) {
      _dt = [
        ...configData?.outputColumns.map(m =>
          m.SortOrder === _item.SortOrder ? { ..._item } : { ...m }
        )
      ]
    } else {
      _dt = [...configData?.outputColumns, _item]
    }

    // check length and character if padding is set to left or right;

    if (form.Padding == "LEFT" || form.Padding == "RIGHT") {
      if (form.Length == "") {
        setLengthErrorMsg(val => true)
        window.scrollTo(0, 0)
      }
      if (form.Character == "") {
        setCharacterErrorMsg(val => true)
        window.scrollTo(0, 0)
      }
    }

    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      if (!lengthErrorMsg && !characterErrorMsg) {
        if (location.state?.isEdit) {
          dispatch(setOutputColumns(_dt))
          history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
            isEdit: location.state?.isParentEdit,
            data: { ...location.state?.form, ...{ Columns: _dt } }
          })
        } else {
          if (Object.keys(duplicateColumn).length == 0) {
            dispatch(setOutputColumns(_dt))
            history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
              isEdit: location.state?.isParentEdit,

              data: { ...location.state?.form, ...{ Columns: _dt } }
            })
          } else {
            setErrorMsg(true)
            window.scrollTo(0, 0)
          }
        }
      }
    })
  }
  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
        isEdit: location.state?.isParentEdit,

        data: { ...location.state?.form }
      })
    })
  }

  const getBreadcrumbConfig = () => {
    let currentPath: string = `outputFiles`
    let variables: any = {
      outputFiles: location?.state.isEdit
        ? getTranslations(t, Translates.Edit_Column)
        : getTranslations(t, Translates.New_Column)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const getErrorMessage = field => {
    if (field == "DataSource" && errorMsg) {
      return ` ${form.DataSource} is already added`
    }
    if (field == "Length" && lengthErrorMsg) {
      return ` Length is mandatory when padding is selected`
    }
    if (field == "Character" && characterErrorMsg) {
      return ` Character is mandatory when padding is selected`
    }
  }
  const validationCheck = () => {
    if (
      !form.ColumnName ||
      !form.DataSource ||
      columnErr ||
      configTypeFromStore === "view"
    ) {
      return true
    }
    return false
  }
  return (
    <div>
      <OutputFilesBreadCrumb
        customPath="New Column"
        bConfig={getBreadcrumbConfig()}
      />

      <Grid className="main-container" container>
        <Grid item xs={12} sm={12} className="mb-8">
          <Grid container>
            <Grid item xs={12} sm={3}>
              <label className="mb-3 form-control__label ">
                {getTranslations(t, Translates.Basic_Details)}
              </label>
              <FormControl
                variant="filled"
                className="custom-form-control select"
              >
                <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                  {getTranslations(t, Translates.Data_Source)}*
                </InputLabel>

                <Select
                  id="demo-multiple-checkbox"
                  value={form.DataSource || ""}
                  name="DataSource"
                  onChange={e => onFormChange(e)}
                  error={errorMsg ? true : false}
                >
                  {sqlQueryColumns?.map((item: any) => (
                    <MenuItem
                      key={item.ColumnName}
                      value={item.ColumnName}
                      className="dropdown-list"
                    >
                      <small>{item.ColumnName}</small>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText
                className="red-text"
                style={{ marginTop: "-10px" }}
              >
                {errorMsg ? getErrorMessage("DataSource") : ""}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <TextField
                data-testid="columnName"
                fullWidth
                label={`${getTranslations(t, Translates.Name)}*`}
                variant="filled"
                autoSave="false"
                autoComplete="off"
                className="custom-form-control"
                onChange={e => onFormChange(e)}
                name="ColumnName"
                value={form.ColumnName || ""}
                error={columnErr || !!weekIVFields.ColumnName ? true : false}
                helperText={columnErr || weekIVFields.ColumnName}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="filled"
                className="custom-form-control select"
              >
                <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                  {getTranslations(t, Translates.Data_Type)}
                </InputLabel>

                <Select
                  id="demo-multiple-checkbox"
                  value={form?.DataType}
                  name="DataType"
                  onChange={e => onFormChange(e)}
                >
                  {dataTypeValues?.map((item: any) => (
                    <MenuItem key={item} value={item} className="dropdown-list">
                      <small>{item}</small>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              {form.DataType === "DECIMAL" ? (
                <TextField
                  fullWidth
                  label={getTranslations(t, Translates.Enter_Decimal_Places)}
                  autoComplete="off"
                  className="custom-form-control"
                  variant="filled"
                  autoSave="false"
                  name="NumberOfDecimals"
                  value={form.NumberOfDecimals}
                  onChange={e => onFormChange(e)}
                />
              ) : null}
              {getCurrencySymbolField(form, t, onFormChange, currencies)}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="filled"
                className="custom-form-control select"
              >
                <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                  {getTranslations(t, Translates.Padding)}
                </InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  value={form?.Padding}
                  name="Padding"
                  onChange={e => onFormChange(e)}
                >
                  {paddingValues?.map((name: any) => (
                    <MenuItem key={name} value={name} className="dropdown-list">
                      <small>{name}</small>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {(form?.Padding == "LEFT" || form?.Padding == "RIGHT") && (
            <Grid container>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label={getTranslations(t, Translates.Length)}
                  variant="filled"
                  autoSave="false"
                  autoComplete="off"
                  className="custom-form-control"
                  onChange={e => onFormChange(e)}
                  name="Length"
                  value={form.Length || ""}
                  error={lengthErrorMsg ? true : false}
                  helperText={getErrorMessage("Length")}
                />
              </Grid>
            </Grid>
          )}
          {(form?.Padding == "LEFT" || form?.Padding == "RIGHT") && (
            <Grid container>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label={getTranslations(t, Translates.Character)}
                  variant="filled"
                  autoSave="false"
                  autoComplete="off"
                  className="custom-form-control"
                  onChange={e => onFormChange(e)}
                  name="Character"
                  value={form.Character || ""}
                  error={characterErrorMsg ? true : false}
                  helperText={getErrorMessage("Character")}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12} sm={3}>
              <div className="text-box custom-popper input">
                <Autocomplete
                  //  name="SurroundCharacter"
                  fullWidth
                  className="custom-form-control select"
                  id="tags-filled"
                  freeSolo
                  disablePortal={true}
                  disableClearable
                  inputValue={surroundCharacterValue}
                  value={form.SurroundCharacter}
                  options={surroundCharacterValues.map(o => o)}
                  onChange={(e: any, newValue: string | null) => {
                    onFormSurroundCharacterChange(newValue)
                  }}
                  onInputChange={(event, newInputValue) => {
                    onFormSurroundCharacterChange(newInputValue)
                    setSurroundCharacterValue(newInputValue)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label={getTranslations(t, Translates.Surround_Character)}
                      name="SurroundCharacter"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className="inner-view__footer">
            <Button
              variant="text"
              className="primary-btn cancel-btn mr-5"
              data-testid="cancel"
              onClick={() => onCancelClick()}
            >
              {getTranslations(t, Translates.Cancel)}
            </Button>
            <Button
              data-testid="save"
              variant="contained"
              onClick={() => onSaveClick()}
              disabled={validationCheck()}
              className="primary-btn"
            >
              {getTranslations(t, Translates.Save)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default EditColumns
