//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class UserService {
  getUserRoles(customerId) {
    const endPoint = `/api/Roles?accountId=${customerId}`
    return api.findAll(endPoint)
  }
  checkUserExistByEmail(emailId) {
    const endPoint = `/api/User/CheckUserExistByEmail`
    return api.post(endPoint, { emailId: emailId })
  }
  getAllRegionalsByAccount(customerId) {
    const endPoint = `/api/Company/GetAllRegionalsByAccountId/${customerId}`
    return api.findAll(endPoint)
  }
  saveUser(payload) {
    const endPoint = `/api/User`
    return api.post(endPoint, payload)
  }
  updateUser(payload) {
    const endPoint = `/api/User`
    return api.put(endPoint, payload)
  }
  userAccount(payload: any) {
    const endPoint = `api/User/GetAllUsersByAccount`
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
  downloadAccountUser(payload: any) {
    const endPoint = `api/User/DownloadUsersByAccount`
    return api.post(endPoint, payload)
  }
  saveCustomerSelection(payload) {
    const endPoint: string = `/api/User/UpdateSelectedAccountDetails`
    return api.put(endPoint, payload)
  }
  postUserValidationData(payload: any, config: any) {
    const endPoint = `api/User/AddUsersBulk`
    return api.saveWithParams(endPoint, payload, config)
  }

  resendUserInvite(email: string) {
    const endPoint = `api/User/ResendUserInvitation?email=${email}`
    return api.findAll(endPoint)
  }
}
//END-NOSCAN
