import { useHistory, useParams } from "react-router-dom"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { PATH } from "src/constants/paths"
import FormSubmitCancelButtons from "src/configurationPortal/pages/Components/FormSubmitCancelButtons"
import { getPayload } from "../../Utils/Helpers/CreateEditMultiEventOutput"
import { ROLE_CONSTANTS } from "src/utils/constants"
import { getCurrentUserRole } from "src/utils/helper"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { MultiEventOutputsService } from "src/service/multiEventOutputs.service"

const FormSubmitCancel = () => {
  const history = useHistory()
  const userRole: string = getCurrentUserRole()
  const multiEventOutputsService = new MultiEventOutputsService()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const customerList = useSelector(
    (state: RootState) => state.settings?.customers
  )
  const selectedCustomerList = customerList?.filter((c: any) => c.selected)
  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(`${PATH.CONFIGURATION}?tab=1`)
    })
  }

  const handleSaveClick = async data => {
    const { payload } = await getPayload(data, storeId)
    try {
      if (storeId === -1) {
        await multiEventOutputsService.addMultiEventDetails(payload)
      } else {
        await multiEventOutputsService.updateMultiEventDetails(payload)
      }
      onCancelClick()
    } catch (_err) {
      return _err
    }
    return true
  }

  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClick}
      onCancelClick={onCancelClick}
      isSaveBtnDisabled={
        userRole.toLowerCase() !== ROLE_CONSTANTS.WisUser.toLowerCase() ||
        selectedCustomerList?.length <= 0 ||
        window.location.search.includes("view")
      }
    />
  )
}

export default FormSubmitCancel
