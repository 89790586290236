import { formField } from "src/utils/constants"
import { ConfigReportType, GeneralFieldType } from "../Types"

export const createEditReportFieldNames = {
  Name: { name: "Name", label: "Name" },
  Description: { name: "Description", label: "Description" },
  FileName: { name: "FileName", label: "File Name" },
  IsInterim: { name: "IsInterim", label: "Interim" },
  IsHasCheckMark: { name: "IsHasCheckMark", label: "Has Check Mark" },
  IsFinal: { name: "IsFinal", label: "Final" },
  IsSignature: { name: "IsSignature", label: "Signature" },
  Prompts: { name: "Prompts", label: "Prompts" },
  RawSQL: { name: "RawSQL", label: "RawSQL" },
  SQL: { name: "SQL", label: "SQL" },
  Columns: { name: "Columns", label: "Columns" },
  Signatures: { name: "Signatures", label: "Signatures" },
  ColumnsToSuppress: { name: "ColumnsToSuppress", label: "ColumnsToSuppress" },
  SubTotals: { name: "SubTotals", label: "Sub Totals" },
  GrandTotals: { name: "GrandTotals", label: "Grand Totals" }
}

export const createEditReportDefaultIniValues: ConfigReportType = {
  Name: "",
  Description: "",
  FileName: "",
  IsInterim: false,
  IsHasCheckMark: false,
  IsFinal: false,
  IsSignature: false,
  ColumnsToSuppress: 0,
  Prompts: [],
  RawSQL: "",
  SQL: "",
  Columns: [],
  Signatures: [],
  SubTotals: [],
  GrandTotals: [],
  Destination: { CustomerPortalCheck: true, FtpDirectory: "", Email: [] }
}

export const GeneralFieldDetails: GeneralFieldType = {
  name: {
    fieldType: formField.text,
    props: {
      name: createEditReportFieldNames.Name.name,
      label: createEditReportFieldNames.Name.label,
      required: true
    }
  },
  description: {
    fieldType: formField.textarea,
    props: {
      name: createEditReportFieldNames.Description.name,
      label: createEditReportFieldNames.Description.label,
      multiline: true,
      rows: 3,
      maxRows: 5,
      required: true
    },
    childCols: { sm: 5 }
  },
  fileName: {
    fieldType: formField.text,
    props: {
      name: createEditReportFieldNames.FileName.name,
      label: createEditReportFieldNames.FileName.label,
      required: true
    }
  },

  isInterim: {
    fieldType: formField.checkbox,
    props: {
      name: createEditReportFieldNames.IsInterim.name,
      label: createEditReportFieldNames.IsInterim.label,
      className: "light"
    }
  },
  isHasCheckMark: {
    fieldType: formField.checkbox,
    condition: { name: createEditReportFieldNames.IsInterim.name, value: true },
    props: {
      name: createEditReportFieldNames.IsHasCheckMark.name,
      label: createEditReportFieldNames.IsHasCheckMark.label,
      className: "light"
    }
  },

  isFinal: {
    fieldType: formField.checkbox,
    props: {
      name: createEditReportFieldNames.IsFinal.name,
      label: createEditReportFieldNames.IsFinal.label,
      className: "light"
    }
  },
  isSignature: {
    fieldType: formField.checkbox,
    condition: { name: createEditReportFieldNames.IsFinal.name, value: true },
    props: {
      name: createEditReportFieldNames.IsSignature.name,
      label: createEditReportFieldNames.IsSignature.label,
      className: "light"
    }
  }
}
