import React, { useCallback, useState } from "react"
import { Grid, Button, Checkbox, Divider, Tooltip } from "@mui/material"
import GlobalTable from "../../../../src/components/GlobalTable/GlobalTable"
import AddIcon from "@mui/icons-material/Add"
import editIcon from "../../../../src/assets/images/Edit.png"
import trashIcon from "../../../../src/assets/images/TrashIcon.svg"
import CheckIcon from "@mui/icons-material/Check"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../../src/constants/paths"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { LocationsList } from "./Constants"
import { setCountPrograms } from "src/redux/slices/configSlice"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useDispatch } from "react-redux"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { updateWarnOnTabChangeToStore } from "../helper"
import { getTranslations } from "src/utils/helper"
import { callbackConfirmationHelper } from "./Helper"
import { KEY_CODES, setIsDisableButton } from "src/utils/constants"

let preDeleteRow = {} as any

const CountFieldsLocations = ({ locations, setIsTabChangeCheck }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  let search = window.location.search || ""

  const {
    config: {
      countFields,
      defaultCountFields,
      countPrograms,
      configType: configTypeFromStore
    }
  } = ConfigDataHook()
  const dispatch = useDispatch()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">{props.value ? props.value : 0}</span>
    )
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Field),
        accessor: "field",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Display_Name),
        accessor: "Labels[0][en-us]",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Show_on_tag_details),
        accessor: "ShowOnTagDetails",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return (
            <span>
              <Checkbox
                checked={props.value}
                className="custom-check light"
                checkedIcon={<CheckIcon />}
                disabled={true}
              />
            </span>
          )
        }
      },
      {
        Header: getTranslations(t, Translates.Show_on_Qty_Entry),
        accessor: "ShowOnQtyEntry",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return (
            <span>
              <Checkbox
                checked={props.value}
                className="custom-check light"
                checkedIcon={<CheckIcon />}
                disabled={true}
              />
            </span>
          )
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "actions",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 100,
        Cell: props => {
          return (
            <span className="action-btns">
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <img
                  src={editIcon}
                  alt={"editImg"}
                  className="mr-2 pointer"
                  width="20"
                  onClick={() => editFieldsLocation(props.row)}
                  onKeyUp={e => handleEditKeyUp(e, props.row)}
                />
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
                <span>
                  <img
                    src={trashIcon}
                    alt="deleteImg"
                    aria-label={
                      props.row.original.field === LocationsList.Location.OjKey
                        ? "disabled"
                        : ""
                    }
                    width="20"
                    onClick={() => deleteFieldsLocation(props.row.original)}
                    onKeyUp={e => handleDeleteKeyUp(e, props.row.original)}
                    className={`${
                      configTypeFromStore === "view" ? "disable-img" : "pointer"
                    }`}
                  />
                </span>
              </Tooltip>
            </span>
          )
        }
      }
    ],
    [t, locations]
  )

  const handleNewLocationClick = () => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIGURATION_COUNT_FIELDS_LOCATION + search)
    })
    //eslint-disable-next-line
  }
  const handleEditKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      editFieldsLocation(row)
    }
  }, [])

  const editFieldsLocation = (row: any) => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CONFIGURATION_COUNT_FIELDS_LOCATION + search, {
        isEdit: true,
        index: row.index,
        data: { ...row.original }
      })
    })
  }

  const handleDeleteKeyUp = useCallback((e: any, row: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      deleteFieldsLocation(row)
    }
  }, [])

  const deleteFieldsLocation = (row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row }
  }
  const deleteField = data => {
    if ([LocationsList.SecondaryLocation1.OjKey].includes(preDeleteRow.field)) {
      delete data.SecondaryLocation1
      delete data.SecondaryLocation2
      delete data.SecondaryLocation3
    } else if (
      [LocationsList.SecondaryLocation2.OjKey].includes(preDeleteRow.field)
    ) {
      delete data.SecondaryLocation2
      delete data.SecondaryLocation3
    } else {
      delete data[preDeleteRow.field]
    }
    return data
  }

  const deleteFieldFromCountProgram = () => {
    let locCountPrograms = countPrograms.map(i => {
      let copyOfI = JSON.parse(JSON.stringify(i))
      if (
        [LocationsList.SecondaryLocation1.OjKey].includes(preDeleteRow.field)
      ) {
        delete copyOfI?.SecondaryLocation1
        delete copyOfI?.SecondaryLocation2
        delete copyOfI?.SecondaryLocation3
      } else if (
        [LocationsList.SecondaryLocation2.OjKey].includes(preDeleteRow.field)
      ) {
        delete copyOfI?.SecondaryLocation2
        delete copyOfI?.SecondaryLocation3
      } else {
        delete copyOfI?.[preDeleteRow.field]
      }
      return copyOfI
    })

    dispatch(setCountPrograms(locCountPrograms))
  }

  const callbackConfirmation = useCallback(
    (res: boolean) => {
      callbackConfirmationHelper({
        res,
        setIsConfirmOpen,
        dispatch,
        deleteField,
        deleteFieldFromCountProgram,
        defaultCountFields,
        countFields
      })
    },
    //eslint-disable-next-line
    [countFields]
  )
  const setContent = useCallback(
    () => {
      let fields = ""
      if (
        [LocationsList.SecondaryLocation1.OjKey].includes(preDeleteRow.field) &&
        locations.length > 2
      ) {
        fields = `${LocationsList.SecondaryLocation1.OjKey}, ${LocationsList.SecondaryLocation2.OjKey}`
        if (locations.length > 3) {
          fields = `${fields}, ${LocationsList.SecondaryLocation3.OjKey}`
        }
      } else if (
        [LocationsList.SecondaryLocation2.OjKey].includes(preDeleteRow.field) &&
        locations.length > 3
      ) {
        fields = `${LocationsList.SecondaryLocation2.OjKey}, ${LocationsList.SecondaryLocation3.OjKey}`
      } else {
        fields = preDeleteRow.field
      }
      return (
        <AlertConfirmationContent
          warningMsg={`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete
          )} "${fields}" ${getTranslations(
            t,
            Translates.Location
          )} ${getTranslations(
            t,
            Translates.in_count_fields_and_count_programs
          )}?`}
        />
      )
    },
    //eslint-disable-next-line
    [preDeleteRow.field, locations]
  )

  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive mt-2">
        <Grid xs={12} item data-testid="CountFGlobTable">
          <GlobalTable
            columns={columnsData}
            data={locations}
            noDataText={getTranslations(t, Translates.No_Reservations_Found)}
          ></GlobalTable>
        </Grid>
        {isConfirmOpen && (
          <ConfirmDialog
            open={isConfirmOpen}
            callBack={callbackConfirmation}
            title={`${getTranslations(t, Translates.DELETE)}`}
            content={setContent}
            custref={{}}
            confirmButtonText={`${getTranslations(t, Translates.CONFIRM)}`}
            cancelButtonText={`${getTranslations(t, Translates.Cancel)}`}
          />
        )}
      </Grid>
    )
  }
  return (
    <Grid container className="main-container">
      <Grid item sm={6} className="d-flex align-items-center">
        <h6>{getTranslations(t, Translates.Locations)}</h6>
      </Grid>
      <Grid item sm={6} className="text-right">
        <Button
          variant="contained"
          className="primary-btn"
          onClick={handleNewLocationClick}
          disabled={setIsDisableButton(
            configTypeFromStore,
            locations,
            LocationsList
          )}
        >
          <AddIcon className="mr-1" />{" "}
          {getTranslations(t, Translates.New_Location)}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {displayGrid()}
        <Divider className="dashed my-5"></Divider>
      </Grid>
    </Grid>
  )
}

export default CountFieldsLocations
