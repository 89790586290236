import { formField } from "src/utils/constants"
import { createEditFieldNames } from "../CreateEditMultiEventOutput"
import { DateRangeFieldsType } from "../../Types"

export const DateRangeFields: DateRangeFieldsType = {
  isSpecificDateRange: {
    fieldType: formField.checkbox,
    props: {
      name: createEditFieldNames.IsSpecificDateRange.name,
      label: createEditFieldNames.IsSpecificDateRange.label,
      className: "light"
    },
    triggerValidationFields: [
      {
        name: createEditFieldNames.StartDate.name
      },
      {
        name: createEditFieldNames.EndDate.name
      }
    ]
  },
  startDate: {
    fieldType: formField.date,
    condition: {
      name: createEditFieldNames.IsSpecificDateRange.name,
      value: true
    },
    props: {
      name: createEditFieldNames.StartDate.name,
      label: createEditFieldNames.StartDate.label,
      required: true
    },
    triggerValidationFields: [
      {
        name: createEditFieldNames.EndDate.name
      },
      {
        name: createEditFieldNames.Time.name
      }
    ],
    parentCols: { sm: 3 },
    childCols: { sm: 12 }
  },
  endDate: {
    fieldType: formField.date,
    condition: {
      name: createEditFieldNames.IsSpecificDateRange.name,
      value: true
    },
    props: {
      name: createEditFieldNames.EndDate.name,
      label: createEditFieldNames.EndDate.label,
      required: true,
      className: "ml-2"
    },
    triggerValidationFields: [
      {
        name: createEditFieldNames.StartDate.name
      }
    ],
    parentCols: { sm: 3 },
    childCols: { sm: 12 }
  }
}
