import { useState } from "react"
import { PATH } from "src/constants/paths"
import { useHistory, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import {
  setNewReports,
  setNewReportPrompts,
  setCurrentOutputFile
} from "src/redux/slices/configSlice"
import {
  getFieldDelimiterValue,
  getRecordDelimiterValue
} from "../OutputFiles/OutputHelper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { columnData, PromptRender } from "../PromptHelpers"
import { useTranslation } from "react-i18next"
let preDeleteRow = {} as any

const OutputPrompts = ({ form, isParentEdit }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  let search = window.location.search || ""
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  let { storeId }: { storeId: string } = useParams()
  const reportPromptData = useSelector(
    (state: RootState) => state.config?.newReportPrompts
  )
  const newOutputFile = useSelector(
    (state: RootState) => state.config?.currentOutputFile
  )
  const { newReportPrompts } = useSelector((state: RootState) => {
    return {
      configData: state.config?.configDetails,
      newReportPrompts: state.config?.newReportPrompts
    }
  })

  const deletePrompt = (row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row }
  }
  const callbackConfirmation = (res: boolean) => {
    if (res) {
      let dt = reportPromptData
        .filter(f => f.Label !== preDeleteRow?.Label)
        .map((m: any, i) => {
          return { ...m, SortOrder: i + 1 }
        })

      dispatch(setNewReportPrompts(dt))
    }
    setIsConfirmOpen(false)
  }

  const handleNewPromptClick = () => {
    dispatch(
      setNewReports({
        promptsData: reportPromptData
      })
    )
    form = {
      ...form,
      FieldDelimiter: getFieldDelimiterValue(form),
      RecordDelimiter: getRecordDelimiterValue(form)
    }

    const destination = {
      CustomerPortalCheck: form?.customerPortalCheck,
      FtpDirectory: form?.selectedOption,
      Email: form?.chips
    }

    dispatch(
      setCurrentOutputFile({ ...newOutputFile, form, Destination: destination })
    )
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_EDIT_PROMPTS + search, {
        isEdit: false,
        storeId: storeId,
        isParentEdit: isParentEdit,
        form: form
      })
    })
  }

  const handleEditPromptClick = (row: any) => {
    updateWarnOnTabChangeToStore(false)
    dispatch(setCurrentOutputFile(form))
    form = {
      ...form,
      FieldDelimiter: getFieldDelimiterValue(form),
      RecordDelimiter: getRecordDelimiterValue(form)
    }
    const destination = {
      CustomerPortalCheck: form?.customerPortalCheck,
      FtpDirectory: form?.selectedOption,
      Email: form?.chips
    }

    dispatch(
      setCurrentOutputFile({ ...newOutputFile, form, Destination: destination })
    )
    let preEditRow = { ...row }
    let editPromtObj = {
      SortOrder: preEditRow.SortOrder,
      Label: preEditRow.Label,
      VariableName: preEditRow.VariableName,
      Type: preEditRow.Type,
      DefaultValue: preEditRow.DefaultValue,
      NumberOfDecimals: preEditRow.NumberOfDecimals,
      CustomList: preEditRow.CustomList
    }
    setTimeout(() => {
      history.push(PATH.CREATE_EDIT_PROMPTS + search, {
        isEdit: true,
        editPromptObj: editPromtObj,
        storeId: storeId,
        isParentEdit: isParentEdit,
        form: form,
        index: Number(row?.id)
      })
    })
  }
  const columnsData = columnData(
    handleEditPromptClick,
    deletePrompt,
    t,
    configTypeFromStore
  )

  return (
    <>
      {PromptRender(
        false,
        columnsData,
        handleNewPromptClick,
        reportPromptData,
        newReportPrompts,
        { isConfirmOpen, callbackConfirmation, preDeleteRow },
        configTypeFromStore
      )}
    </>
  )
}

export default OutputPrompts
