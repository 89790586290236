import { configureStore, ThunkDispatch, Action } from "@reduxjs/toolkit"
import authSlice from "./slices/authSlice"
import { useDispatch } from "react-redux"
import { ThunkAction } from "redux-thunk"
import settingsReducer from "./slices/settingsSlice"
import reservationsReducer from "./slices/reservationsSlice"
import scheduleReducer from "./slices/scheduleSlice"
import userReducer from "./slices/usersSlice"
import configReducer from "./slices/configSlice"
import configurationReducer from "./slices/configurationSlice"
import lookupRoutinesSlice from "./slices/lookupRoutinesSlice"
import uploadFilesReducer from "./slices/uploadFileSlice"
import reportsReducer from "./slices/reportsSlice"
import liveEventsReducer from "./slices/liveEventsSlice"
import varianceReducer from "./slices/ConfigPortal/VarianceSlice"
import outputBundleReducer from "./slices/ConfigPortal/OutputBundleSlice"
import reportReducer from "./slices/ConfigPortal/ReportSlice"
import columnSlice from "./slices/ConfigPortal/ColumnSlice"
import ftpConnectionReducer from "./slices/ConfigPortal/FtpConnectionSlice"
import customerSelectionReducer from "./slices/ConfigPortal/CustomerSelectionSlice"
import multiEventOutputReducer from "./slices/MultiEventOutputSlice"
export const store = configureStore({
  reducer: {
    auth: authSlice,
    settings: settingsReducer,
    reservations: reservationsReducer,
    schedule: scheduleReducer,
    user: userReducer,
    config: configReducer,
    configuration: configurationReducer,
    lookup: lookupRoutinesSlice,
    uploadFiles: uploadFilesReducer,
    reports: reportsReducer,
    liveEvents: liveEventsReducer,
    variance: varianceReducer,
    outputBundle: outputBundleReducer,
    report: reportReducer,
    column: columnSlice,
    ftpConnection: ftpConnectionReducer,
    customerSelection: customerSelectionReducer,
    multiEventOutput: multiEventOutputReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
export type AppDispatch = ThunkDispatch<RootState, void, Action>
export const useAppThunkDispatch = () => useDispatch<AppDispatch>()
export type AppThunk = ThunkAction<void, RootState, unknown, Action>
export default store;
