//BEGIN-NOSCAN
import { encrypt } from "src/utils/helper"
import ApiService from "src/utils/useAxios"

const api = new ApiService()
// istanbul ignore next
export class SettingsService {
  addregionalgroupdetails(payload: any) {
    const endPoint = "/api/Customer/AddRegionalGroupDetails"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
  getLocationsByStore(customerIds) {
    const endPoint = `/api/Company/GetCustomerDetailByCustomerId`
    return api.post(endPoint, customerIds)
  }
  addlocationdetails(payload: any) {
    const endPoint = "/api/Location/AddLocationDetails"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
  updatelocationdetails(payload: any) {
    const endPoint = "/api/Location/UpdateLocationDetails"
    return api
      .put(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
  getalllocationsbyaccount(customerId) {
    const endPoint = `/api/Location/GetAllLocationsByAccount/${customerId}`
    return api.findAll(endPoint)
  }
  getCompanyDetails(customerId) {
    const endPoint = `/api/Company/GetCompanyDetails?customerId=${customerId}`
    return api.findAll(endPoint)
  }
  addCompanyDetails(payload: any, config: any) {
    const endPoint = "/api/Company/AddCompanyDetails"
    return api.savefile(endPoint, payload, config)
  }
  deleteCompanyLogo(customerId) {
    const endPoint = `api/Company/DeleteCompanyLogo?accountId=${customerId}`
    return api.delete(endPoint, {})
  }

  downloadlocationsbyaccount(customerId) {
    const endPoint = `/api/Location/DownloadLocationsByAccount/${customerId}`
    return api.findAll(endPoint)
  }
  getlocationbystore(storeId) {
    const endPoint = `/api/Location/GetLocationByStore/${storeId}`
    return api.findAll(endPoint)
  }
  bulkUpdateLocation(payload: any, config: any) {
    let params = { ...payload }
    let fileData = params.fileData ? encrypt(params.fileData) : ""
    params.fileData = fileData
    const endPoint = "/api/Location/AddLocationBulk"
    return api.saveWithParams(endPoint, params, config)
  }
  getCountryDetails() {
    const endPoint = `/api/Location/GetCountryDetails`
    return api.findAll(endPoint)
  }
  getStateDetails(countryId) {
    const endPoint = `/api/Location/GetStateDetailsByCountryId/${countryId}`
    return api.findAll(endPoint)
  }
  getTimeZoneDetails() {
    //const endPoint = `api/Lookup/GetLookUpDataByType?lookupType=US_Standard_TimeZones`
   const endPoint = `api/Lookup/GetTimeZones`
    return api.findAll(endPoint)
  }
  addLocationAttributeDetails(payload: any) {
    const endPoint = "api/Customer/AddLocationAttributeDetails"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
  editCompanySettings(payload: any) {
    const endPoint = `/api/Company/UpdateCompanyInfo`
    return api.put(endPoint, payload)
  }
  updateSurveyOption(payload: any) {
    const endPoint = `/api/Company/UpdateCustomerSatisfactionSurvey`
    return api.put(endPoint, payload)
  }
}
//END-NOSCAN
