import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class CustomerService {
  getCustomers() {
    const endPoint = `/api/customer`
    return api.findAll(endPoint)
  }
  getAccountsByEmail(email: string) {
    const endPoint: string = `api/User/GetSelectedAccountsByEmail?userEmail=${email}`
    return api.findAll(endPoint)
  }
}
