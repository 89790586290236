import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { fieldNames } from "../../../Utils/Constants"
import { getVarianceFormVariable } from "../../../Utils/FunctionsHelper"
import MUIRadio from "src/components/MUIFormFields/MUIRadio"
import { getTranslations } from "src/utils/helper"

const RadioButtons = ({
  formControlLabels,
  name,
  onChange
}: {
  formControlLabels: any
  name: string
  onChange?: Function
}) => {
  const { t } = useTranslation<any>()
  const { watch } = useFormContext()
  let { varianceForm } = getVarianceFormVariable(
    watch(fieldNames.CurrentTabVarianceType)
  )
  const IsNotEnableFeature = !watch(varianceForm.IsEnable.name)
  return (
    <MUIRadio
      name={name}
      className={"mb-2"}
      formControl={{
        disabled: IsNotEnableFeature,
        radioGroup: {
          row: true,
          formControlLabels: Object.keys(formControlLabels).map(i => ({
            ...formControlLabels[i],
            label: getTranslations(t, formControlLabels[i].label),
            radio: { size: "medium" },
            className: "mr-7"
          })),
          ...(onChange && { onChange: onChange })
        }
      }}
    />
  )
}

export default RadioButtons
