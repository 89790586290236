import React, { ReactNode, useState, useEffect, useCallback } from "react"
import Header from "../../src/components/Header/Header"
import styles from "./MainLayout.module.scss"
import { KEY_CODES } from "src/utils/constants"

interface Props {
  children: ReactNode
}

export default function MainLayout(props: Props) {
  const { children } = props
  const [toggleFooter, setToggleFooter] = useState(true)
  const [loadChild, setLoadChild] = useState(false)
  const footerToggleClick = () => {
    let toggledOption = !toggleFooter
    setToggleFooter(toggledOption)
  }
  const handleFooterToggleKeyUp = useCallback((e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      footerToggleClick()
    }
  }, [])

  useEffect(() => {}, [])
  const handleLoadChild = () => {
    setLoadChild(true)
  }
  return (
    <div
      className={`wrapper d-flex flex-column align-items-stretch ${
        !toggleFooter ? "footer-collapse" : ""
      }`}
    >
      {/* <div  className={pathname === "*"? 'wrapper d-flex flex-column align-items-stretch no-footer': ' wrapper d-flex flex-column align-items-stretch'}> */}
      <main className={`flex-grow-1 mw-100 ${styles.MainContentContainer}`}>
        <Header handleLoadChild={handleLoadChild} />
        {loadChild && <div className={`main-wrapper`}>{children}</div>}
        <span
          data-testid="MLFooterBtn"
          className="footer-btn"
          onClick={footerToggleClick}
          onKeyUp={handleFooterToggleKeyUp}
        ></span>
      </main>

      {/* <Footer /> */}

      {/* </div> */}
    </div>
  )
}
