import {
  FilledInput,
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Tooltip
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import React, { useState, useEffect } from "react"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { setLiveEventsFilters } from "src/redux/slices/liveEventsSlice"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"

const LiveEventsToolBar: React.FC<any> = ({
  handleSearch,
  handleFilter,
  filterCount,
  liveEvents,
  tabValue,
  pollingCount
}) => {
  const { t } = useTranslation<any>()
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const liveEventsPrevFilter = useSelector(
    (state: RootState) => state.liveEvents?.liveEventsFilterForm
  )
  useEffect(() => {
    setSearch("")
  }, [tabValue])

  useEffect(() => {
    handleSearch(search)
  }, [search, liveEventsPrevFilter, pollingCount])
  const getBreadcrumbConfig = () => {
    let currentPath: string = `liveEvents`
    let variables: any = {
      liveEvents: getTranslations(t, Translates.Live_Events)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  const handleSearchClick = e => {
    setSearch(e.target.value)
  }
  const handleFilterClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    dispatch(setLiveEventsFilters(liveEventsPrevFilter))
    handleFilter()
  }

  return (
    <div className="main-toolbar">
      <div className="main-container">
        <Grid container>
          <Grid item sm={5}>
            <div className="custom-breadcrumb">
              <BreadCrumb
                customPath={"LiveEvents"}
                breadCrumbInfo={getBreadcrumbConfig()}
                renderedPage={""}
              />
            </div>
          </Grid>
          {liveEvents && liveEvents.length > 0 ? (
            <Grid item sm={7}>
              <div className="text-right toolbar-actions">
                <FormControl
                  variant="filled"
                  id="filterButton"
                  className="custom-form-control search"
                >
                  <FilledInput
                    id="filterButton"
                    data-testid="tool-search"
                    placeholder={getTranslations(
                      t,
                      Translates.Name_StoreNo_Address
                    )}
                    value={search}
                    autoComplete={"off"}
                    onChange={e => {
                      handleSearchClick(e)
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon></SearchIcon>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Tooltip arrow title={getTranslations(t, Translates.Filter)}>
                  <Button
                    variant="text"
                    id="filterButton"
                    dat-testid="filter"
                    className=" mx-2 icon-btn"
                    onClick={handleFilterClick}
                  >
                    <FilterListOutlinedIcon data-testid="filter" />
                    {filterCount != 0 ? (
                      <span className="filter-icon__count">{filterCount}</span>
                    ) : null}
                  </Button>
                </Tooltip>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </div>
  )
}
export default LiveEventsToolBar
