import { updateWarnOnTabChange } from "src/redux/slices/configSlice"
import { store } from "src/redux/store"
import { replaceAllMultiple } from "src/utils/helper"

export const getBreadcrumbConfig = ({
  landingName,
  landingPath,
  componentName,
  nextPage = false,
  storeId = -1,
  createPath = "",
  editPath = "",
  showHomeIcon = false
}: {
  landingName: string
  landingPath: string
  componentName: string
  nextPage?: boolean
  storeId?: number
  createPath?: string
  editPath?: string
  showHomeIcon?: boolean
}) => {
  let landingPage = "landingPage"
  let custLinks: any = {}
  custLinks[`@${landingPage}`] = landingPath
  let currentPath: string = `##configuration##/@${landingPage}/${componentName}`
  let variables: any = {}
  variables[`@${landingPage}`] = landingName
  variables[`${componentName}`] = componentName

  let bConfig = {
    custLinks,
    showHomeIcon: showHomeIcon,
    currentPath,
    mapObj: variables
  }

  if (nextPage) {
    const componentNameVariable = `@${replaceAllMultiple(
      componentName,
      [" "],
      [""]
    )}`
    bConfig.currentPath = bConfig.currentPath.replace(
      `${componentName}`,
      `${componentNameVariable}`
    )
    let componentPath =
      storeId !== -1 ? editPath.replace(":id", `${storeId}`) : createPath
    bConfig.custLinks = {
      ...bConfig.custLinks,
      ...{ [componentNameVariable]: componentPath.slice(1) }
    }
    delete bConfig.mapObj[`${componentName}`]
    bConfig.mapObj = {
      ...bConfig.mapObj,
      ...{ [componentNameVariable]: componentName }
    }
    bConfig.showHomeIcon = false
  }

  return {
    componentName,
    bConfig
  }
}

export const firstLetterUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const handleAutoCompleteDataSourceChange = (
  values,
  dataSource,
  result,
  setDataSource
) => {
  updateWarnOnTabChangeToStore(true)
  const value = values
  if (value[value.length - 1] === "All") {
    setDataSource(dataSource.length === result.length ? [] : result)
    return
  }
  setDataSource(value)
}

export const updateWarnOnTabChangeToStore = (
  val: boolean,
  override = false
) => {
  const location = window.location
  if (override || location.pathname.includes("/configuration"))
    store.dispatch(updateWarnOnTabChange(val))
}

const promptTypeList = { DECIMAL: "DECIMAL", NUMERIC: "NUMERIC" }

const replaceWithSQLWithPromptDefault = (locSql, promptsDataObj) => {
  const isPromptTypeMatch = type =>
    [
      promptTypeList.DECIMAL.toLowerCase(),
      promptTypeList.NUMERIC.toLowerCase()
    ].includes(type?.toLowerCase())

  const processPrompt = prompt => {
    const isNumber = isPromptTypeMatch(prompt?.Type)
    const variableName = `@${prompt?.VariableName}`

    while (locSql.split(" ").includes(variableName)) {
      locSql = locSql.replace(
        variableName,
        isNumber
          ? prompt?.DefaultValue
          : `'${prompt?.DefaultValue?.toString()}'`
      )
    }
  }

  if (Array.isArray(promptsDataObj) && promptsDataObj.length) {
    promptsDataObj.forEach(processPrompt)
  }

  return locSql
}
export const makeClearQueryToExecute = (
  sql: string,
  promptsDataObj,
  isPromptReplace = true
) => {
  let locSql = sql
  locSql = replaceAllMultiple(
    locSql,
    [
      "\n",
      "\r",
      "\t",
      "\\n",
      "\\r",
      "\\t",
      "\n \r",
      "\\n \\r",
      ">",
      "<",
      "=",
      "> =",
      "< ="
    ],
    [" ", " ", " ", " ", " ", " ", " ", " ", " > ", " < ", " = ", ">=", "<="]
  )
  locSql = locSql.replace(/(?:\r\n|\r|\n)/g, " ")
  locSql = locSql.replace(/[\r\n\t]/g, " ")
  locSql = locSql.replace(/@(\w+)/g, " @$1 ")
  if (isPromptReplace) {
    locSql = replaceWithSQLWithPromptDefault(locSql, promptsDataObj)
  }

  locSql = locSql.trim()
  return locSql
}

export const ConfigTabNames = {
  GeneralSettings: "GeneralSettings",
  InputFiles: "InputFiles",
  Categories: "Categories",
  CountFields: "CountFields",
  LookupRoutines: "LookupRoutines",
  CountPrograms: "CountPrograms",
  Variances: "Variances",
  Audits: "Audits",
  Reports: "Reports",
  Outputs: "Outputs",
  SubTotals: "Sub-totals",
  GrandTotals: "Grand-totals",
  OutputBundles: "OutputBundles",
  MultiEventOutputs: "Multi-Event-Outputs"
}

export const checkRequiredFieldErrorCheck = data => {
  let content: string = ""
  let alertMsgFieldError = {}
  const fileNameLen = 260
  if (data?.FileName?.length > fileNameLen) {
    content = `File name length can not be more than ${fileNameLen}!`
  } else if (!data?.SQL) {
    content = "SQL query is required can not be empty!"
  } else if (!data.Columns?.length) {
    content = "Columns section is required can not be empty!"
  }
  if (content) {
    alertMsgFieldError = {
      severity: "error",
      title: "Validation Error!",
      content: content
    }
  }
  return { alertMsgFieldError }
}

export const reducer = (state, action) => {
  // If action is a function, call it with the current state and return the result
  if (typeof action === "function") {
    return action(state)
  }

  // Otherwise, toggle the state
  return !state
}

export const emptyFieldValues = ["", null, undefined]
