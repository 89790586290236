import { Controller, useFormContext } from "react-hook-form"
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material"

import shortid from "shortid"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { getDefaultValue, getFieldError } from "./Helper"
import { useCallback } from "react"

interface Option {
  label: string
  value: any
  default?: boolean
}
export interface Props {
  inputAttributes: {
    name: string
    label: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    InputProps?: any
    value?: any
    defaultValue?: any
    onChange?: Function
    options: Option[]
  }
}

const MUIMultiSelect = ({ inputAttributes }: Props) => {
  const {
    name,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    required,
    value,
    defaultValue,
    onChange,
    options
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods

  let defVal: any = getDefaultValue(value, defaultValue, options, true)
  const { isError, ErrorText } = getFieldError(name, errors)
  const renderSelectedValue = useCallback(
    //istanbul ignore next
    selected => selected?.join(", ") || "",
    []
  )

  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field } }) => (
        <FormControl variant="filled" className="custom-form-control select">
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            data-testid="selectCombobox"
            onChange={e => {
              field.onChange(e)
              updateWarnOnTabChangeToStore(true)
              if (onChange) onChange()
            }}
            multiple
            id={id ?? name}
            required={required ?? false}
            size={size ?? "small"}
            placeholder={placeholder}
            inputRef={ref}
            variant={variant ?? "filled"}
            color={color ?? "primary"}
            fullWidth
            className={`${className}`}
            error={isError}
            renderValue={renderSelectedValue}
          >
            {options.map((type, inx) => (
              <MenuItem
                key={shortid.generate()}
                value={type.value}
                className="dropdown-list"
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      field.value && field.value.indexOf(type.value) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={type.label} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={isError ? "text-danger" : ""}>
            {ErrorText}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default MUIMultiSelect
