import { formField } from "src/utils/constants"
import { renderType } from "./FcsOptions"
import { systemDefaultFieldNames } from "../CreateEditMultiEventOutput"

export const systemDefaultFields = [
  [
    { type: renderType.heading, heading: "Min_Store" },
    {
      type: renderType.field,
      fieldType: formField.text,
      width: "150px",
      className: "ml-2",
      props: {
        name: systemDefaultFieldNames.SDMinStore.name,
        label: systemDefaultFieldNames.SDMinStore.label,
        required: true
      },
      triggerValidationFields: [
        { name: systemDefaultFieldNames.SDMaxStore.name }
      ]
    },
    { type: renderType.heading, heading: "Max_Store", className: "ml-2" },
    {
      type: renderType.field,
      fieldType: formField.text,
      width: "150px",
      className: "ml-2",
      props: {
        name: systemDefaultFieldNames.SDMaxStore.name,
        label: systemDefaultFieldNames.SDMaxStore.label,
        required: true
      },
      triggerValidationFields: [
        { name: systemDefaultFieldNames.SDMinStore.name }
      ]
    }
  ]
]
