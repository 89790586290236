import { Grid } from "@mui/material"
import React, { useEffect, useState, ReactNode } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  containsAll,
  decryptUrlParams,
  getTranslations
} from "../../../src/utils/helper"
import MainLayout from "../../../src/layouts/MainLayout"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import { ConfigService } from "../../../src/service/config.service"
import {
  getConfigDetails,
  getConfiguration,
  setConfigDetails,
  setDefaultConfigType,
  setDefaultCountFields
} from "../../../src/redux/slices/configSlice"
import Categories from "./Categories/Categories"
import CountFields from "./CountFields/CountFields"
import CreateEditCategory from "./Categories/CreateEditCategory"
import NewLookupRoutine from "./LookupRoutines/NewLookupRoutine"
import GeneralSettings from "./GeneralSettings"
import CountFieldsCreateEditLocation from "./CountFields/CountFieldsCreateEditLocation"
import CountFieldsCreateEditProduct from "./CountFields/CountFieldsCreateEditProduct"
import LookupRoutines from "./LookupRoutines/LookupRoutines"
import { PATH } from "../../../src/constants/paths"
import { Tab } from "../../../src/constants/configurationPortal"
import CreateLookup from "./LookupRoutines/CreateLookup/index"
import Audits from "./Audits/Audits"
import NewAuditRule from "./Audits/NewAuditRule"
import OutputFiles from "./OutputFiles/OutputFiles"
import CreateEditOutputFiles from "./OutputFiles/CreateEditOutputFiles"
import CountPrograms from "./CountPrograms/LandingPage"
import CreateEditCountProgram from "./CountPrograms/CreateEditCountProgram"
import OutputCreateEditPrompts from "./OutputFiles/OutputCreateEditPrompts"
import OutputPrompts from "./OutputFiles/OutputPrompts"
import InputFiles from "./InputFiles/InputFiles"
import InputFileTypeSelect from "./InputFiles/InputFileTypeSelect"
import CreateEditInputFileAuxiliary from "./InputFiles/CreateEditInputFileAuxiliary"
import CreateEditInputFileCustomer from "./InputFiles/CreateEditInputFileCustomer"
import CreateEditColumn from "./OutputFiles/CreateEditColumn"
import EditColumns from "./OutputFiles/EditColumns"
import CreateEditOutputSubTotals from "./OutputFiles/CreateEditOutputSubTotals"
import CreateEditOutputGrandTotals from "./OutputFiles/CreateEditOutputGrandTotals"
import CreateInputFields from "./InputFiles/CreateInputFields"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { ConfigurationStoreType } from "src/utils/types/types"
import { updateWarnOnTabChangeToStore } from "./helper"
import {
  currencySymbolDisplay,
  getCurrencySymbol
} from "./CountFields/Constants"
import {
  alertMsgShow,
  emptyAlert,
  getCountFieldFormateForConfig
} from "./FunctionHelper"
import { DEFAULT_RADIX } from "src/utils/constants"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"

const ConfigurationPortal = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation<any>()
  const {
    config: { configDetails, Outputs, customerConfigurationsStatus },
    varianceData: { varianceRedux },
    outputBundleData: { outputBundlesRedux },
    reportData: { reportsRedux }
  } = ConfigDataHook()
  let location = useLocation() as any
  let configService = new ConfigService()
  const dispatch = useAppThunkDispatch()

  const generalSettings = useSelector(
    (state: RootState) => state.config?.generalSettings
  )

  const LocationCategories = useSelector(
    (state: RootState) => state.config?.locationCategories
  )

  const lookupRoutines = useSelector(
    (state: RootState) => state.config?.lookupRoutines
  )
  const customers = useSelector((state: RootState) => state.settings?.customers)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const countFields = useSelector(
    (state: RootState) => state.config?.defaultCountFields
  )
  const countPrograms = useSelector(
    (state: RootState) => state.config?.countPrograms
  )

  const efficiencySettings = useSelector(
    (state: RootState) => state.config?.efficiencySettings
  )
  const accuracySettings = useSelector(
    (state: RootState) => state.config?.accuracySettings
  )

  const defaultCountFields = useSelector(
    (state: RootState) => state.config?.defaultCountFields
  )

  const inputFiles = useSelector((state: RootState) => state.config?.inputFiles)
  const AuditsData = useSelector((state: RootState) => state.config?.Audits)
  const warnOnTabChange = useSelector(
    (state: RootState) => state.config.warnOnTabChange
  )

  const [alertVisible, setAlertVisible] = useState(false)
  const [alertMsg, setAlertMsg] = useState(emptyAlert)
  const [configData, setConfigData] = useState({} as any)
  const [gSObject, setGSObject] = useState({
    CustomerID: 0,
    CustomerName: "",
    ConfigurationId: 0,
    ConfigurationName: "",
    ConfigurationVersion: "",
    Currencies: "",
    Notes: "",
    SupportedLanguages: [],
    Status: 3
  })

  let path = location.pathname.split("/")
  let params = new URLSearchParams(decryptUrlParams(location.search?.slice(1)))
  let idString = params.get("id")
  const idNum = idString ? parseInt(idString, DEFAULT_RADIX) : undefined
  let editType = params.get("type")

  const selectedCustomerList = customers?.filter((c: any) => c.selected)
  const customerIds = selectedCustomerList?.map(i => i.idCustomer)
  useEffect(() => {
    if (alertVisible === true) {
      setTimeout(() => {
        setAlertVisible(false)
      }, 3000)
    }
  }, [alertVisible])

  useEffect(() => {
    const controller = new AbortController()
    if (
      customers?.length &&
      !["success", "loading", "failed"].includes(customerConfigurationsStatus)
    ) {
      dispatch(getConfigDetails(customerIds))
    }
    return () => {
      controller.abort()
    }
    //eslint-disable-next-line
  }, [customers])

  useEffect(() => {
    const controller = new AbortController()
    dispatch(setDefaultConfigType(editType))
    getConfigurationData()
    updateWarnOnTabChangeToStore(false)
    return () => {
      controller.abort()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true
    //istanbul ignore next
    if (isMounted) {
      window.onbeforeunload = () => (warnOnTabChange ? true : null)
    }
    return () => {
      isMounted = false
    }
  }, [warnOnTabChange])

  const getConfigurationData = () => {
    if (configDetails && Object.keys(configDetails).length) {
      setConfigState(configDetails)
    } else {
      dispatch(getConfiguration(idString)).then((temp: any) => {
        if (temp.payload.data) {
          setConfigState(temp.payload.data)
        }
      })
    }
  }

  const setConfigState = data => {
    setConfigData(data)
    setGSObject({
      CustomerID: data.idCustomer,
      CustomerName: "",
      ConfigurationId: data.idConfig,
      ConfigurationName: data.name,
      ConfigurationVersion: data.version,
      Currencies: "",
      Notes: "",
      SupportedLanguages: data.supportedLanguages,
      Status: 3
    })
  }

  const generatePayload = (
    tab: any,
    tabVal: any,
    prod_required = false,
    department_required = false
  ) => {
    let payload: any = {
      createdDate: new Date(),
      lastUpdatedDate: new Date(),
      idConfig: idNum,
      idCustomer: parseInt(customerId, DEFAULT_RADIX),
      name: configDetails?.name || configData?.name,
      status: generalSettings.Status || 3,
      supportedLanguages: configDetails?.supportedLanguages?.toString() || "",
      version: configDetails?.version || "1.0",
      configuration: ""
    }

    let configVal: ConfigurationStoreType = configDetails?.configuration
      ? JSON.parse(configDetails?.configuration)
      : {
          GeneralSettings:
            Object.keys(generalSettings).length === 0
              ? gSObject
              : generalSettings,
          LocationCategories: LocationCategories,
          LookupRoutines: lookupRoutines,
          Reports: reportsRedux,
          Fields: countFields,
          GroupedVariances: varianceRedux.GroupVariance,
          FullStoreVariances: varianceRedux.FullVariance,
          Outputs: Outputs,
          Audits: AuditsData,
          CountPrograms: countPrograms,
          InputFiles: inputFiles,
          FilesRequired: {
            DepartmentFileNotRequired: false,
            ProductFileNotRequired: false
          },
          Efficiency: efficiencySettings,
          Accuracy: accuracySettings
        }

    if (tab === Tab.GSTab) {
      payload.name = tabVal?.ConfigurationName
      payload.status = tabVal?.Status
      const Fields = configVal.Fields
      const locDefaultCountFields = JSON.parse(
        JSON.stringify(defaultCountFields)
      )
      if (generalSettings?.Currencies !== tabVal.Currencies) {
        const { cDisplayMap } = getCurrencySymbol()
        Object.keys(configVal.Fields).forEach(i => {
          if (
            currencySymbolDisplay.includes(i) &&
            Fields[i].CurrencySymbol &&
            ![undefined, "", null, false].includes(Fields[i].CurrencySymbol)
          ) {
            Fields[i].CurrencySymbol = cDisplayMap.get(tabVal.Currencies)
            locDefaultCountFields[i].CurrencySymbol = cDisplayMap.get(
              tabVal.Currencies
            )
          }
        })
        let finalObj: any = { data: locDefaultCountFields, type: "UPDATE" }
        dispatch(setDefaultCountFields(finalObj))
      }
      payload.configuration = JSON.stringify({
        ...configVal,
        GeneralSettings: {
          CustomerID: tabVal.CustomerID,
          CustomerName: tabVal.CustomerName,
          ConfigurationId: configDetails?.idConfig,
          ConfigurationName: tabVal.ConfigurationName,
          ConfigurationVersion: configDetails?.version,
          Currencies: tabVal.Currencies,
          Notes: tabVal.Notes,
          SupportedLanguages: tabVal.SupportedLanguages,
          Status: tabVal.Status
        },

        Accuracy: {
          ...configVal["Accuracy"],
          AuditReasonsToExclude: configVal["Audits"]["ExcludedAuditRules"],
          IncludeTagAudits: tabVal.AccuracySettings["IncludeTagAudits"],
          Goal: tabVal.AccuracySettings["Goal"],
          SecondaryGoal: tabVal.AccuracySettings["SecondaryGoal"]
        },
        Efficiency: tabVal.EfficiencySettings,
        Fields: Fields
      })
    } else if (tab === Tab.LocationCategoriesTab) {
      payload.configuration = JSON.stringify({
        ...configVal,
        LocationCategories: [...tabVal]
      })
    } else if (tab === Tab.LookupRoutinesTab) {
      payload.configuration = JSON.stringify({
        ...configVal,
        LookupRoutines: [...tabVal]
      })
    } else if (tab === Tab.CountFieldsTab) {
      let fieldsObj: any = Object.values(tabVal)[0]
      let fieldsObjCopy = getCountFieldFormateForConfig(fieldsObj)

      if (Object.keys(fieldsObjCopy).length)
        payload.configuration = JSON.stringify({
          ...configVal,
          Fields: {
            ...fieldsObjCopy,
            ...{ countScreenTotals: tabVal?.countScreenTotals || "" },
            ...{ productKey: tabVal?.productKey || "" }
          },
          CountPrograms: countPrograms
        })
    } else if (tab === Tab.OutputFiles) {
      const allOutputsNames = Outputs.map(i => i.Name)
      let locOutputBundles = outputBundlesRedux.map(i1 => {
        return {
          ...i1,
          Outputs: i1.Outputs.filter(i => allOutputsNames.includes(i))
        }
      })

      payload.configuration = JSON.stringify({
        ...configVal,
        Outputs: [...tabVal],
        OutputBundles: [...locOutputBundles]
      })
    } else if (tab === Tab.AuditsTab) {
      payload.configuration = JSON.stringify({
        ...configVal,
        Audits: tabVal,
        Accuracy: {
          ...configVal["Accuracy"],
          AuditReasonsToExclude: tabVal["ExcludedAuditRules"]
        }
      })
    } else if (tab === Tab.CountProgramsTab) {
      payload.configuration = JSON.stringify({
        ...configVal,
        CountPrograms: [...tabVal]
      })
    } else if (tab === Tab.InputFilesTab) {
      payload.configuration = JSON.stringify({
        ...configVal,
        InputFiles: [...tabVal],
        FilesRequired: {
          DepartmentFileNotRequired: department_required,
          ProductFileNotRequired: prod_required
        }
      })
    }

    const configObj = JSON.parse(payload.configuration)
    if (!configObj.hasOwnProperty("FilesRequired")) {
      configObj.FilesRequired = {
        DepartmentFileNotRequired: false,
        ProductFileNotRequired: false
      }
      payload.configuration = JSON.stringify(configObj)
    }

    return payload
  }

  const handleConfigSaveClick = async (
    finalValues,
    tab,
    productValid = false,
    departmentValid = false
  ) => {
    const payload = generatePayload(
      tab,
      finalValues,
      productValid,
      departmentValid
    )
    if (!payload?.configuration) {
      setAlertVisible(true)
      setAlertMsg({
        severity: "error",
        title: "Error",
        content: "Please add some details before continue with this!"
      })

      return false
    }

    try {
      let _res: any = {}
      if (Object.keys(payload).length) {
        _res = await configService.updateConfiguration(payload)
      }
      if (_res && _res.status === 200) {
        if (tab === Tab.GSTab) {
          if (finalValues?.Status !== generalSettings?.Status) {
            dispatch(getConfigDetails(customerIds))
          }
        }
        dispatch(setConfigDetails(payload))
        updateWarnOnTabChangeToStore(false)
        setAlertVisible(true)
        setAlertMsg({
          severity: "success",
          title: getTranslations(t, Translates.Success),
          content: `${getTranslations(t, Translates.Saved_Successfully)!}`
        })
      }
    } catch (err: any) {
      setAlertVisible(true)
      setAlertMsg({
        severity: "error",
        title: "Error",
        content: err?.Message || "Something went wrong, Please try again later!"
      })
    }
  }

  if (children) {
    return <MainLayout>{children}</MainLayout>
  }

  return (
    <MainLayout>
      {path.includes(PATH.CONFIGURATION_PORTAL.split("/")[2]) ? (
        <GeneralSettings liftSettingsSaveClick={handleConfigSaveClick} />
      ) : null}

      {path.includes(PATH.CONFIGURATION_COUNT_FIELDS.split("/")[2]) ? (
        <CountFields liftCountFieldsSaveClick={handleConfigSaveClick} />
      ) : null}
      {path.includes(PATH.CONFIG_LOOKUP_ROUTINES.split("/")[2]) ? (
        <LookupRoutines liftLookupRoutinesSaveClick={handleConfigSaveClick} />
      ) : null}

      {containsAll(
        [
          PATH.CREATE_CATEGORY.split("/")[2],
          PATH.CREATE_CATEGORY.split("/")[3]
        ],
        path
      ) ||
      containsAll(
        [PATH.EDIT_CATEGORY.split("/")[2], PATH.EDIT_CATEGORY.split("/")[4]],
        path
      ) ? (
        <CreateEditCategory />
      ) : null}
      {path.includes(PATH.CATEGORIES.split("/")[2]) && path.length === 3 ? (
        <Categories liftCategorySaveClick={handleConfigSaveClick} />
      ) : null}
      {path.includes(PATH.CONFIG_NEW_LOOKUP_ROUTINE.split("/")[2]) ? (
        <NewLookupRoutine />
      ) : null}
      {path.includes(PATH.CREATE_LOOKUP.split("/")[2]) ||
      containsAll(
        [
          PATH.COUNT_PROGRAM_INSTRUCTIONS.split("/")[2],
          PATH.COUNT_PROGRAM_INSTRUCTIONS.split("/")[3]
        ],
        path
      ) ||
      containsAll(
        [
          PATH.AUDITS_INSTRUCTIONS.split("/")[2],
          PATH.AUDITS_INSTRUCTIONS.split("/")[3]
        ],
        path
      ) ||
      containsAll(
        [
          PATH.COUNT_FIELDS_INSTRUCTIONS.split("/")[2],
          PATH.COUNT_FIELDS_INSTRUCTIONS.split("/")[3]
        ],
        path
      ) ? (
        <CreateLookup />
      ) : null}
      {path.includes(PATH.CONFIGURATION_COUNT_FIELDS_LOCATION.split("/")[2]) ? (
        <CountFieldsCreateEditLocation />
      ) : null}
      {path.includes(PATH.CONFIGURATION_COUNT_FIELDS_PRODUCT.split("/")[2]) ? (
        <CountFieldsCreateEditProduct />
      ) : null}
      {path.includes(PATH.OUTPUT_FILES.split("/")[2]) ? (
        <OutputFiles liftOutputFilesSaveClick={handleConfigSaveClick} />
      ) : null}
      {path.includes(PATH.CREATE_EDIT_OUTPUTFILES.split("/")[2]) ? (
        <CreateEditOutputFiles />
      ) : null}
      {path.includes(PATH.OUTPUTPROMPTS.split("/")[2]) ? (
        <OutputPrompts form={{}} isParentEdit={false} />
      ) : null}
      {path.includes(PATH.CREATE_EDIT_PROMPTS.split("/")[2]) ? (
        <OutputCreateEditPrompts />
      ) : null}
      {path.includes(PATH.CREATE_EDIT_COLUMN.split("/")[2]) ? (
        <CreateEditColumn />
      ) : null}
      {path.includes(PATH.AUDITS.split("/")[2]) && path.length === 3 ? (
        <Audits liftAuditSaveClick={handleConfigSaveClick} />
      ) : null}
      {path.includes(PATH.CREATE_AUDIT.split("/")[2]) ? <NewAuditRule /> : null}
      {path.includes(PATH.INPUT_FILES.split("/")[2]) ? (
        <InputFiles liftInputFilesSaveClick={handleConfigSaveClick} />
      ) : null}
      {path.includes(PATH.SELECT_INPUT_FILE_TYPE.split("/")[2]) ? (
        <InputFileTypeSelect />
      ) : null}
      {path.includes(PATH.CREATE_EDIT_AUXILIARY_INPUT_FILE.split("/")[2]) ? (
        <CreateEditInputFileAuxiliary />
      ) : null}
      {path.includes(PATH.CREATE_EDIT_CUSTOMER_INPUT_FILE.split("/")[2]) ? (
        <CreateEditInputFileCustomer />
      ) : null}
      {containsAll(
        [
          PATH.CREATE_COUNT_PROGRAMS.split("/")[2],
          PATH.CREATE_COUNT_PROGRAMS.split("/")[3]
        ],
        path
      ) ||
      containsAll(
        [
          PATH.EDIT_COUNT_PROGRAMS.split("/")[2],
          PATH.EDIT_COUNT_PROGRAMS.split("/")[4]
        ],
        path
      ) ? (
        <CreateEditCountProgram />
      ) : null}

      {path.includes(PATH.COUNT_PROGRAMS.split("/")[2]) && path.length === 3 ? (
        <CountPrograms liftCountProgramsSaveClick={handleConfigSaveClick} />
      ) : null}
      {path.includes(PATH.EDIT_COLUMNS.split("/")[2]) ? <EditColumns /> : null}
      {path.includes(PATH.CREATE_EDIT_OUTPUT_SUBTOTALS.split("/")[2]) ? (
        <CreateEditOutputSubTotals />
      ) : null}
      {path.includes(PATH.CREATE_EDIT_OUTPUT_GRAND_TOTALS.split("/")[2]) ? (
        <CreateEditOutputGrandTotals />
      ) : null}
      {path.includes(PATH.CREATE_INPUT_FIELDS.split("/")[2]) ? (
        <CreateInputFields />
      ) : null}
      <Grid container>
        {alertVisible ? <>{alertMsgShow(alertMsg)}</> : null}
      </Grid>
    </MainLayout>
  )
}

export default React.memo(ConfigurationPortal)
