import { Button, Grid, Tooltip } from "@mui/material"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { RootState } from "src/redux/store"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { View } from "src/utils/constants"
import BreadCrumbC from "../ToolBar/BreadCrumbC"
const VariancesToolBar = ({
  variancesSaveClick,
  variancesErrorSubmit,
  handleSubmit
}: {
  variancesSaveClick: Function
  variancesErrorSubmit: Function
  handleSubmit?: Function
}) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )

  const getBreadcrumbConfig = () => {
    let currentPath: string = "variances"
    let variables: any = {
      variances: getTranslations(t, Translates.Variances)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const variancesToolbarSaveClick = () => {
    variancesSaveClick()
  }
  return (
    <div className="main-toolbar">
      <div className="main-container">
        <Grid container>
          <BreadCrumbC
            customPath="Variances"
            getBreadcrumbConfig={getBreadcrumbConfig}
          />
          <Grid item xs={12} sm={6}>
            <div className="text-right toolbar-actions">
              <Tooltip arrow title={getTranslations(t, Translates.Save)}>
                <span>
                  <Button
                    variant="text"
                    className="icon-btn mx-2"
                    disabled={configTypeFromStore === View}
                    data-testid="saveBtn"
                    onClick={
                      handleSubmit
                        ? handleSubmit(variancesSaveClick, variancesErrorSubmit)
                        : variancesToolbarSaveClick
                    }
                  >
                    <SaveOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default VariancesToolBar
