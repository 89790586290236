import { renderRowLayoutChildren } from "../helpers"
const ReadOnlyRow = _props => {
  let { data } = _props

  return (
    <div className="base row">
      <span className="base-row">{renderRowLayoutChildren(data)}</span>
    </div>
  )
}

export default ReadOnlyRow
