import { IconButton, Tooltip } from "@mui/material"
import { Subtotal_Fields } from "src/constants/outputFiles"
import TrashIcon from "../../../../src/assets/images/TrashIcon.svg"
import EditIcon from "../../../../src/assets/images/Edit.png"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
export const displaySpan = (props: any, sqlQueryColumns) => {
  if (props?.column?.Header === "Data Group") {
    // check the column is available in the SQL Query Columns
    let result = sqlQueryColumns?.map(({ ColumnName }) => ColumnName)

    let foundVal = props?.value?.every(function (val) {
      return result.indexOf(val) >= 0
    })

    if (foundVal) {
      return (
        <span className="d-block text-left">
          {props.value ? props.value.toString() : "-"}
        </span>
      )
    } else {
      return (
        <span className="d-block text-left errro" style={{ color: "red" }}>
          {props.value ? props.value.toString() : "-"}
        </span>
      )
    }
  } else {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }
}

export const displayValues = (props: any) => {
  return (
    <>
      {props?.Values?.length > 0
        ? props?.Values?.map(p => {
            return (
              <span key={p} className="d-block text-left">
                {p}
              </span>
            )
          })
        : "-"}
    </>
  )
}

export const columnDataPrepare = (
  columnHeadersList,
  sqlQueryColumns,
  handleEditTotalClick,
  deleteTotal,
  t,
  configTypeFromStore
) => {
  return [
    columnHeadersList?.map(id => {
      return {
        Header: getTranslations(t, id.Header),
        accessor: id.accessor,
        headerClassName: id.headerClassName,
        disableFilters: id.disableFilters,
        disableSortBy: id.disableSortBy,
        setWidth: id.setWidth,
        Cell: props => {
          const cellFunc =
            id.Header === Subtotal_Fields.Values
              ? displayValues(props.row.original)
              : displaySpan(props, sqlQueryColumns)
          return cellFunc
        }
      }
    }),
    {
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      headerClassName: "temp_class",
      disableFilters: true,
      disableSortBy: true,
      setWidth: 150,
      Cell: props => {
        return (
          <span className="action-btns d-block">
            <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
              <IconButton
                data-testid="edit"
                onClick={() => handleEditTotalClick(props.row.original)}
              >
                <img src={EditIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
              <span>
                <IconButton
                  data-testid="delete"
                  onClick={() => deleteTotal(props.row.original)}
                  disabled={configTypeFromStore === "view" ? true : false}
                  className={`mx-1  ${
                    configTypeFromStore === "view" ? "disable-img" : "pointer"
                  }`}
                >
                  <img src={TrashIcon} />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        )
      }
    }
  ].flat(Infinity)
}
