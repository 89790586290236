import { useHistory, useParams } from "react-router-dom"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useAppThunkDispatch } from "src/redux/store"
import { PATH } from "src/constants/paths"
import { setOutputBundlesRedux } from "src/redux/slices/ConfigPortal/OutputBundleSlice"
import {
  FilesDetailsFields,
  createEditFieldNames
} from "../../Utils/Constants/CreateEditOutputBundleConstant"
import FormSubmitCancelButtons from "src/configurationPortal/pages/Components/FormSubmitCancelButtons"

const FormSubmitCancel = () => {
  const history = useHistory()
  const { id }: { id: string; tab: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const {
    config: { Outputs },
    outputBundleData: { outputBundlesRedux },
    reportData: { reportsRedux }
  } = ConfigDataHook()

  const dispatch = useAppThunkDispatch()
  let search = window.location.search || ""
  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.OUTPUT_FILES + search)
    })
  }

  const handleSaveClick = data => {
    //istanbul ignore next
    let locOutputBundlesRedux = JSON.parse(JSON.stringify(outputBundlesRedux))
    const allReportsNames = reportsRedux.map(i => i.Name)
    const allOutputsNames = Outputs.map(i => i.Name)
    data = {
      ...data,
      [createEditFieldNames.Reports.name]: data[
        createEditFieldNames.Reports.name
      ].filter(i => allReportsNames.includes(i)),
      [createEditFieldNames.Outputs.name]: data[
        createEditFieldNames.Outputs.name
      ].filter(i => allOutputsNames.includes(i)),
      ...(data.FileType !==
        FilesDetailsFields.fileType.props.formControl.radioGroup
          .formControlLabels[0].value && {
        [createEditFieldNames.FileName.name]: ""
      })
    }
    //istanbul ignore next
    if (storeId <= -1) {
      locOutputBundlesRedux = [...locOutputBundlesRedux, { ...data }]
    } else {
      locOutputBundlesRedux = locOutputBundlesRedux.map((i, j) => {
        return j === storeId - 1
          ? {
              ...i,
              ...data
            }
          : i
      })
    }
    dispatch(setOutputBundlesRedux(locOutputBundlesRedux))
    onCancelClick()
  }
  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClick}
      onCancelClick={onCancelClick}
    />
  )
}

export default FormSubmitCancel
