import { AlertColor } from "@mui/material"
import { functionTypes } from "./index"

const lookupEmptyEditorError = {
  title: "Empty instruction",
  severity: "error" as AlertColor,
  content: "If statement cannot be empty"
}

const lookupFormatError = {
  title: "Format mismatched",
  severity: "error" as AlertColor,
  content: "Something wrong with your instructions"
}
export const ifScreenErrorCheck = (
  layout,
  row,
  index,
  margin_left,
  isConditionObject
) => {
  let alertMsg = {}
  if (index === 0 && layout.length <= 2) {
    alertMsg = lookupEmptyEditorError
  } else if (index === 0 && row.function !== functionTypes.If) {
    alertMsg = {
      title: lookupFormatError.title,
      content: "If block should be in first row"
    }
  } else if (
    index === layout.length - 1 &&
    layout[layout.length - 1].function !== functionTypes.If
  ) {
    alertMsg = {
      title: lookupFormatError.title,
      content: "If block close brocket should be in last row"
    }
  } else if (margin_left < 0) {
    alertMsg = lookupFormatError
  } else if (
    (row.function === functionTypes.Braces &&
      row.content !== ")" &&
      margin_left === 1) ||
    (row.function !== functionTypes.If &&
      row.function !== functionTypes.Braces &&
      margin_left === 0)
  ) {
    alertMsg = {
      title: lookupFormatError.title,
      content: "If condition block should have only one main black"
    }
  } else if (row.function === functionTypes.Braces && row.content !== ")") {
    if (
      layout[index + 1].function === functionTypes.Braces &&
      layout[index + 1].content === ")"
    ) {
      alertMsg = {
        title: "Empty block",
        content: "Brace statement cannot be empty"
      }
    }
  }
  alertMsg = { ...alertMsg, ...{ severity: "error" as AlertColor } }
  return { alertMsg }
}
export const mainScreenErrorCheck = (
  layout,
  row,
  index,
  margin_left,
  productFieldName,
  component
) => {
  let alertMsg = {}
  if (index === 0 && layout.length <= 0) {
    alertMsg = lookupEmptyEditorError
  } else if (margin_left < 0) {
    alertMsg = lookupFormatError
  } else if (row?.function === functionTypes.If) {
    if (index + 1 > layout.length - 1) {
      alertMsg = lookupFormatError
    } else if (
      layout[index + 1] &&
      layout[index + 2] &&
      layout[index + 1]?.function === functionTypes.Else &&
      layout[index + 2]?.function === functionTypes.Endif
    ) {
      alertMsg = {
        title: "Empty block",
        content: "If/else statement cannot be empty"
      }
    }
  } else if (row?.function === functionTypes.Else) {
    if (index + 1 > layout.length - 1) {
      alertMsg = lookupFormatError
    } else if (
      layout[index - 1] &&
      layout[index + 1] &&
      layout[index - 1]?.function === functionTypes.If &&
      layout[index + 1]?.function === functionTypes.Endif
    ) {
      alertMsg = {
        title: "Empty block",
        content: "If/else statement cannot be empty"
      }
    }
  }

  alertMsg = { ...alertMsg, ...{ severity: "error" as AlertColor } }
  return { alertMsg }
}
