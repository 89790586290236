import { Controller, useFormContext } from "react-hook-form"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TextField } from "@mui/material"
import { getDefaultValue, getFieldError } from "./Helper"
import moment from "moment"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { getTranslations } from "src/utils/helper"
import { useTranslation } from "react-i18next"
export type Props = {
  inputAttributes: {
    name: string
    type?: "text" | "email" | "tel"
    label: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    maxDate?: Date
    minDate?: Date
    disabled?: boolean
    value?: any
    defaultValue?: any
    onChange?: Function
  }
}

const MUIDatePicker = ({ inputAttributes }: Props) => {
  const { t } = useTranslation<any>()

  const {
    name,
    type,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    fullWidth,
    required,
    maxDate,
    minDate,
    disabled,
    value,
    defaultValue,
    onChange
  } = inputAttributes

  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods

  const { isError, ErrorText } = getFieldError(name, errors)

  let defVal: any = getDefaultValue(value, defaultValue, [])

  const prepareDate = date => {
    return date ? moment(date).format("DD-MMM-YYYY") : null
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        defaultValue={prepareDate(defVal)}
        render={({ field: { ref, value, ...field } }) => (
          <DesktopDatePicker
            {...field}
            inputFormat="dd-MMM-yyyy"
            value={value}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            onChange={e => {
              field.onChange(prepareDate(e))
              updateWarnOnTabChangeToStore(true)
              if (onChange) onChange()
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={`${getTranslations(t, label)}`}
                className={`${className} custom-form-control date`}
                fullWidth={fullWidth ?? true}
                name={name}
                id={id ?? name}
                required={required ?? false}
                size={size ?? "small"}
                placeholder={placeholder}
                type={type ?? "text"}
                variant={variant ?? "filled"}
                color={color ?? "primary"}
                autoSave="false"
                autoComplete="off"
                error={isError}
                helperText={ErrorText}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default MUIDatePicker
