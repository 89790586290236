import { currencies } from "src/configurationPortal/pages/GeneralSettings"
import { DEFAULT_RADIX } from "src/utils/constants"
import { ConfigurationStoreType } from "src/utils/types/types"

export const Tab = {
  GSTab: "GSTab",
  LocationCategoriesTab: "LocationCategoriesTab",
  LocationReportingsTab: "LocationReportingsTab",
  CountFieldsTab: "CountFieldsTab",
  LookupRoutinesTab: "LookupRoutinesTab",
  VariancesTab: "VariancesTab",
  OutputFiles: "OutputFilesTab",
  AuditsTab: "AuditsTab",
  CountProgramsTab: "CountProgramsTab",
  InputFilesTab: "InputFilesTab"
}

export const ConfigurationStoreTypeKeys = {
  GeneralSettings: "GeneralSettings",
  Audits: "Audits",
  CountPrograms: "CountPrograms",
  Fields: "Fields",
  FullStoreVariances: "FullStoreVariances",
  GroupedVariances: "GroupedVariances",
  InputFiles: "InputFiles",
  LocationCategories: "LocationCategories",
  LookupRoutines: "LookupRoutines",
  OutputFile: "OutputFile",
  Reports: "Reports",
  configuration: "configuration"
}

export const ConfigSkipSections = [
  ConfigurationStoreTypeKeys.InputFiles,
  ConfigurationStoreTypeKeys.LookupRoutines,
  ConfigurationStoreTypeKeys.Audits,
  ConfigurationStoreTypeKeys.Reports,
  ConfigurationStoreTypeKeys.OutputFile,
  ConfigurationStoreTypeKeys.Fields,
  "Outputs",
  "OutputBundles",
  "FilesRequired",
]

export const ConfigurationStoreInitiate: ConfigurationStoreType = {
  GeneralSettings: {},
  Audits: [],
  CountPrograms: [],
  Fields: {},
  FullStoreVariances: { Enabled: false },
  GroupedVariances: { Enabled: false },
  InputFiles: [],
  FilesRequired: {
    DepartmentFileNotRequired: false,
    ProductFileNotRequired: false
  },
  LocationCategories: [],
  LookupRoutines: [],
  Outputs: [],
  OutputBundles: [],
  Reports: [],
  Efficiency: [],
  Accuracy: []
}

export const configPayload = (customerId, customerName, configName) => {
  let generalSettings: any = {
    CustomerID: customerId,
    CustomerName: customerName,
    ConfigurationId: 0,
    ConfigurationName: configName,
    ConfigurationVersion: "1.0",
    Currencies: currencies[0].label,
    Notes: "",
    SupportedLanguages: []
  }
  let Accuracy = {
    IncludeTagAudits: false
  }
  let InputFiles = {
    DepartmentFileNotRequired: false,
    ProductFileNotRequired: false
  }

  const locConfig: ConfigurationStoreType = {
    ...ConfigurationStoreInitiate,
    ...{ GeneralSettings: generalSettings },
    ...{ Accuracy },
    ...{ FilesRequired: InputFiles }
  }

  const payload: any = {
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
    idConfig: 0,
    idCustomer: parseInt(customerId, DEFAULT_RADIX),
    type: "",
    name: configName,
    status: 3,
    supportedLanguages: "",
    version: "1.0",
    configuration: JSON.stringify(locConfig),
    actionType: "",
    parentConfigId: 0,
    isDefault: false
  }

  return payload
}
