import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import { validationSchema } from "./Utils/Validations/CreateEditReportValidation"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  getBreadcrumbPath,
  getDefaultValues
} from "./Utils/Helpers/CreateEditReportHelper"
import { ConfigReportType } from "./Utils/Types"
import ReportBreadCrumb from "src/configurationPortal/pages/Components/CBreadCrumb"
import Prompts from "../Components/Prompts"
import { ConfigTabNames } from "../helper"
import {
  GeneralFieldDetails,
  createEditReportFieldNames
} from "./Utils/Constants/CreateEditReportConstant"
import FormSubmitCancel from "./Components/CreateEditReport/FormSubmitCancel"
import { PATH } from "src/constants/paths"
import ReadOnlySQLEditor from "../Components/SQLEditor/ReadOnlySQLEditor"
import Columns from "../Components/Columns"
import Totals from "../Components/Totals"
import Destination from "../Components/Destination"
import FormFieldConditionRender from "../Components/FormFieldConditionRender"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import FormHeading from "src/components/FormHeading"
import { getColumnsFromAPI } from "../FunctionHelper"
import Signatures from "../Components/Signatures"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { useAppThunkDispatch } from "src/redux/store"

const CreateEditReport = () => {
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""
  const {
    reportData: { reportRedux, reportsRedux },
    columnReduxData: { columnsRedux },
    config: { configDataHookCalled }
  } = ConfigDataHook()

  const locGetDefaultValues = () => {
    return getDefaultValues(reportRedux, reportsRedux, storeId)
  }

  const formHookMethods = useForm<ConfigReportType>({
    defaultValues: locGetDefaultValues(),
    resolver: yupResolver(
      validationSchema(reportsRedux, storeId, t, Translates)
    ),
    criteriaMode: "all",
    mode: "onChange"
  })

  const { getValues, setValue, reset } = formHookMethods

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let isRedirect = false
      if (configDataHookCalled) {
        if (!isRedirect && storeId > -1) {
          if (storeId > reportsRedux?.length || storeId <= 0) {
            isRedirect = true
          }
        }

        if (isRedirect) {
          history.push(PATH.REPORTS + search)
        } else {
          if (storeId > 0) {
            if (!columnsRedux?.SQL && reportsRedux) {
              getColumnsFromAPI(reportsRedux[storeId - 1])
            }
            reset(locGetDefaultValues())
            dispatch(setReportRedux(locGetDefaultValues()))
          }
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [configDataHookCalled])

  const setGridReorder = (formV, reduxV, fName) => {
    //istanbul ignore next
    if (JSON.stringify(formV) !== JSON.stringify(reduxV)) {
      setValue(fName as any, reduxV, { shouldValidate: true })
    }
  }

  useEffect(() => {
    let isMounted = true
    if (
      isMounted &&
      reportRedux &&
      Object.keys(reportRedux).length &&
      JSON.stringify(getValues()) !== JSON.stringify(reportRedux)
    ) {
      ;["Columns", "Prompts", "SubTotals", "GrandTotals", "Signatures"].forEach(
        element => {
          setGridReorder(
            getValues()[element],
            reportRedux[element],
            createEditReportFieldNames[element].name
          )
        }
      )
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [reportRedux])

  const { componentName, bConfig } = getBreadcrumbPath(storeId, t)

  return (
    <>
      <ReportBreadCrumb customPath={componentName} bConfig={bConfig} />
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container">
          <FormHeading heading={`${getTranslations(t, Translates.GENERAL)}*`} />
          <FormFieldConditionRender FieldDetails={GeneralFieldDetails} />
          <Prompts
            componentName={ConfigTabNames.Reports}
            fieldName={createEditReportFieldNames.Prompts.name}
          />
          <ReadOnlySQLEditor
            componentName={ConfigTabNames.Reports}
            fieldName={createEditReportFieldNames.RawSQL.name}
          />
          <Columns
            componentName={ConfigTabNames.Reports}
            fieldName={createEditReportFieldNames.Columns.name}
          />
          <Totals
            componentName={ConfigTabNames.Reports}
            totalType={ConfigTabNames.SubTotals}
            fieldName={createEditReportFieldNames.SubTotals.name}
          />
          <Totals
            componentName={ConfigTabNames.Reports}
            totalType={ConfigTabNames.GrandTotals}
            fieldName={createEditReportFieldNames.GrandTotals.name}
          />
          <Signatures
            componentName={ConfigTabNames.Reports}
            fieldName={createEditReportFieldNames.Signatures.name}
          />
          <Destination componentName={ConfigTabNames.Reports} />
          <FormSubmitCancel />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditReport
