const fieldTypes = {
  string: "string",
  number: "number",
  boolean: "boolean",
  radio: "radio",
  checkbox: "checkbox",
  email: "email",
  array: "array"
}

export default fieldTypes
