import { useDrag } from "react-dnd"
import { functionTypes, SIDEBAR_ITEM } from "./LRConstants"

const SideBarItem = ({ data, setDragDropzoneShow }) => {
  const [{ opacity }, drag]: any = useDrag({
    item: data,
    type: SIDEBAR_ITEM,

    collect: (monitor: any) => {
      //istanbul ignore next
      if (monitor.isDragging()) {
        setDragDropzoneShow(true)
      }

      if (monitor.canDrag()) {
        setDragDropzoneShow(false)
      }
      return {
        //istanbul ignore next
        opacity: monitor.isDragging() ? 0.4 : 1
      }
    }
  } as any)
  const content = data?.content
  return (
    <div
      className="sideBarItem"
      ref={drag}
      style={{ opacity }}
      data-testid={`${
        content === functionTypes.If ? `${content}-` : ""
      }instruction-drag-item`}
    >
      {content}
    </div>
  )
}
export default SideBarItem
