//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()

export class UploadService {
  getUploadFIles(customerId) {
    const endPoint = `/GetUploadFiles?customerId=${customerId}`
    return api.findAll(endPoint)
  }

  uploadFile(payload, config) {
    const endPoint = `/api/UploadFiles/UploadChunks`
    return api.savefile(endPoint, payload, config)
  }

  finalFileMergePost(payload) {
    const endPoint = `/api/UploadFiles/UploadFile`
    return api.post(endPoint, payload)
  }
}
//END-NOSCAN