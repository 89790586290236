
export const breadcrumbNameMap: { [key: string]: string } = {

  "/devicereservation": "Device Reservation",
  "/settings":"Settings",
  "/schedule": "Schedule"
}

export const ReportingBreadcrumbTooltip = {
  infoHeader: "INFORMATION",
  infoMessage: `Amet minim mollit non deserunt ullamco est sit aliqua
  dolor do amet sint. Velit officia consequat duis enim
  velit mollit.`
}
