import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb"
import {
  setOutputColumns,
  setCurrentOutputFile,
  setNewReportPrompts,
  setOutputFiles,
  setSQLQuery,
  setOutputSubTotals,
  setOutputGrandTotals,
  setOutputDestination
} from "../../../redux/slices/configSlice"
import { RootState, useAppThunkDispatch } from "../../../redux/store"
import { useHistory, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import React, { useState, useEffect, useReducer } from "react"
import { PATH } from "../../../constants/paths"
import { getTranslations, setWeekFieldError } from "src/utils/helper"
import {
  CONFIG_MODULES,
  EDIT_TYPE,
  FILE_TYPE,
  ACEEDITOR_BACKGROUND_COLORS,
  FILE_TYPE_VALUES,
  FIELD_DELIMITER,
  RECORD_DELIMITER
} from "src/utils/constants"

import OutputPrompts from "./OutputPrompts"
import AceEditorC from "src/components/AceEditorC/AceEditorC"
import OutputColumns from "./OutputColumns"
import { Loader } from "src/utils/reusables/reusable"
import {
  OUTPUT_FILE_COMPONENT_TYPE,
  OUTPUT_FILE_TITLES
} from "src/constants/outputFiles"
import OutputFileTotals from "../Components/OutputFileTotals"
import { API_RESPONSE } from "../../../utils/constants"
import { SqlTestEditorService } from "../../../service/sqlTestEditor.service"
import { setSQLQueryColumns } from "src/redux/slices/configSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import {
  checkRequiredFieldErrorCheck,
  makeClearQueryToExecute,
  reducer,
  updateWarnOnTabChangeToStore
} from "../helper"
import ReportFileDestination from "../Components/ReportFileDestination"
import { alertMsgShow, emptyAlert } from "../FunctionHelper"

interface INewOutputFile {
  Name: string
  Description: string
  FileName: string
  ColumnsToSuppress: string
  FileType: string
  FieldDelimiter: string
  RecordDelimiter: string
  HasHeaderRecord: boolean
  Prompts?: any
  SQL?: any
  SubTotals: any
  GrandTotals: any
  OtherFieldDelimiter: string
  OtherRecordDelimiter: string
  AsciiCharValue: any
  Destination: any
}

let alertVisible: boolean = false
let alertMsg = emptyAlert
const CreateEditOutputFiles = () => {
  const { t } = useTranslation<any>()
  const location = useLocation() as any
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const [sqlQuery, setSqlQuery] = useState("")
  const [, setReload] = useReducer(reducer, false)
  const queryfromCreateEditForm = location.state?.showLatestQueryValue
  let search = window.location.search || ""
  const locationState = { ...location.state }
  const [customerPortalCheck, setCustomerPortalCheck] = useState(true)
  const [selectedOption, setSelectedOption] = useState("None" as any)
  const [chips, setChips] = useState([] as any)
  let isEdit = locationState.isEdit
  const [form, setForm] = React.useState<INewOutputFile>({
    Name: "",
    Description: "",
    FileName: "",
    ColumnsToSuppress: "",
    FileType: "",
    RecordDelimiter: "{CR}{LF}",
    FieldDelimiter: "",
    HasHeaderRecord: false,
    SubTotals: [],
    GrandTotals: [],
    OtherFieldDelimiter: "",
    OtherRecordDelimiter: "",
    AsciiCharValue: "",
    Destination: {}
  } as INewOutputFile)
  const [errorMsg, setErrorMsg] = useState(false)
  const [weekIVFields, setWeekIVFields] = useState({
    Name: "",
    FileName: ""
  })
  let sortID = location.pathname?.split("/")[3]
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const configData = useSelector((state: RootState) => state.config)
  const newOutputFile = useSelector(
    (state: RootState) => state.config?.currentOutputFile
  )

  const outputColumn = useSelector(
    (state: RootState) => state.config?.outputColumns
  )
  const sqlQueryColumns = useSelector(
    (state: RootState) => state.config?.newSQLQueryColumns
  )
  const [isValid, setIsValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const testSQLService = new SqlTestEditorService()
  useEffect(() => {
    const destination = newOutputFile?.Destination
    const locationStateDestination = locationState.data?.Destination
    if (destination && Object.keys(destination).length) {
      setCustomerPortalCheck(destination.CustomerPortalCheck)
      setSelectedOption(destination.FtpDirectory)
      setChips(destination.Email)
    } else if (locationState.data) {
      setCustomerPortalCheck(locationStateDestination?.CustomerPortalCheck)
      setSelectedOption(locationStateDestination?.FtpDirectory)
      setChips(locationStateDestination?.Email)
    }
    //eslint-disable-next-line
  }, [newOutputFile])
  useEffect(() => {
    setForm({
      ...locationState.data,
      FieldDelimiter: setFieldDelimiterValue(),
      RecordDelimiter: setRecordDelimiterValue(),
      HasHeaderRecord: locationState.data?.HasHeaderRecord,
      OtherFieldDelimiter: locationState.data?.FieldDelimiter,
      OtherRecordDelimiter: locationState.data?.RecordDelimiter
    })

    //eslint-disable-next-line
  }, [locationState.data])

  const formAndReduxVariableSet = () => {
    setForm({
      ...locationState.data,
      FieldDelimiter: setFieldDelimiterValue(),
      RecordDelimiter: setRecordDelimiterValue(),
      HasHeaderRecord: locationState.data?.HasHeaderRecord,
      OtherFieldDelimiter: locationState.data?.FieldDelimiter,
      OtherRecordDelimiter: locationState.data?.RecordDelimiter
    })
    dispatch(setNewReportPrompts(locationState.data?.Prompts || []))
    dispatch(setOutputColumns(locationState.data?.Columns || []))
    dispatch(setOutputSubTotals(locationState.data?.SubTotals || []))
    dispatch(setOutputGrandTotals(locationState.data?.GrandTotals || []))
    dispatch(setOutputDestination(locationState.data?.Destination || {}))

    let locSQL = ""

    if (locationState.data) {
      if (locationState.data.RawSQL) {
        locSQL = locationState.data.RawSQL
      } else if (locationState.data.SQL) {
        locSQL = locationState.data.SQL
      }
    }
    dispatch(setSQLQuery(locSQL))
  }
  useEffect(() => {
    const locationState = { ...location.state }
    setLoading(true)

    if (locationState.data && configData?.newSQLQuery) {
      updateWarnOnTabChangeToStore(true)
    }

    if (locationState.isEdit === true) {
      formAndReduxVariableSet()
    }

    if (newOutputFile && Object.keys(newOutputFile).length > 0) {
      dispatch(setOutputSubTotals(newOutputFile.SubTotals || []))
      dispatch(setOutputGrandTotals(newOutputFile.GrandTotals || []))
      dispatch(setOutputDestination(newOutputFile.Destination || {}))
    }

    getSQLQueryColumns()
    setLoading(false)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSqlQuery(configData?.newSQLQuery)
  }, [configData?.newSQLQuery])

  const getSQLQueryColumns = () => {
    if (locationState.data?.SQL && !sqlQueryColumns?.length) {
      const Prompts = configData?.newReportPrompts?.length
        ? configData.newReportPrompts
        : locationState.data.Prompts || []

      const queryToBeTested = makeClearQueryToExecute(
        locationState.data.SQL,
        Prompts
      )
      testSQLService
        .postSqlTestEditorValueForVerify(queryToBeTested)
        .then(res => {
          if (Number(res.status) === Number(API_RESPONSE.SUCCESS)) {
            dispatch(setSQLQueryColumns(res.data))
          }
        })
        .catch(err => err)
    }
  }

  const getLookupBreadcrumb = () => {
    if (locationState.isEdit) {
      return OUTPUT_FILE_TITLES.EditOutputFile
    } else {
      return OUTPUT_FILE_TITLES.NewOutputFile
    }
  }

  const getBreadcrumbConfig = () => {
    let currentPath: string = `outputFiles`
    let variables: any = {
      outputFiles: getTranslations(t, getLookupBreadcrumb())
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }

  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value || ""

    if (e.target.name === "Name") {
      const _OutputName = configData.Outputs.map(m => m.Name)
      if (_OutputName.includes(val)) {
        setErrorMsg(true)
        setForm({ ...form, [e.target.name]: val })
      } else {
        setErrorMsg(false)
        setForm({ ...form, [e.target.name]: val })
      }
      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    } else if (e.target.name === "FileName") {
      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
      setForm({ ...form, [e.target.name]: val })
    } else if (
      e.target.name === "FileType" &&
      e.target.value !== FILE_TYPE.Text
    ) {
      setForm({
        ...form,
        [e.target.name]: val,
        FieldDelimiter: "",
        RecordDelimiter: "{CR}{LF}",
        OtherFieldDelimiter: "",
        OtherRecordDelimiter: "",
        AsciiCharValue: ""
      })
    } else if (e.target.name === "hasHeader") {
      setForm({ ...form, HasHeaderRecord: e.target.checked })
    } else if (e.target.name) {
      setForm({ ...form, [e.target.name]: val })
    }
  }

  const getFieldDelimiterValue = () => {
    if (form.FieldDelimiter === FIELD_DELIMITER[0]) {
      return FIELD_DELIMITER[0]
    } else if (form.FieldDelimiter === FIELD_DELIMITER[1]) {
      return "\t"
    } else if (form.FieldDelimiter === FIELD_DELIMITER[2]) {
      return ","
    } else if (form.FieldDelimiter === FIELD_DELIMITER[3]) {
      return "|"
    } else if (form.FieldDelimiter === FIELD_DELIMITER[4]) {
      return ":"
    } else if (form.FieldDelimiter === FIELD_DELIMITER[5]) {
      return ";"
    } else if (form.FieldDelimiter === "OTHER") {
      return form.OtherFieldDelimiter
    }
  }
  const setFieldDelimiterValue = () => {
    if (locationState.data?.FieldDelimiter === FIELD_DELIMITER[0]) {
      return FIELD_DELIMITER[0]
    } else if (locationState.data?.FieldDelimiter === "\t") {
      return "TAB"
    } else if (locationState.data?.FieldDelimiter === ",") {
      return "COMMA"
    } else if (locationState.data?.FieldDelimiter === "|") {
      return "PIPE"
    } else if (locationState.data?.FieldDelimiter === ":") {
      return "COLON"
    } else if (locationState.data?.FieldDelimiter === ";") {
      return "SEMI COLON"
    } else if (locationState.data?.FieldDelimiter) {
      return "OTHER"
    }
  }

  const setRecordDelimiterValue = () => {
    if (locationState.data?.RecordDelimiter === RECORD_DELIMITER[3]) {
      return RECORD_DELIMITER[3]
    } else if (locationState.data?.RecordDelimiter === "\r\n") {
      return "{CR}{LF}"
    } else if (locationState.data?.RecordDelimiter === "\n") {
      return "{LF}"
    } else if (locationState.data?.RecordDelimiter === "\r") {
      return "{CR}"
    } else if (
      locationState.data?.RecordDelimiter !== FILE_TYPE.ASCIICharValue
    ) {
      return "OTHER"
    }
  }
  const getRecordDelimiterValue = () => {
    if (form.RecordDelimiter === RECORD_DELIMITER[3]) {
      return RECORD_DELIMITER[3]
    } else if (form.RecordDelimiter === RECORD_DELIMITER[0]) {
      return "\r\n"
    } else if (form.RecordDelimiter === RECORD_DELIMITER[1]) {
      return "\n"
    } else if (form.RecordDelimiter === RECORD_DELIMITER[2]) {
      return "\r"
    } else if (form.RecordDelimiter === "OTHER") {
      return form.OtherRecordDelimiter
    } else {
      return form.RecordDelimiter
    }
  }

  const hideAlert = () => {
    setTimeout(() => {
      alertVisible = false
      alertMsg = emptyAlert
      setReload(oldVal => !oldVal)
    }, 3000)
  }

  const payloadPreparation = () => {
    const suppressedColumns = outputColumn.filter(column => {
      return column.Suppress
    })

    const _sortOrder =
      configData?.Outputs?.length > 0
        ? configData?.Outputs?.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0

    return {
      Name: form.Name,
      Description: form.Description,
      FileName: form.FileName,
      FileType: form.FileType,
      Prompts:
        configData?.newReportPrompts?.length > 0
          ? configData.newReportPrompts
          : [],

      SQL: makeClearQueryToExecute(
        configData?.newSQLQuery?.length > 0
          ? configData?.newSQLQuery
          : sqlQuery,
        [],
        false
      ),
      RawSQL:
        configData?.newSQLQuery?.length > 0
          ? configData?.newSQLQuery
          : sqlQuery,
      Columns:
        configData?.outputColumns?.length > 0 ? configData?.outputColumns : [],
      SortOrder: location.state?.isEdit
        ? location.state?.data?.SortOrder
        : _sortOrder + 1,
      SubTotals:
        configData?.outputSubTotals?.length > 0
          ? configData?.outputSubTotals
          : [],
      GrandTotals:
        configData?.outputGrandTotals?.length > 0
          ? configData?.outputGrandTotals
          : [],
      Destination: {
        CustomerPortalCheck: customerPortalCheck,
        FtpDirectory: selectedOption,
        Email: chips
      },
      ColumnsToSuppress: suppressedColumns?.length
        ? Math.max(...suppressedColumns.map(o => o.SortOrder))
        : 0,
      HasHeaderRecord: form.HasHeaderRecord,
      FieldDelimiter: getFieldDelimiterValue(),
      RecordDelimiter: getRecordDelimiterValue(),
      AsciiCharValue: form.AsciiCharValue
    }
  }
  const onSaveClick = () => {
    let sortID
    // get the columns suppressed from the columns object

    if (location.state?.isEdit !== true) {
      sortID = location.pathname?.split("/")[3]
      const _OutputName = configData?.Outputs.map(m => m.Name)
      const filteredReportsArray = configData?.Outputs.filter(function (itm) {
        return itm.SortOrder === sortID
      })
      if (_OutputName.includes(form.Name)) {
        setErrorMsg(true)
        queryfromCreateEditForm === true
          ? setSqlQuery(configData?.newSQLQuery)
          : setSqlQuery(
              filteredReportsArray[0]?.RawSQL || filteredReportsArray[0]?.SQL
            )

        return null
      }
    } else {
      setSqlQuery(configData?.newSQLQuery)
    }
    setErrorMsg(false)
    const _item = payloadPreparation()
    const { alertMsgFieldError } = checkRequiredFieldErrorCheck(_item)
    if (Object.keys(alertMsgFieldError).length) {
      alertVisible = true
      alertMsg = { ...alertMsg, ...alertMsgFieldError }
      setReload(oldVal => !oldVal)
      hideAlert()
      return false
    }

    let _dt = [] as any
    if (location.state?.isEdit) {
      _dt = configData?.Outputs.map(m =>
        m.SortOrder === _item.SortOrder ? { ...m, ..._item } : { ...m }
      )
    } else {
      _dt = [...(configData?.Outputs || []), _item]
    }
    dispatch(setOutputFiles(_dt))
    onCancelClick()
  }

  const opneNewSQLEditor = () => {
    const currentPath = location.pathname.includes("edit") ? "edit" : "new"
    updateWarnOnTabChangeToStore(false)
    let sortID
    if (currentPath === EDIT_TYPE.Edit) {
      sortID = location.pathname?.split("/")[3] /*parameters
          query: query to be binded to the editor if there is already saved query
          promptsObj: Vairables Object
          SortID: The Sort ID of the object
          IsEdit: Flag to route the screen to new/edit screen after creating the query
          Component:Component name from which the SQL Editor is being called
          */
      setTimeout(() => {
        history.push(PATH.CREATE_EDIT_SQL + search, {
          query: sqlQuery || "",
          promptsObj: configData?.newReportPrompts,
          sortID: sortID,
          isEdit: true,
          componentName: CONFIG_MODULES.OutputFiles,
          isParentEdit: location.state?.isEdit,
          data: form
        })
      })
    } else {
      sortID = 0 /*parameters
          query: query to be binded to the editor if there is already saved query
          promptsObj: Vairables Object
          SortID: The Sort ID of the object
          IsEdit: Flag to route the screen to new/edit screen after creating the query
          Component:Component name from which the SQL Editor is being called
        */
      setTimeout(() => {
        history.push(PATH.CREATE_EDIT_SQL + search, {
          query: sqlQuery || "",
          promptsObj: configData?.newReportPrompts,
          sortID: sortID,
          isEdit: false,
          componentName: CONFIG_MODULES.OutputFiles,
          isParentEdit: location.state?.isEdit,
          data: form
        })
      })
    }
    const destination = {
      CustomerPortalCheck: customerPortalCheck,
      FtpDirectory: selectedOption,
      Email: chips
    }

    dispatch(setCurrentOutputFile({ form, Destination: destination }))
  }

  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.OUTPUT_FILES + search)
    })
  }

  const checkValidation = () => {
    let reqFields = {}
    if (form.FileType?.toLowerCase() === FILE_TYPE.Text.toLowerCase()) {
      if (form.FieldDelimiter === FILE_TYPE.Other) {
        if (
          form.RecordDelimiter !== FILE_TYPE.Other &&
          form.RecordDelimiter !== FILE_TYPE.ASCIICharValue
        ) {
          const {
            Name,
            Description,
            FileName,
            FileType,

            RecordDelimiter,
            OtherFieldDelimiter
          } = form
          Object.assign(reqFields, {
            Name,
            Description,
            FileName,
            FileType,

            RecordDelimiter,
            OtherFieldDelimiter
          })

          return checkRestForm(reqFields)
        } else if (form.RecordDelimiter === FILE_TYPE.Other) {
          const {
            Name,
            Description,
            FileName,
            FileType,
            RecordDelimiter,
            OtherFieldDelimiter,
            OtherRecordDelimiter
          } = form
          Object.assign(reqFields, {
            Name,
            Description,
            FileName,
            FileType,

            RecordDelimiter,
            OtherFieldDelimiter,
            OtherRecordDelimiter
          })

          return checkRestForm(reqFields)
        } else if (form.RecordDelimiter === FILE_TYPE.ASCIICharValue) {
          const {
            Name,
            Description,
            FileName,
            FileType,

            RecordDelimiter,
            OtherFieldDelimiter,
            AsciiCharValue
          } = form
          Object.assign(reqFields, {
            Name,
            Description,
            FileName,
            FileType,
            RecordDelimiter,
            OtherFieldDelimiter,
            AsciiCharValue
          })

          return checkRestForm(reqFields)
        }
      } else if (form.RecordDelimiter === FILE_TYPE.Other) {
        const {
          Name,
          Description,
          FileName,
          FileType,

          RecordDelimiter,
          OtherRecordDelimiter
        } = form
        Object.assign(reqFields, {
          Name,
          Description,
          FileName,
          FileType,

          RecordDelimiter,
          OtherRecordDelimiter
        })
        return checkRestForm(reqFields)
      } else if (form.RecordDelimiter === FILE_TYPE.ASCIICharValue) {
        const {
          Name,
          Description,
          FileName,
          FileType,

          RecordDelimiter,
          AsciiCharValue
        } = form

        Object.assign(reqFields, {
          Name,
          Description,
          FileName,
          FileType,

          RecordDelimiter,
          AsciiCharValue
        })
        return checkRestForm(reqFields)
      } else {
        const {
          Name,
          Description,
          FileName,
          FileType,

          RecordDelimiter
        } = form

        Object.assign(reqFields, {
          Name,
          Description,
          FileName,
          FileType,
          RecordDelimiter
        })
        return checkRestForm(reqFields)
      }
    } else {
      const { Name, Description, FileName, FileType } = form

      Object.assign(reqFields, { Name, Description, FileName, FileType })
      return checkRestForm(reqFields)
    }
  }

  const checkRestForm = rest =>
    Object.values(rest).some(s => s === "" || s === null || errorMsg === true)

  const getNameError = () => {
    if (errorMsg) {
      return `Output Name already exists`
    } else if (weekIVFields.Name) {
      return weekIVFields.Name
    }
    return ""
  }

  return (
    <>
      <div>
        <div className="main-toolbar">
          <div className="main-container">
            <Grid container>
              <Grid item sm={12}>
                <div className="custom-breadcrumb CP">
                  <BreadCrumb
                    customPath={"New Output"}
                    breadCrumbInfo={getBreadcrumbConfig()}
                    renderedPage={""}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {loading ? (
          Loader()
        ) : (
          <Grid container className="main-container">
            <Grid item xs={12} sm={12} className="mb-8">
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <label className="form-control__label mb-3">
                    {getTranslations(t, Translates.GENERAL)}
                  </label>
                  <div className="text-box">
                    {" "}
                    <TextField
                      fullWidth
                      label={`${getTranslations(t, Translates.Name)}*`}
                      variant="filled"
                      autoSave="false"
                      autoComplete="off"
                      className="custom-form-control"
                      onChange={e => onFormChange(e)}
                      name="Name"
                      value={form.Name || ""}
                      error={!!errorMsg || !!weekIVFields.Name}
                      helperText={getNameError()}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <TextField
                    multiline
                    fullWidth
                    label={`${getTranslations(t, Translates.Description)}*`}
                    rows={3}
                    variant="filled"
                    autoSave="false"
                    autoComplete="off"
                    className="custom-form-control"
                    onChange={e => onFormChange(e)}
                    name="Description"
                    value={form.Description || ""}
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <div className="text-box">
                        <TextField
                          fullWidth
                          label={`${getTranslations(t, Translates.File_Name)}*`}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          onChange={e => onFormChange(e)}
                          name="FileName"
                          value={form.FileName || ""}
                          error={!!weekIVFields.FileName}
                          helperText={weekIVFields.FileName}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <FormControl
                        variant="filled"
                        className="custom-form-control select"
                      >
                        <InputLabel
                          id="demo-multiple-checkbox-label"
                          variant="filled"
                        >
                          {getTranslations(t, Translates.Select_File_Type)}*
                        </InputLabel>

                        <Select
                          id="demo-multiple-checkbox"
                          data-testid="FileTypeSelectField"
                          value={form.FileType}
                          name="FileType"
                          onChange={e => onFormChange(e)}
                        >
                          {FILE_TYPE_VALUES.map((name: any) => (
                            <MenuItem
                              key={name}
                              value={name}
                              className="dropdown-list"
                            >
                              <small>{getTranslations(t, name)}</small>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {form.FileType?.toLowerCase() ===
                  FILE_TYPE.Text.toLowerCase() ? (
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={3}>
                            <FormControl
                              variant="filled"
                              className="custom-form-control select"
                            >
                              <InputLabel
                                id="demo-multiple-checkbox-label"
                                variant="filled"
                              >
                                {getTranslations(t, Translates.Field_Delimiter)}
                              </InputLabel>
                              <Select
                                id="demo-multiple-checkbox"
                                data-testid="FieldDelimiterSelect"
                                value={form.FieldDelimiter}
                                name="FieldDelimiter"
                                onChange={e => onFormChange(e)}
                              >
                                {FIELD_DELIMITER.map((name: any) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    className="dropdown-list"
                                  >
                                    <small>{name}</small>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            {form.FieldDelimiter === FILE_TYPE.Other ? (
                              <TextField
                                fullWidth
                                label={`${getTranslations(
                                  t,
                                  Translates.OTHER
                                )}*`}
                                variant="filled"
                                autoSave="false"
                                autoComplete="off"
                                className="custom-form-control ml-2"
                                onChange={e => onFormChange(e)}
                                name={"OtherFieldDelimiter"}
                                value={form.OtherFieldDelimiter}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          variant="filled"
                          className="custom-form-control select"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {getTranslations(t, Translates.Record_Delimiter)}*
                          </InputLabel>
                          <Select
                            data-testid="RecordDelimiterSelect"
                            id="demo-multiple-checkbox"
                            value={form.RecordDelimiter}
                            name="RecordDelimiter"
                            onChange={e => onFormChange(e)}
                          >
                            {RECORD_DELIMITER.map((item: any) => (
                              <MenuItem
                                key={item}
                                value={item}
                                className="dropdown-list"
                              >
                                <small>{item}</small>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {form.RecordDelimiter === FILE_TYPE.Other ? (
                          <TextField
                            fullWidth
                            label={form.RecordDelimiter + "*"}
                            variant="filled"
                            autoSave="false"
                            autoComplete="off"
                            className="custom-form-control ml-2"
                            onChange={e => onFormChange(e)}
                            name={"OtherRecordDelimiter"}
                            value={form.OtherRecordDelimiter}
                          />
                        ) : (
                          ""
                        )}
                        {form.RecordDelimiter === FILE_TYPE.ASCIICharValue ? (
                          <TextField
                            fullWidth
                            label={form.RecordDelimiter + "*"}
                            variant="filled"
                            autoSave="false"
                            autoComplete="off"
                            className="custom-form-control ml-2"
                            onChange={e => onFormChange(e)}
                            name={"AsciiCharValue"}
                            value={form.AsciiCharValue}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="HasHeaderRecordCheckField"
                        name="hasHeader"
                        checked={form.HasHeaderRecord}
                        defaultChecked={false}
                        className="custom-check light"
                        onChange={e => onFormChange(e)}
                        checkedIcon={<CheckIcon />}
                      />
                    }
                    label={getTranslations(t, Translates.Has_Header_Record)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <OutputPrompts
              form={{
                ...form,
                customerPortalCheck: customerPortalCheck,
                selectedOption: selectedOption,
                chips: chips
              }}
              isParentEdit={isEdit}
            />
            <Grid item xs={12} sm={12} className="mt-2 sqlEditor-readOnly">
              <Divider className="dashed my-4" />
              <h4 className="mb-2">{getTranslations(t, Translates.SQL)}</h4>
              <div className="gray-bg pa-4">
                <AceEditorC
                  editormode="mysql"
                  editorvalue={sqlQuery}
                  editorreadOnly={true}
                  focus={false}
                  editorBackgroundColor={
                    ACEEDITOR_BACKGROUND_COLORS.DisabledMode
                  }
                  onFocusChangeHandler={opneNewSQLEditor}
                  onChangeHandler={() => {}}
                ></AceEditorC>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <OutputColumns
                isParentEdit={isEdit}
                form={{
                  ...form,
                  customerPortalCheck: customerPortalCheck,
                  selectedOption: selectedOption,
                  chips: chips
                }}
                sortID={sortID}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <OutputFileTotals
                isParentEdit={isEdit}
                form={{
                  ...form,
                  customerPortalCheck: customerPortalCheck,
                  selectedOption: selectedOption,
                  chips: chips
                }}
                component={OUTPUT_FILE_COMPONENT_TYPE.SUB_TOTAL}
                setForm={setForm}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <OutputFileTotals
                isParentEdit={isEdit}
                form={{
                  ...form,
                  customerPortalCheck: customerPortalCheck,
                  selectedOption: selectedOption,
                  chips: chips
                }}
                component={OUTPUT_FILE_COMPONENT_TYPE.GRAND_TOTAL}
                setForm={setForm}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ReportFileDestination
                form={{
                  ...form,
                  customerPortalCheck: customerPortalCheck,
                  selectedOption: selectedOption,
                  chips: chips
                }}
                setSelectedOption={setSelectedOption}
                setChips={setChips}
                setCustomerPortalCheck={setCustomerPortalCheck}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="inner-view__footer">
                <Button
                  variant="text"
                  className="primary-btn cancel-btn mr-5"
                  onClick={() => onCancelClick()}
                >
                  {getTranslations(t, Translates.Cancel)}
                </Button>
                <Button
                  variant="contained"
                  className="primary-btn"
                  disabled={
                    configTypeFromStore === "view" ||
                    checkValidation() === true ||
                    !isValid ||
                    Object.values(weekIVFields).some(s => s || s !== "")
                  }
                  onClick={() => onSaveClick()}
                >
                  {getTranslations(t, Translates.Save)}
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
      <Grid container>
        {alertVisible ? <>{alertMsgShow(alertMsg)}</> : null}
      </Grid>
    </>
  )
}

export default CreateEditOutputFiles
