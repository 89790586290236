import { validateInteger } from "src/utils/helper"
import { FieldTypes } from "src/constants/audits"
import { DEFAULT_RADIX } from "src/utils/constants"

export const auditRuleTypes = {
  DETAIL: "DETAIL",
  RESCAN: "RESCAN"
}

export const aRFieldNames = {
  reason: "reason",
  auditType: "auditType",
  followupPercentageThreshold: "followupPercentageThreshold",
  followupRequiredUser: "followupRequiredUser",
  followupOriginalReasons: "followupOriginalReasons",
  followupStartTag: "followupStartTag",
  followupAccuracy: "followupAccuracy",
  followupNumberOfFollowups: "followupNumberOfFollowups",
  randomDetailPercentageThreshold: "randomDetailPercentageThreshold",
  randomDetailRequiredUser: "randomDetailRequiredUser",
  randomDetail1stAt: "randomDetail1stAt",
  randomDetail1stNext: "randomDetail1stNext",
  randomDetailStopAt: "randomDetailStopAt",
  targettedLocationPercentageThreshold: "targettedLocationPercentageThreshold",
  targettedLocationRequiredUser: "targettedLocationRequiredUser",
  targetedRecordPercentageThreshold: "targetedRecordPercentageThreshold",
  targetedRecordRequiredUser: "targetedRecordRequiredUser",
  reqAuditsPerCounter: "reqAuditsPerCounter",
  randomDetailsRepeat: "randomDetailsRepeat",
  followupHighQty: "followupHighQty",
  followupRandomSalesFloor: "followupRandomSalesFloor",
  checkDetailAudits: "checkDetailAudits",
  IsPerPerson: "IsPerPerson",
  percentThreshold: "percentThreshold",
  controlledradiobuttonsgroup: "controlledradiobuttonsgroup",
  reqAuditsPercent: "reqAuditsPercent"
}

export const setAuditFormData = (
  e,
  setForm,
  form,
  setErrorMsg,
  setFollowupAccuracyPercentageErrorMsg,
  setOverallPercentageErrorMsg,
  setFollowupStopAtPercentageErrorMsg
) => {
  let val = e.target.value

  if (e.target?.name) {
    setForm({ ...form, [e.target.name]: val })
  }
  if (e.target?.name == aRFieldNames.reason) {
    setForm({ ...form, reason: e.target.value })
    setErrorMsg(false)
  }
  if (e.target?.name == aRFieldNames.targettedLocationPercentageThreshold) {
    setValidPercentageValues(
      setForm,
      form,
      aRFieldNames.targettedLocationPercentageThreshold,
      val
    )
  }
  // targeted record
  if (e.target?.name == aRFieldNames.targetedRecordPercentageThreshold) {
    setValidPercentageValues(
      setForm,
      form,
      "targetedRecordPercentageThreshold",
      val
    )
  }
  // random audit
  if (e.target?.name == aRFieldNames.randomDetailPercentageThreshold) {
    setValidPercentageValues(
      setForm,
      form,
      "randomDetailPercentageThreshold",
      val
    )
  }
  if (e.target?.name == aRFieldNames.randomDetail1stAt) {
    if (!validateInteger(val)) {
      setValidateDecimalIntegerFormData(setForm, form, e, FieldTypes.INTEGER)
    }
  }
  if (e.target?.name == aRFieldNames.randomDetail1stNext) {
    if (!validateInteger(val)) {
      setValidateDecimalIntegerFormData(setForm, form, e, FieldTypes.INTEGER)
    }
  }
  if (e.target?.name == aRFieldNames.randomDetailStopAt) {
    setValidPercentageValues(setForm, form, "randomDetailStopAt", val)
  }
  if (e.target?.name == aRFieldNames.randomDetailsRepeat) {
    setForm({ ...form, randomDetailsRepeat: e.target.checked })
  }
  //followup
  if (e.target?.name == aRFieldNames.followupPercentageThreshold) {
    setValidPercentageValues(setForm, form, "followupPercentageThreshold", val)
  }

  if (e.target?.name == aRFieldNames.followupHighQty) {
    setForm({ ...form, followupHighQty: e.target.checked })
  }
  if (e.target?.name == aRFieldNames.followupRandomSalesFloor) {
    setForm({ ...form, followupRandomSalesFloor: e.target.checked })
  }
  if (e.target?.name == aRFieldNames.followupStartTag) {
    if (!validateInteger(val)) {
      setValidateDecimalIntegerFormData(setForm, form, e, FieldTypes.INTEGER)
    }
  }
  if (e.target?.name == aRFieldNames.followupAccuracy) {
    setValidPercentageValues(setForm, form, aRFieldNames.followupAccuracy, val)
  }
  if (e.target?.name == aRFieldNames.followupNumberOfFollowups) {
    setValidPercentageValues(
      setForm,
      form,
      aRFieldNames.followupNumberOfFollowups,
      val
    )
  }
  // main audit data
  if (e.target?.name === aRFieldNames.checkDetailAudits) {
    setForm({ ...form, checkDetailAudits: e.target.checked })
  }
  if (e.target?.name === "checkRescanAudits") {
    setForm({ ...form, checkRescanAudits: e.target.checked })
  }
  if (e.target?.name === aRFieldNames.IsPerPerson) {
    setForm({ ...form, IsPerPerson: e.target.checked })
  }

  if (e.target?.name == aRFieldNames.controlledradiobuttonsgroup) {
    setForm({ ...form, controlledradiobuttonsgroup: val })
  }

  if (e.target?.name == aRFieldNames.percentThreshold) {
    setValidPercentageValues(setForm, form, aRFieldNames.percentThreshold, val)
  }
  if (e.target?.name == aRFieldNames.reqAuditsPercent) {
    setValidPercentageValues(setForm, form, aRFieldNames.reqAuditsPercent, val)
  }
  if (e.target?.name == aRFieldNames.reqAuditsPerCounter) {
    setForm({ ...form, reqAuditsPerCounter: val })
  }
}

export const onAutoCompleteFormChange = (setForm, form, targetID, values) => {
  setForm({ ...form, [targetID]: values })
}

const setValidPercentageValues = (setForm, form, name, val) => {
  if (val <= 100) setForm({ ...form, [name]: val })
  else {
    setForm({ ...form, [name]: "" })
  }
}

const getBreadcrumbConfig = (
  landingName: string,
  landingPath: string,
  componentName: string
) => {
  let landingPage = "landingPage"
  let custLinks: any = {}
  custLinks[`@${landingPage}`] = landingPath
  let currentPath: string = `##configuration##/@${landingPage}`
  let variables: any = {}
  variables[`@${landingPage}`] = landingName
  return {
    componentName,
    bConfig: {
      custLinks,
      showHomeIcon: false,
      currentPath,
      mapObj: variables
    }
  }
}

export const getBreadcrumbPath = (componentname, title, path) => {
  let componentName = componentname
  const { bConfig } = getBreadcrumbConfig(title, path, componentName)
  return { bConfig }
}

const setValidateDecimalIntegerFormData = (setForm, form, e, type) => {
  if (type == FieldTypes.INTEGER) {
    setForm({
      ...form,
      [e.target.name]: e.target.value.substring(0, e.target.value.indexOf("."))
    })
  } else {
    setForm({
      ...form,
      [e.target.name]: e.target.value.substring(0, e.target.value.length - 1)
    })
  }
}

export const checkPercentageError = (
  val,
  setPercentageErrorMsg,
  setOverallPercentageErrorMsg
) => {
  if (val > 100) {
    setPercentageErrorMsg(true)
    if (setOverallPercentageErrorMsg) setOverallPercentageErrorMsg(true)
  } else {
    setPercentageErrorMsg(false)
    if (setOverallPercentageErrorMsg) setOverallPercentageErrorMsg(false)
  }
}

export const generateRuleName = (ruleObj, row) => {
  let filteredRuleObj = ruleObj.filter(rule => {
    return rule.Reason.includes(row.Reason)
  })
  let ruleName =
    `copy of ${row.Reason}` + "(" + `${filteredRuleObj.length}` + ")"

  // check if the copied rule name is existing
  let checkDupliateRuleName = ruleObj.filter(ruleObj => {
    return ruleObj.Reason == ruleName
  })

  if (checkDupliateRuleName.length > 0) {
    ruleName =
      `copy of ${row.Reason}` + "(" + `${filteredRuleObj.length + 1}` + ")"
  }

  return ruleName
}

export const getPercentageErrorMessage = percentageErrorMsg => {
  if (percentageErrorMsg) {
    return `Percentage fields cannot be more than 100`
  }
}

export const generatePercentageValues = () => {
  let percentagesArray: any[] = []
  for (let i = 0; i <= 100; i++) {
    percentagesArray.push(i + "%")
  }
  return percentagesArray
}

export const formatInteger = value => {
  return value || value === 0
    ? parseFloat(
        parseFloat((parseInt(value, DEFAULT_RADIX) / 100).toString()).toFixed(2)
      )
    : 0
}

export const percentageValueConversion = value => {
  return value || value === 0
    ? (parseFloat(value) * 100).toFixed(0) + "%"
    : value
}
