import { formField } from "src/utils/constants"
import {
  ConfigOutputGrandTotalType,
  ConfigOutputSubtotalType,
  ConfigReportGrandTotalType,
  ConfigReportSubtotalType
} from "../Types"

export const AggregateConfigsField = {
  DataSource: "DataSource",
  Column: "Column",
  Function: "Function"
}

export const createEditTotalFieldNames = {
  DataGroup: { name: "DataGroup", label: "Data_Group" },
  Label: { name: "Label", label: "Label" },
  AggregateConfigs: { name: "AggregateConfigs", label: "AggregateConfigs" },
  AConfigsDataSource: {
    name: `AggregateConfigs[{inx}].${AggregateConfigsField.DataSource}`,
    label: "Data_Source"
  },
  AConfigsColumn: {
    name: `AggregateConfigs[{inx}].${AggregateConfigsField.Column}`,
    label: "Column"
  },
  AConfigsFunction: {
    name: `AggregateConfigs[{inx}].${AggregateConfigsField.Function}`,
    label: "Function"
  },
  Placement: { name: "Placement", label: "Placement" }
}

export const AggregateConfigsIniValues = [
  { DataSource: "", Column: "", Function: "" }
]
export const createEditReportGTDifIniValues: ConfigReportGrandTotalType = {
  Label: "",
  AggregateConfigs: AggregateConfigsIniValues
}

export const createEditReportSTDifIniValues: ConfigReportSubtotalType = {
  DataGroup: [],
  ...createEditReportGTDifIniValues
}

export const createEditOutputGTDifIniValues: ConfigOutputGrandTotalType = {
  ...createEditReportGTDifIniValues,
  Placement: ""
}

export const createEditOutputSTDifIniValues: ConfigOutputSubtotalType = {
  ...createEditReportSTDifIniValues,
  Placement: ""
}

export const functionOptions = [
  { label: "SUM", value: "SUM" },
  { label: "MIN", value: "MIN" },
  { label: "MAX", value: "MAX" },
  { label: "MEAN", value: "MEAN" },
  { label: "MEDIAN", value: "MEDIAN" },
  { label: "DISTINCTCOUNT", value: "DISTINCTCOUNT" },
  { label: "COUNT", value: "COUNT" }
]

export const placementOptions = [
  { label: "AFTER", value: "AFTER" },
  { label: "BEFORE", value: "BEFORE" }
]

export const placementField = {
  fieldType: formField.auto_dropdown,
  props: {
    name: createEditTotalFieldNames.Placement.name,
    label: createEditTotalFieldNames.Placement.label,
    options: placementOptions
  }
}
