//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()
export class LoginService {
  ValidateEmail(email) {
    const endPoint = `/api/Login/ValidateEmail/${email}`
    return api.findAll(endPoint)
  }
  UpdatePassword(payLoad) {
    const endPoint = "/api/Login/UpdatePassword"
    return api.update(endPoint, payLoad)
  }
  logout() {
    const endPoint: string = `api/LogOut`
    return api.post(endPoint, {})
  }
  getTokenForADLogin() {
    const endPoint: string = `/api/Login/GetAzureAdAccessToken`
    return api.findAll(endPoint)
  }
  getLogin(){
    const endPoint: string = `/api/Login`
    return api.findAll(endPoint)
  }
}
//END-NOSCAN