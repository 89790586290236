import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

export const getEmailTooltipText = t => {
  return getTranslations(t, Translates.Email_Text)
}
export const getDestinationText = t => {
  return getTranslations(t, Translates.Destination)
}
export const getCustomerPortalText = t => {
  return getTranslations(t, Translates.Customer_Portal)
}
export const getFtpDirectoryText = t => {
  return getTranslations(t, Translates.Ftp_Directory)
}
export const getEmailText = t => {
  return getTranslations(t, Translates.Email)
}
export const getInvalidEmailText = t => {
  return getTranslations(t, Translates.Invalid_Email)
}
export const generateReportData = (FieldDetails, watch, form) => {
  let reportData = {}

  for (const fieldDetail of FieldDetails) {
    const fieldName = fieldDetail.name;
    reportData[fieldName] = watch(fieldName);
  }
  let Destination = {
    CustomerPortalCheck: form?.customerPortalCheck,
    FtpDirectory: form?.selectedOption,
    Email: form?.chips
  }

  reportData = { ...reportData, Destination }

  return reportData
}
