import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import {
  setModalContent,
  getReportColumnData,
  getVarianceFormVariable
} from "../../Utils/FunctionsHelper"
import {
  NonBlindVFieldOptions,
  ReportGridModalSource,
  fieldNames
} from "../../Utils/Constants"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import React, { useEffect, useReducer } from "react"
import { Translates } from "src/i18n/i18n"
import {
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import { PATH } from "src/constants/paths"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { setVarianceRedux } from "src/redux/slices/ConfigPortal/VarianceSlice"
import { ReportFieldType } from "../../Utils/Type"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { getTranslations } from "src/utils/helper"
import { useSelector } from "react-redux"

let preDeletedRow: ReportFieldType = {} as ReportFieldType
let showWarningModal: boolean = false
let modalOpenSource: string = ""
let ReportRemovedFields: string[] = []
const ReportFieldGrid = () => {
  const { t } = useTranslation<any>()
  const { watch, getValues, setValue } = useFormContext()
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )

  const [, setReload] = useReducer(reducer, false)
  let search = window.location.search || ""
  let { varianceForm, gridComponent, gridNoDataText } = getVarianceFormVariable(
    watch(fieldNames.CurrentTabVarianceType)
  )
  const IsNotEnableFeature = !watch(varianceForm.IsEnable.name)
  const rows = watch(varianceForm.ReportFields.name)

  const handleAddReportFieldClick = () => {
    createEditCommonAction(PATH.VARIANCES_REPORT_CREATE)
  }
  const handleEditReportFieldClick = (row: {
    original: ReportFieldType
    index: number
  }) => {
    createEditCommonAction(
      PATH.VARIANCES_REPORT_EDIT.replace(":id", `${row.index + 1}`)
    )
  }

  const handleDeleteReportFieldClick = (row: { original: ReportFieldType }) => {
    showWarningModal = true
    preDeletedRow = row.original
    modalOpenSource = ReportGridModalSource.delete
    setReload(oldVal => !oldVal)
  }
  const createEditCommonAction = path => {
    updateWarnOnTabChangeToStore(false)
    dispatch(setVarianceRedux(getValues()))
    setTimeout(() => {
      history.push(path + search, {
        varianceRedux: getValues()
      })
    })
  }

  const columnsData = React.useMemo(
    () =>
      getReportColumnData(
        t,
        IsNotEnableFeature,
        handleEditReportFieldClick,
        handleDeleteReportFieldClick,
        configTypeFromStore
      ),
    [t, IsNotEnableFeature] //eslint-disable-line
  )
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (watch(varianceForm.IsBlindVariance.name)) {
        ReportRemovedFields = rows
          .filter(i =>
            [...NonBlindVFieldOptions.map(obj => obj.value)].includes(i.Field)
          )
          .map(i => i.Field)
        if (ReportRemovedFields.length) {
          showWarningModal = true
          modalOpenSource = ReportGridModalSource.blindVariance
          setReload(oldVal => !oldVal)
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [watch(varianceForm.IsBlindVariance.name)])

  const CallbackConfirmation = (res: boolean) => {
    if (res) {
      let dt = rows
      if (modalOpenSource === ReportGridModalSource.delete) {
        dt = rows.filter(f => f.Field !== preDeletedRow.Field)
      } else if (modalOpenSource === ReportGridModalSource.blindVariance) {
        dt = rows.filter(
          i =>
            ![...NonBlindVFieldOptions.map(obj => obj.value)].includes(i.Field)
        )
      }
      setValue(varianceForm.ReportFields.name, dt)
    } else {
      if (modalOpenSource === ReportGridModalSource.blindVariance) {
        setValue(varianceForm.IsBlindVariance.name, false)
      }
    }
    showWarningModal = false
    preDeletedRow = {} as ReportFieldType
    modalOpenSource = ""
    setReload(oldVal => !oldVal)
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <h4>{getTranslations(t, Translates.Report_Fields)}</h4>
      </Grid>
      <Grid item xs={6} className="text-right">
        <Button
          variant="contained"
          data-testid="addReport"
          className="primary-btn pa-2"
          disabled={configTypeFromStore === "view" || IsNotEnableFeature}
          onClick={handleAddReportFieldClick}
        >
          <AddIcon className="mr-1" />{" "}
          {getTranslations(t, Translates.Add_Field)}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        className="mt-2"
        data-testid="VRFGridGlobalTable"
      >
        <GlobalTableDragDrop
          component={gridComponent}
          columns={columnsData}
          data={rows || []}
          noDataText={getTranslations(t, gridNoDataText)}
        />
        {showWarningModal && (
          <ConfirmDialog
            open={showWarningModal}
            callBack={CallbackConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setModalContent({
              preDeletedRow,
              modalOpenSource,
              ReportRemovedFields,
              t
            })}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.CANCEL)}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ReportFieldGrid
