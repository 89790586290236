//BEGIN-NOSCAN
import ApiService from "src/utils/useAxios"

const api = new ApiService()
export class MultilingualService {
  getLanguageText() {
    const endPoint = "api/LanguageContent"
    return api.findAll(endPoint)
  }
}
//END-NOSCAN
