import { useCallback, useReducer } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { Button, Divider, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { Translates } from "src/i18n/i18n"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { useAppThunkDispatch } from "src/redux/store"
import {
  reducer,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import FormHeading from "src/components/FormHeading"
import {
  getColumnsData,
  getVariableBasedOnComponent,
  setModalContent
} from "./Utils/Helpers/SignaturesGridHelper"
let preDeletedRow: any = {}
let showWarningModal = false

const Signatures = ({
  componentName,
  fieldName
}: {
  componentName: string
  fieldName: string
}) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const [, setReload] = useReducer(reducer, false)
  const dispatch = useAppThunkDispatch()

  const { setValue, getValues } = useFormContext()

  const { Signatures: signaturesFrom } = getValues()

  const search = window.location.search || ""

  const { variableSetRedux, tableComponent, createPath, editPath } =
    getVariableBasedOnComponent(componentName, storeId, -1)

  const handleCreateSignatureClick = useCallback(() => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))
    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          createPath,
          [":tab", ":tabId"],
          [componentName.toLocaleLowerCase(), `${storeId}`]
        ) + search,
        {
          variableRedux: getValues()
        }
      )
    })
  }, [
    dispatch,
    getValues,
    history,
    variableSetRedux,
    createPath,
    componentName,
    storeId,
    search
  ])

  const updateSignature = (row1: { index: number }) => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))
    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          editPath,
          [":tab", ":tabId", ":id"],
          [componentName.toLocaleLowerCase(), `${storeId}`, `${row1.index + 1}`]
        ) + search,
        {
          variableRedux: getValues()
        }
      )
    })
  }
  const deleteSignature = (row1: { original: any; index: number }) => {
    showWarningModal = true
    preDeletedRow = {
      ...row1.original,
      index: row1.index
    }
    setReload(oldVal => !oldVal)
  }

  const CallbackConfirmation = useCallback(
    (res: boolean) => {
      if (res) {
        const dt = signaturesFrom.filter(
          (_f, inx) => inx !== preDeletedRow.index
        )
        setValue(fieldName, dt)
      }
      showWarningModal = false
      preDeletedRow = {}
      setReload(oldVal => !oldVal)
    },
    [signaturesFrom, setValue, setReload]
  )

  const columnsData = getColumnsData({
    updateSignature,
    deleteSignature,
    t
  })

  return (
    <Grid
      container
      className="custom-table-responsive categories-table"
      data-testid="reportsSignatureGloTable"
    >
      <Grid item xs={12} sm={12}>
        <Divider className="dashed my-4" />

        <Grid container>
          <Grid item xs={6}>
            <FormHeading
              heading={`${getTranslations(t, Translates.Signatures)}`}
            />
          </Grid>
          <Grid item xs={6} className="text-right">
            <Button
              variant="contained"
              data-testid="reportSignatureCreate"
              className="primary-btn pa-2"
              onClick={handleCreateSignatureClick}
            >
              <AddIcon className="mr-1" />{" "}
              {getTranslations(t, Translates.New_Signature)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item className="mt-2">
        <GlobalTableDragDrop
          component={tableComponent}
          columns={columnsData}
          data={signaturesFrom}
          noDataText={getTranslations(t, Translates.No_Signatures_available)}
        />
      </Grid>
      {showWarningModal && (
        <ConfirmDialog
          open={showWarningModal}
          callBack={CallbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setModalContent({
            preDeletedRow,
            t
          })}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.CANCEL)}
        />
      )}
    </Grid>
  )
}

export default Signatures
