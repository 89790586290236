import { formField } from "src/utils/constants"
import { WeekDayOptions, WeekNumberOptions, renderType } from "./FcsOptions"
import { YDMOValues, yearlyFieldNames } from "../CreateEditMultiEventOutput"

export const yearlyRadioOptions = { day: "day", week: "week" }

export const DayMonthOptions = [
  { value: YDMOValues.January, label: "January" },
  { value: YDMOValues.February, label: "February" },
  { value: YDMOValues.March, label: "March" },
  { value: YDMOValues.April, label: "April", default: true },
  { value: YDMOValues.May, label: "May" },
  { value: YDMOValues.June, label: "June" },
  { value: YDMOValues.July, label: "July" },
  { value: YDMOValues.August, label: "August" },
  { value: YDMOValues.September, label: "September" },
  { value: YDMOValues.October, label: "October" },
  { value: YDMOValues.November, label: "November" },
  { value: YDMOValues.December, label: "December" }
]
export const yearlyFields = [
  [
    {
      type: renderType.field,
      fieldType: formField.radio,
      width: "22px",
      props: {
        name: yearlyFieldNames.DayMonthRadio.name,
        formControl: {
          radioGroup: {
            row: true,
            formControlLabels: [
              {
                value: yearlyRadioOptions.day,
                label: ""
              }
            ].map(i => ({ ...i, radio: { size: "medium" }, className: "mr-5" }))
          }
        }
      },
      resetFieldNames: [
        { name: yearlyFieldNames.WeekMonthRadio.name, value: "" }
      ]
    },
    { type: renderType.heading, heading: "On_Month", className: "ml-1" },
    {
      type: renderType.field,
      fieldType: formField.auto_dropdown,
      width: "200px",
      className: "ml-2",
      disableCondition: {
        name: yearlyFieldNames.DayMonthRadio.name,
        value: yearlyRadioOptions.day
      },
      props: {
        name: yearlyFieldNames.DayMonth.name,
        label: yearlyFieldNames.DayMonth.label,
        required: true,
        isNotSort: true,
        options: DayMonthOptions
      },
      triggerValidationFields: [
        { name: yearlyFieldNames.DayMonthDayNumber.name }
      ]
    },
    { type: renderType.heading, heading: "day", className: "ml-2" },
    {
      type: renderType.field,
      fieldType: formField.text,
      width: "80px",
      className: "ml-2",
      disableCondition: {
        name: yearlyFieldNames.DayMonthRadio.name,
        value: yearlyRadioOptions.day
      },
      props: {
        name: yearlyFieldNames.DayMonthDayNumber.name,
        label: yearlyFieldNames.DayMonthDayNumber.label,
        required: true
      }
    }
  ]
]
