import { useFormContext } from "react-hook-form"
import { Divider, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { fieldNames } from "../../Utils/Constants"
import { getVarianceFormVariable } from "../../Utils/FunctionsHelper"
import VarianceGeneralSettings from "./Components/VarianceGeneralSettings"
import MUICheckbox from "src/components/MUIFormFields/MUICheckbox"
import ThresholdSettings from "./Components/ThresholdSettings"
import ReportFieldGrid from "../ReportFields/ReportFieldsGrid"
import { getTranslations } from "src/utils/helper"

const VarianceForm = () => {
  const { t } = useTranslation<any>()
  const { watch } = useFormContext()
  let { varianceForm } = getVarianceFormVariable(
    watch(fieldNames.CurrentTabVarianceType)
  )
  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <MUICheckbox
              inputAttributes={{
                name: varianceForm.IsEnable.name,
                label: getTranslations(t, varianceForm.IsEnable.label),
                className: "light"
              }}
            />
            <Divider className="dashed my-4" />
            <VarianceGeneralSettings />
            <Divider className="dashed my-4" />
            <ThresholdSettings />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider className="dashed my-4" />
        <ReportFieldGrid />
      </Grid>
    </>
  )
}

export default VarianceForm
