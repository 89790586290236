import { IConfigCountProgram } from "src/utils/types/types"
import * as yup from "yup"
import { fieldNames } from "./Constant"

export const validationSchema = (
  countPrograms: IConfigCountProgram[],
  storeId: number
) => {
  return yup.object().shape({
    [fieldNames.name.name]: yup
      .string()
      .required(`${fieldNames.name.label} is required`)
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Only alphabets and numerics are allowed for this field"
      )
      .max(90, `${fieldNames.name.label} must be at most 90 characters`)
      .test(
        fieldNames.name.name,
        "Count program name already exist!",
        (value: string | undefined) => {
          let exist: boolean = false
          if (value) {
            countPrograms.forEach((item, inx) => {
              if (storeId === -1) {
                if (
                  value.toLowerCase().toString() ===
                  item.Name[0]["en-us"].toLowerCase().toString()
                ) {
                  exist = true
                }
              }
            })
          }
          return !exist
        }
      )
      .trim()
  })
}
