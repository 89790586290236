import { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { Checkbox, FormControlLabel, Grid, IconButton } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ImageIcon from "@mui/icons-material/Image"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { useTranslation } from "react-i18next"
import editIcon from "src/assets/images/Edit.png"
import { setModalOpen } from "src/redux/slices/ConfigPortal/CustomerSelectionSlice"
import { createEditFieldNames } from "../../Utils/Constants/CreateEditMultiEventOutput"
import { selectedCustomerSortByName } from "../../Utils/Helpers/CreateEditMultiEventOutput"

const CustomerSection = () => {
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const customers = useSelector((state: RootState) => state.settings?.customers)
  const selectedCustomers = customers?.filter(i => i.selected)
  const { setValue, watch } = useFormContext()
  const loadings = watch(createEditFieldNames.Loadings.name)

  useEffect(() => {
    let isMounted = true
    if (
      isMounted &&
      customers &&
      JSON.stringify(
        selectedCustomerSortByName(watch(createEditFieldNames.Customers.name))
      ) !== JSON.stringify(selectedCustomerSortByName(selectedCustomers))
    ) {
      setValue(createEditFieldNames.Customers.name, selectedCustomers, {
        shouldValidate: true
      })
      setValue(createEditFieldNames.Loadings.name, {
        ...loadings,
        isConfigAPICall: true
      })
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [customers])
  return (
    <>
      <Grid item xs={12} sm={12}>
        <label className="form-control__label mb-3">
          {`${getTranslations(t, Translates.Customer_Selected)}`}{" "}
          <span className="selected">{`(${
            selectedCustomers?.length
          } ${getTranslations(t, Translates.Selected)})`}</span>
          {customers?.length > 1 ? (
            <IconButton
              onClick={() => {
                dispatch(setModalOpen(true))
              }}
              className="ml-5"
            >
              <span className="text-center d-block">
                <img
                  src={editIcon}
                  alt={"edit icon"}
                  className="pointer"
                  data-testid="customer-select-edit"
                />
              </span>
            </IconButton>
          ) : null}
        </label>
      </Grid>
      <Grid item xs={12}>
        {selectedCustomers?.map((c: any) => {
          return (
            <FormControlLabel
              key={`cust-${c.name}`}
              className="check-logo gray-bg "
              control={
                <Checkbox
                  name="lgpd_agreement"
                  checked={c.selected}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
              label={
                <p>
                  {c.img ? (
                    <img src={c.img} alt={`${c.name}_logo`} />
                  ) : (
                    <ImageIcon style={{ minHeight: "60px" }} />
                  )}
                  <span>{c.name}</span>
                </p>
              }
            />
          )
        })}
      </Grid>
    </>
  )
}

export default CustomerSection
