import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"
export const renderAccuracySmiley = (cellValue, pGoal, sGoal) => {
    pGoal = pGoal * 100
    sGoal = sGoal * 100
    if (cellValue) {
      if (cellValue < 0) {
        return (
          <>
            <span className="accuracy-status error">
              <SentimentDissatisfiedIcon />
  
              {`${0}%`}
            </span>
          </>
        )
      } else if (cellValue >= pGoal) {
        return (
          <>
            <span className="accuracy-status success">
              <SentimentSatisfiedAltIcon />
              {`${cellValue}%`}
            </span>
          </>
        )
      } else if (cellValue >= sGoal && cellValue < pGoal) {
        return (
          <>
            <span className="accuracy-status warning">
              <SentimentNeutralIcon />
              {`${cellValue}%`}
            </span>
          </>
        )
      } else if (cellValue < sGoal) {
        return (
          <>
            <span className="accuracy-status error">
              <SentimentDissatisfiedIcon />
              {`${cellValue}%`}
            </span>
          </>
        )
      } else {
        return "-"
      }
    } else {
      return "-"
    }
  }