export const multiEventOutputRouteReplaceVar = {
  CreateSchedule: "/file-creation-schedule",
  Configuration: "/configuration"
}
const configVariancePaths = {
  VARIANCES: "/configuration/variances",
  VARIANCES_ADD: "/configuration/variances/report-field/create",
  VARIANCES_EDIT: "/configuration/variances/report-field/:id/edit",
  VARIANCES_REPORT_CREATE: "/configuration/variances/report-field/create",
  VARIANCES_REPORT_EDIT: "/configuration/variances/report-field/:id/edit"
}

const configReportPaths = {
  REPORTS: "/configuration/reports",
  REPORT_CREATE: "/configuration/reports/create",
  REPORT_EDIT: "/configuration/reports/:id/edit"
}

export const configPromptPaths = {
  TAB_CREATE_PROMPT_CREATE: "/configuration/:tab/create/prompt/create",
  TAB_EDIT_PROMPT_CREATE: "/configuration/:tab/:tabId/edit/prompt/create",
  TAB_CREATE_PROMPT_EDIT: "/configuration/:tab/create/prompt/:id/edit",
  TAB_EDIT_PROMPT_EDIT: "/configuration/:tab/:tabId/edit/prompt/:id/edit"
}

export const configSQLEditorPaths = {
  TAB_CREATE_SQL_EDITOR: "/configuration/:tab/create/sql-editor",
  TAB_EDIT_SQL_EDITOR: "/configuration/:tab/:tabId/edit/sql-editor"
}

export const configColumnPaths = {
  TAB_CREATE_COLUMN_CREATE: "/configuration/:tab/create/column/create",
  TAB_EDIT_COLUMN_CREATE: "/configuration/:tab/:tabId/edit/column/create",
  TAB_CREATE_COLUMN_EDIT: "/configuration/:tab/create/column/:id/edit",
  TAB_EDIT_COLUMN_EDIT: "/configuration/:tab/:tabId/edit/column/:id/edit"
}

export const configSignaturePaths = {
  TAB_CREATE_SIGNATURE_CREATE: "/configuration/:tab/create/signature/create",
  TAB_EDIT_SIGNATURE_CREATE: "/configuration/:tab/:tabId/edit/signature/create",
  TAB_CREATE_SIGNATURE_EDIT: "/configuration/:tab/create/signature/:id/edit",
  TAB_EDIT_SIGNATURE_EDIT: "/configuration/:tab/:tabId/edit/signature/:id/edit"
}

export const configTotalPaths = {
  TAB_CREATE_TOTAL_CREATE: "/configuration/:tab/create/:type/create",
  TAB_EDIT_TOTAL_CREATE: "/configuration/:tab/:tabId/edit/:type/create",
  TAB_CREATE_TOTAL_EDIT: "/configuration/:tab/create/:type/:id/edit",
  TAB_EDIT_TOTAL_EDIT: "/configuration/:tab/:tabId/edit/:type/:id/edit"
}

const configOutputBundlePaths = {
  OUTPUT_BUNDLE_CREATE: "/configuration/outputFiles/output-bundles/create",
  OUTPUT_BUNDLE_EDIT: "/configuration/outputFiles/output-bundles/:id/edit"
}

const configPortalPaths = {
  ...configVariancePaths,
  ...configReportPaths,
  ...configPromptPaths,
  ...configOutputBundlePaths,
  ...configSQLEditorPaths,
  ...configColumnPaths,
  ...configSignaturePaths,
  ...configTotalPaths,
  ...configOutputBundlePaths
}

const multiEventOutputs = {
  MULTI_EVENT_OUTPUTS: `${multiEventOutputRouteReplaceVar.CreateSchedule}/:tab`,
  MULTI_EVENT_OUTPUT_CREATE: `${multiEventOutputRouteReplaceVar.CreateSchedule}/:tab/create`,
  MULTI_EVENT_OUTPUT_EDIT: `${multiEventOutputRouteReplaceVar.CreateSchedule}/:tab/:id/edit`
}

export const PATH = {
  ...configPortalPaths,
  ...multiEventOutputs,
  ...{
    HOME: "/",
    LOGIN: "/login",
    DEVICE_RESERVATION: "/devicereservation",
    SETTINGS: "/settings",
    SCHEDULE_BANNER: "/scheduleBanner",
    LOCATION_BANNER: "/locationBanner",
    LOCATIONS: "/locations",
    CREATE_LOCATION: "/locations/create",
    EDIT_LOCATION: "/locations/edit/:storeId",
    LOCATION_DETAILS: "/locationDetails",
    LOCATION_TYPE_SELECT: "/locationTypeSelect",
    SETTINGS_REGIONAL_GROUPs: "/settingsRegionalGroups",
    CREATE_RESERVATION: "/createreservation",
    SELECT_CUSTOMER: "/selectCustomer",
    REGIONAL_GROUPS: "/settings/regionalGroups/regionalGroupsDetails",
    REGIONAL_GROUPS_BANNER: "/settings/regionalGroups",
    COMPANY_DETAILS: "/settings/companyDetails",
    CREATE_USER: "/user/create",
    EDIT_USER: "/user/edit/:email",
    CREATE_SCHEDULE: "/schedule/create",
    EDIT_SCHEDULE: "/schedule/edit/:scheduleId",
    USERS: "/users",
    SCHEDULE: "/schedule",
    SCHEDULE_DETAILS: "/schedule/Details",
    SCHEDULE_EVENT_DETAILS: "/schedule/EventDetails/:scheduleId",
    SCHEDULE_CREATE_TYPE: "/schedule/createType",
    CREATE_BULK_LOCATION: "/locations/createBulk",
    CREATE_USER_TYPE: "/user/create/type",
    LOCATION_BULK_DATAMAPPING: "/locations/createBulk/dataMapping",
    LOCATION_BULK_VALIDATION: "/locations/createBulk/dataMapping/validation",
    USER_TYPE_SELECT: "/userTypeSelect",
    CREATE_USER_BULK: "/user/create/bulk",
    USER_BULK_DATA_MAPPING: "/UserBulkDataMapping",
    CREATE_BULK_SCHEDULE: "/schedule/createBulk",
    SCHEDULE_BULK_DATAMAPPING: "/schedule/createBulk/dataMapping",
    CONFIGURATION: "/configuration",
    CONFIGURATION_DETAILS: "/configuration/Details",
    CONFIGURATION_PORTAL: "/configuration/generalSettings",
    CONFIGURATION_COUNT_FIELDS: "/configuration/countFields",
    CONFIGURATION_COUNT_FIELDS_LOCATION:
      "/configuration/createEditLocationCountField",
    CONFIGURATION_COUNT_FIELDS_PRODUCT:
      "/configuration/createEditProductCountField",
    CATEGORIES: "/configuration/categories",
    CREATE_CATEGORY: "/configuration/category/create",
    EDIT_CATEGORY: "/configuration/category/:id/edit",
    CREATE_REPORTING: "/configuration/createReport",
    EDIT_REPORTING: "/configuration/editReport/:storeId",
    CREATE_REPORTING_PROMPT: "/configuration/CreatePromptReport",
    EDIT_REPORTING_PROMPT: "/configuration/createReport/EditPrompt/:reportID",
    CONFIG_LOOKUP_ROUTINES: "/configuration/lookupRoutines",
    CONFIG_NEW_LOOKUP_ROUTINE: "/configuration/createNewLookupRoutine",
    CREATE_LOOKUP: "/configuration/newLookup",
    CREATE_EDIT_SQL: "/configuration/createSql",
    OUTPUT_FILES: "/configuration/outputFiles",
    CREATE_EDIT_OUTPUTFILES: "/configuration/createEditOutputFiles",
    AUDITS: "/configuration/audits",
    CREATE_AUDIT: "/configuration/auditsCreateAudit",
    AUDITS_INSTRUCTIONS: "/configuration/audits/instructions",
    COUNT_FIELDS_INSTRUCTIONS: "/configuration/count-fields/instructions",
    COUNT_PROGRAMS: "/configuration/count-programs",
    CREATE_COUNT_PROGRAMS: "/configuration/count-programs/create",
    EDIT_COUNT_PROGRAMS: "/configuration/count-programs/:id/edit",
    COUNT_PROGRAM_INSTRUCTIONS: "/configuration/count-programs/instructions",
    OUTPUTPROMPTS: "/configuration/outputPrompts",
    CREATE_EDIT_PROMPTS: "/configuration/createEditPromptsOutput",
    INPUT_FILES: "/configuration/inputFiles",
    SELECT_INPUT_FILE_TYPE: "/configuration/inputFileType",
    CREATE_EDIT_AUXILIARY_INPUT_FILE: "/configuration/createAuxiliaryInputFile",
    CREATE_EDIT_CUSTOMER_INPUT_FILE: "/configuration/createCustomerInputFile",
    CREATE_EDIT_COLUMN: "/configuration/createEditColumnReport",
    EDIT_COLUMNS: "/configuration/editColumnsOutput",
    CREATE_INPUT_FIELDS: "/configuration/createInputField",
    CREATE_EDIT_OUTPUT_SUBTOTALS: "/configuration/createEditOutputSubTotals",
    CREATE_EDIT_OUTPUT_GRAND_TOTALS:
      "/configuration/createEditOutputGrandTotals",
    CREATE_EDIT_REPORT_SUBTOTALS: "/configuration/createEditReportSubTotals",
    CREATE_EDIT_REPORT_GRAND_TOTALS:
      "/configuration/createEditReportGrandTotals",
    PAGE_NOT_FOUND: "/PageNotFound",
    UPLOADFILES_DATA: "/uploadFilesData",
    UPLOADFILES: "/uploadFiles",
    UPLOADFILES_DETAIL_DATA: "/uploadFiles/editDepartment",
    EDIT_DEPARTMENT_FILE: "/uploadFiles/department/edit",
    FINAL_REPORTS_AND_FILES: "/finalReportsAndFiles",
    EDIT_UPLOAD_FILES: "/uploadFiles/editFile/:id",
    CREATE_EDIT_UPLOAD_FILES_DATA: "/uploadFiles/editFile/:id/createEditData",
    LIVE_EVENTS: "/liveEvents",
    LOCATION_ATTRIBUTES: "/settings/locationAttributes",
    CREATE_EDIT_FTP_CONNECTION: "/createEditFtpConnection",
    FTP_CONNECTION: "/ftpConnection",
    EDIT_FTP_CONNECTION: "/ftpConnection/edit/:name",
    DEVICE_RESERVATION_TYPE_SELECT:
      "/devicereservation/deviceReservationTypeSelect",
    DEVICE_RESERVATION_BULK_UPLOAD: "/devicereservation/createBulk",
    DEVICE_RESERVATION_BULK_DATAMAPPING:
      "/devicereservation/createBulk/dataMapping",
    DEVICE_RESERVATION_BULK_VALIDATION:
      "/devicereservation/createBulk/dataMapping/validation",
    UPLOADFILE_BULK_UPLOAD: "/uploadfile/createBulk",
    UPLOADFILE_BULK_DATAMAPPING: "/uploadfile/createBulk/dataMapping",
    UPLOADFILE_BULK_VALIDATION: "/uploadfile/createBulk/dataMapping/validation",
    UPDATE_CONFIGURATION: "/updateConfiguration/:eventIds",
    CONFIG_VIEW_HISTORY: "/configuration-view-history"
  }
}
