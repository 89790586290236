import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MultiEventOutputFormType } from "src/pages/MultiEventOutputs/Utils/Types"

export interface MultiEventOutputSliceType {
  multiEventOutputsRedux: any[]
  multiEventOutputRedux: MultiEventOutputFormType
  isSetMultiEventOutputsRedux: boolean
  selectedCustomerIds: number[]
  configFilesByCustomersRedux: {
    configId: number
    customerId: number
    configName: string
    configVersion: string
  }[]
}

const initialState: MultiEventOutputSliceType = {
  multiEventOutputsRedux: [],
  multiEventOutputRedux: {} as MultiEventOutputFormType,
  isSetMultiEventOutputsRedux: false,
  selectedCustomerIds: [],
  configFilesByCustomersRedux: []
}
export const multiEventOutputSlice = createSlice({
  name: "multiEventOutput",
  initialState,
  reducers: {
    setMultiEventOutputsRedux: (state, _action: PayloadAction<any>) => {
      state.multiEventOutputsRedux = [..._action.payload]
      state.isSetMultiEventOutputsRedux = true
    },
    setMultiEventOutputRedux: (state, _action: PayloadAction<any>) => {
      state.multiEventOutputRedux = { ..._action.payload }
    },
    setSelectedCustomerIds: (state, _action: PayloadAction<any>) => {
      state.selectedCustomerIds = [..._action.payload]
    },
    setConfigFilesByCustomersRedux: (state, _action: PayloadAction<any>) => {
      state.configFilesByCustomersRedux = [..._action.payload]
    }
  },
  extraReducers: builder => {}
})
export const {
  setMultiEventOutputsRedux,
  setMultiEventOutputRedux,
  setConfigFilesByCustomersRedux,
  setSelectedCustomerIds
} = multiEventOutputSlice.actions
export default multiEventOutputSlice.reducer
