import React, { useRef } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

interface InputConfirm {
  open: boolean
  callBack: any
  title: any
  content: any
  custref: any
  isConfirmButtonShow?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
}

const ConfirmDialog: React.FC<InputConfirm> = ({
  open,
  callBack,
  title,
  content,
  custref,
  isConfirmButtonShow = true,
  confirmButtonText = "CONFIRM",
  cancelButtonText = "CANCEL",
}) => {
  const { t } = useTranslation<any>()

  const dialogCancelButtonRef = useRef<any>(custref)

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        className="custom-dialog"
        data-testid="custom-dialog"
      >
        <div>
          <Grid container className="custom-dialog__header">
            <Grid className="custom-dialog__header-close">
              <Button
                variant="text"
                className="secondary-btn"
                disableRipple={true}
                startIcon={<CloseIcon />}
                onClick={() => callBack(false)}
                ref={dialogCancelButtonRef}
                data-testid="dialog-cancel-click"
              >
                {getTranslations(t, Translates.Close)}
              </Button>
            </Grid>
            <h2> {title}</h2>
          </Grid>
        </div>
        <DialogContent className="custom-dialog__cont">
          {typeof content === "function" ? content() : content}
        </DialogContent>
        <DialogActions className="custom-dialog__actions">
          <Button
            variant="contained"
            className="secondary-btn mr-2"
            onClick={() => callBack(false)}
            ref={dialogCancelButtonRef}
            data-testid="dialog-cancel-btn"
          >
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            className="primary-btn"
            disabled={!isConfirmButtonShow}
            onClick={() => callBack(true)}
            data-testid="dialog-confirmation-btn"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
