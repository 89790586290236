import { useLocation } from "react-router-dom"
import { CONFIG_MODULES } from "src/utils/constants"
import CreateEditPrompts from "../Components/CreateEditPrompts"
import { Grid } from "@mui/material"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
const OutputCreateEditPrompts = () => {
  const location = useLocation() as any
  const getBreadcrumbConfig = () => {
    let currentPath: string = `audits`
    let variables: any = {
      audits: "Output Prompt Details"
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }

  return (
    <>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item sm={12}>
              <div className="custom-breadcrumb CP">
                <BreadCrumb
                  customPath={"Output Prompt Details"}
                  breadCrumbInfo={getBreadcrumbConfig()}
                  renderedPage={""}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <CreateEditPrompts
        component={CONFIG_MODULES.OutputFiles}
        location={location}
      />
    </>
  )
}
export default OutputCreateEditPrompts
