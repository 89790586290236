import React, { useEffect, useState } from "react"
import DropZone from "../../DropZone"
import {
  getIfElseLayout,
  getLayout,
  setIfElseLayout,
  setLayout
} from "../../layout.service"
import { functionTypes, ifElse, ROW, SIDEBAR_ITEM } from "../../LRConstants"
import { getRadioButtonsJSX } from "../../LRConstants/ifScreenRadioContent"
import {
  ifScreenErrorCheck,
  mainScreenErrorCheck
} from "../../LRConstants/renderRowErrorCheck"
import Row from "../../Row"
import shortid from "shortid"
import {
  booleanFunctionFieldAdding,
  handleMoveSidebarComponentIntoParent,
  margin_left_find_for_row
} from "../../helpers"
import { CONFIG_MODULES } from "src/utils/constants"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"

interface Props {
  isIfElseState: boolean
  setItem: any
  setPageHeading: any
  make_if_screen_enable: (a: boolean) => void
  setIsIfElseStateEdit: any
  setIsIfElseStartIndex: any
  removeRow: any
  setParameters: any
  parameters: any
  setOpen: any
  dragDropzoneShow: boolean
  open: boolean
  item: any
  setModalFormSubmitCall: any
  modalFormSubmitCall: boolean
  component: string
  isRecordLevelAudit: boolean
  isShowEmptyElseBlock: boolean
  isConditionObject: boolean
  productFieldName: string
}
const RenderLayout = ({
  isIfElseState,
  setItem,
  setPageHeading,
  make_if_screen_enable,
  setIsIfElseStateEdit,
  setIsIfElseStartIndex,
  removeRow,
  setParameters,
  parameters,
  setOpen,
  dragDropzoneShow,
  open,
  item,
  setModalFormSubmitCall,
  modalFormSubmitCall,
  component,
  isRecordLevelAudit,
  isShowEmptyElseBlock,
  isConditionObject,
  productFieldName
}: Props) => {
  const [reload, setReload] = useState<boolean>(true)
  const [dropZone, setDropZone] = useState<any>()

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (
        [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
      ) {
        if (!getIfElseLayout().length) {
          handleDrop(
            {
              path: "0",
              childrenCount: 0
            },
            ifElse[0]
          )
        } else {
          make_if_screen_enable(true)
          setIsIfElseStartIndex(0)
          setIsIfElseStateEdit(true)
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (open && modalFormSubmitCall) {
        updateData(item, dropZone)
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [parameters])

  const updateData = (uItem: any, uDropZone: any) => {
    let isIf = uItem?.function === functionTypes.If
    let tempLayout = isIfElseState || isIf ? getIfElseLayout() : getLayout()
    if (uItem?.edit) {
      tempLayout[uItem.index] = {
        ...tempLayout[uItem.index],
        ...uItem,
        ...{ parameters: parameters }
      }
      if (isIfElseState || isIf) {
        setIfElseLayout(tempLayout)
      } else {
        setLayout(tempLayout)
      }
      setOpen(false)
      setParameters([])
      setModalFormSubmitCall(false)
      return true
    }
    let splitDropZonePath = uDropZone?.path.split("-")
    let newItem = {}
    let isBraces = uItem?.function === functionTypes.Braces

    if (isBraces || isIf) {
      let id = shortid.generate()
      newItem = generateItem(uItem, newItem, id)
    } else {
      newItem = {
        ...uItem,
        id: shortid.generate(),
        type: ROW,
        parameters: parameters
      }
    }

    tempLayout = handleMoveSidebarComponentIntoParent(
      tempLayout,
      splitDropZonePath,
      newItem
    )
    if (isIfElseState || isIf) {
      if (
        tempLayout[0]?.function === functionTypes.If &&
        typeof tempLayout[0]?.content === "object" &&
        tempLayout[1]?.function === functionTypes.If &&
        tempLayout[1]?.content === ")"
      ) {
        setIsIfElseStartIndex(Number(uDropZone?.path.split("-")[0]))
      }
      setIfElseLayout(tempLayout)
    } else {
      setLayout(tempLayout)
    }
    setOpen(false)
    setParameters([])
    setModalFormSubmitCall(false)
  }
  const generateItem = (gItem, content, id = null) => {
    return {
      ...gItem,
      id: !id ? shortid.generate() : id,
      type: ROW,
      content: content
    }
  }
  const renderIfStatement = (layout, row, index) => {
    const row1 = {
      ...row,
      ...{
        type: ROW,
        label: row.function.toUpperCase(),
        parameters: [],
        isIfLayoutArray: true
      }
    }
    return renderRowComponent(layout, row1, index)
  }

  const expandMoreAndLessClick = (inx: number, isExpandMore: boolean) => {
    let templateLayout = isIfElseState
      ? [...getIfElseLayout()]
      : [...getLayout()]
    templateLayout[inx] = {
      ...templateLayout[inx],
      ...{ isExpandMore }
    }
    isIfElseState ? setIfElseLayout(templateLayout) : setLayout(templateLayout)
    setReload(oldVal => !oldVal)
  }

  const renderRowComponent = (layout, data, index) => {
    return (
      <>
        {data?.isRowDisplay ? (
          <Row
            rowNo={index}
            data={{ ...data, ...{ layout: layout, reload: reload } }}
            components={isIfElseState ? getIfElseLayout() : getLayout()}
            path={index}
            setParametersForEditModal={setParametersForEditModal}
            make_if_screen_enable_for_edit={make_if_screen_enable_for_edit}
            deleteRow={deleteRow}
            dragDropzoneShow={dragDropzoneShow}
            setReload={setReload}
            onDrop={handleDrop}
            expandMoreAndLessClick={expandMoreAndLessClick}
          />
        ) : null}
      </>
    )
  }
  const handleDrop = (hDropZone: any, hItem: any) => {
    updateWarnOnTabChangeToStore(true)
    if (hItem?.type === SIDEBAR_ITEM) {
      const { itemCopy } = booleanFunctionFieldAdding(
        hItem,
        isIfElseState,
        component,
        isRecordLevelAudit
      )
      setDropZone(hDropZone)
      setItem(itemCopy)
      if (itemCopy?.function === functionTypes.If) {
        if (
          ![CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
        ) {
          setPageHeading(oldVal => `${oldVal} - ${itemCopy?.label}`)
        }
        make_if_screen_enable(true)
      } else if (itemCopy?.inputType) {
        setOpen(true)
        return
      }
      updateData(itemCopy, hDropZone)
    }
  }
  const make_if_screen_enable_for_edit = (inx: number) => {
    const layout = getLayout()
    setIsIfElseStateEdit(true)
    setTimeout(() => {
      setIsIfElseStartIndex(inx)
      setIfElseLayout([...layout[inx].array])
      make_if_screen_enable(true)
    }, 50)
  }
  const deleteRow = (row, path) => {
    removeRow({ ...row, path: path })
    setReload(oldValue => !oldValue)
  }
  const setParametersForEditModal = (sItem, paras) => {
    setItem(sItem)
    setParameters(paras)
    setOpen(true)
  }
  let currentLayout = isIfElseState ? [...getIfElseLayout()] : [...getLayout()]
  let margin_left = 0

  const dropZoneFirstChild = (index: number, row: any) => {
    if (row?.isRowDisplay) {
      return (
        <div className={`${index === 0 ? "height-20" : ""}`}>
          <DropZone
            data={{
              path: `${index}`,
              childrenCount: currentLayout.length
            }}
            onDrop={handleDrop}
            isLast
            className={`${isIfElseState ? "ifScreen" : ""} ${
              !isIfElseState && dragDropzoneShow && index === 0 ? "" : "d-none"
            } `}
          />
        </div>
      )
    }

    return null
  }
  let isRowDisplay = true
  let expandMoreOrLessId = ""
  return (
    <>
      {currentLayout.map((row, index) => {
        const { ml, start } = margin_left_find_for_row(row, margin_left)
        margin_left = ml
        const { alertMsg } = isIfElseState
          ? ifScreenErrorCheck(
              currentLayout,
              row,
              index,
              margin_left,
              isConditionObject
            )
          : mainScreenErrorCheck(
              currentLayout,
              row,
              index,
              margin_left,
              productFieldName,
              component
            )
        const start_tab = start ? margin_left - 1 : margin_left

        const { itemCopy } = booleanFunctionFieldAdding(
          row,
          isIfElseState,
          component,
          isRecordLevelAudit
        )
        row = { ...row, ...itemCopy }
        row = {
          ...row,
          ...{
            ml: margin_left > 0 ? start_tab : 0,
            error: alertMsg,
            index: index,
            isIfScreen: isIfElseState,
            data: {
              path: `${index}`,
              childrenCount: currentLayout.length
            }
          }
        }
        if (
          isIfElseState &&
          [functionTypes.If, functionTypes.Braces].includes(row.function) &&
          row.content !== ")"
        ) {
          row = { ...row, ...(!row.condition && { condition: "OR" }) }
          row = {
            ...row,
            ...{ content: getRadioButtonsJSX(setReload, row) }
          }
        }

        if (
          [functionTypes.If, functionTypes.Else, functionTypes.Braces].includes(
            row?.function
          ) &&
          row?.content !== ")" &&
          isRowDisplay
        ) {
          expandMoreOrLessId = row?.id
          if (!row?.isExpandMore) {
            isRowDisplay = false
          }
        } else if (expandMoreOrLessId === row?.id) {
          expandMoreOrLessId = ""
          isRowDisplay = true
          if ([functionTypes.Else].includes(row?.function)) {
            expandMoreOrLessId = row?.id
            if (!row?.isExpandMore) {
              isRowDisplay = false
            }
          }
        }
        row = { ...row, ...{ isRowDisplay } }

        if (
          [functionTypes.If, functionTypes.Else, functionTypes.Braces].includes(
            row?.function
          ) &&
          row?.content !== ")" &&
          expandMoreOrLessId === row?.id
        ) {
          row = { ...row, ...{ isRowDisplay: true } }
        }
        if (!isShowEmptyElseBlock) {
          if (
            row?.function === functionTypes.Else &&
            currentLayout[index + 1]?.function === functionTypes.Endif
          ) {
            row = { ...row, ...{ isRowDisplay: false } }
          }
        }

        let locLayout = isIfElseState
          ? [...getIfElseLayout()]
          : [...getLayout()]
        locLayout[index] = row
        isIfElseState ? setIfElseLayout(locLayout) : setLayout(locLayout)

        return (
          <React.Fragment key={shortid.generate()}>
            {dropZoneFirstChild(index, row)}
            {!isIfElseState && row.function === functionTypes.If
              ? renderIfStatement(currentLayout, row, index)
              : renderRowComponent(currentLayout, row, index)}
          </React.Fragment>
        )
      })}

      <DropZone
        data={{
          path: `${currentLayout.length}`,
          childrenCount: currentLayout.length
        }}
        onDrop={handleDrop}
        isLast
        className={`lastDropzone ${
          !isIfElseState && dragDropzoneShow ? "" : "d-none"
        }`}
      />
    </>
  )
}
export default RenderLayout
