import { Controller, useFormContext } from "react-hook-form"
import { Autocomplete, Chip, FormControl, TextField } from "@mui/material"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { getFieldError, getDefaultValue } from "./Helper"
import CloseIcon from "@mui/icons-material/Close"
import { useState } from "react"
import { Props as MUIMultiAutoCompleteTextProps } from "./MUIMultiAutoCompleteText"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"

const MUIMultiAutoCompleteTextChips = ({
  inputAttributes
}: MUIMultiAutoCompleteTextProps) => {
  const { t } = useTranslation<any>()
  const [inputValue, setInputValue] = useState("")
  let {
    name,
    type,
    label,
    variant,
    placeholder,
    className,
    id,
    size,
    color,
    fullWidth,
    required,
    value,
    defaultValue,
    onChange,
    options,
    disabled,
    freeSolo,
    isNotSort
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods

  let newOptions = !isNotSort
    ? [...options].sort((a, b) => a.label?.localeCompare(b.label))
    : options

  let defVal: any = getDefaultValue(value, defaultValue, newOptions, true)
  const { isError, ErrorText } = getFieldError(name, errors)

  const handleKeyDown = (event, field, isBlur = false) => {
    if (
      (event.key === "Enter" || event.key === "Tab" || isBlur) &&
      inputValue !== ""
    ) {
      event.preventDefault()
      const trimmedValue = inputValue.trim()
      if (trimmedValue !== "") {
        field.onChange([...field.value, trimmedValue])
        setInputValue("")
      }
    } else if (event.key === "Backspace" && inputValue === "") {
      event.preventDefault()
      const updatedChips = [...field.value]
      updatedChips.pop()
      field.onChange(updatedChips)
    }
  }

  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field } }) => {
        return (
          <FormControl
            className="custom-form-control select-chips"
            variant="filled"
          >
            <Autocomplete
              ref={ref}
              className={`${className} "`}
              id={id ?? name}
              freeSolo={freeSolo ? true : false}
              options={[]}
              value={field.value}
              multiple
              onChange={(e, v, u, i) => {
                updateWarnOnTabChangeToStore(true)
                if (onChange) onChange()
              }}
              disabled={disabled ?? false}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => {
                      const updatedChips = [...field.value]
                      field.onChange(updatedChips.filter((i, j) => j !== index))
                    }}
                    deleteIcon={<CloseIcon />}
                  />
                ))
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={`${getTranslations(t, label)}`}
                  fullWidth={fullWidth ?? true}
                  required={required ?? false}
                  size={size ?? "small"}
                  placeholder={placeholder}
                  disabled={disabled ?? false}
                  type={type ?? "text"}
                  variant={variant ?? "filled"}
                  color={color ?? "primary"}
                  error={isError}
                  helperText={ErrorText}
                  onKeyDown={e => handleKeyDown(e, field)}
                  onBlur={e => handleKeyDown(e, field, true)}
                />
              )}
            />
          </FormControl>
        )
      }}
    />
  )
}

export default MUIMultiAutoCompleteTextChips
