import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  Divider,
  Checkbox
} from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import {
  Auxiliary_Fields,
  FileTypeList,
  INPUT_FILE_ACTION_TYPE,
  INPUT_FILE_TYPE,
  tables
} from "src/constants/inputFiles"
import { PATH } from "src/constants/paths"
import { Loader } from "src/utils/reusables/reusable"
import CheckIcon from "@mui/icons-material/Check"
import { useDispatch, useSelector } from "react-redux"
import {
  getTranslations,
  handleFocus,
  setWeekFieldError
} from "src/utils/helper"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import { setInputFiles } from "src/redux/slices/configSlice"
import { RootState } from "src/redux/store"
import { ConfigurationService } from "src/service/configuration.service"
import { updateWarnOnTabChangeToStore } from "../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { onCancelClick } from "../FunctionHelper"
import { FILE_TYPE_LIST } from "src/constants/inputFiles"

interface IForm {
  FileType: string
  Name: string
  CustomName: string
  Description: string
  LegacyProcessingCheck: boolean
  LegacyLocation: string
  CustomerMaintenanceCheck: boolean
  CustomRangeCheck: boolean
  RestrictRangesCheck: boolean
  AuxiliaryFile: any
  Type: string
}

const CreateEditInputFileAuxiliary = () => {
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const { t } = useTranslation<any>()
  const configData = useSelector((state: RootState) => state.config)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  let search = window.location.search
  const legacyLocationRef = useRef<any>(null)
  const [data, setData] = useState([] as any)
  const formIniVal = {
    FileType: "",
    Name: "",
    Description: "",
    LegacyProcessingCheck: false,
    LegacyLocation: "",
    CustomerMaintenanceCheck: false,
    RestrictRangesCheck: true,
    CustomRangeCheck: false,
    AuxiliaryFile: [],
    Type: INPUT_FILE_TYPE.Auxiliary
  }
  const [fileOptionFormValues, setFileOptionFormValues] = React.useState<any>({
    department: formIniVal,
    varianceControl: formIniVal,
    areaRangeFile: formIniVal,
    custom: formIniVal
  })
  const [form, setForm] = React.useState<IForm>(formIniVal as IForm)
  const [duplicateNameError, setDuplicateNameError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [emptyError, setEmptyError] = useState(false)
  const [weekIVFields, setWeekIVFields] = useState({
    Name: ""
  })
  const [legacyError, setLegacyError] = useState(false)
  const location: any = useLocation()
  let locationState: any = { ...location.state }
  const [tableData, setTableData] = useState([] as any)
  const configService = new ConfigurationService()
  useEffect(() => {
    getColumnNamesAndDataTypes()
    if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
      if (
        locationState.data.FileType === FileTypeList[0].value ||
        locationState.data.FileType === FileTypeList[1].value ||
        locationState.data.FileType === FileTypeList[2].value
      ) {
        setData(locationState.data.AuxiliaryFile)
      }
      setForm({
        ...locationState.data
      })
    }
  }, [])
  const getColumnNamesAndDataTypes = async () => {
    setLoading(true)
    await configService
      .getColumnNamesAndDataTypes()
      .then((temp: any) => {
        setTableData(temp.data)
      })
      .catch(err => {
        console.log(err.Message || err)
      })
      .finally(() => setLoading(false))
  }

  const setFormValueBySelectFileType = (oldVal, newVal) => {
    if (
      oldVal === FileTypeList[0].value ||
      (!oldVal && newVal === FileTypeList[0].value)
    ) {
      setFileOptionFormValues(oVal => ({ ...oVal, department: form }))
    } else if (
      oldVal === FileTypeList[1].value ||
      (!oldVal && newVal === FileTypeList[1].value)
    ) {
      setFileOptionFormValues(oVal => ({ ...oVal, varianceControl: form }))
    } else if (
      oldVal === FileTypeList[2].value ||
      (!oldVal && newVal === FileTypeList[2].value)
    ) {
      setFileOptionFormValues(oVal => ({ ...oVal, areaRangeFile: form }))
    } else if (
      oldVal === FileTypeList[3].value ||
      (!oldVal && newVal === FileTypeList[3].value)
    ) {
      setFileOptionFormValues(oVal => ({ ...oVal, custom: form }))
    }

    if (oldVal) {
      let formV: any = {}
      if (newVal === FileTypeList[0].value) {
        formV = fileOptionFormValues.department
      } else if (newVal === FileTypeList[1].value) {
        formV = fileOptionFormValues.varianceControl
      } else if (newVal === FileTypeList[2].value) {
        formV = fileOptionFormValues.areaRangeFile
      } else if (newVal === FileTypeList[3].value) {
        formV = fileOptionFormValues.custom
      }
      setForm(oVal => ({ ...oVal, ...formV }))
      if (formV?.Name) {
        onFormChange({
          target: { name: Auxiliary_Fields.Name, value: formV.Name }
        })
      }
    }
  }
  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value
    const checked = e.target.checked || false
    const fileTypePreVal = form.FileType
    if (e.target.name === Auxiliary_Fields.FileType) {
      // dont change this condition place to bottom
      setFormValueBySelectFileType(fileTypePreVal, val)
    }
    if (e.target.name) {
      setForm(oldVal => ({ ...oldVal, [e.target.name]: val }))
    }
    if (e.target.name === Auxiliary_Fields.LegacyProcessingCheck) {
      setLegacyError(false)
      if (checked) {
        setTimeout(() => {
          legacyLocationRef.current.focus()
        }, 50)
      }
      setForm(oldVal => ({ ...oldVal, [e.target.name]: checked }))
    }
    if (e.target.name === Auxiliary_Fields.CustomerMaintenanceCheck) {
      setForm(oldVal => ({ ...oldVal, [e.target.name]: checked }))
    }
    if (e.target.name === Auxiliary_Fields.CustomRangeCheck) {
      setForm(oldVal => ({ ...oldVal, [e.target.name]: checked }))
    }
    if (e.target.name === Auxiliary_Fields.RestrictRangesCheck) {
      setForm({ ...form, [e.target.name]: checked })
    }
    if (e.target.name === Auxiliary_Fields.FileType) {
      if (val === FileTypeList[0].value) {
        tableData.forEach(t => {
          if (t.tableName === tables.Validation_Department) {
            setData(t.columnsName)
          }
        })
        setForm(oldVal => ({
          ...oldVal,
          [e.target.name]: val,
          AuxiliaryFile: []
        }))
      } else if (val === FileTypeList[1].value) {
        tableData.forEach(t => {
          if (t.tableName === tables.VarianceControl) {
            setData(t.columnsName)
          }
        })
        setForm(oldVal => ({
          ...oldVal,
          [e.target.name]: val,
          AuxiliaryFile: []
        }))
      } else if (val === FileTypeList[2].value) {
        tableData.forEach(t => {
          if (t.tableName === tables.Area_Range_File) {
            setData(t.columnsName)
          }
        })
        setForm(oldVal => ({
          ...oldVal,
          [e.target.name]: val,
          AuxiliaryFile: []
        }))
      } else {
        setForm(oldVal => ({
          ...oldVal,
          [e.target.name]: val,
          AuxiliaryFile: []
        }))
      }
    }
    if (e.target.name === Auxiliary_Fields.Name) {
      setDuplicateNameError(false)

      setEmptyError(false)
      setNameError(false)
      if (!val) {
        setNameError(true)
        setEmptyError(true)
      }
      setForm(oldVal => ({
        ...oldVal,
        [e.target.name]: val
      }))
      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    }
    if (e.target.name === Auxiliary_Fields.Description) {
      setForm(oldVal => ({
        ...oldVal,
        [e.target.name]: val
      }))
    }
    if (e.target.name === Auxiliary_Fields.LegacyLocation) {
      setLegacyError(false)
      setForm(oldVal => ({
        ...oldVal,
        [e.target.name]: val
      }))
    }
  }
  const getLookupBreadcrumb = () => {
    if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
      return `${locationState.data.Name}`
    } else {
      return getTranslations(t, Translates.New_Auxiliary_File)
    }
  }
  const getBreadcrumbConfig = () => {
    let inputFiles = "inputFiles"
    let custLinks: any = {}
    custLinks["@inputFiles"] = "configuration/inputFiles"
    let newAuxiliaryFile = getTranslations(t, Translates.New_Auxiliary_File)
    let currentPath: string = `##configuration##/@${inputFiles}/${newAuxiliaryFile}`
    let variables: any = {}
    variables[`@${inputFiles}`] = getTranslations(t, Translates.Input_Files)
    variables[`${newAuxiliaryFile}`] = getLookupBreadcrumb()
    let bConfig: any = {
      custLinks,
      showHomeIcon: false,
      currentPath,
      mapObj: variables
    }
    return bConfig
  }

  const onSaveClick = () => {
    const inputFiles = configData?.inputFiles || []
    const _sortOrder =
      inputFiles.length > 0
        ? inputFiles.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0
    const _item = {
      FileType: form.FileType,
      Name: form.Name,
      Description: form.Description,
      LegacyProcessingCheck: form.LegacyProcessingCheck,
      LegacyLocation: form.LegacyLocation,
      RestrictRangesCheck: form.RestrictRangesCheck,
      CustomerMaintenanceCheck: form.CustomerMaintenanceCheck,
      CustomRangeCheck:
        form.FileType === FileTypeList[2].value ? form.CustomRangeCheck : false,
      AuxiliaryFile: data,
      Type: INPUT_FILE_TYPE.Auxiliary,
      SortOrder: _sortOrder + 1
    }
    let error: any = false
    const customerObject: any = inputFiles.find(
      (i: any) => i.Name === form.Name && form.Name !== locationState.data?.Name
    )
    if (customerObject) {
      setDuplicateNameError(true)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      error = true
    } else if (form.LegacyProcessingCheck && !form.LegacyLocation) {
      setLegacyError(true)
      error = true
    }
    if (!error) {
      setNameError(false)

      if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
        let foundIndex: number = Number(locationState.index)
        if (foundIndex !== -1) {
          let newArr = [...inputFiles]
          newArr[foundIndex] = _item
          dispatch(setInputFiles([...newArr]))
        } else {
          dispatch(setInputFiles([...inputFiles, _item]))
        }
      } else {
        dispatch(setInputFiles([...inputFiles, _item]))
      }

      updateWarnOnTabChangeToStore(false)
      setTimeout(() => {
        history.push(PATH.INPUT_FILES + search)
      })
    }
  }

  const commonValues = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: true,
    setWidth: 250,
    Cell: props => {
      return props.value ? props.value : "-"
    }
  }

  const columnHeadersList: any = [
    {
      ...{
        Header: getTranslations(t, Translates.Name),
        accessor: "columnName"
      },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.Data_Type),
        accessor: "dataType"
      },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.Allowed_Lengths),
        accessor: "characterLength"
      },
      ...commonValues
    }
  ]

  const columnsData = React.useMemo(
    () =>
      [
        columnHeadersList.map(id => {
          return {
            Header: id.Header,
            accessor: id.accessor,
            headerClassName: id.headerClassName,
            disableFilters: id.disableFilters,
            disableSortBy: id.disableSortBy,
            setWidth: id.setWidth,
            Cell: id.Cell
          }
        })
      ].flat(Infinity),
    [data, t]
  )
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive categories-table">
        <Grid xs={12} item>
          <GlobalTable
            component="inputFiles"
            columns={columnsData}
            data={data}
            noDataText={
              form.FileType === FileTypeList[0].label
                ? `${getTranslations(
                    t,
                    Translates.No_Departments_data_available
                  )}.`
                : `${getTranslations(
                    t,
                    Translates.No_Variance_Control_data_available
                  )}.`
            }
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }

  const getNameError = () => {
    if (nameError || emptyError) {
      return getTranslations(t, Translates.Name_cannot_be_empty)
    } else if (duplicateNameError) {
      return getTranslations(t, Translates.Name_already_exists)
    } else if (weekIVFields.Name) {
      return weekIVFields.Name
    }
    return ""
  }
  return (
    <>
      {loading ? (
        Loader()
      ) : (
        <div>
          <div className="main-toolbar">
            <div className="main-container" data-testid="input-aux-container">
              <Grid container>
                <Grid
                  item
                  sm={6}
                  className="d-flex align-items-center"
                  data-testid="input-aux-Grid"
                >
                  <div
                    className="custom-breadcrumb CP"
                    data-testid="input-aux-BreadCrumb-div"
                  >
                    <BreadCrumb
                      customPath={"New Auxiliary File"}
                      breadCrumbInfo={getBreadcrumbConfig()}
                      renderedPage={""}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className="main-container">
            <Grid item xs={12} sm={8}>
              <div>
                <strong className="d-block mb-1">
                  {getTranslations(t, Translates.FILE)}
                </strong>
                <FormControl data-testid="CEIFAFileType">
                  <RadioGroup
                    row
                    name="FileType"
                    value={form.FileType}
                    onChange={e => onFormChange(e)}
                  >
                    {FileTypeList.map(m => {
                      return (
                        <FormControlLabel
                          key={m.value}
                          className="mr-7"
                          value={m.value}
                          control={<Radio />}
                          label={getTranslations(t, m.label)}
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
                <Divider className="dashed mt-2" />
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoComplete="off"
                    label={getTranslations(t, Translates.Name)}
                    name="Name"
                    onChange={e => onFormChange(e)}
                    onFocus={handleFocus}
                    value={form.Name}
                    variant="filled"
                    className="custom-form-control mt-3"
                    error={
                      nameError ||
                      emptyError ||
                      duplicateNameError ||
                      !!weekIVFields.Name
                    }
                    helperText={getNameError()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoComplete="off"
                    label={getTranslations(t, Translates.Description)}
                    name="Description"
                    onChange={e => onFormChange(e)}
                    onFocus={handleFocus}
                    value={form.Description}
                    variant="filled"
                    className="custom-form-control mt-3"
                  />
                </Grid>
              </div>
              <Divider className="dashed my-4" />
              <Grid container>
                <Grid item xs={12} sm={4}>
                  {form.FileType !== FILE_TYPE_LIST.AreaRangeFile ? (
                    <>
                      <FormControlLabel
                        className="mb-2"
                        control={
                          <Checkbox
                            onChange={e => onFormChange(e)}
                            checked={form.LegacyProcessingCheck}
                            className="custom-check light"
                            name="LegacyProcessingCheck"
                            checkedIcon={<CheckIcon />}
                          />
                        }
                        label={getTranslations(
                          t,
                          Translates.Used_by_Legacy_Processing
                        )}
                      />
                      {form.LegacyProcessingCheck ? (
                        <TextField
                          inputRef={legacyLocationRef}
                          size="small"
                          autoComplete="off"
                          label={getTranslations(t, Translates.Legacy_Location)}
                          name="LegacyLocation"
                          onChange={e => onFormChange(e)}
                          onFocus={handleFocus}
                          value={form.LegacyLocation}
                          variant="filled"
                          className="custom-form-control"
                          error={legacyError}
                          helperText={
                            legacyError
                              ? getTranslations(
                                  t,
                                  Translates.Please_enter_legacy_location
                                )
                              : ""
                          }
                        />
                      ) : null}
                    </>
                  ) : null}

                  <FormControlLabel
                    className="mb-2"
                    control={
                      <Checkbox
                        onChange={e => onFormChange(e)}
                        checked={form.CustomerMaintenanceCheck}
                        className="custom-check light"
                        name="CustomerMaintenanceCheck"
                        checkedIcon={<CheckIcon />}
                      />
                    }
                    label={getTranslations(
                      t,
                      Translates.Allow_Customer_Maintenance
                    )}
                  />
                  {form.FileType === FILE_TYPE_LIST.AreaRangeFile && (
                    <FormControlLabel
                      className="mb-2"
                      control={
                        <Checkbox
                          onChange={e => onFormChange(e)}
                          checked={form.RestrictRangesCheck}
                          className="custom-check light"
                          name="RestrictRangesCheck"
                          checkedIcon={<CheckIcon />}
                        />
                      }
                      label={getTranslations(
                        t,
                        Translates.Restrict_Ranges_to_File_Values
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              {form.FileType === "Custom" ? (
                <div>
                  <Divider className="dashed my-4" />
                  <strong className="d-block mb-2">
                    {getTranslations(t, Translates.CUSTOM_FILE_FORMAT)}
                  </strong>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => onFormChange(e)}
                        checked={form.CustomRangeCheck}
                        className="custom-check light"
                        name="CustomRangeCheck"
                        checkedIcon={<CheckIcon />}
                      />
                    }
                    label={getTranslations(t, Translates.Range_File)}
                  />
                </div>
              ) : null}
            </Grid>
            {form.FileType === "Department" ||
            form.FileType === FILE_TYPE_LIST.AreaRangeFile ||
            form.FileType === "VarianceControl" ? (
              <Grid container className="main-container">
                <Grid item xs={12}>
                  <Divider className="dashed my-4" />
                </Grid>
                <Grid item xs={12}>
                  <strong className="d-block mb-2">
                    {getTranslations(t, Translates.AUXILIARY_FILE)}
                  </strong>
                  {displayGrid()}
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <div className="inner-view__footer">
                <Button
                  data-testid="cancelBtn"
                  variant="text"
                  className="primary-btn cancel-btn mr-5"
                  onClick={() => {
                    onCancelClick(history)
                  }}
                >
                  {getTranslations(t, Translates.Cancel)}
                </Button>
                <Button
                  data-testid="saveBtn"
                  variant="contained"
                  className="primary-btn"
                  disabled={
                    !form.Name ||
                    !form.FileType ||
                    configTypeFromStore === "view" ||
                    Object.values(weekIVFields).some(s => s || s !== "")
                  }
                  onClick={onSaveClick}
                >
                  {getTranslations(t, Translates.Save)}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default CreateEditInputFileAuxiliary
