import { useCallback, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { ROW, SIDEBAR_ITEM, functionTypes } from "./LRConstants"
import editIcon from "../../../assets/images/Edit.png"
import { IconButton, styled, Tooltip } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import update from "immutability-helper"
import { setIfElseLayout, setLayout } from "./layout.service"
import Badge, { BadgeProps } from "@mui/material/Badge"
import { getAllIndexes, renderRowLayoutChildren } from "./helpers"
import classNames from "classnames"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { KEY_CODES } from "src/utils/constants"

const style = {}

const Row = ({
  data,
  rowNo,
  components,
  path,
  setParametersForEditModal,
  make_if_screen_enable_for_edit,
  deleteRow,
  dragDropzoneShow,
  setReload,
  onDrop,
  expandMoreAndLessClick
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isTopRow, setIsTopRow] = useState<boolean>(false)

  const DND_ITEM_TYPE = [SIDEBAR_ITEM, ROW]
  const [{ handlerId, isOver, canDrop }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId()
    }),
    drop: (item, monitor) => {
      let dropPosition = data.data
      if (!isTopRow) {
        dropPosition = {
          ...dropPosition,
          ...{ path: `${Number(dropPosition.path) + 1}` }
        }
      } else {
        if (
          data?.function === functionTypes.Endif &&
          data.layout[data?.index - 1]?.function === functionTypes.Else &&
          !data.layout[data?.index - 1]?.isRowDisplay
        ) {
          dropPosition = {
            ...dropPosition,
            ...{ path: `${Number(dropPosition.path) - 1}` }
          }
        }
      }
      dragDropzoneShow && onDrop(dropPosition, item)
    },
    hover: (item: any, monitor) => {
      if (ref.current) {
        let hoverIndex = path
        const dragIndex = item.index

        if (
          data?.function === functionTypes.Endif &&
          data.layout[data?.index - 1]?.function === functionTypes.Else &&
          !data.layout[data?.index - 1]?.isRowDisplay
        ) {
          hoverIndex = dragIndex < hoverIndex ? path + 1 : path - 1
        }
        const layout = [...data.layout]
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        if (clientOffset) {
          const hoverClientY = clientOffset.y - hoverBoundingRect.top
          let locIsTopRow = hoverMiddleY > hoverClientY

          if (data?.isIfScreen) {
            if ([path].includes(0)) {
              locIsTopRow = false
            } else if ([path].includes(layout.length - 1)) {
              locIsTopRow = true
            }
          }
          setIsTopRow(locIsTopRow)
        }

        if (!dragDropzoneShow) {
          if (!ref.current) {
            return
          }
          let indexes: number[] = []
          const dragRecord = layout[dragIndex]

          if (dragIndex === hoverIndex) {
            return
          }
          if (
            data?.isIfScreen &&
            ([dragIndex, hoverIndex].includes(0) ||
              [dragIndex, hoverIndex].includes(layout.length - 1))
          ) {
            return
          }
          if (
            [functionTypes.Braces].includes(dragRecord?.function) &&
            dragRecord.content === ")"
          ) {
            return
          }
          if (
            [functionTypes.Else, functionTypes.Endif].includes(
              dragRecord?.function
            )
          ) {
            return
          }
          if (
            [functionTypes.If, functionTypes.Braces].includes(
              dragRecord?.function
            )
          ) {
            indexes = getAllIndexes(layout, dragRecord.id, true, "id")
            if (
              hoverIndex >= indexes[0] &&
              hoverIndex <= indexes[indexes.length - 1]
            ) {
              return
            }
          }

          if (clientOffset) {
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return
            }
          }
          if (moveRow(dragIndex, hoverIndex, indexes, layout, dragRecord)) {
            if (
              [functionTypes.If, functionTypes.Braces].includes(
                dragRecord?.function
              )
            ) {
              item.index =
                dragIndex < hoverIndex && hoverIndex - dragIndex !== 1
                  ? dragIndex + 1
                  : hoverIndex
            } else {
              item.index = hoverIndex
            }
          }
        }
      }
    }
  })

  const moveRow = (dragIndex, hoverIndex, indexes, layout, dragRecord) => {
    let updatedRecords: any
    if (
      [functionTypes.If, functionTypes.Braces].includes(dragRecord?.function)
    ) {
      updatedRecords = update(layout, {
        $splice: [
          [dragIndex, indexes[indexes.length - 1] - indexes[0] + 1],
          [
            dragIndex < hoverIndex && hoverIndex - dragIndex !== 1
              ? dragIndex + 1
              : hoverIndex,
            0,
            layout.slice(indexes[0], indexes[indexes.length - 1] + 1)
          ]
        ]
      })
      updatedRecords = updatedRecords.flat(Infinity)
    } else {
      updatedRecords = update(layout, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord]
        ]
      })
    }

    data.isIfScreen
      ? setIfElseLayout(updatedRecords)
      : setLayout(updatedRecords)

    setReload(oldVal => !oldVal)
    return true
  }

  const [{ isDragging }, drag]: any = useDrag({
    type: ROW,
    item: data,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      }
    }
  } as any)
  const opacity = isDragging ? 0 : 1

  dragDropzoneShow ? drop(ref) : drag(drop(ref))
  const displayTooltip = () => {
    return (
      data?.inputType?.length ||
      (data?.isIfLayoutArray && data.function === functionTypes.If)
    )
  }
  const displayDeleteTooltip = () => {
    return (
      ![
        functionTypes.If,
        functionTypes.Braces,
        functionTypes.Else,
        functionTypes.Endif
      ].includes(data.function) ||
      (data?.isIfLayoutArray && data.function === functionTypes.If) ||
      (data.function === functionTypes.Braces && data.content !== ")")
    )
  }

  const handleEditModalKeyUp = useCallback((e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      openEditModal()
    }
  }, [])
  const openEditModal = () => {
    if (!data?.isIfLayoutArray) {
      setParametersForEditModal(
        { ...components[path], ...{ edit: true, index: path } },
        data.parameters
      )
    } else {
      make_if_screen_enable_for_edit(path)
    }
  }
  const openDeleteModal = () => {
    deleteRow(data, path)
  }

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -2,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 2px",
      minWidth: "12px",
      height: "12px"
    }
  }))
  const isActive = isOver && canDrop

  let rowHoverClass = ""
  if (dragDropzoneShow && isOver) {
    rowHoverClass = `${isTopRow ? "hover-top" : "hover-bottom"}`
  }

  const usefulTipsIcons = () => {
    if (data.error && Object.keys(data.error).length && data.error.content) {
      return (
        <Tooltip arrow title={data.error.content}>
          <IconButton aria-label="cart">
            <StyledBadge badgeContent="" color="error" />
          </IconButton>
        </Tooltip>
      )
    } else {
      if (
        [functionTypes.If, functionTypes.Else, functionTypes.Braces].includes(
          data?.function
        ) &&
        data.content !== ")"
      ) {
        if (data?.isExpandMore) {
          return (
            <Tooltip arrow title={"Expand Less"}>
              <IconButton
                size="small"
                onClick={() => expandMoreAndLessClick(path, false)}
                data-testid="Expand Less"
              >
                <ExpandLessIcon />
              </IconButton>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip arrow title={"Expand More"}>
              <IconButton
                size="small"
                onClick={() => expandMoreAndLessClick(path, true)}
                data-testid="Expand More"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
          )
        }
      }
    }
    return null
  }

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className={classNames(`base draggable row cursor-move ${rowHoverClass}`, {
        active: isActive
      })}
      data-handler-id={handlerId}
      data-testid="lookup-row-drag"
    >
      <span className="base-row">
        <strong>
          {rowNo + 1} {usefulTipsIcons()}
        </strong>{" "}
        {renderRowLayoutChildren(data)}
        {displayTooltip() ? (
          <Tooltip arrow title="Edit">
            <img
              src={editIcon}
              alt={"RowEditImg"}
              className="base-row__edit"
              width="20"
              onClick={openEditModal}
              onKeyUp={handleEditModalKeyUp}
            />
          </Tooltip>
        ) : null}
        {displayDeleteTooltip() ? (
          <Tooltip arrow title="Remove">
            <CancelIcon
              data-testid="RowDeleteIcon"
              className="base-row__delete"
              width="20"
              onClick={openDeleteModal}
            />
          </Tooltip>
        ) : null}
      </span>
    </div>
  )
}
export default Row
