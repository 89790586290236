import { IFormCountProgram } from "src/utils/types/types"
import { LookupConfigToEditor } from "../LookupRoutines/helpers"
export const COUNT_PROGRAMS_TYPE = {
  CLONE: "CLONE",
  EDIT: "EDIT"
}
export const CountTypeList = [
  { label: "AQ Only", value: "AQ Only", translateKey: "AQ_Only" },
  { label: "MQ Only", value: "MQ Only", translateKey: "MQ_Only" },
  { label: "TOGGLE", value: "TOGGLE", translateKey: "TOGGLE" }
]
export const COUNT_PROGRAMS_FIELDS = {
  Name: "Name",
  CountType: "CountType"
}
export const LOCATION_RADIO_LIST = [
  { label: "Standard Wis Tag", value: "StandardWisTag" },
  { label: "Custom Location", value: "CustomLocation" },
  { label: "CODE128", value: "CODE128" },
  { label: "QRCODE", value: "QRCODE" },
  { label: "Enable Mode", value: "Enabled" }
]
export const landingColumnDataDetails = [
  {
    Header: "Name",
    accessor: "Name[0][en-us]",
    setWidth: 250
  },
  {
    Header: "ACTIONS",
    accessor: "action",
    setWidth: 200
  }
]

export const fieldNames = {
  name: { name: "Name", label: "Name" },
  StandardWisTag: {
    name: "LocationMode.items[{inx}].StandardWisTag",
    label: "Standard WIS Tag",
    value: "Standard WIS Tag"
  },
  CustomLocation: {
    name: "LocationMode.items[{inx}].StandardWisTag",
    label: "Custom Location",
    value: "Custom Location"
  },

  IsAlpha: {
    name: "LocationMode.items[{inx}].IsAlpha"
  },
  AllowedBarcodeTypes0: {
    name: "LocationMode.items[{inx}].AllowedBarcodeTypes0",
    label: "QRCODE"
  },
  AllowedBarcodeTypes1: {
    name: "LocationMode.items[{inx}].AllowedBarcodeTypes1",
    label: "CODE128"
  },
  ParentTabIndex: { name: "ParentTabIndex" },
  ChildTabIndex: { name: "ChildTabIndex" },
  ProductEnableMode: { name: "ProductMode.Enabled", label: "Enable_Mode" },
  countType: { name: "ProductMode.CountType", label: "Count Type" },
  locationSetupInstructionArrayName: {
    name: "LocationMode.items[{inx}].SetupInstructions"
  },
  locationValidationInstructionArrayName: {
    name: "LocationMode.items[{inx}].ValidationInstructions"
  },
  locationLookupInstructionArrayName: {
    name: "LocationMode.items[{inx}].LookupInstructions"
  },
  locationKeyName: {
    name: "LocationMode.items[{inx}].key"
  },
  locationIsSeparateInsertRuleName: {
    name: "LocationMode.items[{inx}].IsSeparateInsertRule",
    label: "Separate Insert Rules"
  },

  locationInsertRuleSetupInstructionArrayName: {
    name: "LocationMode.items[{inx}].InsertRules.SetupInstructions"
  },
  locationInsertRuleValidationInstructionArrayName: {
    name: "LocationMode.items[{inx}].InsertRules.ValidationInstructions"
  },
  locationInsertRuleLookupInstructionArrayName: {
    name: "LocationMode.items[{inx}].InsertRules.LookupInstructions"
  },
  productIsSeparateInsertRuleName: {
    name: "ProductMode.items[{inx}].IsSeparateInsertRule",
    label: "Separate Insert Rules"
  },
  productSetupInstructionArrayName: {
    name: "ProductMode.items[{inx}].SetupInstructions"
  },
  productValidationInstructionArrayName: {
    name: "ProductMode.items[{inx}].ValidationInstructions"
  },
  productLookupInstructionArrayName: {
    name: "ProductMode.items[{inx}].LookupInstructions"
  },
  productInsertRuleSetupInstructionArrayName: {
    name: "ProductMode.items[{inx}].InsertRules.SetupInstructions"
  },
  productInsertRuleValidationInstructionArrayName: {
    name: "ProductMode.items[{inx}].InsertRules.ValidationInstructions"
  },
  productInsertRuleLookupInstructionArrayName: {
    name: "ProductMode.items[{inx}].InsertRules.LookupInstructions"
  },

  productKeyName: {
    name: "ProductMode.items[{inx}].key"
  }
}

export const AllowedBarcodeTypes = ["QRCODE", "CODE128"]

export const locationStaticConfigSetupInstruction: any = [
  {
    Function: "AllowScan",
    Parameters: ["UPCA,CODE128"]
  },
  {
    Function: "AllowKey",
    Parameters: ["NUMERIC"]
  }
]
export const locationStaticConfigValidationInstruction: any = [
  {
    Function: "Trim",
    Parameters: [" "]
  },
  {
    Function: "CheckDataType",
    Parameters: ["Numeric"],
    OnFail: "ERROR",
    OnFailMessage: [
      {
        "en-us": "Invalid Data Type"
      }
    ]
  },
  {
    Function: "CheckLength",
    Parameters: [7, 12],
    OnFail: "ERROR",
    OnFailMessage: [
      {
        "en-us": "Invalid Length"
      }
    ]
  }
]

const conditionCommon = {
  Function: "If",
  Condition: [
    "AND",
    [
      {
        Function: "CheckEntryMethod",
        Parameters: ["SCANNED"]
      },
      {
        Function: "CheckLength",
        Parameters: [12]
      },
      {
        Function: "CheckBarcodeType",
        Parameters: ["UPCA"]
      }
    ]
  ],
  IfTrue: [
    {
      Function: "LookupRoutine",
      Parameters: [
        "UPCA_LOOKUP",
        true,
        [
          {
            "en-us": "Barcode Not Found"
          }
        ],
        true,
        "BARCODE"
      ]
    }
  ]
}

let conditionCommon1 = JSON.parse(JSON.stringify(conditionCommon))
conditionCommon1.Condition[1][1]["Parameters"] = [7]
conditionCommon1.Condition[1][2]["Parameters"] = ["CODE128"]
conditionCommon1.IfTrue[0].Parameters[0] = "SKU_LOOKUP"
conditionCommon1.IfTrue[0].Parameters[2][0]["en-us"] = "SKU Not Found"
conditionCommon1.IfTrue[0].Parameters[4] = "SKU"

let conditionCommon2 = JSON.parse(JSON.stringify(conditionCommon))
conditionCommon2.Condition[1][0]["Parameters"] = ["KEYED"]

export const locationStaticConfigLookupInstruction: any = [
  conditionCommon,
  conditionCommon1,
  conditionCommon2,
  {
    Function: "If",
    Condition: [
      "AND",
      [
        {
          Function: "CheckEntryMethod",
          Parameters: ["KEYED"]
        },
        {
          Function: "CheckLength",
          Parameters: [7]
        }
      ]
    ],
    IfTrue: [
      {
        Function: "LookupRoutine",
        Parameters: ["SKU_LOOKUP"]
      }
    ]
  },
  {
    Function: "ERROR",
    Parameters: [
      [
        {
          "en-us": "Item Not Found and Rescan"
        }
      ],
      false
    ]
  }
]

export const locationStaticEditorSetupInstruction: any = LookupConfigToEditor(
  locationStaticConfigSetupInstruction
)
export const locationStaticEditorValidationInstruction: any =
  LookupConfigToEditor(locationStaticConfigValidationInstruction)
export const locationStaticEditorLookupInstruction: any = LookupConfigToEditor(
  locationStaticConfigLookupInstruction
)

export const defaultIniValues: IFormCountProgram = {
  Name: "",
  LocationMode: {
    items: [
      {
        key: 0,
        StandardWisTag: fieldNames.StandardWisTag.value,
        IsAlpha: false,
        AllowedBarcodeTypes0: true,
        AllowedBarcodeTypes1: true,
        SetupInstructions: [],
        ValidationInstructions: [],
        LookupInstructions: [],
        IsSeparateInsertRule: false,
        InsertRules: {
          SetupInstructions: [],
          ValidationInstructions: [],
          LookupInstructions: []
        }
      }
    ]
  },
  ProductMode: {
    Enabled: true,
    CountType: "",
    items: [
      {
        key: 0,
        SetupInstructions: [],
        ValidationInstructions: [],
        LookupInstructions: [],
        IsSeparateInsertRule: false,
        InsertRules: {
          SetupInstructions: [],
          ValidationInstructions: [],
          LookupInstructions: []
        }
      }
    ]
  },
  ParentTabIndex: 0,
  ChildTabIndex: 0
}
export const CopyStdWisTagSetupInstructions: any = [
  {
    Function: "AllowScan",
    Parameters: ["CODE128", "QRCODE"]
  },
  {
    Function: "AllowKey",
    Parameters: ["ALPHANUMERIC"]
  }
]
const MIN_VALUE = 5
const MAX_VALUE = 7
export const checkBoxClass = "custom-check small"
export const CopyStdWisTagValidationInstructions: any = [
  {
    Function: "CheckDataType",
    Parameters: ["NUMERIC"],
    OnFail: "ERROR",
    OnFailMessage: [{ "en-us": "Invalid Data Type" }]
  },
  {
    Function: "CheckLengthRange",
    Parameters: [MIN_VALUE, MAX_VALUE],
    OnFail: "ERROR",
    OnFailMessage: [{ "en-us": "Must be between 5-7" }]
  }
]
