import * as yup from "yup"
import {
  createEditFieldNames,
  fileTypes
} from "../Constants/CreateEditOutputBundleConstant"
import { ConfigOutputBundleType } from "../Types"
import { requiredErr } from "src/utils/constants"
import { destinationValidation } from "src/configurationPortal/pages/Reports/Utils/Validations/CreateEditReportValidation"
import { getTranslations, validateInputString } from "src/utils/helper"
export const validationSchema = (
  outputBundlesRedux: ConfigOutputBundleType[],
  storeId: number,
  t,
  Translates
) => {
  return yup.object().shape({
    [createEditFieldNames.Name.name]: yup
      .string()
      .required(requiredErr)
      .test(
        createEditFieldNames.Name.name,
        "Output bundle name already exist.",
        value => {
          return !outputBundlesRedux.some(
            (item, inx) => item.Name === value && storeId - 1 !== inx
          )
        }
      )
      .test(
        createEditFieldNames.Name.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      ),

    [createEditFieldNames.FileName.name]: yup
      .string()
      .when([createEditFieldNames.FileType.name], {
        is: Type => Type === fileTypes.Zipped,
        then: yup
          .string()
          .required(requiredErr)
          .test(
            createEditFieldNames.FileName.name,
            `${getTranslations(t, Translates.Invalid_Input)}`,
            value => validateInputString(value ?? "")
          )
      }),
    [createEditFieldNames.Description.name]: yup
      .string()
      .max(100, "Length of this field can not be more than 100"),
    ...destinationValidation("Emails").fields
  })
}
