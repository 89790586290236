import CategoriesToolBar from "../../../../src/components/ConfigurationPortal/CategoriesToolBar/CategoriesToolBar"
import {
  Alert,
  AlertTitle,
  Checkbox,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { Loader } from "../../../../src/utils/reusables/reusable"
import React, { useCallback, useEffect, useState } from "react"
import GlobalTableDragDrop from "../../../../src/components/GlobalTable/GlobalTableDragDrop"
import { useAppThunkDispatch } from "../../../../src/redux/store"
import { setLocationCategories } from "../../../../src/redux/slices/configSlice"
import ConfirmDialog from "../../../../src/components/ConfirmDialog/ConfirmDialog"
import Warning from "@mui/icons-material/Warning"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import trashIcon from "../../../../src/assets/images/TrashIcon.svg"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNames, updateWarnOnTabChangeToStore } from "../helper"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { getTranslations } from "src/utils/helper"
import editIcon from "src/assets/images/Edit.png"
import { PATH } from "src/constants/paths"
import { useHistory } from "react-router-dom"

let preDeleteRow = {} as any
const Categories = ({ liftCategorySaveClick }) => {
  const history = useHistory()
  const {
    config: { locationCategories, configType: configTypeFromStore }
  } = ConfigDataHook()

  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)
  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.Categories,
    isTabChangeCheck: isTabChangeCheck
  })
  const [renderData, setRenderData] = useState([] as any)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const search = window.location.search || ""

  useEffect(() => {
    setRenderData([...locationCategories])
  }, [locationCategories])

  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">{props.value ? props.value : 0}</span>
    )
  }

  const displayDisableCheckbox = (props: any) => {
    return (
      <Checkbox
        checked={props.value}
        className={`custom-check light`}
        checkedIcon={<CheckIcon />}
        disabled={true}
      />
    )
  }
  const updateCategory = useCallback(
    (row1: { original: any; index: number }) => {
      setIsTabChangeCheck(false)
      updateWarnOnTabChangeToStore(false)
      setTimeout(() => {
        history.push(
          PATH.EDIT_CATEGORY.replace(":id", `${row1.index + 1}`) + search
        )
      })
    },
    []
  )
  const deleteCategory = useCallback((row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row.original }
  }, [])
  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Sort),
        accessor: "SortOrder",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 50
      },
      {
        Header: getTranslations(t, Translates.Category),
        accessor: "Name",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 300,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Label),
        accessor: "Label",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 250,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: "AQ Only",
        accessor: "IsAQOnly",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 250,
        Cell: props => {
          return displayDisableCheckbox(props)
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 150,

        Cell: props => {
          const handleEditClick = () => {
            updateCategory(props.row)
          }

          const handleDeleteClick = () => {
            deleteCategory(props.row)
          }
          const icons = [
            {
              title: getTranslations(t, Translates.Edit),
              clickFn: handleEditClick,
              isNotDisabled: true,
              icon: (
                <img
                  src={editIcon}
                  alt={"edit icon"}
                  className="pointer"
                  data-testid="edit-btn"
                />
              )
            },
            {
              title: getTranslations(t, Translates.Delete),
              clickFn: handleDeleteClick,
              icon: (
                <img
                  src={trashIcon}
                  alt="delete"
                  className="pointer"
                  data-testid="delete-btn"
                />
              )
            }
          ]
          return (
            <>
              {icons.map(f => {
                return (
                  <Tooltip arrow title={f.title} key={f.title}>
                    <IconButton
                      onClick={f.clickFn}
                      disabled={
                        !f.isNotDisabled && configTypeFromStore === "view"
                      }
                    >
                      <span className="text-center d-block">{f.icon}</span>
                    </IconButton>
                  </Tooltip>
                )
              })}
            </>
          )
        }
      }
    ],
    [t]
  )

  const setContent = () => {
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete
          )} ${preDeleteRow.Name} ${getTranslations(
            t,
            Translates.Category
          )}?`}</strong>
        </p>
      </Alert>
    )
  }
  const callbackConfirmation = (res: boolean) => {
    if (res) {
      let dt
      if (preDeleteRow.Name !== "Other") {
        dt = renderData.filter(f => f.Name !== preDeleteRow.Name)
      } else {
        dt = renderData.filter(f => f.Label !== preDeleteRow.Label)
      }
      dt = dt.map((m: any, i) => {
        return { ...m, SortOrder: i + 1 }
      })
      dispatch(setLocationCategories(dt))
    }
    setIsConfirmOpen(false)
  }
  const catSaveClick = () => {
    liftCategorySaveClick(
      [
        ...locationCategories.map((m, i) => {
          return { ...m, SortOrder: i + 1 }
        })
      ],
      "LocationCategoriesTab"
    )
  }
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive categories-table">
        <Grid xs={12} item data-testid="CateGbloTable">
          <GlobalTableDragDrop
            component={TableComponentNames.categories}
            columns={columnsData}
            data={renderData}
            noDataText={
              renderData.length && renderData.length !== 0
                ? Loader()
                : getTranslations(t, Translates.No_Categories_data_available)
            }
          ></GlobalTableDragDrop>
        </Grid>
        {isConfirmOpen && (
          <ConfirmDialog
            open={isConfirmOpen}
            callBack={callbackConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.Cancel)}
          />
        )}
      </Grid>
    )
  }
  return (
    <>
      <CategoriesToolBar
        catSaveClick={catSaveClick}
        setIsTabChangeCheck={setIsTabChangeCheck}
      />

      <div className="main-container">{displayGrid()}</div>
    </>
  )
}

export default Categories
