

import React, { useEffect, useMemo, useState } from "react"
import { Grid } from "@mui/material"
import { useAppThunkDispatch } from "src/redux/store"
import CreateScheduleBulk from "./CreateScheduleBulk"
import ScheduleBulkDataMapping from "./ScheduleBulkDataMapping"
import ScheduleBulkValidation from "./ScheduleBulkValidation"
import { setScheduleReset } from "src/redux/slices/scheduleSlice"
const ScheduleBulkContainer: React.FC<any> = () => {
  const [currentPage, setCurrentPage] = useState("page1")
  const dispatch = useAppThunkDispatch()
  useEffect(() => {
    setCurrentPage("page1")
    return () => {
      dispatch(setScheduleReset())
    }
  }, [])
  const setPage = _page => {
    setCurrentPage(_page)
  }
  const renderPages = useMemo(() => {
    if (currentPage === "page1") {
      return <CreateScheduleBulk setPage={setPage} />
    } else if (currentPage === "page2") {
      return <ScheduleBulkDataMapping setPage={setPage} />
    } else if (currentPage === "page3") {
      return <ScheduleBulkValidation setPage={setPage} />
    } else {
      return <></>
    }
  }, [currentPage])
  return (
    <>
      <Grid container>{renderPages}</Grid>
    </>
  )
}
export default React.memo(ScheduleBulkContainer)