import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps
} from "react-router-dom"
import { RootState } from "src/redux/store"
import { getPermissionsForUser } from "src/utils/helper"

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
  isRedirect?: boolean
  redirectTo?: string
  feature?: string
  permissions?: string
}
const multiCustRestricted = [
  "/locations",
  "/deviceReservations",
  "/locations/create",
  "/locations/edit",
  "/locationDetails",
  "/settings",
  "/settings/companyDetails",
  "/locationTypeSelect",
  "/settingsRegionalGroups",
  "/settings/regionalGroups/regionalGroupsDetails",
  "/settings/regionalGroups",
  "/settings/companyDetails",
  "/user/create",
  "/user/edit",
  "/users"
]

function AuthenticatedGuard(props: Props) {
  let isNotallowed: any
  let allowed = true
  const customerList = useSelector(
    (state: RootState) => state.settings.customers
  )
  const [userPermissions, setUserPermissions] = useState([] as any)
  useEffect(() => {
    const dt = getPermissionsForUser()
    let data = [
      ...dt.map((m: { Permissions: any[] }) => {
        return {
          ...m,
          Permissions: m.Permissions.filter(e => e).map(e => e.toLowerCase())
        }
      })
    ]
    setUserPermissions([...data])
  }, [])

  const selectedCustomerList = customerList.filter((c: any) => c.selected)
  if (selectedCustomerList.length > 1) {
    isNotallowed = multiCustRestricted.find(p => props.path?.includes(p))
    isNotallowed = isNotallowed ? true : false
  }
  // const isAuthenticated = true
  const { component: Component, ...rest } = props
  let isRedirect = props.isRedirect ?? false
  let redirectTo = props.redirectTo ?? "/"
  const PATH_NAME = window.location.pathname
  //Checking permissions
  let item = userPermissions.find(
    f => f.Feature.toLowerCase() === (props.feature ?? "").toLowerCase()
  )
  if (
    props.feature &&
    userPermissions.length > 0 &&
    !item?.Permissions.includes(props.permissions || "")
  ) {
    allowed = false
  }

  return (
    <React.Fragment>
      <Route
        {...rest}
        //istanbul ignore next
        render={props => {
          if (
            !localStorage.getItem("username") ||
            !localStorage.getItem("accessToken")
          ) {
            localStorage.setItem("redirectPath", PATH_NAME)
            return <Redirect to="/login" />
          }
          if (isRedirect) {
            return <Redirect to={`${redirectTo}`} />
          }
          //istanbul ignore next
          if (isNotallowed) {
            return <Redirect to="/" />
          }
          //istanbul ignore next
          if (allowed) {
            return <Component {...props} />
          } else {
            return <Redirect to="/PageNotFound" />
          }
        }}
      />
    </React.Fragment>
  )
}
export default AuthenticatedGuard
