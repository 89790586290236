import { useFormContext } from "react-hook-form"
import { Divider, Grid, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import InfoIcon from "@mui/icons-material/Info"
import FormHeading from "src/components/FormHeading"
import FormFieldConditionRender from "src/configurationPortal/pages/Components/FormFieldConditionRender"
import { Translates } from "src/i18n/i18n"
import { createEditFieldNames } from "src/pages/MultiEventOutputs/Utils/Constants/CreateEditMultiEventOutput"
import { DateRangeFields } from "src/pages/MultiEventOutputs/Utils/Constants/FileCreationSchedule/DateRange"
import {
  FcsDetailsFields,
  TimeFields,
  fileCSOptions
} from "src/pages/MultiEventOutputs/Utils/Constants/FileCreationSchedule/FcsOptions"
import { getScheduleFieldDetails } from "src/pages/MultiEventOutputs/Utils/Helpers/CreateEditMultiEventOutput"
import { getTranslations } from "src/utils/helper"
import FcsOptionsFields from "./FcsOptionsFields"
import MUITimePicker from "src/components/MUIFormFields/MUITimePicker"

const FcsOptions = () => {
  const { t } = useTranslation<any>()

  const { watch } = useFormContext()

  let {
    scheduleDetailFields,
    scheduleDetailFields1,
    scheduleDetailFields1Heading
  } = getScheduleFieldDetails(
    watch(createEditFieldNames.FileCreationSchedule.name)
  )

  return (
    <Grid container>
      <FormHeading
        heading={`${getTranslations(t, Translates.FileCreationSchedule)} *`}
        isDivider={true}
      />
      <FormFieldConditionRender FieldDetails={FcsDetailsFields} />
      {watch(createEditFieldNames.FileCreationSchedule.name) ? (
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />
          {scheduleDetailFields.map((i, _j) => (
            <FcsOptionsFields FieldDetails={i} key={`div-${i.props?.name}`} />
          ))}
          {Object.keys(scheduleDetailFields1).length ? (
            <div className="my-2">
              {scheduleDetailFields1Heading ? (
                <FormHeading
                  heading={`${getTranslations(
                    t,
                    scheduleDetailFields1Heading
                  )} *`}
                />
              ) : //istanbul ignore next
              null}
              <FormFieldConditionRender FieldDetails={scheduleDetailFields1} />
            </div>
          ) : null}

          <Grid container>
            <Grid item xs={12} sm={12} className={`mt-1`}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <label className="mb-2 f-600">
                    {getTranslations(t, Translates.Time)}
                  </label>
                  <Tooltip
                    id="emailTooltip"
                    arrow
                    title={
                      <div className="pa-2">
                        <h6 className="mb-2 f-600 text-uppercase">
                          <InfoIcon className="primary-text mr-1" />
                          {getTranslations(t, Translates.Time)}
                        </h6>
                        {getTranslations(t, Translates.Time_current_time_zone)}
                      </div>
                    }
                  >
                    <InfoOutlinedIcon className="primary-text ml-1" />
                  </Tooltip>
                  <MUITimePicker inputAttributes={TimeFields.time.props} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {[
        fileCSOptions.Default,
        fileCSOptions.Daily,
        fileCSOptions.Weekly
      ].includes(watch(createEditFieldNames.FileCreationSchedule.name)) ? (
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />
          <FormFieldConditionRender FieldDetails={DateRangeFields} />
        </Grid>
      ) : null}
    </Grid>
  )
}

export default FcsOptions
