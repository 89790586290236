//BEGIN-NOSCAN
import React from "react"
import { createRoot } from "react-dom/client"
import "./assets/scss/index.scss"
import App from "./App/App"
import { Provider } from "react-redux"
import { StyledEngineProvider } from "@mui/material"
import { store } from "./redux/store"
import { BrowserRouter } from "react-router-dom"
import Toaster from "./components/Toaster/Toaster"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./config/authConfig"
import { prepareMsalConfig } from "./utils/helper"
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js"
// import { reactPlugin } from "./AppInsights/AppInsights"

let mConfig: any = prepareMsalConfig(
  { domain_hint: "", login_hint: "" },
  msalConfig,
  true
)
const msalInstance = new PublicClientApplication(mConfig)
const root = createRoot(document.getElementById("root")!)
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Toaster />
        <MsalProvider instance={msalInstance}>
          {/* <AppInsightsErrorBoundary
            onError={err => {
              return <h1>{err}</h1>
            }}
            appInsights={reactPlugin}
          > */}
          <App />
          {/* </AppInsightsErrorBoundary> */}
        </MsalProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  </Provider>
  // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
//END-NOSCAN
