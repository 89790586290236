import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Divider,
  Checkbox
} from "@mui/material"
import { Loader } from "../../../../src/utils/reusables/reusable"
import React, { useEffect, useState } from "react"

import GlobalTableDragDrop from "../../../../src/components/GlobalTable/GlobalTableDragDrop"
import { RootState, useAppThunkDispatch } from "../../../../src/redux/store"
import { useSelector } from "react-redux"
import editIcon from "src/assets/images/Edit.png"
import TrashIcon from "src/assets/images/TrashIcon.svg"
import { useHistory, useParams } from "react-router-dom"
import { PATH } from "src/constants/paths"
import {
  setCurrentOutputFile,
  setOutputColumns
} from "src/redux/slices/configSlice"
import AddIcon from "@mui/icons-material/Add"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import CheckIcon from "@mui/icons-material/Check"
import {
  getFieldDelimiterValue,
  getRecordDelimiterValue
} from "../OutputFiles/OutputHelper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { displaySource } from "../ColumnHelper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getSetContent } from "../DeleteContentHelper"
import { getTranslations } from "src/utils/helper"
let preDeleteRow = {} as any
const OutputColumns = ({ form, sortID, isParentEdit }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) =>state.config?.configType
  )
  const history = useHistory()
  const [renderData, setRenderData] = useState([] as any)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  let search = window.location.search || ""
  const editColumn = useSelector(
    (state: RootState) => state.config?.outputColumns
  )

  const sqlQueryColumns = useSelector(
    (state: RootState) => state.config?.newSQLQueryColumns
  )
  const dispatch = useAppThunkDispatch()

  let { storeId }: { storeId: string } = useParams()
  useEffect(() => {
    setLoading(true)
    setRenderData(editColumn && [...editColumn])
    setLoading(false)
  }, [editColumn])
  useEffect(() => {
    setLoading(true)
    setRenderData(editColumn && [...editColumn])
    setLoading(false)
  }, [sqlQueryColumns])

  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : ""}
      </span>
    )
  }
  const displayPadding = (props: any) => {
    return (
      <span className="d-block text-left">
        {" "}
        {Array.isArray(props.value) ? props.value[0]["Format"] : props.value}
      </span>
    )
  }

  const handleNewColumnClick = () => {
    form = {
      ...form,
      FieldDelimiter: getFieldDelimiterValue(form),
      RecordDelimiter: getRecordDelimiterValue(form)
    }
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.EDIT_COLUMNS + search, {
        isEdit: false,
        storeId: storeId,
        isParentEdit: isParentEdit,

        form: form
      })
    })
    const destination = {
      CustomerPortalCheck: form?.customerPortalCheck,
      FtpDirectory: form?.selectedOption,
      Email: form?.chips
    }

    dispatch(setCurrentOutputFile({ form, Destination: destination }))
  }

  const EditOutputColumn = (data: any) => {
    form = {
      ...form,
      FieldDelimiter: getFieldDelimiterValue(form),
      RecordDelimiter: getRecordDelimiterValue(form)
    }
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.EDIT_COLUMNS + search, {
        isEdit: true,
        data: { ...data },
        storeId: storeId,
        isParentEdit: isParentEdit,
        form: form
      })
    })
    const destination = {
      CustomerPortalCheck: form?.customerPortalCheck,
      FtpDirectory: form?.selectedOption,
      Email: form?.chips
    }

    dispatch(setCurrentOutputFile({ form, Destination: destination }))
  }

  const handleDeleteColumnClick = (row: any) => {
    setIsConfirmOpen(true)
    preDeleteRow = { ...row }
  }
  const callbackConfirmation = (res: boolean) => {
    if (res) {
      let dt = editColumn?.filter(
        f => f.ColumnName !== preDeleteRow?.ColumnName
      )
      dt = dt?.map((m: any, i) => {
        return { ...m, SortOrder: i + 1 }
      })
      dispatch(setOutputColumns(dt))
      setRenderData(dt)
      //setReportGrandTotalValues(dt)
    }

    setIsConfirmOpen(false)
  }
  const deleteTextData = () => {
    return `${getTranslations(t, Translates.Are_you_sure_you_want_to_delete)} ${
      preDeleteRow?.ColumnName
    } ${getTranslations(t, Translates.Column)}?`
  }

  const setContent = getSetContent(t, deleteTextData())
  const handleSupressColumns = rowValue => {
    const updatedOuputColumns = editColumn.map(function (column) {
      if (column["SortOrder"] <= rowValue["SortOrder"]) {
        return { ...column, Suppress: !rowValue["Suppress"] }
      }
      return column
    })
    dispatch(setOutputColumns(updatedOuputColumns))
  }
  const displayCheckbox = (props: any) => {
    return (
      <Checkbox
        className="custom-check light"
        name="ShowOnTagDetails"
        checked={props.value}
        checkedIcon={<CheckIcon />}
        onChange={e => handleSupressColumns(props.row.original)}
      />
    )
  }

  const commonValues = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: true,
    setWidth: 200,
    Cell: props => {
      return displaySpan(props)
    }
  }

  const columnHeadersList: any = [
    {
      ...{
        Header: getTranslations(t, Translates.Sort),
        accessor: "SortOrder"
      },
      ...commonValues,
      ...{ setWidth: 50 }
    },
    {
      ...{
        Header: getTranslations(t, Translates.Source),
        accessor: "DataSource"
      },
      ...commonValues,
      ...{
        setWidth: 150,
        Cell: props => {
          return displaySource(props, sqlQueryColumns)
        }
      }
    },
    {
      ...{
        Header: getTranslations(t, Translates.Name),
        accessor: "ColumnName"
      },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.Data_Type),
        accessor: "DataType"
      },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.Padding),
        accessor: "Padding"
      },
      ...commonValues,
      ...{
        Cell: props => {
          return displayPadding(props)
        }
      }
    },
    {
      ...{
        Header: getTranslations(t, Translates.Surround_Character),
        accessor: "SurroundCharacter"
      },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.SUPPRESS),
        accessor: "Suppress"
      },
      ...commonValues,
      ...{
        setWidth: 150,
        Cell: props => {
          return displayCheckbox(props)
        }
      }
    },
    {
      ...{ Header: getTranslations(t, Translates.ACTIONS), accessor: "action" },
      ...commonValues,
      ...{
        setWidth: 180,
        Cell: props => {
          return (
            <>
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <IconButton
                  onClick={() => {
                    EditOutputColumn(props.row.original)
                  }}
                >
                  <span className="action-btns text-center d-block ">
                    <img
                      src={editIcon}
                      alt={"edit icon"}
                      className="pointer"
                      data-testid="edit"
                    />
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
                <span>
                  <IconButton
                    onClick={() => handleDeleteColumnClick(props.row.original)}
                    className={`${
                      configTypeFromStore === "view" ? "disable-img" : "pointer"
                    }`}
                    disabled={configTypeFromStore === "view"}
                  >
                    <span className="action-btns text-center d-block ">
                      <img src={TrashIcon} className="pointer" alt="delete" />
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )
        }
      }
    }
  ]
  const columnData = [
    columnHeadersList?.map(id => {
      return {
        Header: getTranslations(t, id.Header),
        accessor: id.accessor,
        headerClassName: id.headerClassName,
        disableFilters: id.disableFilters,
        disableSortBy: id.disableSortBy,
        setWidth: id.setWidth,
        Cell: id.Cell
      }
    })
  ].flat(Infinity)

  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive categories-table">
        <Grid item xs={12} sm={12}>
          <Divider className="dashed my-4" />
          <Grid container>
            <Grid item xs={6}>
              <h4>{getTranslations(t, Translates.Columns)}</h4>
            </Grid>
            <Grid item xs={6} className="text-right">
              <Button
                variant="contained"
                data-testid="reportpromptCreate"
                className="primary-btn pa-2"
                onClick={handleNewColumnClick}
                disabled={
                  !(sqlQueryColumns?.length || editColumn?.length) ||
                  configTypeFromStore === "view"
                }
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Column)}
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} className="mt-2" item>
            <GlobalTableDragDrop
              component="outputColumns"
              columns={columnData}
              data={renderData || []}
              noDataText={
                renderData && renderData?.length !== 0
                  ? Loader()
                  : `${getTranslations(
                      t,
                      Translates.No_Columns_Data_available
                    )}.`
              }
            ></GlobalTableDragDrop>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {loading ? Loader() : displayGrid()}
      {isConfirmOpen && (
        <ConfirmDialog
          title={getTranslations(t, Translates.DELETE)}
          open={isConfirmOpen}
          callBack={callbackConfirmation}
          content={setContent}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.Cancel)}
        ></ConfirmDialog>
      )}
    </>
  )
}

export default OutputColumns
