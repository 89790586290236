import { Box, Typography } from "@mui/material"
import { TabPanelProps } from "src/utils/types/types"
import { CONFIG_MODULES } from "src/utils/constants"
import {
  LocationsList,
  ProductsList,
  reorderRecords
} from "../../CountFields/Constants"
import Instructions from "./Instructions"

export const a11yProps = (index: number, type: string) => {
  return {
    id: `${type}-tab-${index}`,
    "aria-controls": `${type}-tabpanel-${index}`
  }
}
export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, type, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${type}-tabpanel-${index}`}
      aria-labelledby={`${type}-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

let productList: {
  value: string
  label: string
  originalValue: string
  OjKey: string
}[] = []

let locationList = productList

export const setProductList = newProductList => {
  productList = newProductList
}

export const getProductList = () => {
  return productList
}

export const setLocationList = newLocationList => {
  locationList = newLocationList
}

export const getLocationList = () => {
  return locationList
}

export const prepareDataForProductList = (countField, isRefetch = false) => {
  if (!productList.length || isRefetch) {
    if (countField && Object?.keys(countField).length) {
      let products: any = Object.keys(countField)
        .filter(
          i =>
            ![
              LocationsList.Location.OjKey,
              LocationsList.SecondaryLocation1.OjKey,
              LocationsList.SecondaryLocation2.OjKey,
              LocationsList.SecondaryLocation3.OjKey
            ].includes(i) && ProductsList[i]
        )
        .map(i => {
          return { field: i, ...countField[i] }
        })
      let fieldsOptionsList = reorderRecords(products)

      fieldsOptionsList = fieldsOptionsList
        ?.filter(i => Object.keys(i).length > 1 && ProductsList[i?.field])
        ?.map(i => {
          i = { ...ProductsList[i?.field], ...i }
          return {
            label: i?.Labels[0]["en-us"].replace(/([a-z])([A-Z])/g, "$1 $2"),
            value: i?.Labels[0]["en-us"],
            originalValue: i?.field,
            OjKey: i?.OjKey
          }
        })
      setProductList([...fieldsOptionsList])
    }
  }
  return true
}

export const prepareDataForLocationList = (countField, isRefetch = false) => {
  if (!locationList.length || isRefetch) {
    if (countField && Object?.keys(countField).length) {
      let locations: any = Object?.keys(countField)
        .filter(
          i =>
            [
              LocationsList.Location.OjKey,
              LocationsList.SecondaryLocation1.OjKey,
              LocationsList.SecondaryLocation2.OjKey,
              LocationsList.SecondaryLocation3.OjKey
            ].includes(i) && LocationsList[i]
        )
        .map(i => {
          return { field: i, ...countField[i] }
        })
      let fieldsOptionsList = locations
      fieldsOptionsList = fieldsOptionsList
        ?.filter(i => Object.keys(i).length > 1 && LocationsList[i?.field])
        ?.map(i => {
          i = { ...LocationsList[i?.field], ...i }
          return {
            label: i?.Labels[0]["en-us"].replace(/([a-z])([A-Z])/g, "$1 $2"),
            value: i?.Labels[0]["en-us"],
            originalValue: i?.field,
            OjKey: i?.OjKey
          }
        })
      setLocationList([...fieldsOptionsList])
    }
  }
  return true
}

export const instructionDetails = [
  {
    title: "Setup Instructions",
    required: true,
    component: CONFIG_MODULES.CountPrograms.setup
  },
  {
    title: "Validation Instructions",
    required: true,
    component: CONFIG_MODULES.CountPrograms.validation
  },
  {
    title: "Lookup Instructions",
    required: true,
    component: CONFIG_MODULES.CountPrograms.Lookup
  }
]

export const fetchInstructionLayout = (
  setupInstructionsName: string,
  validationInstructionsName: string,
  lookupInstructionsName: string,
  j: number,
  OjKey: string,
  bConfig: any,
  disable: boolean = false
) => {
  return (
    <Instructions
      setupInstructionsName={setupInstructionsName.replace(
        "{inx}",
        j.toString()
      )}
      validationInstructionsName={validationInstructionsName.replace(
        "{inx}",
        j.toString()
      )}
      lookupInstructionsName={lookupInstructionsName.replace(
        "{inx}",
        j.toString()
      )}
      bConfig={bConfig}
      product={OjKey}
      disable={disable}
    />
  )
}
