export const Audit_Types = [
  "Follow Up System Audits",
  "Random System Audits",  
  "Record System Audits",
  "Location System Audits",
 
]
export const Audit_TypesObj = {
  Random: "Random System Audits",
  FollowUp: "Follow Up System Audits",
  TargetedLocation: "Location System Audits",
  TargetedRecord: "Record System Audits"
}

export const BreadCrumb_Fields = {
  AUDITS_COMPONENT:"audits",
  AUDITRULE_TITLE:"Audit New Rule Conditions",
  AUDIT_TITLE:"General Audit Settings"
}

export const FieldTypes = {
  INTEGER:"Integer",
  DECIMAL:"Decimal"
}
