import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import {
  RadioButtonLists,
  VarianceTypes,
  fieldNames
} from "../../../Utils/Constants"
import { Translates } from "src/i18n/i18n"
import {
  getRadioArr,
  getVarianceFormVariable,
  setFocusWhileChange
} from "../../../Utils/FunctionsHelper"
import MUIText from "src/components/MUIFormFields/MUIText"
import RadioButtons from "./RadioButtons"
import { getTranslations, handleFocus } from "src/utils/helper"

const ThresholdSettings = () => {
  const { t } = useTranslation<any>()
  const {
    watch,
    setFocus,
    setValue,
    formState: { errors }
  } = useFormContext()
  let { varianceForm } = getVarianceFormVariable(
    watch(fieldNames.CurrentTabVarianceType)
  )
  const Threshold = watch(varianceForm.Threshold.name)
  const RadioArr = getRadioArr(
    varianceForm,
    Threshold,
    watch(fieldNames.CurrentTabVarianceType),
    watch(varianceForm.IsBasedOnControlFile.name)
  )
  const IsNotEnableFeature = !watch(varianceForm.IsEnable.name)
  const handleOnChange = i => {
    if (
      ["", undefined, null].includes(watch(i.constant.name)) ||
      errors[watch(fieldNames.CurrentTabVarianceType)]?.[
        i.constant.originalName
      ]
    ) {
      setValue(i.constant.name, 0, { shouldValidate: true })
    }
    setFocusWhileChange(i.constant.name, setFocus)
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (
        watch(fieldNames.CurrentTabVarianceType) ===
        VarianceTypes.FullVariance.name
      ) {
        if (!watch(varianceForm.IsBasedOnControlFile.name)) {
          if (
            watch(varianceForm.QtyThreshold.name) ===
            RadioButtonLists.ThresholdSelects.VarianceControlFile.value
          ) {
            setValue(varianceForm.QtyThreshold.name, "")
          }

          if (
            watch(varianceForm.ExtThreshold.name) ===
            RadioButtonLists.ThresholdSelects.VarianceControlFile.value
          ) {
            setValue(varianceForm.ExtThreshold.name, "")
          }
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [watch(varianceForm.IsBasedOnControlFile.name)])

  return (
    <>
      <h4 className="mb-3">
        {getTranslations(t, Translates.Threshold_Settings)}
      </h4>

      {RadioArr.map(i => {
        return (
          <React.Fragment key={i.key}>
            {i.isShow ? (
              <>
                <strong className="d-block mb-1 mt-3">{`${getTranslations(
                  t,
                  i.label
                )}${i.required && "*"}`}</strong>
                <RadioButtons
                  {...{
                    formControlLabels: i.formControlLabels,
                    name: i.name,
                    ...(i.constant && {
                      onChange: () => handleOnChange(i)
                    })
                  }}
                />
              </>
            ) : null}

            {i.isShow &&
            i.constant &&
            [i.constantShowValue].includes(watch(i.name)) ? (
              <Grid container className="mt-1">
                <Grid item xs={12} sm={3}>
                  <MUIText
                    inputAttributes={{
                      name: i.constant.name,
                      label: `${getTranslations(t, i.constant.label)}*`,
                      disabled: IsNotEnableFeature,
                      onFocus: handleFocus
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ThresholdSettings
