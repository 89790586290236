import { IconButton, Tooltip } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import editIcon from "src/assets/images/Edit.png"
import trashIcon from "src/assets/images/TrashIcon.svg"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"
import AlertConfirmationContent from "src/configurationPortal/pages/Components/AlertConfirmationContent"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import { store } from "src/redux/store"
import { PATH } from "src/constants/paths"

export const getColumnsData = ({ updatePrompt, deletePrompt, t }) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    setWidth: 250,
    Cell: props => {
      return displaySpan(props)
    }
  }

  return [
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Sort),
      accessor: "SortOrder",
      setWidth: 50
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Prompt),
      accessor: "Label[0][en-us]"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Variable_Name),
      accessor: "VariableName"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Type),
      accessor: "Type"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Default_Value),
      accessor: "DefaultValue"
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      setWidth: 150,
      Cell: props => {
        const icons = [
          {
            title: getTranslations(t, Translates.Edit),
            clickFn: updatePrompt,
            icon: (
              <img
                src={editIcon}
                alt={"edit icon"}
                className="pointer"
                data-testid="report-prompt-edit"
              />
            )
          },
          {
            title: getTranslations(t, Translates.Delete),
            clickFn: deletePrompt,
            icon: (
              <img
                src={trashIcon}
                alt="delete"
                className="pointer"
                data-testid="report-prompt-delete"
              />
            )
          }
        ]
        return (
          <>
            {icons.map((f, i) => {
              return (
                <>
                  <Tooltip arrow title={f.title} key={f.title}>
                    <IconButton onClick={() => f.clickFn(props.row)}>
                      <span className="text-center d-block">{f.icon}</span>
                    </IconButton>
                  </Tooltip>
                </>
              )
            })}
          </>
        )
      }
    }
  ]
}

export const setModalContent = ({ preDeletedRow, t }) => {
  let content = `${getTranslations(
    t,
    Translates.Are_you_sure_you_want_to_delete
  )} "${preDeletedRow?.Label}" ${getTranslations(t, Translates.Prompt)}?`
  return <AlertConfirmationContent warningMsg={content} />
}

export const getVariableBasedOnComponent = (
  componentName: string,
  tabId: number,
  promptId: number
) => {
  let variableSetRedux = setReportRedux
  let tableComponent = TableComponentNames.reportPrompts
  const { reportRedux, reportsRedux } = store.getState().report
  let variableRedux = reportRedux
  let variablesRedux = reportsRedux
  let createPath = PATH.TAB_CREATE_PROMPT_CREATE
  let editPath = PATH.TAB_CREATE_PROMPT_EDIT
  let redirectUrl = PATH.REPORT_CREATE
  if (tabId !== -1) {
    createPath = PATH.TAB_EDIT_PROMPT_CREATE
    editPath = PATH.TAB_EDIT_PROMPT_EDIT
  }
  if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Reports.toLocaleLowerCase()
  ) {
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    }
  } else if (
    componentName.toLocaleLowerCase() ===
    ConfigTabNames.Outputs.toLocaleLowerCase()
  ) {
    variableSetRedux = setReportRedux
    tableComponent = TableComponentNames.outputPrompts
    variableRedux = reportRedux
    variablesRedux = reportsRedux
    if (tabId !== -1) {
      redirectUrl = PATH.REPORT_EDIT
    } else {
      redirectUrl = PATH.REPORT_CREATE
    }
  }
  return {
    variableSetRedux,
    tableComponent,
    variableRedux,
    createPath,
    editPath,
    redirectUrl,
    variablesRedux
  }
}
