import { Grid, Button, Alert, AlertTitle } from "@mui/material"
import React, { useEffect, useState } from "react"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined"
import CheckIcon from "@mui/icons-material/Check"
import { Loader } from "src/utils/reusables/reusable"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import HistoryIcon from "@mui/icons-material/History"
import { filterByHelper, getTranslations } from "src/utils/helper"
import UploadStatusFilter from "./UploadStatusFilter"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import store from "../../redux/store"
import { PAGE_REFRESH_TIME, UPLOAD_STATUS } from "src/utils/constants"
import DateFormat from "src/components/DateFormat"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { clearPoll, intervalVarNames } from "src/service/poll.service"
import { UploadFileService } from "src/service/uploadfile.service"
import {
  setUploadFilesStatusRecordsInitialResp,
  setUploadFilesStatusRecordsRender,
  setUploadStatusFilterDatas
} from "src/redux/slices/uploadFileSlice"
import { Warning } from "@mui/icons-material"

let isAPIInProgress = false
let pollId

const UploadStatusDetails = ({
  hideFilter,
  showFilterPage,
  sendFilterDataToParent
}) => {
  const [loading, setLoading] = useState(false)
  const [renderFilterData, setRenderFilterData] = useState([] as any)
  const dispatch = useAppThunkDispatch()
  const { t } = useTranslation<any>()
  const [uploadAlert, setUploadAlert] = useState(false)
  const uploadFiles = useSelector((state: RootState) => state.uploadFiles)
  const { uploadStatusFilters, uploadStatusFilterDatas } = uploadFiles || {}
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  useEffect(() => {
    if (uploadStatusFilters?.sDate) {
      setRenderFilterData(uploadStatusFilterDatas)
    }
  }, [renderFilterData])

  useEffect(() => {
    let timer
    if (uploadAlert) {
      timer = setTimeout(() => setUploadAlert(false), 3000)
    }
    return () => clearTimeout(timer)
  }, [uploadAlert])

  const uploadFileService = new UploadFileService()
  const [initialResp, setInitialResp] = useState([] as any)

  const getIcon = statusValue => {
    if (
      statusValue.toLowerCase() === UPLOAD_STATUS.received ||
      statusValue.toLowerCase() === UPLOAD_STATUS.successful ||
      statusValue.toLowerCase() === UPLOAD_STATUS.finished
    ) {
      return <CheckIcon />
    } else if (statusValue.toLowerCase() === UPLOAD_STATUS.processing) {
      return <HistoryIcon />
    } else if (statusValue.toLowerCase() === UPLOAD_STATUS.failed) {
      return <CancelOutlinedIcon />
    }
  }
  //istanbul ignore next
  const pollApi = async () => {
    if (customerId) {
      pollId = setTimeout(() => {
        getUploadStatus(customerId, true)
        pollApi()
      }, PAGE_REFRESH_TIME)
    }
  }

  const clearPagePoll = () => {
    clearPoll([intervalVarNames.UPLOADSTATUS], "uploadStatus")
    if (pollId) clearInterval(pollId)
  }

  const init = () => {
    if (customerId && !isAPIInProgress) {
      clearPagePoll()
      getUploadStatus(customerId)
      pollApi()
    }
  }

  useEffect(() => {
    if (customerId) {
      init()
    }
    return () => {
      clearPagePoll()
    }
  }, [customerId])

  const formatData = (data: any) => {
    if (!data || !data.length) {
      return []
    }

    let formated = data.map(item => {
      let recordId: any = new Date(item.droppedOffAt).getTime()
      recordId = `${item.fileName}-${recordId}`
      return { ...item, recordId }
    })
    return formated
  }

  const getUploadStatus = async (customerId, isPoll: boolean = false) => {
    try {
      isAPIInProgress = true
      if (!isPoll) {
        setLoading(true)
      }
      await uploadFileService
        .getUploadFilesViewStatus(customerId)
        .then((temp: any) => {
          //istanbul ignore if
          if (isPoll) {
            let newformatedData = formatData(temp.data)
            let newState =
              store.getState().uploadFiles.uploadFilesStatusRecordsRender
            let exisistFormatingData = [...newState]
            exisistFormatingData = exisistFormatingData.map(item => {
              let newMatched = newformatedData.find(
                record => record.recordId === item.recordId
              )
              if (newMatched) {
                if (item.status !== newMatched.status) {
                  return { ...item, status: newMatched.status }
                }
              }
              return item
            })

            setRenderFilterData(exisistFormatingData)
          } else {
            const formatted = formatData(temp.data)
            setInitialResp(formatted)
            setRenderFilterData(formatted)
            dispatch(setUploadFilesStatusRecordsInitialResp(formatted))
            dispatch(setUploadFilesStatusRecordsRender(formatted))
          }
        })
        .finally(() => {
          isAPIInProgress = false
          setLoading(false)
        })
    } catch (_error) {
      setLoading(false)
    }
  }

  const getStatusColor = statusValue => {
    if (statusValue.toLowerCase() === UPLOAD_STATUS.received) {
      return "info"
    } else if (
      statusValue.toLowerCase() === UPLOAD_STATUS.successful ||
      statusValue.toLowerCase() === UPLOAD_STATUS.finished
    ) {
      return "success"
    } else if (statusValue.toLowerCase() === UPLOAD_STATUS.processing) {
      return "info-light"
    } else if (statusValue.toLowerCase() === UPLOAD_STATUS.failed) {
      return "error"
    }
  }

  const getAppliedValues = sDate => {
    sendFilterDataToParent(sDate)
    let filters: any = []
    let filtered: any[] = []
    if (sDate) {
      filters.push({
        field: "droppedOffAt",
        value: sDate,
        func: "equalDate",
        isConverstionRequired: true
      })
    }
    filtered = filterByHelper(filters, initialResp)
    dispatch(setUploadStatusFilterDatas([...filtered]))
    hideFilter()
  }

  const cancelClick = () => {
    hideFilter()
  }
  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }
  const handleViewErrors = props => {
    const errorsFilePath = props?.row?.original?.errorsFilePath
    if (errorsFilePath) {
      window.open(errorsFilePath, "_blank")
    } else {
      setUploadAlert(true)
    }
  }

  const handleViewTotal = props => {
    const totalsFilePath = props?.row?.original?.totalsFilePath
    if (totalsFilePath) {
      window.open(totalsFilePath, "_blank")
    } else {
      setUploadAlert(true)
    }
  }
  const renderUploadAlert = () => (
    <Alert
      data-testid="procedureAlert"
      severity="error"
      icon={<Warning />}
      className="custom-alert top"
    >
      <AlertTitle className="custom-alert__title f-600">
        {getTranslations(t, Translates.Error)}
      </AlertTitle>
      {getTranslations(t, Translates.File_Path_Not_Available)}
    </Alert>
  )

  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.FILE_NAME),
        accessor: "fileName", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Uploaded_By),
        accessor: "droppedOffBy", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Uploaded_At),
        accessor: "droppedOffAt", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          let propsToDate: any = { ...props, isLocal: true }
          return <DateFormat {...propsToDate} />
        }
      },
      {
        Header: getTranslations(t, Translates.Status),
        accessor: "status", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return props.value ? (
            <span className={`status-bg ${getStatusColor(props?.value)}`}>
              {getIcon(props?.value)}
              {getTranslations(t, props?.value?.toLowerCase())}
            </span>
          ) : (
            "-"
          )
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the dat
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return (
            <span className="action-btns">
              {props?.row?.original?.status?.toLowerCase() ===
              UPLOAD_STATUS.failed ? (
                <Button
                  data-testid="view-total-errors"
                  variant="text"
                  className="primary-btn"
                  onClick={() => handleViewErrors(props)}
                >
                  <RemoveRedEyeOutlinedIcon className="gray-text mr-1" />
                  <span className="f-600 text-uppercase">
                    {getTranslations(t, Translates.View_Errors)}
                  </span>
                </Button>
              ) : props?.row?.original?.status?.toLowerCase() ===
                UPLOAD_STATUS.finished ? (
                <Button
                  data-testid="view-total-records"
                  variant="text"
                  className="primary-btn"
                  onClick={() => handleViewTotal(props)}
                >
                  <RemoveRedEyeOutlinedIcon className="gray-text mr-1" />
                  <span className="f-600 text-uppercase">
                    {getTranslations(t, Translates.View_Total)}
                  </span>
                </Button>
              ) : (
                ""
              )}
            </span>
          )
        }
      }
    ],
    [initialResp, t]
  )

  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive">
        <Grid xs={12} item>
          <GlobalTable
            columns={columnsData}
            data={renderFilterData || []}
            noDataText={getTranslations(t, Translates.No_Upload_Status_Found)}
            initialStateProp={{
              sortBy: [
                {
                  id: columnsData.length ? columnsData[2].accessor : "",
                  desc: true
                }
              ]
            }}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      {uploadAlert && renderUploadAlert()}
      {loading ? Loader() : displayGrid()}
      {showFilterPage ? (
        <UploadStatusFilter
          showFilterPage={showFilterPage}
          applyClick={getAppliedValues}
          cancelClick={cancelClick}
        />
      ) : null}
    </div>
  )
}
export default UploadStatusDetails
