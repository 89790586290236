//BEGIN-NOSCAN
import * as CryptoJS from "crypto-js"
import axios from "axios"
import { getKeys } from "src/utils/helper"

export class HereService {
  // istanbul ignore next
  autoComplete = (qParams, countryCode) => {
    let token = localStorage.getItem("hereToken")
    const baseURL: string = getKeys("HereAutoCompleteUrl")
    let url: any = baseURL + `?${qParams}`
    if (countryCode)
      url = url + `&in=countryCode:${encodeURIComponent(countryCode)}`
    const headers = { Authorization: `Bearer ${token}` }
    return axios.get(url, { headers })
  }
  autoCompleteTimeZone = (qParams) => {
    let token = localStorage.getItem("hereToken")
    const baseURL: string = 'https://geocode.search.hereapi.com/v1/geocode'
    let url: any = baseURL + `?${qParams}`+`&show=tz`
    // if (countryCode)
    //   url = url + `&in=countryCode:${encodeURIComponent(countryCode)}`
    const headers = { Authorization: `Bearer ${token}` }
    return axios.get(url, { headers })
  }
  // istanbul ignore next
  getToken = () => {
    localStorage.setItem("hereToken", "**")
    let url = getKeys("HereTokenEndPointUrl")
    let key = encodeURI(getKeys("HereAccessKeyId"))
    let secret = encodeURI(getKeys("HereAccessKeySecret"))
    let nonce = btoa(Math.random().toString(36)).substring(2, 13)
    let timestamp: any = Math.floor(Date.now() / 1000)
    let normalizedUrl = encodeURIComponent(url)
    let signing_method = encodeURI("HMAC-SHA256")
    let sig_string = "oauth_consumer_key="
      .concat(key)
      .concat("&oauth_nonce=")
      .concat(nonce)
      .concat("&oauth_signature_method=")
      .concat(signing_method)
      .concat("&oauth_timestamp=")
      .concat(timestamp)
      .concat("&")
      .concat("oauth_version=1.0")

    let normalised_string = "POST&"
      .concat(normalizedUrl)
      .concat("&")
      .concat(encodeURIComponent(sig_string))
    let signingKey = secret.concat("&")

    let digest = CryptoJS.HmacSHA256(normalised_string, signingKey)
    let signature = CryptoJS.enc.Base64.stringify(digest)

    let auth = 'OAuth oauth_consumer_key="'
      .concat(key)
      .concat('",oauth_signature_method="')
      .concat(signing_method)
      .concat('",oauth_signature="')
      .concat(encodeURIComponent(signature))
      .concat('",oauth_timestamp="')
      .concat(timestamp)
      .concat('",oauth_nonce="')
      .concat(nonce)
      .concat('",oauth_version="1.0"')

    axios({
      method: "post",
      url: url,
      data: JSON.stringify({ grantType: "client_credentials" }),
      headers: {
        "Content-Type": "application/json",
        Authorization: auth
      }
    })
      .then(resp => {
        localStorage.setItem("hereToken", resp.data.accessToken)
        localStorage.setItem(
          "hereTokenFetchedAt",
          new Date().getTime().toString()
        )
      })
      .catch(err => {
        console.log("error while fetching here API token ", err)
      })
  }
}
//END-NOSCAN
